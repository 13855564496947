<template>
     <div>
          <div
               class="pa-5 d-flex justify-space-between heading_bg"
               id="pdf_invoice"
          >
               <span class="heading_color--text my-auto">{{
                    $t('invoice.printpreview')
               }}</span>
               <div>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold mr-2"
                         @click="printWeb"
                    >
                         <span class="white--text">{{
                              $t('global.print')
                         }}</span>
                    </v-btn>
                    <v-btn
                         color="grey"
                         class="body-2 font-weight-bold"
                         text
                         @click="closeWeb"
                    >
                         <span>{{ $t('global.cancel') }}</span>
                    </v-btn>
               </div>
          </div>
          <v-divider class="no_print mb-5"></v-divider>

          <div class="white pa-7 pdfa4screenl mx-auto" id="pdfgencontent">
               <div class="d-flex justify-space-between mb-5">
                    <div>
                         <v-img
                              height="200px"
                              width="200px"
                              :src="url + '/' + this.apiCompanySetting.logo"
                         ></v-img>
                    </div>
                    <div class="d-flex flex-column" id="companybox">
                         <span class="text-h4 mb-3 text-right">{{
                              $t('components.invoice')
                         }}</span>
                         <span class="text-h6 text-right">{{
                              apiCompanySetting.name
                         }}</span>
                         <span
                              v-if="apiCompanySetting.address"
                              class="text-right text2_color--text font-weight-light"
                              >{{ apiCompanySetting.address }}</span
                         >
                         <span
                              v-if="apiCompanySetting.phone"
                              class="text-right text2_color--text mt-2 font-weight-light"
                              >T:{{ apiCompanySetting.phone }}</span
                         >
                         <span
                              v-if="apiCompanySetting.fax"
                              class="text-right text2_color--text font-weight-light"
                              >F:{{ apiCompanySetting.fax }}</span
                         >
                    </div>
               </div>
               <v-divider></v-divider>
               <div class="d-flex justify-space-between pa-7 my-auto">
                    <div>
                         <span
                              class="text-right text2_color--text text--darken-3"
                              >{{
                              $t('invoice.billto')
                              }}</span
                         >
                         <div class="mt-3">
                              <span style="font-size:1.5rem;">
                                   {{ selectcustomer.name }}</span
                              >
                         </div>
                         <div
                              v-if="selectcustomer.address"
                              style="max-width: 350px"
                         >
                              <span class="text-body-2 text2_color--text">
                                   {{ selectcustomer.address }}</span
                              >
                         </div>
                         <div v-if="selectcustomer.email">
                              <v-icon small>mdi-email</v-icon>
                              <span
                                   class="text-body-2 text2_color--text font-weight-bold"
                              >
                                   {{ selectcustomer.email }}</span
                              >
                         </div>
                         <div v-if="selectcustomer.phone">
                              <v-icon small>mdi-phone</v-icon>
                              <span
                                   class="text-body-2 text2_color--text font-weight-bold"
                              >
                                   {{ selectcustomer.phone }}</span
                              >
                         </div>
                         <div v-if="selectcustomer.member">
                              <v-icon small>mdi-wallet-membership</v-icon>
                              <span
                                   class="text-body-2 text2_color--text font-weight-bold"
                              >
                                   {{
                                        selectcustomer.member[0]
                                             .customer_category_name
                                   }}</span
                              >
                         </div>
                         <!--<div v-if="apiInvoice.tag">
                              <v-icon small>mdi-tag</v-icon>
                              <span
                                   v-for="(tag, index) in apiInvoice.tag"
                                   :key="index"
                              >
                                   <v-chip
                                        class="ma-1"
                                        text-color="grey darken-1"
                                        label
                                        x-small
                                   >
                                        {{ tag }}</v-chip
                                   >
                              </span>
                         </div>-->
                    </div>

                    <div class="itembox">
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoiceno') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <span>{{ apiInvoice.invoiceno }}</span>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.pono') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <span>{{ apiInvoice.po }}</span>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoicedate') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <span>{{ apiInvoice.invoice_date }}</span>
                              </v-col>
                         </v-row>

                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.paybefore') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <span>{{ apiInvoice.due_date }}</span>
                              </v-col>
                         </v-row>

                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoicecurrency') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <span>{{ apiInvoice.currency }}</span>
                              </v-col>
                         </v-row>
                    </div>
               </div>

               <!--<div class="heading_bg px-3"> -->
               <v-container fluid class="heading_bg px-3">
                    <v-row>
                         <v-col cols="6">
                              <span class="mx-3"> &nbsp;&nbsp; </span>
                              <span class="caption heading_color--text">{{
                                   $t('components.product')
                              }}</span>
                         </v-col>
                         <v-col cols="2" class="text-right">
                              <span class="caption heading_color--text">{{
                                   $t('invoice.unitprice')
                              }}</span>
                         </v-col>
                         <v-col cols="2" class="text-right">
                              <span class="caption heading_color--text">{{
                                   $t('invoice.qty')
                              }}</span>
                         </v-col>
                         <v-col cols="2" class="text-right">
                              <span class="caption heading_color--text">{{
                                   $t('invoice.amount')
                              }}</span>
                              <span class="px-3">&nbsp;</span>
                         </v-col>
                    </v-row>
               </v-container>
               <!-- </div> -->

               <v-container fluid class="amList px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(item, index) in apiInvoice.product"
                         :key="index"
                    >
                         <v-col cols="6">
                              <div class="d-flex flex-row">
                                   <v-avatar
                                        size="15"
                                        color="heading_bg"
                                        class="mx-3 my-auto"
                                   >
                                        <span
                                             class="caption heading_color--text"
                                             >{{ index + 1 }}</span
                                        >
                                   </v-avatar>
                                   {{ item.type == 0 ? '↪' : '' }}
                                   <span>{{ item.name }}</span>
                              </div>
                              <div
                                   class="ml-10 mt-2 text2_color--text text-caption"
                              >
                                   <pre style="line-height: 0.8rem">{{
                                        item.description
                                   }}</pre>
                                   <span v-if="item.isPersist">{{
                                        $t('invoice.stockit')
                                   }}</span>
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div class="d-flex flex-row float-right">
                                   <span>$ {{ item.price }}</span>
                              </div>
                              <div
                                   v-if="item.orignal_price != null"
                                   class="float-right mt-2 text2_color--text text-caption"
                              >
                                   <pre
                                        style="line-height: 0.8rem"
                                        class="text-decoration-line-through"
                                        >{{ item.orignal_price }}</pre
                                   >
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div
                                   v-if="item.type != 3"
                                   class="d-flex flex-row float-right"
                              >
                                   <span>{{ item.qty }} {{ item.unit }}</span>
                              </div>
                              <div v-else class="text-right">
                                   <div>{{ item.qty }} {{ item.unit }}</div>
                                   <div
                                        class="mt-2 text2_color--text text-caption"
                                   >
                                        <pre style="line-height: 0.8rem"
                                             >{{ $t('invoice.include') }}: {{
                                                  item.credit
                                             }}</pre
                                        >
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div class="text-right">
                                   <span>$ {{ item.total }}</span>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="pa-7">
                    <v-row class="justify-space-between">
                         <v-col cols="7">
                              <div class="h6 font-weight-bold mb-1">
                                   {{ $t('global.remark') }}
                              </div>
                              <v-textarea
                                   v-model="apiInvoice.notes"
                                   outlined
                                   readonly
                                   no-resize
                                   hide-details
                                   auto-grow
                                   rows="3"
                              ></v-textarea>
                         </v-col>
                         <v-col cols="5" center>
                              <v-row dense align="end" class="px-2">
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3"
                                        >{{ $t('invoice.subtotal') }}</v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             readonly
                                             prefix="$"
                                             :value="formatCurrency(apiInvoice.subtotal)"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row
                                   dense
                                   align="end"
                                   class="px-2"
                                   v-if="apiCompanySetting.enable_tax"
                              >
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3"
                                        >{{ $t('invoice.tax') }}</v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             :value="formatCurrency(apiInvoice.tax)"
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             prefix="$"
                                             readonly
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row dense align="end" class="px-2">
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3"
                                        >{{ $t('invoice.discount') }}</v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             :value="formatCurrency(apiInvoice.discount)"
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             prefix="$"
                                             readonly
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row
                                   dense
                                   align="end"
                                   class="pa-2 heading_bg mt-3 rounded"
                              >
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3 font-weight-bold"
                                        >{{ $t('invoice.amount') }}</v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             readonly
                                             prefix="$"
                                             :value="formatCurrency(apiInvoice.amount)"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <div
                                   style="border:solid; border-width: 1px; 
                                   border-radius: 7px; margin-top:15px; border-color:#a1a1a1;
                                   padding:5px"
                              >
                                   <v-row
                                        dense
                                        align="end"
                                        class="pa-1 rounded"
                                   >
                                        <v-col
                                             cols="6"
                                             class="text-right text2_color--text text--darken-3 my-auto"
                                             >{{ $t('invoice.paid') }}</v-col
                                        >
                                        <v-col
                                             cols="6"
                                             class="text2_color--text"
                                        >
                                             <v-text-field
                                                  single-line
                                                  dense
                                                  hide-details
                                                  class="right-input"
                                                  readonly
                                                  prefix="$"
                                                  :value="formatCurrency(amountpaid)"
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>

                                   <v-row
                                        dense
                                        align="end"
                                        class="pa-1 rounded"
                                   >
                                        <v-col
                                             cols="6"
                                             class="text-right text2_color--text text--darken-3 my-auto"
                                             >{{ $t('invoice.unpaid') }}</v-col
                                        >
                                        <v-col
                                             cols="6"
                                             class="text2_color--text"
                                        >
                                             <v-text-field
                                                  single-line
                                                  dense
                                                  hide-details
                                                  class="right-input"
                                                  readonly
                                                  prefix="$"
                                                  :value="formatCurrency(apiInvoice.amount - amountpaid)"
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>
                              </div>
                         </v-col>
                    </v-row>
                    <v-row>
                         <div>
                              <div class="h6 font-weight-bold px-3">
                                   {{ $t('invoice.tandc') }}
                              </div>
                              <div class="px-3 pt-2">
                                   <pre
                                        style="white-space: pre-wrap; font-family:'Roboto', sans-serif;"
                                        >{{
                                        apiInvoice.tas
                                        }}</pre
                                   >
                              </div>
                         </div>
                    </v-row>
               </div>
          </div>
          <!--<div class="page-footer">This is footer content!</div>-->

          <div class="no_print mt-5"></div>
     </div>
</template>

<script>
     /* eslint-disable */
     import InvoiceService from '../../../utils/api/InvoiceService';
     //import SettingService from "../../../utils/api/SettingService";
     import BrowserSession from '@/utils/BrowserSession';

     //import _ from 'lodash';
     import html2canvas from 'html2canvas';
     import { jsPDF } from 'jspdf';

     //const config = require('@/config/config.js');

     //const date = new Date();
     /*const unformatter = (value) => {
          if (!value) return null;
          if (typeof value === 'string' || value instanceof String) {
               value = value.replace(',', '');
               value = Number(value);
               return value;
          } else {
               let str_value = value.toString();
               str_value = str_value.replace(',', '');
               str_value = Number(str_value);
               return str_value;
          }
     };*/
     const numformat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
     //const zeroPad = (num, places) => String(num).padStart(places, '0');

     export default {
          /*props: {
         _id: String
       },*/
          data: () => ({
               id: null,

               apiInvoice: {},

               //companyname: '',
               //companyaddress: '',
               //companylogo: '',

               invoiceprefix: '',
               invoiceid: '',

               po: '',
               notes: '',
               tas: '',

               selectcustomer: {},

               selectcurrency: 'HKD',
               invoicedate: '2022-01-20',
               duedate: '2022-01-20',

               apiItemList: [],
               apiServiceList: [],
               apiPackageList: [],
               apiInvoiceSetting: {},
               apiCompanySetting: {},
               apiRewardSetting: {},

               invoiceLineItems: [],

               subtotal: '0.00',

               isOverrideTax: false,
               tax: '0.00',
               trate: 0,

               isOverrideDiscount: false,
               discount: '0.00',
               memberdiscountrate: 0,

               amount: 0.0,
               amountpaid: 0.0,
               balance: 0.0,

               is_fail: false,
               error_message: '',

               url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,
               image: null,
          }),
          methods: {
               apiGetInvoice() {
                    InvoiceService.getInvoice(this.id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.apiInvoice = response.data.data;
                              }
                              console.log(this.apiInvoice);
                              this.selectcustomer = this.apiInvoice.billto;
                              this.apiInvoice.product.map((item) => {
                                   item.price = this.formatCurrency(item.price);
                                   item.total = this.formatCurrency(item.total);
                              });
                              /*this.apiInvoice.subtotal = numformat.format(
                                   this.apiInvoice.subtotal
                              );
                              this.apiInvoice.amount = numformat.format(
                                   this.apiInvoice.amount
                              );
                              this.apiInvoice.discount = numformat.format(
                                   this.apiInvoice.discount
                              );
                              this.apiInvoice.tax = numformat.format(
                                   this.apiInvoice.tax
                              );*/

                              this.calPaid();

                              window.document.title = this.apiInvoice.invoiceno;
                              //console.log(this.apiInvoice);
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetSettingPrefix() {
                    BrowserSession.getSession('setting', (data) => {
                         this.apiInvoiceSetting = data.find((d) => {
                              return d.key == 'invoice';
                         }).value;
                         this.tas = this.apiInvoiceSetting.terms;

                         this.apiCompanySetting = data.find((d) => {
                              return d.key == 'company';
                         }).value;
                    });

               },
               calPaid(){
                    this.amountpaid = 0.0;
                    this.apiInvoice.payment_list.map(payment=>{
                         if (
                                   payment.status == 'done' &&
                                   payment.payment_type != 'stock'
                              )
                                   this.amountpaid += payment.amount;
                    })
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               genPDF() {
                    /*var pdf = new jsPDF();
           pdf.html(document.body,
                   {callback: function(doc){
                     doc.output('dataurlnewwindow');
                     }
                   }
           )*/
                    let element = document.getElementById('pdfgencontent');
                    html2canvas(element, { allowTaint: true, useCORS: true }).then(
                         function (canvas) {
                              //console.log('width:' + canvas.width);
                              //console.log('height:' + canvas.height);

                              var image = canvas.toDataURL('image/jpeg');

                              var pdf = new jsPDF('p', 'mm', 'a4');

                              const imgWidth = 210;
                              //const pageHeight = 297;
                              const imgHeight =
                                   (canvas.height * imgWidth) / canvas.width;

                              pdf.addImage(image, 'JPEG', 0, 0, imgWidth, imgHeight);
                              //pdf.save('facture.pdf');
                              //window.open(URL.createObjectURL(pdf.output("blob")))
                              //document.write(pdf.output("blob"));
                              pdf.autoPrint();
                              pdf.output('dataurlnewwindow');
                         }
                    );
               },
               printWeb() {
                    window.print();
               },
               closeWeb() {
                    close();
               },
          },
          created() {
               //console.log('created');
               //console.log(this.$route.query._id);
               this.id = this.$route.query._id;

               this.apiGetInvoice();
               this.apiGetSettingPrefix();
          },
          components: {},
     };
</script>

<style scoped>
     @media print {
          #pdfgencontent {
               background: #fff;
               -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
               color-adjust: exact !important; /*Firefox*/
          }
          #pdf_invoice {
               display: none !important;
          }
          .tc0 {
               background-color: white !important;
               page-break-after: avoid;
               page-break-inside: avoid;
          }
          .tc1 {
               background-color: white !important;
               page-break-after: avoid;
               page-break-inside: avoid;
          }
          .no_print {
               display: none !important;
          }
          div.page-footer {
               display: block;
          }
     }
     @page {
          size: A4 portrait;
          margin-left: 0cm;
          margin-right: 0cm;
          orphans: 4;
          widows: 2;
     }

     .itembox {
          width: 300px;
          margin-top: auto;
          margin-bottom: auto;
     }
     #companybox {
          width: 300px;
          margin-top: auto;
          margin-bottom: auto;
     }
     .pdfa4screenl {
          /*width:  1220px;
       min-height: 1754px;*/
          width: 794px;
          /*height: 1123px;*/
     }
     .right-input input {
          text-align: right;
     }
     div.page-footer {
          text-align: center;
          height: 50px;
          font-size: 10px;
          opacity: 0.8;
          position: fixed;
          bottom: 0;
          width: 100%;
     }
     div.page-footer {
          display: none;
          height: 60px;
     }
</style>
