<template>
     <div class="pa-5">
          <div class="mb-10 d-flex flex-row justify-space-between">
               <span class="my-auto">{{ $t('global.remark') }}</span>
               <v-btn
                    @click="openAddDialog"
                    color="primary"
                    class="body-2 font-weight-bold"
               >
                    <v-icon size="18">mdi-plus</v-icon>
                    <span class="white--text">{{ $t('global.add') }}</span>
               </v-btn>
          </div>

          <v-row v-for="(log, index) in logobjs" :key="index" class="mb-3">
               <v-col class="my-auto">
                    <div class="body-2 font-weight-light pl-3">
                         <pre>{{ log.content }}</pre>
                    </div>
                    <div>
                         <span v-for="(tag, index) in log.tag" :key="index">
                              <v-chip class="ma-1" label small>
                                   {{ tag }}
                              </v-chip>
                         </span>
                    </div>
                    <div class="text-caption text2_color--text pt-2 pl-3">
                         @ {{ formatDateTime(log.last_update) }}
                    </div>
                    <!--<div class="text-caption text2_color--text pl-3">
                         建立於@ {{ formatDateTime(log.created_date) }}
                    </div>-->
               </v-col>
               <v-col cols="2" class="text-center my-auto">
                    <v-btn icon @click="modifyRecord(log._id)"
                         ><v-icon color="primary">mdi-pencil-circle</v-icon>
                    </v-btn>
               </v-col>
          </v-row>

          <v-dialog v-model="addDialog" persistent max-width="550px">
               <v-card class="pa-0">
                    <TabCustomerNoteAddComponent
                         v-if="addDialog"
                         :customerobj="customerobj"
                         :_id="selectedid"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
/*eslint-disable */
import CustomerProfileService from '@/utils/api/CustomerProfileService';
import TabCustomerNoteAddComponent from '@/views/customer/TabCustomerNoteAddView';

const moment = require('moment');

export default {
     props: { customerobj: Object },
     data: () => ({
          addDialog: false,
          logobjs: [],

          selectedid: null,

          is_fail: false,
          is_success: false,
          error_message: '',
          success_message: '',
     }),
     methods: {
          apiGetRecords() {
               CustomerProfileService.getCustomerLog(
                    'note',
                    this.customerobj._id
               )
                    .then((response) => {
                         if (response.data.status == 'success')
                              //console.log(response.data);
                              this.logobjs = response.data.data;
                    })
                    .catch((e) => {
                         console.log(e);
                    });
          },
          modifyRecord(id) {
               this.selectedid = id;
               this.addDialog = true;
          },
          formatDateTime(value) {
               return moment(value).format('YYYY-MM-DD HH:mm');
          },
          openAddDialog() {
               this.addDialog = true;
          },
          onClickCancel() {
               this.addDialog = false;
          },
          onClickInput(msg) {
               this.addDialog = false;

               this.success_message = msg;
               this.is_success = true;

               this.apiGetRecords();
          },
          onFail(msg) {
               this.addDialog = false;

               this.error_message = msg;
               this.is_fail = true;
          },
     },
     created() {
          if (this.customerobj != null) this.apiGetRecords();
          //console.log('note tab created!');
     },
     components: {
          TabCustomerNoteAddComponent,
     },
};
</script>
