import http from '../HttpConn';
var isObject = require('lodash/isObject');
class ProductService {
     getAll(page, limit) {
          return http.get('/product', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page, limit) {
          return http.get('/product/allactive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/product/count');
     }
     getProduct(id) {
          return http.get('/product/' + id);
     }
     /* eslint-disable */
     updateProduct(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          //let _id = ccobj._id;
          //delete ccobj["_id"];
          return http.put('/product/update/' + ccobj._id, ccobj);
     }
     addProduct(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/product/add', ccobj);
     }
     deleteProduct(id) {
          return http.delete('/product/delete/' + id);
     }
     /* eslint-enable */
}
export default new ProductService();
