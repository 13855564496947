<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3">
               <v-container fluid>
                    <v-row class="pa-5 my-auto" align="center">
                         <span class="text1_color--text">
                              {{ $t('report.employeereport') }}
                         </span>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div
                                   v-html="$t('report.employeereporttips')"
                              ></div>
                         </v-tooltip>
                         <v-spacer></v-spacer>
                         <v-autocomplete
                              v-model="selectuser"
                              :items="apiUserList"
                              item-text="name"
                              item-value="_id"
                              :label="$t('report.selectsales')"
                              style="max-width: 170px"
                              class="mx-2"
                              dense
                              hide-details
                              outlined
                              return-object
                         ></v-autocomplete>
                         <v-menu
                              v-model="showsdate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                        v-model="dateRangeText"
                                        :label="$t('report.daterange')"
                                        style="max-width: 220px"
                                        hide-details
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                   ></v-text-field>
                              </template>
                              <v-date-picker
                                   v-model="date_range"
                                   range
                              ></v-date-picker>
                         </v-menu>
                         <v-menu
                              bottom
                              origin="center center"
                              transition="scale-transition"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                             >mdi-arrow-down-drop-circle</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list>
                                   <v-list-item
                                        :input-value="datef == 'today'"
                                        @click="setFilterDate('today')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.today') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last7days'"
                                        @click="setFilterDate('last7days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past7day') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last30days'"
                                        @click="setFilterDate('last30days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past1month') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'thismonth'"
                                        @click="setFilterDate('thismonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.thismonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'lastmonth'"
                                        @click="setFilterDate('lastmonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.pastmonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold my-auto"
                              @click="getRecords"
                              :loading="isLoading"
                         >
                              <span class="white--text">
                                   {{ $t('report.executereport') }}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>
          </div>

          <div
               v-if="this.clicked"
               class="white rounded elevation-3 mx-3 mt-7 pt-5 pb-5"
          >
               <div class="d-flex flex-row">
                    <GChart
                         type="PieChart"
                         :data="ProductChartValue"
                         :options="{
                              title: 'Product Sales',
                              legend: { position: 'bottom' },
                              width: 250,
                              height: 350,
                              chartArea: {
                                   left: 80,
                                   top: 80,
                                   right: 10,
                                   width: '100%',
                                   height: '60%',
                              },
                         }"
                    />

                    <GChart
                         type="PieChart"
                         :data="ServiceChartValue"
                         :options="{
                              title: 'Service Sales',
                              legend: { position: 'bottom' },
                              width: 250,
                              height: 350,
                              chartArea: {
                                   left: 80,
                                   top: 80,
                                   right: 10,
                                   width: '100%',
                                   height: '60%',
                              },
                         }"
                    />

                    <GChart
                         type="PieChart"
                         :data="PackageChartValue"
                         :options="{
                              title: 'Package Sales',
                              legend: { position: 'bottom' },
                              width: 250,
                              height: 350,
                              chartArea: {
                                   left: 80,
                                   top: 80,
                                   right: 10,
                                   width: '100%',
                                   height: '60%',
                              },
                         }"
                    />
               </div>
               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="4">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span
                                        class="caption heading_color--text"
                                        >{{ $t('product.name') }}</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        class="caption heading_color--text"
                                        >{{$t('invoice.qty')}}</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        class="caption heading_color--text"
                                        >{{ $t('invoice.amount') }}</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        class="caption heading_color--text"
                                        >{{$t('product.cost')}}</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        class="caption heading_color--text"
                                        >{{$t('report.net')}}</span
                                   >
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(product, index) in productList"
                         :key="index"
                         class="px-3 fill-height"
                         align="center"
                    >
                         <v-col cols="4" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="text1_color--text text-body-2">
                                   {{ product.productname[0] }}
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div class="text1_color--text text-body-2">
                                   {{ product.count }}
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <div class="text1_color--text text-body-2">
                                   $ {{ displayformat(product.totalsales) }}
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <div class="text1_color--text text-body-2">
                                   ($ {{ displayformat(product.totalcost) }})
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <div class="text1_color--text text-body-2">
                                   $
                                   {{
                                        displayformat(
                                             product.totalsales -
                                                  product.totalcost
                                        )
                                   }}
                              </div>
                         </v-col>
                    </v-row>
               </v-container>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-end pa-5">
                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text pb-3"
                                   >{{ $t('report.productsummary') }}
                              </span>
                         </div>

                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text text-body-2">
                                   {{ $t('report.totalsales') }} :
                              </span>
                              <span
                                   class="text-decoration-underline font-weight-bold"
                              >
                                   $ {{ formatCurrency(total_sale[0]) }}</span
                              >
                         </div>

                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text text-body-2">
                                   {{$t('report.totalcost')}} :
                              </span>
                              <span
                                   class="text-decoration-underline font-weight-bold"
                              >
                                   ($ {{ formatCurrency(total_cost[0]) }})</span
                              >
                         </div>
                    </div>

                    <div class="d-flex flex-column align-end pa-5">
                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text pb-3"
                                   >{{ $t('report.servicesummary') }}
                              </span>
                         </div>
                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text text-body-2">
                                   {{ $t('report.totalsales') }} :
                              </span>
                              <span
                                   class="text-decoration-underline font-weight-bold"
                              >
                                   $ {{ formatCurrency(total_sale[1]) }}</span
                              >
                         </div>

                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text text-body-2">
                                   {{$t('report.totalcost')}} :
                              </span>
                              <span
                                   class="text-decoration-underline font-weight-bold"
                              >
                                   ($ {{ formatCurrency(total_cost[1]) }})</span
                              >
                         </div>
                    </div>

                    <div class="d-flex flex-column align-end pa-5">
                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text pb-3"
                                   >{{ $t('report.packagesummary') }}
                              </span>
                         </div>
                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text text-body-2">
                                   {{ $t('report.totalsales') }} :
                              </span>
                              <span
                                   class="text-decoration-underline font-weight-bold"
                              >
                                   $ {{ formatCurrency(total_sale[2]) }}</span
                              >
                         </div>

                         <div
                              style="width: 220px"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span class="text1_color--text text-body-2">
                                   {{$t('report.totalcost')}} :
                              </span>
                              <span
                                   class="text-decoration-underline font-weight-bold"
                              >
                                   ($ {{ formatCurrency(total_cost[2]) }})</span
                              >
                         </div>
                    </div>
               </div>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="d-flex flex-column align-end pa-5">
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2">
                              {{ $t('report.totalsales') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(total) }}
                         </span>
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{$t('report.totalcost')}} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              ($ {{ formatCurrency(cost) }})
                         </span>
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{$t('report.totalnet')}} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(total-cost) }}
                         </span>
                    </div>
               </div>
          </div>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import InvoiceService from '@/utils/api/InvoiceService';
     import { GChart } from 'vue-google-charts';
     //import _ from 'lodash';
     var isEmpty = require('lodash/isEmpty');
     var find = require('lodash/find');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     const moment = require('moment');

     export default {
          props: {},
          data: () => ({
               clicked: false,
               datef: 'lastmonth',
               date_range: [],
               showsdate: false,

               apiUserList: [],
               selectuser: null,

               productList: [],

               total: 0,
               cost: 0,

               ProductChartValue: [['Category', 'Sales']],
               ServiceChartValue: [['Category', 'Sales']],
               PackageChartValue: [['Name', 'Sales']],

               isLoading: false,

               total_sale: [0.0, 0.0, 0.0, 0.0],
               total_cost: [0.0, 0.0, 0.0, 0.0],

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',
          }),
          methods: {
               apiGetUserList() {
                    BrowserSession.getSession('user', (data) => {
                         this.apiUserList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               getRecords() {
                    if (!this.selectuser) {
                         this.error_message = 'Please select user';
                         this.is_fail = true;
                         return;
                    }
                    if (isEmpty(this.date_range) || this.date_range.length != 2) {
                         this.error_message = 'Please select date range';
                         this.is_fail = true;
                         return;
                    }

                    this.isLoading = true;
                    this.clicked = true;

                    this.total = 0;
                    this.cost = 0;

                    //console.log(this.date_range);
                    if (this.date_range[0] > this.date_range[1]) {
                         //exchange the date
                         let date_less = this.date_range[0];
                         this.date_range[0] = this.date_range[1];
                         this.date_range[1] = date_less;
                    }

                    InvoiceService.getSalesProductRank(
                         this.selectuser._id,
                         this.date_range
                    )
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   //console.log(response.data);
                                   this.productList = response.data.data;

                                   let p_cat = [];
                                   let s_cat = [];
                                   let pack_name = [];

                                   this.total_sale= [0.0, 0.0, 0.0, 0.0];
                                   this.total_cost = [0.0, 0.0, 0.0, 0.0];

                                   this.ProductChartValue = [['Category', 'Sales']];
                                   this.ServiceChartValue = [['Category', 'Sales']];
                                   this.PackageChartValue = [['Name', 'Sales']];

                                   //set chart
                                   this.productList.map((p) => {
                                        this.total += p.totalsales;
                                        this.cost += p.totalcost;
                                        if (p._id.type == 1) {
                                             //product
                                             let a = find(p_cat, {
                                                  category: p.category[0],
                                             });
                                             if (a) {
                                                  a.total += p.totalsales;
                                             } else {
                                                  p_cat.push({
                                                       category: p.category[0],
                                                       total: p.totalsales,
                                                  });
                                             }
                                             this.total_sale[0]+=p.totalsales;
                                             this.total_cost[0]+=p.totalcost;
                                        } else if (p._id.type == 2) {
                                             //service
                                             let a = find(s_cat, {
                                                  category: p.category[0],
                                             });
                                             if (a) {
                                                  a.total += p.totalsales;
                                             } else {
                                                  s_cat.push({
                                                       category: p.category[0],
                                                       total: p.totalsales,
                                                  });
                                             }
                                             this.total_sale[1]+=p.totalsales;
                                             this.total_cost[1]+=p.totalcost;
                                        } else if (p._id.type == 3) {
                                             //package
                                             let a = find(pack_name, {
                                                  name: p.productname[0],
                                             });
                                             if (a) {
                                                  a.total += p.totalsales;
                                             } else {
                                                  pack_name.push({
                                                       productname: p.productname[0],
                                                       total: p.totalsales,
                                                  });
                                             }
                                             this.total_sale[2]+=p.totalsales;
                                             this.total_cost[2]+=p.totalcost;
                                        } else if (p._id.type == 99) {
                                             //other
                                        } else {
                                             //package usage
                                             this.total_cost[4]+=p.totalcost;
                                        }
                                   });

                                   p_cat.map((p) => {
                                        if (p.category) {
                                             this.ProductChartValue.push([
                                                  p.category,
                                                  p.total,
                                             ]);
                                        } else {
                                             this.ProductChartValue.push([
                                                  '沒分類',
                                                  p.total,
                                             ]);
                                        }
                                   });
                                   s_cat.map((p) => {
                                        if (p.category) {
                                             this.ServiceChartValue.push([
                                                  p.category,
                                                  p.total,
                                             ]);
                                        } else {
                                             this.ServiceChartValue.push([
                                                  '沒分類',
                                                  p.total,
                                             ]);
                                        }
                                   });
                                   pack_name.map((p) => {
                                        this.PackageChartValue.push([
                                             p.productname,
                                             p.total,
                                        ]);
                                   });

                                   this.isLoading = false;
                              }
                         })
                         .catch((e) => {
                              this.isLoading = false;
                              console.log(e);
                         });
               },
               displayformat(total) {
                    return numformat.format(total);
               },
               setFilterDate(datef) {
                    this.datef = datef;

                    if (datef == 'today') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last7days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(6, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last30days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(29, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'thismonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().startOf('month').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'lastmonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment()
                                   .subtract(1, 'month')
                                   .startOf('month')
                                   .format('YYYY-MM-DD'),
                              moment()
                                   .subtract(1, 'month')
                                   .endOf('month')
                                   .format('YYYY-MM-DD'),
                         ];
                         return;
                    }
               },
          },
          computed: {
               dateRangeText() {
                    return this.date_range.join(' 至 ');
               },
          },
          components: {
               GChart,
          },
          created() {
               this.apiGetUserList();

               let x = new Date();
               x.setDate(0);
               this.date_range[1] = x.toISOString().slice(0, 10);
               x.setDate(1);
               this.date_range[0] = x.toISOString().slice(0, 10);

               this.datef = 'lastmonth';
          },
     };
</script>
