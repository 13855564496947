<template>
     <div>
          <div class="pa-3 d-flex justify-space-between heading_bg" id="event">
               <div>
                    <div
                         class="heading_color--text my-auto font-weight-bold text-h6"
                    >
                         {{ appointment_obj.customer_name }}
                    </div>
                    <div class="heading_color--text my-auto font-weight-bold">
                         <v-icon
                              small
                              color="rgba(0, 0, 0, 0.54)"
                              style="margin-right:2px"
                         >
                              mdi-cellphone-basic
                         </v-icon>
                         {{ appointment_obj.customer_phone }}
                    </div>
               </div>
               <div>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         icon
                         @click="$emit('onClickCancel', null);"
                    >
                         <v-icon>mdi-close</v-icon>
                    </v-btn>
               </div>
          </div>

          <v-divider></v-divider>

          <div class="white pa-3">
               <div class="mb-3 d-flex justify-space-between">
                    <v-checkbox
                         v-model="isCompleted"
                         :label="$t('appointment.completed')"
                         hide-details
                         class="mt-0 mb-2"
                         @change="updateEventComplete"
                    ></v-checkbox>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         icon
                         @click="deleteEvent"
                    >
                         <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
               </div>
               <v-divider></v-divider>

               <div class="font-weight-bold mt-3 mb-1">
                    {{ $t('appointment.detail')}}
               </div>
               <div class="mb-1">
                    <v-icon
                         small
                         color="rgba(0, 0, 0, 0.54)"
                         style="margin-right:2px"
                    >
                         mdi-badge-account
                    </v-icon>
                    {{ appointment_obj.staff_name }}
               </div>
               <div class="mb-3">
                    <v-icon
                         small
                         color="rgba(0, 0, 0, 0.54)"
                         style="margin-right:2px"
                    >
                         mdi-file-document-check
                    </v-icon>
                    {{ appointment_obj.service_name || $t('appointment.normalbooking') }}
               </div>
               <!--<div class="mb-3">
                    <v-icon
                         small
                         color="rgba(0, 0, 0, 0.54)"
                         style="margin-right:2px"
                    >
                         mdi-cellphone-basic
                    </v-icon>
                    {{ appointment_obj.customer_phone }}
                    <span class="text-caption" style="color:grey">
                         ({{ appointment_obj.customer_name }})</span
                    >
               </div>-->
               <v-divider></v-divider>

               <div class="font-weight-bold mt-3 mb-1">
                    {{ $t('appointment.resource')}}
               </div>
               <div class="mb-3">
                    <v-icon
                         small
                         color="rgba(0, 0, 0, 0.54)"
                         style="margin-right:2px"
                    >
                         mdi-sofa-single
                    </v-icon>
                    {{ appointment_obj.resource_name || '-' }}
               </div>
               <v-divider></v-divider>

               <div class="d-flex flex-row mt-3 mb-3">
                    <div style="width:170px;">
                         <div class="font-weight-bold mt-2 mb-1">
                              {{$t('appointment.starttime')}}
                         </div>
                         <div class="pl-1 text-caption" style="color:grey">
                              {{ appointment_obj.appointment_formatedstartdate }}
                         </div>
                         <div class="pl-1">
                              {{ appointment_obj.appointment_formatedstart }}
                         </div>
                    </div>
                    <v-divider vertical class="ma-1"></v-divider>
                    <div class="ml-2">
                         <div class="font-weight-bold mt-2 mb-1">
                              {{ $t('appointment.endtime') }}
                         </div>
                         <div class="pl-1 text-caption" style="color:grey">
                              {{ appointment_obj.appointment_formatedenddate }}
                         </div>
                         <div class="pl-1">
                              {{ appointment_obj.appointment_formatedend }}
                         </div>
                    </div>
               </div>
               <v-divider></v-divider>

               <div class="font-weight-bold mt-3 mb-3">
                    {{ $t('global.remark') }}
               </div>
               <div
                    class="pa-2 blue-grey lighten-5 rounded-lg"
                    style="min-height:50px; width:100%; overflow-wrap: break-word;"
               >
                    {{ appointment_obj.remark }}
               </div>
          </div>

          <div class="pa-5 d-flex justify-space-between">
               <v-btn
                    color="primary"
                    class="body-2 font-weight-bold"
                    @click="modifyEvent"
                    min-width="100%"
                    :disabled="isCompleted"
               >
                    <span>{{$t('global.modify')}}</span>
               </v-btn>
               <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>

          <ConfirmDialog ref="confirm" />
     </div>
</template>

<script>
     import AppointmentService from '@/utils/api/AppointmentService';
     import ConfirmDialog from '@/views/components/ConfirmDialog';

     const moment = require('moment');
     export default({
          props: {
            _id: String
          },
          data:()=>({
            appointment_obj: {},

            isCompleted: false,
            appointment_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            appointment_show: false,

            appointment_start: '2021-06-10T10:00:00',
            appointment_end: '2021-06-10T10:40:00'
          }),
          methods: {
           apiGetEvent(){
             AppointmentService.getAppointment(this._id).then(response=>{
               //console.log(response.data.data);
               this.appointment_obj = response.data.data;
               this.appointment_obj.appointment_formateddate = moment(this.appointment_obj.appointment_date).format('ddd, Do MMM YYYY');
               this.appointment_obj.appointment_formatedstart = moment(this.appointment_obj.start_at).format('LT');
               this.appointment_obj.appointment_formatedstartdate = moment(this.appointment_obj.start_at).format('MMMM DD');
               this.appointment_obj.appointment_formatedend = moment(this.appointment_obj.end_at).format('LT');
               this.appointment_obj.appointment_formatedenddate = moment(this.appointment_obj.end_at).format('MMMM DD');
               this.isCompleted = this.appointment_obj.completed;
             }).catch(e=>{
               console.log(e);
             })
           },
           modifyEvent(){
            //open modify view
            this.$emit('onClickModify');
           },
           updateEventComplete(){
            AppointmentService.updateAppointment({_id: this._id, completed: this.isCompleted}).then(response=>{
              //console.log(response.data);
              if(response.data.status == 'success')
               this.$emit('onClickCompleted', this.isCompleted);
            }).catch(e=>{
              console.log(e);
            })
           },
           async deleteEvent(){
            if ( await this.$refs.confirm.open(
                        this.$t('global.confirm'),
                        this.$t('components.confirmdeletemsg'))
               ) {
                AppointmentService.deleteAppointment(this._id).then(response=>{
                  //console.log(response.data);
                  if(response.data.status == 'success')
                    this.$emit('onClickInput', this.$t('global.successPrefix'));
                }).catch(e=>{
                  console.log(e);
                })
               }
           },
          },
          mounted(){
           this.apiGetEvent();
          },
          components:{
            ConfirmDialog
          }
        })
</script>
