//import config from '@/config/config';
//import BrowserSession from '@/utils/BrowserSession';
//import _ from 'lodash';

var intersection = require('lodash/intersection');

class Permission {
     //get target key
     getkey(targetkey) {
          let crmobj =
               localStorage.getItem('crmlogin') ||
               sessionStorage.getItem('crmlogin');
          let localS = JSON.parse(crmobj);
          return localS[targetkey];
     }

     //check if side menu have permission
     isSideMenuHavePermission(disabled_page, menukey, pms = null) {
          if (!menukey) return true;

          //console.log(disabled_page);
          if (intersection(disabled_page, menukey).length > 0) {
               //console.log('This page is disabled : ' + menukey);
               return false;
          }

          //no pms passing, just return false
          if (!pms) return false;

          return intersection(pms, menukey).length != 0;
     }

     isPageHavePermission(disabled_page, pagekey) {
          //1. check global environment config contain disable page?
          if (intersection(disabled_page, pagekey).length > 0) return false;

          let crmobj =
               localStorage.getItem('crmlogin') ||
               sessionStorage.getItem('crmlogin');
          let localS = JSON.parse(crmobj);
          //console.log(localS['pms']);
          //console.log(pagekey);
          //2. check user got permission?
          return intersection(localS['pms'], pagekey).length == 0
               ? false
               : true;
     }

     isActionHavePermission(disabled_page, actionkey) {
          //1. check global environment config contain disable page?
          if (intersection(disabled_page, actionkey).length > 0) return false;

          //2. check user got permission?
          let crmobj =
               localStorage.getItem('crmlogin') ||
               sessionStorage.getItem('crmlogin');
          let localS = JSON.parse(crmobj);
          return intersection(localS['pms'], actionkey).length == 0
               ? false
               : true;
     }
}

export default new Permission();
