<template>
     <v-app :style="{ background: $vuetify.theme.themes.light.background }">
          <SideBar v-if="!$route.meta.hideSidebar" />
          <NavBar v-if="!$route.meta.hideNavbar" />
          <v-main
               style="width: 100%; background-color: #edf2f9; max-width: 1366px;"
          >
               <router-view></router-view>
          </v-main>
          <div style="text-align: center; font-size: 12px; color:grey">
               {{footer}}
          </div>
     </v-app>
</template>

<script>
     import NavBar from '@/views/components/NavBar';
     import SideBar from '@/views/components/SideBar';
     const config = require("./config/config.js");

     export default {
          name: 'App',
          components: { NavBar, SideBar },
          data: () => ({
               footer: config.footer
          }),
     };
</script>
