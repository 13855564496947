<template>
     <div>
          <div class="pa-5 d-flex justify-space-between" id="event">
               <span
                    v-if="!_appointmentid"
                    class="heading_color--text my-auto"
                    >{{ $t('appointment.addevent') }}</span
               >
               <span
                    v-else
                    class="heading_color--text my-auto"
                    >{{ $t('appointment.modifyevent') }}</span
               >
               <div class="d-flex flex-row">
                    <!--<v-text-field
                         v-model="searchvalue"
                         label="搜尋用戶"
                         single-line
                         dense
                         outlined
                         hide-details="auto"
                         style="max-width: 250px"
                         @click:append="searchphone"
                         @keydown="searchphonekb"
                         :messages="notfoundmsg"
                    >
                         <template v-slot:append>
                              <v-fade-transition leave-absolute>
                                   <v-progress-circular
                                        v-if="isSearching"
                                        class="my-auto mr-1"
                                        size="20"
                                        color="info"
                                        indeterminate
                                   ></v-progress-circular>
                                   <v-icon v-else>mdi-magnify</v-icon>
                              </v-fade-transition>
                         </template>
                    </v-text-field>-->
               </div>
          </div>

          <v-divider></v-divider>

          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="pa-7 post_bg d-flex flex-row"
          >
               <div style="max-width:400px;">
                    <div class="d-flex flex-row" v-if="!showAddNewCustomer">
                         <v-autocomplete
                              id="customercombo"
                              v-model="customer"
                              :loading="customerlistloading"
                              :items="customerList"
                              :search-input.sync="searchCustomer"
                              item-text="name_and_phone"
                              item-value="_id"
                              :label="$t('appointment.searchbynameandphone')"
                              :disabled="_appointmentid!=null"
                              dense
                              outlined
                              return-object
                              background-color="#ffffff"
                         ></v-autocomplete>
                         <v-btn
                              color="primary"
                              class="ml-3 body-2 font-weight-bold"
                              @click="showAddNewCustomer = true"
                              :disabled="_appointmentid!=null"
                         >
                              <span>{{ $t('customer.addcustomer') }}</span>
                         </v-btn>
                    </div>
                    <div v-if="showAddNewCustomer" class="d-flex flex-col">
                         <v-container
                              class="mb-5"
                              style="
                                        border-width: 1px;
                                        border-style: solid;
                                        border-color: grey;
                                        border-radius: 5px;
                                        background-color: #ffffff;
                                   "
                         >
                              <v-row class="pa-2">
                                   <v-col cols="6" class="pt-1 px-2 py-1">
                                        <v-text-field
                                             v-model="newcustomername"
                                             :label="'*'+$t('customer.name')"
                                             hide-details="auto"
                                             dense
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                                   <v-col cols="6" class="pt-1 px-2 py-1">
                                        <v-text-field
                                             v-model="newcustomerphone"
                                             :label="'*'+$t('customer.phone')"
                                             hide-details="auto"
                                             dense
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>
                              <!--<v-row>
                         <v-col cols="6" class="pt-5 px-2 py-1"> </v-col>
                         <v-col style="display:flex; justify-content: end;">
                              <v-btn
                                   color="primary"
                                   class="mr-3 body-2 font-weight-bold"
                                   @click="showAddNewCustomer = true"
                              >
                                   <span>添加</span>
                              </v-btn>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <v-btn
                                   color="grey"
                                   class="body-2 font-weight-bold"
                                   text
                                   @click="showAddNewCustomer = false"
                              >
                                   <span>取消</span>
                              </v-btn>
                         </v-col>
                    </v-row>-->
                         </v-container>

                         <v-btn
                              color="primary"
                              fab
                              x-small
                              style="margin-top:10px; margin-left:10px;"
                              @click="showAddNewCustomer = false"
                         >
                              <v-icon>mdi-close</v-icon>
                         </v-btn>
                    </div>

                    <v-select
                         v-model="service"
                         :items="serviceList"
                         item-text="name"
                         item-value="_id"
                         :label="$t('appointment.bookservice')"
                         prepend-inner-icon="mdi-phone-clock"
                         outlined
                         dense
                         return-object
                         background-color="#ffffff"
                    ></v-select>

                    <div class="d-flex flex-row">
                         <v-select
                              v-model="staff"
                              :items="staffList"
                              item-text="name"
                              item-value="_id"
                              :label="'*'+$t('components.employee')"
                              prepend-inner-icon="mdi-account-tie"
                              :rules="requiredRules"
                              outlined
                              dense
                              return-object
                              @change="changestaff"
                              :loading="loadingAppointment"
                              background-color="#ffffff"
                         ></v-select>
                         <div class="px-2"></div>
                         <v-select
                              v-model="resource"
                              :items="resourceList"
                              item-text="name"
                              item-value="_id"
                              :label="$t('appointment.bookresource')"
                              prepend-inner-icon="mdi-sofa-single"
                              outlined
                              dense
                              return-object
                              @change="changeresource"
                              :loading="loadingAppointment"
                              clearable
                              @click:clear="clearresource"
                              background-color="#ffffff"
                         ></v-select>
                    </div>

                    <div id="user_calendar" :class="staff?'':'disable_css'">
                         <div
                              class="ml-2 d-flex flex-row justify-space-between"
                              style="color:grey; font-size:17px;"
                         >
                              <span>{{ $t('appointment.dateandtime') }}</span>
                              <div style="margin-right:15px">
                                   <span
                                        style="height: 7px; width: 7px; background-color: rgb(17, 210, 59);
                                        border-radius: 50%; display: inline-block;"
                                   ></span
                                   ><span
                                        style="font-size:12px"
                                        >{{ $t('appointment.free') }}</span
                                   >
                                   <span
                                        style="height: 7px; width: 7px; background-color: rgb(224, 224, 24);
                                        border-radius: 50%; display: inline-block;
                                        margin-left: 7px"
                                   ></span
                                   ><span
                                        style="font-size:12px"
                                        >{{ $t('appointment.available') }}</span
                                   >
                                   <span
                                        style="height: 7px; width: 7px; background-color: rgb(215, 35, 35);
                                        border-radius: 50%; display: inline-block;
                                        margin-left: 7px"
                                   ></span
                                   ><span
                                        style="font-size:12px"
                                        >{{ $t('appointment.busy') }}</span
                                   >
                              </div>
                         </div>
                         <v-date-picker
                              ref="vdp"
                              v-model="sdate"
                              width="370"
                              class="ma-2"
                              landscape
                              no-title
                              :events="functionEvents"
                              @change="changeDate"
                              :picker-date.sync="pickerDate"
                              :min="new Date().toISOString().substr(0,10)"
                         ></v-date-picker>
                         <div class="d-flex flex-row align-center">
                              <VueCtkDateTimePicker
                                   v-model="appointment_start"
                                   id="stime"
                                   :label="'*' + $t('appointment.starttime')"
                                   format="HH:mm"
                                   formatted="HH:mm"
                                   onlyTime
                                   minute-interval="15"
                                   class="ma-2"
                                   no-header
                                   :error="isDateStartError"
                                   @input="set_start_time"
                              >
                              </VueCtkDateTimePicker>
                              <v-divider vertical></v-divider>

                              <VueCtkDateTimePicker
                                   v-model="appointment_end"
                                   id="etime"
                                   :label="'*' + $t('appointment.endtime')"
                                   format="HH:mm"
                                   formatted="HH:mm"
                                   onlyTime
                                   minute-interval="15"
                                   class="ma-2"
                                   no-header
                                   :error="isDateEndError"
                                   @input="set_end_time"
                              >
                              </VueCtkDateTimePicker>
                         </div>
                    </div>

                    <v-textarea
                         v-model="remark"
                         :label="$t('global.remark')"
                         auto-grow
                         outlined
                         hide-details
                         rows="3"
                         background-color="#ffffff"
                    ></v-textarea>
                    <!--<v-checkbox
                         v-model="isSMSnotify"
                         label="SMS提醒"
                         hide-details
                         disable
                    ></v-checkbox>-->
               </div>

               <div
                    id="calendar_div"
                    style=" 
                    width: 260px; height: 100%; margin-left:20px; "
                    v-if="showdetail"
               >
                    <v-calendar
                         v-if="!loadingAppointment"
                         ref="calendarRef"
                         id="cal_id"
                         v-model="calendar"
                         color="primary"
                         type="category"
                         :categories="categories"
                         category-show-all
                         category-days="1"
                         first-interval="18"
                         interval-minutes="30"
                         interval-count="24"
                         interval-height="22"
                         locale="zh-hk"
                         :events="events"
                         @mousedown:time="onClickCalendar"
                    ></v-calendar>

                    <div style="font-size: 13px;margin-top:7px">
                         <span style="color:#6aadd7">■</span
                         >{{ $t('appointment.bookedevent') }}
                         <span style="color:#d5b077; padding-left:15px;">■</span
                         >{{ $t('appointment.bookedresource') }}
                    </div>
                    <div style="font-size: 13px;">
                         <span style="color:#1976d2">■</span
                         >{{ $t('appointment.thisbooking') }}
                    </div>
               </div>
          </v-form>

          <v-divider></v-divider>

          <div class="pa-5 d-flex justify-space-between" id="event">
               <span></span>
               <div>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addEvent"
                         v-if="!_appointmentid"
                    >
                         <span>{{ $t('global.add') }}</span>
                    </v-btn>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="modifyEvent"
                         v-else
                    >
                         <span>{{ $t('global.modify')}}</span>
                    </v-btn>

                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         class="body-2 font-weight-bold"
                         text
                         @click="$emit('onClickCancel', null)"
                    >
                         <span>{{ $t('global.close') }}</span>
                    </v-btn>
               </div>
          </div>

          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import _ from 'lodash';
     import AppointmentService from '@/utils/api/AppointmentService';
     import CustomerService from '@/utils/api/CustomerService';
     import UserService from '@/utils/api/UserService';
     import ResourceService from '@/utils/api/ResourceService';
     import ServiceService from '@/utils/api/ServiceService';
     import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
     import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

     /* eslint-disable */
     const moment = require('moment');
     var isEmpty = require('lodash/isEmpty');
     const zeroPad = (num, places) => String(num).padStart(places, '0')
     const colors= ['#6aadd7', '#d5b077', '#1976d2'];

     export default {
          props: {
               _appointmentid: String,
               _resource: Object,
               _date: String,
               _time: Object,
               _staff: Object
          },
          data: () => ({
               valid: true,
               customerRules: [(v) => !!v || 'Customer is required'],
               requiredRules: [(v) => !!v || 'This Field is required'],

               orig_appointment: null,

               /*dh: [
                    '00',
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '20',
                    '21',
                    '22',
                    '23',
               ],*/
               calendar_start: 18,
               calendar_length: 30,

               customer: null,
               customerList: [],
               service: null,
               serviceList: [],
               staff: null,
               staffList: [],
               resource: null,
               resourceList: [],
               remark: '',

               //format: 2022-06-15T00:05:00
               sdate: (new Date()).toISOString().substr(0, 10),
               appointment_start: null,
               appointment_end: null,

               isDateStartError: false,
               isDateEndError: false,

               isSMSnotify: false,

               showAddNewCustomer: false,
               newcustomername: null,
               newcustomerphone: null,

               isSearching: false,
               searchvalue: null,

               notfoundmsg: '',

               showdetail: false,
               loadingAppointment: false,
               //categories: [this.$t('components.employee'), this.$t('appointment.resource')],
               categories: ['', ''],
               events: [],


               tmp_event: {
                    _id: 'tmp_event',
                    //name: this.$t('appointment.newbooking'),
                    name: '',
                    start: '',
                    interval: 60,
                    end: '',
                    color: colors[2],
                    timed: true,
                    category: '',
                    singleline: false,
                    eventSummary: '',
                    resource: null,
               },
               calendar: '',

               statistic_events: [],
               statistic_color:['#11D23B', '#E0E018', '#D72323'],
               pickerDate: null,
               customerlistloading: false,
               searchCustomer: null,

               is_fail: false,
               error_message: '',
          }),
          methods: {
               async apiGetStuff() {
                    let resp = await UserService.getAllActiveUserResource();
                    this.staffList = resp.data.data;
                    resp = await ResourceService.getAllActive();
                    this.resourceList = resp.data.data;
                    resp = await ServiceService.getAllActive();
                    this.serviceList = resp.data.data;

                    this.categories = [this.$t('components.employee'), this.$t('appointment.resource')];
                    this.tmp_event.name = this.$t('appointment.newbooking');
               },
               apiGetStaffAppointmentMonthStat(YYYYDD=null){
                    if(!this.staff){
                         return;
                    }

                    let format_obj = {
                         staffid: this.staff._id,
                         from_date: (new Date()).toISOString().substr(0, 10),
                         to_date: moment(new Date()).endOf('month').toISOString().substr(0,10)
                    }
                    if(YYYYDD){
                         format_obj = {...format_obj, ...{
                                   from_date: (new Date(YYYYDD+'-01')).toISOString().substr(0, 10),
                                   to_date: moment(new Date(YYYYDD+'-01')).endOf('month').toISOString().substr(0,10)
                              }
                         }
                    }

                    AppointmentService.getMonthlyStatistic(format_obj).then(response=>{
                         //console.log(response.data);
                         this.statistic_events = response.data.data.map(i=>{
                              //return {day: i._id.substr(8,2), status: (i.count<3)?0:(i.count<6)?1:2}
                              return {day: i._id.substr(8,2), status: (i.total_booked_mins<120)?0:(i.total_booked_mins<320)?1:2}
                         })
                    }).catch(e=>{
                         console.log(e);
                    })
               },
               apiGetAppointment(id){
                    AppointmentService.getAppointment(id).then(response=>{
                         if(response.data.status != 'success'){
                              this.is_fail = true;
                              this.error_message = 'Cannot load this appointment, pls try again later!';
                              return;
                         }
                         //console.log(response.data);
                         this.orig_appointment = response.data.data;

                         //1. set this to corresponding field
                         this.service = _.find(this.serviceList, {_id: this.orig_appointment.serviceid});
                         this.staff = _.find(this.staffList, {_id: this.orig_appointment.staffid});
                         this.resource = _.find(this.resourceList, {_id: this.orig_appointment.resourceid});
                         this.remark = this.orig_appointment.remark;
                         this.sdate = (this.orig_appointment.appointment_date).substr(0, 10);
                         this.calendar = this.sdate;
                         let start = new Date(this.orig_appointment.start_at);
                         let end = new Date(this.orig_appointment.end_at);

                         this.appointment_start = zeroPad(start.getHours(),2)+':'+zeroPad(start.getMinutes(),2);
                         this.appointment_end = zeroPad(end.getHours(),2)+':'+zeroPad(end.getMinutes(),2);

                         if(!_.isEmpty(this.orig_appointment.customerid)){
                              //get customer
                              CustomerService.getCustomer(this.orig_appointment.customerid).then(response=>{
                                   //console.log(response.data.data);
                                   this.customer = response.data.data;
                                   this.customer.name_and_phone = this.customer.name+ " ("+this.customer.phone+")";
                                   this.customerList = [this.customer];
                              }).catch(e=>{
                                   console.log(e);
                              })
                         }else{
                              this.showAddNewCustomer = true;
                              this.newcustomername = this.orig_appointment.customer_name;
                              this.newcustomerphone = this.orig_appointment.customer_phone;
                         }

                         //2. get all other appointments with this staff and resource, remove current appointment
                         this.apiGetAppointments();
                         //3. set this appointment to tmp event


                    }).catch(e=>{
                         console.log(e);
                    })
               },
               apiGetAppointments() {
                    if(!this.staff && !this.resource && this.loadingAppointment){
                         return;
                    }

                    this.loadingAppointment = true;
                    let query = [];
                    if(this.staff){
                         //console.log(this.staff.name);
                         this.categories[0] = this.staff.name;
                         query.push({staffid: this.staff._id});
                    }
                    if(this.resource){
                         //console.log(this.resource.name);
                         this.categories[1] = this.resource.name;
                         query.push({resourceid: this.resource._id});
                    }

                    AppointmentService.getAppointmentByFilter({
                         startdate: this.sdate, enddate: this.sdate,
                         filter:query, filterlogic:'or'
                    }).then(response=>{
                         if (response.data.status == 'success') {
                              this.showcalendar();
                              this.formatEvent(response.data.data);
                              this.loadingAppointment = false;
                              this.showdetail = true;
                         }
                    }).catch(e=>{
                         console.log(e);
                         this.loadingAppointment = false;
                    })
               },
               /* eslint-disable */
               searchphone(event) {
                    this.isSearching = true;
                    CustomerService.searchCustomer({ value: this.searchvalue })
                         .then((response) => {
                              if (isEmpty(response.data.data)) {
                                   this.notfoundmsg = this.$t('appointment.customernotfoundmsg');
                                   this.isSearching = false;
                                   return;
                              }
                              this.customer = response.data.data[0];
                              this.isSearching = false;
                              this.notfoundmsg = null;
                              this.searchvalue = null;
                              this.$refs.refToCustomerCombo.focus();
                         })
                         .catch((e) => {
                              console.log(e);
                         });
                    //console.log(event);
               },
               searchphonekb(kbevent) {
                    if (kbevent.keyCode == 13) {
                         this.isSearching = true;
                         CustomerService.searchCustomer({ value: this.searchvalue })
                              .then((response) => {
                                   //console.log(response.data.data);
                                   if (isEmpty(response.data.data)) {
                                        this.notfoundmsg = this.$t('appointment.customernotfoundmsg');
                                        this.isSearching = false;
                                        return;
                                   }
                                   this.customer = response.data.data[0];
                                   this.isSearching = false;
                                   this.notfoundmsg = null;
                                   this.searchvalue = null;
                                   this.$refs.refToCustomerCombo.focus();
                              })
                              .catch((e) => {
                                   console.log(e);
                                   this.notfoundmsg = this.$t('appointment.customernotfoundmsg');
                              });
                    }
                    //console.log(kbevent);
               },

               formatEvent(data) {
                    this.events = [];
                    //by staff
                    data.map((appointment) => {
                         this.events.push({
                              _id: appointment._id,
                              name: appointment.customer_name,
                              start: new Date(appointment.start_at),
                              end: new Date(appointment.end_at),
                              color: colors[0],
                              timed: true,
                              category: appointment.staff_name,
                              singleline: false,
                              eventSummary: appointment.remark,
                         });
                    });
                    //by resource
                    data.map((appointment) => {
                         if (appointment.resourceid) {
                              this.events.push({
                                   _id: appointment._id,
                                   name: appointment.staff_name,
                                   start: new Date(appointment.start_at),
                                   end: new Date(appointment.end_at),
                                   color: colors[1],
                                   timed: true,
                                   category: appointment.resource_name,
                                   singleline: false,
                                   eventSummary: appointment.remark,
                              });
                         }
                    });
                    //console.log(this.events);

                    //if modify appointment, update the event
                    this.updateOrigEvent();

                    //check any tmp event? if have, move it to events to show up
                    if(this.tmp_event && this.tmp_event.start && this.tmp_event.end){
                         this.events.push(this.tmp_event);
                         if(this.tmp_event.resource){
                              this.events.push({...this.tmp_event, ...{category: this.tmp_event.resource.name}});
                         }
                    }

                    this.addHeaderClass();
               },
               changestaff(){
                    if(this._appointmentid){
                         this.orig_appointment.staff_name = this.staff.name;
                         this.orig_appointment.staffid = this.staff._id;
                    }
                    //console.log(this.staff)
                    this.apiGetAppointments();

                    this.apiGetStaffAppointmentMonthStat();

                    if(!this.showdetail){
                         this.$emit('onExpand');
                         this.showdetail = true;
                    }

                    //check if start and end time set, add tmp event
                    if(this.appointment_start){
                         this.set_start_time();
                    }
               },
               changeresource(){
                    this.tmp_event.resource = this.resource;

                    this.apiGetAppointments();

                    if(!this.showdetail){
                         this.$emit('onExpand');
                         this.showdetail = true;
                    }

               },
               clearresource(){
                    if(this.tmp_event){
                         this.tmp_event.resource = null;
                    }

                    //console.log('clear resource');
                    this.resource = null;
                    //console.log(this.resource)
                    this.categories[1] = this.$t('appointment.resource');
                    this.apiGetAppointments();
               },
               changeDate(d){
                    console.log(d);
                    if(!this.showdetail)
                         return;

                    if(this._appointmentid){
                         //change current appointment date
                         this.orig_appointment.appointment_date = new Date(d);
                         this.orig_appointment.start_at = new Date(d+'T'+this.appointment_start+':00');
                         this.orig_appointment.end_at = new Date(d+'T'+this.appointment_end+':00');
                    }

                    this.calendar = d;
                    this.apiGetAppointments();
               },
               set_start_time(){
                    let sdate = new Date(this.sdate.substr(0,4),
                                        Number(this.sdate.substr(5,2)) -1,
                                        this.sdate.substr(8,2),
                                        this.appointment_start.substr(0,2),
                                        this.appointment_start.substr(3,2));
                    let edate = moment(sdate).add(this.tmp_event.interval, 'minutes').toDate();

                    this.appointment_end = zeroPad(edate.getHours(), 2) + ':' + zeroPad(edate.getMinutes(), 2);

                    if(this._appointmentid){
                         this.orig_appointment.start_at = sdate;
                         this.orig_appointment.end_at = edate;
                         this.updateOrigEvent();
                         return;
                    }

                    this.setTmpEvent(sdate, edate);
               },
               set_end_time(){
                    let sdate = new Date(this.sdate.substr(0,4),
                                        Number(this.sdate.substr(5,2)) -1,
                                        this.sdate.substr(8,2),
                                        this.appointment_start.substr(0,2),
                                        this.appointment_start.substr(3,2));
                    let edate = new Date(this.sdate.substr(0,4),
                                        Number(this.sdate.substr(5,2))-1,
                                        this.sdate.substr(8,2),
                                        this.appointment_end.substr(0,2),
                                        this.appointment_end.substr(3,2));

                    //check minutes between sdate and edate
                    var m_sdate = moment(sdate);
                    var m_edate = moment(edate);

                    if(m_sdate.diff(m_edate)>0){
                         this.isDateEndError = true;
                         return;
                    }else{
                         this.isDateEndError = false;
                         //set interval
                         this.tmp_event.interval = m_edate.diff(m_sdate, 'minutes');
                    }

                    if(this._appointmentid){
                         this.orig_appointment.start_at = sdate;
                         this.orig_appointment.end_at = edate;
                         this.orig_appointment.interval = m_edate.diff(m_sdate, 'minutes');
                         this.updateOrigEvent();
                         return;
                    }

                    this.setTmpEvent(sdate, edate);
               },
               addEvent() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    if( this.showAddNewCustomer ){
                         if(_.isEmpty(this.newcustomername) || _.isEmpty(this.newcustomerphone)){
                              this.error_message = this.$t('appointment.missingcustomerinfomsg')
                              this.is_fail = true;
                              return;
                         }
                    }else{
                         if(_.isEmpty(this.customer)){
                              this.error_message = this.$t('appointment.missingcustomerinfomsg')
                              this.is_fail = true;
                              return;
                         }
                    }
                    if (isEmpty(this.appointment_start)) {
                         this.isDateStartError = true;
                         return;
                    }
                    if (isEmpty(this.appointment_end)) {
                         this.isDateEndError = true;
                         return;
                    }

                    if(isEmpty(this.staff)){
                         this.error_message = this.$t('appointment.missingstaffmsg');
                         this.is_fail = true;
                         return;
                    }

                    //build object
                    let new_event = {};
                    if(this.showAddNewCustomer){
                         new_event.customerid = null;
                         new_event.customer_name = this.newcustomername;
                         new_event.customer_phone = this.newcustomerphone;
                    }else{
                         new_event.customerid = this.customer._id;
                         new_event.customer_name = this.customer.name;
                         new_event.customer_phone = this.customer.phone;
                    }
                    new_event.appointment_date = this.sdate.substr(
                         0,
                         10
                    );
                    new_event.start_at = this.sdate + 'T' +this.appointment_start+ ':00.000+08:00';
                    new_event.end_at = this.sdate + 'T' +this.appointment_end + ':00.000+08:00';

                    new_event.serviceid = this.service ? this.service._id : null;
                    new_event.service_name = this.service ? this.service.name : null;
                    new_event.expected_price = this.service
                         ? this.service.price
                         : null;
                    new_event.service_duration = this.tmp_event.interval;

                    new_event.staffid = this.staff._id;
                    new_event.staff_name = this.staff.name;

                    new_event.resourceid = this.resource ? this.resource._id : null;
                    new_event.resource_name = this.resource
                         ? this.resource.name
                         : null;

                    new_event.remark = this.remark;
                    new_event.sms = this.isSMSnotify;
                    new_event.status = 'active';

                    AppointmentService.addAppointment(new_event)
                         .then((response) => {
                              //console.log(response);
                              this.$emit(
                                   'onClickInput',
                                   this.$t('global.successPrefix')+':' + new_event.customer_name
                              );
                         })
                         .catch((e) => {
                              console.log(e);
                              this.$emit(
                                   'onFailAction',
                                   this.$t('global.failPrefix')+':' + e.message
                              );
                         });

                    //this.$emit('onClickCancel', null);
               },
               modifyEvent(){
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    if (isEmpty(this.appointment_start)) {
                         this.isDateStartError = true;
                         return;
                    }
                    if (isEmpty(this.appointment_end)) {
                         this.isDateEndError = true;
                         return;
                    }
                    if(isEmpty(this.staff)){
                         this.error_message = this.$t('appointment.missingstaffmsg');
                         this.is_fail = true;
                         return;
                    }

                    //build object
                    let modify_event = {};
                    modify_event._id = this._appointmentid;
                    modify_event.appointment_date = this.sdate.substr(0,10);
                    modify_event.start_at = this.sdate + 'T' +this.appointment_start+ ':00.000+08:00';
                    modify_event.end_at = this.sdate + 'T' +this.appointment_end + ':00.000+08:00';

                    modify_event.serviceid = this.service ? this.service._id : null;
                    modify_event.service_name = this.service ? this.service.name : null;
                    modify_event.expected_price = this.service
                         ? this.service.price
                         : null;
                    modify_event.service_duration = this.orig_appointment.interval;

                    modify_event.staffid = this.staff._id;
                    modify_event.staff_name = this.staff.name;

                    modify_event.resourceid = this.resource ? this.resource._id : null;
                    modify_event.resource_name = this.resource
                         ? this.resource.name
                         : null;

                    modify_event.remark = this.remark;
                    //modify_event.sms = this.isSMSnotify;
                    modify_event.status = 'active';

                    AppointmentService.updateAppointment(modify_event)
                         .then((response) => {
                              if(response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix')
                                   );
                              else
                                   this.$emit(
                                        'onFailAction',
                                        this.$t('global.failPrefix')+':' + e.message
                                   );
                         })
                         .catch((e) => {
                              console.log(e);
                              this.$emit(
                                   'onFailAction',
                                   this.$t('global.successPrefix')+':' + e.message
                              );
                         });

                    //this.$emit('onClickCancel', null);
               },
               showcalendar(){
                    if(document.getElementById('calendar_div')){
                         document.getElementById('calendar_div').style.display = 'block';
                    }
               },
               functionEvents(date){
                    if(!this.staff)
                         return;

                    //console.log(moment(date).diff(moment(), 'days'));

                    if( moment(date).diff(moment(), 'days') <0){
                         return false;
                    }

                    const [,, day] = date.split('-');
                    let x = _.find(this.statistic_events, {day: day});
                    if(x)
                         return this.statistic_color[x.status];
                    else
                         return this.statistic_color[0];
               },
               onClickCalendar(tms){
                    let min = 0;
                    if(tms.minute < 30 ){
                         min = 0;
                    }else{
                         min = 30;
                    }

                    let sdate = new Date(tms.year,tms.month-1,tms.day,tms.hour,min);
                    let edate = moment(sdate).add(this.tmp_event.interval, 'minutes').toDate();

                    this.appointment_start = zeroPad(sdate.getHours(),2) + ":" + zeroPad(sdate.getMinutes(),2);
                    this.appointment_end = zeroPad(edate.getHours(), 2) + ":" + zeroPad(edate.getMinutes(), 2);

                    //modify appointment
                    if(this._appointmentid){
                         this.orig_appointment.start_at = sdate;
                         this.orig_appointment.end_at = edate;

                         this.updateOrigEvent();
                         return;
                    }

                    //set event obj
                    let te = {
                         start: sdate,
                         end: edate,
                         category: this.staff.name,
                         eventSummary: '',
                    }
                    //console.log(te);

                    //search any previous created tmp_event on events list
                    this.events = _.filter(this.events, function(e){ return e._id!='tmp_event'});

                    this.tmp_event = {...this.tmp_event, ...te};
                    this.events.push(this.tmp_event);

                    //check if book resource?
                    if(this.resource){
                         this.tmp_event.resource = this.resource;
                         this.events.push({...this.tmp_event, ...{category: this.resource.name}});
                    }
               },

               setTmpEvent(sdate, edate){
                    if(!this.staff)
                         return;

                    //add new tmp events record to this staff
                    let te = {
                         start: sdate,
                         end: edate,
                         category: this.staff.name,
                         eventSummary: '',
                    }
                    //console.log(tmp_event);
                    //search any previous created tmp_event on events list
                    this.events = _.filter(this.events, function(e){ return e._id!='tmp_event'});

                    this.tmp_event = {...this.tmp_event, ...te};
                    this.events.push(this.tmp_event);

                    //check if book resource?
                    if(this.resource){
                         this.events.push({...this.tmp_event, ...{category: this.resource.name}});
                    }
               },

               updateOrigEvent(){
                    if(!this.orig_appointment)
                         return;

                    //remove this orig_appointment from events list
                    this.events = _.filter(this.events, (e)=>{ return e._id!= this.orig_appointment._id});
                    let ev = {
                         _id: this.orig_appointment._id,
                         name: this.orig_appointment.customer_name,
                         start: new Date(this.orig_appointment.start_at),
                         end: new Date(this.orig_appointment.end_at),
                         color: colors[2],
                         timed: true,
                         category: this.orig_appointment.staff_name,
                         singleline: false,
                         eventSummary: this.orig_appointment.remark,
                    };
                    //convert orig_appointment and push to event to show up
                    this.events.push(ev);

                    //check if book resource?
                    if(this.resource){
                         this.events.push({...ev,
                                   ...{name: this.orig_appointment.staff_name,
                                        category: this.resource.name}});
                    }
               },

               async apiSearchCustomer(val){
                    if (this.customerList.length > 0) return
                    this.customerlistloading = true;
                    //go api search for match
                    let  searchquery = { customerphone: val, customername: val }

                    try {
                         let result = await AppointmentService.searchCustomer(1, 20, searchquery);
                         //console.log(result);
                         let x = result.data.data.map(data=>{
                              data.name_and_phone = data.name+ " ("+data.phone+")";
                              return data;
                         })
                         this.customerList = x;
                         //console.log(this.customerList);
                         this.customerlistloading = false;
                    } catch (e) {
                         console.log(e);
                         this.is_fail = true;
                         this.error_message = this.$t('global.errorgettingdata');
                         this.customerlistloading = false;
                    }

               },

               addHeaderClass(){
                    const intervalID = setInterval(() => {
                         if (document.querySelector('#cal_id')) {
                              //console.log('The element exists');

                              if(this.staff){
                                   //console.log('add user header class');
                                   //document.getElementsByClassName('v-calendar-category__category')[0].style.background = '#6aadd7';
                                   document.querySelectorAll('#cal_id .v-calendar-category__category')[0].style.background = '#6aadd7';
                              }

                              if(this.resource){
                                   //console.log('add resource header class');
                                   //document.getElementsByClassName('v-calendar-category__category')[1].style.background = '#d5b077';
                                   document.querySelectorAll('#cal_id .v-calendar-category__category')[1].style.background = '#d5b077';
                              }

                              clearInterval(intervalID);
                         }
                    }, 30);
               }
          },
          created() {
               //console.log('event add on created');
               //this.apiGetStuff();
          },
          async mounted () {
               await this.apiGetStuff();
               //console.log("event add on mounted");
               if(!_.isEmpty(this._appointmentid)){
                    this.apiGetAppointment(this._appointmentid);
                    return;
               }

               if(!_.isEmpty(this._date)){
                    this.sdate = this._date;
                    this.calendar = this._date;
               }

               if(!_.isEmpty(this._staff)){
                    this.staff = this._staff;
                    //console.log(this.staff);
                    this.changestaff();
               }
               if(!_.isEmpty(this._resource)){
                    this.resource = this._resource;
                    //console.log(this.resource);
                    this.changeresource();
               }

               if(!_.isEmpty(this._time)){
                    //console.log(this._time);
                    this.onClickCalendar(this._time);
               }

          },
          components: {
               VueCtkDateTimePicker,
          },
          watch: {
               pickerDate (val) {
                    //console.log('month was change!')
                    //console.log(val);
                    //load the appointment statistic
                    this.apiGetStaffAppointmentMonthStat(val);
               },
               searchCustomer (val) {
                    val && val !== this.customer && this.apiSearchCustomer(val)
               },
          }
     };
</script>
<style>
     #cal_id .v-calendar-category__column-header{
          max-width:100px;
          min-width: 100px;
     }
     #cal_id .v-calendar-daily__day{
          max-width:100px;
          min-width: 100px;
     }

     .select_cal_user{
          background:#6aadd7 !important;
     }
     .select_cal_resource{
          background:#d5b077 !important;
     }

     #cal_id .v-calendar-category__column-header .v-calendar-category__category{
          background:#7b7b7b;
          font-size: 12px;
          margin: 3px;
          color: white;
          border-radius: 10px;
     }

     /*#cal_id .v-calendar-category__column-header:nth-child(1) .v-calendar-category__category{
          font-size: 12px;
          background:#6aadd7;
          margin: 3px;
          color: white;
          border-radius: 10px;
     }
     #cal_id .v-calendar-category__column-header:nth-child(2) .v-calendar-category__category{
          font-size: 12px;
          background:#d5b077;
          margin: 3px;
          color: white;
          border-radius: 10px;
     }*/

     #user_calendar{
          border:solid; border-width: 1px;
          border-color:grey; border-radius: 7px;
          padding:5px; margin-bottom: 22px;
     }
     .disable_css{
          pointer-events: none;
          opacity: 0.4;
     }
</style>
