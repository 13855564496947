//const axios = require("axios");
//const config = require("../config/config.js");
import http from './HttpConn';

class UploadFilesService {
     upload(file, onUploadProgress) {
          let formData = new FormData();
          formData.append('upload', file);

          return http.post('/uploadImage', formData, {
               headers: {
                    'Content-Type': 'multipart/form-data',
               },
               onUploadProgress,
          });
     }
     uploadLogo(file, onUploadProgress) {
          let formData = new FormData();
          formData.append('upload', file);

          return http.post('/uploadLogo', formData, {
               headers: {
                    'Content-Type': 'multipart/form-data',
               },
               onUploadProgress,
          });
     }
     uploadConfig(file, type = 'firebase', onUploadProgress) {
          let formData = new FormData();
          formData.append('upload', file);
          //formData.append('type', configtype);

          return http.post('/uploadConfig/' + type, formData, {
               headers: {
                    'Content-Type': 'multipart/form-data',
               },
               onUploadProgress,
          });
     }
     uploadCSV(file, type = 'customer', onUploadProgress) {
          let formData = new FormData();
          formData.append('upload', file);

          return http.post('/uploadCSV/' + type, formData, {
               headers: {
                    'Content-Type': 'multipart/form-data',
               },
               onUploadProgress,
          });
     }
     getImageList() {
          return http.get('/imageCount');
     }
     removeImage(filename) {
          return http.get('/imageRemove/' + filename);
     }
}
export default new UploadFilesService();
