<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span class="heading_color--text">{{ title }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div class="px-10 py-5 post_bg">
               <div>
                    <v-chip
                         v-for="tag in targetList"
                         :key="tag._id"
                         class="ma-2"
                         color="primary"
                         outlined
                         close
                         @click:close="addRemoveTag(tag)"
                    >
                         {{ tag.name }}
                    </v-chip>
               </div>

               <div class="mt-3" v-if="removeTagList.length > 0">
                    <v-chip
                         v-for="tag in removeTagList"
                         :key="tag._id"
                         class="ma-2"
                         color="red"
                         outlined
                    >
                         <span class="text-decoration-line-through">{{
                              tag.name
                         }}</span>
                    </v-chip>
               </div>
          </div>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="closeDialog"
                    >
                         {{ $t('global.update') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>

<script>
     import TagService from '../../utils/api/TagService';

     export default {
          props: {
               tagtype: String,
          },
          data: () => ({
               targetList: [],
               title: '',
               removeTagList: [],
          }),
          methods: {
               apiGetAllStuff() {
                    TagService.getTagsByGroup(this.tagtype)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.targetList = response.data.data;
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                              return;
                         });
                    //console.log(this.targetList);
               },
               async closeDialog() {
                    while (this.removeTagList.length > 0) {
                         let tag = this.removeTagList.pop();
                         let response = await TagService.deleteTag(tag._id);
                         //console.log(response);
                         if (response.status != 200) {
                              //console.log(response);
                              this.$emit(
                                   'onFailAction',
                                   this.$t('global.failPrefix') +':' + this.title
                              );
                         }
                    }
                    this.$emit('onSuccessAction', this.$t('global.successPrefix') + ':' + this.title );
               },
               cancel() {
                    this.$emit('onClickCancel', null);
               },
               addRemoveTag(tag) {
                    tag.isRemove = 'remove';
                    this.removeTagList.push(tag);
                    this.targetList.splice(this.targetList.indexOf(tag), 1);

                    //console.log(tag._id+" going to remove");
                    //console.log(this.targetList);
                    //console.log(this.removeTagList);
               },
          },
          created() {
               this.apiGetAllStuff();
               if (this.tagtype == 'category') this.title = this.$t('product.category');
               if (this.tagtype == 'brand') this.title = this.$t('product.brand');
               if (
                    this.tagtype == 'unit' ||
                    this.tagtype == 'sunit' ||
                    this.tagtype == 'punit'
               )
                    this.title = this.$t('product.unit');
          },
     };
</script>

<style>
     .tagremove {
          background-color: grey;
     }
</style>
