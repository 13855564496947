<template>
     <div>
          <v-card v-if="this.$route.name !== 'login'">
               <v-navigation-drawer
                    v-model="drawer"
                    :mini-variant.sync="mini"
                    permanent
                    app
                    color="#edf2f9"
                    floating
                    width="200"
               >
                    <v-app-bar-nav-icon
                         right
                         class="ma-3"
                         @click.stop="mini = !mini"
                    ></v-app-bar-nav-icon>

                    <v-list dense nav>
                         <div>
                              <v-card class="mb-2" color="transparent" outlined>
                                   <div class="mx-auto text-center">
                                        <v-avatar
                                             :size="mini ? '36px' : '55px'"
                                        >
                                             <v-img :src="this.avatar"></v-img>
                                        </v-avatar>

                                        <div v-if="!mini">
                                             <v-btn @click="showUser" text>
                                                  <h4>{{ this.username }}</h4>
                                             </v-btn>
                                             <div
                                                  class="caption heading_color--text"
                                             >
                                                  {{ this.title }}
                                             </div>
                                        </div>
                                   </div>
                              </v-card>
                         </div>
                         <v-divider class="my-2"></v-divider>
                         <div v-for="item in items" :key="item.title">
                              <v-list-item
                                   link
                                   :to="item.route"
                                   v-if="
                                        !item.sub && havePermit(item.accesskey)
                                   "
                              >
                                   <v-list-item-icon>
                                        <v-icon>
                                             {{ item.icon }}
                                        </v-icon>
                                   </v-list-item-icon>
                                   <v-list-item-content>
                                        <v-list-item-title
                                             class="link_color--text"
                                        >
                                             {{ item.title }}
                                        </v-list-item-title>
                                   </v-list-item-content>
                              </v-list-item>
                              <v-list-group
                                   v-else-if="
                                        item.sub && havePermit(item.accesskey)
                                   "
                                   :key="item.title"
                                   no-action
                              >
                                   <template v-slot:activator>
                                        <v-list-item-icon>
                                             <v-icon>
                                                  {{ item.icon }}
                                             </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title
                                             class="link_color--text"
                                        >
                                             {{ item.title }}
                                        </v-list-item-title>
                                   </template>
                                   <div
                                        v-for="subItem in item.sub"
                                        :key="subItem.title"
                                   >
                                        <v-list-item
                                             v-if="
                                                  havePermit(subItem.accesskey)
                                             "
                                             :to="subItem.route"
                                        >
                                             <v-list-item-title
                                                  class="link_color--text ml-2 pl-9"
                                                  >{{
                                                       subItem.title
                                                  }}</v-list-item-title
                                             >
                                        </v-list-item>
                                   </div>
                              </v-list-group>
                         </div>
                    </v-list>
               </v-navigation-drawer>
          </v-card>

          <v-dialog v-model="addDialog" persistent max-width="590px">
               <v-card class="pa-0">
                    <UserAddComponent
                         v-if="addDialog"
                         :_id="uid"
                         enableModifyPassword="1"
                         @onClickCancel="cancelInput"
                         @onClickInput="successInput"
                         @onFailAction="failInput"
                    />
               </v-card>
          </v-dialog>
          <SessionDialog ref="sessiondialog" />

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     //import UserService from "@/utils/api/UserService";
     import BrowserSession from '@/utils/BrowserSession';
     import Permission from '@/utils/Permission';
     import SessionDialog from '@/views/components/SessionDialog';
     import UserAddComponent from '@/views/user/UserAddView';

     //const config = require('@/config/config.js');

     export default {
          data() {
               return {
                    publicPath: window.location.origin,
                    username: '',
                    title: '',
                    avatar: '',
                    pms: [],
                    drawer: true,
                    mini: false,
                    addDialog: false,
                    uid: null,
                    is_success: false,
                    is_fail: false,
                    success_message: '',
                    error_message: '',
                    items: [
                         {
                              title: this.$t('components.dashboard'),
                              icon: 'mdi-view-dashboard',
                              route: '/home',
                              accesskey: ['BIMeL'],
                         },
                         {
                              title: this.$t('components.appointment'),
                              icon: 'mdi-timetable',
                              route: '', //accesskey: ['TiAFN', 'xtDEL'],
                              accesskey: ['TiAFN'],
                              sub: [
                                   {
                                        title: this.$t('components.appointment'),
                                        route: '/appointment',
                                        accesskey: ['TiAFN', 'xtDEL'],
                                   },
                                   /*{title:'簽到', route:'/voucher'}*/
                              ],
                         },
                         {
                              title: this.$t('components.customer'),
                              icon: 'mdi-account-group',
                              route: '', //accesskey:['xDWyh', 'HVvyR', 'LLVWE', 'Mpxzf'],
                              accesskey: ['xDWyh'],
                              sub: [
                                   {
                                        title: this.$t('components.customer'),
                                        route: '/customer',
                                        accesskey: ['xDWyh', 'HVvyR'],
                                   },
                                   {
                                        title: this.$t('components.customerlv'),
                                        route: '/customercategory',
                                        accesskey: ['LLVWE', 'Mpxzf'],
                                   },
                              ],
                         },
                         {
                              title: this.$t('components.product'),
                              icon: 'mdi-package-variant',
                              route: '', //accesskey: ['fNaDt', 'mLaeD', 'tJnMZ', 'kbwnQ', 'ZvkrC', 'ChZpz'],
                              sub: [
                                   {
                                        title: this.$t('components.product'),
                                        route: '/product',
                                        accesskey: ['fNaDt', 'mLaeD'],
                                   },
                                   {
                                        title: this.$t('components.service'),
                                        route: '/service',
                                        accesskey: ['tJnMZ', 'kbwnQ'],
                                   },
                                   {
                                        title: this.$t('components.package'),
                                        route: '/package',
                                        accesskey: ['ZvkrC', 'ChZpz'],
                                   },
                              ],
                         },
                         {
                              title: this.$t('components.sales'),
                              icon: 'mdi-cash-register',
                              route: '', //accesskey: ['TWwvR', 'ZCIMR', 'pbKyf', 'Vbgoh'],
                              accesskey: ['pbKyf'],
                              sub: [
                                   {
                                        title: this.$t('components.quotation'),
                                        route: '/quotation',
                                        accesskey: ['TWwvR', 'ZCIMR'],
                                   },
                                   {
                                        title: this.$t('components.invoice'),
                                        route: '/invoice',
                                        accesskey: ['pbKyf', 'Vbgoh'],
                                   },
                              ],
                         },
                         {
                              title: this.$t('components.transaction'),
                              icon: 'mdi-cash-sync',
                              route: '', //accesskey: ['ONyFq', 'uamho', 'GTgMN', 'ovyeA'],
                              accesskey: ['ONyFq'],
                              sub: [
                                   {
                                        title: this.$t('components.payment'),
                                        route: '/payment',
                                        accesskey: ['ONyFq', 'uamho'],
                                   },
                                   /*{title:'退款', route:'/refund'},*/
                                   {
                                        title: this.$t('components.expense'),
                                        route: '/expense',
                                        accesskey: ['GTgMN', 'ovyeA'],
                                   },
                              ],
                         },
                         {
                              title: this.$t('components.report'),
                              icon: 'mdi-chart-bar',
                              route: '', //accesskey: ['QqseP', 'LARTa', 'shsJo', 'UHQDQ', 'XxvFG', 'sIWzT', 'TJmMw'],
                              sub: [
                                   {
                                        title: this.$t('components.salereport'),
                                        route: '/salesReport',
                                        accesskey: ['QqseP'],
                                   },
                                   {
                                        title: this.$t('components.statement'),
                                        route: '/statementReport',
                                        accesskey: ['LARTa'],
                                   },
                                   {
                                        title: this.$t('components.customersummary'),
                                        route: '/customerSalesReport',
                                        accesskey: ['shsJo'],
                                   },
                                   {
                                        title: this.$t('components.productreport'),
                                        route: '/productReport',
                                        accesskey: ['UHQDQ'],
                                   },
                                   {
                                        title: this.$t('components.paymentreport'),
                                        route: '/paymentReport',
                                        accesskey: ['XxvFG'],
                                   },
                                   {
                                        title: this.$t('components.salesreport'),
                                        route: '/salesProductReport',
                                        accesskey: ['sIWzT'],
                                   },
                                   {
                                        title: this.$t('components.salessummary'),
                                        route: '/salesSummaryReport',
                                        accesskey: ['sIWzT'],
                                   },
                                   {
                                        title: this.$t(
                                             'components.commissionreport'
                                        ),
                                        route: '/commissionReport',
                                        accesskey: ['TJmMw'],
                                   },
                              ],
                         },
                         //{ title: '庫存', icon: 'mdi-dolly', route:'/inventory'},
                         {
                              title: this.$t('components.employee'),
                              icon: 'mdi-toolbox',
                              route: '',
                              accesskey: ['lwaRc'],
                              sub: [
                                   {
                                        title: this.$t('components.employee'),
                                        route: '/user',
                                        accesskey: ['lwaRc'],
                                   },
                                   {
                                        title: this.$t('components.permissiongroup'),
                                        route: '/userrole',
                                        accesskey: ['lwaRc'],
                                   },
                              ],
                         },
                         {
                              title: this.$t('components.app'),
                              icon: 'mdi-cellphone-cog',
                              route: '',
                              accesskey: ['cRPPN'],
                              sub: [
                                   {
                                        title: this.$t('components.blog'),
                                        route: '/blog',
                                        accesskey: ['OEVMz'],
                                   },
                                   {
                                        title: this.$t('components.pushmsg'),
                                        route: '/pm',
                                        accesskey: ['cRPPN'],
                                   },
                                   {
                                        title: this.$t('components.segment'),
                                        route: '/customergroup',
                                        accesskey: ['LLVWE', 'Mpxzf'],
                                   },
                                   {
                                        title: this.$t('components.booking'),
                                        route: '/booking',
                                        accesskey: ['KvVMJ', 'VmDPa'],
                                   }
                              ],
                         },
                         {
                              title: this.$t('components.setting'),
                              icon: 'mdi-cogs',
                              route: '/setting',
                              accesskey: ['ukWQg'],
                         },
                    ],
               };
          },
          methods: {
               GetUserLoginDetail() {
                    if (localStorage.getItem('crmlogin')) {
                         let localS = JSON.parse(localStorage.crmlogin);
                         this.username = localS.name;
                         this.title = localS.title;
                         this.avatar = this.publicPath + '/avatar/' + localS.avatar;
                         this.pms = localS.pms;
                         this.uid = localS._id;

                         this.$user.username = localS.name;
                         //console.log(this.$user.username);
                         return;
                    }
                    if (sessionStorage.getItem('crmlogin')) {
                         let localS = JSON.parse(sessionStorage.crmlogin);
                         this.username = localS.name;
                         this.title = localS.title;
                         this.avatar = this.publicPath + '/avatar/' + localS.avatar;
                         this.pms = localS.pms;
                         this.uid = localS._id;

                         this.$user.username = localS.name;
                         //console.log(this.$user.username);
                         return;
                    }
               },
               havePermit(menukeys) {
                    let csetting = BrowserSession.getSession('config');
                    //console.log(csetting);
                    //console.log(this.pms);

                    let disabled_page_array = csetting.disabled_page;
                    return Permission.isSideMenuHavePermission(
                         disabled_page_array,
                         menukeys,
                         this.pms
                    );

               },
               showUser() {
                    this.addDialog = true;
               },

               cancelInput() {
                    this.addDialog = false;
                    this.is_success = false;
                    this.is_fail = false;
                    this.success_message = '';
                    this.error_message = '';
               },
               successInput(msg) {
                    this.addDialog = false;
                    this.success_message = msg;
                    this.is_success = true;
                    this.is_fail = false;
               },
               failInput(err_msg) {
                    this.addDialog = false;
                    this.is_success = false;
                    this.error_message = err_msg;
                    this.is_fail = true;
               },
          },
          created() {
               if (this.$route.name !== 'login')
                    this.$auth.checkExpiry(
                         () => {
                              this.GetUserLoginDetail();
                              //Permission.readConfigValue();
                         },
                         () => {
                              this.$refs.sessiondialog.open();
                         }
                    );
          },
          mounted() {
          },
          components: {
               SessionDialog,
               UserAddComponent,
          },
     };
</script>

<style scoped>
     .v-list-item__icon {
          margin-right: 15px !important;
     }
</style>
