<template>
     <v-container fluid fill-height>
          <v-row align="center" justify="center">
               <v-flex xs12 sm8 md4>
                    <v-card class="elevation-12">
                         <v-toolbar dark color="primary">
                              <v-toolbar-title>登入AM CRM系統</v-toolbar-title>
                         </v-toolbar>
                         <v-card-text>
                              <form ref="form" @submit.prevent="login()">
                                   <v-text-field
                                        v-model="username"
                                        name="username"
                                        label="登入名稱"
                                        type="text"
                                        required
                                        hide-details
                                   ></v-text-field>
                                   <v-text-field
                                        v-model="password"
                                        name="password"
                                        label="密碼"
                                        type="password"
                                        required
                                        hide-details
                                   ></v-text-field>
                                   <v-checkbox
                                        v-model="saveAcc"
                                        label="保存登入資料"
                                   ></v-checkbox>
                                   <v-btn
                                        type="submit"
                                        class="mt-4"
                                        color="primary"
                                        value="log in"
                                        :loading="isLoading"
                                        >Login</v-btn
                                   >
                              </form>
                         </v-card-text>
                    </v-card>
               </v-flex>
          </v-row>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </v-container>
</template>

<script>
     /* eslint-disable */
     import UserService from '@/utils/api/UserService';
     import BrowserSession from '@/utils/BrowserSession';
     //import _ from 'lodash';
     var pick = require('lodash/pick');

     const config = require('@/config/config.js');
     const login_expiry_sec = 60 * 60 * 24 * 365;

     export default {
          data: () => ({
               username: '',
               password: '',
               saveAcc: false,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',
               retry: 0,

               isLoading: false,

               localstorageheader: [
                    'avatar',
                    'login_name',
                    'name',
                    'title',
                    'token',
                    'pms',
                    '_id',
               ],
               localS: null,
          }),
          methods: {
               login() {
                    if (this.retry >= 5) {
                         this.is_fail = true;
                         this.error_message = '錯誤登入過多，帳戶暫停！';
                         return;
                    }
                    //got localStorage, check if token expiry, going to renew pms
                    if (this.localS) {
                         //eslint-disable-next-line
                         UserService.checkTokenAndRenew(this.localS)
                              .then((response) => {
                                   //console.log(response);
                                   //get new pms
                                   if (response.data.status != 'success') {
                                        localStorage.removeItem('crmlogin');
                                        this.$cookies.remove('token');
                                        this.password = '';
                                        this.localS = null;

                                        this.error_message = '認証錯誤！請重新登入';
                                        this.is_fail = true;
                                        return;
                                   }

                                   this.localS = JSON.parse(localStorage.crmlogin);
                                   this.username = this.localS.login_name;
                                   this.password = 'ThisIsSecretKey';
                                   this.saveAcc = true;
                                   this.$cookies.set(
                                        'token',
                                        this.localS.token,
                                        login_expiry_sec,
                                        null,
                                        //config.cookiedomain
                                        //process.env.VUE_APP_CDOMAIN
                                        window.location.hostname
                                   );

                                   this.getSetting();

                                   //redirect to dashboard
                                   //document.location.href = '/home';
                                   //return;
                              })
                              .catch((err) => {
                                   //expiry or jwt error
                                   console.log(err);
                                   localStorage.removeItem('crmlogin');
                                   this.$cookies.remove('token');
                                   this.password = '';
                                   this.localS = null;

                                   this.error_message = '登入過期，請重新登入！';
                                   this.is_fail = true;
                                   return;
                              });
                         return;
                    }

                    //login process
                    UserService.checkLogin({
                         username: this.username,
                         password: this.password,
                    })
                         .then((response) => {
                              //console.log(response);
                              if (response.data.status != 'success') {
                                   this.is_fail = true;
                                   this.error_message = '登入錯誤！';
                                   this.retry++;
                                   return;
                              }

                              //save detail to local storage according to user
                              if (this.saveAcc) {
                                   localStorage.setItem(
                                        'crmlogin',
                                        JSON.stringify(
                                        pick(
                                                  response.data.data,
                                                  this.localstorageheader
                                             )
                                        )
                                   );
                                   this.$cookies.set(
                                        'token',
                                        response.data.data.token,
                                        login_expiry_sec,
                                        null,
                                        //config.cookiedomain
                                        //process.env.VUE_APP_CDOMAIN
                                        window.location.hostname
                                   );
                              } else {
                                   //remove old login data
                                   localStorage.removeItem('crmlogin');
                                   this.$cookies.remove('token');

                                   //expiry when session close
                                   sessionStorage.setItem(
                                        'crmlogin',
                                        JSON.stringify(
                                             pick(
                                                  response.data.data,
                                                  this.localstorageheader
                                             )
                                        )
                                   );
                                   this.$cookies.set(
                                        'token',
                                        response.data.data.token,
                                        0,
                                        null,
                                        //config.cookiedomain
                                        //process.env.VUE_APP_CDOMAIN
                                        window.location.hostname
                                   );
                              }

                              this.getSetting();
                         })
                         .catch((error) => {
                              console.log(error);
                              this.is_fail = true;
                              this.error_message =
                                   '登入發生問題，請清理瀏覽器快取再嘗試！';
                              return;
                         });
               },
               getSetting() {
                    this.isLoading = true;
                    BrowserSession.cleanAllSession();
                    BrowserSession.getAllSession();

                    setTimeout(function () {
                         this.isLoading = false;
                         window.location = '/home';
                    }, 2000);
               },
          },
          created() {
               //console.log(window.location.hostname);

               //get user info from localstorage first
               if (localStorage.getItem('crmlogin')) {
                    //if (config.enableautologin) {
                    if(config.enableautologin){
                         this.success_message =
                              '正在處理登入訊息...成功後會自動登入';
                         this.is_success = true;
                    }

                    this.localS = JSON.parse(localStorage.crmlogin);
                    this.username = this.localS.login_name;
                    this.password = 'ThisIsSecretKey';
                    this.saveAcc = true;
                    this.$cookies.set(
                         'token',
                         this.localS.token,
                         login_expiry_sec,
                         null,
                         //config.cookiedomain
                         //process.env.VUE_APP_CDOMAIN
                         window.location.hostname
                    );

                    //if enable autologin, going to check cookie expiry
                    //redirect to dashboard if success
                    //if (config.enableautologin)
                    if(config.enableautologin)
                         this.$auth.checkExpiry(
                              () => {
                                   this.getSetting();
                                   //redirect to dashboard
                                   //document.location.href = '/home';
                              },
                              () => {
                                   //cookie expiry, need to re-login
                                   localStorage.removeItem('crmlogin');
                                   this.password = null;
                                   this.$cookies.remove('token');
                              }
                         );

                    return;
               }

               //get user info from sessionStorage, normally this wont happen
               if (sessionStorage.getItem('crmlogin')) {
                    this.localS = JSON.parse(sessionStorage.crmlogin);
                    this.username = this.localS.login_name;
                    this.password = 'ThisIsSecretKey';
                    this.saveAcc = false;
                    this.$cookies.set(
                         'token',
                         this.localS.token,
                         0,
                         null,
                         //config.cookiedomain
                         //process.env.VUE_APP_CDOMAIN
                         window.location.hostname
                    );

                    this.getSetting();
                    return;
               }
          },
          components: {},
     };
</script>

<style></style>
