import { io } from 'socket.io-client';

const baseURL = process.env.VUE_APP_APISERVER || `${location.origin}/api`;
const socket = io(baseURL);

socket.on('connect', () => {
     console.log(socket.id + ' connected to server!');
});

/*socket.on('import', (data) => {
     console.log(data);
});*/

export default socket;
