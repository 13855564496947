<template>
     <div class="background pa-5">
          <div class="white rounded elevation-3 mb-5 pa-7 a4screen">
               <div class="d-flex justify-space-between pa-7">
                    <div>
                         <v-img
                              height="200px"
                              width="200px"
                              :src="url + '/' + apiCompanySetting.logo"
                         ></v-img>
                    </div>
                    <div class="d-flex flex-column" id="companybox">
                         <span class="text-h4 mb-3 text-right">Invoice</span>
                         <span class="text-h6 text-right">{{
                              apiCompanySetting.name
                         }}</span>
                         <span
                              class="text-right text2_color--text font-weight-light"
                              >{{ apiCompanySetting.address }}</span
                         >
                         <span
                              v-if="apiCompanySetting.phone"
                              class="text-right text2_color--text mt-2 font-weight-light"
                              >T:{{ apiCompanySetting.phone }}</span
                         >
                         <span
                              v-if="apiCompanySetting.fax"
                              class="text-right text2_color--text font-weight-light"
                              >F:{{ apiCompanySetting.fax }}</span
                         >
                    </div>
               </div>
               <v-divider></v-divider>
               <div class="d-flex justify-space-between pa-7 my-auto">
                    <v-card class="pa-5" width="300" min-height="280">
                         <div>
                              <div class="text-center pb-3">
                                   {{ $t('invoice.billto') }}
                              </div>
                              <v-divider class="pb-3"></v-divider>
                              <div>
                                   <v-icon small>mdi-account</v-icon>
                                   <span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.name }}</span
                                   >
                              </div>
                              <div
                                   v-if="
                                        selectcustomer && selectcustomer.address
                                   "
                              >
                                   <v-icon small>mdi-city</v-icon>
                                   <span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.address }}</span
                                   >
                              </div>
                              <div
                                   v-if="selectcustomer && selectcustomer.email"
                              >
                                   <v-icon small>mdi-email</v-icon
                                   ><span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.email }}</span
                                   >
                              </div>
                              <div
                                   v-if="selectcustomer && selectcustomer.phone"
                              >
                                   <v-icon small>mdi-phone</v-icon
                                   ><span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.phone }}</span
                                   >
                              </div>
                              <div
                                   v-if="
                                        selectcustomer && selectcustomer.member
                                   "
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <div>
                                        <v-icon small
                                             >mdi-wallet-membership</v-icon
                                        ><span
                                             class="text-body-2 text2_color--text font-weight-bold"
                                        >
                                             {{
                                                  selectcustomer.member[0]
                                                       .customer_category_name
                                             }}</span
                                        >
                                   </div>
                                   <v-btn icon small @click="update_discount"
                                        ><v-icon small color="primary"
                                             >mdi-cached</v-icon
                                        ></v-btn
                                   >
                              </div>
                              <div
                                   v-if="
                                        selectcustomer &&
                                        selectcustomer.re_point
                                   "
                              >
                                   <v-icon small>mdi-wallet-giftcard</v-icon
                                   ><span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.re_point }}</span
                                   >
                              </div>
                         </div>
                    </v-card>

                    <div class="itembox">
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{
                                        $t('invoice.salesrepresentative')
                                   }}
                                   :</v-col
                              >
                              <v-col cols="6" class="text1_color--text pl-2">
                                   <div>{{ selectSales }}</div>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoiceno') }} :</v-col
                              >
                              <v-col cols="6" class="text1_color--text pl-2">
                                   <div>
                                        {{ apiInvoiceSetting.prefix + invoiceid }}
                                   </div>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.pono') }} :</v-col
                              >
                              <v-col cols="6" class="text1_color--text pl-2">
                                   <div>
                                        {{ po }}
                                   </div>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoicedate') }} :</v-col
                              >
                              <v-col cols="6" class="text1_color--text pl-2">
                                   <div>{{invoicedate}}</div>
                              </v-col>
                         </v-row>

                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.paybefore') }} :</v-col
                              >
                              <v-col cols="6" class="text1_color--text pl-2">
                                   <div>{{duedate}}</div>
                              </v-col>
                         </v-row>

                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoicecurrency') }} :</v-col
                              >
                              <v-col cols="6" class="text1_color--text pl-2">
                                   <div>{{ selectcurrency }}</div>
                              </v-col>
                         </v-row>
                    </div>
               </div>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="5">
                                   <span class="mx-3"> &nbsp; </span>
                                   <span class="caption heading_color--text">{{
                                        $t('components.product')
                                   }}</span>
                              </v-col>
                              <v-col cols="2" class="text-right">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.unitprice')
                                   }}</span>
                              </v-col>
                              <v-col cols="2" class="text-right">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.qty')
                                   }}</span>
                              </v-col>
                              <v-col cols="3" class="text-right">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.amount')
                                   }}</span>
                                   <span class="px-3">&nbsp;</span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList pl-1">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(item, index) in invoiceLineItems"
                         :key="index"
                    >
                         <v-col cols="5" class="d-flex flex-column">
                              <div class="d-flex flex-row">
                                   <v-avatar
                                        size="15"
                                        color="heading_bg"
                                        class="mx-2 my-auto"
                                   >
                                        <span
                                             class="caption heading_color--text"
                                             >{{ index + 1 }}</span
                                        >
                                   </v-avatar>
                                   <div>{{ item.name }}</div>
                              </div>
                              <div
                                   class="ml-10 mt-1 text2_color--text text-caption"
                              >
                                   <pre style="line-height: 0.8rem">{{
                                        item.description
                                   }}</pre>
                                   <div v-if="item.commissionpersonid">
                                        <v-chip x-small>
                                             {{ seekcommissionperson(item.commissionpersonid) }}
                                        </v-chip>
                                   </div>
                              </div>
                         </v-col>

                         <v-col
                              cols="2"
                              class="d-flex flex-column justify-space-between"
                         >
                              <div class="text-right">
                                   <div>${{ formatCurrency(item.price) }}</div>
                                   <div
                                        v-if="!item.discountable"
                                        class="caption text2_color--text"
                                   >
                                        {{ $t('invoice.nondiscountable') }}
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="2" class="text-right">
                              <div>{{ item.qty }} {{item.unit}}</div>
                              <div
                                   v-if="item.type==4 || item.type ==0"
                                   class="caption text2_color--text"
                              >
                                   Cr {{ item.credit }}
                              </div>
                         </v-col>

                         <v-col cols="3">
                              <div class="text-right">
                                   ${{ formatCurrency(item.total) }}
                                   <span class="px-3">&nbsp;</span>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="pa-7">
                    <v-row class="justify-space-between">
                         <v-col cols="7">
                              <div>
                                   <span class="h6 font-weight-bold mb-1 py-1">
                                        {{ $t('global.remark') }}
                                   </span>
                              </div>

                              <v-textarea
                                   v-model="notes"
                                   outlined
                                   auto-grow
                                   no-resize
                                   readonly
                              ></v-textarea>
                         </v-col>
                         <v-col cols="5">
                              <v-row dense align="end" class="pr-2">
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3 my-auto"
                                        >{{ $t('invoice.subtotal') }}</v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             readonly
                                             prefix="$"
                                             :value="subtotal"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row
                                   dense
                                   align="end"
                                   class="pr-2"
                                   v-if="apiCompanySetting.enable_tax"
                              >
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3"
                                        >{{ $t('invoice.tax') }}
                                        <!--{{
                                             isOverrideTax ? '' : trate + '%'
                                        }}--></v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             v-model="tax"
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             prefix="$"
                                             readonly
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row dense align="end" class="pr-2">
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3 my-auto"
                                        >{{ $t('invoice.discount') }}
                                        <!--{{
                                             isOverrideDiscount
                                                  ? ''
                                                  : memberdiscountrate + '%'
                                        }}--></v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             prefix="$"
                                             readonly
                                             :value="formatCurrency(discount)"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row
                                   dense
                                   align="end"
                                   class="pa-2 heading_bg mt-3 rounded"
                              >
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3 font-weight-bold my-auto"
                                        >{{ $t('invoice.amount') }}</v-col
                                   >
                                   <v-col cols="6" class="text2_color--text">
                                        <v-text-field
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input font-weight-bold"
                                             readonly
                                             prefix="$"
                                             :value="formatCurrency(amount)"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <div
                                   style="border:solid; border-width: 1px; 
                                   border-radius: 7px; margin-top:15px; border-color:#a1a1a1;
                                   padding:5px"
                              >
                                   <v-row
                                        dense
                                        align="end"
                                        class="pa-1 rounded"
                                   >
                                        <v-col
                                             cols="6"
                                             class="text-right text2_color--text text--darken-3 my-auto"
                                             >{{ $t('invoice.paid') }}</v-col
                                        >
                                        <v-col
                                             cols="6"
                                             class="text2_color--text"
                                        >
                                             <v-text-field
                                                  single-line
                                                  dense
                                                  hide-details
                                                  class="right-input"
                                                  readonly
                                                  prefix="$"
                                                  :value="formatCurrency(amountpaid)"
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>

                                   <v-row
                                        dense
                                        align="end"
                                        class="pa-1 rounded"
                                   >
                                        <v-col
                                             cols="6"
                                             class="text-right text2_color--text text--darken-3 my-auto"
                                             >{{ $t('invoice.unpaid') }}</v-col
                                        >
                                        <v-col
                                             cols="6"
                                             class="text2_color--text"
                                        >
                                             <v-text-field
                                                  single-line
                                                  dense
                                                  hide-details
                                                  class="right-input"
                                                  readonly
                                                  prefix="$"
                                                  :value="formatCurrency(amount - amountpaid)"
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>
                              </div>
                         </v-col>
                    </v-row>
                    <v-row>
                         <div class="h6 font-weight-bold px-3 py-1">
                              {{ $t('invoice.tandc') }}
                         </div>
                    </v-row>
                    <v-row>
                         <pre
                              class="px-3"
                              style="white-space: pre-wrap; font-family:'Roboto', sans-serif;"
                              >{{
                              this.tas
                              }}</pre
                         >
                    </v-row>
               </div>
          </div>

          <div
               class="white rounded elevation-3 mb-5 pa-5 d-flex justify-space-between a4width"
          >
               <v-spacer></v-spacer>
               <v-btn
                    color="grey"
                    class="body-2 font-weight-bold ml-2"
                    text
                    @click="closeDialog"
               >
                    <span>{{ $t('global.close') }}</span>
               </v-btn>
          </div>

          <ConfirmDialog ref="confirm" />

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               {{ $t('global.successPrefix') }}:
               <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               {{ $t('global.failPrefix') }}:
               <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /*eslint-disable*/
     import InvoiceService from '@/utils/api/InvoiceService';
     import BrowserSession from '@/utils/BrowserSession';

     import ConfirmDialog from '@/views/components/ConfirmDialog';
     //import _ from 'lodash';
     var find = require('lodash/find');

     //const config = require('@/config/config.js');
     const moment = require('moment');
     const date = new Date();
     /*const unformatter = (value) => {
          if (!value && value != 0) return null;
          if (typeof value === 'string' || value instanceof String) {
               value = value.replace(',', '');
               value = Number(value);
               return value;
          } else {
               let str_value = value.toString();
               str_value = str_value.replace(',', '');
               str_value = Number(str_value);
               return str_value;
          }
     };*/
     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     const zeroPad = (num, places) => String(num).padStart(places, '0');

     const roundto = (num, dp = 0, type = 'roundoff') => {
          if (type == 'roundoff') return +(Math.round(num + 'e+' + dp) + 'e-' + dp);

          if (type == 'roundup') return +(Math.ceil(num + 'e+' + dp) + 'e-' + dp);

          if (type == 'rounddown') return +(Math.floor(num + 'e+' + dp) + 'e-' + dp);
     };

     export default {
          props: {
               _id: String, //invoice id
          },
          data: function () {
               return {
                    pagekey: ['Vbgoh'],
                    windowHeight: window.innerHeight,
                    windowWidth: window.innerWidth,

                    itemEditDialog: false,
                    itemEditidx: null,

                    showCustomerDetail: false,
                    showTandCBox: false,

                    selectTemplate: null,

                    invoiceid: '',
                    selectSales: null,
                    po: '',
                    notes: '',
                    tas: '',

                    apiCustomerList: [],
                    selectcustomer: {},
                    commissiontoList: [],

                    logobjs: [],
                    selectedlogidx: null,

                    selectcurrency: 'HKD',

                    invoicedate: date.toISOString().substr(0, 10),
                    invoicedatepicker: false,
                    duedate: new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000)
                         .toISOString()
                         .substr(0, 10),
                    duedatepicker: false,


                    apiUserList: [],
                    apiInvoiceSetting: {},
                    apiCompanySetting: {},
                    invoiceobj: {},

                    isProductAvailiable: true,
                    isServiceAvailiable: true,
                    isPackageAvailiable: true,
                    isStockAvailiable: false,

                    invoiceLineItems: [],
                    invoiceLineItemDetail: {},
                    preUseService1: null,
                    preUseQty1: null,
                    preUseService2: null,
                    preUseQty2: null,

                    subtotal: 0.0,
                    non_discountable_subtotal: 0.0,

                    isOverrideTax: false,
                    tax: 0.0,
                    tmptax: 0.0,

                    isOverrideDiscount: false,
                    discount: 0.0,
                    memberdiscountrate: 0,
                    tmpdiscount: 0.0,

                    max_pt: 0,
                    used_pt: 0,
                    exchange_pt_amount: 0.0,

                    amount: 0.0,

                    amountpaid: 0.0,
                    balance: 0.0,

                    ApiPaymentListName: [],
                    payment_type: '',

                    expense_type: this.$t('invoice.cash'),

                    pay_amount: 0.0,
                    expense_amount: 0.0,

                    tags: [],
                    tagsList: [],

                    url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,

                    is_fail: false,
                    is_success: false,
                    error_message: '',
                    success_message: '',

                    isLoading: false,
               };
          },
          methods: {
               apiGetSettingPrefix() {
                    BrowserSession.getSession('setting', (data) => {
                         this.apiInvoiceSetting = data.find((d) => {
                              return d.key == 'invoice';
                         }).value;
                         //this.tas = this.apiInvoiceSetting.terms;

                         this.apiCompanySetting = data.find((d) => {
                              return d.key == 'company';
                         }).value;

                         let ptype = data.find((d) => {
                              return d.key == 'invoice';
                         }).value['payment_type'];
                         this.ApiPaymentListName = ptype.filter(e=>e.enable);

                         this.ApiPaymentListName = this.ApiPaymentListName.map(e=>{
                              return {...e, ...{displayname: this.$t('invoice.' + e.name)}}
                         });

                         if(this.ApiPaymentListName.length > 0){
                              this.payment_type = this.ApiPaymentListName[0];
                              this.expense_type = this.ApiPaymentListName[0];
                         }
                    });
               },
               loadInvoice() {
                    //console.log('loadInvoice():', this._id);
                    InvoiceService.getInvoiceFull(this._id)
                         .then(async (response) => {
                              //console.log(response.data.data);
                              if (response.data.status == 'success') {
                                   //this.apiInvoiceObj = response.data.data;
                                   this.invoiceobj = response.data.data;
                              } else {
                                   this.error_message = response.data.message;
                                   this.is_fail = true;
                                   return;
                              }

                              this.invoiceid = zeroPad(this.invoiceobj.invoiceid, 7);
                              this.invoiceno = this.invoiceobj.invoiceno;
                              this.invoicedate = this.invoiceobj.invoice_date;
                              this.tags = this.invoiceobj.tag;
                              this.duedate = this.invoiceobj.due_date;
                              this.selectSales = this.invoiceobj.sales.name,

                              this.po = this.invoiceobj.po;
                              this.selectcurrency = this.invoiceobj.currency;
                              this.subtotal = numformat.format(
                                   this.invoiceobj.subtotal
                              );
                              this.calPaid();

                              this.discount = this.invoiceobj.discount;
                              this.tax = this.invoiceobj.tax;
                              this.amount = this.invoiceobj.amount;
                              this.notes = this.invoiceobj.notes;
                              this.tas = this.invoiceobj.tas;

                              //console.log(this.apiCustomerList);
                              this.selectcustomer = this.invoiceobj.billto;
                              this.commissiontoList = this.invoiceobj.commissionto;

                              this.invoiceLineItems = this.invoiceobj.product;
                         })
                         .catch((e) => {
                              console.log(e);
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },

               calPaid(){
                    this.invoiceobj.payment_list.map(payment=>{
                         if (
                                   payment.status == 'done' &&
                                   payment.payment_type != 'stock'
                              )
                                   this.amountpaid += payment.amount;
                    })
               },

               /*runCal() {
                    this.calSubtotal();
                    this.calTax();
                    this.calDiscount();
                    this.calTotal();
               },
               calSubtotal() {
                    let stotal = 0.0; //total
                    let ex_stotal = 0.0; //no discountable product
                    this.invoiceLineItems.map((item) => {
                         //console.log(item);
                         stotal += unformatter(item.total);
                         if (typeof item.discountable == undefined) {
                              if (!item.obj.discountable) {
                                   ex_stotal += unformatter(item.total);
                              }
                         } else {
                              if (!item.discountable)
                                   ex_stotal += unformatter(item.total);
                         }
                    });

                    //this.subtotal = stotal;
                    this.subtotal = numformat.format(roundto(stotal, 2));
                    this.non_discountable_subtotal = roundto(ex_stotal, 2);
                    //console.log(this.non_discountable_subtotal);
               },
               calTax() {
                    if (!this.apiCompanySetting.enable_tax) {
                         this.tax = 0;
                         return;
                    }
                    if (this.isOverrideTax) {
                         return;
                    }

                    let stax = 0.0;
                    stax =
                         unformatter(this.subtotal) *
                         //(unformatter(this.trate) / 100);
                         (unformatter(this.apiInvoiceSetting.tax) / 100);
                    this.tax = roundto(stax, 2);
                    //this.tax = numformat.format(stax);
               },
               calDiscount() {
                    if (this.isOverrideDiscount) {
                         //this.discount = numformat.format(this.discount);
                         //console.log('override discount!');
                         return;
                    }

                    let dis = 0.0;
                    dis =
                         (unformatter(this.subtotal) -
                              this.non_discountable_subtotal) *
                         (unformatter(this.memberdiscountrate) / 100);
                    this.discount = roundto(dis, 2);
                    //console.log('Cal discount:'+dis);
                    //this.discount = numformat.format(dis);
               },
               calCommission(price, commissiontype, comm) {
                    if (commissiontype == 'percentage') {
                         return roundto((price * comm) / 100, 2);
                    }
                    return comm;
               },
               calTotal() {
                    let amount = 0.0;
                    amount =
                         unformatter(this.subtotal) -
                         unformatter(this.discount) +
                         unformatter(this.tax);
                    //this.amount = amount;
                    this.amount = numformat.format(
                         roundto(
                              amount,
                              this.apiInvoiceSetting.decimal_place,
                              this.apiInvoiceSetting.rounding
                         )
                    );
               },*/

               closeDialog() {
                    this.$emit('onClickCancel', null);
               },
               /*copyAmount() {
                    this.pay_amount =
                         unformatter(this.amount) - this.exchange_pt_amount;
               },
               cleanPackageSelect(idx) {
                    if (idx == 1) {
                         //console.log('cleaning 1...');
                         this.preUseService1 = null;
                         this.preUseQty1 = null;

                         //console.log(this.invoiceLineItemDetail);
                    }
                    if (idx == 2) {
                         //console.log('cleaning 2...');
                         this.preUseService2 = null;
                         this.preUseQty2 = null;

                         //console.log(this.invoiceLineItemDetail);
                    }
               },*/
               seekcommissionperson(_id){
                    let person = find(this.commissiontoList, {_id: _id})

                    if(person){
                         return person.name;
                    }

                    return null;
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               formatDateTime(value) {
                    return moment(value).format('YYYY-MM-DD HH:mm');
               },
          },
          async created() {
               this.apiGetSettingPrefix();
               await this.loadInvoice();
          },

          components: {
               ConfirmDialog,
          },

          computed: {},
     };
</script>

<style>
     .itembox {
          width: 300px;
          margin-top: auto;
          margin-bottom: auto;
     }
     #companybox {
          width: 300px;
          margin-top: auto;
          margin-bottom: auto;
     }
     .a4width {
          width: 830px;
     }
     .a4screen {
          width: 830px;
          height: 100%;
          /* ipad 7th, 8th,9th gen : 1080 * 810 (currently tested)
          ipad air 4gen : 1180 * 820
          ipad air 3gen : 1112 * 834
       */
          /*width: 794px;
       height: 1123px;*/
     }
     .right-input input {
          text-align: right;
     }
</style>
