<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span
                         v-if="_id"
                         class="heading_color--text"
                         >{{$t('global.modify')}}</span
                    >
                    <span
                         v-else
                         class="heading_color--text"
                         >{{$t('global.add')}}</span
                    >
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="px-10 post_bg"
               >
                    <v-tabs
                         color="primary accent-4"
                         background-color="post_bg"
                         centered
                    >
                         <v-tab>{{ $t('user.userinfo') }}</v-tab>
                         <v-tab>{{ $t('user.loginsetting') }}</v-tab>
                         <v-tab>{{ $t('user.commissionsetting') }}</v-tab>
                         <v-tab>{{ $t('user.hoursetting') }}</v-tab>

                         <v-tab-item style="min-height: 530px" class="post_bg">
                              <v-container fluid class="post_bg py-2">
                                   <div>
                                        <center>
                                             <v-btn
                                                  icon
                                                  small
                                                  color="primary"
                                                  @click="preview_icon"
                                             >
                                                  <v-icon>
                                                       mdi-chevron-left
                                                  </v-icon>
                                             </v-btn>

                                             <v-avatar>
                                                  <v-img
                                                       :src=" publicPath + icon_names[userobj.icon_index]"
                                                  />
                                             </v-avatar>

                                             <v-btn
                                                  icon
                                                  small
                                                  color="primary"
                                                  @click="next_icon"
                                             >
                                                  <v-icon>
                                                       mdi-chevron-right
                                                  </v-icon>
                                             </v-btn>
                                        </center>
                                   </div>
                                   <center class="caption heading_color--text">
                                        {{ $t('user.avatar') }}
                                   </center>

                                   <v-row class="mt-5">
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="userobj.name"
                                                  :counter="50"
                                                  :rules="nameRules"
                                                  :label="$t('user.name')"
                                                  :hint="$t('global.required')"
                                                  persistent-hint
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                             <v-select
                                                  v-model="userobj.gender"
                                                  :items="genderList"
                                                  :label="$t('user.gender')"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-select>
                                        </v-col>
                                   </v-row>

                                   <v-row>
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="userobj.title"
                                                  :counter="50"
                                                  :label="$t('user.occupation')"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                             <v-menu
                                                  v-model="showedate"
                                                  :close-on-content-click="false"
                                                  :nudge-right="40"
                                                  transition="scale-transition"
                                                  offset-y
                                                  min-width="auto"
                                             >
                                                  <template
                                                       v-slot:activator="{ on, attrs, }"
                                                  >
                                                       <v-text-field
                                                            v-model=" userobj.employ_date "
                                                            :label="$t('user.employdate')"
                                                            hint="YYYY-MM-DD"
                                                            persistent-hint
                                                            readonly
                                                            outlined
                                                            dense
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            background-color="#ffffff"
                                                       ></v-text-field>
                                                  </template>
                                                  <v-date-picker
                                                       v-model=" userobj.employ_date "
                                                       @input=" showedate = false "
                                                  ></v-date-picker>
                                             </v-menu>
                                        </v-col>
                                   </v-row>

                                   <v-row>
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="userobj.phone"
                                                  :label="$t('user.phone')"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="userobj.email"
                                                  :label="$t('user.email')"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>

                                   <v-row>
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="userobj.id_card"
                                                  :label="$t('user.id')"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                             <v-menu
                                                  v-model="showdob"
                                                  :close-on-content-click="false"
                                                  :nudge-right="40"
                                                  transition="scale-transition"
                                                  offset-y
                                                  min-width="auto"
                                             >
                                                  <template
                                                       v-slot:activator="{ on, attrs, }"
                                                  >
                                                       <v-text-field
                                                            v-model="userobj.dob"
                                                            :label="$t('user.dob')"
                                                            hint="YYYY-MM-DD"
                                                            persistent-hint
                                                            readonly
                                                            outlined
                                                            dense
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            background-color="#ffffff"
                                                       ></v-text-field>
                                                  </template>
                                                  <v-date-picker
                                                       v-model="userobj.dob"
                                                       @input="showdob = false"
                                                  ></v-date-picker>
                                             </v-menu>
                                        </v-col>
                                   </v-row>

                                   <v-row>
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="userobj.address"
                                                  :label="$t('user.address')"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>

                                   <v-row>
                                        <v-col cols="8" class="py-0">
                                             <v-textarea
                                                  v-model="userobj.remark"
                                                  :label="$t('user.remark')"
                                                  rows="2"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-textarea>
                                        </v-col>
                                        <v-col cols="4" class="py-0">
                                             <v-switch
                                                  color="primary"
                                                  v-model="isActive"
                                                  :label="$t('user.status')"
                                                  :disabled="this._id == null"
                                                  inset
                                                  dense
                                                  hide-details
                                             >
                                             </v-switch>
                                        </v-col>
                                   </v-row>
                              </v-container>
                         </v-tab-item>

                         <v-tab-item style="min-height: 530px" class="post_bg">
                              <v-container fluid class="post_bg py-5">
                                   <v-row class="mt-5">
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="userobj.login_name"
                                                  :disabled="!userobj.enable_login"
                                                  :label="$t('user.loginname')"
                                                  :counter="20"
                                                  :error="loginname_error"
                                                  :error-messages="loginname_err_msg"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                             <v-text-field
                                                  v-model="new_password"
                                                  :disabled="!userobj.enable_login"
                                                  :label="$t('user.loginpassword')"
                                                  :counter="20"
                                                  :error="password_error"
                                                  :error-messages="password_err_msg"
                                                  outlined
                                                  type="password"
                                                  dense
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>

                                   <v-row>
                                        <v-col class="py-0">
                                             <v-select
                                                  v-model="userobj.roleid"
                                                  :items="roleList"
                                                  item-text="role"
                                                  item-value="_id"
                                                  hide-selected
                                                  :label="$t('components.permissiongroup')"
                                                  outlined
                                                  dense
                                                  return-object
                                                  background-color="#ffffff"
                                             ></v-select>
                                        </v-col>
                                        <v-col class="pt-0">
                                             <v-checkbox
                                                  v-model="userobj.enable_login"
                                                  :label="$t('user.allowlogin')"
                                                  dense
                                                  hide-details
                                             ></v-checkbox>
                                        </v-col>
                                   </v-row>

                                   <v-row> </v-row>
                              </v-container>
                         </v-tab-item>

                         <v-tab-item style="min-height: 530px" class="post_bg">
                              <v-container fluid class="post_bg py-5">
                                   <v-row class="mt-5">
                                        <v-select
                                             v-model="userobj.commission_plans"
                                             :items="planList"
                                             item-text="name"
                                             item-value="_id"
                                             :label="$t('user.commissionplans')"
                                             clearable
                                             multiple
                                             outlined
                                             small-chips
                                             return-object
                                             background-color="#ffffff"
                                        ></v-select>
                                   </v-row>
                              </v-container>
                         </v-tab-item>

                         <v-tab-item style="min-height: 530px" class="post_bg">
                              <v-container fluid class="post_bg py-2 mt-5">
                                   <div
                                        class="d-flex flex-row justify-space-between"
                                        v-for="day in userobj.work_time"
                                        :key="day.week"
                                   >
                                        <span
                                             class="mr-1 mt-2"
                                             :style="{ width: '85px' }"
                                        >
                                             {{dayname[day.weekday - 1]}}
                                        </span>
                                        <v-select
                                             v-model="day.start"
                                             :items="clocklist"
                                             :label="$t('user.workstart')"
                                             outlined
                                             dense
                                             :disabled="!day.active"
                                             :style="{ 'max-width': '110px' }"
                                             class="mx-2"
                                             background-color="#ffffff"
                                        ></v-select>
                                        <span class="mx-1 mt-2"> - </span>
                                        <v-select
                                             v-model="day.end"
                                             :items="clocklist"
                                             :label="$t('user.workend')"
                                             outlined
                                             dense
                                             :disabled="!day.active"
                                             :style="{ 'max-width': '110px' }"
                                             class="mx-2"
                                             background-color="#ffffff"
                                        ></v-select>
                                        <v-switch
                                             color="primary"
                                             v-model="day.active"
                                             :label="day.active ? $t('user.open') : $t('user.close')"
                                             hide-details
                                             inset
                                             class="mt-1 mx-1"
                                        ></v-switch>
                                   </div>
                              </v-container>
                         </v-tab-item>
                    </v-tabs>
               </v-form>
          </div>
          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="!this._id"
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{$t('global.add')}}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="updateRecord"
                    >
                         {{$t('global.modify')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>

          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Update Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import RoleService from '@/utils/api/RoleService';
     import UserService from '@/utils/api/UserService';
     import CommissionService from '@/utils/api/CommissionService';
     import BrowserSession from '@/utils/BrowserSession';
     //import _ from 'lodash';
     var isEmpty = require('lodash/isEmpty');
     var find = require('lodash/find');
     var indexOf = require('lodash/indexOf');

     //const config = require('@/config/config.js');

     export default {
          props: {
               _id: String,
               enableModifyPassword: String,
          },
          data: () => ({
               publicPath: window.location.origin+'/avatar/',

               //icon_index: 0,
               roleList: [],
               //role: null,
               planList: [],
               //commissionplans: null,

               orgi_userobj: {},
               userobj: {
                    icon_index: 0,
                    login_name: '',
                    avatar: 'F01.png',
                    password: null,
                    roleid: '',
                    name: '',
                    title: '',
                    gender: '女',
                    phone: '',
                    address: '',
                    email: '',
                    id_card: '',
                    dob: '',
                    employ_date: '',
                    work_time: [
                         { weekday: 1, start: '9:00', end: '18:00', active: true },
                         { weekday: 2, start: '9:00', end: '18:00', active: true },
                         { weekday: 3, start: '9:00', end: '18:00', active: true },
                         { weekday: 4, start: '9:00', end: '18:00', active: true },
                         { weekday: 5, start: '9:00', end: '18:00', active: true },
                         { weekday: 6, start: '9:00', end: '18:00', active: false },
                         { weekday: 7, start: '9:00', end: '18:00', active: false },
                    ],
                    commission_plans: [],
                    enable_login: false,
                    status: 'active',
                    remark: '',
               },
               new_password: null,
               password_error: false,
               password_err_msg: null,
               loginname_error: false,
               loginname_err_msg: null,

               valid: true,
               showedate: false,
               showdob: false,
               nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) =>
                         (v && v.length <= 50) ||
                         'Name must be less than 50 characters',
               ],
               icon_names: [
                    'F01.png',
                    'F02.png',
                    'F03.png',
                    'F04.png',
                    'F05.png',
                    'F06.png',
                    'F07.png',
                    'M01.png',
                    'M02.png',
                    'M03.png',
                    'M04.png',
                    'M05.png',
                    'M06.png',
                    'M07.png',
                    'M08.png',
                    'M09.png',
                    'M10.png',
                    'M11.png',
                    'M12.png',
                    'M13.png',
                    'M14.png',
                    'M15.png',
                    'M16.png',
               ],
               genderList: ['男', '女'],
               genderListName: ['M', 'F'],

               working: [
                    { weekday: 1, start: '9:00', end: '18:00', active: true },
                    { weekday: 2, start: '9:00', end: '18:00', active: true },
                    { weekday: 3, start: '9:00', end: '18:00', active: true },
                    { weekday: 4, start: '9:00', end: '18:00', active: true },
                    { weekday: 5, start: '9:00', end: '18:00', active: true },
                    { weekday: 6, start: '9:00', end: '18:00', active: false },
                    { weekday: 7, start: '9:00', end: '18:00', active: false },
               ],
               dayname: [
                    '星期一',
                    '星期二',
                    '星期三',
                    '星期四',
                    '星期五',
                    '星期六',
                    '星期日',
               ],

               clocklist: [
                    '0:00',
                    '0:30',
                    '1:00',
                    '1:30',
                    '2:00',
                    '2:30',
                    '3:00',
                    '3:30',
                    '4:00',
                    '4:30',
                    '5:00',
                    '5:30',
                    '6:00',
                    '6:30',
                    '7:00',
                    '7:30',
                    '8:00',
                    '8:30',
                    '9:00',
                    '9:30',
                    '10:00',
                    '10:30',
                    '11:00',
                    '11:30',
                    '12:00',
                    '12:30',
                    '13:00',
                    '13:30',
                    '14:00',
                    '14:30',
                    '15:00',
                    '15:30',
                    '16:00',
                    '16:30',
                    '17:00',
                    '17:30',
                    '18:00',
                    '18:30',
                    '19:00',
                    '19:30',
                    '20:00',
                    '20:30',
                    '21:00',
                    '21:30',
                    '22:00',
                    '22:30',
                    '23:00',
                    '23:30',
               ],

               is_fail: false,
               is_success: false,
               success_message: '',
               error_message: '',
          }),
          methods: {
               apiGetAllStuff() {
                    RoleService.getAll(1, 999)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.roleList = response.data.data;
                                   //if add new user, set default role
                                   if (isEmpty(this._id)) {
                                        //console.log('set default role')
                                        this.userobj.roleid = this.roleList[0];
                                   }
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         }),
                         CommissionService.getAll()
                              .then((response) => {
                                   if (response.data.status == 'success')
                                        this.planList = response.data.data;
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                              });
               },

               apiGetUser(id) {
                    UserService.getUser(id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   //console.log(response.data.data);
                                   this.userobj = {
                                        ...this.userobj,
                                        ...response.data.data,
                                   };
                                   /*this.userobj = {
                                        ...this.userobj,
                                        ...{ password: 'xxxxxxxxxx' },
                                   };*/
                                   this.userobj.roleid = find(this.roleList, {
                                        _id: this.userobj.roleid,
                                   });
                                   this.userobj.commission_plans =
                                        this.userobj.commission_plans.map((p) => {
                                             return find(this.planList, {
                                                  _id: p,
                                             });
                                        });
                                   this.userobj.icon_index = indexOf(
                                        this.icon_names,
                                        this.userobj.avatar
                                   );

                                   this.orgi_userobj = {
                                        ...this.orgi_userobj,
                                        ...this.userobj,
                                   };
                                   this.userobj.gender =
                                        this.genderList[
                                             indexOf(
                                                  this.genderListName,
                                                  this.userobj.gender
                                             )
                                        ];

                                   //console.log(this.userobj);
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },

               preview_icon() {
                    this.userobj.icon_index--;
                    if (this.userobj.icon_index < 0) {
                         this.userobj.icon_index = this.icon_names.length - 1;
                    }
               },

               next_icon() {
                    this.userobj.icon_index++;
                    if (this.userobj.icon_index >= this.icon_names.length) {
                         this.userobj.icon_index = 0;
                    }
               },

               async addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }

                    //1. check user name already register?
                    if (this.userobj.enable_login) {
                         if (
                         isEmpty(this.userobj.login_name) ||
                              isEmpty(this.new_password)
                         ) {
                              this.is_fail = true;
                              this.error_message = '請輸入登入資料！';
                              return;
                         }
                         let result = await UserService.getUserByName(
                              this.userobj.login_name
                         );

                         //console.log(result);
                         if (result.data.recordcount >0) {
                              this.$emit('onFailAction', '添加失敗，已有此用戶！');
                              return;
                         }
                    }

                    let res = await UserService.addUser({
                         ...this.userobj,
                         ...{
                              password: this.new_password,
                              avatar: this.icon_names[this.userobj.icon_index],
                              gender: this.genderListName[
                                   this.genderList.indexOf(this.userobj.gender)
                              ],
                              roleid: this.userobj.roleid._id,
                              commission_plans: this.userobj.commission_plans.map(
                                   (i) => {
                                        return i._id;
                                   }
                              ),
                         },
                    });

                    //console.log(res);

                    if (res.data.status == 'success') {
                         this.reloadUserCache();
                         this.$emit(
                              'onClickInput',
                              '成功新增僱員 - ' + this.userobj.name
                         );
                    } else {
                         this.$emit('onFailAction', res.data.message);
                    }
               },

               updateRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }

                    if (this.userobj.enable_login) {
                         //check login detail
                         if (isEmpty(this.userobj.login_name)) {
                              //set error
                              this.loginname_error = true;
                              this.loginname_err_msg = '登入名稱不可為空';
                              return;
                         } else {
                              this.loginname_error = false;
                              this.loginname_err_msg = null;
                         }
                         if (isEmpty(this.new_password)) {
                              //set error
                              this.password_error = true;
                              this.password_err_msg = '登入密碼不可為空';
                              return;
                         } else {
                              this.password_error = false;
                              this.password_err_msg = null;
                         }
                    }

                    let user = {
                         ...this.userobj,
                         ...{
                              avatar: this.icon_names[this.userobj.icon_index],
                              roleid: this.userobj.roleid._id,
                              gender: this.genderListName[
                                   this.genderList.indexOf(this.userobj.gender)
                              ],
                         },
                    };

                    if (this.userobj.enable_login) {
                         user = { ...user, ...{ password: this.new_password } };
                    }

                    user.commission_plans = this.userobj.commission_plans.map(
                         (i) => i._id
                    );

                    //delete user.password;

                    UserService.updateUser(user)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.reloadUserCache();
                              this.$emit(
                                   'onClickInput',
                                   '成功更新僱員 - ' + this.userobj.name
                              );
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               cancel() {
                    this.$emit('onClickCancel', null);
               },

               reloadUserCache() {
                    BrowserSession.renewSession('user', null);
               },
          },
          computed: {
               isActive: {
                    get() {
                         return this.userobj.status == 'active';
                    },
                    set(value) {
                         this.userobj.status = value ? 'active' : 'inactive';
                    },
               },
          },
          created() {
               this.apiGetAllStuff();

               if (this._id) {
                    this.apiGetUser(this._id);
               }

               this.dayname = [this.$t('user.monday'),
                               this.$t('user.tuesday'),
                               this.$t('user.wednesday'),
                               this.$t('user.thursday'),
                               this.$t('user.friday'),
                               this.$t('user.saturday'),
                               this.$t('user.sunday')
                              ]
          },
     };
</script>
