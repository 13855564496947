<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span
                         v-if="_id == null"
                         class="heading_color--text"
                         >{{$t('global.newrecord')}}</span
                    >
                    <span
                         v-else
                         class="heading_color--text"
                         >{{$t('global.modify')}}</span
                    >
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="px-10 py-5 post_bg"
               >
                    <div class="d-inline-flex flex-row">
                         <v-img
                              :src="url + '/' + previewImage"
                              height="100"
                              width="100"
                              class="mx-2"
                         ></v-img>
                         <v-btn
                              color="primary"
                              @click="imageDialog = true"
                              outlined
                              height="100px"
                              width="100px"
                              >{{$t('service.setimage')}}</v-btn
                         >
                         <input
                              v-show="false"
                              ref="inputUpload"
                              type="file"
                              @change="uploadImage"
                         />
                         <div class="my-auto">
                              <span class="text-caption mx-3">
                                   {{$t('service.imagemsg')}}
                              </span>
                              <v-progress-linear
                                   class="mx-3"
                                   :value="progress"
                              ></v-progress-linear>
                              <span
                                   class="text-body1 error--text mx-3"
                                   v-if="error_message"
                                   >{{ error_message }}</span
                              >
                         </div>
                    </div>
                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="name"
                                   :counter="50"
                                   :rules="nameRules"
                                   :label="$t('service.name')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="serviceid"
                                   :label="$t('service.serviceno')"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-combobox
                                   v-model="category"
                                   :items="categoryList"
                                   hide-selected
                                   :hint="$t('service.inputcategory')"
                                   :label="$t('service.category')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-combobox>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="duration"
                                   :label="$t('service.serviceduration')"
                                   :hint="$t('global.required')"
                                   :suffix="$t('service.minute')"
                                   :rules="[numberRules]"
                                   type="number"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="price"
                                   prefix="$"
                                   :label="$t('service.price')"
                                   :hint="$t('global.required')"
                                   :rules="[numberRules]"
                                   type="number"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="cost"
                                   prefix="$"
                                   :label="$t('service.cost')"
                                   :hint="$t('global.required')"
                                   :rules="[numberRules]"
                                   type="number"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-combobox
                                   v-model="unit"
                                   :items="unitList"
                                   hide-selected
                                   :hint="$t('service.inputunit')"
                                   :label="$t('service.unit')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-combobox>
                         </v-col>
                         <v-col class="d-flex flex-row"> </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0" cols="8">
                              <v-textarea
                                   v-model="description"
                                   :label="$t('service.detail')"
                                   rows="4"
                                   outlined
                                   hide-details
                                   dense
                                   background-color="#ffffff"
                              >
                              </v-textarea>
                         </v-col>
                         <v-col class="py-0" cols="4">
                              <div
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <v-text-field
                                        v-model="commission"
                                        :suffix="ispercentage ? '%' : ''"
                                        :prefix="ispercentage ? '' : '$'"
                                        outlined
                                        :label="$t('service.commission')"
                                        :hint="$t('global.required')"
                                        type="number"
                                        persistent-hint
                                        :rules="[numberRules]"
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                                   <v-checkbox
                                        v-model="ispercentage"
                                        label="%"
                                        dense
                                        hide-details
                                   ></v-checkbox>
                              </div>
                              <v-checkbox
                                   v-model="ispersist"
                                   :label="$t('service.allowpersist')"
                                   dense
                                   hide-details
                              ></v-checkbox>
                              <v-checkbox
                                   v-model="discountable"
                                   :label="$t('service.allowdiscount')"
                                   dense
                                   hide-details
                              ></v-checkbox>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="py-0">
                              <v-switch
                                   color="primary"
                                   v-model="isActive"
                                   :label="$t('service.status')"
                                   hide-details
                                   dense
                                   :disabled="_id == null"
                                   inset
                              >
                              </v-switch>
                         </v-col>
                         <v-col class="py-0"> </v-col>
                    </v-row>
               </v-form>
          </div>
          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="_id == null"
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{$t('global.add')}}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="editRecord"
                    >
                         {{$t('global.modify')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>

          <v-dialog v-model="imageDialog" persistent max-width="790px">
               <ImageDialog
                    v-if="imageDialog"
                    @onClickCancel="cancelInput"
                    @onClickInput="successInput"
                    @onFailAction="failInput"
               />
          </v-dialog>

          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Update Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import TagService from '@/utils/api/TagService';
     import ServiceService from '@/utils/api/ServiceService';
     import uploadService from '@/utils/uploadFileService';
     import BrowserSession from '@/utils/BrowserSession';
     import ImageDialog from '@/views/components/ImageManagerDialog';

     export default {
          props: { _id: String },
          data: () => ({
               valid: true,
               name: '',
               nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) =>
                         (v && v.length <= 50) ||
                         'Name must be less than 50 characters',
               ],

               serviceid: '',
               category: { text: '' },
               categoryList: [],
               ispercentage: false,
               ispersist: true,
               discountable: true,

               commission: 0.0,
               duration: null,
               price: null,
               cost: null,

               numberRules: (v) => {
                    if (!v && v != 0) return 'This field is required!';
                    if (v.length == 0) return 'This field is required!';
                    if (!isNaN(v)) return true;
                    return 'Please input a number';
               },

               unit: { text: '' },
               unitList: [],
               description: '',

               isActive: true,

               is_fail: false,
               error_message: '',

               url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,
               previewImage: 'noimage.jpeg',
               progress: 0,

               imageDialog: false,
          }),
          methods: {
               validate() {
                    this.$refs.form.validate();
               },

               resetValidation() {
                    this.$refs.form.resetValidation();
               },

               uploadImage(event) {
                    this.progress = 0;

                    uploadService
                         .upload(event.target.files[0], (event) => {
                              this.progress = Math.round(
                                   (100 * event.loaded) / event.total
                              );
                         })
                         .then((response) => {
                              //this.previewImage = './'+response.data.file.path;
                              this.previewImage = './' + response.data.file.filename;
                              //console.log(response);
                         })
                         .catch((err) => {
                              this.progress = 0;
                              this.error_message =
                              this.$t('global.failPrefix')+':' + err.response.data.error;
                              //console.log(err.response.data.error);
                              //console.log(this.message);
                         });
               },

               apiGetAllStuff() {
                    TagService.getAll(1, 999)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;

                                   this.categoryList = data
                                        .filter((ele) => {
                                             return ele.type == 'scategory';
                                        })
                                        .map((ele) => {
                                             return {
                                                  text: ele.name,
                                                  value: ele._id,
                                             };
                                        });
                                   this.unitList = data
                                        .filter((ele) => {
                                             return ele.type == 'sunit';
                                        })
                                        .map((ele) => {
                                             return {
                                                  text: ele.name,
                                                  value: ele._id,
                                             };
                                        });
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },

               getRecord() {
                    ServiceService.getService(this._id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;

                                   this.name = data.name;
                                   this.serviceid = data.serviceid;
                                   this.duration = data.duration;
                                   this.category = { text: data.category };
                                   this.unit = { text: data.unit };
                                   this.description = data.description;
                                   this.price = data.price;
                                   this.cost = data.cost;
                                   this.ispercentage =
                                        data.commissiontype == 'percentage';
                                   this.commission = data.commission;
                                   this.isActive = data.status == 'active';
                                   this.ispersist = data.persist;
                                   this.previewImage = data.image;
                                   this.discountable =
                                        data.discountable != null
                                             ? data.discountable
                                             : true;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.error_message = e.message;
                         });
               },

               async editRecord() {
                    if (!this.$refs.form.validate()) {
                         //console.log('form invalid!');
                         return;
                    }
                    //check need to create new category?
                    //console.log(this.category);
                    if (typeof this.category === 'string') {
                         this.category = { text: this.category };
                         let cat_result = await TagService.addTag({
                              name: this.category.text,
                              type: 'scategory',
                         });
                         //console.log(cat_result);
                         if (cat_result.status != 200) {
                              this.$emit(
                                   'onFailAction',
                                   this.$t('service.categoryerrormsg')
                              );
                              return;
                         }
                    }
                    //check need to create new unit?
                    //console.log(this.unit);
                    if (typeof this.unit === 'string') {
                         this.unit = { text: this.unit };
                         let unit_result = await TagService.addTag({
                              name: this.unit.text,
                              type: 'sunit',
                         });
                         //console.log(unit_result);
                         if (unit_result.status != 200) {
                              this.$emit('onFailAction', this.$t('service.uniterrormsg'));
                              return;
                         }
                    }

                    let edit_service = {
                         _id: this._id,
                         name: this.name,
                         serviceid: this.serviceid,
                         category: this.category.text,
                         duration: this.duration,
                         price: this.price,
                         cost: this.cost,
                         unit: this.unit.text,
                         commissiontype: this.ispercentage ? 'percentage' : 'fixed',
                         commission: this.commission,
                         description: this.description,
                         image: this.previewImage,
                         persist: this.ispersist,
                         status: this.isActive ? 'active' : 'inactive',
                         discountable: this.discountable,
                    };
                    //console.log(edit_service);

                    ServiceService.updateService(edit_service)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.reloadServiceCache();
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +':' + this.name
                                   );
                              }
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               async addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    //check need to create new category?
                    let category_text = null;
                    if (typeof this.category === 'string') {
                         category_text = this.category;
                         this.category = { text: this.category };
                         let cat_result = await TagService.addTag({
                              name: this.category.text,
                              type: 'scategory',
                         });
                         //console.log(cat_result);
                         if (cat_result.status != 200) {
                              this.error_message = this.$t('service.categoryerrormsg');
                              this.$emit('onFailAction', this.error_message);
                              return;
                         }
                    } else {
                         category_text = this.category.text;
                    }

                    //check need to create new unit?
                    //console.log(this.unit);
                    let unit_text = null;
                    if (typeof this.unit === 'string') {
                         unit_text = this.unit;
                         this.unit = { text: this.unit };
                         let unit_result = await TagService.addTag({
                              name: this.unit.text,
                              type: 'sunit',
                         });
                         //console.log(unit_result);
                         if (unit_result.status != 200) {
                              this.error_message = this.$t('service.uniterrormsg');
                              this.$emit('onFailAction', this.error_message);
                              return;
                         }
                    } else {
                         unit_text = this.unit.text;
                    }

                    let new_service = {
                         name: this.name,
                         serviceid: this.serviceid,
                         category: category_text,
                         price: this.price,
                         cost: this.cost,
                         unit: unit_text,
                         description: this.description,
                         commissiontype: this.ispercentage ? 'percentage' : 'fixed',
                         persist: this.ispersist,
                         commission: this.commission,
                         duration: this.duration,
                         image: this.previewImage,
                         status: 'active',
                         discountable: this.discountable,
                    };
                    //console.log(new_product);

                    ServiceService.addService(new_service)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.reloadServiceCache();
                              this.$emit(
                                   'onClickInput',
                                   this.$t('global.successPrefix') +':' + this.name
                              );
                              this.reloadServiceCache();
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               cancel() {
                    //this.clearAllField();
                    this.$emit('onClickCancel', null);
               },

               reloadServiceCache() {
                    BrowserSession.renewSession('service', null);
               },

               cancelInput() {
                    this.imageDialog = false;
               },
               successInput(file) {
                    this.imageDialog = false;
                    this.previewImage = file;
               },
               failInput() {
                    this.imageDialog = false;
                    this.error_message = 'Cannot select this file';
                    this.is_fail = true;
               },
          },
          created() {
               this.apiGetAllStuff();
               if (this._id) this.getRecord();
          },

          components: {
               ImageDialog,
          },
     };
</script>
