<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span class="heading_color--text">
                         {{ $t('global.newrecord') }}
                    </span>
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div class="px-10 py-5 post_bg">
               <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="name"
                                   :counter="50"
                                   :rules="nameRules"
                                   :label="$t('package.name')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="packid"
                                   :label="$t('package.id')"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0 d-flex flex-row" cols="6">
                              <v-text-field
                                   v-model="price"
                                   prefix="$"
                                   :label="$t('package.price')"
                                   :hint="$t('global.required')"
                                   type="number"
                                   :rules="[numberRules]"
                                   persistent-hint
                                   outlined
                                   dense
                                   class="pr-1"
                                   background-color="#ffffff"
                              ></v-text-field>
                              <v-text-field
                                   v-model="cost"
                                   prefix="$"
                                   :label="$t('package.cost')"
                                   :hint="$t('global.required')"
                                   type="number"
                                   :rules="[numberRules]"
                                   persistent-hint
                                   outlined
                                   dense
                                   class="pl-1"
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0" cols="6">
                              <div class="d-flex justify-space-between">
                                   <v-text-field
                                        v-model="credit"
                                        :label="$t('package.convertcredit')"
                                        :rules="[numberRules]"
                                        :hint="$t('global.required')"
                                        type="number"
                                        persistent-hint
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </div>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-combobox
                                   v-model="unit"
                                   :items="unitList"
                                   item-text="name"
                                   item-value="_id"
                                   hide-selected
                                   :hint="$t('package.inputunit')"
                                   :label="$t('package.unit')"
                                   persistent-hint
                                   outlined
                                   dense
                                   return-object
                                   background-color="#ffffff"
                              ></v-combobox>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="effectivemonth"
                                   :label="$t('package.duration')"
                                   :rules="[numberRules]"
                                   type="number"
                                   :suffix="$t('package.month')"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0" cols="8">
                              <v-textarea
                                   v-model="description"
                                   :label="$t('package.detail')"
                                   rows="3"
                                   outlined
                                   hide-details
                                   background-color="#ffffff"
                              >
                              </v-textarea>
                         </v-col>
                         <v-col class="py-0" cols="4">
                              <div
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <v-text-field
                                        v-model="commission"
                                        :suffix="ispercentage ? '%' : ''"
                                        :prefix="ispercentage ? '' : '$'"
                                        outlined
                                        :label="$t('package.commission')"
                                        :hint="$t('global.required')"
                                        type="number"
                                        persistent-hint
                                        :rules="[numberRules]"
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                                   <v-checkbox
                                        v-model="ispercentage"
                                        label="%"
                                        dense
                                        hide-details
                                   ></v-checkbox>
                              </div>
                              <v-checkbox
                                   v-model="ispersist"
                                   :label="$t('package.allowpersist')"
                                   dense
                                   hide-details
                              ></v-checkbox>
                              <v-checkbox
                                   v-model="discountable"
                                   :label="$t('package.allowdiscount')"
                                   dense
                                   hide-details
                              ></v-checkbox>
                         </v-col>
                    </v-row>

                    <v-row class="ml-3 mt-5 mb-2">
                         <div class="d-flex align-center">
                              <span class="pr-3">
                                   {{$t('package.specifyservice')}}
                              </span>
                              <v-btn
                                   icon
                                   small
                                   @click="addLinkService"
                                   color="primary"
                              >
                                   <v-icon>mdi-plus-circle</v-icon>
                              </v-btn>
                         </div>
                    </v-row>

                    <v-row
                         v-for="(service, index) in linkedService"
                         :key="service._id"
                    >
                         <v-col cols="6" class="py-1">
                              <v-select
                                   :items="apiServiceList"
                                   v-model="service.obj"
                                   item-text="name"
                                   item-value="_id"
                                   return-object
                                   outlined
                                   dense
                                   hide-details
                                   class="px-1"
                              >
                              </v-select>
                         </v-col>
                         <v-col cols="6" class="d-flex py-1">
                              <v-text-field
                                   v-model="service.max_usage"
                                   :label="$t('package.limit')"
                                   :rules="[numberRules]"
                                   dense
                                   type="number"
                                   hide-details
                                   outlined
                                   class="px-1"
                              ></v-text-field>
                              <v-text-field
                                   v-model="service.credit"
                                   :label="$t('package.consumecredit')"
                                   :rules="[numberRules]"
                                   dense
                                   type="number"
                                   hide-details
                                   outlined
                                   class="px-1"
                              ></v-text-field>
                              <v-btn
                                   icon
                                   small
                                   @click="removeLinkService(index)"
                                   class="mt-1"
                                   color="primary"
                              >
                                   <v-icon>mdi-delete-circle</v-icon>
                              </v-btn>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="py-0">
                              <v-switch
                                   color="primary"
                                   v-model="isActive"
                                   :label="$t('package.status')"
                                   hide-details
                                   inset
                                   :disabled="_id == null"
                              >
                              </v-switch>
                         </v-col>
                         <v-col class="py-0"> </v-col>
                    </v-row>
                    <div class="mt-5"></div>
               </v-form>
          </div>
          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="!_id"
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add')}}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="editRecord"
                    >
                         {{$t('global.modify')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>

          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Update Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import TagService from '@/utils/api/TagService';
     import PackageService from '@/utils/api/PackageService';
     import ServiceService from '@/utils/api/ServiceService';
     import BrowserSession from '@/utils/BrowserSession';

     export default {
          props: {
               _id: String,
          },
          data: () => ({
               valid: true,
               name: '',
               nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) =>
                         (v && v.length <= 50) ||
                         'Name must be less than 50 characters',
               ],
               packid: '',
               price: null,
               cost: null,
               credit: null,
               numberRules: (v) => {
                    if (!v && v != 0) return 'This field is required!';
                    if (v.length == 0) return 'This field is required!';
                    if (!isNaN(v)) return true;
                    return 'Please input a number';
               },
               //unit: {text: '次'},
               //unitList: ['次','張','個','份'],
               unit: null,
               unitList: [],
               effectivemonth: 12,
               description: '',
               ispersist: true,
               isActive: true,

               commission: 0.0,
               ispercentage: false,
               discountable: false,

               apiServiceList: [],
               linkedService: [],

               servicedialog: false,
               is_fail: false,
               error_message: '',
          }),
          methods: {
               validate() {
                    this.$refs.form.validate();
               },

               resetValidation() {
                    this.$refs.form.resetValidation();
               },

               apiGetAllService() {
                    ServiceService.getAllActive(1, 999)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.apiServiceList = response.data.data;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },

               apiGetAllStuff() {
                    TagService.getTagsByGroup('punit')
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.unitList = response.data.data;
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },

               getRecord() {
                    PackageService.getPackage(this._id).then((response) => {
                         if (response.data.status == 'success') {
                              let data = response.data.data;

                              this.name = data.name;
                              this.packid = data.packid;
                              this.description = data.description;
                              this.price = data.price;
                              this.unit = { name: data.unit };
                              this.cost = data.cost;
                              this.credit = data.credit;
                              this.effectivemonth = data.effective_month;
                              data.link_services.map((item, index) => {
                                   let obj = {
                                        obj: null,
                                        max_usage: item.max_usage,
                                        credit: item.credit,
                                   };
                                   if (item.id == data.servicesobj[index]._id) {
                                        obj.obj = data.servicesobj[index];
                                   }
                                   this.linkedService.push(obj);
                              });
                              this.ispersist = data.persist;
                              this.isActive = data.status == 'active' ? true : false;
                              this.commission = data.commission;
                              this.ispercentage =
                                   data.commissiontype == 'percentage'
                                        ? true
                                        : false;
                              this.discountable =
                                   data.discountable != null
                                        ? data.discountable
                                        : false;
                         }
                    });
               },

               async addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }

                    //check need to create new unit?
                    //console.log(this.unit);
                    if (typeof this.unit === 'string') {
                         this.unit = { name: this.unit };
                         let unit_result = await TagService.addTag({
                              name: this.unit.name,
                              type: 'punit',
                         });
                         //console.log(unit_result);
                         if (unit_result.status != 200) {
                              this.$emit('onFailAction', this.$t('service.uniterrormsg'));
                              return;
                         }
                    }

                    let constr_service = [];
                    this.linkedService.map((s) => {
                         constr_service.push({
                              _id: s.obj._id,
                              name: s.obj.name,
                              max_usage: s.max_usage,
                              credit: s.credit,
                         });
                    });

                    let new_package = {
                         name: this.name,
                         packid: this.packid,
                         description: this.description,
                         price: this.price,
                         unit: this.unit.name,
                         cost: this.cost,
                         credit: this.credit,
                         effective_month: this.effectivemonth,
                         link_services: constr_service,
                         persist: this.ispersist,
                         status: 'active',
                         commission: this.commission,
                         commissiontype: this.ispercentage ? 'percentage' : 'fixed',
                         discountable: this.discountable,
                    };

                    //console.log(new_package);

                    PackageService.addPackage(new_package)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +':'+ this.name
                                   );
                              this.reloadPackageCache();
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               async editRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }

                    //check need to create new unit?
                    //console.log(this.unit);
                    if (typeof this.unit === 'string') {
                         this.unit = { text: this.unit };
                         let unit_result = await TagService.addTag({
                              name: this.unit.text,
                              type: 'punit',
                         });
                         //console.log(unit_result);
                         if (unit_result.status != 200) {
                              this.$emit('onFailAction', this.$t('service.uniterrormsg'));
                              return;
                         }
                    }

                    let constr_service = [];
                    this.linkedService.map((s) => {
                         constr_service.push({
                              _id: s.obj._id,
                              name: s.obj.name,
                              max_usage: s.max_usage,
                              credit: s.credit,
                         });
                    });

                    let new_package = {
                         _id: this._id,
                         name: this.name,
                         packid: this.packid,
                         description: this.description,
                         price: this.price,
                         cost: this.cost,
                         unit: this.unit.name,
                         credit: this.credit,
                         effective_month: this.effectivemonth,
                         link_services: constr_service,
                         persist: this.ispersist,
                         commission: this.commission,
                         commissiontype: this.ispercentage ? 'percentage' : 'fixed',
                         discountable: this.discountable,
                         status: this.isActive ? 'active' : 'inactive',
                    };

                    //console.log(new_package);

                    PackageService.updatePackage(new_package)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +':'+ this.name
                                   );
                              this.reloadPackageCache();
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               addLinkService() {
                    this.linkedService.push({ obj: null, max_usage: 0, credit: 1 });
               },

               removeLinkService(idx) {
                    this.linkedService.splice(idx, 1);
               },

               cancel() {
                    //this.clearAllField();
                    this.$emit('onClickCancel', null);
               },

               reloadPackageCache() {
                    BrowserSession.renewSession('package', null);
               },
          },
          created() {
               this.apiGetAllService();
               this.apiGetAllStuff();

               if (this._id) this.getRecord();
          },
     };
</script>
