import http from '../HttpConn';
var isObject = require('lodash/isObject');

class CommissionService {
     getCount() {
          return http.get('/commission/count');
     }
     getAll() {
          return http.get('/commission');
     }
     getCommission(id) {
          return http.get('/commission/' + id);
     }
     /* eslint-disable */
     updateCommission(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.put('/commission/update/' + ccobj._id, ccobj);
     }
     addCommission(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/commission/add', ccobj);
     }
     deleteCommission(id) {
          return http.delete('/commission/delete/' + id);
     }
     /* eslint-enable */
}
export default new CommissionService();
