<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3">
               <v-container fluid>
                    <v-row class="pa-5 my-auto" align="center">
                         <span class="text1_color--text">
                              {{ $t('report.paymentreport') }}
                         </span>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div
                                   v-html="$t('report.paymentreporttips')"
                              ></div>
                         </v-tooltip>
                         <v-spacer></v-spacer>
                         <v-menu
                              v-model="showsdate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                        v-model="dateRangeText"
                                        :label="$t('report.daterange')"
                                        style="max-width: 220px"
                                        hide-details
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                   ></v-text-field>
                              </template>
                              <v-date-picker
                                   v-model="date_range"
                                   range
                              ></v-date-picker>
                         </v-menu>
                         <v-menu
                              bottom
                              origin="center center"
                              transition="scale-transition"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                             >mdi-arrow-down-drop-circle</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list>
                                   <v-list-item
                                        :input-value="datef == 'today'"
                                        @click="setFilterDate('today')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.today') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last7days'"
                                        @click="setFilterDate('last7days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past7day') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last30days'"
                                        @click="setFilterDate('last30days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past1month') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'thismonth'"
                                        @click="setFilterDate('thismonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.thismonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'lastmonth'"
                                        @click="setFilterDate('lastmonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.pastmonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <!--<span>&nbsp;&nbsp;</span>
                         <v-select
                              v-model="selectStatus"
                              :items="paymentStatus"
                              attach
                              chips
                              label="付款狀態"
                              multiple
                              outlined
                              dense
                              style="max-width: 180px"
                              class="my-auto"
                              hide-details
                         >
                              <template v-slot:selection="{ item, index }">
                                   <v-chip v-if="index === 0" small>
                                        <span>{{ item }}</span>
                                   </v-chip>
                                   <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                   >
                                        (+{{ selectStatus.length - 1 }}...)
                                   </span>
                              </template>
                         </v-select>-->
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold my-auto"
                              @click="getRecords"
                              :loading="isLoading"
                         >
                              <span
                                   class="white--text"
                                   >{{ $t('report.executereport') }}</span
                              >
                         </v-btn>
                    </v-row>
               </v-container>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="d-flex flex-row align-center">
                    <div style="width: 450px">
                         <GChart
                              type="PieChart"
                              :data="chartvalue"
                              :options="{
                                   title: 'Payment Type',
                                   legend: { position: 'bottom' },
                                   width: 350,
                                   height: 350,
                                   chartArea: {
                                        left: 80,
                                        top: 80,
                                        right: 10,
                                        width: '100%',
                                        height: '60%',
                                   },
                              }"
                         />
                    </div>
                    <div class="pa-5" style="width: 300px">
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.invoicestatusstat') }} : </span
                         ><br />
                         <span
                              class="text1_color--text text-body-2 ml-3"
                              style="width: 100px; display: inline-block"
                              >{{ $t('invoice.unpaid') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(Total_init) }}</span
                         ><br />
                         <span
                              class="text1_color--text text-body-2 ml-3"
                              style="width: 100px; display: inline-block"
                              >{{ $t('invoice.paid') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(Total_done) }}</span
                         ><br />
                         <span
                              class="text1_color--text text-body-2 ml-3"
                              style="width: 100px; display: inline-block"
                              >{{ $t('invoice.void') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(Total_void) }}</span
                         >
                         <p />
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.paymentstat') }} : </span
                         ><br />
                         <div
                              v-for="paytype in ApiPaymentListName"
                              :key="paytype.name"
                         >
                              <div v-if="paytype.amount>0">
                                   <span
                                        class="text1_color--text text-body-2 ml-3"
                                        style="width: 100px; display: inline-block"
                                        >{{ paytype.displayname }} :
                                   </span>
                                   <span
                                        class="text-decoration-underline font-weight-bold"
                                        v-if="paytype.name != 'stock'"
                                        >$
                                        {{ formatCurrency(paytype.amount) }}</span
                                   >
                                   <span
                                        class="text-decoration-underline font-weight-bold"
                                        v-else
                                        >Cr
                                        {{ formatCurrency(paytype.amount) }}</span
                                   >
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <!--<v-col cols="3">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text"
                                        >單據編號</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text"
                                        >單據總額</span
                                   >
                              </v-col>-->
                              <v-col cols="3">
                                   <span class="mx-5"> &nbsp; </span>
                                   <span
                                        class="caption heading_color--text"
                                        >{{$t('invoice.paymentmethod')}}</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        class="caption heading_color--text"
                                        >{{$t('invoice.amount')}}</span
                                   >
                              </v-col>
                              <v-col cols="3">
                                   <span
                                        class="caption heading_color--text"
                                        >{{$t('invoice.invoiceno')}}</span
                                   >
                              </v-col>
                              <v-col cols="3">
                                   <span
                                        class="caption heading_color--text"
                                        >{{$t('invoice.paymentdate')}}</span
                                   >
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(payment, index) in paymentList"
                         :key="payment._id"
                         class="px-3 fill-height"
                         align="center"
                    >
                         <v-col cols="3" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        index + 1
                                   }}</span>
                              </v-avatar>
                              <!--<div class="d-flex flex-column">
                                   <div class="text1_color--text text-body-2">
                                        {{ payment.invoice.invoiceno }}
                                   </div>
                                   <div class="text2_color--text text-caption">
                                        {{ payment.customer.name }}
                                   </div>
                              </div>-->
                              <div class="text1_color--text text-body-2">
                                   {{ $t('invoice.'+payment.payment_type) }}
                              </div>
                         </v-col>

                         <!--<v-col cols="2">
                              <div class="text1_color--text text-body-2">
                                   $
                                   {{ formatCurrency(payment.invoice.amount) }}
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <div class="text1_color--text text-body-2">
                                   {{ payment.payment_type }}
                              </div>
                         </v-col>-->

                         <v-col cols="2" class="d-flex flex-column">
                              <div
                                   v-if="payment.payment_type != 'stock'"
                                   class="text1_color--text text-body-2"
                              >
                                   $ {{ formatCurrency(payment.amount) }}
                              </div>
                              <div v-else class="text1_color--text text-body-2">
                                   Cr {{ formatCurrency(payment.amount) }}
                              </div>
                         </v-col>

                         <v-col cols="3" class="d-flex flex-column">
                              <div class="text1_color--text text-body-2">
                                   {{ payment.invoice.invoiceno }}
                              </div>
                              <div class="text2_color--text text-caption">
                                   <!--{{ payment.customer.name }}-->
                                   ${{ formatCurrency(payment.invoice.amount) }}
                              </div>
                         </v-col>

                         <v-col
                              cols="3"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span
                                   class="text1_color--text text-body-2 mx-2"
                                   >{{ formatDate(payment.pay_date) }}</span
                              >
                              <div>
                                   <v-icon
                                        small
                                        v-if="payment.status == 'void'"
                                        color="#ff5252"
                                   >
                                        mdi-cancel
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="payment.status == 'done'"
                                        color="#1976d2"
                                   >
                                        mdi-checkbox-marked-circle-outline
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="payment.status == 'init'"
                                        color="orange"
                                   >
                                        mdi-progress-clock
                                   </v-icon>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>
          </div>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import { GChart } from 'vue-google-charts';
     import PaymentService from '@/utils/api/PaymentService';
     import BrowserSession from '@/utils/BrowserSession';
     //import _ from 'lodash';
     var isEmpty = require('lodash/isEmpty');
     var sortBy = require('lodash/sortBy');
     var find = require('lodash/find');
     var reverse = require('lodash/reverse');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     const moment = require('moment');

     export default {
          props: {},
          data: () => ({
               clicked: false,
               date_range: [],
               datef: 'lastmonth',
               showsdate: false,

               paymentList: [],

               selectStatus: ['init', 'done', 'void'],
               paymentStatus: ['init', 'done', 'void'],

               ApiPaymentListName: [],

               chartvalue: [['Payment', 'Amount']],

               Total_init: 0.0,
               Total_void: 0.0,
               Total_done: 0.0,

               isLoading: false,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',
          }),
          methods: {
               getRecords() {
                    if (isEmpty(this.date_range) || this.date_range.length != 2) {
                         this.error_message = 'Please select date range';
                         this.is_fail = true;
                         return;
                    }

                    this.isLoading = true;
                    this.clicked = true;

                    //console.log(this.date_range);
                    if (this.date_range[0] > this.date_range[1]) {
                         //exchange the date
                         let date_less = this.date_range[0];
                         this.date_range[0] = this.date_range[1];
                         this.date_range[1] = date_less;
                    }
                    PaymentService.getPaymentByDateRange(this.date_range)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   //console.log(response);
                                   this.Total_done = 0.0;
                                   this.Total_init = 0.0;
                                   this.Total_void = 0.0;
                                   /*this.paymentTypeTotal.map((t) => {
                                        t.amount = 0;
                                   });*/
                                   //clear all amount
                                   this.ApiPaymentListName.map((t)=>{
                                        t.amount = 0;
                                   })

                                   //console.log(response);
                                   this.paymentList = response.data.data;

                                   this.paymentList.map((pay) => {
                                        switch (pay.status) {
                                             case 'done':
                                                  this.Total_done += pay.amount;
                                                  break;
                                             case 'void':
                                                  this.Total_void += pay.amount;
                                                  break;
                                             case 'init':
                                                  this.Total_init += pay.amount;
                                                  break;
                                             default:
                                                  break;
                                        }
                                        /*let x = find(this.paymentTypeTotal, {
                                             name: pay.payment_type,
                                        });
                                        if (x == undefined) {
                                             x = find(this.paymentTypeTotal, {
                                                  name: 'other',
                                             });
                                        }*/
                                        //search corresponding payment type and sum up
                                        let x = find(this.ApiPaymentListName,{name: pay.payment_type});
                                        if( x == undefined){
                                             x = find(this.ApiPaymentListName, {name:'other'})
                                        }
                                        if (pay.status == 'done')
                                             x.amount += pay.amount;
                                   });

                                   this.chartvalue = [['Payment', 'Amount']];
                                   /*let sort_d = sortBy(this.paymentTypeTotal, [
                                        'amount',
                                   ]);*/
                                   this.ApiPaymentListName = sortBy(this.ApiPaymentListName, ['amount']);

                                   /*let sort_d = sortBy(this.ApiPaymentListName, [
                                        'amount',
                                   ]);*/

                                   let sort_d = reverse(this.ApiPaymentListName);
                                   //console.log(sort_d);

                                   sort_d.map((p) => {
                                        if (p.amount > 0) {
                                             this.chartvalue.push([
                                                  p.displayname,
                                                  p.amount,
                                             ]);
                                        }
                                   });

                                   //console.log(this.chartvalue);
                              }
                              this.isLoading = false;
                         })
                         .catch((e) => {
                              this.isLoading = false;
                              console.log(e);
                         });
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               setFilterDate(datef) {
                    this.datef = datef;

                    if (datef == 'today') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last7days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(6, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last30days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(29, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'thismonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().startOf('month').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'lastmonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment()
                                   .subtract(1, 'month')
                                   .startOf('month')
                                   .format('YYYY-MM-DD'),
                              moment()
                                   .subtract(1, 'month')
                                   .endOf('month')
                                   .format('YYYY-MM-DD'),
                         ];
                         return;
                    }
               },
          },
          computed: {
               dateRangeText() {
                    return this.date_range.join(' 至 ');
               },
          },
          components: {
               GChart,
          },
          created() {
               let x = new Date();
               x.setDate(0);
               this.date_range[1] = x.toISOString().slice(0, 10);
               x.setDate(1);
               this.date_range[0] = x.toISOString().slice(0, 10);

               this.datef = 'lastmonth';

               BrowserSession.getSession('setting', (data) => {
                         let ptype = data.find((d) => {
                              return d.key == 'invoice';
                         }).value['payment_type'];

                         //this.ApiPaymentListName = ptype.filter(e=>e.enable);
                         this.ApiPaymentListName = ptype.map(e=>{
                              return {...e, ...{amount:0, displayname:this.$t('invoice.' + e.name)}}
                         })
                         this.ApiPaymentListName.push(
                                   { name: 'reward point', amount: 0, displayname: this.$t('customer.reward') },
                                   { name: 'stock', amount: 0, displayname: this.$t('product.stock') });
                        // console.log(this.ApiPaymentListName);
                    });
          },
     };
</script>
