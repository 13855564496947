//import _ from 'lodash';
var pick = require('lodash/pick');

class JsonToCSV {
     customer_header = [
          '_id',
          'name',
          'password',
          'company',
          'gender',
          'email',
          'phone',
          'default_sales',
          'address',
          'dob',
          'description',
          'member_lv',
          'member_lv_expiry',
          'join_reward',
          'enable_member_login',
          're_point',
          'lv_point',
          'tag',
          'status',
          'created_date',
          'last_update',
     ];
     customer_csv_header = {
          _id: '_id',
          name: 'name',
          password: 'password',
          company: 'company',
          gender: 'gender',
          email: 'email',
          phone: 'phone',
          default_sales: 'staff',
          address: 'address',
          dob: 'dob',
          description: 'description',
          member_lv: 'membership',
          member_lv_expiry: 'membership expiry',
          join_reward: 'reward',
          enable_member_login: 'login',
          re_point: 'reward point',
          lv_point: 'membership point',
          tag: 'tag',
          status: 'status',
          created_date: 'created_date',
          last_update: 'last_update',
     };
     product_header = [
          '_id',
          'name',
          'sku',
          'description',
          'barcode',
          'category',
          'brand',
          'cost',
          'price',
          'unit',
          'image',
          'status',
          'persist',
          'commissiontype',
          'commission',
          'discountable',
          'created_date',
          'last_update',
     ];
     service_header = [
          '_id',
          'name',
          'serviceid',
          'description',
          'category',
          'cost',
          'price',
          'unit',
          'image',
          'duration',
          'commissiontype',
          'commission',
          'persist',
          'discountable',
          'status',
          'created_date',
          'last_update',
     ];
     package_header = [
          '_id',
          'name',
          'packid',
          'description',
          'price',
          'credit',
          'unit',
          'effective_month',
          'link_services',
          'persist',
          'status',
          'commission',
          'commissiontype',
          'discountable',
          'created_date',
          'last_update',
     ];
     invoice_header = [
          '_id',
          'invoiceid',
          'invoiceno',
          'invoice_date',
          'due_date',
          'salesid',
          'po',
          'currency',
          'subtotal',
          'discount',
          'tax',
          'amount',
          'tas',
          'notes',
          'billto',
          'product',
          'status',
          'tag',
          'created_date',
          'last_update',
     ];
     payment_header = [
          '_id',
          'invoiceid',
          'customerid',
          'amount',
          'payment_type',
          'ref',
          'pay_date',
          're_sys',
          'pt_sys',
          'earn_re',
          'earn_mk',
          'status',
          'created_date',
          'last_update',
     ];
     resource_header = [
          '_id',
          'name',
          'description',
          'shopid',
          'type',
          'status',
          'last_update',
          'created_date',
     ];
     commission_header = [
          '_id',
          'name',
          'type',
          'min_range',
          'max_range',
          'commission_type',
          'commission',
          'status',
          'last_update',
          'created_date',
     ];
     template_header = [
          '_id',
          'name',
          'userid',
          'content',
          'created_date',
          'last_update',
     ];

     async export(filename, table) {
          let api = null;
          let header = null;
          let service = null;

          if (table == 'customer') {
               service = await import('@/utils/api/CustomerService');
               header = this.customer_header;
               //get staff and member level
               let memberservice = require('@/utils/api/CustomerCategoryService');
               let response = await memberservice.default.getAll();
               let membership = response.data.data;
               console.log(membership);

               let staffservice = require('@/utils/api/UserService');
               response = await staffservice.default.getAll();
               let staff = response.data.data;
               console.log(staff);
          }
          if (table == 'product') {
               service = await import('@/utils/api/ProductService');
               header = this.product_header;
          }
          if (table == 'service') {
               service = await import('@/utils/api/ServiceService');
               header = this.service_header;
          }
          if (table == 'package') {
               service = await import('@/utils/api/PackageService');
               header = this.package_header;
          }
          if (table == 'invoice') {
               service = await import('@/utils/api/InvoiceService');
               header = this.invoice_header;
          }
          if (table == 'payment') {
               service = await import('@/utils/api/PaymentService');
               header = this.payment_header;
          }
          if (table == 'resource') {
               service = await import('@/utils/api/ResourceService');
               header = this.resource_header;
          }
          if (table == 'commission') {
               service = await import('@/utils/api/CommissionService');
               header = this.commission_header;
          }
          if (table == 'template') {
               service = await import('@/utils/api/TemplateService');
               header = this.template_header;
          }

          api = service.default;
          api.getAll()
               .then((response) => {
                    let data = response.data.data.map((data) =>
                         pick(data, header)
                    );

                    let utf8data = JSON.parse(JSON.stringify(data));

                    this.exportToCsv(filename, header, utf8data, table);

                    //export as json format
                    //console.log(data);
                    //var jsonse = JSON.stringify(data);

                    /*const url = window.URL.createObjectURL(new Blob([jsonse]));
                    const link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);*/
               })
               .catch((e) => {
                    console.log(e);
                    return false;
               });
     }

     //filename: string
     //header: array of csv header name
     //rows: array of json

     exportToCsv(filename, header, rows, table) {
          var processRow = function (row) {
               var finalVal = '';

               header.map((h, j) => {
                    var innerValue =
                         row[h] === null || row[h] === undefined
                              ? ''
                              : row[h].toString();
                    if (row[h] instanceof Date) {
                         innerValue = row[h].toLocaleString();
                    }
                    var result = innerValue.replace(/"/g, '""');
                    if (result.search(/("|,|\n)/g) >= 0)
                         result = '"' + result + '"';
                    if (j > 0) finalVal += ',';
                    finalVal += result;
               });
               return finalVal + '\n';
          };

          //build header row
          var csvFile = '';
          /*header.map((h, j) => {
               if (j > 0) csvFile += ',';
               csvFile += h;
          });*/
          csvFile = this.buildHeader(table, header);
          csvFile += '\n';

          //build data rows
          for (var i = 0; i < rows.length; i++) {
               csvFile += processRow(rows[i]);
          }

          var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvFile], {
               type: 'text/csv;charset=utf-8;',
          });
          if (navigator.msSaveBlob) {
               // IE 10+
               navigator.msSaveBlob(blob, filename);
          } else {
               var link = document.createElement('a');
               if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
               }
          }
     }

     buildHeader(type, header) {
          if (type == 'customer') {
               let ch = [];
               header.map((h) => {
                    ch.push(this.customer_csv_header[h]);
               });

               return ch.join(',');
          }

          return header.join(',');
     }
}
export default new JsonToCSV();
