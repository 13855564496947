import http from '../HttpConn';
var isObject = require('lodash/isObject');
class UserService {
     getAll(page, limit) {
          return http.get('/user', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page, limit) {
          return http.get('/user/allactive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/user/count');
     }
     getUser(id) {
          return http.get('/user/' + id);
     }
     getUserByName(name) {
          return http.get('/user/name/' + name);
     }
     getAllActiveUserResource() {
          return http.get('/user/allactiveuserresource');
     }

     /* eslint-disable */
     updateUser(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/user/update/' + ccobj._id, ccobj);
     }
     addUser(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/user/add', ccobj);
     }
     deleteUser(id) {
          return http.delete('/user/delete/' + id);
     }
     disableUser(id) {
          return http.put('/user/updatestatus/' + id, { status: 'inactive' });
     }
     checkLogin(ccobj) {
          return http.post('/user/login', ccobj);
     }
     checkToken() {
          return http.head('/user/session');
     }
     checkTokenAndRenew(ccobj) {
          return http.post('/user/renewLogin', ccobj);
     }
     /* eslint-enable */
}
export default new UserService();
