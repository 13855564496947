import http from '../HttpConn';
var isObject = require('lodash/isObject');
class TagService {
     getAll(page, limit) {
          return http.get('/tag', {
               params: { page: page, limit: limit },
          });
     }
     getTag(id) {
          return http.get('/tag/' + id);
     }
     getTagsByGroup(group) {
          return http.get('/tag/group/' + group);
     }
     /* eslint-disable */
     updateTag(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/tag/update/' + ccobj._id, ccobj);
     }
     addTag(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/tag/add', ccobj);
     }
     deleteTag(id) {
          return http.delete('/tag/delete/' + id);
     }
     /* eslint-enable */
}
export default new TagService();
