<template>
     <div class="pa-5">
          <div class="pb-10 d-flex flex-row justify-space-between">
               <span class="my-auto">{{ $t('components.invoice') }}</span>
               <v-btn
                    color="primary"
                    class="body-2 font-weight-bold"
                    @click="showAddInvoice"
               >
                    <v-icon size="18">mdi-plus</v-icon>
                    <span class="white--text">{{ $t('global.add') }}</span>
               </v-btn>
          </div>

          <v-list>
               <v-list-item
                    v-for="(invoice, index) in invoiceList"
                    :key="index"
                    class="mb-3"
               >
                    <!--<v-list-item-avatar height="60" width="80">-->
                    <div class="pr-7">
                         <v-avatar
                              style="
                                   border: solid;
                                   border-width: 1px;
                                   border-radius: 50%;
                              "
                              :class="`invUser-${invoice.status}`"
                              size="50"
                         >
                              <span
                                   class="body-2"
                                   :class="`invUser-${invoice.status}`"
                                   >{{ invoice.status }}</span
                              >
                         </v-avatar>
                    </div>
                    <!--</v-list-item-avatar>-->
                    <v-list-item-content>
                         <v-list-item-title class="body-2 font-weight-light"
                              >#{{ invoice.invoiceno }}</v-list-item-title
                         >
                         <v-list-item-title class="body-2 font-weight-light">
                              {{ $t('invoice.amount') }} :
                              <strong
                                   >${{
                                        formatCurrency(invoice.amount)
                                   }}</strong
                              ></v-list-item-title
                         >
                         <v-list-item-subtitle
                              class="caption font-weight-light pt-2"
                              >@
                              {{ invoice.invoice_date }}</v-list-item-subtitle
                         >
                    </v-list-item-content>
                    <v-btn
                         icon
                         small
                         class="mr-3"
                         v-if="invoice.status == 'unpaid'"
                         @click="modifyRecord(invoice._id)"
                    >
                         <v-icon
                              v-if="invoice.status == 'unpaid'"
                              color="primary"
                              >mdi-pencil-circle</v-icon
                         >
                    </v-btn>
                    <a :href="'/invoicepdf?_id=' + invoice._id" target="_blank">
                         <v-icon color="primary">mdi-printer-eye</v-icon>
                    </a>
               </v-list-item>
          </v-list>

          <div class="text-center body2 font-weight-light">
               <v-btn v-if="!noMoreRecord" icon @click="apiPullRecords">
                    <v-icon color="primary">mdi-chevron-down</v-icon>
               </v-btn>
               <span v-else>{{ $t('global.end') }}</span>
          </div>

          <v-progress-circular
               v-if="isLoading"
               indeterminate
               color="primary"
               class="bottom"
          />

          <v-dialog
               v-model="editdialog"
               persistent
               max-width="900px"
               class="pa-1 background"
          >
               <InvoiceEditDialog
                    v-if="editdialog"
                    @onClickCancel="onClickCancel"
                    @onClickInput="onClickInput"
                    @onFailAction="onFail"
                    :_id="selectedid"
                    isEdit="true"
               />
          </v-dialog>
     </div>
</template>

<script>
import InvoiceService from '@/utils/api/InvoiceService';
import InvoiceEditDialog from '@/views/invoice/invoice/InvoiceAddView';

const moment = require('moment');

const numformat = new Intl.NumberFormat('en-US', {
     minimumFractionDigits: 2,
     maximumFractionDigits: 2,
});

export default {
     props: {
          customerobj: Object,
     },
     data: () => ({
          isLoading: false,
          noMoreRecord: false,

          page: 1,
          recordperpage: 5,
          invoiceList: [],

          editdialog: false,
          selectedid: null,
     }),
     methods: {
          apiGetRecords() {
               InvoiceService.getInvoiceByUserid(
                    this.customerobj._id,
                    this.page,
                    this.recordperpage
               )
                    .then((response) => {
                         //console.log(response.data);
                         if (response.data.status == 'success') {
                              let data = response.data.data;
                              if (data.length <= 0) {
                                   this.noMoreRecord = true;
                              }
                              this.invoiceList = data;
                         }
                    })
                    .catch((e) => {
                         console.log(e);
                    });
          },
          apiPullRecords() {
               if (this.noMoreRecord) {
                    return;
               }
               this.isLoading = true;
               InvoiceService.getInvoiceByUserid(
                    this.customerobj._id,
                    ++this.page,
                    this.recordperpage
               )
                    .then((response) => {
                         if (response.data.status == 'success') {
                              let data = response.data.data;
                              if (data.length <= 0) {
                                   this.noMoreRecord = true;
                              }
                              this.invoiceList = this.invoiceList.concat(data);
                         }
                         this.isLoading = false;
                    })
                    .catch((e) => {
                         this.isLoading = false;
                         console.log(e);
                    });
          },
          modifyRecord(_id) {
               this.selectedid = _id;
               this.editdialog = true;
          },
          onClickCancel() {
               this.editdialog = false;
          },
          onClickInput() {
               this.editdialog = false;
               this.apiGetRecords();
          },
          onFail() {
               this.editdialog = false;
               this.apiGetRecords();
          },
          showAddInvoice() {
               /*if (!Permission.isPageHavePermission(this.managekey)) {
                    this.error_message =
                         'Sorry, you dont have permission to access!';
                    this.is_fail = true;
                    return;
               }*/
               this.$router.push({
                    name: 'invoiceadd',
                    params: { cid: this.customerobj._id },
               });
          },
          formatDate(value) {
               return moment(value).format('YYYY-MM-DD');
          },
          formatCurrency(total) {
               return numformat.format(total);
          },
     },
     created() {
          if (this.customerobj != null) this.apiGetRecords();
     },
     components: {
          InvoiceEditDialog,
     },
};
</script>

<style>
.invUser-unpaid {
     border-color: orange !important;
     color: orange !important;
}
.invUser-paid {
     border-color: #1976d2 !important;
     color: #1976d2 !important;
}
.invUser-void {
     border-color: #ff5252 !important;
     color: #ff5252 !important;
}
</style>
