<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3">
               <v-container fluid>
                    <v-row class="pa-5 my-auto" align="center">
                         <span class="text1_color--text">
                              {{ $t('report.employeedetailreport') }}
                         </span>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div
                                   v-html="$t('report.employeedetailreporttips')"
                              ></div>
                         </v-tooltip>
                         <v-spacer></v-spacer>

                         <v-menu
                              v-model="showsdate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                        v-model="dateRangeText"
                                        :label="$t('report.daterange')"
                                        style="max-width: 220px"
                                        hide-details
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                   ></v-text-field>
                              </template>
                              <v-date-picker
                                   v-model="date_range"
                                   range
                              ></v-date-picker>
                         </v-menu>
                         <v-menu
                              bottom
                              origin="center center"
                              transition="scale-transition"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                             >mdi-arrow-down-drop-circle</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list>
                                   <v-list-item
                                        :input-value="datef == 'today'"
                                        @click="setFilterDate('today')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.today') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last7days'"
                                        @click="setFilterDate('last7days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past7day') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last30days'"
                                        @click="setFilterDate('last30days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past1month') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'thismonth'"
                                        @click="setFilterDate('thismonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.thismonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'lastmonth'"
                                        @click="setFilterDate('lastmonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.pastmonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;</span>
                         <v-select
                              v-model="selectStatus"
                              :items="invoiceStatus"
                              attach
                              chips
                              :label="$t('report.invoicestatus')"
                              multiple
                              outlined
                              dense
                              style="max-width: 170px"
                              class="my-auto"
                              hide-details
                         >
                              <template v-slot:selection="{ item, index }">
                                   <v-chip v-if="index === 0" small>
                                        <span>{{ item.text }}</span>
                                   </v-chip>
                                   <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                   >
                                        (+{{ selectStatus.length - 1 }}...)
                                   </span>
                              </template>
                         </v-select>
                    </v-row>

                    <v-row class="px-5 my-auto" align="center">
                         <v-spacer></v-spacer>
                         <v-autocomplete
                              v-model="selectuser"
                              :items="apiUserList"
                              item-text="name"
                              item-value="_id"
                              :label="$t('report.selectsales')"
                              style="max-width: 170px"
                              class="mx-2"
                              dense
                              hide-details
                              outlined
                              return-object
                         ></v-autocomplete>
                         <v-select
                              v-model="selectCommissionBy"
                              :label="$t('report.target')"
                              :items="commissionStatus"
                              outlined
                              dense
                              style="max-width: 170px"
                              class="my-auto ml-2"
                              hide-details
                         >
                         </v-select>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div v-html="$t('report.targettips')"></div>
                         </v-tooltip>

                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold my-auto"
                              @click="getRecords"
                              :loading="isLoading"
                         >
                              <span class="white--text">
                                   {{ $t('report.executereport') }}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="4">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.invoiceno') }}
                                   </span>
                                   <v-btn
                                        color="info"
                                        icon
                                        @click="
                                             selectShowDetail =
                                                  !selectShowDetail
                                        "
                                   >
                                        <v-icon v-if="!selectShowDetail" small
                                             >mdi-chevron-down</v-icon
                                        >
                                        <v-icon v-else small
                                             >mdi-chevron-up</v-icon
                                        >
                                   </v-btn>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.amount') }}
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        v-if="selectedtype == 0"
                                        class="caption heading_color--text"
                                   >
                                        {{ $t('product.cost') }}
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        v-if="selectedtype == 0"
                                        class="caption heading_color--text"
                                   >
                                        {{ $t('product.commission') }}
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span
                                        v-if="selectedtype == 0"
                                        class="caption heading_color--text"
                                        >{{$t('report.net')}}</span
                                   >
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(invoice, index) in invoiceList"
                         :key="invoice._id"
                         class="px-3 fill-height"
                         align="center"
                    >
                         <v-col cols="4" class="d-flex flex-row align-center">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="text1_color--text text-body-2">
                                   {{ invoice.invoiceno }}
                              </div>
                              <div class="pl-3">
                                   <v-icon
                                        small
                                        v-if="invoice.status == 'void'"
                                        color="#ff5252"
                                   >
                                        mdi-cancel
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="invoice.status == 'paid'"
                                        color="#1976d2"
                                   >
                                        mdi-checkbox-marked-circle-outline
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="invoice.status == 'unpaid'"
                                        color="orange"
                                   >
                                        mdi-progress-clock
                                   </v-icon>
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div class="text1_color--text text-body-2">
                                   $ {{ formatCurrency(invoice.amount) }}
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <div
                                   v-if="selectedtype == 0"
                                   class="text1_color--text text-body-2 my-auto"
                              >
                                   ($ {{ formatCurrency(invoice.totalcost) }})
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <span
                                   v-if="selectedtype == 0"
                                   class="text1_color--text text-body-2 my-auto"
                              >
                                   ($
                                   {{formatCurrency(invoice.totalcommission)}})
                              </span>
                         </v-col>

                         <v-col
                              cols="2"
                              class="d-flex flex-row justify-space-between"
                         >
                              <span
                                   v-if="selectedtype == 0"
                                   class="text1_color--text text-body-2 my-auto"
                              >
                                   $
                                   {{
                                        formatCurrency(
                                             invoice.amount -
                                                  invoice.totalcost -
                                                  invoice.totalcommission
                                        )
                                   }}</span
                              >
                         </v-col>

                         <v-card
                              v-if="selectShowDetail"
                              class="ml-5 mb-5"
                              width="85%"
                              outlined
                         >
                              <v-list-item>
                                   <v-list-item-content>
                                        <div
                                             class="text-caption mb-5 grey--text font-weight-bold"
                                        >
                                             <v-row>
                                                  <v-col cols="4">
                                                       {{ $t('product.name') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('product.price') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('invoice.qty') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('product.cost') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('product.commission') }}
                                                  </v-col>
                                             </v-row>
                                        </div>
                                        <div>
                                             <v-row
                                                  v-for="product in invoice.product"
                                                  :key="product._id"
                                                  class="fill-height"
                                                  align="center"
                                             >
                                                  <v-col
                                                       cols="4"
                                                       class="text-caption text2_color--text"
                                                       >{{
                                                            product.name
                                                       }}</v-col
                                                  >
                                                  <v-col
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >$
                                                       {{
                                                            formatCurrency(
                                                                 product.price
                                                            )
                                                       }}</v-col
                                                  >
                                                  <v-col
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >{{ product.qty }}</v-col
                                                  >
                                                  <v-col
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >$
                                                       {{
                                                            formatCurrency(
                                                                 product.cost
                                                            )
                                                       }}</v-col
                                                  >
                                                  <v-col
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >$
                                                       {{
                                                            formatCurrency(
                                                                 product.commission
                                                            )
                                                       }}</v-col
                                                  >
                                             </v-row>
                                        </div>
                                   </v-list-item-content>
                              </v-list-item>
                         </v-card>
                    </v-row>
               </v-container>
          </div>

          <div
               v-if="this.clicked"
               class="white rounded elevation-3 mx-3 pa-5 mt-7"
          >
               <div class="d-flex flex-column align-end">
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalsales') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(invoiceTotal) }}</span
                         >
                    </div>
                    <div
                         v-if="selectedtype == 0"
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalcost') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(costTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalcommission') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(commissionTotal) }}</span
                         >
                    </div>
                    <div
                         v-if="selectedtype == 0"
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalnet') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $
                              {{
                                   formatCurrency(
                                        invoiceTotal -
                                             costTotal -
                                             commissionTotal
                                   )
                              }}</span
                         >
                    </div>
                    <div
                         v-if="selectedtype == 1"
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalqty') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              {{ itemTotal }}</span
                         >
                    </div>
               </div>
          </div>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import BrowserSession from '@/utils/BrowserSession';
     import InvoiceService from '@/utils/api/InvoiceService';
     import Permission from '@/utils/Permission';
     //import _ from 'lodash';
     var isEmpty = require('lodash/isEmpty');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     const moment = require('moment');

     export default {
          props: {},
          data: () => ({
               clicked: false,

               datef: 'lastmonth',
               date_range: [],
               showsdate: false,

               apiUserList: [],
               selectuser: null,

               invoiceList: [],

               selectStatus: ['paid'],
               invoiceStatus: [{value:'paid', text:'paid'},
                                   {value:'unpaid', text:'unpaid'},
                                   {value:'deleted', text:'deleted'}],

               selectCommissionBy: 'order',
               //commissionStatus: ['單據', '服務及商品'],
               commissionStatus: [{value:'order', text:'Invoice'},
                                   {value:'orderitems', text:'Invoice items'}],
               //commissionStatusName: ['Order', 'Item'],
               selectedtype: 0,

               selectShowDetail: false,

               invoiceTotal: 0.0,
               costTotal: 0.0,
               commissionTotal: 0.0,
               itemTotal: 0,

               isLoading: false,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',
          }),
          methods: {
               apiGetUserList() {
                    BrowserSession.getSession('user', (data) => {
                         this.apiUserList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               getRecords() {
                    if (!this.selectuser) {
                         this.error_message = 'Please select user';
                         this.is_fail = true;
                         return;
                    }

                    if (isEmpty(this.date_range) || this.date_range.length != 2) {
                         this.error_message = 'Please select date range';
                         this.is_fail = true;
                         return;
                    }

                    this.isLoading = true;
                    this.clicked = true;

                    if (this.date_range[0] > this.date_range[1]) {
                         //exchange the date
                         let date_less = this.date_range[0];
                         this.date_range[0] = this.date_range[1];
                         this.date_range[1] = date_less;
                    }

                    let obj = {
                         daterange: this.date_range,
                         userid: this.selectuser._id,
                         status: this.selectStatus,
                    };

                    //select all invoice by this user
                    if (this.selectCommissionBy == 'order') {
                         InvoiceService.getSalesSummaryReport(obj)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        //console.log(response);
                                        this.isLoading = false;

                                        this.invoiceTotal = 0;
                                        this.commissionTotal = 0;
                                        this.costTotal = 0;

                                        let inv = response.data.data;

                                        inv.map((invoice) => {
                                             invoice.totalcost = 0.0;
                                             invoice.totalcommission = 0.0;

                                             invoice.product.map((item) => {
                                                  invoice.totalcost +=
                                                       item.cost * item.qty;
                                                  invoice.totalcommission +=
                                                       item.commission * item.qty;
                                             });

                                             this.invoiceTotal += invoice.amount;
                                             this.commissionTotal +=
                                                  invoice.totalcommission;
                                             this.costTotal += invoice.totalcost;
                                        });
                                        this.invoiceList = inv;
                                   }
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                                   console.log(e);
                              });

                         this.selectedtype = 0;
                    }

                    if (this.selectCommissionBy == 'orderitems') {
                         InvoiceService.getCommissionSummaryReport(obj)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.isLoading = false;

                                        this.invoiceTotal = 0;
                                        this.commissionTotal = 0;
                                        this.costTotal = 0;
                                        this.itemTotal = 0;

                                        let inv = response.data.data;

                                        inv.map((invoice) => {
                                             invoice.totalcost = 0.0;
                                             invoice.totalcommission = 0.0;

                                             invoice.product.map((item) => {
                                                  invoice.totalcost +=
                                                       item.cost * item.qty;
                                                  invoice.totalcommission +=
                                                       item.commission * item.qty;
                                                  this.itemTotal += item.qty;
                                             });

                                             this.invoiceTotal += invoice.amount;
                                             this.commissionTotal +=
                                                  invoice.totalcommission;
                                             this.costTotal += invoice.totalcost;
                                        });

                                        this.invoiceList = inv;
                                   }
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                                   console.log(e);
                              });
                         this.selectedtype = 1;
                    }
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
               setFilterDate(datef) {
                    this.datef = datef;

                    if (datef == 'today') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last7days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(6, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last30days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(29, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'thismonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().startOf('month').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'lastmonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment()
                                   .subtract(1, 'month')
                                   .startOf('month')
                                   .format('YYYY-MM-DD'),
                              moment()
                                   .subtract(1, 'month')
                                   .endOf('month')
                                   .format('YYYY-MM-DD'),
                         ];
                         return;
                    }
               },
          },
          computed: {
               dateRangeText() {
                    return this.date_range.join(' 至 ');
               },
          },
          created() {
               this.apiGetUserList();

               let x = new Date();
               x.setDate(0);
               this.date_range[1] = x.toISOString().slice(0, 10);
               x.setDate(1);
               this.date_range[0] = x.toISOString().slice(0, 10);

               this.datef = 'lastmonth';

               this.commissionStatus = [{value:'order', text:this.$t('components.invoice')},
                                   {value:'orderitems', text:this.$t('report.invoiceitems')}];

               this.invoiceStatus= [{value:'paid', text:this.$t('invoice.paid')},
                                   {value:'unpaid', text:this.$t('invoice.unpaid')},
                                   {value:'deleted', text:this.$t('invoice.void')}];
          }
     };
</script>
