<template>
     <div class="pa-5">
          <div class="pb-10 d-flex flex-row justify-space-between">
               <span class="my-auto">{{ $t('components.payment') }}</span>
               <v-btn
                    color="primary"
                    class="body-2 font-weight-bold"
                    @click="showPaymentDialog"
               >
                    <v-icon size="18">mdi-plus</v-icon>
                    <span class="white--text">{{ $t('global.add') }}</span>
               </v-btn>
          </div>

          <v-list>
               <v-list-item
                    v-for="(payment, index) in paymentList"
                    :key="index"
                    class="mb-3"
                    link
                    :to="'/invoicepdf?_id=' + payment.invoiceid"
                    target="_blank"
               >
                    <!--<v-list-item-avatar height="60" width="80">-->
                    <div class="pr-7">
                         <v-avatar
                              style="
                                   border: solid;
                                   border-width: 1px;
                                   border-radius: 50%;
                              "
                              :class="`payUser-${payment.status}`"
                              size="50"
                         >
                              <span
                                   class="body-2"
                                   :class="`payUser-${payment.status}`"
                                   >{{ payment.status }}</span
                              >
                         </v-avatar>
                    </div>
                    <!--</v-list-item-avatar>-->
                    <v-list-item-content>
                         <v-list-item-title class="body-2 font-weight-light"
                              >#{{
                                   payment.invoice.length == 1
                                        ? payment.invoice[0].invoiceno
                                        : 'Null'
                              }}</v-list-item-title
                         >
                         <v-list-item-title class="body-2 font-weight-light">
                              {{ payment.payment_type }} :
                              <strong
                                   ><span v-if="payment.payment_type == 'stock'"
                                        >Cr </span
                                   ><span v-else>$ </span
                                   >{{ payment.amount }}</strong
                              ></v-list-item-title
                         >
                         <v-list-item-subtitle
                              class="caption font-weight-light pt-2"
                              >@
                              {{
                                   formatDate(payment.pay_date)
                              }}</v-list-item-subtitle
                         >
                    </v-list-item-content>
                    <v-icon color="primary">mdi-eye-circle-outline</v-icon>
               </v-list-item>
          </v-list>

          <v-dialog v-model="paydialog" persistent max-width="750px">
               <v-card>
                    <PaymentDialog
                         v-if="paydialog"
                         :cid="customerobj._id"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                    />
               </v-card>
          </v-dialog>

          <div class="text-center body2 font-weight-light">
               <v-btn v-if="!noMoreRecord" icon @click="apiPullRecords">
                    <v-icon color="primary">mdi-chevron-down</v-icon>
               </v-btn>
               <span v-else>{{ $t('global.end') }}</span>
          </div>
     </div>
</template>

<script>
     /* eslint-disable */
     import PaymentService from '@/utils/api/PaymentService';
     import PaymentDialog from '@/views/invoice/payment/PaymentAddView';

     const moment = require('moment');
     export default {
          props: { customerobj: Object },
          data: () => ({
               paydialog: false,

               isLoading: false,
               noMoreRecord: false,

               page: 1,
               recordperpage: 5,
               paymentList: [],
          }),
          methods: {
               apiGetRecord() {
                    PaymentService.getPaymenyByCustomerid(
                         this.customerobj._id,
                         this.page,
                         this.recordperpage
                    )
                         .then((response) => {
                              if (response.data.status == 'success')
                                   //console.log(response.data);
                                   this.paymentList = response.data.data;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiPullRecords() {
                    if (this.noMoreRecord) {
                         return;
                    }
                    this.isLoading = true;
                    PaymentService.getPaymenyByCustomerid(
                         this.customerobj._id,
                         ++this.page,
                         this.recordperpage
                    )
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   if (data.length <= 0) {
                                        this.noMoreRecord = true;
                                   }
                                   this.paymentList = this.paymentList.concat(data);
                              }
                              this.isLoading = false;
                         })
                         .catch((e) => {
                              this.isLoading = false;
                              console.log(e);
                         });
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               showPaymentDialog() {
                    this.paydialog = true;
               },
               onClickCancel() {
                    this.paydialog = false;
               },
               onClickInput() {
                    this.paydialog = false;
               },
               onFail() {
                    this.paydialog = false;
               },
          },
          created() {
               if (this.customerobj != null) this.apiGetRecord();
               //console.log('payment tab created!');
          },
          components: {
               PaymentDialog,
          },
     };
</script>

<style>
     .payUser-init {
          border-color: orange !important;
          color: orange !important;
     }
     .payUser-done {
          border-color: #1976d2 !important;
          color: #1976d2 !important;
     }
     .payUser-void {
          border-color: #ff5252 !important;
          color: #ff5252 !important;
     }
</style>
