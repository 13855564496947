<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3">
               <v-container fluid>
                    <v-row class="pa-5 my-auto" align="center">
                         <span class="text1_color--text">
                              {{ $t('report.customerreport') }}
                         </span>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div
                                   v-html="$t('report.customerreporttips')"
                              ></div>
                         </v-tooltip>
                         <v-spacer></v-spacer>
                         <v-menu
                              v-model="showsdate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                        v-model="dateRangeText"
                                        :label="$t('report.daterange')"
                                        style="max-width: 220px"
                                        hide-details
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                   ></v-text-field>
                              </template>
                              <v-date-picker
                                   v-model="date_range"
                                   range
                              ></v-date-picker>
                         </v-menu>
                         <v-menu
                              bottom
                              origin="center center"
                              transition="scale-transition"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                             >mdi-arrow-down-drop-circle</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list>
                                   <v-list-item
                                        :input-value="datef == 'today'"
                                        @click="setFilterDate('today')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.today') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last7days'"
                                        @click="setFilterDate('last7days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past7day') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last30days'"
                                        @click="setFilterDate('last30days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past1month') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'thismonth'"
                                        @click="setFilterDate('thismonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.thismonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'lastmonth'"
                                        @click="setFilterDate('lastmonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.pastmonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold my-auto"
                              @click="getRecords"
                              :loading="isLoading"
                         >
                              <span class="white--text">
                                   {{ $t('report.executereport') }}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="mt-5 mx-auto pa-3" align="center">
                    <div class="text1_color--text">Sales Summary</div>
                    <div v-if="date_range">
                         {{ date_range[0] }} to {{ date_range[1] }}
                    </div>
               </div>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="3">
                                   <span class="mx-2"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{$t('customer.name')}}
                                   </span>
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.amount') }}
                                   </span>
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.paid') }}
                                   </span>
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text">
                                        {{ $t('report.balance') }}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(summary, index) in summaryList"
                         :key="summary._id"
                         class="px-2 fill-height"
                         align="center"
                    >
                         <v-col cols="3" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-2 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="text1_color--text text-body-2">
                                   {{ summary.customer[0].name }}
                              </div>
                         </v-col>

                         <v-col cols="3">
                              <span class="text1_color--text text-body-2">
                                   $
                                   {{
                                        formatCurrency(summary.totalSaleAmount)
                                   }}</span
                              >
                         </v-col>

                         <v-col cols="3" class="d-flex flex-column">
                              <span
                                   :class="
                                        summary.paid > 0
                                             ? 'primary--text'
                                             : 'text1_color--text'
                                   "
                                   class="text-body-2"
                              >
                                   $ {{ formatCurrency(summary.paid) }}</span
                              >
                         </v-col>

                         <v-col cols="3" class="d-flex flex-column">
                              <span
                                   :class="
                                        summary.balance > 0
                                             ? 'red--text'
                                             : 'text1_color--text'
                                   "
                                   class="text-body-2"
                              >
                                   $ {{ formatCurrency(summary.balance) }}</span
                              >
                         </v-col>
                    </v-row>
               </v-container>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="d-flex flex-column align-end pa-5">
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('invoice.amount') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(Total) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('invoice.paid') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold primary--text"
                         >
                              $ {{ formatCurrency(payTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="pl-2 text2_color--text text-body-2">
                              → {{$t('invoice.redeemamount')}} :
                         </span>
                         <span
                              class="text-decoration-underline text2_color--text text-body-2"
                         >
                              $ {{ formatCurrency(redeemTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{$t('invoice.unpaid')}} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold red--text"
                         >
                              $ {{ formatCurrency(nonPayTotal) }}</span
                         >
                    </div>
               </div>
          </div>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>
<script>
     import InvoiceService from '@/utils/api/InvoiceService';
     //import _ from 'lodash';
     var isEmpty = require('lodash/isEmpty');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     const moment = require('moment');

     export default {
          props: {},
          data: () => ({
               clicked: false,
               date_range: [],
               datef: 'lastmonth',
               showsdate: false,

               summaryList: [],

               Total: 0.0,
               payTotal: 0.0,
               nonPayTotal: 0.0,
               redeemTotal: 0.0,

               isLoading: false,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',
          }),
          methods: {
               getRecords() {
                    if (isEmpty(this.date_range) || this.date_range.length != 2) {
                         this.error_message = 'Please select date range';
                         this.is_fail = true;
                         return;
                    }

                    //clear up data
                    this.Total = 0;
                    this.payTotal = 0;
                    this.nonPayTotal = 0;

                    this.isLoading = true;
                    this.clicked = true;
                    //console.log(this.date_range);
                    if (this.date_range[0] > this.date_range[1]) {
                         //exchange the date
                         let date_less = this.date_range[0];
                         this.date_range[0] = this.date_range[1];
                         this.date_range[1] = date_less;
                         //console.log('exchanged!');
                         //console.log(this.date_range);
                    }
                    InvoiceService.getCustomerSalesReport(this.date_range)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.summaryList = response.data.data;

                                   this.summaryList.map((summary) => {
                                        summary.paid = 0.0;
                                        summary.payment.map((payment) => {
                                             payment.map((p) => {
                                                  if (p.status == 'done') {
                                                       if (
                                                            p.payment_type == 'stock'
                                                       ) {
                                                            summary.creditpaid +=
                                                                 p.amount;
                                                            return;
                                                       }
                                                       summary.paid += p.amount;
                                                       if (
                                                            p.payment_type ==
                                                            'reward point'
                                                       ) {
                                                            this.redeemTotal +=
                                                                 p.amount;
                                                       }
                                                  }
                                             });
                                        });
                                        summary.balance =
                                             summary.totalSaleAmount - summary.paid;
                                        if (summary.balance < 0) summary.balance = 0;

                                        this.Total += summary.totalSaleAmount;
                                        this.payTotal += summary.paid;
                                        this.nonPayTotal += summary.balance;
                                   });
                              }
                              this.isLoading = false;
                         })
                         .catch((e) => {
                              this.isLoading = false;
                              console.log(e);
                         });
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               setFilterDate(datef) {
                    this.datef = datef;

                    if (datef == 'today') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last7days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(6, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last30days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(29, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'thismonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().startOf('month').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'lastmonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment()
                                   .subtract(1, 'month')
                                   .startOf('month')
                                   .format('YYYY-MM-DD'),
                              moment()
                                   .subtract(1, 'month')
                                   .endOf('month')
                                   .format('YYYY-MM-DD'),
                         ];
                         return;
                    }
               },
          },
          computed: {
               dateRangeText() {
                    return this.date_range.join(' 至 ');
               },
          },
          created() {
               let x = new Date();
               x.setDate(0);
               this.date_range[1] = x.toISOString().slice(0, 10);
               x.setDate(1);
               this.date_range[0] = x.toISOString().slice(0, 10);

               this.datef = 'lastmonth';
          },
     };
</script>
