<template>
     <div class="pa-7">
          <div class="white rounded elevation-3">
               <v-container>
                    <v-row class="pa-5">
                         <span class="heading_color--text">{{
                              $t('components.segment')
                         }}</span>
                         <v-spacer></v-spacer>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addDialog = true"
                              :loading="isLoading"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">{{
                                   $t('global.add')
                              }}</span>
                         </v-btn>
                    </v-row>
               </v-container>

               <v-divider></v-divider>

               <div class="heading_bg px-3">
                    <v-container>
                         <v-row>
                              <v-col cols="4">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">{{
                                        $t('global.name')
                                   }}</span>
                              </v-col>
                              <v-col cols="4">
                                   <div class="caption heading_color--text">
                                        {{ $t('customer.detail') }}
                                   </div>
                              </v-col>
                              <v-col cols="2">
                                   <div class="caption heading_color--text">
                                        {{ $t('global.createdat') }}
                                   </div>
                              </v-col>
                              <v-col cols="2">
                                   <div class="caption heading_color--text">
                                        {{ $t('customer.status') }}
                                   </div>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container class="amList">
                    <v-row
                         v-for="(customergroup, index) in customerGroupList"
                         :key="index"
                         :class="`tc${index % 2}`"
                         class="pa-3"
                         align="center"
                    >
                         <v-col cols="4">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        index + 1
                                   }}</span>
                              </v-avatar>
                              <!--<a :href="`/customercategorydetail/${customercategory._id}`" 
              class="text-decoration-none text-subtitle-2">
            {{ customercategory.customer_category_name }}</a>-->
                              <span
                                   class="text-decoration-none text-subtitle-2"
                                   >{{ customergroup.name }}</span
                              >
                         </v-col>
                         <v-col cols="4">
                              <div class="text1_color--text text-body-2">
                                   {{ customergroup.description }}
                              </div>
                         </v-col>

                         <v-col
                              cols="2"
                              class="d-flex flex-row justify-space-between"
                         >
                              <div
                                   class="text1_color--text text-body-2 my-auto"
                              >
                                   {{ formatDate(customergroup.created_date) }}
                              </div>
                         </v-col>
                         <v-col
                              cols="2"
                              class="d-flex flex-row justify-space-between"
                         >
                              <div>
                                   <v-icon
                                        small
                                        v-if="customergroup.status == 'error'"
                                        color="#ff5252"
                                   >
                                        mdi-cancel
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="customergroup.status == 'done'"
                                        color="#1976d2"
                                   >
                                        mdi-checkbox-marked-circle-outline
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="customergroup.status == 'init'"
                                        color="orange"
                                   >
                                        mdi-progress-clock
                                   </v-icon>
                              </div>
                              <div>
                                   <v-menu offset-y>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       exeRecord(
                                                            customergroup._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-play-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t(
                                                                 'components.execute'
                                                            )
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       deleteRecord(
                                                            customergroup._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-delete-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t('global.delete')
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                         <v-col cols="1"> </v-col>
                         <v-col
                              v-if="customergroup.segment.length > 0"
                              cols="7"
                              style="
                                   border: solid;
                                   border-radius: 5px;
                                   border-width: 1px;
                                   border-color: var(--v-primary-lighten2);
                              "
                         >
                              <div
                                   v-for="(seg, i) in customergroup.segment"
                                   :key="i"
                              >
                                   <span class="caption"
                                        >{{ nameMapping(seg.field_name) }}
                                        {{ nameMapping(seg.condition_field) }}
                                        {{
                                             seg.value_type == 'obj'
                                                  ? nameMapping(seg.value.name)
                                                  : seg.value
                                        }}</span
                                   >
                              </div>
                         </v-col>
                         <v-col cols="1"> </v-col>
                    </v-row>
               </v-container>
          </div>

          <v-dialog v-model="addDialog" persistent max-width="690px">
               <v-card class="pa-0">
                    <CustomerGroupAddComponent
                         v-if="addDialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFail="onFail"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar v-model="is_success" color="primary" elevation="24">
               {{ $t('global.successPrefix') }}:
               <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               {{ $t('global.failPrefix') }}:
               <strong>{{ this.error_message }}</strong>
          </v-snackbar>
          <ConfirmDialog ref="confirm" />
     </div>
</template>

<script>
     /* eslint-disable */
     import BrowserSession from '@/utils/BrowserSession';
     import CustomerGroupService from '@/utils/api/CustomerGroupService';
     import CustomerGroupAddComponent from '@/views/customer/CustomerGroupAddView';

     import Permission from '@/utils/Permission';
     import ConfirmDialog from '@/views/components/ConfirmDialog';

     const moment = require('moment');
     export default {
          data() {
               return {
                    pagekey: ['LLVWE'],
                    managekey: ['Mpxzf'],

                    addDialog: false,
                    customerGroupList: [],

                    noOfPage: 0,
                    page: 1,
                    recordperpage: 5,

                    selectedid: null,
                    editDialog: false,

                    isLoading: false,

                    is_success: false,
                    is_fail: false,
                    success_message: '',
                    error_message: '',
               };
          },
          methods: {
               apiGetCustomerGroupList() {
                    CustomerGroupService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.customerGroupList = response.data.data;
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               createGroup() {
                    this.addDialog = true;
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },

               modifyRecord(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }

                    this.selectedid = _id;
                    this.editDialog = true;
               },
               onClickCancel() {
                    this.addDialog = false;
               },
               onClickInput(msg) {
                    this.success_message = msg;
                    this.is_success = true;

                    this.apiGetCustomerGroupList();
                    this.addDialog = false;
               },
               onFail(msg) {
                    this.error_message = msg;
                    this.is_fail = true;
                    this.addDialog = false;
               },

               nameMapping(name) {
                    let x = {
                         gender: this.$t('customer.gender'),
                         re_point: this.$t('customer.reward'),
                         lv_point: this.$t('customer.membershippt'),
                         member_lv: this.$t('customer.membershiplv'),
                         status: this.$t('customer.status'),
                         eq: this.$t('global.eq'),
                         gt: this.$t('global.gt'),
                         lt: this.$t('global.lt'),
                         M: this.$t('global.male'),
                         F: this.$t('global.female'),
                         active: this.$t('global.active'),
                         inactive: this.$t('global.inactive'),
                         product: this.$t('components.product'),
                         service: this.$t('components.service'),
                         package: this.$t('components.package'),
                         invoice_date: this.$t('invoice.invoicedate'),
                         amount: this.$t('invoice.invoiceamount'),
                         paid: this.$t('invoice.paid'),
                         unpaid: this.$t('invoice.unpaid'),
                         void: this.$t('invoice.void'),
                    };

                    return x[name] || name;
               },

               async deleteRecord(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }

                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('components.confirmdeletemsg')
                         )
                    ) {
                         CustomerGroupService.deleteCustomerGroup(_id)
                              .then(() => {
                                   this.success_message = this.$t('components.successdeletedmsg');
                                   this.is_success = true;
                                   this.apiGetCustomerGroupList();
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                              });
                    }
               },

               async exeRecord(_id) {
                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('components.executesegmentmsg')
                         )
                    ) {
                         this.isLoading = true;
                         try {
                              //going to execute
                              await CustomerGroupService.segmentation(_id);

                              await CustomerGroupService.updateCustomerGroup({
                                   _id: _id,
                                   status: 'done',
                              });

                              this.is_success = true;
                              this.success_message = this.$t( 'components.exesegmentsuccessmsg');
                              this.apiGetCustomerGroupList();
                         } catch (e) {
                              this.error_message = e.message;
                              this.is_fail = true;
                         } finally {
                              this.isLoading = false;
                         }
                    }
               },
          },
          created() {
               this.apiGetCustomerGroupList();
          },
          components: {
               ConfirmDialog,
               CustomerGroupAddComponent,
          },
     };
</script>

<style></style>
