import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueCookies from 'vue-cookies';

Vue.use(Vuetify);
Vue.use(VueCookies);

export default new Vuetify({
     theme: {
          options: {
               customProperties: true,
          },
          themes: {
               light: {
                    background: '#edf2f9',
                    link_color: '#2A2A2A',
                    text1_color: '#373737',
                    text2_color: '#929292',
                    price_color: '#f5803e',
                    heading_color: '#344050',
                    heading_bg: '#edf2f9',
                    post_bg: '#f9fafd',
                    badge_primary_bg: '#d5e5fa',
                    badge_primary_text: '#1c4f93',
                    badge_secondary_bg: '#e3e6ea',
                    badge_secondary_text: '#7d899b',
               },
          },
     },
});
