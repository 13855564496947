import http from '../HttpConn';
var isObject = require('lodash/isObject');
class PushMessageService {
     getAll(page = 1, limit = 9999) {
          return http.get('/pm', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page = 1, limit = 9999) {
          return http.get('/pm/allActive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/pm/count');
     }
     getPushMessage(id) {
          return http.get('/pm/' + id);
     }
     /* eslint-disable */
     updatePushMessage(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/pm/update/' + ccobj._id, ccobj);
     }
     addPushMessage(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/pm/add', ccobj);
     }
     deletePushMessage(id) {
          return http.delete('/pm/delete/' + id);
     }
     /* eslint-enable */
}
export default new PushMessageService();
