import http from '../HttpConn';
var isObject = require('lodash/isObject');
class InvoiceService {
     getAll(page, limit) {
          return http.get('/invoice', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/invoice/count');
     }
     getLastInvoiceId() {
          return http.get('/invoice/lastid');
     }
     getInvoice(_id) {
          return http.get('/invoice/' + _id);
     }
     getInvoiceFull(_id) {
          return http.get('/invoice/getFull/' + _id);
     }
     getInvoiceByid(iid) {
          return http.get('/invoice/byinvoiceid/' + iid);
     }
     getInvoicesByStatus(ccarray) {
          return http.post('/invoice/bystatus/', ccarray);
     }
     getInvoiceByUserid(uid, page, limit, status = null) {
          return http.get('/invoice/byuserid/' + uid, {
               params: { page: page, limit: limit, status: status },
          });
     }
     getInvoiceByUseridCount(uid) {
          return http.get('/invoice/byuseridcount/' + uid);
     }
     /* eslint-disable */
     updateInvoice(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/invoice/update/' + ccobj._id, ccobj);
     }
     updateInvoiceStatus(ccobj) {
          //{newstatus: 'status'}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/invoice/updatestatus/' + ccobj._id, ccobj);
     }
     CanInvoiceComplete(_id) {
          return http.get('/invoice/cancomplete/' + _id);
     }
     completeInvoice(_id) {
          return http.get('/invoice/completeinvoice/' + _id);
     }
     addInvoiceFull(ccobj) {
          //format: {invoice:{invoice object}, staffname: 'string' }
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/invoice/addFull', ccobj);
     }
     deleteInvoice(id) {
          return http.delete('/invoice/delete/' + id);
     }
     getInvoiceStatusStatistic() {
          return http.get('invoice/invoicestatus');
     }
     getInvoiceByDateRange(dateArray) {
          return http.get(
               '/invoice/byinvoicedaterange/' +
                    dateArray[0] +
                    '_' +
                    dateArray[1]
          );
     }
     getInvoiceByCustomerDateRange(dateArray, customer = null) {
          return http.get(
               '/invoice/bycustomerdaterange/' +
                    customer +
                    '/' +
                    dateArray[0] +
                    '_' +
                    dateArray[1]
          );
     }
     getInvoiceWithPayment(id) {
          return http.get('/invoice/withpayment/' + id);
     }

     /* reporting */
     getCustomerSalesReport(dateArray, customer = 'all') {
          return http.get(
               '/invoice/customersales/' +
                    customer +
                    '/' +
                    dateArray[0] +
                    '_' +
                    dateArray[1]
          );
     }

     getSalesSummaryReport(ccobj) {
          return http.post('/invoice/bysalesdaterange', ccobj);
     }

     getCommissionSummaryReport(ccobj) {
          return http.post('/invoice/bycommissiondaterange', ccobj);
     }

     getProductRank(dateArray) {
          return http.get(
               '/invoice/productRank/' + dateArray[0] + '_' + dateArray[1]
          );
     }

     getSalesProductRank(_id, dateArray) {
          return http.get(
               '/invoice/productSalesRank/' +
                    _id +
                    '/' +
                    dateArray[0] +
                    '_' +
                    dateArray[1]
          );
     }

     getExpiryAmount() {
          return http.get('/invoice/expiryAmount');
     }

     getCurrentAmount() {
          return http.get('/invoice/currentAmount');
     }
     searchInvoice(valueobj) {
          if (!valueobj || valueobj.value == null)
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          return http.post('/invoice/search/', valueobj);
     }
     searchInvoiceByCustomerid(id, page, limit) {
          return http.get('/invoice/searchbycid/' + id, {
               params: { page: page, limit: limit },
          });
     }
     advsearchInvoice(page, limit, ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/invoice/asearch/', {
               ...ccobj,
               ...{ params: { page: page, limit: limit } },
          });
     }
}
export default new InvoiceService();
