<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5" align="center">
                    <span v-if="_id" class="text1_color--text">{{
                         $t('global.modify')
                    }}</span>
                    <span v-else class="text1_color--text">{{
                         $t('global.newrecord')
                    }}</span>
                    <v-spacer></v-spacer>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
               </v-row>
          </v-container>

          <v-divider></v-divider>

          <v-container fluid>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="post_bg"
               >
                    <v-row class="px-5">
                         <v-col cols="6">
                              <v-text-field
                                   v-model="title"
                                   :counter="50"
                                   :rules="nameRules"
                                   :label="$t('mobile.title')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                              <v-menu
                                   v-model="showdate"
                                   :close-on-content-click="false"
                                   :nudge-right="40"
                                   transition="scale-transition"
                                   offset-y
                                   min-width="auto"
                              >
                                   <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                             v-model="publish_date"
                                             :label="$t('mobile.publishdate')"
                                             hint="YYYY-MM-DD"
                                             persistent-hint
                                             readonly
                                             outlined
                                             dense
                                             v-bind="attrs"
                                             v-on="on"
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </template>
                                   <v-date-picker
                                        v-model="publish_date"
                                        @input="showdate = false"
                                   ></v-date-picker>
                              </v-menu>
                         </v-col>
                         <v-col cols="6" class="d-inline-flex flex-row">
                              <v-img
                                   :src="url + '/' + previewImage"
                                   max-height="100px"
                                   max-width="100px"
                                   class="mx-2"
                              ></v-img>
                              <v-btn
                                   color="primary"
                                   @click="imageDialog = true"
                                   outlined
                                   height="100px"
                                   width="100px"
                                   >{{$t('mobile.setimage')}}</v-btn
                              >
                         </v-col>
                    </v-row>
                    <v-row class="px-5">
                         <v-col cols="12" class="pt-0">
                              <v-textarea
                                   v-model="sdescription"
                                   :label="$t('mobile.shortdesc')"
                                   rows="4"
                                   outlined
                                   dense
                                   hide-details
                                   background-color="#ffffff"
                              >
                              </v-textarea>
                         </v-col>
                    </v-row>
               </v-form>
          </v-container>
          <v-container>
               <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
               ></ckeditor>
          </v-container>

          <v-container class="pb-5">
               <v-switch
                    color="primary"
                    v-model="isActive"
                    :label="$t('mobile.status')"
                    inset
                    dense
                    hide-details
               >
               </v-switch>
          </v-container>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="_id == null"
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="updateRecord"
                    >
                         {{ $t('global.modify') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>

          <v-dialog v-model="imageDialog" persistent max-width="790px">
               <ImageDialog
                    v-if="imageDialog"
                    @onClickCancel="cancelInput"
                    @onClickInput="successInput"
                    @onFailAction="failInput"
               />
          </v-dialog>
     </div>
</template>

<script>
     /* eslint-disable */
     import CKEditor from '@ckeditor/ckeditor5-vue2';
     import ClassicEditor from '@/assets/ckeditor';
     import BlogService from '@/utils/api/BlogService';
     import ImageDialog from '@/views/components/ImageManagerDialog';

     const moment = require('moment');

     export default {
          props: {
               _id: String,
          },
          data: () => ({
               valid: true,
               editor: ClassicEditor,
               editorData: '<h1>Title: Hello World</h1><p>Post content here...</p>',
               editorConfig: {
                    //plugins: [SimpleUploadAdapter],
                    // The configuration of the editor.
                    simpleUpload: {
                         // The URL the images are uploaded to.
                         url: process.env.VUE_APP_APISERVER?(process.env.VUE_APP_APISERVER/+'crmuploadImage'):(`${location.origin}/api/crmuploadImage`),
                         withCredentials: true,
                         // Headers sent along with the XMLHttpRequest to the upload server.
                         headers: {},
                    },
                    toolbar: {
                         shouldNotGroupWhenFull: true,
                    },
                    mediaEmbed: {previewsInData: true},
               },
               title: '',
               sdescription: '',
               previewImage: 'noimage.jpeg',
               publish_date: '',
               showdate: false,
               isActive: true,
               nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) =>
                         (v && v.length <= 50) ||
                         'Name must be less than 50 characters',
               ],

               url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,

               imageDialog: false,
          }),
          methods: {
               addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    BlogService.addBlog({
                         title: this.title,
                         short_description: this.sdescription,
                         content: this.editorData,
                         publish_date: this.publish_date,
                         image: this.previewImage,
                         status: this.isActive ? 'active' : 'inactive',
                    })
                         .then((response) => {
                              //console.log(response);
                              if (response.data.status == 'success') {
                                   this.$emit('onClickInput', this.$t('global.successPrefix'));
                              } else
                                   this.$emit('onFailAction', response.data.message);
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },
               updateRecord() {
                    BlogService.updateBlog({
                         _id: this._id,
                         title: this.title,
                         short_description: this.sdescription,
                         content: this.editorData,
                         publish_date: this.publish_date,
                         image: this.previewImage,
                         status: this.isActive ? 'active' : 'inactive',
                    })
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') + this.title
                                   );
                              else this.$emit('onFailAction', response.data.message);
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },
               cancel() {
                    this.$emit('onClickCancel', null);
               },
               apiGetBlog(id) {
                    BlogService.getBlog(id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   this.title = data.title;
                                   this.sdescription = data.short_description;
                                   this.editorData = data.content;
                                   this.previewImage = data.image || 'noimage.jpeg';
                                   this.publish_date = this.formatDate(
                                        data.publish_date
                                   );
                                   this.isActive = data.status == 'active';
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               cancelInput() {
                    this.imageDialog = false;
               },
               successInput(file) {
                    this.imageDialog = false;
                    this.previewImage = file;
               },
               failInput() {
                    this.imageDialog = false;
                    this.error_message = 'Cannot select this file';
                    this.is_fail = true;
               },
          },
          components: {
               ckeditor: CKEditor.component,
               ImageDialog,
          },
          created() {
               if (this._id) this.apiGetBlog(this._id);
               else {
                    this.publish_date = new Date().toISOString().substr(0, 10);
               }
          },
     };
</script>
