<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span v-if="_id" class="heading_color--text">
                         {{ $t('global.modify') }}
                    </span>
                    <span v-else class="heading_color--text">
                         {{ $t('global.add') }}
                    </span>
               </v-row>
          </v-container>
          <v-divider></v-divider>

          <v-container fluid>
               <v-text-field
                    class="pa-5"
                    v-model="permissionobj.role"
                    :label="$t('user.groupname')"
                    :counter="50"
                    :rules="nameRules"
                    outlined
                    dense
                    persistent-hint
                    :hint="$t('global.required')"
               ></v-text-field>
               <div
                    style="font-size:20px; margin-bottom:15px; text-align: center;"
               >
                    {{$t('user.selectpermission')}}
               </div>
               <v-row
                    :class="`tc${index % 2}`"
                    v-for="(permission, index) in permissionList"
                    :key="index"
                    class="pa-3"
                    align="center"
               >
                    <v-checkbox
                         v-model="permissionobj.permission"
                         :label="permission.displayname"
                         :value="permission.key"
                         persistent-hint
                         :hint="permission.displaydesc"
                         :disabled="checkIsDisabled(permission.key)"
                         dense
                    ></v-checkbox>
               </v-row>
          </v-container>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="!this._id"
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{$t('global.add')}}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="modifyRecord"
                    >
                         {{$t('global.modify')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>

<script>
     import RoleService from '@/utils/api/RoleService';
     //import _ from 'lodash';
     var includes = require('lodash/includes');

     const config = require('@/config/config.js');

     export default {
          props: {
               _id: String,
          },
          data: () => ({
               orig_permissionobj: {},
               permissionobj: {
                    role: '',
                    permission: [],
               },

               nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) =>
                         (v && v.length <= 50) ||
                         'Name must be less than 50 characters',
               ],

               permissionList: [],
          }),
          methods: {
               apiGetPermissionList() {
                    RoleService.getAllPermissions()
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.permissionList = response.data.data;

                              console.log(this.permissionList);
                              this.permissionList.map(pe=>{
                                   pe['displayname'] = this.$t('user.'+pe.key);
                                   pe['displaydesc'] = this.$t('user.'+pe.key+'desc');
                              })
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetPermission(id) {
                    //eslint-disable-next-line
                    RoleService.getRole(id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.orig_permissionobj = {
                                        ...this.permissionobj,
                                        ...response.data.data,
                                   };
                                   this.permissionobj = {
                                        ...this.permissionobj,
                                        ...response.data.data,
                                   };
                                   console.log(this.permissionobj);
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               addNewRecord() {
                    //let new_role = {
                    //  role: this.name,
                    //  permission: this.selectedPermission
                    //};
                    //eslint-disable-next-line
                    RoleService.addRole(this.permissionobj)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') + this.permissionobj.role
                                   );
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.$emit('onFailAction', e.message);
                         });
               },
               modifyRecord() {
                    //eslint-disable-next-line
                    RoleService.updateRole(this.permissionobj)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') + this.permissionobj.role
                                   );
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.$emit('onFailAction', e.message);
                         });
               },
               checkIsDisabled(key) {
                    if (includes(config.disablepage, key)) return true;

                    return false;
               },
               cancel() {
                    this.$emit('onClickCancel', null);
               },
          },
          created() {
               this.apiGetPermissionList();

               if (this._id) {
                    this.apiGetPermission(this._id);
               }
          },
     };
</script>
