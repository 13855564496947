<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span class="my-auto">{{ $t('components.gallery') }}</span>
                    <v-spacer></v-spacer>
                    <span class="text-caption error--text my-auto px-2">{{
                         err_message
                    }}</span>
                    <v-btn
                         color="primary"
                         @click="$refs.inputUpload.click()"
                         outlined
                         >{{ $t('components.uploadimage') }}
                    </v-btn>
                    <input
                         v-show="false"
                         ref="inputUpload"
                         type="file"
                         @change="uploadImage"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
               <v-progress-linear
                    class="px-3"
                    :value="progress"
                    v-if="uploading"
               ></v-progress-linear>
          </v-container>
          <v-divider></v-divider>
          <v-container fluid>
               <v-virtual-scroll
                    bench="1"
                    height="600"
                    item-height="600"
                    :items="scrollImageList"
               >
                    <template v-slot:default="{ item }">
                         <v-row class="pa-0">
                              <v-col
                                   v-for="(image, index) in item"
                                   :key="image + index"
                                   class="d-flex child-flex"
                                   cols="3"
                              >
                                   <div @click="selectImage(image)">
                                        <v-img
                                             :src="url + '/' + image"
                                             aspect-ratio="1"
                                             class="grey lighten-2"
                                        >
                                             <template v-slot:placeholder>
                                                  <v-row
                                                       class="fill-height ma-0"
                                                       align="center"
                                                       justify="center"
                                                  >
                                                       <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                       ></v-progress-circular>
                                                  </v-row>
                                             </template>
                                             <!--<v-btn
                                                  icon
                                                  @click="removeImage(image)"
                                             >
                                                  <v-icon color="red"
                                                       >mdi-close-circle</v-icon
                                                  >
                                             </v-btn>-->
                                        </v-img>
                                   </div>
                              </v-col>
                         </v-row>
                    </template>
               </v-virtual-scroll>
          </v-container>
     </div>
</template>

<script>
     //import BlogService from '@/utils/api/BlogService';
     import UploadService from '@/utils/uploadFileService';

     //const config = require('@/config/config.js');
     export default {
          /*eslint-disable*/
          data: function () {
               return {
                    imageList: [],
                    scrollImageList: [[]],
                    noOfImages: 0,
                    url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,
                    uploading: false,
                    progress: 0,

                    err_message: '',
               };
          },
          methods: {
               apiGetRecordsCountAndList() {
                    UploadService.getImageList().then((response) => {
                         //console.log(response);
                         if (response.data.status == 'success') {
                              let data = response.data.data;
                              this.imageList = data.files;
                              this.noOfImages = data.count;

                              let g = 12;
                              let counter = 0;
                              let index = 0;
                              this.scrollImageList = [[]];

                              data.files.map((image) => {
                                   if (index >= g) {
                                        counter++;
                                        index = 0;
                                        this.scrollImageList[counter] = [];
                                   }
                                   //console.log(this.scrollImageList[counter]);
                                   this.scrollImageList[counter].push(image);
                                   index++;
                              });
                         }

                         //console.log(this.scrollImageList);
                    });
               },
               removeImage(file) {
                    UploadService.removeImage(file).then((response) => {
                         //console.log(response);
                         if (response.data.status == 'success')
                              this.apiGetRecordsCountAndList();
                    });
               },
               selectImage(file) {
                    //console.log(file);
                    this.$emit('onClickInput', file);
               },
               cancel() {
                    this.$emit('onClickCancel', null);
               },
               uploadImage(event) {
                    //console.log(event.target.files[0].name);
                    //this.currentImage = event.target.files[0].name;
                    this.progress = 0;
                    this.uploading = true;

                    UploadService.upload(event.target.files[0], (event) => {
                         this.progress = Math.round(
                              (100 * event.loaded) / event.total
                         );
                    })
                         .then((response) => {
                              //this.message = response.data.message;
                              //this.previewImage = './'+response.data.file.path;
                              //this.previewImage = '/' + response.data.file.filename;
                              //console.log(response);
                              if (response.data.status == 'success') {
                                   this.apiGetRecordsCountAndList();
                                   this.uploading = false;
                              }
                         })
                         .catch((err) => {
                              this.progress = 0;
                              this.err_message = 'Fail: ' + err.response.data.error;
                              //console.log(err.response.data.error);
                              //console.log(this.message);
                              this.uploading = false;
                         });
               },
          },
          created() {
               this.apiGetRecordsCountAndList();
          },
     };
</script>
