<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span class="heading_color--text">{{
                         $t('global.newrecord')
                    }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>

          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="pa-10 post_bg"
               >
                    <v-row>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="name"
                                   :counter="50"
                                   :rules="nameRules"
                                   :label="$t('customer.segmentname')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="py-0">
                              <v-textarea
                                   v-model="description"
                                   :label="$t('customer.detail')"
                                   outlined
                                   dense
                                   rows="3"
                                   background-color="#ffffff"
                              ></v-textarea>
                         </v-col>
                    </v-row>

                    <div class="mt-5">Segmentation</div>
                    <v-divider class="pb-5"></v-divider>

                    <div
                         class="d-flex flex-row"
                         v-for="(obj, index) in selectedObj"
                         :key="index"
                    >
                         <v-select
                              v-model="obj.type"
                              :items="ObjType"
                              :label="$t('global.type')"
                              outlined
                              dense
                              class="pr-1"
                              style="max-width: 150px"
                              @change="onChangeType(obj)"
                              background-color="#ffffff"
                         ></v-select>
                         <v-select
                              v-model="obj.field"
                              :items="obj.fieldList"
                              item-text="name"
                              :label="$t('customer.segmentcondition')"
                              outlined
                              dense
                              return-object
                              class="pr-1"
                              style="max-width: 200px"
                              @change="onChangeField(obj)"
                              background-color="#ffffff"
                         ></v-select>
                         <v-select
                              v-model="obj.conditionfield"
                              :items="obj.conditionfieldList"
                              :label="$t('customer.segmentcompare')"
                              outlined
                              dense
                              class="pr-1"
                              style="max-width: 80px"
                              background-color="#ffffff"
                         ></v-select>

                         <v-text-field
                              v-model="obj.value"
                              v-if="obj.valuetype == 'number'"
                              :label="$t('customer.value')"
                              outlined
                              dense
                              type="number"
                              hide-details
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-text-field
                              v-model="obj.value"
                              v-else-if="obj.valuetype == 'string'"
                              :label="$t('customer.value')"
                              outlined
                              dense
                              hide-details
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-select
                              v-model="obj.value"
                              v-else-if="obj.valuetype == 'obj'"
                              :items="obj.field.list"
                              :label="$t('customer.value')"
                              outlined
                              dense
                              return-object
                              background-color="#ffffff"
                         ></v-select>
                         <v-menu
                              v-model="showsdate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                              v-else-if="obj.valuetype == 'date'"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                        :id="obj.value"
                                        v-model="obj.value"
                                        :label="$t('global.date')"
                                        style="max-width: 220px"
                                        hide-details
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </template>
                              <v-date-picker
                                   v-model="obj.value"
                              ></v-date-picker>
                         </v-menu>
                         <v-btn
                              icon
                              color="primary"
                              @click="delCondition(index)"
                              ><v-icon>mdi-delete-circle</v-icon></v-btn
                         >
                    </div>
                    <div class="my-5">
                         <v-btn small color="primary" @click="addCondition"
                              ><v-icon>mdi-plus</v-icon
                              >{{ $t('customer.addnewcondition') }}</v-btn
                         >
                    </div>

                    <!--<div class="mt-5">Import</div>
                    <v-divider></v-divider>-->
               </v-form>
          </div>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         text
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>
<script>
     /* eslint-disable */
     import CustomerGroupService from '@/utils/api/CustomerGroupService';
     import BrowserSession from '@/utils/BrowserSession';

     export default {
          data() {
               return {
                    valid: true,
                    name: null,
                    description: null,

                    nameRules: [
                         (v) => !!v || 'This field is required',
                         (v) =>
                              (v && v.length <= 50) ||
                              'Field must be less than 50 characters',
                    ],

                    selectedObj: [],

                    ObjType: [
                         { text: this.$t('components.customer'), value: 'customer' },
                         { text: this.$t('components.invoice'), value: 'invoice' },
                    ],

                    fieldList1: [
                         /*{
                         name: '出生年份',
                         conditionfieldList: ['大於', '是', '小於'],
                         valuetype: 'date',
                    },*/
                         {
                              id: 0,
                              name: this.$t('customer.gender'),
                              conditionfieldList: ['='],
                              valuetype: 'obj',
                              list: [
                                   { _id: null, text: 'Male', value: 'M' },
                                   { _id: null, text: 'Female', value: 'F' },
                              ],
                              t: 'customer',
                         },
                         {
                              id: 1,
                              name: this.$t('customer.reward'),
                              conditionfieldList: ['>', '=', '<'],
                              valuetype: 'number',
                              t: 'customer',
                         },
                         {
                              id: 2,
                              name: this.$t('customer.membershippt'),
                              conditionfieldList: ['>', '=', '<'],
                              valuetype: 'number',
                              t: 'customer',
                         },
                         {
                              id: 3,
                              name: this.$t('customer.membershiplv'),
                              conditionfieldList: ['='],
                              valuetype: 'obj',
                              list: [],
                              t: 'customer',
                         },
                         {
                              id: 4,
                              name: this.$t('customer.status'),
                              conditionfieldList: ['='],
                              valuetype: 'obj',
                              list: [
                                   { _id: null, text: 'active', value: 'active' },
                                   {
                                        _id: null,
                                        text: 'inactive',
                                        value: 'inactive',
                                   },
                              ],
                              t: 'customer',
                         },
                    ],
                    fieldList2: [
                         {
                              id: 5,
                              name: this.$t('components.product'),
                              conditionfieldList: ['='],
                              valuetype: 'obj',
                              list: [],
                              t: 'invoice',
                         },
                         {
                              id: 6,
                              name: this.$t('components.service'),
                              conditionfieldList: ['='],
                              valuetype: 'obj',
                              list: [],
                              t: 'invoice',
                         },
                         {
                              id: 7,
                              name: this.$t('components.package'),
                              conditionfieldList: ['='],
                              valuetype: 'obj',
                              list: [],
                              t: 'invoice',
                         },
                         {
                              id: 8,
                              name: this.$t('invoice.invoicedate'),
                              conditionfieldList: ['>', '=', '<'],
                              valuetype: 'date',
                              t: 'invoice',
                         },
                         {
                              id: 9,
                              name: this.$t('invoice.invoiceamount'),
                              conditionfieldList: ['>', '=', '<'],
                              valuetype: 'number',
                              t: 'invoice',
                         },
                         {
                              id: 10,
                              name: this.$t('invoice.status'),
                              conditionfieldList: ['='],
                              valuetype: 'obj',
                              list: [
                                   { _id: null, text: 'paid', value: 'paid' },
                                   { _id: null, text: 'unpaid', value: 'unpaid' },
                                   { _id: null, text: 'void', value: 'void' },
                              ],
                              t: 'invoice',
                         },
                    ],

                    fieldMapping: [
                         //出生年份: 'dob',
                         'gender',
                         're_point',
                         'lv_point',
                         'member_lv',
                         'status',
                         'product',
                         'service',
                         'package',
                         'invoice_date',
                         'amount',
                         'status',
                    ],
                    conditionMapping: { '=': 'eq', '>': 'gt', '<': 'lt' },

                    apiItemList: [],
                    apiServiceList: [],
                    apiPackageList: [],
                    apiCustomerCategoryList: [],

                    showsdate: false,
               };
          },
          methods: {
               apiGetCustomerCategoryList() {
                    BrowserSession.getSession('customer_category', (dd) => {
                         this.apiCustomerCategoryList = dd;
                         this.fieldList1[3].list = this.apiCustomerCategoryList.map(
                              (d) => {
                                   return {
                                        ...d,
                                        ...{
                                             text: d.customer_category_name,
                                             value: d.customer_category_name,
                                        },
                                   };
                              }
                         );
                         //console.log(this.apiCustomerCategoryList);
                    });
               },
               apiGetProductList() {
                    //get from session, disable in-active product
                    BrowserSession.getSession('product', (data) => {
                         this.apiItemList = data.filter((d) => {
                              return d.status == 'active';
                         });

                         this.fieldList2[0].list = this.apiItemList.map((d) => {
                              return { ...d, ...{ text: d.name, value: d.name } };
                         });
                    });
               },
               apiGetServiceList() {
                    BrowserSession.getSession('service', (data) => {
                         this.apiServiceList = data.filter((d) => {
                              return d.status == 'active';
                         });
                         this.fieldList2[1].list = this.apiServiceList.map((d) => {
                              return { ...d, ...{ text: d.name } };
                         });
                    });
               },
               apiGetPackageList() {
                    BrowserSession.getSession('package', (data) => {
                         this.apiPackageList = data.filter((d) => {
                              return d.status == 'active';
                         });
                         this.fieldList2[2].list = this.apiPackageList.map((d) => {
                              return { ...d, ...{ text: d.name } };
                         });
                    });
               },
               onChangeType(obj) {
                    if (obj.type == 'customer') {
                         obj.fieldList = this.fieldList1;
                    } else if (obj.type == 'invoice') {
                         obj.fieldList = this.fieldList2;
                    }

                    obj.field = obj.fieldList[0];
                    //console.log(obj);
                    this.onChangeField(obj);
               },
               onChangeField(obj) {
                    obj.conditionfieldList = obj.field.conditionfieldList;
                    obj.conditionfield = obj.conditionfieldList[0];
                    obj.valuetype = obj.field.valuetype;
                    obj.value = null;

                    if (obj.valuetype == 'obj') {
                         obj.valueList = obj.field.list;
                         if (obj.valueList.length > 0) obj.value = obj.valueList[0];
                    }
               },
               addCondition() {
                    this.selectedObj.push({
                         type: null,
                         field: {},
                         fieldList: [],
                         conditionfield: {},
                         conditionfieldList: [],
                         value: null,
                         valuetype: 'number',
                         valueList: [],
                    });
               },
               delCondition(idx) {
                    //console.log(idx);
                    this.selectedObj.splice(idx, 1);
               },
               addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    let segment_items = this.selectedObj.map((s) => {
                         return {
                              type: s.type,
                              field_name: this.fieldMapping[s.field.id],
                              condition_field:
                                   this.conditionMapping[s.conditionfield],
                              value_type: s.valuetype,
                              value:
                                   s.valuetype == 'obj'
                                        ? { _id: s.value._id, name: s.value.value }
                                        : s.valuetype == 'number'
                                        ? parseFloat(s.value)
                                        : s.value,
                         };
                    });
                    //console.log(segment_items);

                    CustomerGroupService.addCustomerGroup({
                         name: this.name,
                         description: this.description,
                         segment: segment_items,
                         status: 'init',
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   //console.log(response.data);
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +
                                             ': ' +
                                             this.name
                                   );
                              } else {
                                   this.$emit(
                                        'onFail',
                                        this.$t('global.failPrefix') +
                                             ': ' +
                                             response.data.message
                                   );
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.$emit(
                                   'onFail',
                                   this.$t('global.failPrefix') + ': ' + e.message
                              );
                         });
               },
               cancel() {
                    this.$emit('onClickCancel', null);
               },
          },
          created() {
               this.apiGetCustomerCategoryList();
               this.apiGetProductList();
               this.apiGetServiceList();
               this.apiGetPackageList();
          },
     };
</script>
