import http from '../HttpConn';
var isObject = require('lodash/isObject');
class ExpenseService {
     getCount() {
          return http.get('/expense/count');
     }

     getAll(page, limit) {
          return http.get('/expense/', {
               params: { page: page, limit: limit },
          });
     }

     getExpense(id) {
          return http.get('/expense/' + id);
     }

     getExpenseByInvoice(id) {
          return http.get('/expense/group/' + id);
     }
     getExpenseSumByInvoice(id) {
          return http.get('/expense/group/sum/' + id);
     }
     /* eslint-disable */
     addExpense(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/expense/add', ccobj);
     }
     updateExpenseStatus(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/expense/updateStatus/' + ccobj._id, ccobj);
     }
     /* eslint-enable */

     /* reporting */
     getExpenseByDateRange(dateArray) {
          return http.get(
               '/expense/byexpensedaterange/' +
                    dateArray[0] +
                    '_' +
                    dateArray[1]
          );
     }
}
export default new ExpenseService();
