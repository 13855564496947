<template>
     <div class="pa-7">
          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-10 py-5 post_bg"
          >
               <div class="text-h6 mt-5">
                    {{ $t('setting.invoicesetting') }}
               </div>
               <v-divider class="mb-5"></v-divider>
               <div class="pa-5">
                    <v-row>
                         <v-col class="pa-1">
                              <v-text-field
                                   v-model="invobj.prefix"
                                   :rules="nameRules"
                                   :label="$t('setting.invoiceprefix')"
                                   counter="5"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col class="pa-1 d-flex flex-row">
                              <v-text-field
                                   v-model="invobj.tax"
                                   :rules="numberRules"
                                   :label="$t('invoice.tax')"
                                   :hint="$t('global.required')"
                                   type="number"
                                   suffix="%"
                                   persistent-hint
                                   outlined
                                   dense
                                   :disabled="!invobj.enable_tax"
                                   background-color="#ffffff"
                              >
                              </v-text-field>
                              <v-switch
                                   v-model="invobj.enable_tax"
                                   inset
                                   dense
                                   class="pl-5 my-auto"
                              ></v-switch>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="pa-1">
                              <v-select
                                   v-model="invobj.decimal_place"
                                   :items="decimalplaceList"
                                   :label="$t('setting.decimalplace')"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              >
                              </v-select>
                         </v-col>
                         <v-col class="pa-1">
                              <v-select
                                   v-model="invobj.rounding"
                                   :label="$t('setting.carrysetting')"
                                   :items="roundList"
                                   item-text="text"
                                   item-value="value"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              >
                              </v-select>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="pa-1">
                              <v-select
                                   v-model="invobj.discount_autoupdate"
                                   :items="discountupdateList"
                                   :label="$t('setting.autoupdatediscount')"
                                   item-text="text"
                                   item-value="value"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              >
                              </v-select>
                         </v-col>
                         <v-col class="pa-1">
                              <v-text-field
                                   v-model="invobj.duedays"
                                   :rules="numberRules"
                                   :label="$t('setting.paymentdue')"
                                   :hint="$t('global.required')"
                                   type="number"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              >
                              </v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-textarea
                              v-model="invobj.terms"
                              :label="$t('setting.term')"
                              outlined
                              dense
                              background-color="#ffffff"
                         >
                         </v-textarea>
                    </v-row>
               </div>

               <div class="text-h6 mt-5">{{$t('setting.paymenttype')}}</div>
               <v-divider class="mb-5"></v-divider>
               <div class="d-flex flex-wrap pb-10">
                    <div
                         v-for="(ptype, index) in invobj.payment_type"
                         :key="ptype + index"
                    >
                         <v-switch
                              color="primary"
                              v-model="ptype.enable"
                              :label="$t('invoice.'+ptype.name)"
                              hide-details
                              inset
                              class="my-3 mx-6"
                         ></v-switch>
                    </div>
               </div>

               <div class="text-h6 mt-5">
                    {{ $t('setting.membersystemsetting') }}
               </div>
               <v-divider class="mb-5"></v-divider>
               <div>
                    <div
                         :style="
                              ptobj.enable_pt_sys
                                   ? 'border-width: 1px; border-style: solid; border-radius: 5px; border-color:black'
                                   : 'border-width: 1px; border-style: solid; border-radius: 5px; border-color:grey'
                         "
                         class="pa-5 mb-3"
                    >
                         <v-checkbox
                              v-model="ptobj.enable_pt_sys"
                              :disabled="!config.enable_pt_sys"
                              class="my-auto"
                              :label="$t('setting.enablereward')"
                         ></v-checkbox>
                         <div
                              :class="
                                   ptobj.enable_pt_sys
                                        ? 'mb-1'
                                        : 'mb-1 grey--text'
                              "
                         >
                              {{$t('setting.earnreward')}}
                         </div>
                         <v-row class="my-auto mb-5">
                              <v-col class="pa-1">
                                   <v-text-field
                                        v-model.number="ptobj.register_pt"
                                        :disabled="!ptobj.enable_pt_sys"
                                        :label="$t('setting.register')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        type="number"
                                        style="max-width: 150px"
                                        hide-details
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="pa-1">
                                   <v-text-field
                                        v-model.number="ptobj.purchase_pt"
                                        :disabled="!ptobj.enable_pt_sys"
                                        :label="$t('setting.consumeonedollar')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        type="number"
                                        style="max-width: 150px"
                                        hide-details
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="pa-1">
                                   <v-text-field
                                        v-model.number="ptobj.birthday_pt"
                                        :disabled="!ptobj.enable_pt_sys"
                                        :label="$t('setting.dob')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        type="number"
                                        style="max-width: 150px"
                                        hide-details
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                         </v-row>

                         <div
                              :class="
                                   ptobj.enable_pt_sys
                                        ? 'mb-0'
                                        : 'mb-0 grey--text'
                              "
                         >
                              {{ $t('setting.pointexpirysetting') }}
                         </div>
                         <v-row class="my-auto">
                              <v-col class="pa-1">
                                   <v-radio-group
                                        v-model="ptobj.point_by"
                                        row
                                        dense
                                        hide-details
                                        style="margin-top: 0px"
                                        :disabled="!config.enable_pt_sys"
                                   >
                                        <div
                                             class="d-flex flex-row"
                                             style="
                                                  width: 100%;
                                                  margin-bottom: 10px;
                                             "
                                        >
                                             <v-radio
                                                  :disabled="!ptobj.enable_pt_sys"
                                                  :label="$t('setting.fixdateexpiry')"
                                                  value="fix"
                                                  class="mx-2"
                                             ></v-radio>
                                             <v-select
                                                  :disabled="
                                                       ptobj.point_by !=
                                                            'fix' ||
                                                       !ptobj.enable_pt_sys
                                                  "
                                                  v-model="ptobj.fix"
                                                  :label="$t('setting.expirydate')"
                                                  :items="dayList"
                                                  item-text="text"
                                                  item-value="value"
                                                  dense
                                                  hide-details
                                                  style="
                                                       max-width: 150px;
                                                       margin-left: 10px;
                                                  "
                                                  background-color="#ffffff"
                                             >
                                             </v-select>
                                        </div>
                                        <div
                                             class="d-flex flex-row"
                                             style="padding-top:4px;"
                                        >
                                             <v-radio
                                                  :disabled="!ptobj.enable_pt_sys"
                                                  :label="$t('setting.memberdateexpiry')"
                                                  value="auto"
                                                  class="mx-2"
                                             ></v-radio>
                                             <v-text-field
                                                  :disabled="
                                                       ptobj.point_by !=
                                                            'auto' ||
                                                       !ptobj.enable_pt_sys
                                                  "
                                                  v-model="ptobj.extend_days"
                                                  :label="$t('setting.extend')"
                                                  style="max-width:80px"
                                                  dense
                                                  hide-details
                                                  :suffix="$t('setting.day')"
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </div>
                                        <div style="width:100%" class="pt-4">
                                             <v-radio
                                                  :disabled="!ptobj.enable_pt_sys"
                                                  :label="$t('setting.noexpiry')"
                                                  value="never_expiry"
                                                  class="mx-2"
                                             ></v-radio>
                                        </div>
                                   </v-radio-group>
                              </v-col>
                         </v-row>

                         <div
                              :class="
                                   ptobj.enable_pt_sys
                                        ? 'mb-1 mt-5'
                                        : 'mb-1 mt-5 grey--text'
                              "
                         >
                              {{$t('setting.pointredeemsetting')}}
                         </div>
                         <v-row class="my-auto">
                              <v-col class="pa-1 d-flex flex-row align-center">
                                   <v-text-field
                                        v-model.number="
                                             ptobj.dollar_equal_point
                                        "
                                        :disabled="!ptobj.enable_pt_sys"
                                        :label="$t('setting.onepointredeemevery')"
                                        type="number"
                                        outlined
                                        dense
                                        class="mx-2"
                                        style="max-width: 250px"
                                        hide-details
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                                   <span> = HKD 1.00</span>
                              </v-col>
                         </v-row>
                    </div>
                    <div
                         :style="
                              ptobj.enable_lv_sys
                                   ? 'border-width: 1px; border-style: solid; border-radius: 5px; border-color:black'
                                   : 'border-width: 1px; border-style: solid; border-radius: 5px; border-color:grey'
                         "
                         class="pa-5 mb-3"
                    >
                         <v-checkbox
                              v-model="ptobj.enable_lv_sys"
                              :disabled="!config.enable_lv_sys"
                              class="my-auto"
                              :label="$t('setting.enablelevel')"
                              dense
                         ></v-checkbox>
                         <div
                              :class="
                                   ptobj.enable_lv_sys
                                        ? 'mb-2'
                                        : 'mb-2 grey--text'
                              "
                         >
                              {{ $t('setting.earnlevelpoint')}}
                         </div>
                         <v-row class="my-auto mb-5">
                              <v-col class="pa-0">
                                   <v-text-field
                                        v-model.number="ptobj.register_mark"
                                        :disabled="!ptobj.enable_lv_sys"
                                        :label="$t('setting.register')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        style="max-width: 150px"
                                        hide-details
                                        type="number"
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="pa-0">
                                   <v-text-field
                                        v-model.number="ptobj.purchase_mark"
                                        :disabled="!ptobj.enable_lv_sys"
                                        :label="$t('setting.consumeonedollar')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        style="max-width: 150px"
                                        hide-details
                                        type="number"
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="pa-0">
                                   <v-text-field
                                        v-model.number="ptobj.birthday_mark"
                                        :disabled="!ptobj.enable_lv_sys"
                                        :label="$t('setting.dob')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        style="max-width: 150px"
                                        hide-details
                                        type="number"
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="pa-0">
                                   <v-text-field
                                        v-model.number="ptobj.order_mark"
                                        :disabled="!ptobj.enable_lv_sys"
                                        :label="$t('setting.eachorder')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        style="max-width: 150px"
                                        hide-details
                                        type="number"
                                        :suffix="$t('setting.point')"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                         </v-row>
                         <div
                              :class="
                                   ptobj.enable_lv_sys
                                        ? 'mb-0'
                                        : 'mb-0 grey--text'
                              "
                         >
                              {{$t('setting.membershipexpirysetting')}}
                         </div>
                         <v-row class="my-auto">
                              <v-col class="pa-1">
                                   <v-radio-group
                                        v-model="ptobj.mark_by"
                                        row
                                        dense
                                        hide-details
                                        style="margin-top: 0px"
                                        :disabled="!ptobj.enable_lv_sys"
                                   >
                                        <div
                                             class="d-flex flex-row"
                                             style="
                                                  width: 100%;
                                                  margin-bottom: 15px;
                                             "
                                        >
                                             <v-radio
                                                  :label="$t('setting.fixdateexpiry')"
                                                  value="eday"
                                                  class="mx-2 mt-1"
                                             ></v-radio>
                                             <v-select
                                                  :disabled="
                                                       ptobj.mark_by !=
                                                            'eday' ||
                                                       !ptobj.enable_lv_sys
                                                  "
                                                  v-model="ptobj.eday"
                                                  :label="$t('setting.expirydate')"
                                                  :items="dayList"
                                                  item-text="text"
                                                  item-value="value"
                                                  dense
                                                  hide-details
                                                  style="
                                                       max-width: 150px;
                                                       margin-left: 10px;
                                                  "
                                                  background-color="#ffffff"
                                             >
                                             </v-select>
                                        </div>
                                        <div
                                             class="d-flex flex-row"
                                             style="padding-top:4px;"
                                        >
                                             <v-radio
                                                  :label="$t('setting.memberdateexpirynoextend')"
                                                  value="acc"
                                                  class="mx-2"
                                             ></v-radio>
                                        </div>
                                        <div style="width:100%" class="pt-4">
                                             <v-radio
                                                  :label="$t('setting.noexpiry')"
                                                  value="never_expiry"
                                                  class="mx-2"
                                             ></v-radio>
                                        </div>
                                   </v-radio-group>
                              </v-col>
                         </v-row>
                         <v-row class="pb-5 mt-1">
                              <v-col class="px-5">
                                   <v-select
                                        :disabled="
                                             ptobj.mark_by == 'never_expiry' ||
                                             !ptobj.enable_lv_sys
                                        "
                                        v-model="ptobj.expiry_action"
                                        :label="$t('setting.membershipcal')"
                                        :items="eActionList"
                                        item-text="text"
                                        item-value="value"
                                        dense
                                        hide-details
                                        outlined
                                        style="margin-left: 10px"
                                        background-color="#ffffff"
                                   ></v-select>
                              </v-col>
                         </v-row>

                         <div
                              :class="
                                   ptobj.enable_lv_sys
                                        ? 'mb-1 mt-5'
                                        : 'mb-1 mt-5 grey--text'
                              "
                         >
                              {{$t('setting.lvupsetting')}}
                         </div>
                         <v-row class="my-auto">
                              <v-col class="pa-1">
                                   <v-radio-group
                                        v-model="ptobj.lvup"
                                        row
                                        dense
                                        hide-details
                                        style="margin-top: 0px"
                                        :disabled="!ptobj.enable_lv_sys"
                                   >
                                        <v-radio
                                             :label="$t('setting.auto')"
                                             value="auto"
                                             class="mx-2"
                                        ></v-radio>

                                        <!--<v-radio
                                             label="提示訊息"
                                             value="pop"
                                             class="mx-2"
                                        ></v-radio>-->

                                        <v-radio
                                             :label="$t('setting.manual')"
                                             value="manual"
                                             class="mx-2"
                                        ></v-radio>
                                   </v-radio-group>
                              </v-col>
                         </v-row>
                    </div>
               </div>
          </v-form>

          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="updateRecord"
                    >
                         {{$t('global.save')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import SettingService from '@/utils/api/SettingService';
     import BrowserSession from '@/utils/BrowserSession';
     import config from '@/config/config';

     //import _ from 'lodash';

     export default {
          data: () => ({
               valid: true,

               config: config,

               decimalplaceList: [0, 1, 2, 3, 4, 5],
               roundList: [
                    { text: '四捨五入', value: 'roundoff' },
                    { text: '上捨', value: 'roundup' },
                    { text: '下捨', value: 'rounddown' },
               ], //roundoff, roundup, rounddown

               discountupdateList: [
                    { text: '使用', value: true },
                    { text: '停用', value: false },
               ],
               dayList: [
                    { text: '1月1日', value: 1 },
                    { text: '2月1日', value: 2 },
                    { text: '3月1日', value: 3 },
                    { text: '4月1日', value: 4 },
                    { text: '5月1日', value: 5 },
                    { text: '6月1日', value: 6 },
                    { text: '7月1日', value: 7 },
                    { text: '8月1日', value: 8 },
                    { text: '9月1日', value: 9 },
                    { text: '10月1日', value: 10 },
                    { text: '11月1日', value: 11 },
                    { text: '12月1日', value: 12 },
               ],
               eActionList: [
                    { text: '一律回到預設等級', value: 'defaultmembership' },
                    { text: '根據分數重新計算等級', value: 'calbypt' },
                    {
                         text: '根據分數重新計算(不升級)，不足降一級',
                         value: 'calbypt_stepdown',
                    },
                    {
                         text: '根據分數重新計算(不升級)，不足回到預設等級',
                         value: 'calbypt_default',
                    },
               ],

               orgi_invobj: {},
               invobj: {
                    prefix: '',
                    tax: 0,
                    enable_tax: true,
                    decimal_place: 2,
                    rounding: 'roundoff',
                    terms: '',
                    discount_autoupdate: true,
                    duedays: 0,
                    payment_type: [
                    { name: 'cash', enable: true },
                    { name: 'cheque', enable: true },
                    { name: 'visa', enable: true },
                    { name: 'master', enable: true },
                    { name: 'AE', enable: true },
                    { name: 'ATM', enable: true },
                    { name: 'banktransfer', enable: true },
                    { name: 'octopus', enable: true },
                    { name: 'alipay', enable: true },
                    { name: 'wechatpay', enable: true },
                    { name: 'unionpay', enable: true },
                    { name: 'payme', enable: true },
                    { name: 'fps', enable: true },
                    { name: 'paypal', enable: true },
                    { name: 'other', enable: true },
                    ]
               },

               nameRules: [
                    (v) => !!v || 'This Field is required',
                    (v) =>
                         (v && v.length <= 5) ||
                         'This Field must be less than 5 characters',
               ],
               numberRules: [
                    (v) => !!v.toString() || 'This Field is required',
                    (v) => !isNaN(parseInt(v)) || 'This Field must be a number',
               ],

               orgi_ptobj: {},
               ptobj: {
                    enable_pt_sys: false,
                    register_pt: null,
                    purchase_pt: null,
                    birthday_pt: null,
                    dollar_equal_point: null,
                    point_by: 'auto_extend',
                    fix: 1,

                    enable_lv_sys: false,
                    register_mark: null,
                    purchase_mark: null,
                    birthday_mark: null,
                    order_mark: null,
                    mark_by: 'eday',
                    eday: 1,
                    expiry_action: 'defaultmembership',
                    lvup: 'auto',
               },

               is_fail: false,
               is_success: false,
               success_message: '',
               error_message: '',
          }),
          methods: {
               apiGetOtherSetting() {
                    SettingService.getSettingByKey('invoice')
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   this.orgi_invobj = {
                                        ...this.orgi_invobj,
                                        ...data.value,
                                   };
                                   this.invobj = {
                                        ...this.invobj,
                                        ...data.value,
                                   };
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
                    SettingService.getSettingByKey('reward')
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   this.orgi_ptobj = {
                                        ...this.orgi_ptobj,
                                        ...data.value,
                                   };
                                   this.ptobj = {
                                        ...this.ptobj,
                                        ...data.value,
                                   };
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },

               updateRecord() {
                    SettingService.updateSettingByKey('invoice', this.invobj)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   SettingService.updateSettingByKey(
                                        'reward',
                                        this.ptobj
                                   )
                                        .then((response) => {
                                             if (response.data.status == 'success') {
                                                  BrowserSession.cleanAllSession();
                                                  BrowserSession.getAllSession();
                                                  this.is_success = true;
                                                  this.success_message = '成功更新';

                                                  this.reloadSettingCache();
                                             }
                                        })
                                        .catch((e) => {
                                             console.log(e);
                                             this.is_fail = true;
                                             this.error_message = e.message;
                                        });
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.is_fail = true;
                              this.error_message = e.message;
                         });
               },

               cancel() {
                    //console.log(this.orgi_invobj);
                    //console.log(this.invobj);
                    this.invobj = { ...this.invobj, ...this.orgi_invobj };
                    this.ptobj = { ...this.ptobj, ...this.orgi_ptobj };
               },

               reloadSettingCache() {
                    BrowserSession.renewSession('setting', null);
               },
          },

          created() {
               this.apiGetOtherSetting();

               this.roundList = [
                    { text: this.$t('setting.roundoff'), value: 'roundoff' },
                    { text: this.$t('setting.roundup'), value: 'roundup' },
                    { text: this.$t('setting.rounddown'), value: 'rounddown' },
               ],

               this.discountupdateList = [
                    { text: this.$t('setting.enable'), value: true },
                    { text: this.$t('setting.disable'), value: false },
               ],

               this.eActionList =  [
                    { text: this.$t('setting.downtodefaultlv'), value: 'defaultmembership' },
                    { text: this.$t('setting.calmembership'), value: 'calbypt' },
                    {
                         text: this.$t('setting.calmembershipnoupdownone'),
                         value: 'calbypt_stepdown',
                    },
                    {
                         text: this.$t('setting.calmembershipnoupdowndefault'),
                         value: 'calbypt_default',
                    },
               ];
          },
     };
</script>
