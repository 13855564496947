import http from '../HttpConn';
var isObject = require('lodash/isObject');
class PaymentService {
     getCount() {
          return http.get('/payment/count');
     }

     getAll(page, limit) {
          return http.get('/payment/', {
               params: { page: page, limit: limit },
          });
     }

     getPayment(id) {
          return http.get('/payment/' + id);
     }

     getPaymenyByCustomerid(cid, page, limit) {
          return http.get('/payment/bycustomer/' + cid, {
               params: { page: page, limit: limit },
          });
     }

     getPaymentByInvoice(page, limit, id) {
          return http.get('/payment/group/' + id, {
               params: { page: page, limit: limit },
          });
     }

     getPaymentTypeStatistic() {
          return http.get('payment/paymenttype');
     }

     getPaymentSumByInvoice(id) {
          return http.get('/payment/group/sum/' + id);
     }
     /* eslint-disable */
     addPayment(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/payment/add', ccobj);
     }
     addAPPayment(ccobj) {
          //check ccobj
          //{invoiceid, customerid, payment_type, pay_date, staffname}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/payment/autocppay', ccobj);
     }
     addPPayment(ccobj) {
          //check ccobj
          //{invoiceid, customerid, payment_type, amount, pay_date, remark, upid, staffname}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/payment/addcppay', ccobj);
     }
     voidPayment(ccobj) {
          //check ccobj
          //{amount, _id, staffname}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/payment/void/' + ccobj._id, ccobj);
     }
     updatePaymentStatus(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/payment/updateStatus/' + ccobj._id, ccobj);
     }
     /* eslint-enable */

     /* reporting */
     getPaymentByDateRange(dateArray) {
          return http.get(
               '/payment/bypaymentdaterange/' +
                    dateArray[0] +
                    '_' +
                    dateArray[1]
          );
     }
     deletePayment(id) {
          return http.delete('/payment/delete/' + id);
     }
}
export default new PaymentService();
