<template>
     <div class="pa-7">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="heading_color--text">
                              {{$t('package.name')}}
                         </span>
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                   >
                                        {{$t('global.morefunction')}}
                                        <v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link @click="editTag('punit')">
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small>mdi-group</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title>
                                                  {{ $t('package.manageunit') }}
                                             </v-list-item-title>
                                        </v-list-item-content>
                                   </v-list-item>

                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                             >
                                                  {{ $t('global.import') }}
                                             </v-list-item-title>
                                        </v-list-item-content>
                                   </v-list-item>

                                   <v-list-item link @click="onExportCSV()">
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{ $t('global.export')}}
                                        </v-list-item-title>
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addRecord"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">
                                   {{$t('global.add')}}
                              </span>
                         </v-btn>

                         <v-dialog v-model="dialog" persistent max-width="790px"
                              ><v-card class="pa-0">
                                   <PackageAddComponent
                                        v-if="dialog"
                                        @onClickCancel="cancelInput"
                                        @onClickInput="successInput"
                                        @onFailAction="failInput"
                                   />
                              </v-card>
                         </v-dialog>

                         <v-dialog
                              v-model="editdialog"
                              persistent
                              max-width="790px"
                              ><v-card class="pa-0">
                                   <PackageAddComponent
                                        v-if="editdialog"
                                        @onClickCancel="cancelInput"
                                        @onClickInput="successInput"
                                        @onFailAction="failInput"
                                        :_id="selectedid"
                                   />
                              </v-card>
                         </v-dialog>

                         <v-dialog
                              v-model="tagdialog"
                              persistent
                              max-width="790px"
                              ><v-card class="pa-0">
                                   <TagEditComponent
                                        v-if="tagdialog"
                                        @onClickCancel="cancelInput"
                                        @onSuccessAction="successInput"
                                        @onFailAction="failInput"
                                        :tagtype="selectedtype"
                                   />
                              </v-card>
                         </v-dialog>
                    </v-row>
               </v-container>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="6 my-auto">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{$t('package.name')}}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{$t('package.id')}} /
                                        {{$t('package.duration')}}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{$t('package.price')}} /
                                        {{$t('package.credit')}}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{$t('package.status')}}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(packagei, index) in packageList"
                         :key="packagei._id"
                         class="px-3 fill-height"
                    >
                         <v-col cols="6" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="my-auto mx-5"
                                   style="overflow: visible;"
                              >
                                   <span
                                        class="heading_color--text"
                                        style="font-size: 9px !important;"
                                        >{{
                                        (page - 1) * 5 + index + 1
                                        }}</span
                                   >
                              </v-avatar>
                              <div class="my-auto">
                                   <!--<a :href="`/packagedetail/${packagei._id}`" 
              class="text-decoration-none text-subtitle-1">
            {{ packagei.name }}</a>-->
                                   <span
                                        class="text-decoration-none text-subtitle-1"
                                        >{{ packagei.name }}</span
                                   >
                                   <div class="text2_color--text text-caption">
                                        <pre style="line-height: 0.8rem">{{
                                             packagei.description
                                        }}</pre>
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="2" class="my-auto">
                              <div class="text1_color--text text-body-2">
                                   {{ packagei.packid }}
                              </div>
                              <div class="text2_color--text text-caption">
                                   {{ packagei.effective_month }}月
                              </div>
                         </v-col>

                         <v-col cols="2" class="my-auto">
                              <div class="price_color--text text-subtitle-1">
                                   ${{ numformat(packagei.price) }}
                              </div>
                              <div>
                                   <span class="text2_color--text text-caption">
                                        {{ packagei.credit }}
                                   </span>
                                   <span class="text2_color--text text-caption">
                                        {{ packagei.unit }}</span
                                   >
                              </div>
                         </v-col>

                         <v-col cols="2" class="my-auto">
                              <div class="d-flex justify-space-between">
                                   <v-icon
                                        small
                                        :color="
                                             packagei.status == 'active'
                                                  ? 'primary'
                                                  : 'red'
                                        "
                                   >
                                        {{
                                             packagei.status == 'active'
                                                  ? 'mdi-checkbox-marked-circle-outline'
                                                  : 'mdi-cancel'
                                        }}
                                   </v-icon>
                                   <v-menu offset-y>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       modifyRecord(
                                                            packagei._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-pencil-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{$t('global.modify')}}</v-list-item-title
                                                  >
                                             </v-list-item>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       deleteRecord(
                                                            packagei._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-delete-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{$t('global.delete')}}</v-list-item-title
                                                  >
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>
          <ConfirmDialog ref="confirm" />

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import PackageService from '@/utils/api/PackageService';
     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import PackageAddComponent from './PackageAddView';
     import TagEditComponent from '@/views/components/ManageTagDialog';
     import JsonToCSV from '@/utils/JsonToCSV';
     import Permission from '@/utils/Permission';

     const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
     const moment = require('moment');
     export default {
          data() {
               return {
                    packageList: [],
                    noOfPage: 0,
                    page: 1,
                    recordperpage: 5,

                    dialog: false,
                    editdialog: false,
                    tagdialog: false,
                    is_fail: false,
                    is_success: false,
                    success_message: '',
                    error_message: '',
                    selectedid: null,
                    selectedtype: '',
               };
          },
          methods: {
               apiGetPackageList() {
                    PackageService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.packageList = response.data.data;
                              let rs = response.data.recordcount;
                              let reminder = rs % this.recordperpage;
                              if (reminder > 0) reminder = 1;
                              let ps = Math.floor(rs / this.recordperpage);
                              this.noOfPage = reminder + ps;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               onExportCSV() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    JsonToCSV.export('packagelist.csv', 'package');
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               async deleteRecord(_id) {
                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('components.confirmdeletemsg')
                         )
                    ) {
                         // eslint-disable-next-line
                         PackageService.deletePackage(_id)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.success_message = this.$t('global.successdeletedmsg');
                                        this.is_success = true;
                                        //this.apiGetRecordsCount();
                                        this.apiGetPackageList();
                                        this.reloadPackageCache();
                                   }
                              })
                              .catch((e) => {
                                   if (e.response.status == 400) {
                                        ///console.log(e.response);
                                        this.error_message = e.response.data.message;
                                   } else {
                                        this.error_message = e.message;
                                   }

                                   this.is_fail = true;
                              });
                    }
               },
               addRecord() {
                    (this.selectedid = null), (this.dialog = true);
               },
               modifyRecord(_id) {
                    //console.log(_id);
                    this.selectedid = _id;
                    this.editdialog = true;
               },
               cancelInput() {
                    this.dialog = false;
                    this.editdialog = false;
                    this.tagdialog = false;
                    this.is_success = false;
                    this.is_fail = false;
                    this.success_message = '';
                    this.error_messag = '';
               },
               successInput: function (msg) {
                    //this.apiGetRecordsCount();
                    this.apiGetPackageList();
                    this.dialog = false;
                    this.editdialog = false;
                    this.tagdialog = false;
                    this.success_message = msg;
                    this.is_success = true;
                    this.is_fail = false;
               },
               failInput: function (err_msg) {
                    this.dialog = false;
                    this.editdialog = false;
                    this.tagdialog = false;
                    this.is_success = false;
                    this.error_message = err_msg;
                    this.is_fail = true;
               },
               onChangePage() {
                    this.apiGetPackageList();
               },
               editTag(type = 'punit') {
                    this.selectedtype = type;
                    this.tagdialog = true;
               },
               numformat(number) {
                    return nf.format(number);
               },
               reloadPackageCache() {
                    BrowserSession.renewSession('package', null);
               },
          },
          created() {
               //this.apiGetRecordsCount();
               this.apiGetPackageList();
          },
          components: {
               ConfirmDialog,
               PackageAddComponent,
               TagEditComponent,
          },
     };
</script>

<style>
     .tc0 {
          background-color: white;
     }
     .tc1 {
          background-color: var(--v-post_bg-base) !important;
     }
     .v-chip.theme--light.active {
          background: var(--v-badge_primary_bg-base) !important;
          color: var(--v-badge_primary_text-base);
     }
     .v-chip.theme--light.inactive {
          background: var(--v-badge_secondary_bg-base) !important;
          color: var(--v-badge_secondary_text-base);
     }
     .amList .row {
          border-bottom-color: #f3f3f3;
          border-bottom-width: 1px;
          border-bottom-style: solid;
     }
     #menu-list-btn01 {
          margin-right: 1px !important;
     }
     #menu-list-btn02 {
          margin-right: 1px !important;
     }
</style>
