/** You have to set this configuration before create docker image
 */

const env = process.env.NODE_ENV || 'development';

var config_tmp = {
     default: {
          disablepage: [],
     },
     development: {
          //only control on permission page selection
          disablepage: ['TWwvR', 'ZCIMR', 'TiAFN', 'xtDEL', 'TJmMw'],

          disabled_page: [],
          enableautologin: true,
          enable_pt_sys: true,
          enable_lv_sys: true,

          enable_setting_tab_company: true,
          enable_setting_tab_resource: true,
          enable_setting_tab_commission: true,
          enable_setting_tab_template: true,
          enable_setting_tab_notification: true,
          enable_setting_tab_api: true,
          enable_setting_tab_media: true,
          enable_setting_tab_other: true,

          footer: 'Copyright © 2024 Testing company limited',
     },
     production: {
          //only control on permission page selection
          disablepage: ['TWwvR', 'ZCIMR', 'TiAFN', 'xtDEL', 'TJmMw'],
          //quot, appointment, commission report

          //control navigation menu by config file
          disabled_page: ['TWwvR', 'ZCIMR', 'TiAFN', 'xtDEL', 'TJmMw'],
          //quot, appointment, commission report

          enableautologin: true,
          enable_pt_sys: false,
          enable_lv_sys: true,

          enable_setting_tab_company: true,
          enable_setting_tab_resource: false,
          enable_setting_tab_commission: false,
          enable_setting_tab_template: true,
          enable_setting_tab_notification: true,
          enable_setting_tab_api: false,
          enable_setting_tab_media: true,
          enable_setting_tab_other: true,

          footer: 'Copyright © 2024 Salon Go Ahead Limited',
     },
};

var config = {
     ...config_tmp.default,
     ...config_tmp[env],
};

module.exports = config;
