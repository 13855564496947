import http from '../HttpConn';
var isObject = require('lodash/isObject');
class QuotationService {
     getAll(page, limit) {
          return http.get('/quotation', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/quotation/count');
     }
     getLastQuotationId() {
          return http.get('/quotation/lastid');
     }
     getQuotation(id) {
          return http.get('/quotation/' + id);
     }
     /* eslint-disable */
     updateQuotation(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/quotation/update/' + ccobj._id, ccobj);
     }
     updateQuotationStatus(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/quotation/updatestatus/' + ccobj._id, ccobj);
     }
     addQuotation(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/quotation/add', ccobj);
     }
     deleteQuotation(id) {
          return http.delete('/quotation/delete/' + id);
     }
     /* eslint-enable */
}
export default new QuotationService();
