import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
//import vuetify from 'vuetify/lib';
import i18n from './plugins/i18n/i18n';

import auth from './utils/Authentication';

//Vue.config.productionTip = false;

//global variable
Vue.prototype.$auth = new auth();
Vue.prototype.$user = Vue.observable({
     username: '',
});

new Vue({
     router,
     vuetify,
     i18n,
     render: (h) => h(App),
}).$mount('#app');
