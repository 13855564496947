export default {
     global: {
          confirm: '確定',
          cancel: '取消',
          close: '關閉',
          ok: 'OK',
          update: '更新',
          add: '建立',
          modify: '修改',
          delete: '刪除',
          save: '保存',
          type: '類別',
          category: '分類',
          brand: '品牌',
          unit: '單位',
          required: '*必填欄位',
          fieldrequired: '此欄位是必填',
          successPrefix: '成功',
          successPostfix: '',
          failPrefix: '失敗',
          failPostfix: '',
          male: '男',
          female: '女',
          createdat: '建立於',
          newrecord: '新資料',
          expiry: '過期',
          remark: '備註',
          log: '日誌',
          end: '完',
          name: '名稱',
          consume: '消費',
          plsentervalid: '請輸入正確資料',
          morefunction: '更多功能',
          import: '匯入資料',
          export: '匯出資料',
          search: '搜索',
          filter: '篩選',
          date: '日期',
          eq: '等於',
          gt: '大於',
          lt: '少於',
          active: '啟用',
          inactive: '未啟用',
          duplicate: '複製',
          print: '打印',
          permissionerrmsg: '抱歉，你沒有權限存取！',
          notfound: '找不到資料',
          or: '或',
          password: '密碼',
          errorgettingdata: '未能取得資料!',
     },
     appointment: {
          resource: '資源',
          salesfilter: '雇員篩選',
          resourcefilter: '資源篩選',
          addevent: '新增預約',
          modifyevent: '修改預約',
          searchbynameandphone: '輸入電話或名稱搜尋',
          bookservice: '預約服務',
          bookresource: '預約資源',
          dateandtime: '預約日期及時間',
          free: '有空',
          available: '尚有',
          busy: '繁忙',
          starttime: '開始時間',
          endtime: '結束時間',
          bookedevent: '已被預約時段',
          bookedresource: '已預約資源',
          thisbooking: '本預約',
          newbooking: '新預約',
          customernotfoundmsg: '找不到用戶',
          missingcustomerinfomsg: '請輸入客戶資料',
          missingstaffmsg: '請選擇負責僱員',
          completed: '完成',
          detail: '詳細資料',
          normalbooking: '一般預約',
     },
     components: {
          gallery: '圖片庫',
          uploadimage: '上傳圖片',
          sessionexpirytitle: '登入過期',
          sessionexpirymsg: '你的登入已過期，請重新登入!',
          dashboard: '儀表板',
          appointment: '預約',
          customer: '客戶',
          customerlv: '客戶級別',
          product: '商品',
          service: '服務',
          package: '套票',
          sales: '銷售',
          quotation: '報價',
          invoice: '發票',
          transaction: '交易',
          payment: '收款',
          expense: '支出',
          report: '報表',
          salereport: '銷售報表',
          statement: '客戶對帳表',
          customersummary: '客戶銷售報表',
          productreport: '商品報表',
          paymentreport: '收款明細報表',
          salesreport: '銷售員報表',
          salessummary: '銷售員明細報表',
          commissionreport: '佣金報表',
          employee: '僱員',
          permissiongroup: '權限組別',
          app: '應用',
          blog: '文章',
          pushmsg: '推送',
          segment: '分組',
          setting: '設定',
          companysetting: '公司設定',
          resourcesetting: '資源設定',
          commissionsetting: '佣金計劃',
          templatesetting: '模板列表',
          notificationsetting: '通知設定',
          apisetting: 'API設定',
          mediasetting: '媒體管理',
          othersetting: '其他設定',
          execute: '執行',
          confirmdeletemsg: '你是否要刪除此項？刪除後不能復原',
          executesegmentmsg:
               '你是否要執行會員分組？程序運行可能需時數分鐘或數小時，繼續嗎？',
          successdeletedmsg: '成功刪除資料',
          exesegmentsuccessmsg: '完成執行動作，您現在可以此客戶分組',
          modifystatus: '修改狀態',
          booking: '會員預約',
     },
     customer: {
          addcustomer: '新增客戶',
          modifycustomer: '修改客戶',
          customer: '客戶',
          name: '客戶名稱',
          company: '公司名稱',
          phone: '電話',
          email: '電郵',
          dob: '生日',
          quickselectyear: '快速選年',
          gender: '性別',
          sales: '負責員工',
          address: '客戶地址',
          detail: '詳細資料',
          tag: '識別標簽',
          status: '狀態',
          randm: '積分/等級',
          reward: '獎賞積分',
          membershippt: '等級點數',
          joinmembership: '參加會員積分系統？',
          membershiplv: '級別',
          memebshipexpiry: '級別到期日',
          createmembershipfirst: '請先建立用戶級別！',
          modifymembership: '修改級別',
          membershipname: '級別名稱',
          setdiscount: '給予優惠',
          membershipreq: '等級點數要求',
          setdefaultlv: '設為預設級別',
          contactdetail: '聯絡資料',
          otherdetail: '其他資料',
          remain: '餘',
          newconsumerecord: '新增存品消費',
          consumecredit: '消費額度',
          overdue: '過期未付',
          outstanding: '待付',
          createinvoice: '建立發票',
          segmentname: '分組名稱',
          segmentcondition: '篩選條件',
          segmentcompare: '比較',
          value: '值',
          addnewcondition: '增加條件',
          addtagmsg: '輸入資料，按<kbd>enter</kbd>建立新的標簽。',
          suspendaccount: '停用帳戶',
          activeaccount: '啟用帳戶',
          modifylogin: '登入更新',
          searchplaceholder: '基本搜索(客戶全名,電話,標籤)',
          newpassword: '新密碼',
          generate: '生成新密碼',
          allowlogin: '允許帳戶登入',
          modifyinfomsg1: '1) 你需要點擊生成新密碼，取得該帳戶的新密碼',
          modifyinfomsg2:
               '2) 你必需輸入權限密碼，然後按更新，方可以更新登入資料',
          superpassword: '權限密碼',
          advancesearch: '進階搜索',
          fulltagplaceholder: '必需整全名稱',
     },
     dashboard: {
          sevendayssales: '7天銷售',
          overdue: '逾期',
          newcustomer: '新客戶',
          todayinvoices: '今天銷售數',
     },
     invoice: {
          amount: '總額',
          invoicedate: '發票日期',
          invoiceamount: '發票金額',
          paid: '已付',
          unpaid: '未付',
          void: '作廢',
          status: '狀態',
          expensename: '支出名稱',
          receiver: '收款人',
          duedate: '到期日',
          paymentmethod: '支付方式',
          detail: '詳細資料',
          paymentdate: '付款日期',
          paymentinit: '待付',
          paymentdone: '完成',
          cash: '現金',
          cheque: '支票',
          creditcard: '信用卡',
          visa: 'VISA',
          master: 'MASTER',
          AE: 'AE',
          ATM: '銀行卡',
          banktransfer: '轉帳',
          octopus: '八達通',
          alipay: '支付寶',
          wechatpay: '微信支付',
          unionpay: '銀聯卡',
          payme: 'PayMe',
          applepay: 'Apple pay',
          fps: '轉數快',
          paypal: 'Paypal',
          other: '其他',
          expenserecord: '支出記錄',
          expensetips:
               '<span>你的支出記錄</span><br /><span>你可以記錄發票上的支出</span><br /><span>或所有應繳付的帳單</span>',
          donetips: '完成此筆支付',
          inittips: '此筆支付未完成',
          voidtips: '將此筆付款作廢',
          importtemplate: '匯入模板',
          selectcustomer: '請選擇客戶',
          salesrepresentative: '銷售人員',
          invoiceno: '發票編號',
          pono: '購貨單號',
          paybefore: '付款期限',
          invoicecurrency: '結算貨幣',
          unitprice: '單價',
          qty: '數量',
          subtotal: '小計',
          tax: '稅款',
          discount: '折扣',
          tandc: '條款',
          invoicetag: '為訂單添加識別標簽',
          redeemmark: '兌換積分',
          redeemtips:
               '<span>你可使用積分兌換來購物</span><br /><span>兌換積分可用作扣減訂單額</span>',
          redeem: '兌換',
          redeemamount: '兌換金額',
          invoicepayment: '一併添加付款資料',
          invoicepaymenttips:
               '<span>你可為發票添加付款資料</span><br /><span>你可以按「全額」將總額放到此收入中</span><br /><span>當你保存時，會自動新增此筆收入</span>',
          allamount: '全額',
          payandsave: '付款及保存',
          commissionto: '受佣人',
          commissiontotips: '預設為單據銷售員',
          commission: '佣金',
          commissiontips: '佣金會根據商品設定自動計算',
          stockit: '商品/套票存到用戶帳戶',
          setserviceconsume: '設定消費服務(最多可設定2個)',
          selectconsumeservice: '選擇消費的服務',
          modifytandc: '修改條款',
          remainstockcredit: '可用存有額',
          maxusagelimit: '最大可用次數',
          unlimit: '不限',
          consumed: '已使用次數',
          exceedlimit: '超過可用次數',
          include: '包括',
          invoicetips:
               '<span>你可建立及修改發票</span><br /><span>以及發票收付款的功能</span><br /><span>一旦發票「作癈」後，所有操作都會禁止</span>',
          printpreview: '預覽打印',
          invoicedonetips: '全款已收，將此單據更新為完成',
          invoiceinittips: '仍有付款未完成',
          invoicevoidtips: '將此訂單作廢，不能刪除',
          paymentfunction: '支付功能',
          addnewpayment: '新增付款',
          newpaymenttips: '為單據新增付款記錄',
          savetemplate: '保存模板',
          savetemplatetips: '將此發票保存為模板',
          overamountmsg: '收款額多於應收金額，是否仍要繼續？',
          billto: '帳單客戶',
          nondiscountable: '不可折扣',
          nametemplate: '輸入模板名稱',
          templateerrmsg: '請輸入正確的模板名稱!',
          paymentincompletemsg: '此單據未完成所有付款，你仍要完成此單據嗎？',
          voidmsg: '發票作癈後不能復原!<br>此動作<b>不會</b>將<b>付款</b>資料作癈，<br>你需手動作癈其單據下的付款記錄，以免發生問題！',
          voidpaymentmsg:
               '你是否要作癈此款項？作癈後不能復原!<br>此動作<b>會</b><br>1.作廢此付款<br>2.扣減客戶積分（如啟動了會員系統 ）<br>3.返還套票使用額<br>但<b>不會</b><br>1.改變發票狀態<br>2.取消客戶存有項目<br>3.取消已升會員級別',
          paymenttips:
               '<span>透過此方法建立的收款</span><br /><span>不能使用客戶的獎賞積分！</span>',
          unpaidamount: '未付金額',
          autoupdateinvoicestatus: '自動更新發票狀態',
          isDiscountable: '可給予折扣',
          paymentamount: '支付總額',
          searchplaceholder: '基本搜索(客戶名稱,電話等)',
          read: '觀看',
          searchbynameandphone: '輸入電話或名稱搜尋',
          advancesearch: '進階搜索',
          selectinvoice: '選擇發票',
          normalpayment: '一般支付',
          packagepayment: '套票支付',
          receivable: '應收',
     },
     mobile: {
          stoppublish: '停止發佈',
          content: '內容',
          publishdate: '發佈日期',
          status: '狀態',
          blogstatus: '文章狀態',
          stoppublishdesc: '將文章設為不顯示',
          publish: '發佈',
          publishdesc: '將文章設為公開顯示',
          title: '標題',
          setimage: '設定圖片',
          shortdesc: '簡介',
          deliverydate: '發送日期',
          stopdeliverydesc: '將訊息停止安排發佈',
          deliverydesc: '將訊息設為重新安排發佈',
          target: '目標客戶',
          messagesetting: '訊息內容設定',
          targetsetting: '客戶群設定',
          deliverysetting: '訊息發送設定',
          advancesetting: '進階設定',
          deliverydatetime: '發送時間',
          sound: '訊息提示音',
          redirectpost: '選擇導向文章',
          booking: '預約',
          bookingdate: '預約日期',
          bookingperson: '預約人',
          bookedservice: '預約服務',
          remark: '備註',
          person: '人',
          personcount: '到訪人數',
          phone: '電話',
          pending: '待批',
          pendingdesc: '將預約改為等待批核',
          approved: '批核',
          approveddesc: '將預約改為批核',
          rejected: '拒絕',
          rejectdesc: '拒絕預約',
     },
     product: {
          name: '商品名稱',
          stock: '存品',
          remaincredit: '剩餘額度',
          status: '狀態',
          detail: '詳細資料',
          cost: '成本',
          credit: '額度',
          managecategory: '管理分類',
          managebrand: '管理品牌',
          manageunit: '管理單位',
          sku: 'SKU',
          category: '分類',
          setimage: '設定圖片',
          imagemsg: '上傳的圖片請為jpg或png格式，並不大於100KB(400*400)',
          inputcategory: '你可直接輸入新分類',
          brand: '品牌',
          inputbrand: '你可直接輸入新品牌',
          price: '售價',
          unit: '單位',
          inputunit: '你可直接輸入新單位',
          barcode: '條碼',
          commission: '佣金',
          allowpersist: '允許存到客戶帳戶',
          allowdiscount: '允許折扣',
          categoryerrormsg: '建立新的分類時發生問題',
          branderrormsg: '建立新的品牌時發生問題',
          uniterrormsg: '建立新的單位時發生問題',
     },
     service: {
          name: '服務',
          managecategory: '管理分類',
          manageunit: '管理單位',
          category: '分類',
          serviceno: '服務編號',
          price: '單價',
          status: '狀態',
          setimage: '設定圖片',
          imagemsg: '上傳的圖片請為jpg或png格式，並不大於100KB(400*400)',
          inputcategory: '你可直接輸入新分類',
          unit: '單位',
          inputunit: '你可直接輸入新單位',
          serviceduration: '服務時長',
          minute: '分鐘',
          cost: '成本',
          detail: '詳細資料',
          commission: '佣金',
          allowpersist: '允許存到客戶帳戶',
          allowdiscount: '允許折扣',
          categoryerrormsg: '建立新的分類時發生問題',
          uniterrormsg: '建立新的單位時發生問題',
     },
     package: {
          name: '套票',
          manageunit: '管理單位',
          id: '號碼',
          duration: '期效',
          price: '單價',
          status: '狀態',
          credit: '額度',
          allowpersist: '允許存到客戶帳戶',
          allowdiscount: '允許折扣',
          convertcredit: '兌換額',
          unit: '單位',
          inputunit: '你可直接輸入新單位',
          detail: '詳細資料',
          month: '月',
          commission: '佣金',
          specifyservice: '指定服務',
          limit: '限制次數',
          consumecredit: '每次消費扣減',
          cost: '成本',
          uniterrormsg: '建立新的單位時發生問題',
     },
     report: {
          salesreport: '銷售報表',
          salesreporttips:
               '<span>查詢時間段內所有的銷售單</span><br /><span>不選擇狀態代表全部單據都顯示</span>',
          daterange: '日期範圍',
          invoicestatus: '單據狀態',
          today: '今天',
          past7day: '過去7天',
          past1month: '過去30天',
          thismonth: '本月',
          pastmonth: '上月',
          executereport: '執行報告',
          statementreport: '客戶對帳報告',
          statementreporttips:
               '<span>時間段內指定客戶的銷售單據報表</span><br /><span>預設單據篩選狀態是「已付」及「未付」</span>',
          balance: '結餘',
          customerreport: '客戶銷售報告',
          customerreporttips:
               '<span>查詢時間段內全部客戶的銷售慨覽</span><br /><span>（不包含已刪除的銷售單）</span><br /><span>如你需要查詢已刪除的銷售單，你可以查詢「客戶對帳報告」</span>',
          productreport: '商品報告',
          productreporttips:
               '<span>查詢時間段內商品銷售量</span><br /><span>已作廢的單據不會納入計算</span><br /><span>單據折扣優惠不會納入計算</span>',
          benefit: '利潤',
          paymentreport: '收款明細報表',
          paymentreporttips: '<span>查詢時間段內單據的付款情況</span><br />',
          invoicestatusstat: '付款狀態統計',
          paymentstat: '付款統計',
          employeereport: '銷售員報告',
          employeereporttips:
               '<span>查詢時間段內銷售員的商品統計</span><br /><span>已作廢的單據不會納入計算</span><br /><span>單據折扣優惠不會納入計算</span>',
          selectsales: '選擇員工',
          totalsales: '總銷售',
          totalcost: '總成本',
          employeedetailreport: '銷售員明細報告',
          employeedetailreporttips:
               '<span>時間段內指定銷售員的銷售單據報表</span><br /><span>預設單據篩選狀態是「已付」</span><br /><span>你可設定目標以訂單或商品作篩選</span>',
          target: '目標',
          targettips:
               '<span>目標篩選是基於整張「單據」或單據內的「服務及商品」作篩選</span><br /><span>目標為單據時，會篩選出屬於此員工的單據</span><br /><span>目標為服務及商品時，會篩選所有單據中，屬於此員工稍售的服務及商品，非此員工的單據也會顯示</span>',
          invoiceitems: '發票項目',
          totalcommission: '總佣金',
          totalqty: '總銷售量',
          totalnet: '總利潤',
          net: '利潤',
          productsummary: '商品總結',
          servicesummary: '服務總結',
          packagesummary: '套票總結',
     },
     setting: {
          companyinfo: '公司資料',
          imageuploaddesc: '上傳的圖片請為jpg或png格式，並不大於100KB(800x800)',
          companyname: '公司名稱',
          companyphone: '公司電話',
          companywebsite: '公司網頁',
          companyfax: '公司傳真',
          address: '地址',
          businesshour: '營業時間',
          workstart: '上班時間',
          workend: '下班時間',
          open: '營業',
          close: '休息',
          companypage: '公司專頁',
          manageunit: '管理單位',
          resourcename: '資源名稱',
          type: '類別',
          status: '狀態',
          description: '簡介',
          addnewtypedesc: '輸入資料，按enter',
          commissionname: '計劃名稱',
          salesrange: '銷售額範圍',
          getcommission: '所得佣金',
          commissionhint:
               'Simple: 簡單佣金計劃，根據所銷售的總額計算佣金 Progression: 根據銷售額，層層演進計算佣金',
          saleamountlow: '銷售額(最低)',
          saleamounthigh: '銷售額(最高)',
          commissioncal: '佣金計算方式',
          commissionvalue: '佣金數量',
          templatename: '模版名稱',
          belongtostaff: '所屬員工',
          createdat: '建立於',
          notificationsetting: '推送訊息設定',
          enablenotification: '使用推送服務',
          plsuploadconfig: '請上傳設定檔',
          firebasefile: '上傳 firebase 服務帳戶的JSON文件',
          firebasefilehint: '上傳新JSON文件前，請先將舊文件備份',
          smtpsetting: 'SMTP設定',
          enablesmtp: '使用SMTP',
          smtpserver: 'SMTP 伺服器',
          smtpport: 'SMTP 端口',
          smtpuser: 'SMTP 用戶',
          smtppassword: 'SMTP 密碼',
          smtpfrom: '寄出電郵',
          smtptest: '測試郵件',
          smtpsendtest: '傳送測試',
          enablegooglebu: '使用Google API備份服務',
          googledrivefile: '上傳 google drive 服務帳戶的JSON文件',
          googledrivefilehint:
               '*請在你的google drive上，建立好一個備份資料夾，並且分享給 google service 帳戶上的電郵地址，備份方可進行。',
          backupnow: '備份數據',
          backupmedia: '備份媒體',
          runningbackup: '正在備份檔案，請稍候...(備份時間視乎檔案大小)',
          invoicesetting: '發票設定',
          invoiceprefix: '發票前綴',
          decimalplace: '小數位數',
          carrysetting: '進位設定',
          autoupdatediscount: '自動更新折扣',
          paymentdue: '付款期限(天)',
          term: '條款',
          paymenttype: '收款類型',
          membersystemsetting: '會員系統設定',
          enablereward: '使用奬賞積分系統',
          earnreward: '會員奬賞積分獲取',
          register: '註冊獲得',
          point: '分',
          consumeonedollar: '消費每一元獲得',
          dob: '生日獲得',
          pointexpirysetting: '積分期限設定',
          fixdateexpiry: '指定日子過期',
          expirydate: '過期日',
          memberdateexpiry: '會員帳戶過期日，交易時續限期',
          memberdateexpirynoextend: '會員帳戶過期日',
          extend: '即日起延續',
          day: '天',
          noexpiry: '不過期',
          pointredeemsetting: '兌換設定',
          onepointredeemevery: '每X積分兌換1元購物值',
          enablelevel: '使用會員等級點數系統',
          earnlevelpoint: '會員等級點數獲取方法',
          eachorder: '每訂單獲得',
          membershipexpirysetting: '等級期限設定',
          membershipcal: '計算等級策略',
          lvupsetting: '級別升級設定',
          auto: '自動',
          manual: '手動',
          downtodefaultlv: '一律回到預設等級',
          calmembership: '根據分數重新計算等級',
          calmembershipnoupdownone: '根據分數重新計算(不升級)，不足降一級',
          calmembershipnoupdowndefault:
               '根據分數重新計算(不升級)，不足回到預設等級',
          enable: '使用',
          disable: '停用',
          roundoff: '四捨五入',
          roundup: '上捨',
          roundown: '下捨',
     },
     user: {
          name: '用戶名稱',
          group: '用戶組別',
          phone: '電話',
          employdate: '入職日期',
          status: '狀態',
          suspendaccount: '停用帳戶',
          userinfo: '僱員資料',
          loginsetting: '登入設定',
          commissionsetting: '佣金設定',
          hoursetting: '上班時間',
          avatar: '頭像',
          gender: '性別',
          occupation: '職位',
          email: '電郵',
          id: '身份證',
          dob: '生日日期',
          address: '地址',
          remark: '備註',
          loginname: '登入名稱',
          loginpassword: '登入密碼',
          allowlogin: '允許帳戶登入',
          commissionplans: '佣金計劃',
          workstart: '上班時間',
          workend: '下班時間',
          open: '營業',
          close: '休息',
          monday: '星期一',
          tuesday: '星期二',
          wednesday: '星期三',
          thursday: '星期四',
          friday: '星期五',
          saturday: '星期六',
          sunday: '星期日',
          groupname: '權限組別名稱',
          selectpermission: '選擇給予的權限',
          BIMeL: '查閱儀表板',
          BIMeLdesc: '給予查閱儀表板的權限',
          TiAFN: '查閱預約',
          TiAFNdesc: '給予查閱預約頁的權限',
          xtDEL: '管理預約',
          xtDELdesc: '給予管理預約頁的權限, 如添加, 修改等',
          xDWyh: '查閱客戶',
          xDWyhdesc: '給予查閱客戶頁的權限',
          HVvyR: '管理客戶',
          HVvyRdesc: '給予管理客戶頁的權限, 如添加, 修改等',
          BWNcA: '查閱客戶級別',
          BWNcAdesc: '給予查閱客戶級別頁的權限',
          oTtHe: '管理客戶級別',
          oTtHedesc: '給予管理客戶級別頁的權限, 如添加, 修改等',
          fNaDt: '查閱商品',
          fNaDtdesc: '給予查閱商品頁的權限',
          mLaeD: '管理商品',
          mLaeDdesc: '給予管理商品頁的權限, 如添加, 修改等',
          tJnMZ: '查閱服務',
          tJnMZdesc: '給予查閱服務頁的權限',
          kbwnQ: '管理服務',
          kbwnQdesc: '給予管理服務頁的權限, 如添加, 修改等',
          ZvkrC: '查閱套票',
          ZvkrCdesc: '給予查閱套票頁的權限',
          ChZpz: '管理套票',
          ChZpzdesc: '給予管理套票頁的權限, 如添加, 修改等',
          TWwvR: '查閱報價',
          TWwvRdesc: '給予查閱報價頁的權限',
          ZCIMR: '管理報價',
          ZCIMRdesc: '給予管理報價頁的權限, 如添加, 修改等',
          pbKyf: '查閱發票',
          pbKyfdesc: '給予查閱發票頁的權限',
          Vbgoh: '管理發票',
          Vbgohdesc: '給予管理發票頁的權限, 如添加, 修改等',
          ONyFq: '查閱收款',
          ONyFqdesc: '給予查閱收款頁的權限',
          uamho: '管理收款',
          uamhodesc: '給予管理收款頁的權限, 如添加, 修改等',
          GTgMN: '查閱支出',
          GTgMNdesc: '給予查閱支出頁的權限',
          ovyeA: '管理支出',
          ovyeAdesc: '給予管理支出頁的權限, 如添加, 修改等',
          QqseP: '查閱銷售報表',
          QqsePdesc: '給予查閱銷售報表頁的權限',
          LARTa: '查閱客戶對帳表',
          LARTadesc: '給予查閱客戶對帳表頁的權限',
          shsJo: '查閱客戶銷售表',
          shsJodesc: '給予查閱客戶銷售報表頁的權限',
          UHQDQ: '查閱商品報表',
          UHQDQdesc: '給予查閱商品報表頁的權限',
          XxvFG: '查閱收款明細報表',
          XxvFGdesc: '給予查閱收款明細報表頁的權限',
          sIWzT: '查閱銷售員/明細報表',
          sIWzTdesc: '給予查閱銷售員/明細報表頁的權限',
          TJmMw: '查閱佣金報表',
          TJmMwdesc: '給予查閱佣金報表頁的權限',
          lwaRc: '管理員工/權限組別',
          lwaRcdesc: '給予管理員工/權限組別頁的權限, 如添加, 修改等',
          ukWQg: '管理設定',
          ukWQgdesc: '給予管理設定頁的權限',
          OEVMz: '查閱文章',
          OEVMzdesc: '給予查閱手機文章頁的權限',
          NogJD: '管理文章',
          NogJDdesc: '給予管理手機文章頁的權限, 如添加, 修改等',
          cRPPN: '查閱推送',
          cRPPNdesc: '給予查閱手機訊息推送頁的權限',
          fOESf: '管理推送',
          fOESfdesc: '給予管理手機訊息推送頁的權限, 如添加',
          LLVWE: '查閱客戶分組',
          LLVWEdesc: '給予查閱客戶分組頁的權限',
          Mpxzf: '管理客戶分組',
          Mpxzfdesc: '給予管理客戶分組頁的權限, 如將用戶分組',
          KvVMJ: '查閱手機預約',
          KvVMJdesc: '給予查閱手機預約頁的權限',
          VmDPa: '管理手機預約',
          VmDPadesc: '給予管理手機預約頁的權限',
          hafeB: '查閱佣金計劃',
          hafeBdesc: '給予查閱佣金計劃頁的權限',
          AhcXU: '管理佣金計劃',
          AhcXUdesc: '給予管理佣金計劃頁的權限',
     },
};
