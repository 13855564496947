<template>
     <div class="pa-7">
          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-10 py-5 post_bg"
          >
               <div class="text-h6 mt-5">{{$t('components.apisetting')}}</div>
               <v-divider class="mb-5"></v-divider>
               <div class="pa-5">
                    <v-row>
                         <v-col class="d-flex flex-row">
                              <span class="my-auto">
                                   {{ $t('setting.enablegooglebu') }}
                              </span>
                              <v-switch
                                   class="my-auto ml-5"
                                   v-model="backup_enable"
                                   dense
                                   inset
                                   hide-details
                              >
                              </v-switch>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col>
                              <span class="text-caption">Current File: </span
                              ><span
                                   v-if="set_file != null"
                                   class="text-caption text-decoration-underline"
                                   @click="openDialog"
                                   >{{ this.set_file }}</span
                              ><span
                                   v-else
                                   >{{$t('setting.plsuploadconfig')}}</span
                              >
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="pa-1">
                              <v-file-input
                                   v-model="google_file"
                                   show-size
                                   counter
                                   outlined
                                   dense
                                   :label="$t('setting.googledrivefile')"
                                   accept="application/json"
                                   :rules="fileRules"
                                   :disabled="!backup_enable"
                                   :hint="$t('setting.firebasefilehint')"
                                   persistent-hint
                                   background-color="#ffffff"
                              ></v-file-input>
                         </v-col>
                    </v-row>

                    <v-row>
                         <div class="text-caption pa-1">
                              {{ $t('setting.googledrivefilehint') }}
                         </div>
                    </v-row>
                    <div class="d-flex flex-row justify-end">
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold my-4 mr-3"
                              @click="backupnow('db')"
                              :disabled="!backup_enable"
                              :loading="uploading"
                         >
                              {{$t('setting.backupnow')}}
                         </v-btn>

                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold my-4"
                              @click="backupnow('media')"
                              :disabled="!backup_enable"
                              :loading="uploading"
                         >
                              {{$t('setting.backupmedia')}}
                         </v-btn>
                    </div>
                    <v-row v-if="uploading">
                         <v-col cols="2">
                              <v-progress-circular
                                   indeterminate
                                   color="primary"
                              ></v-progress-circular
                         ></v-col>
                         <v-col>
                              <div>
                                   {{$t('setting.runningbackup')}}
                              </div>
                         </v-col>
                    </v-row>
               </div>
          </v-form>

          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="clickUpdate"
                    >
                         {{$t('global.save')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>

          <v-dialog v-model="content_dialog" width="500">
               <v-card class="pa-5">
                    <code class="text-caption">{{
                         this.set_file_content
                    }}</code>
               </v-card>
          </v-dialog>

          <ConfirmDialog ref="confirm" />

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import SettingService from '@/utils/api/SettingService';
     import uploadService from '@/utils/uploadFileService';
     import BrowserSession from '@/utils/BrowserSession';
     import ConfirmDialog from '@/views/components/ConfirmDialog';

     export default {
          data: () => ({
               valid: true,

               config_setting: null,

               backup_enable: false,
               set_file: null,
               set_file_content: null,
               google_file: null,
               content_dialog: false,

               uploading: false,
               fileRules: [
                    (value) =>
                         !value ||
                         value.size < 10000 ||
                         'Size should be less than 10 KB!',
               ],

               is_fail: false,
               is_success: false,
               success_message: '',
               error_message: '',

               progress: 0,
          }),
          methods: {
               apiGetBackupSetting() {
                    SettingService.getSettingByKey('config')
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.config_setting = response.data.data.value;

                                   this.backup_enable =
                                        this.config_setting.googledrive_backup_enable;
                                   this.set_file =
                                        this.config_setting.googledrive_config_path;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               uploadfile() {
                    //console.log(this.google_file);
                    this.uploading = true;
                    uploadService
                         .uploadConfig(this.google_file, 'googledrive', (event) => {
                              this.progress = Math.round(
                                   (100 * event.loaded) / event.total
                              );
                         })
                         .then((response) => {
                              this.uploading = false;
                              this.progress = 0;
                              this.updateRecord(response.data.file.path);
                         })
                         .catch((err) => {
                              this.uploading = false;
                              this.progress = 0;
                              this.is_fail = true;
                              this.error_message = err.message;
                         });
               },
               updateRecord(path = null) {
                    SettingService.updateSettingByKey('config', {
                         ...this.config_setting,
                         ...{
                              googledrive_backup_enable: this.backup_enable,
                              googledrive_config_path: path || this.set_file,
                         },
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.is_success = true;
                                   this.success_message = '成功更新';
                                   this.apiGetBackupSetting();
                                   this.google_file = null;
                                   this.reloadSettingCache();
                              }
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.is_fail = true;
                              this.error_message = e.message;
                         });
               },
               openDialog() {
                    this.content_dialog = true;
                    SettingService.getSettingFile(this.set_file)
                         .then((response) => {
                              //console.log(response);
                              if (response.status == 200)
                                   this.set_file_content = response.data;
                         })
                         .catch((e) => {
                              this.set_file_content = e;
                         });
               },
               clickUpdate() {
                    if (this.google_file != null) this.uploadfile();
                    else this.updateRecord();
               },
               async backupnow(type) {
                    if (
                         await this.$refs.confirm.open(
                              '確定操作',
                              '你是否現在進行備份？備份需要較長時間，繼續執行？'
                         )
                    ) {
                         this.uploading = true;
                         SettingService.backupnow(type)
                              .then((response) => {
                                   this.uploading = false;

                                   if (response.data.status == 'success') {
                                        this.is_success = true;
                                        this.success_message =
                                             '備份成功，請查閱你的google drive';
                                        return;
                                   }

                                   this.is_fail = true;
                                   this.fail_message = response.data.message;
                              })
                              .catch((e) => {
                                   console.log(e);
                                   this.is_fail = true;
                                   this.fail_message = e.response.data.error;
                              });
                    }
               },

               cancel() {
                    this.apiGetBackupSetting();
               },

               reloadSettingCache() {
                    BrowserSession.renewSession('setting', null);
               },
          },
          created() {
               this.apiGetBackupSetting();
          },
          components: {
               ConfirmDialog,
          },
     };
</script>
