<template>
     <div>
          <v-timeline dense clipped>
               <v-timeline-item
                    color="primary"
                    small
                    v-for="(log, index) in logobjs"
                    :key="index"
               >
                    <v-row class="pt-1">
                         <v-col cols="2" class="px-0 my-auto">
                              <span class="text-caption">{{
                                   formatDate(log.created_date)
                              }}</span>
                         </v-col>
                         <v-col>
                              <div class="font-weight-medium">
                                   {{ log.action }}
                              </div>
                              <div
                                   class="text-caption font-weight-light"
                                   style="width: 90%"
                              >
                                   {{ log.content }}
                              </div>
                              <div class="text2_color--text text-caption">
                                   {{ formatTime(log.created_date) }}
                              </div>
                         </v-col>
                    </v-row>
               </v-timeline-item>
          </v-timeline>
     </div>
</template>

<script>
import CustomerProfileService from '@/utils/api/CustomerProfileService';

const moment = require('moment');
export default {
     props: { customerobj: Object },
     data: () => ({
          logobjs: null,
     }),
     methods: {
          apiGetRecords() {
               CustomerProfileService.getCustomerLog(
                    'log',
                    this.customerobj._id
               )
                    .then((response) => {
                         if (response.data.status == 'success')
                              this.logobjs = response.data.data;
                    })
                    .catch((e) => {
                         console.log(e);
                    });
          },
          formatDate(value) {
               return moment(value).format('ll');
          },
          formatTime(value) {
               return moment(value).format('LT');
          },
     },
     created() {
          this.apiGetRecords();
     },
};
</script>
