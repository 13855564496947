var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-7"},[(_vm.havePermit())?_c('div',{staticClass:"white rounded elevation-3"},[_c('div',{staticClass:"text1_color--text pa-5"},[_vm._v(" "+_vm._s(_vm.$t('components.dashboard'))+" ")]),_c('v-divider',{staticClass:"py-5"}),_c('v-container',{staticClass:"px-7 d-flex flex-row"},[_c('v-card',{staticClass:"mx-2",attrs:{"width":"300","color":"blue darken-2"}},[_c('v-card-title',{staticClass:"text-h5 white--text"},[_vm._v(" $"+_vm._s(_vm.formatCurrency(_vm.sevenDaysAmount))+" ")]),_c('v-card-subtitle',{staticClass:"text2_color--text text--lighten-3"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.sevendayssales'))+" ")])],1),_c('v-card',{staticClass:"mx-2",attrs:{"width":"300","color":"blue darken-2"}},[_c('v-card-title',{staticClass:"text-h5 white--text"},[_vm._v(" $"+_vm._s(_vm.formatCurrency(_vm.overdueAmount))+" ")]),_c('v-card-subtitle',{staticClass:"text2_color--text text--lighten-3"},[_vm._v(" "+_vm._s(_vm.overdueCount)+" "+_vm._s(_vm.$t('dashboard.overdue'))+" ")])],1),_c('v-card',{staticClass:"mx-2",attrs:{"width":"200","color":"blue darken-2"}},[_c('v-card-title',{staticClass:"text-h5 white--text"},[_vm._v(" "+_vm._s(_vm.newCustomerCount)+" ")]),_c('v-card-subtitle',{staticClass:"text2_color--text text--lighten-3"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.newcustomer'))+" ")])],1),_c('v-card',{staticClass:"mx-2",attrs:{"width":"200","color":"blue darken-2"}},[_c('v-card-title',{staticClass:"text-h5 white--text"},[_vm._v(" "+_vm._s(_vm.todayInvoiceCount)+" ")]),_c('v-card-subtitle',{staticClass:"text2_color--text text--lighten-3"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.todayinvoices'))+" ")])],1)],1),_c('div',{staticClass:"mt-20"},[_c('GChart',{attrs:{"type":"LineChart","data":_vm.chartvalue,"options":{
                         title: _vm.$t('dashboard.sevendayssales'),
                         legend: { position: 'bottom' },
                         width: '99%',
                         height: 400,
                         chartArea: {
                              left: 80,
                              top: 80,
                              right: 10,
                              width: '100%',
                              height: '70%',
                         },
                    }}})],1),_c('v-container',{staticClass:"px-7 d-flex flex-row"})],1):_c('div',[_c('span',[_vm._v("Sorry, you don't have permission to access!")])])])}
var staticRenderFns = []

export { render, staticRenderFns }