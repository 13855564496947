import CustomerCategoryService from './api/CustomerCategoryService';
//import CustomerService from './api/CustomerService';
import ProductService from './api/ProductService';
import UserService from './api/UserService';
import ServiceService from './api/ServiceService';
import PackageService from './api/PackageService';
import SettingService from './api/SettingService';
import ResourceService from './api/ResourceService';
import config from '@/config/config';

var orderBy = require('lodash/orderBy');

/* eslint-disable */

class BrowserSession {
     constructor() {
          this.cache_objs = {
               config: {
                    sessionkey: 'config',
                    fetchapi: this.fetchConfig,
                    expiry: false,
                    defaultsetting: [
                         {
                              disabled_page: ['TWwvR', 'ZCIMR'],
                              enable_registration: true,
                              enable_mobileapp: true,
                         },
                    ],
               },
               customer_category: {
                    sessionkey: 'customer_category',
                    fetchapi: this.fetchCustomerCategory,
                    expiry: false,
                    defaultsetting: [],
               },
               user: {
                    sessionkey: 'user',
                    fetchapi: this.fetchUser,
                    expiry: false,
                    defaultsetting: [],
               },
               setting: {
                    sessionkey: 'setting',
                    fetchapi: this.fetchSetting,
                    expiry: false,
                    defaultsetting: [
                         {
                              key: 'invoice',
                              value: {
                                   prefix: 'INV',
                                   tax: 0,
                                   decimal_place: 0,
                                   rounding: 'roundoff',
                                   terms: '',
                                   discount_autoupdate: true,
                                   duedays: 0,
                                   payment_type: [
                                        { name: 'cash', status: 'active' },
                                        { name: 'cheque', status: 'active' },
                                        { name: 'visa', status: 'active' },
                                        { name: 'master', status: 'active' },
                                        { name: 'AE', status: 'active' },
                                        { name: 'ATM', status: 'active' },
                                        {
                                             name: 'banktransfer',
                                             status: 'active',
                                        },
                                        { name: 'octopus', status: 'active' },
                                        { name: 'alipay', status: 'active' },
                                        {
                                             name: 'wechatpay',
                                             status: 'active',
                                        },
                                        { name: 'unionpay', status: 'active' },
                                        { name: 'payme', status: 'active' },
                                        { name: 'applepay', status: 'active' },
                                        { name: 'fps', status: 'active' },
                                        { name: 'paypal', status: 'active' },
                                        { name: 'other', status: 'active' },
                                   ],
                              },
                         },
                         {
                              key: 'company',
                              value: {
                                   logo: 'noimage.jpg',
                                   name: 'NONE',
                                   phone: '',
                                   fax: '',
                                   website: '',
                                   address: '',
                                   facebook: '',
                                   instagram: '',
                                   linkedin: '',
                                   business_hour: [
                                        {
                                             weekday: 1,
                                             start: '9:00',
                                             end: '18:00',
                                             active: true,
                                        },
                                        {
                                             weekday: 2,
                                             start: '9:00',
                                             end: '18:00',
                                             active: true,
                                        },
                                        {
                                             weekday: 3,
                                             start: '9:00',
                                             end: '18:00',
                                             active: true,
                                        },
                                        {
                                             weekday: 4,
                                             start: '9:00',
                                             end: '18:00',
                                             active: true,
                                        },
                                        {
                                             weekday: 5,
                                             start: '9:00',
                                             end: '18:00',
                                             active: true,
                                        },
                                        {
                                             weekday: 6,
                                             start: '9:00',
                                             end: '18:00',
                                             active: true,
                                        },
                                        {
                                             weekday: 7,
                                             start: '9:00',
                                             end: '18:00',
                                             active: true,
                                        },
                                   ],
                                   twitter: '',
                              },
                         },
                         {
                              key: 'reward',
                              value: {
                                   enable_pt_sys: false,
                                   register_pt: 0,
                                   purchase_pt: 0,
                                   birthday_pt: 0,
                                   dollar_equal_point: 0,
                                   point_by: 'auto_extend',
                                   enable_lv_sys: false,
                                   register_mark: 0,
                                   purchase_mark: 0,
                                   birthday_mark: 0,
                                   order_mark: 1,
                                   mark_by: 'member_eday',
                                   updown: 'byrank',
                              },
                         },
                         {
                              key: 'config',
                              value: {
                                   disabled_page: [],
                                   enable_registration: true,
                                   enable_mobileapp: true,
                                   membership_duration_days: 365,
                                   auth_pwd: null,
                              },
                         },
                    ],
               },
               product: {
                    sessionkey: 'product',
                    fetchapi: this.fetchProduct,
                    expiry: false,
                    defaultsetting: [],
               },
               service: {
                    sessionkey: 'service',
                    fetchapi: this.fetchService,
                    expiry: false,
                    defaultsetting: [],
               },
               package: {
                    sessionkey: 'package',
                    fetchapi: this.fetchPackage,
                    expiry: false,
                    defaultsetting: [],
               },
               resource: {
                    sessionkey: 'resource',
                    fetchapi: this.fetchResource,
                    expiry: false,
                    defaultsetting: [],
               },
          };
     }

     getAllSession() {
          Object.entries(this.cache_objs).forEach(([key, value]) => {
               value.fetchapi(value.sessionkey, null);
          });
     }

     cleanAllSession() {
          Object.entries(this.cache_objs).forEach(([key, value]) => {
               sessionStorage.removeItem(value.sessionkey);
          });
     }

     setSessionExpiry(key) {
          this.cache_objs[key].expiry = true;
     }

     getSession(key, cb = null) {
          if (!key && !cb) return null;
          if (!key) cb(null);
          if (this.cache_objs[key] == undefined) return null;

          let return_result = sessionStorage.getItem(key);

          //console.log(key);
          //console.log(this.cache_objs[key]);

          if (!return_result || this.cache_objs[key].expiry) {
               //console.log('fetch data from api...');
               this.cache_objs[key].fetchapi(
                    this.cache_objs[key].sessionkey,
                    cb
               );
          } else {
               //console.log('read from session: ' + key);
               if (cb) cb(JSON.parse(return_result));
               else return JSON.parse(return_result);
          }
     }

     /*getSessionFromCache(key){
          let return_result = sessionStorage.getItem(key);
          return JSON.parse(return_result);
     }*/

     renewSession(key, cb = null) {
          if (!key && !cb) return null;
          if (!key) cb(null);
          this.cache_objs[key].fetchapi(this.cache_objs[key].sessionkey, cb);
     }

     fetchCustomerCategory(sessionkey, cb) {
          CustomerCategoryService.getAll()
               .then((response) => {
                    if (response.data.status == 'success') {
                         //save to session
                         sessionStorage.setItem(
                              sessionkey,
                              JSON.stringify(response.data.data)
                         );
                    }

                    if (cb) cb(response.data.data);
                    else return response.data.data;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb)
                         cb(this.cache_objs.customer_category.defaultsetting);
                    else
                         return this.cache_objs.customer_category
                              .defaultsetting;
               });
     }

     fetchUser(sessionkey, cb) {
          UserService.getAll()
               .then((response) => {
                    if (response.data.status == 'success') {
                         let list = response.data.data;
                         let sortlist = orderBy(list, ['name'], ['asc']);
                         //save to session
                         sessionStorage.setItem(
                              sessionkey,
                              //JSON.stringify(response.data.data)
                              JSON.stringify(sortlist)
                         );
                    }
                    if (cb) cb(response.data.data);
                    else return response.data.data;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb) cb(this.cache_objs.user.defaultsetting);
                    else return this.cache_objs.user.defaultsetting;
               });
     }

     fetchSetting(sessionkey, cb) {
          SettingService.getAll()
               .then((response) => {
                    if (response.data.status == 'success') {
                         //console.log(response.data);
                         //save to session
                         sessionStorage.setItem(
                              sessionkey,
                              JSON.stringify(response.data.data)
                         );
                    }
                    if (cb) cb(response.data.data);
                    else return response.data.data;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb) cb(this.cache_objs.setting.defaultsetting);
                    else return this.cache_objs.setting.defaultsetting;
               });
     }

     fetchProduct(sessionkey, cb) {
          ProductService.getAll()
               .then((response) => {
                    if (response.data.status == 'success') {
                         //save to session
                         sessionStorage.setItem(
                              sessionkey,
                              JSON.stringify(response.data.data)
                         );
                    }
                    if (cb) cb(response.data.data);
                    else return response.data.data;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb) cb(this.cache_objs.product.defaultsetting);
                    else return this.cache_objs.product.defaultsetting;
               });
     }

     fetchService(sessionkey, cb) {
          ServiceService.getAll()
               .then((response) => {
                    //save to session
                    sessionStorage.setItem(
                         sessionkey,
                         JSON.stringify(response.data.data)
                    );
                    if (cb) cb(response.data.data);
                    else return response.data.data;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb) cb(this.cache_objs.service.defaultsetting);
                    else return this.cache_objs.service.defaultsetting;
               });
     }

     fetchPackage(sessionkey, cb) {
          PackageService.getAll()
               .then((response) => {
                    if (response.data.status == 'success') {
                         //save to session
                         sessionStorage.setItem(
                              sessionkey,
                              JSON.stringify(response.data.data)
                         );
                    }
                    if (cb) cb(response.data.data);
                    else return response.data.data;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb) cb(this.cache_objs.package.defaultsetting);
                    else return this.cache_objs.package.defaultsetting;
               });
     }

     fetchResource(sessionkey, cb) {
          ResourceService.getAll()
               .then((response) => {
                    if (response.data.status == 'success') {
                         //save to session
                         sessionStorage.setItem(
                              sessionkey,
                              JSON.stringify(response.data.data)
                         );
                    }
                    if (cb) cb(response.data.data);
                    else return response.data.data;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb) cb(this.cache_objs.resource.defaultsetting);
                    else return this.cache_objs.resource.defaultsetting;
               });
     }

     fetchConfig(sessionkey, cb) {
          SettingService.getSettingByKey('config')
               .then((response) => {
                    //console.log(response.data);
                    let x = response.data.data.value;
                    //console.log(config.disablepage);
                    //console.log(x);
                    x.disabled_page = x.disabled_page.concat(
                         config.disabled_page
                    );
                    //save to session
                    sessionStorage.setItem(
                         sessionkey,
                         //JSON.stringify(response.data.data.value)
                         JSON.stringify(x)
                    );

                    //if (cb) cb(response.data.data.value);
                    //else return response.data.data.value;
                    if (cb) cb(x);
                    else return x;
               })
               .catch((error) => {
                    console.log(error);
                    if (cb) cb(this.cache_objs.config.defaultsetting);
                    else return this.cache_objs.config.defaultsetting;
               });
     }
}

export default new BrowserSession();
