<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span v-if="_id == null" class="heading_color--text">{{
                         $t('global.newrecord')
                    }}</span>
                    <span v-else class="heading_color--text">{{
                         $t('global.modify')
                    }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="pa-10 post_bg"
               >
                    <v-row>
                         <v-col>
                              <v-textarea
                                   v-model="content"
                                   :label="$t('global.remark')"
                                   hide-details
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-textarea>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col>
                              <v-combobox
                                   v-model="tag"
                                   :items="tagsList"
                                   multiple
                                   hide-details
                                   small-chips
                                   :label="$t('customer.tag')"
                                   outlined
                                   dense
                                   hide-selected
                                   deletable-chips
                                   background-color="#ffffff"
                              ></v-combobox>
                         </v-col>
                    </v-row>
               </v-form>
          </div>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="_id == null"
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="editRecord"
                    >
                         {{ $t('global.modify') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>

<script>
     /* eslint-disable */
     import CustomerProfileService from '@/utils/api/CustomerProfileService';

     export default {
          props: { customerobj: Object, _id: String },
          data: () => ({
               valid: true,
               tag: [],
               tagsList: [],
               content: '',
          }),
          methods: {
               apiGetRecord() {
                    CustomerProfileService.getCustomerSpecifyLog(this._id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.tag = response.data.data.tag;
                                   this.content = response.data.data.content;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    let cobject = {
                         customerid: this.customerobj._id,
                         tag: this.tag,
                         content: this.content,
                         type: 'note',
                         action: 'create',
                    };
                    //console.log(cobject);

                    CustomerProfileService.addCustomerLog(cobject)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +
                                             ': ' +
                                             this.customerobj.name
                                   );
                              else
                                   this.$emit(
                                        'onFail',
                                        this.$t('global.failPrefix') +
                                             ': ' +
                                             response.data.message
                                   );
                         })
                         .catch((e) => {
                              this.$emit(
                                   'onFail',
                                   this.$t('global.failPrefix') + ': ' + e.message
                              );
                         });
               },
               editRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    let cobject = {
                         _id: this._id,
                         tag: this.tag,
                         content: this.content,
                         action: 'update',
                    };
                    //console.log(cobject);

                    CustomerProfileService.updateCustomerLog(cobject)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +
                                             ': ' +
                                             this.customerobj.name
                                   );
                              else
                                   this.$emit(
                                        'onFail',
                                        this.$t('global.failPrefix') +
                                             ': ' +
                                             response.data.message
                                   );
                         })
                         .catch((e) => {
                              this.$emit(
                                   'onFail',
                                   this.$t('global.failPrefix') + ': ' + e.message
                              );
                         });
               },
               cancel() {
                    this.$emit('onClickCancel');
               },
          },
          created() {
               if (this.customerobj != null) {
                    this.tagsList = this.customerobj.tag;
               }
               if (this._id != null) {
                    //console.log('get log id:' + this._id);
                    this.apiGetRecord();
               }
          },
     };
</script>
