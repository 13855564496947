import http from '../HttpConn';
var isObject = require('lodash/isObject');
class PackageService {
     getAll(page, limit) {
          return http.get('/package', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page, limit) {
          return http.get('/package/allactive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/package/count');
     }
     getPackage(id) {
          return http.get('/package/' + id);
     }
     /* eslint-disable */
     updatePackage(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/package/update/' + ccobj._id, ccobj);
     }
     addPackage(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/package/add', ccobj);
     }
     deletePackage(id) {
          return http.delete('/package/delete/' + id);
     }
     /* eslint-enable */
}
export default new PackageService();
