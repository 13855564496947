<template>
     <div v-if="havePermit()" class="background pa-5">
          <div
               class="white rounded elevation-3 mb-5 pa-5 d-flex justify-space-between a4width"
               id="new_invoice"
               v-if="isEdit != 'true'"
          >
               <span class="heading_color--text my-auto">{{
                    $t('global.newrecord')
               }}</span>

               <div class="d-flex flex-row">
                    <v-btn
                         icon
                         :color="importCustomer?'primary':'secondary'"
                         @click="importCustomer = !importCustomer"
                    >
                         <v-icon v-if="importCustomer"
                              >mdi-account-check</v-icon
                         >
                         <v-icon v-else>mdi-account-cancel</v-icon>
                    </v-btn>
                    <v-select
                         v-model="selectTemplate"
                         :items="apiTemplateList"
                         :label="$t('invoice.importtemplate')"
                         item-text="name"
                         item-value="_id"
                         dense
                         hide-details
                         class="px-2"
                         style="width: 200px"
                         return-object
                    ></v-select>
                    <v-btn
                         icon
                         small
                         color="primary"
                         class="px-2"
                         @click="importDataFromTemplate"
                    >
                         <v-icon>mdi-arrow-right-bold-circle</v-icon>
                    </v-btn>
               </div>
               <div>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         :loading="isLoading"
                         @click="saveInvoice"
                    >
                         <span class="white--text">{{
                              $t('global.save')
                         }}</span>
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         class="body-2 font-weight-bold"
                         text
                         @click="cancelInput"
                    >
                         <span>{{ $t('global.cancel') }}</span>
                    </v-btn>
               </div>
          </div>

          <div class="white rounded elevation-3 mb-5 pa-7 a4screen">
               <div class="d-flex justify-space-between pa-7">
                    <div>
                         <v-img
                              height="200px"
                              width="200px"
                              :src="url + '/' + apiCompanySetting.logo"
                         ></v-img>
                    </div>
                    <div class="d-flex flex-column" id="companybox">
                         <span class="text-h4 mb-3 text-right">Invoice</span>
                         <span class="text-h6 text-right">{{
                              apiCompanySetting.name
                         }}</span>
                         <span
                              class="text-right text2_color--text font-weight-light"
                              >{{ apiCompanySetting.address }}</span
                         >
                         <span
                              v-if="apiCompanySetting.phone"
                              class="text-right text2_color--text mt-2 font-weight-light"
                              >T:{{ apiCompanySetting.phone }}</span
                         >
                         <span
                              v-if="apiCompanySetting.fax"
                              class="text-right text2_color--text font-weight-light"
                              >F:{{ apiCompanySetting.fax }}</span
                         >
                    </div>
               </div>
               <v-divider></v-divider>
               <div class="d-flex justify-space-between pa-7 my-auto">
                    <v-card class="pa-5" width="300" min-height="280">
                         <div>
                              <div class="d-flex flex-row">
                                   <v-autocomplete
                                        id="customercombo"
                                        v-model="selectcustomer"
                                        :loading="customerlistloading"
                                        :items="customerfilterlist"
                                        :search-input.sync="searchCustomer"
                                        item-text="name_and_phone"
                                        item-value="_id"
                                        :label="$t('invoice.searchbynameandphone')"
                                        @keydown="keyinCustomer"
                                        dense
                                        outlined
                                        return-object
                                        @change="(obj) => onChangeCustomer(obj)"
                                        background-color="#ffffff"
                                   ></v-autocomplete>
                                   <v-btn icon @click="openQr()" class="ml-3">
                                        <v-icon color="primary"
                                             >mdi-qrcode-scan</v-icon
                                        >
                                   </v-btn>
                              </div>

                              <v-divider></v-divider>

                              <div
                                   v-if="selectcustomer && selectcustomer.name"
                                   class="mt-3 mb-2 d-flex flex-row justify-space-between"
                              >
                                   <span class="text-body-1">
                                        {{ selectcustomer.name }}</span
                                   >
                                   <!--<v-btn
                                        icon
                                        small
                                        @click="displayCustomerDialog()"
                                        class="ml-3"
                                   >
                                        <v-icon small color="primary"
                                             >mdi-account-details</v-icon
                                        >
                                   </v-btn>-->
                              </div>
                              <div
                                   v-if="
                                        selectcustomer && selectcustomer.address
                                   "
                                   class="mb-5"
                              >
                                   <span class="text-body-2 text2_color--text">
                                        {{ selectcustomer.address }}</span
                                   >
                              </div>
                              <div
                                   v-if="selectcustomer && selectcustomer.email"
                              >
                                   <v-icon small>mdi-email</v-icon
                                   ><span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.email }}</span
                                   >
                              </div>
                              <div
                                   v-if="selectcustomer && selectcustomer.phone"
                              >
                                   <v-icon small>mdi-phone</v-icon
                                   ><span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.phone }}</span
                                   >
                              </div>
                              <div
                                   v-if="
                                        selectcustomer && selectcustomer.member
                                   "
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <div>
                                        <v-icon small
                                             >mdi-wallet-membership</v-icon
                                        ><span
                                             class="text-body-2 text2_color--text font-weight-bold"
                                        >
                                             {{
                                                  selectcustomer.member[0]
                                                       .customer_category_name
                                             }}</span
                                        >
                                   </div>
                                   <v-btn icon small @click="update_discount"
                                        ><v-icon small color="primary"
                                             >mdi-cached</v-icon
                                        ></v-btn
                                   >
                              </div>
                              <div
                                   v-if="
                                        selectcustomer &&
                                        selectcustomer.re_point
                                   "
                              >
                                   <v-icon small>mdi-wallet-giftcard</v-icon
                                   ><span
                                        class="text-body-2 text2_color--text font-weight-bold"
                                   >
                                        {{ selectcustomer.re_point }}</span
                                   >
                              </div>
                         </div>
                    </v-card>

                    <div class="itembox">
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{
                                        $t('invoice.salesrepresentative')
                                   }}
                                   :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <v-select
                                        v-model="selectSales"
                                        :items="apiUserList"
                                        item-text="name"
                                        item-value="_id"
                                        hide-details
                                        dense
                                        return-object
                                        background-color="#ffffff"
                                   ></v-select>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoiceno') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <v-text-field
                                        v-model="invoiceid"
                                        single-line
                                        dense
                                        hide-details
                                        :prefix="apiInvoiceSetting.prefix"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.pono') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <v-text-field
                                        v-model="po"
                                        single-line
                                        dense
                                        hide-details
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                         </v-row>
                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoicedate') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <v-menu
                                        v-model="invoicedatepicker"
                                        :close-on-content-click="false"
                                        :nudge-right="-90"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                   >
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-text-field
                                                  v-model="invoicedate"
                                                  hide-details
                                                  dense
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </template>
                                        <v-date-picker
                                             v-model="invoicedate"
                                             @input="invoicedatepicker = false"
                                        ></v-date-picker>
                                   </v-menu>
                              </v-col>
                         </v-row>

                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.paybefore') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <v-menu
                                        v-model="duedatepicker"
                                        :close-on-content-click="false"
                                        :nudge-right="-90"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                   >
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-text-field
                                                  v-model="duedate"
                                                  hide-details
                                                  dense
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  background-color="#ffffff"
                                             ></v-text-field>
                                        </template>
                                        <v-date-picker
                                             v-model="duedate"
                                             @input="duedatepicker = false"
                                        ></v-date-picker>
                                   </v-menu>
                              </v-col>
                         </v-row>

                         <v-row dense align="end">
                              <v-col
                                   cols="6"
                                   class="text-right text2_color--text text--darken-3"
                                   >{{ $t('invoice.invoicecurrency') }} :</v-col
                              >
                              <v-col cols="6" class="text2_color--text pl-2">
                                   <v-select
                                        v-model="selectcurrency"
                                        :items="currencyList"
                                        hide-details
                                        dense
                                        background-color="#ffffff"
                                   ></v-select>
                              </v-col>
                         </v-row>
                    </div>
               </div>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="5">
                                   <span class="mx-3"> &nbsp; </span>
                                   <span class="caption heading_color--text">{{
                                        $t('components.product')
                                   }}</span>
                              </v-col>
                              <v-col cols="2" class="text-right">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.unitprice')
                                   }}</span>
                              </v-col>
                              <v-col cols="2" class="text-right">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.qty')
                                   }}</span>
                              </v-col>
                              <v-col cols="3" class="text-right">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.amount')
                                   }}</span>
                                   <span class="px-3">&nbsp;</span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList pl-1">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(item, index) in invoiceLineItems"
                         :key="index"
                    >
                         <v-col cols="5" class="d-flex flex-column">
                              <div class="d-flex flex-row">
                                   <v-avatar
                                        size="15"
                                        color="heading_bg"
                                        class="mx-2 my-auto"
                                   >
                                        <span
                                             class="caption heading_color--text"
                                             >{{ index + 1 }}</span
                                        >
                                   </v-avatar>

                                   <v-select
                                        v-if="item.type == 1"
                                        v-model="item.obj"
                                        :items="apiItemList"
                                        item-text="name"
                                        item-value="_id"
                                        hide-details
                                        dense
                                        filled
                                        solo
                                        @change="
                                             (obj) =>
                                                  onChangeLineItem(obj, index)
                                        "
                                        return-object
                                        class="max-width: 280px"
                                        background-color="#ffffff"
                                   ></v-select>

                                   <v-select
                                        v-else-if="item.type == 2"
                                        v-model="item.obj"
                                        :items="apiServiceList"
                                        item-text="name"
                                        item-value="_id"
                                        hide-details
                                        dense
                                        filled
                                        solo
                                        @change="
                                             (obj) =>
                                                  onChangeLineItem(obj, index)
                                        "
                                        return-object
                                        class="max-width: 280px"
                                        background-color="#ffffff"
                                   ></v-select>

                                   <v-select
                                        v-else-if="item.type == 3"
                                        v-model="item.obj"
                                        :items="apiPackageList"
                                        item-text="name"
                                        item-value="_id"
                                        hide-details
                                        dense
                                        filled
                                        solo
                                        @change="
                                             (obj) =>
                                                  onChangeLineItem(obj, index)
                                        "
                                        return-object
                                        class="max-width: 280px"
                                        background-color="#ffffff"
                                   ></v-select>
                                   <v-select
                                        v-else-if="item.type == 4"
                                        v-model="item.obj"
                                        :items="apiStockList"
                                        :item-value="
                                             (item) => item._id + '/' + item.id
                                        "
                                        :item-text="
                                             (item) => {
                                                  return (
                                                       (item.p_status ==
                                                       'normal'
                                                            ? '* '
                                                            : item.p_status ==
                                                              'expiry'
                                                            ? '[E] '
                                                            : '[X] ') +
                                                       item.product_name +
                                                       ' [' +
                                                       item.name +
                                                       '] ID:' +
                                                       item._id.slice(-4)
                                                  );
                                             }
                                        "
                                        hide-details
                                        dense
                                        filled
                                        solo
                                        @change="
                                             (obj) =>
                                                  onChangeLineItem(obj, index)
                                        "
                                        return-object
                                        style="max-width: 280px !important"
                                        background-color="#ffffff"
                                   ></v-select>
                                   <v-text-field
                                        v-else
                                        v-model="item.name"
                                        hide-details
                                        dense
                                        filled
                                        class="max-width: 280px"
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </div>
                              <div
                                   class="ml-10 mt-1 text2_color--text text-caption"
                              >
                                   <pre style="line-height: 0.8rem">{{
                                        item.description
                                   }}</pre>
                                   <div v-if="item.type == 4">
                                        {{ getRemainCredit(item, index) }}
                                   </div>
                                   <div v-if="item.commissionto" class="pt-1">
                                        <v-chip x-small>
                                             {{ item.commissionto.name }}</v-chip
                                        >
                                   </div>
                              </div>
                              <div
                                   class="ml-10 mt-3 d-flex flex-row"
                                   v-if="
                                        item.preUseService1 &&
                                        item.preUseQty1 > 0
                                   "
                              >
                                   <span class="my-auto pr-2">↪</span>
                                   <v-text-field
                                        readonly
                                        outlined
                                        dense
                                        hide-details
                                        background-color="brown lighten-5"
                                        :value="item.preUseService1.name"
                                   ></v-text-field>
                              </div>
                              <div
                                   class="ml-10 mt-3 d-flex flex-row"
                                   v-if="
                                        item.preUseService2 &&
                                        item.preUseQty2 > 0
                                   "
                              >
                                   <span class="my-auto pr-2">↪</span>
                                   <v-text-field
                                        readonly
                                        outlined
                                        dense
                                        hide-details
                                        background-color="brown lighten-5"
                                        :value="item.preUseService2.name"
                                   ></v-text-field>
                              </div>
                         </v-col>

                         <v-col
                              cols="2"
                              class="d-flex flex-column justify-space-between"
                         >
                              <div>
                                   <v-text-field
                                        v-if="!item.type || item.type != 4"
                                        v-model="item.price"
                                        :rules="[numberRules]"
                                        hide-details
                                        dense
                                        single-line
                                        type="number"
                                        class="right-input"
                                        @change="
                                             (value) => onChangePriceQty(index)
                                        "
                                        prefix="$"
                                        background-color="#ffffff"
                                   >
                                   </v-text-field>
                                   <v-text-field
                                        v-else
                                        v-model="item.price"
                                        solo
                                        hide-details
                                        dense
                                        single-line
                                        outlined
                                        flat
                                        class="right-input mr-2"
                                        prefix="$"
                                        readonly
                                        background-color="grey lighten-4"
                                   >
                                   </v-text-field>
                                   <div
                                        v-if="!item.discountable"
                                        class="caption text2_color--text"
                                   >
                                        {{ $t('invoice.nondiscountable') }}
                                   </div>
                              </div>
                              <div>
                                   <div
                                        class="mt-3 d-flex flex-row"
                                        v-if="
                                             item.preUseService1 &&
                                             item.preUseQty1 > 0
                                        "
                                   >
                                        <v-text-field
                                             readonly
                                             background-color="brown lighten-5"
                                             outlined
                                             class="right-input mr-2"
                                             dense
                                             hide-details
                                             value="0"
                                             prefix="$"
                                        ></v-text-field>
                                   </div>
                                   <div
                                        class="mt-3 d-flex flex-row"
                                        v-if="
                                             item.preUseService2 &&
                                             item.preUseQty2 > 0
                                        "
                                   >
                                        <v-text-field
                                             readonly
                                             background-color="brown lighten-5"
                                             outlined
                                             class="right-input mr-2"
                                             dense
                                             hide-details
                                             value="0"
                                             prefix="$"
                                        ></v-text-field>
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div v-if="item.type != 3">
                                   <v-text-field
                                        v-model="item.qty"
                                        :rules="[numberRules]"
                                        hide-details="auto"
                                        :error-messages="item.is_qty_error"
                                        dense
                                        single-line
                                        type="number"
                                        class="right-input"
                                        :suffix="item.unit"
                                        @input="
                                             (value) => onChangePriceQty(index)
                                        "
                                        background-color="#ffffff"
                                   >
                                   </v-text-field>
                                   <div
                                        class="mt-1 text2_color--text text-caption float-right"
                                   >
                                        <pre style="line-height: 0.8rem">{{
                                             item.limit_desc
                                        }}</pre>
                                   </div>
                              </div>
                              <div
                                   v-else
                                   class="d-flex flex-column justify-space-between"
                                   style="height: 100%"
                              >
                                   <div>
                                        <v-text-field
                                             v-model="item.qty"
                                             :rules="[numberRules]"
                                             hide-details="auto"
                                             dense
                                             single-line
                                             type="number"
                                             class="right-input"
                                             @input="
                                                  (value) =>
                                                       onChangePriceQty(index)
                                             "
                                             :suffix="item.unit"
                                             background-color="#ffffff"
                                        >
                                        </v-text-field>
                                        <div
                                             class="mr-5 text2_color--text text-caption text-right"
                                        >
                                             <span style="line-height: 0.8rem">
                                                  {{ $t('invoice.include') }} :
                                                  {{
                                                       item.obj.credit * item.qty
                                                  }}</span
                                             >
                                        </div>
                                   </div>
                                   <div>
                                        <div
                                             class="mt-3 d-flex flex-row"
                                             v-if="
                                                  item.preUseService1 &&
                                                  item.preUseQty1 > 0
                                             "
                                        >
                                             <v-text-field
                                                  readonly
                                                  class="right-input mr-2"
                                                  background-color="brown lighten-5"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :value="item.preUseQty1"
                                                  suffix=""
                                             ></v-text-field>
                                        </div>
                                        <div
                                             class="mt-3 d-flex flex-row"
                                             v-if="
                                                  item.preUseService2 &&
                                                  item.preUseQty2 > 0
                                             "
                                        >
                                             <v-text-field
                                                  readonly
                                                  class="right-input mr-2"
                                                  background-color="brown lighten-5"
                                                  outlined
                                                  dense
                                                  hide-details
                                                  :value="item.preUseQty2"
                                                  suffix=""
                                             ></v-text-field>
                                        </div>
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="3" class="d-flex align-start">
                              <v-text-field
                                   v-model="item.total"
                                   solo
                                   hide-details
                                   dense
                                   single-line
                                   outlined
                                   flat
                                   class="right-input mr-2"
                                   prefix="$"
                                   readonly
                                   background-color="grey lighten-4"
                              >
                              </v-text-field>
                              <div class="d-flex flex-column">
                                   <v-btn
                                        icon
                                        small
                                        @click="deleteItem(index)"
                                        class="mt-2"
                                        color="primary"
                                   >
                                        <v-icon>mdi-delete-circle</v-icon>
                                   </v-btn>
                                   <v-btn
                                        icon
                                        small
                                        @click="editItem(index)"
                                        class="mt-2"
                                        color="primary"
                                   >
                                        <v-icon>mdi-pencil-circle</v-icon>
                                   </v-btn>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold mt-5 mr-5"
                         @click="addNewLineItem(1)"
                         :disabled="!isProductAvailiable"
                    >
                         <v-icon size="18">mdi-plus</v-icon>
                         <span class="white--text">{{
                              $t('components.product')
                         }}</span>
                    </v-btn>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold mt-5 mr-5"
                         @click="addNewLineItem(2)"
                         :disabled="!isServiceAvailiable"
                    >
                         <v-icon size="18">mdi-plus</v-icon>
                         <span class="white--text">{{
                              $t('components.service')
                         }}</span>
                    </v-btn>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold mt-5 mr-5"
                         @click="addNewLineItem(3)"
                         :disabled="!isPackageAvailiable"
                    >
                         <v-icon size="18">mdi-plus</v-icon>
                         <span class="white--text">{{
                              $t('components.package')
                         }}</span>
                    </v-btn>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold mt-5 mr-5"
                         @click="addNewLineItem(4)"
                         :disabled="!isStockAvailiable"
                    >
                         <v-icon size="18">mdi-plus</v-icon>
                         <span class="white--text">{{
                              $t('product.stock')
                         }}</span>
                    </v-btn>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold mt-5 mr-5"
                         @click="addNewTmpLineItem"
                    >
                         <v-icon size="18">mdi-plus</v-icon>
                         <span class="white--text">{{
                              $t('invoice.other')
                         }}</span>
                    </v-btn>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold mt-5"
                         @click="openBarcode()"
                    >
                         <v-icon size="18">mdi-barcode-scan</v-icon>
                    </v-btn>
               </div>

               <div class="pa-7">
                    <v-row class="justify-space-between">
                         <v-col cols="7">
                              <div>
                                   <span class="h6 font-weight-bold mb-1 py-1">
                                        {{ $t('global.remark') }}
                                   </span>
                                   <v-btn
                                        icon
                                        small
                                        color="primary"
                                        @click="displayCustomerDialog()"
                                   >
                                        <v-icon
                                             >mdi-arrow-right-bold-circle</v-icon
                                        >
                                   </v-btn>
                              </div>

                              <v-textarea
                                   v-model="notes"
                                   outlined
                                   auto-grow
                                   no-resize
                                   background-color="#ffffff"
                              ></v-textarea>
                         </v-col>
                         <v-col cols="5">
                              <v-row dense align="end" class="pr-2">
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3 my-auto"
                                        >{{ $t('invoice.subtotal') }}</v-col
                                   >
                                   <v-col cols="6" class="text2_color--text">
                                        <v-text-field
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             readonly
                                             background-color="grey lighten-4"
                                             outlined
                                             prefix="$"
                                             type="text"
                                             :value="subtotal"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row
                                   dense
                                   align="end"
                                   class="pr-2"
                                   v-if="apiCompanySetting.enable_tax"
                              >
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3"
                                        >{{ $t('invoice.tax') }}
                                        <!--{{
                                             isOverrideTax ? '' : trate + '%'
                                        }}--></v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             v-model="tax"
                                             :rules="[numberRules]"
                                             type="number"
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             prefix="$"
                                             @change="onChangeTax"
                                             @focus="onFocusTax"
                                             @blur="onBlurTax"
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row dense align="end" class="pr-2">
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3"
                                        >{{ $t('invoice.discount') }}
                                        <!--{{
                                             isOverrideDiscount
                                                  ? ''
                                                  : memberdiscountrate + '%'
                                        }}--></v-col
                                   >
                                   <v-col
                                        cols="6"
                                        class="text2_color--text pl-2"
                                   >
                                        <v-text-field
                                             v-model="discount"
                                             :rules="[numberRules]"
                                             type="number"
                                             single-line
                                             dense
                                             hide-details
                                             class="right-input"
                                             prefix="$"
                                             @change="onChangeDiscount"
                                             @focus="onFocusDiscount"
                                             @blur="onBlurDiscount"
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>

                              <v-row
                                   dense
                                   align="end"
                                   class="pa-2 heading_bg mt-3 rounded"
                              >
                                   <v-col
                                        cols="6"
                                        class="text-right text2_color--text text--darken-3 font-weight-bold my-auto"
                                        >{{ $t('invoice.amount') }}</v-col
                                   >
                                   <v-col cols="6" class="text2_color--text">
                                        <v-text-field
                                             single-line
                                             dense
                                             hide-details
                                             outlined
                                             class="right-input font-weight-bold"
                                             readonly
                                             background-color="grey lighten-4"
                                             prefix="$"
                                             type="text"
                                             :value="amount"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>
                         </v-col>
                    </v-row>
                    <v-row>
                         <div class="h6 font-weight-bold px-3 py-1">
                              {{ $t('invoice.tandc') }}
                         </div>
                         <v-btn icon small color="primary" @click="openTAS">
                              <v-icon>mdi-pencil-circle</v-icon>
                         </v-btn>
                    </v-row>
                    <v-row>
                         <pre
                              class="px-3"
                              style="white-space: pre-wrap; font-family:'Roboto', sans-serif;"
                              >{{
                              this.tas
                              }}</pre
                         >
                    </v-row>
               </div>
          </div>

          <div
               class="white rounded elevation-3 mb-5 pa-5 d-flex justify-space-between a4width"
          >
               <div class="d-flex flex-column">
                    <span class="pb-3">{{ $t('invoice.invoicetag') }}</span>
                    <v-combobox
                         v-model="tags"
                         :items="tagsList"
                         multiple
                         hide-details
                         small-chips
                         :label="$t('customer.tag')"
                         outlined
                         dense
                         hide-selected
                         deletable-chips
                         background-color="#ffffff"
                    ></v-combobox>
               </div>
               <div class="my-auto">
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         :loading="isLoading"
                         @click="saveInvoice"
                         v-if="isEdit != 'true'"
                    >
                         <span class="white--text">{{
                              $t('global.save')
                         }}</span>
                    </v-btn>
                    <!--<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         class="body-2 font-weight-bold"
                         text
                         @click="cancelInput"
                    >
                         <span>取消</span>
                    </v-btn>-->
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold ml-2"
                         :loading="isLoading"
                         @click="updateInvoice"
                         v-if="isEdit == 'true'"
                    >
                         <span class="white--text">{{
                              $t('global.update')
                         }}</span>
                    </v-btn>
                    <v-btn
                         color="grey"
                         class="body-2 font-weight-bold ml-2"
                         text
                         @click="closeDialog"
                         v-if="isEdit == 'true'"
                    >
                         <span>{{ $t('global.close') }}</span>
                    </v-btn>
               </div>
          </div>

          <div
               class="white rounded elevation-3 mb-5 pa-2 a4width"
               v-if="isEdit != 'true'"
          >
               <div class="d-flex flex-row justify-space-between">
                    <div
                         class="ma-2 white rounded elevation-3"
                         style="width: 50%"
                    >
                         <div
                              class="d-flex flex-row justify-space-between mt-3 mb-4"
                         >
                              <div class="my-auto ml-3">
                                   <span>{{ $t('invoice.redeemmark') }}</span>
                                   <v-tooltip right>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  class="mx-1 my-auto"
                                                  small
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  >mdi-help-circle</v-icon
                                             >
                                        </template>
                                        <div
                                             v-html="$t('invoice.redeemtips')"
                                        ></div>
                                   </v-tooltip>
                              </div>
                         </div>
                         <v-divider class="mb-3"></v-divider>
                         <v-container v-if="apiRewardSetting.enable_pt_sys">
                              <v-row>
                                   <v-col cols="7" class="py-0">
                                        <div class="caption text2_color--text">
                                             {{ $t('invoice.redeem') }}:
                                             <span class="text1_color--text">{{
                                                  used_pt
                                             }}</span>
                                        </div>
                                        <v-slider
                                             v-model="used_pt"
                                             :max="max_pt"
                                             min="0"
                                             :step="
                                                  apiRewardSetting.dollar_equal_point ||
                                                  9999999
                                             "
                                             :disabled="
                                                  !apiRewardSetting.enable_pt_sys
                                             "
                                             @change="onChangeRewardPt"
                                        ></v-slider>
                                   </v-col>
                                   <v-col cols="5" class="py-0">
                                        <v-text-field
                                             v-model="exchange_pt_amount"
                                             prefix="$"
                                             :label="$t('invoice.redeemamount')"
                                             readonly
                                             type="number"
                                             outlined
                                             dense
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                              </v-row>
                         </v-container>
                    </div>

                    <div
                         class="ma-2 white rounded elevation-3"
                         style="width: 50%"
                    >
                         <div class="d-flex flex-row justify-space-between">
                              <div class="my-auto ml-3">
                                   <span>{{
                                        $t('invoice.invoicepayment')
                                   }}</span>
                                   <v-tooltip right>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  class="mx-1 my-auto"
                                                  small
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  >mdi-help-circle</v-icon
                                             >
                                        </template>
                                        <div
                                             v-html="
                                                  $t(
                                                       'invoice.invoicepaymenttips'
                                                  )
                                             "
                                        ></div>
                                   </v-tooltip>
                              </div>
                              <v-btn class="ma-2" @click="copyAmount">{{
                                   $t('invoice.allamount')
                              }}</v-btn>
                         </div>
                         <v-divider class="mb-3"></v-divider>
                         <v-container fluid>
                              <v-row>
                                   <v-col cols="7" class="py-0">
                                        <v-select
                                             v-model="payment_type"
                                             :items="ApiPaymentListName"
                                             item-text="displayname"
                                             :label="
                                                  $t('invoice.paymentmethod')
                                             "
                                             persistent-hint
                                             :hint="$t('global.required')"
                                             outlined
                                             dense
                                             return-object
                                             background-color="#ffffff"
                                        ></v-select>
                                   </v-col>
                                   <v-col cols="5" class="py-0">
                                        <v-text-field
                                             v-model="pay_amount"
                                             prefix="$"
                                             :label="$t('invoice.amount')"
                                             :hint="$t('global.required')"
                                             :rules="amountRules"
                                             type="number"
                                             persistent-hint
                                             outlined
                                             dense
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                                   <v-col cols="2" class="py-0"> </v-col>
                              </v-row>
                         </v-container>
                    </div>

                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold my-auto"
                         :loading="isLoading"
                         @click="saveInvoiceAndPay"
                         v-if="isEdit != 'true'"
                    >
                         <span class="white--text">{{
                              $t('invoice.payandsave')
                         }}</span>
                    </v-btn>
               </div>
          </div>

          <v-dialog
               v-if="itemEditDialog"
               v-model="itemEditDialog"
               persistent
               max-width="550px"
          >
               <div class="white rounded elevation-3 post_bg">
                    <v-container fluid>
                         <v-row class="pa-5">
                              <span class="heading_color--text my-auto">{{
                                   $t('global.modify')
                              }}</span>
                         </v-row>
                    </v-container>

                    <v-divider class="mb-5"></v-divider>

                    <v-container fluid>
                         <v-row class="mx-5">
                              <v-col class="py-0">
                                   <v-text-field
                                        v-model="invoiceLineItemDetail.name"
                                        :counter="50"
                                        :rules="nameRules"
                                        :label="$t('components.service')"
                                        :hint="$t('global.required')"
                                        persistent-hint
                                        outlined
                                        dense
                                        disabled
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                         </v-row>
                         <v-row class="mx-5">
                              <v-col class="py-0">
                                   <v-textarea
                                        v-model="
                                             invoiceLineItemDetail.description
                                        "
                                        :label="$t('product.detail')"
                                        outlined
                                        dense
                                        rows="3"
                                        background-color="#ffffff"
                                   ></v-textarea>
                              </v-col>
                         </v-row>
                         <v-row class="mx-5">
                              <v-col class="py-0">
                                   <v-text-field
                                        v-model="invoiceLineItemDetail.price"
                                        prefix="$"
                                        :label="$t('invoice.unitprice')"
                                        :hint="$t('global.required')"
                                        :rules="[numberRules]"
                                        type="number"
                                        persistent-hint
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="py-0">
                                   <v-text-field
                                        v-model="invoiceLineItemDetail.cost"
                                        prefix="$"
                                        :label="$t('product.cost')"
                                        :hint="$t('global.required')"
                                        :rules="[numberRules]"
                                        type="number"
                                        persistent-hint
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                         </v-row>
                         <v-row class="mx-5">
                              <v-col class="py-0">
                                   <v-text-field
                                        v-model="invoiceLineItemDetail.qty"
                                        :label="$t('invoice.qty')"
                                        :hint="$t('global.required')"
                                        :rules="[numberRules]"
                                        type="number"
                                        persistent-hint
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="py-0 d-flex flex-row">
                                   <v-select
                                        v-model="
                                             invoiceLineItemDetail.commissionto
                                        "
                                        :items="apiUserList"
                                        outlined
                                        item-text="name"
                                        item-value="_id"
                                        :label="$t('invoice.commissionto')"
                                        :hint="$t('invoice.commissiontotips')"
                                        persistent-hint
                                        single-line
                                        dense
                                        return-object
                                        background-color="#ffffff"
                                   ></v-select>
                                   <v-btn
                                        icon
                                        @click="invoiceLineItemDetail.commissionto=null"
                                        color="primary"
                                   >
                                        <v-icon>mdi-eraser</v-icon>
                                   </v-btn>
                              </v-col>
                         </v-row>
                         <v-row class="mx-5">
                              <v-col class="py-0">
                                   <v-text-field
                                        v-model="
                                             invoiceLineItemDetail.commission
                                        "
                                        :label="$t('invoice.commission')"
                                        prefix="$"
                                        :hint="$t('invoice.commissiontips')"
                                        :rules="[numberRules]"
                                        type="number"
                                        persistent-hint
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="py-0">
                                   <v-checkbox
                                        v-model="
                                             invoiceLineItemDetail.isPersist
                                        "
                                        dense
                                        hide-details
                                        :disabled="
                                             !invoiceLineItemDetail.obj
                                                  .persist ||
                                             invoiceLineItemDetail.type == 4
                                        "
                                   >
                                        <template v-slot:label>
                                             <span class="text-caption">{{
                                                  $t('invoice.stockit')
                                             }}</span>
                                        </template>
                                   </v-checkbox>
                                   <v-checkbox
                                        v-model="
                                             invoiceLineItemDetail.discountable
                                        "
                                        dense
                                        hide-details
                                   >
                                        <template v-slot:label>
                                             <span class="text-caption">{{
                                                  $t('invoice.isDiscountable')
                                             }}</span>
                                        </template>
                                   </v-checkbox>
                              </v-col>
                         </v-row>
                    </v-container>
                    <v-divider class="mt-5"></v-divider>

                    <v-container v-if="invoiceLineItemDetail.type == 3">
                         <div class="pl-5 py-3">
                              {{ $t('invoice.setserviceconsume') }}
                         </div>
                         <div class="px-5 d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="ma-2"
                              >
                                   <span class="caption heading_color--text">{{
                                        1
                                   }}</span>
                              </v-avatar>
                              <v-select
                                   v-model="preUseService1"
                                   :items="
                                        invoiceLineItemDetail.obj.link_services
                                   "
                                   hide-details
                                   :label="$t('invoice.selectconsumeservice')"
                                   outlined
                                   dense
                                   item-text="name"
                                   return-object
                                   hide-selected
                                   style="width: 80%"
                                   background-color="#ffffff"
                              ></v-select>
                              <v-text-field
                                   v-model="preUseQty1"
                                   :label="$t('invoice.qty')"
                                   type="number"
                                   persistent-hint
                                   outlined
                                   dense
                                   class="pl-2"
                                   background-color="#ffffff"
                              ></v-text-field>
                              <v-btn
                                   icon
                                   small
                                   @click="cleanPackageSelect(1)"
                                   class="mt-2 ml-1"
                                   color="primary"
                              >
                                   <v-icon>mdi-eraser</v-icon>
                              </v-btn>
                         </div>
                         <div class="px-5 d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="ma-2"
                              >
                                   <span class="caption heading_color--text">{{
                                        2
                                   }}</span>
                              </v-avatar>
                              <v-select
                                   v-model="preUseService2"
                                   :items="
                                        invoiceLineItemDetail.obj.link_services
                                   "
                                   hide-details
                                   :label="$t('invoice.selectconsumeservice')"
                                   outlined
                                   dense
                                   item-text="name"
                                   return-object
                                   hide-selected
                                   style="width: 80%"
                                   background-color="#ffffff"
                              ></v-select>
                              <v-text-field
                                   v-model="preUseQty2"
                                   :label="$t('invoice.qty')"
                                   type="number"
                                   outlined
                                   dense
                                   class="pl-2"
                                   background-color="#ffffff"
                              ></v-text-field>
                              <v-btn
                                   icon
                                   small
                                   @click="cleanPackageSelect(2)"
                                   class="mt-2 ml-1"
                                   color="primary"
                              >
                                   <v-icon>mdi-eraser</v-icon>
                              </v-btn>
                         </div>
                    </v-container>
                    <v-divider
                         v-if="invoiceLineItemDetail.type == 3"
                    ></v-divider>

                    <v-container fluid>
                         <v-row class="pa-5">
                              <v-spacer></v-spacer>
                              <v-btn
                                   color="primary"
                                   class="text-body-2 font-weight-bold"
                                   @click="confirmEdit"
                              >
                                   {{ $t('global.confirm') }}
                              </v-btn>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <v-btn
                                   color="grey"
                                   text
                                   class="text-body-2 font-weight-bold"
                                   @click="cancelEdit"
                              >
                                   {{ $t('global.cancel') }}
                              </v-btn>
                         </v-row>
                    </v-container>
               </div>
          </v-dialog>

          <v-dialog
               v-if="qrDialog"
               v-model="qrDialog"
               persistent
               max-width="550px"
          >
               <div class="white rounded elevation-3 text-center">
                    <!--<qrcode-stream @decode="onDecodeQR"></qrcode-stream>-->
                    <StreamBarcodeReader
                         @decode="onDecodeQR"
                    ></StreamBarcodeReader>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="qrDialog = false"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </div>
          </v-dialog>

          <v-dialog
               v-if="barcodeDialog"
               v-model="barcodeDialog"
               persistent
               max-width="550px"
          >
               <div class="white rounded elevation-3 text-center">
                    <StreamBarcodeReader
                         @decode="onDecodeB"
                         @loaded="onLoaded"
                    ></StreamBarcodeReader>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="barcodeDialog = false"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </div>
          </v-dialog>

          <v-dialog
               v-if="showCustomerDetail"
               v-model="showCustomerDetail"
               contained
               persistent
               class="align-center justify-center"
               width="500"
               origin="top left"
          >
               <div class="white rounded elevation-3 py-3 text-center post_bg">
                    <div class="heading_color--text pb-3">
                         {{ $t('global.remark') }}
                    </div>

                    <v-divider class="py-5"></v-divider>

                    <v-card
                         outlined
                         v-for="(log, index) in logobjs"
                         :key="index"
                         class="mx-5 mb-3"
                         @click="selectRemark(index)"
                    >
                         <v-card-text class="text-left">
                              <div
                                   class="text1_color--text font-weight-light pl-3"
                              >
                                   <pre>{{ log.content }}</pre>
                              </div>
                              <div>
                                   <span
                                        v-for="(tag, index) in log.tag"
                                        :key="index"
                                   >
                                        <v-chip class="ma-1" label small>
                                             {{ tag }}
                                        </v-chip>
                                   </span>
                              </div>
                              <div
                                   class="text-caption text2_color--text pt-2 pl-3"
                              >
                                   @ {{ formatDateTime(log.last_update) }}
                              </div>
                              <!--<div class="text-caption text2_color--text pl-3">
                                   建立於@
                                   {{ formatDateTime(log.created_date) }}
                              </div>-->
                         </v-card-text>
                    </v-card>

                    <v-container fluid>
                         <v-row class="pa-5">
                              <v-spacer></v-spacer>
                              <v-btn
                                   color="primary"
                                   class="text-body-2 font-weight-bold"
                                   @click="importRemark()"
                              >
                                   {{ $t('global.confirm') }}
                              </v-btn>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <v-btn
                                   color="grey"
                                   text
                                   class="text-body-2 font-weight-bold"
                                   @click="showCustomerDetail = false"
                              >
                                   {{ $t('global.cancel') }}
                              </v-btn>
                         </v-row>
                    </v-container>
               </div>
          </v-dialog>

          <v-dialog
               v-if="showTandCBox"
               v-model="showTandCBox"
               contained
               persistent
               class="align-center justify-center"
               width="500"
               origin="top left"
          >
               <div class="white rounded elevation-3 py-3 text-center post_bg">
                    <div class="heading_color--text pb-3">
                         {{ $t('invoice.modifytandc') }}
                    </div>

                    <v-divider class="py-5"></v-divider>
                    <v-textarea
                         class="pa-3"
                         outlined
                         :label="$t('invoice.tandc')"
                         auto-grow
                         v-model="modifyTAS"
                         no-resize
                         background-color="#ffffff"
                    ></v-textarea>

                    <v-container fluid>
                         <v-row class="pa-5">
                              <v-spacer></v-spacer>
                              <v-btn
                                   color="primary"
                                   class="text-body-2 font-weight-bold"
                                   @click="confirmEditTandC"
                              >
                                   {{ $t('global.confirm') }}
                              </v-btn>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <v-btn
                                   color="grey"
                                   text
                                   class="text-body-2 font-weight-bold"
                                   @click="showTandCBox = false"
                              >
                                   {{ $t('global.cancel') }}
                              </v-btn>
                         </v-row>
                    </v-container>
               </div>
          </v-dialog>

          <ConfirmDialog ref="confirm" />

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               {{ $t('global.successPrefix') }}:
               <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               {{ $t('global.failPrefix') }}:
               <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
     <div v-else>
          <span>Sorry, you dont have permission to access!</span>
     </div>
</template>

<script>
     /*eslint-disable*/
     import CustomerService from '@/utils/api/CustomerService';
     import PaymentService from '@/utils/api/PaymentService';
     import InvoiceService from '@/utils/api/InvoiceService';
     import LogService from '@/utils/api/LogService';
     import TemplateService from '@/utils/api/TemplateService';
     import CustomerProfileService from '@/utils/api/CustomerProfileService';
     import BrowserSession from '@/utils/BrowserSession';

     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import Permission from '@/utils/Permission';
     import StreamBarcodeReader from 'vue-barcode-reader/src/components/StreamBarcodeReader.vue';

     var isEmpty = require('lodash/isEmpty');
     var find = require('lodash/find');
     var sumBy = require('lodash/sumBy');
     var isNumber = require('lodash/isNumber');
     var clone = require('lodash/clone');
     //var orderBy = require('lodash/orderBy');

     const moment = require('moment');
     const success_sound = require('@/assets/beep.mp3');
     const error_sound = require('@/assets/error_beep.mp3');

     const date = new Date();
     const unformatter = (value) => {
          if (!value && value != 0) return null;
          if (typeof value === 'string' || value instanceof String) {
               value = value.replace(',', '');
               value = Number(value);
               return value;
          } else {
               let str_value = value.toString();
               str_value = str_value.replace(',', '');
               str_value = Number(str_value);
               return str_value;
          }
     };
     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     const zeroPad = (num, places) => String(num).padStart(places, '0');

     const roundto = (num, dp = 0, type = 'roundoff') => {
          if (type == 'roundoff') return +(Math.round(num + 'e+' + dp) + 'e-' + dp);

          if (type == 'roundup') return +(Math.ceil(num + 'e+' + dp) + 'e-' + dp);

          if (type == 'rounddown') return +(Math.floor(num + 'e+' + dp) + 'e-' + dp);
     };

     export default {
          props: {
               _id: String, //pass in invoice id for duplicate
               cid: String, //pass in customer id
               isEdit: String, //pass in for new invoice or edit invoice
          },
          data: function () {
               return {
                    pagekey: ['Vbgoh'],
                    windowHeight: window.innerHeight,
                    windowWidth: window.innerWidth,

                    itemEditDialog: false,
                    itemEditidx: null,

                    showCustomerDetail: false,
                    showTandCBox: false,

                    selectTemplate: null,

                    invoiceid: '',
                    selectSales: null,
                    po: '',
                    notes: '',
                    tas: '',

                    apiCustomerList: [],
                    selectcustomer: {},
                    logobjs: [],
                    selectedlogidx: null,

                    numberRules: (v) => {
                         if (!v && v != 0) return 'This field is required!';
                         if (v.length == 0) return 'This field is required!';
                         if (!isNaN(v)) return true;
                         return 'Please input a number';
                    },

                    currencyList: ['HKD', 'USD'],
                    selectcurrency: 'HKD',

                    invoicedate: date.toISOString().substr(0, 10),
                    invoicedatepicker: false,
                    /*duedate: new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000)
                         .toISOString()
                         .substr(0, 10),*/
                    duedate: date.toISOString().substr(0, 10),
                    duedatepicker: false,
                    duedays: 0,

                    apiCustomerCategoryList: [],
                    apiItemList: [],
                    apiServiceList: [],
                    apiPackageList: [],
                    apiUserList: [],
                    apiTemplateList: [],
                    apiStockList: [],
                    apiInvoiceSetting: {},
                    apiCompanySetting: {},
                    apiRewardSetting: {},

                    isProductAvailiable: true,
                    isServiceAvailiable: true,
                    isPackageAvailiable: true,
                    isStockAvailiable: false,

                    invoiceLineItems: [],
                    invoiceLineItemDetail: {},
                    preUseService1: null,
                    preUseQty1: null,
                    preUseService2: null,
                    preUseQty2: null,

                    subtotal: 0.0,
                    non_discountable_subtotal: 0.0,

                    isOverrideTax: false,
                    tax: 0.0,
                    tmptax: 0.0,

                    isOverrideDiscount: false,
                    discount: 0.0,
                    memberdiscountrate: 0,
                    tmpdiscount: 0.0,

                    max_pt: 0,
                    used_pt: 0,
                    exchange_pt_amount: 0.0,

                    amount: 0.0,

                    amountpaid: 0.0,
                    balance: 0.0,

                    ApiPaymentListName: [],
                    payment_type: '',

                    expense_type: this.$t('invoice.cash'),

                    pay_amount: 0.0,
                    expense_amount: 0.0,

                    tags: [],
                    tagsList: [],

                    nameRules: [
                         (v) => !!v || 'This field is required',
                         (v) =>
                              (v && v.length <= 50) ||
                              'This field must be less than 50 characters',
                    ],
                    amountRules: [
                         (v) =>
                              !Number.isNaN(Number(v)) ||
                              'Amount should be a number',
                    ],

                    url: process.env.VUE_APP_APISERVER|| (window.location.origin+'/api'),

                    is_fail: false,
                    is_success: false,
                    error_message: '',
                    success_message: '',

                    isLoading: false,
                    barcodeDialog: false,
                    qrDialog: false,

                    modifyTAS: null,

                    importCustomer: false,

                    ssound: null,
                    esound: null,

                    customerfilterby: 'byphone',
                    customerlistloading: false,
                    customerfilterlist: [],
                    searchCustomer: null,
               };
          },
          methods: {
               apiGetCustomerCategoryList() {
                    BrowserSession.getSession('customer_category', (dd) => {
                         this.apiCustomerCategoryList = dd;
                         //console.log(this.apiCustomerCategoryList);
                    });
               },
               apiGetProductList() {
                    //get from session, disable in-active product
                    BrowserSession.getSession('product', (data) => {
                         this.apiItemList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               apiGetServiceList() {
                    BrowserSession.getSession('service', (data) => {
                         //console.log(data);
                         this.apiServiceList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               apiGetPackageList() {
                    BrowserSession.getSession('package', (data) => {
                         this.apiPackageList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               apiGetUserList() {
                    BrowserSession.getSession('user', (data) => {
                         this.apiUserList = data.filter((d) => {
                              return d.status == 'active';
                         });
                         //this.apiUserList = orderBy(this.apiUserList, ['name'], ['asc'])
                    });
               },
               apiGetLastInvoiceId() {
                    InvoiceService.getLastInvoiceId()
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.invoiceid = zeroPad(
                                        parseInt(response.data.data.lastid) + 1,
                                        7
                                   );
                              } else {
                                   this.error_message = response.data.message;
                                   this.is_fail = true;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               apiGetSettingPrefix() {
                    BrowserSession.getSession('setting', (data) => {
                         this.apiInvoiceSetting = data.find((d) => {
                              return d.key == 'invoice';
                         }).value;
                         this.tas = this.apiInvoiceSetting.terms;
                         this.duedays = this.apiInvoiceSetting.duedays?parseInt(this.apiInvoiceSetting.duedays):0;
                         this.duedate = new Date(date.getTime() + this.duedays * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),

                         this.apiCompanySetting = data.find((d) => {
                              return d.key == 'company';
                         }).value;

                         this.apiRewardSetting = data.find((d) => {
                              return d.key == 'reward';
                         }).value;

                         let ptype = data.find((d) => {
                              return d.key == 'invoice';
                         }).value['payment_type'];
                         this.ApiPaymentListName = ptype.filter(e=>e.enable);

                         this.ApiPaymentListName = this.ApiPaymentListName.map(e=>{
                              return {...e, ...{displayname: this.$t('invoice.' + e.name)}}
                         });

                         if(this.ApiPaymentListName.length > 0){
                              this.payment_type = this.ApiPaymentListName[0];
                              this.expense_type = this.ApiPaymentListName[0];
                         }

                         //console.log(this.apiInvoiceSetting);
                         //console.log(this.apiCompanySetting);
                         //console.log(this.apiRewardSetting);
                    });
               },
               apiGetTemplateList() {
                    TemplateService.getAll(1, 9999)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.apiTemplateList = response.data.data;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetCustomerByid(id){
                    CustomerService.getCustomer(id).then(response=>{
                         if(response.data.status=='success'){
                              this.selectcustomer = response.data.data;
                              this.selectcustomer.name_and_phone = response.data.data.name + ' ('+ response.data.data.phone+')'
                              this.customerfilterlist = [this.selectcustomer];
                              //if (this.selectcustomer)
                              //     this.tagsList = this.selectcustomer.tag;
                              this.onChangeCustomer(this.selectcustomer);
                         }
                         //set default sales
                         /*this.selectSales =find(this.apiUserList, {
                              _id: this.selectcustomer.default_sales,
                         });
                         //load user stock list
                         this.apiGetCustomerStock(id);*/
                    }).catch(e=>{
                         console.log(e);
                         this.error_message = e.message;
                         this.is_fail = true;
                    })

               },
               apiGetCustomerNote() {
                    CustomerProfileService.getCustomerLog(
                         'note',
                         this.selectcustomer._id
                    )
                         .then((response) => {
                              if (response.data.status == 'success')
                                   //console.log(response.data);
                                   this.logobjs = response.data.data;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               //get customer profile/stock
               async apiGetCustomerProfile() {
                    if (!this.selectcustomer._id) return;

                    this.isStockAvailiable = false;
                    this.apiStockList = [];

                    let stock =
                         await CustomerProfileService.getAvailiableCustomerProfile(
                              this.selectcustomer._id
                         );
                    //console.log(inactive_stock);

                    if (stock.data.status != 'success') {
                         return;
                    }

                    if (stock.data.data.length > 0) {
                         //disable add stock item
                         this.isStockAvailiable = true;
                    }

                    stock.data.data.map((profile) => {
                         //console.log(profile);
                         profile.link_services.map((l) => {
                              //console.log(l);
                              this.apiStockList.push({
                                   ...l,
                                   ...{
                                        _id: profile._id,
                                        customerid: profile.customerid,
                                        productid: profile.productid,
                                        product_name: profile.product_name,
                                        packid: profile.packid,
                                        type: 4,
                                        remain_credit: profile.remain_credit,
                                        p_status: 'normal',
                                   },
                              });
                         });
                    });

                    stock = await CustomerProfileService.getExpiryCustomerProfile(
                         this.selectcustomer._id
                    );
                    if (stock.data.status != 'success') {
                         return;
                    }
                    if (stock.data.data.length > 0) {
                         //disable add stock item
                         this.isStockAvailiable = true;
                    }
                    //get all expiry only
                    stock.data.data.map((profile) => {
                         profile.link_services.map((l) => {
                              this.apiStockList.push({
                                   ...l,
                                   ...{
                                        _id: profile._id,
                                        customerid: profile.customerid,
                                        productid: profile.productid,
                                        product_name: profile.product_name,
                                        packid: profile.packid,
                                        type: 4,
                                        remain_credit: profile.remain_credit,
                                        p_status: 'expiry',
                                   },
                              });
                         });
                    });
               },
               //get customer profile/stock by passing cid
               async apiGetCustomerStock(cid){
                    this.isStockAvailiable = false;
                    this.apiStockList = [];

                    //get this user normal stock
                    let stock = await CustomerProfileService.getAvailiableCustomerProfile(cid);
                    if (stock.data.status == 'success') {
                         if (stock.data.data.length > 0) {
                              this.isStockAvailiable = true;
                         }
                         stock.data.data.map((profile) => {
                              //console.log(profile);
                              profile.link_services.map((l) => {
                                   //console.log(l);
                                   this.apiStockList.push({
                                        ...l,
                                        ...{
                                             _id: profile._id,
                                                       customerid:
                                                            profile.customerid,
                                                       productid: profile.productid,
                                                       product_name:
                                                            profile.product_name,
                                                       packid: profile.packid,
                                                       type: 4,
                                                       remain_credit:
                                                            profile.remain_credit,
                                                       p_status: 'normal',
                                        },
                                   });
                              });
                         });
                    }
                    stock = await CustomerProfileService.getExpiryCustomerProfile(cid);
                    if (stock.data.status == 'success') {
                                   //get this user expiry stock
                                   stock.data.data.map((profile) => {
                                        profile.link_services.map((l) => {
                                             this.apiStockList.push({
                                                  ...l,
                                                  ...{
                                                       _id: profile._id,
                                                       customerid:
                                                            profile.customerid,
                                                       productid: profile.productid,
                                                       product_name:
                                                            profile.product_name,
                                                       packid: profile.packid,
                                                       type: 4,
                                                       remain_credit:
                                                            profile.remain_credit,
                                                       p_status: 'expiry',
                                                  },
                                             });
                                        });
                                   });
                    }
                              //get all inactive
                    stock = await CustomerProfileService.getInactiveCustomerProfile( cid );
                    if (stock.data.status == 'success') {
                                   stock.data.data.map((profile) => {
                                        profile.link_services.map((l) => {
                                             this.apiStockList.push({
                                                  ...l,
                                                  ...{
                                                       _id: profile._id,
                                                       customerid:
                                                            profile.customerid,
                                                       productid: profile.productid,
                                                       product_name:
                                                            profile.product_name,
                                                       packid: profile.packid,
                                                       type: 4,
                                                       remain_credit:
                                                            profile.remain_credit,
                                                       p_status: 'inactive',
                                                  },
                                             });
                                        });
                                   });
                    }
               },
               loadInvoice() {
                    //console.log('loadInvoice():', this._id);
                    InvoiceService.getInvoice(this._id)
                         .then(async (response) => {
                              //console.log(response.data);
                              if (response.data.status == 'success') {
                                   this.apiInvoiceObj = response.data.data;
                                   this.invoiceobj = response.data.data;
                              } else {
                                   this.error_message = response.data.message;
                                   this.is_fail = true;
                                   return;
                              }

                              this.invoiceid = zeroPad(this.invoiceobj.invoiceid, 7);
                              this.invoiceno = this.invoiceobj.invoiceno;
                              this.invoicedate = this.invoiceobj.invoice_date;
                              this.tags = this.invoiceobj.tag;
                              this.duedate = this.invoiceobj.due_date;
                              this.selectSales =find(this.apiUserList, {
                                   _id: this.invoiceobj.salesid,
                              });
                              this.po = this.invoiceobj.po;
                              this.selectcurrency = this.invoiceobj.currency;
                              this.subtotal = numformat.format(
                                   this.invoiceobj.subtotal
                              );
                              this.discount = this.invoiceobj.discount;
                              this.tax = this.invoiceobj.tax;
                              this.amount = numformat.format(this.invoiceobj.amount);
                              this.notes = this.invoiceobj.notes;
                              this.tas = this.invoiceobj.tas;

                              //console.log(this.apiCustomerList);
                              /*this.selectcustomer =find(this.apiCustomerList, {
                                   _id: this.invoiceobj.billto.customerid,
                              });
                              //console.log(this.selectcustomer);*/
                              //API call to get customer detail
                              let result = await CustomerService.getCustomer(this.invoiceobj.billto.customerid);

                              if(result.data.status=='success'){
                                   this.selectcustomer = result.data.data;
                                   this.selectcustomer.name_and_phone = result.data.data.name + ' ('+ result.data.data.phone+')'
                                   this.customerfilterlist = [this.selectcustomer];

                                   //console.log(this.selectcustomer);

                                   if (this.selectcustomer)
                                        this.tagsList = this.selectcustomer.tag;
                              }else{
                                   throw new Error('Get Customer Error');
                              }

                              this.isStockAvailiable = false;
                              this.apiStockList = [];

                              //get this user normal stock
                              this.apiGetCustomerStock(this.invoiceobj.billto.customerid);

                              this.memberdiscountrate =
                                   this.selectcustomer.member[0].discount_percentage;

                              this.invoiceobj.product.map((item) => {
                                   let commission_person = find(this.apiUserList, {
                                        _id: item.commissionpersonid,
                                   }) || { name: null };
                                   let constr_item = {
                                        ...item,
                                        ...{
                                             obj: { _id: item.productid },
                                             total: numformat.format(item.total),
                                             isPersist: item.persist,
                                             commissionto: {
                                                  _id: item.commissionpersonid,
                                                  name: commission_person.name,
                                             },
                                        },
                                   };
                                   if (constr_item.type == 0) {
                                        //this item is preuse service
                                        constr_item.preserviceid = item.preserviceid;
                                        //link this item to master service item
                                        let masteritem =find(
                                             this.invoiceLineItems,
                                             { obj: { _id: item.preserviceid } }
                                        );
                                        //console.log(masteritem);
                                        //match apistockList with this preservice item
                                        let subitem = find(this.apiStockList, {
                                             id: item.productid,
                                        });
                                        //console.log(this.apiStockList);
                                        //console.log(subitem);

                                        //move this preservice item to this master
                                        if (!masteritem.preUseService1) {
                                             masteritem.preUseService1 = subitem;
                                             masteritem.preUseQty1 = constr_item.qty;
                                        } else {
                                             masteritem.preUseService2 = subitem;
                                             masteritem.preUseQty2 = constr_item.qty;
                                        }
                                   } else if (constr_item.type == 1) {
                                        constr_item.obj = find(this.apiItemList, {
                                             _id: item.productid,
                                        });
                                   } else if (constr_item.type == 2) {
                                        constr_item.obj = find(
                                             this.apiServiceList,
                                             {
                                                  _id: item.productid,
                                             }
                                        );
                                   } else if (constr_item.type == 3) {
                                        constr_item.obj = find(
                                             this.apiPackageList,
                                             {
                                                  _id: item.productid,
                                             }
                                        );
                                   } else if (constr_item.type == 4) {
                                        //consume stock
                                        let stock = find(this.apiStockList, {
                                             _id: item.customerprofileid,
                                             id: item.productid,
                                        });

                                        constr_item.obj = {
                                             ...stock,
                                             ...{ name: item.name },
                                        };
                                   }

                                   if (constr_item.type != 0)
                                        this.invoiceLineItems.push(constr_item);
                              });

                              this.selectcustomer = {
                                   ...this.selectcustomer,
                                   ...this.invoiceobj.billto,
                              };

                              //set auto discount
                              if (!this.apiInvoiceSetting.discount_autoupdate) {
                                   //console.log(this.discount_autoupdate);
                                   this.isOverrideDiscount = true;
                                   this.runCal();
                                   return;
                              }

                              if (this.selectcustomer.member) {
                                   this.memberdiscountrate =
                                        this.selectcustomer.member[0].discount_percentage;
                                   this.isOverrideDiscount = false;
                                   this.runCal();
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               addNewLineItem(type) {
                    //type 0 : preUse Service type
                    //type 1 : normal product
                    //type 2 : service
                    //type 3 : package
                    //type 4 : stock
                    //type 99: other
                    if (type == 4) {
                         if (isEmpty(this.selectcustomer)) return;

                         this.invoiceLineItems.push({
                              obj: {},
                              name: '',
                              description: '',
                              cost: 0.0,
                              price: 0.0,
                              credit: '',
                              unit: '',
                              qty: 0.0,
                              total: '0.00',
                              isPersist: false,
                              type: type,
                              commissionto: null,
                              commission: 0,
                              customerprofileid: null,
                              discountable: false,
                         });
                    } else
                         this.invoiceLineItems.push({
                              obj: {},
                              name: '',
                              description: '',
                              cost: 0.0,
                              price: 0.0,
                              credit: type == 3 ? 0 : '',
                              unit: '',
                              qty: 0.0,
                              total: '0.00',
                              isPersist: type == 3 ? true : false,
                              type: type,
                              commissionto: null,
                              commission: 0,
                              discountable: true,
                         });
                    if (type == 1 && this.invoiceLineItems.length > 0)
                         this.onChangeLineItem(
                              this.apiItemList[0],
                              this.invoiceLineItems.length - 1
                         );
                    else if (type == 2 && this.invoiceLineItems.length > 0)
                         this.onChangeLineItem(
                              this.apiServiceList[0],
                              this.invoiceLineItems.length - 1
                         );
                    else if (type == 3 && this.invoiceLineItems.length > 0)
                         this.onChangeLineItem(
                              this.apiPackageList[0],
                              this.invoiceLineItems.length - 1
                         );
                    else if (type == 4 && this.invoiceLineItems.length > 0) {
                         this.onChangeLineItem(
                              this.apiStockList[0],
                              this.invoiceLineItems.length - 1
                         );
                    }
               },
               addNewTmpLineItem() {
                    this.invoiceLineItems.push({
                         obj: { _id: null },
                         name: '',
                         description: '',
                         cost: 0.0,
                         price: 0.0,
                         unit: '',
                         qty: 0.0,
                         total: '0.00',
                         isPersist: false,
                         type: 99,
                         commissionto: null,
                         commission: 0,
                    });
               },
               onChangeCustomer(object) {
                    //console.log('onchangcustomer',object);
                    if(!object)
                         return;
                    //get user profile
                    this.apiGetCustomerProfile();

                    //remove previous customer profile
                    this.tags = [];
                    this.tagsList = _.isEmpty(object.tag)?[]:object.tag;
                    //console.log(object.tag);

                    //set default sales
                    this.selectSales = find(this.apiUserList, {
                         _id: object.default_sales,
                    });

                    //set max redeem pt
                    if (this.apiRewardSetting.enable_pt_sys) {
                         let max_dollor_could_convert = Math.floor(
                              object.re_point /
                                   this.apiRewardSetting.dollar_equal_point
                         );
                         //set max pt could use
                         this.max_pt =
                              max_dollor_could_convert *
                              this.apiRewardSetting.dollar_equal_point;
                    }

                    //remove previous customer profile
                    this.removeStockTypeLineItem();

                    //auto update discount
                    if (!this.apiInvoiceSetting.discount_autoupdate) {
                         //console.log(this.discount_autoupdate);
                         this.isOverrideDiscount = true;
                         this.runCal();
                         return;
                    }

                    //got membership
                    if (object.member) {
                         this.memberdiscountrate =
                              object.member[0].discount_percentage;
                         this.isOverrideDiscount = false;
                         this.runCal();
                    }
               },
               getRemainCredit(item, idx) {
                    //console.log(item);
                    //console.log(idx);

                    if (isEmpty(item.obj)) {
                         return '';
                    }

                    let _id = item.obj._id;
                    //loop all line item before it
                    //check same package consume some credit pt
                    let credit_spend = 0;
                    for (let i = 0; i < idx; i++) {
                         if (
                              !isEmpty(this.invoiceLineItems[i].obj) &&
                              this.invoiceLineItems[i].obj._id == _id
                         )
                              credit_spend +=
                                   this.invoiceLineItems[i].obj.credit *
                                   this.invoiceLineItems[i].qty;
                    }

                    return (
                         this.$t('invoice.remainstockcredit') +
                         ': ' +
                         (item.obj.remain_credit - credit_spend)
                    );
               },
               onChangeLineItem(object, idx) {
                    //reset it qty
                    this.invoiceLineItems[idx].qty = 1;

                    let newItem = {};

                    //stock consume
                    if (object.type == 4) {
                         //check if this stock service was already in line items
                         //console.log(object);
                         //console.log(this.invoiceLineItems);
                         let x = this.invoiceLineItems.filter((item) => {
                              return (
                                   item.obj.id == object.id &&
                                   item.obj._id == object._id
                              );
                         });

                         //console.log(x);
                         let total_consumed = sumBy(x, function (o) {
                              return parseFloat(o.qty);
                         });
                         //total_consumed += 1;
                         total_consumed += object.consumed;

                         //console.log('total consumed:', total_consumed);

                         //check if reach max_usage?
                         let is_qty_error = null;
                         if (
                              object.max_usage > 0 &&
                              //object.max_usage <= object.consumed
                              object.max_usage < total_consumed
                         ) {
                              is_qty_error = [this.$t('invoice.exceedlimit')];
                         }

                         object.total_consumed = total_consumed;

                         newItem = {
                              ...newItem,
                              ...{
                                   obj: object,
                                   name:
                                        object.product_name +
                                        ' [' +
                                        object.name +
                                        ']',
                                   description: object.packid,
                                   limit_desc:
                                        this.$t('invoice.maxusagelimit') +
                                        ': ' +
                                        (object.max_usage
                                             ? object.max_usage
                                             : this.$t('invoice.unlimit')) +
                                        '\n' +
                                        this.$t('invoice.consumed') +
                                        ': ' +
                                        object.consumed,
                                   cost: 0,
                                   price: 0,
                                   credit: object.credit,
                                   unit: '',
                                   qty: 1,
                                   total: '0.00',
                                   type: 4,
                                   isPersist: false,
                                   commissionto: null,
                                   commission: 0,
                                   is_qty_error: is_qty_error,
                                   customerprofileid: object._id,
                              },
                         };
                         this.invoiceLineItems.splice(idx, 1, newItem);

                         return;
                    }

                    //calculate commission cost
                    let s_comm = 0.0;
                    //if(this.invoiceLineItems[idx].type == 2)
                    s_comm = this.calCommission(
                         object.price,
                         object.commissiontype,
                         object.commission
                    );

                    newItem = {
                         ...newItem,
                         ...{
                              obj: object,
                              name: object.name,
                              description: object.description,
                              cost: object.cost,
                              price: object.price,
                              credit: object.credit || '',
                              unit: object.unit,
                              qty: 1,
                              total: numformat.format(object.price),
                              type: this.invoiceLineItems[idx].type,
                              isPersist:
                                   this.invoiceLineItems[idx].type == 3
                                        ? true
                                        : false,
                              commissionto: null,
                              commission: s_comm,
                              discountable: object.discountable,
                         },
                    };

                    this.invoiceLineItems.splice(idx, 1, newItem);
                    this.runCal();
                    //console.log(newItem);
               },
               onChangePriceQty(idx) {
                    if (this.invoiceLineItems[idx].qty < 0) {
                         this.invoiceLineItems[idx].is_qty_error = [
                              'Not accept -ve qty!',
                         ];
                         return;
                    }
                    if (this.invoiceLineItems[idx].type == 4) {
                         this.invoiceLineItems[idx].is_qty_error = null;

                         //check reach max usage
                         let p_item = this.invoiceLineItems[idx];
                         let x = this.invoiceLineItems.filter((item) => {
                              return (
                                   item.obj.id == p_item.obj.id &&
                                   item.obj._id == p_item.obj._id
                              );
                         });
                         //console.log(x);
                         let total_consumed = sumBy(x, function (o) {
                              return parseFloat(o.qty);
                         });
                         total_consumed += p_item.obj.consumed;
                         //console.log('total_consumed:', total_consumed);

                         //p_item.obj.total_consumed = total_consumed;

                         //console.log(this.invoiceLineItems[idx]);
                         if (p_item.obj.max_usage > 0) {
                              /*if (
                                   this.invoiceLineItems[idx].obj.max_usage -
                                        this.invoiceLineItems[idx].obj.consumed <
                                   this.invoiceLineItems[idx].qty
                              ) {*/
                              if (p_item.obj.max_usage < total_consumed) {
                                   p_item.is_qty_error = [
                                        this.$t('invoice.exceedlimit'),
                                   ];
                              }
                         }

                         //check reach max credit?
                         if (
                              this.invoiceLineItems[idx].obj.remain_credit <
                              this.invoiceLineItems[idx].obj.credit *
                                   this.invoiceLineItems[idx].qty
                         ) {
                              this.invoiceLineItems[idx].is_qty_error = [
                                   this.$t('invoice.exceedlimit'),
                              ];
                         }
                    }
                    let total =
                         unformatter(this.invoiceLineItems[idx].price.toString()) *
                         unformatter(this.invoiceLineItems[idx].qty.toString());

                    this.invoiceLineItems[idx].total = numformat.format(total);
                    this.invoiceLineItems = this.invoiceLineItems.filter(
                         (item) => !isEmpty(item)
                    );
                    //console.log(this.invoiceLineItems);
                    this.runCal();
               },
               onChangeTax() {
                    //user interact with discount, user may change the discount setting
                    this.isOverrideTax = true;
                    this.runCal();
               },
               onFocusTax() {
                    //save the tax first, and then change tax to null
                    if (this.tax != null) this.tmptax = this.tax;

                    this.tax = null;
               },
               onChangeRewardPt() {
                    //console.log('Reward pt set:', this.used_pt);
                    let dollar =
                         this.used_pt / this.apiRewardSetting.dollar_equal_point;
                    //console.log(dollar);
                    this.exchange_pt_amount = Math.floor(dollar);
               },
               onBlurTax() {
                    //沒有執行動作
                    if (this.tax == null) this.tax = this.tmptax;

                    this.runCal();
               },
               onChangeDiscount() {
                    //user interact with discount, user may change the discount setting
                    this.isOverrideDiscount = true;
                    this.runCal();
               },
               update_discount() {
                    this.isOverrideDiscount = false;
                    this.memberdiscountrate =
                         this.selectcustomer.member[0].discount_percentage;
                    this.runCal();
               },
               displayCustomerDialog() {
                    this.apiGetCustomerNote();
                    this.showCustomerDetail = true;
               },
               onFocusDiscount() {
                    //save the tax first, and then change tax to null
                    if (this.discount != null) this.tmpdiscount = this.discount;

                    this.discount = null;
               },
               onBlurDiscount() {
                    //沒有執行動作
                    if (this.discount == null) this.discount = this.tmpdiscount;

                    this.runCal();
               },
               deleteItem(idx) {
                    this.invoiceLineItems.splice(idx, 1);
                    this.runCal();
               },
               runCal() {
                    this.calSubtotal();
                    this.calTax();
                    this.calDiscount();
                    this.calTotal();
               },
               calSubtotal() {
                    let stotal = 0.0; //total
                    let ex_stotal = 0.0; //no discountable product
                    this.invoiceLineItems.map((item) => {
                         //console.log(item);
                         stotal += unformatter(item.total);
                         if (typeof item.discountable == undefined) {
                              if (!item.obj.discountable) {
                                   ex_stotal += unformatter(item.total);
                              }
                         } else {
                              if (!item.discountable)
                                   ex_stotal += unformatter(item.total);
                         }
                    });

                    //this.subtotal = stotal;
                    this.subtotal = numformat.format(roundto(stotal, 2));
                    this.non_discountable_subtotal = roundto(ex_stotal, 2);
                    //console.log(this.non_discountable_subtotal);
               },
               calTax() {
                    if (!this.apiCompanySetting.enable_tax) {
                         this.tax = 0;
                         return;
                    }
                    if (this.isOverrideTax) {
                         return;
                    }

                    let stax = 0.0;
                    stax =
                         unformatter(this.subtotal) *
                         //(unformatter(this.trate) / 100);
                         (unformatter(this.apiInvoiceSetting.tax) / 100);
                    this.tax = roundto(stax, 2);
                    //this.tax = numformat.format(stax);
               },
               calDiscount() {
                    if (this.isOverrideDiscount) {
                         //this.discount = numformat.format(this.discount);
                         //console.log('override discount!');
                         return;
                    }

                    let dis = 0.0;
                    dis =
                         (unformatter(this.subtotal) -
                              this.non_discountable_subtotal) *
                         (unformatter(this.memberdiscountrate) / 100);
                    this.discount = roundto(dis, 2);
                    //console.log('Cal discount:'+dis);
                    //this.discount = numformat.format(dis);
               },
               calCommission(price, commissiontype, comm) {
                    if (commissiontype == 'percentage') {
                         return roundto((price * comm) / 100, 2);
                    }
                    return comm;
               },
               calTotal() {
                    let amount = 0.0;
                    amount =
                         unformatter(this.subtotal) -
                         unformatter(this.discount) +
                         unformatter(this.tax);
                    //this.amount = amount;
                    this.amount = numformat.format(
                         roundto(
                              amount,
                              this.apiInvoiceSetting.decimal_place,
                              this.apiInvoiceSetting.rounding
                         )
                    );
               },
               verify() {
                    if (isEmpty(this.selectcustomer)) {
                         this.is_fail = true;
                         this.error_message = 'Please select customer!';
                         return false;
                    }
                    if (isEmpty(this.selectSales)) {
                         this.is_fail = true;
                         this.error_message = 'Please select sales!';
                         return false;
                    }
                    if (isEmpty(this.invoiceLineItems)) {
                         this.is_fail = true;
                         this.error_message = 'Please add at least 1 line item';
                         return false;
                    }
                    let pass = true;
                    let total_credit_consume = 0;
                    this.invoiceLineItems.map((item) => {
                         if (isEmpty(item.name)) {
                              this.is_fail = true;
                              this.error_message = 'Please enter item name!';
                              pass = false;
                         }
                         if (
                              !isNumber(unformatter(item.price)) &&
                              unformatter(item.price) != 0
                         ) {
                              this.is_fail = true;
                              this.error_message =
                                   'Item price ' +
                                   unformatter(item.price) +
                                   ' must be number';
                              pass = false;
                         }
                         if (
                              !isNumber(unformatter(item.qty)) &&
                              unformatter(item.qty) != 0
                         ) {
                              this.is_fail = true;
                              this.error_message =
                                   'Item quantity ' + item.qty + ' must be number!';
                              pass = false;
                         }
                         //check if consumable item, it credit and max_usage
                         if (item.type == 4) {
                              if (
                                   item.obj.max_usage != 0 &&
                                   item.obj.max_usage <= item.obj.consumed
                              ) {
                                   this.is_fail = true;
                                   this.error_message =
                                        'Item ' +
                                        item.name +
                                        ' reach maximum usage!';
                                   pass = false;
                              }

                              if (
                                   item.obj.max_usage != 0 &&
                                   item.obj.max_usage - item.obj.consumed < item.qty
                              ) {
                                   this.is_fail = true;
                                   this.error_message =
                                        'Item ' + item.name + ' over maximum usage!';
                                   pass = false;
                              }

                              if (item.is_qty_error) {
                                   this.is_fail = true;
                                   this.error_message =
                                        'Item ' +
                                        item.name +
                                        ': ' +
                                        item.is_qty_error;
                                   pass = false;
                                   //console.log('qty error!');
                              }

                              total_credit_consume += item.qty * item.credit;

                              if (total_credit_consume > item.obj.remain_credit) {
                                   this.is_fail = true;
                                   this.error_message =
                                        'Item ' +
                                        item.obj.name +
                                        ' not enough credit to checkout!';
                                   pass = false;
                              }
                         }
                    });

                    return pass;
               },
               createPaymentObj() {
                    if (this.pay_amount <= 0) return null;

                    return {
                         invoiceid: null,
                         customerid: this.selectcustomer._id,
                         amount: this.pay_amount,
                         payment_type: this.payment_type.name,
                         ref: null,
                         status: 'done',
                         pay_date: new Date().toISOString().substr(0, 10),
                         staffname: this.$user.username,
                    };
               },
               createRewardPaymentObj() {
                    if (this.exchange_pt_amount <= 0) return null;

                    return {
                         invoiceid: null,
                         customerid: this.selectcustomer._id,
                         amount: this.exchange_pt_amount,
                         payment_type: 'reward point',
                         reward_point: this.used_pt,
                         ref: null,
                         status: 'done',
                         pay_date: new Date().toISOString().substr(0, 10),
                         staffname: this.$user.username,
                    };
               },
               createPackagePaymentObj() {
                    //loop product list with type 4 and type 0
                    //this.invoiceLineItems.map((item) => {
                    for (let i = 0; i < this.invoiceLineItems.length; i++) {
                         let item = this.invoiceLineItems[i];
                         //console.log(item);
                         if (item.type == 4) {
                              return {
                                   invoiceid: null,
                                   customerid: this.selectcustomer._id,
                                   payment_type: 'stock',
                                   pay_date: new Date().toISOString().substr(0, 10),
                                   staffname: this.$user.username,
                              };
                         }
                         if (
                              item.type == 3 &&
                              item.preUseService1 != null &&
                              item.preUseQty1 != null
                         ) {
                              return {
                                   invoiceid: null,
                                   customerid: this.selectcustomer._id,
                                   payment_type: 'stock',
                                   pay_date: new Date().toISOString().substr(0, 10),
                                   staffname: this.$user.username,
                              };
                         }
                         if (
                              item.type == 3 &&
                              item.preUseService2 != null &&
                              item.preUseQty2 != null
                         ) {
                              return {
                                   invoiceid: null,
                                   customerid: this.selectcustomer._id,
                                   payment_type: 'stock',
                                   pay_date: new Date().toISOString().substr(0, 10),
                                   staffname: this.$user.username,
                              };
                         }
                    }
                    return null;
               },
               createInvoiceObj() {
                    let invoice = {};
                    invoice.invoiceid = parseInt(this.invoiceid);
                    invoice.invoiceno =
                         this.apiInvoiceSetting.prefix + this.invoiceid;
                    invoice.invoice_date = this.invoicedate;
                    invoice.due_date = this.duedate;
                    invoice.salesid = this.selectSales._id;
                    invoice.po = this.po;
                    invoice.currency = this.selectcurrency;
                    invoice.subtotal = unformatter(this.subtotal);
                    invoice.discount = unformatter(this.discount);
                    invoice.tax = unformatter(this.tax);
                    invoice.amount = unformatter(this.amount);
                    invoice.tas = this.tas;
                    invoice.notes = this.notes;
                    invoice.tag = this.tags;
                    invoice.billto = {};
                    invoice.billto.customerid = this.selectcustomer._id;
                    invoice.billto.name = this.selectcustomer.name;
                    invoice.billto.company = this.selectcustomer.company;
                    invoice.billto.address = this.selectcustomer.address;
                    invoice.billto.phone = this.selectcustomer.phone;
                    invoice.billto.email = this.selectcustomer.email;
                    invoice.product = [];
                    invoice.obj = [];
                    this.invoiceLineItems.map((item) => {
                         let constr_item = {
                              productid: item.obj._id,
                              commissionpersonid: isEmpty(item.commissionto)
                                   ? this.selectSales._id
                                   : item.commissionto._id,
                              commission: item.commission,
                              type: item.type,
                              name: item.name || item.obj.name,
                              description: item.description || item.obj.description,
                              sku: item.obj.sku || null,
                              category: item.obj.category || null,
                              brand: item.obj.brand || null,
                              cost: unformatter(item.cost),
                              price: unformatter(item.price),
                              qty: unformatter(item.qty),
                              unit: item.unit,
                              credit: item.obj.credit * item.qty,
                              total: unformatter(item.total),
                              persist: item.isPersist,
                              discountable: item.discountable,
                              upid: item.upid || null,
                         };
                         if (item.type == 4) {
                              constr_item = {
                                   ...constr_item,
                                   ...{
                                        productid: item.obj.id,
                                        unit: 'qty',
                                        customerprofileid: item.customerprofileid,
                                        //customerprofileid: item.obj._id,
                                        //consume_link_service_id: item.obj.id,
                                   },
                              };
                         }
                         invoice.product.push(constr_item);
                         invoice.obj.push(item.obj);

                         //check if this item is type 3 and have preUse
                         if (
                              item.type == 3 &&
                              item.preUseService1 &&
                              item.preUseQty1 > 0
                         ) {
                              let lservice =find(this.apiServiceList, {
                                   _id: item.preUseService1.id,
                              });

                              invoice.product.push({
                                   productid: item.preUseService1.id,
                                   preserviceid: item.obj._id,
                                   commissionpersonid: isEmpty(item.commissionto)
                                        ? this.selectSales._id
                                        : item.commissionto._id,
                                   commission: 0,
                                   type: 0,
                                   name:
                                        item.name +
                                             ' [' +
                                             item.preUseService1.name +
                                             ']' || null,
                                   description: '',
                                   sku: item.obj.sku || null,
                                   serviceid: lservice ? lservice.serviceid : null,
                                   category: lservice ? lservice.category : null,
                                   brand: item.obj.brand || null,
                                   cost: 0,
                                   price: 0,
                                   qty: unformatter(item.preUseQty1),
                                   unit: 'qty',
                                   credit:
                                        item.preUseService1.credit * item.preUseQty1,
                                   total: 0,
                                   persist: false,
                                   discountable: false,
                                   upid: item.upid || null,
                              });
                              invoice.obj.push(item.preUseService1);
                         }
                         if (
                              item.type == 3 &&
                              item.preUseService2 &&
                              item.preUseQty2 > 0
                         ) {
                              let lservice = find(this.apiServiceList, {
                                   _id: item.preUseService2.id,
                              });

                              invoice.product.push({
                                   productid: item.preUseService2.id,
                                   preserviceid: item.obj._id,
                                   commissionpersonid: isEmpty(item.commissionto)
                                        ? this.selectSales._id
                                        : item.commissionto._id,
                                   commission: 0,
                                   type: 0,
                                   name:
                                        item.name +
                                             ' [' +
                                             item.preUseService2.name +
                                             ']' || null,
                                   description: '',
                                   sku: item.obj.sku || null,
                                   serviceid: lservice ? lservice.serviceid : null,
                                   category: lservice ? lservice.category : null,
                                   brand: item.obj.brand || null,
                                   cost: 0,
                                   price: 0,
                                   qty: unformatter(item.preUseQty2),
                                   unit: 'qty',
                                   credit:
                                        item.preUseService2.credit * item.preUseQty2,
                                   total: 0,
                                   persist: false,
                                   discountable: false,
                                   upid: item.upid || null,
                              });
                              invoice.obj.push(item.preUseService2);
                         }
                    });
                    invoice.status = 'unpaid';

                    return invoice;
               },
               async saveInvoiceAndPay() {
                    if (!this.verify()) return;

                    this.isLoading = true;

                    //create normal payment
                    let payment_obj = this.createPaymentObj();
                    //create reward payment
                    let r_payment_obj = this.createRewardPaymentObj();
                    //consume credit payment
                    let p_payment_obj = this.createPackagePaymentObj();
                    //console.log(p_payment_obj);

                    let invoice_obj = this.createInvoiceObj();

                    if (
                         (r_payment_obj ? r_payment_obj.amount : 0) +
                              (payment_obj ? payment_obj.amount : 0) <=
                              0 &&
                         invoice_obj.amount > 0 &&
                         p_payment_obj == null
                    ) {
                         this.isLoading = false;
                         this.is_fail = true;
                         this.error_message =
                              'Cannot create payment with less than or equal zero amount';
                         return;
                    }

                    //let over_amount_continue = true;
                    //check if total payment > amount need to pay, alert if continue?
                    if (
                         (r_payment_obj ? r_payment_obj.amount : 0) +
                              (payment_obj ? payment_obj.amount : 0) >
                         invoice_obj.amount
                    ) {
                         if (
                              !(await this.$refs.confirm.open(
                                   this.$t('global.confirm'),
                                   this.$t('invoice.overamountmsg')
                              ))
                         ) {
                              this.isLoading = false;
                              return;
                         }
                    }

                    //create invoice
                    let res = null;
                    let inv_id = null;
                    try {
                         res = await InvoiceService.addInvoiceFull({
                              invoice: invoice_obj,
                              staffname: this.$user.username,
                         });
                         if (res.data.status == 'success')
                              inv_id = res.data.data.insertedId;
                    } catch (e) {
                         console.log(e);
                         this.isLoading = false;
                         this.is_fail = true;
                         this.error_message = 'Error adding new invoice!';
                         history.back();
                         return;
                    }

                    //inv_id = res.data.data.insertedId;
                    //console.log(res.data);

                    try {
                         //add payment
                         if (payment_obj) {
                              payment_obj.invoiceid = inv_id;
                              res = await PaymentService.addPayment(payment_obj);
                         }

                         //add reward point payment
                         if (r_payment_obj) {
                              r_payment_obj.invoiceid = inv_id;
                              res = await PaymentService.addPayment(r_payment_obj);
                         }

                         //add package payment
                         if (p_payment_obj) {
                              p_payment_obj.invoiceid = inv_id;
                              res = await PaymentService.addAPPayment(p_payment_obj);
                         }
                    } catch (e) {
                         console.log(e);
                         this.isLoading = false;
                         this.is_fail = true;
                         this.error_message = e.response.data.message;
                         history.back();
                         return;
                    }

                    //check if can complete invoice? (total pay > invoice balance)
                    //if (invoice_obj.amount == 0) {
                    if (
                         (r_payment_obj ? r_payment_obj.amount : 0) +
                              (payment_obj ? payment_obj.amount : 0) >=
                         invoice_obj.amount
                    ) {
                         try {
                              res = await InvoiceService.completeInvoice(inv_id);
                         } catch (e) {
                              console.log(e);
                              this.isLoading = false;
                              this.is_fail = true;
                              this.error_message = e.response.data.message;
                              history.back();
                              return;
                         }
                    }

                    this.isLoading = false;
                    this.is_success = true;
                    this.success_message = 'Created new invoice and payment!';
                    history.back();
               },
               saveInvoice() {
                    if (!this.verify()) return;

                    this.isLoading = true;

                    let invoice_obj = this.createInvoiceObj();
                    //console.log(invoice_obj);
                    InvoiceService.addInvoiceFull({
                         invoice: invoice_obj,
                         staffname: this.$user.username,
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.isLoading = false;
                                   history.back();
                              }
                         })
                         .catch((e) => {
                              this.isLoading = false;
                              this.is_fail = true;
                              this.error_message = e.response.data.error || e;
                              return;
                         });
               },
               updateInvoice() {
                    if (!this.verify()) return;

                    this.isLoading = true;

                    let invoice_obj = this.createInvoiceObj();
                    InvoiceService.updateInvoice({
                         ...invoice_obj,
                         ...{ _id: this._id },
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   LogService.CustomerInvoiceModifyLog(
                                        invoice_obj.billto.customerid,
                                        this.$user.username,
                                        this.invoiceno
                                   );
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +
                                             ': ' +
                                             this.invoiceno
                                   );
                              }
                              this.isLoading = false;
                         })
                         .catch((e) => {
                              console.log(e);
                              this.isLoading = false;
                              this.is_fail = true;
                              this.error_message = e;
                              //this.$emit('onFailAction', e);
                         });
               },
               closeDialog() {
                    this.$emit('onClickCancel', null);
               },
               copyAmount() {
                    this.pay_amount =
                         unformatter(this.amount) - this.exchange_pt_amount;
               },
               cleanPackageSelect(idx) {
                    if (idx == 1) {
                         //console.log('cleaning 1...');
                         this.preUseService1 = null;
                         this.preUseQty1 = null;

                         //console.log(this.invoiceLineItemDetail);
                    }
                    if (idx == 2) {
                         //console.log('cleaning 2...');
                         this.preUseService2 = null;
                         this.preUseQty2 = null;

                         //console.log(this.invoiceLineItemDetail);
                    }
               },
               importDataFromTemplate() {
                    if (isEmpty(this.selectTemplate)) {
                         this.is_fail = true;
                         this.error_message = 'Please select template to import!';
                         return false;
                    }
                    console.log(this.selectTemplate);

                    //set customer
                    if(this.importCustomer){
                         //get customer from backend

                         CustomerService.getCustomer(this.selectTemplate.content.customerid).then(response=>{
                              if(response.data.status=='success'){
                                   this.selectcustomer = response.data.data;
                                   this.selectcustomer.name_and_phone = response.data.data.name + ' ('+ response.data.data.phone+')'
                                   this.customerfilterlist = [this.selectcustomer];
                              }
                         }).catch(e=>{
                              console.log(e);
                              this.error_message = e.message;
                              this.is_fail = true;
                         })

                         /*let filterCustomer = this.apiCustomerList.filter(
                              (item) => item._id == this.selectTemplate.content.customerid
                         );
                         if (filterCustomer.length == 1) {
                              this.selectcustomer = filterCustomer[0];
                         }*/

                         let filterSales = this.apiUserList.filter(
                              (item) => item._id == this.selectTemplate.content.salesid
                         );
                         if (filterSales.length == 1) {
                              this.selectSales = filterSales[0];
                         }
                    }

                    this.subtotal = numformat.format(
                         this.selectTemplate.content.subtotal
                    );
                    this.discount = numformat.format(
                         this.selectTemplate.content.discount
                    );
                    this.tax = numformat.format(this.selectTemplate.content.tax);
                    this.amount = numformat.format(
                         this.selectTemplate.content.amount
                    );

                    this.currency = this.selectTemplate.content.currency;
                    this.tas = this.selectTemplate.content.tas;
                    this.notes = this.selectTemplate.content.notes;

                    this.invoiceLineItems = [];

                    this.selectTemplate.content.product.map((item) => {
                         //get commissionperson
                         let commissionto = this.apiUserList.filter(
                              (ele) => ele._id == item.commissionpersonid
                         );
                         if (commissionto.length == 1)
                              commissionto = commissionto[0];

                         /*let item_obj = this.apiItemList.filter(ele=>ele._id == item.productid);
             if(item_obj.length == 1)
               item_obj = item_obj[0];*/
                         let item_obj = null;
                         if (item.type == 1)
                              item_obj =find(this.apiItemList, {
                                   _id: item.productid,
                              });
                         else if (item.type == 2)
                              item_obj = find(this.apiServiceList, {
                                   _id: item.productid,
                              });
                         else if (item.type == 3)
                              item_obj = find(this.apiPackageList, {
                                   _id: item.productid,
                              });
                         else item_obj = { _id: null };

                         //console.log(item_obj);

                         this.invoiceLineItems.push({
                              obj: item_obj,
                              name: item.name,
                              description: item.description || '',
                              cost: item.cost,
                              price: item.price,
                              credit: item.credit || '',
                              unit: item.unit || '',
                              qty: item.qty,
                              total: item.total,
                              type: item.type,
                              isPersist: item.persist,
                              commissionto: commissionto,
                              commission: item.commission,
                         });
                    });

                    this.is_success = true;
                    this.success_message = '成功匯入!';

                    this.selectTemplate = null;
               },
               importRemark() {
                    if (this.selectedlogidx == null) return;

                    //console.log(this.logobjs[this.selectedlogidx]);
                    if (isEmpty(this.notes))
                         this.notes = this.logobjs[this.selectedlogidx].content;
                    else
                         this.notes =
                              this.notes +
                              '\n' +
                              this.logobjs[this.selectedlogidx].content;

                    this.showCustomerDetail = false;
                    this.selectedlogidx = null;
               },
               selectRemark(idx) {
                    this.selectedlogidx = idx;
               },
               cancelInput() {
                    history.back();
               },
               editItem(idx) {
                    //console.log(this.invoiceLineItems[idx]);
                    if (
                         !this.invoiceLineItems[idx] ||
                    isEmpty(this.invoiceLineItems[idx].obj)
                    )
                         return;

                    this.itemEditidx = idx;
                    this.invoiceLineItemDetail = clone(this.invoiceLineItems[idx]);

                    this.preUseService1 =
                         this.invoiceLineItemDetail.preUseService1 || null;
                    this.preUseQty1 = this.invoiceLineItemDetail.preUseQty1 || null;

                    this.preUseService2 =
                         this.invoiceLineItemDetail.preUseService2 || null;
                    this.preUseQty2 = this.invoiceLineItemDetail.preUseQty2 || null;

                    this.itemEditDialog = true;
               },
               runDuplicateProcess() {
                    InvoiceService.getInvoice(this._id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.invoiceobj = response.data.data;

                                   this.invoicedate = this.invoiceobj.invoice_date;
                                   this.duedate = this.invoiceobj.due_date;
                                   this.po = this.invoiceobj.po;
                                   this.selectcurrency = this.invoiceobj.currency;
                                   this.subtotal = numformat.format(
                                        this.invoiceobj.subtotal
                                   );
                                   this.discount = numformat.format(
                                        this.invoiceobj.discount
                                   );
                                   this.tax = numformat.format(this.invoiceobj.tax);
                                   this.amount = numformat.format(
                                        this.invoiceobj.amount
                                   );
                                   this.tas = this.invoiceobj.tas;
                                   this.notes = this.invoiceobj.notes;

                                   this.selectcustomer =find(
                                        this.apiCustomerList,
                                        {
                                             _id: this.invoiceobj.billto.customerid,
                                        }
                                   );
                                   //console.log(this.selectcustomer);

                                   this.selectSales = {
                                        _id: this.invoiceobj.salesid,
                                   };

                                   this.invoiceobj.product.map((item) => {
                                        //product type 0 and type 4 will not clone
                                        if(item.type == 0 || item.type == 4)
                                             return;

                                        let commission_person = find(
                                             this.apiUserList,
                                             {
                                                  _id: item.commissionpersonid,
                                             }
                                        ) || { name: null };
                                        let constr_item = {
                                             obj: { _id: item.productid },
                                             type: item.type,
                                             name: item.name,
                                             description: item.description,
                                             price: item.price,
                                             qty: item.qty,
                                             unit: item.unit,
                                             credit: item.credit,
                                             total: item.total,
                                             isPersist: item.persist,
                                             commissionto: commission_person,
                                             commission: item.commission,
                                        };
                                        if (constr_item.type == 1) {
                                             constr_item.obj = find(
                                                  this.apiItemList,
                                                  {
                                                       _id: item.productid,
                                                  }
                                             );
                                        } else if (constr_item.type == 2) {
                                             constr_item.obj = find(
                                                  this.apiServiceList,
                                                  {
                                                       _id: item.productid,
                                                  }
                                             );
                                        } else if (constr_item.type == 3) {
                                             constr_item.obj = find(
                                                  this.apiPackageList,
                                                  {
                                                       _id: item.productid,
                                                  }
                                             );
                                        }
                                        this.invoiceLineItems.push(constr_item);
                                   });
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               confirmEdit() {
                    //update invoice line item
                    this.invoiceLineItems[this.itemEditidx] = {
                         ...this.invoiceLineItems[this.itemEditidx],
                         ...this.invoiceLineItemDetail,
                         ...{
                              preUseService1: this.preUseService1,
                              preUseQty1: this.preUseQty1,
                              preUseService2: this.preUseService2,
                              preUseQty2: this.preUseQty2,
                         },
                    };

                    //console.log(this.invoiceLineItems[this.itemEditidx]);

                    this.onChangePriceQty(this.itemEditidx);
                    this.runCal();
                    this.itemEditDialog = false;
               },
               confirmEditTandC() {
                    this.tas = this.modifyTAS.slice();
                    this.showTandCBox = false;
               },
               openTAS() {
                    this.modifyTAS = this.tas.slice();
                    this.showTandCBox = true;
               },
               cancelEdit() {
                    this.itemEditDialog = false;
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               formatDateTime(value) {
                    return moment(value).format('YYYY-MM-DD HH:mm');
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
               openQr(){
                    if(!this.ssound){
                         this.ssound = new Audio();
                         this.ssound.autoplay = true;
                         this.ssound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
                         this.ssound.load();
                    }

                    if(!this.esound){
                         this.esound = new Audio();
                         this.esound.autoplay = true;
                         this.esound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
                         this.esound.load();
                    }

                    this.qrDialog = true;
               },
               openBarcode(){
                    if(!this.ssound){
                         this.ssound = new Audio();
                         this.ssound.autoplay = true;
                         this.ssound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
                         this.ssound.load();
                    }

                    if(!this.esound){
                         this.esound = new Audio();
                         this.esound.autoplay = true;
                         this.esound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
                         this.esound.load();
                    }

                    this.barcodeDialog = true;
               },
               onDecodeQR(decodedString) {
                    //console.log(decodedString);
                    let customer_obj = find(this.apiCustomerList, {
                         _id: decodedString,
                    });

                    if (customer_obj == undefined) {
                         //let audio = new Audio('./error_beep.mp3');
                         //audio.play();
                         /*const sound = new Audio(
                              require('@/assets/error_beep.mp3')
                         ).play();*/

                         this.esound.src = error_sound;
                         this.error_message = 'Cannot find this customer';
                         this.is_fail = true;
                    } else {
                         //let audio = new Audio('./beep.mp3');
                         //audio.play();
                         /*const sound = new Audio(
                              require('@/assets/beep.mp3')
                         ).play();*/
                         this.ssound.src = success_sound;
                         this.selectcustomer = customer_obj;
                         this.onChangeCustomer(customer_obj);

                         this.success_message = 'Get customer done';
                         this.is_success = true;

                    }

                    //console.log(customer_obj);

                    this.qrDialog = false;
               },
               onDecodeB(decodedString) {
                    //console.log(decodedString);
                    //console.log(this.apiItemList);
                    let product_obj = find(this.apiItemList, {
                         barcode: decodedString,
                    });

                    //console.log(product_obj);
                    if (product_obj == undefined) {
                         //let audio = new Audio('./error_beep.mp3');
                         //audio.play();
                         /*const sound = new Audio(
                              require('@/assets/error_beep.mp3')
                         ).play();*/
                         this.esound.src = error_sound;

                         this.error_message = 'Cannot find this product';
                         this.is_fail = true;
                    } else {
                         //let audio = new Audio('./beep.mp3');
                         //audio.play();
                         /*const sound = new Audio(
                              require('@/assets/beep.mp3')
                         ).play();*/
                         this.ssound.src = success_sound;
                         //this.selectcustomer = customer_obj;
                         //this.onChangeCustomer(customer_obj);
                         this.addNewLineItem(1);
                         this.onChangeLineItem(
                              product_obj,
                              this.invoiceLineItems.length - 1
                         );
                    }

                    this.barcodeDialog = false;
               },
               async apiSearchCustomer(val){
                    //if (this.customerfilterlist.length > 0) return
                    this.customerlistloading = true;
                    //go api search for match

                    let searchquery = { customerphone: val, customername: val };
                    try {
                         let result = await CustomerService.comboSearch(1, 100, searchquery);
                         //console.log(result);
                         let x = result.data.data.map(data=>{
                              data.name_and_phone = data.name+ " ("+data.phone+")";
                              return data;
                         })
                         console.log('X:',x);
                         this.customerfilterlist = x;
                         //console.log(this.customerfilterlist);
                         this.customerlistloading = false;
                    } catch (e) {
                         console.log(e);
                         this.is_fail = true;
                         this.error_message = 'Cannot get customer list!';
                         this.customerlistloading = false;
                    }

               },
               keyinCustomer(KBevent){
                    //if customer was select, any input will delete current one
                    if(this.selectcustomer){
                         this.selectcustomer = null;
                         this.isStockAvailiable = false;
                         this.apiStockList = [];
                         this.removeStockTypeLineItem();
                    }
               },
               removeStockTypeLineItem(){
                    this.invoiceLineItems = this.invoiceLineItems.filter(
                         (l) => l.type != 4
                    );
               }
          },
          async created() {
               await this.apiGetCustomerCategoryList();
               await this.apiGetUserList();
               await this.apiGetLastInvoiceId();
               await this.apiGetSettingPrefix();

               await this.apiGetProductList();
               await this.apiGetServiceList();
               await this.apiGetPackageList();

               await this.apiGetTemplateList();

               if (this._id && this.isEdit == 'true') {
                    await this.loadInvoice();
                    return;
               }

               if (this._id) {
                    this.runDuplicateProcess();
               }

               if(this.cid){
                    //get customer id, load customer detail and set it
                    this.apiGetCustomerByid(this.cid);
               }
          },
          watch: {
               apiItemList: function (newVal) {
                    this.isProductAvailiable = newVal.length > 0;
               },
               apiServiceList: function (newVal) {
                    this.isServiceAvailiable = newVal.length > 0;
               },
               apiPackageList: function (newVal) {
                    this.isPackageAvailiable = newVal.length > 0;
               },
               searchCustomer (val) {
                    if(val && val.length>=3){
                         //console.log(val);
                         if(this.selectcustomer){
                              val !== this.selectcustomer.name_and_phone && this.apiSearchCustomer(val)
                         }else{
                              this.apiSearchCustomer(val);
                         }
                    }
               },

          },

          components: {
               ConfirmDialog,
               //QrcodeStream,
               StreamBarcodeReader,
          },

          computed: {},
     };
</script>

<style>
     .itembox {
          width: 300px;
          margin-top: auto;
          margin-bottom: auto;
     }
     #companybox {
          width: 300px;
          margin-top: auto;
          margin-bottom: auto;
     }
     .a4width {
          width: 830px;
     }
     .a4screen {
          width: 830px;
          height: 100%;
          /* ipad 7th, 8th,9th gen : 1080 * 810 (currently tested)
          ipad air 4gen : 1180 * 820
          ipad air 3gen : 1112 * 834
       */
          /*width: 794px;
       height: 1123px;*/
     }
     .right-input input {
          text-align: right;
     }
     #filtercombo label{
          font-size: 13px;
     }
     #customercombo{
          font-size: 13px;
     }
</style>
