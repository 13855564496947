<template>
     <div>
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5">
                         <span v-if="_id == null" class="heading_color--text">{{
                              $t('global.newrecord')
                         }}</span>
                         <span v-else class="heading_color--text">{{
                              $t('customer.modifymembership')
                         }}</span>
                    </v-row>
               </v-container>
               <v-divider></v-divider>
               <div>
                    <v-form
                         ref="form"
                         v-model="valid"
                         lazy-validation
                         class="pa-10 post_bg"
                    >
                         <v-row>
                              <v-col class="py-0">
                                   <v-text-field
                                        v-model="name"
                                        :value="name"
                                        :counter="50"
                                        :rules="nameRules"
                                        :label="$t('customer.membershipname')"
                                        :hint="$t('global.required')"
                                        persistent-hint
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <v-col class="py-0 d-flex flex-row">
                                   <v-text-field
                                        v-model="discount"
                                        :rules="discountRules"
                                        :label="$t('customer.setdiscount')"
                                        :hint="$t('global.required')"
                                        suffix="%"
                                        type="number"
                                        persistent-hint
                                        outlined
                                        dense
                                        class="pr-2"
                                        background-color="#ffffff"
                                   ></v-text-field>
                                   <v-text-field
                                        v-model="lv_point"
                                        :label="$t('customer.membershipreq')"
                                        type="number"
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                         </v-row>

                         <v-row>
                              <v-col class="py-0">
                                   <v-textarea
                                        v-model="description"
                                        :label="$t('customer.detail')"
                                        rows="3"
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-textarea>
                              </v-col>
                         </v-row>

                         <v-switch
                              v-model="is_default"
                              inset
                              dense
                              :label="$t('customer.setdefaultlv')"
                         ></v-switch>
                    </v-form>
               </div>
               <v-divider></v-divider>
               <v-container fluid>
                    <v-row class="pa-5">
                         <v-spacer></v-spacer>
                         <v-btn
                              v-if="_id == null"
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addNewRecord"
                         >
                              {{ $t('global.add') }}
                         </v-btn>
                         <v-btn
                              v-else
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="editRecord"
                         >
                              {{ $t('global.update') }}
                         </v-btn>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              text
                              @click="cancel"
                         >
                              {{ $t('global.cancel') }}
                         </v-btn>
                    </v-row>
               </v-container>
          </div>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Update Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_success" color="primary" elevation="24">
               Update Successfully!
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import CustomerCategoryService from '../../utils/api/CustomerCategoryService';
     import BrowserSession from '@/utils/BrowserSession';

     export default {
          props: {
               _id: String,
          },
          data() {
               return {
                    valid: false,

                    name: '',
                    nameRules: [
                         (v) => !!v || 'Name is required',
                         (v) =>
                              (v && v.length <= 50) ||
                              'Name must be less than 50 characters',
                    ],
                    discount: 0,
                    discountRules: [
                         (v) => !!v.toString() || 'Discount is required',
                         (v) =>
                              (!isNaN(parseInt(v)) && v >= 0 && v <= 100) ||
                              'Discount must between 0 and 100',
                    ],

                    description: '',
                    is_default: false,

                    lv_point: 0,

                    is_success: false,
                    is_fail: false,
                    error_message: '',
               };
          },
          methods: {
               addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    CustomerCategoryService.addCustomerCategory({
                         customer_category_name: this.name,
                         customer_category_desc: this.description,
                         discount_percentage: this.discount,
                         is_default: this.is_default,
                         lv_point: this.lv_point,
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.reloadCCCache();
                                   this.is_success = true;
                                   this.$emit('onClickInput', null);
                              } else {
                                   this.error_message =
                                        this.$t('global.failPrefix') +
                                        ': ' +
                                        response.data.message;
                                   this.is_fail = true;
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },

               cancel() {
                    this.$emit('onClickCancel', null);
               },

               getRecord() {
                    CustomerCategoryService.getCustomerCategory(this._id).then(
                         (response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   this.name = data.customer_category_name;
                                   this.description = data.customer_category_desc;
                                   this.discount = data.discount_percentage;
                                   this.is_default = data.is_default;
                                   this.lv_point = data.lv_point;
                              } else {
                                   this.error_message =
                                        this.$t('global.failPrefix') +
                                        ': ' +
                                        response.data.message;
                                   this.is_fail = true;
                              }
                         }
                    );
               },

               editRecord() {
                    if (!this.$refs.form.validate()) {
                         //console.log('form invalid!');
                         return;
                    }
                    CustomerCategoryService.updateCustomerCategory({
                         _id: this._id,
                         customer_category_name: this.name,
                         customer_category_desc: this.description,
                         discount_percentage: this.discount,
                         lv_point: this.lv_point,
                         is_default: this.is_default,
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +
                                             ': ' +
                                             this.name
                                   );
                                   this.reloadCCCache();
                              } else {
                                   this.error_message =
                                        this.$t('global.failPrefix') +
                                        ': ' +
                                        response.data.message;
                                   this.is_fail = true;
                              }
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               reloadCCCache() {
                    BrowserSession.renewSession('customer_category', null);
               },
          },
          created() {
               if (this._id) {
                    //console.log("this is edit record!");
                    this.getRecord();
               }
          },
     };
</script>
