<template>
     <div class="pa-3" style="max-width: 1366px" v-if="havePermit()">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">{{
                              $t('components.invoice')
                         }}</span>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div v-html="$t('invoice.invoicetips')"></div>
                         </v-tooltip>
                         <v-btn
                              icon
                              color="primary"
                              style="ml-5"
                              @click="displaysearchbar = !displaysearchbar"
                         >
                              <v-icon small>mdi-magnify</v-icon>
                         </v-btn>

                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                   >
                                        {{ $t('global.morefunction')}}
                                        <v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon class="menu-list-btn">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                                  >{{
                                                       $t('global.import')
                                                  }}</v-list-item-title
                                             >
                                        </v-list-item-content>
                                   </v-list-item>
                                   <v-list-item link @click="onExportCSV()">
                                        <v-list-item-icon class="menu-list-btn">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{
                                                  $t('global.export')
                                             }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="showAddInvoice()"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">{{
                                   $t('global.add')
                              }}</span>
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg mx-4 my-2 rounded">
                    <v-container
                         fluid
                         v-if="displaysearchbar"
                         class="d-flex flex-column"
                    >
                         <div class="d-flex flex-row justify-space-between">
                              <div id="searchbox" class="d-flex flex-row">
                                   <v-text-field
                                        single-line
                                        :label="$t('invoice.searchplaceholder')"
                                        v-model="searchcontent"
                                        prepend-inner-icon="mdi-magnify"
                                        outlined
                                        style="width: 400px"
                                        background-color="white"
                                        dense
                                        hide-details
                                        clearable
                                        clear-icon="mdi-close"
                                        @keydown.enter.prevent="setFilter"
                                        ref="searchtextfield"
                                   >
                                        <template v-slot:append>
                                             <v-btn
                                                  icon
                                                  @click="triggerAdvFilter"
                                             >
                                                  <v-badge
                                                       :content="asearchnotify"
                                                       :value="asearchnotify"
                                                       color="red"
                                                       overlap
                                                  >
                                                       <v-icon
                                                            small
                                                            color="primary"
                                                            >mdi-filter-plus</v-icon
                                                       >
                                                  </v-badge>
                                             </v-btn>
                                        </template>
                                   </v-text-field>
                                   <v-btn
                                        icon
                                        @click="openQr()"
                                        color="primary"
                                   >
                                        <v-icon small>mdi-qrcode-scan</v-icon>
                                   </v-btn>
                              </div>

                              <div>
                                   <v-btn
                                        color="primary"
                                        class="body-2 font-weight-bold mx-2"
                                        @click="setFilter"
                                   >
                                        <span>{{ $t('global.filter') }}</span>
                                   </v-btn>
                                   <v-btn
                                        color="grey"
                                        class="body-2 font-weight-bold"
                                        text
                                        @click="resetFilter"
                                   >
                                        <span class="grey--text">{{
                                             $t('global.cancel')
                                        }}</span>
                                   </v-btn>
                              </div>
                         </div>
                    </v-container>
                    <v-progress-linear
                         indeterminate
                         v-if="searchloading"
                    ></v-progress-linear>
               </div>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="3">
                                   <span class="mx-5"> &nbsp; </span>
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.invoiceno')
                                   }}</span>
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text"
                                        >{{ $t('components.customer') }} /
                                        {{ $t('customer.sales') }}</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text"
                                        >{{ $t('global.date') }} /
                                        {{ $t('invoice.paybefore') }}</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text"
                                        >{{ $t('invoice.amount') }} (
                                        {{$t('invoice.unpaid')}} )
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.status')
                                   }}</span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList pt-3">
                    <v-expansion-panels
                         v-model="panel"
                         flat
                         tile
                         style="flex-direction: column; flex-wrap: nowrap;"
                    >
                         <v-row
                              :class="`tc${index % 2}`"
                              v-for="(invoice, index) in invoiceList"
                              :key="invoice._id"
                              class="fill-height"
                              style=""
                              align="center"
                         >
                              <v-expansion-panel
                                   :class="`tc${index % 2}`"
                                   class="pa-0"
                              >
                                   <v-expansion-panel-header
                                        hide-actions
                                        @click="openPanel(invoice._id, index)"
                                        class="px-0 pt-2 pb-0"
                                   >
                                        <v-col cols="3" class="d-flex flex-row">
                                             <v-avatar
                                                  size="15"
                                                  color="heading_bg"
                                                  class="mx-3 my-auto"
                                                  style="overflow: visible;"
                                             >
                                                  <span
                                                       class="heading_color--text"
                                                       style="font-size:9px !important"
                                                       >{{
                                                            (page - 1) * 5 +
                                                            index +
                                                            1
                                                       }}</span
                                                  >
                                             </v-avatar>

                                             <v-chip
                                                  :class="`inv-${invoice.status} px-3`"
                                                  outlined
                                                  center
                                                  :text-color="`inv-${invoice.status}`"
                                             >
                                                  {{
                                                       invoice.invoiceno
                                                  }}</v-chip
                                             >
                                             <v-icon
                                                  small
                                                  v-if="
                                                       (invoice.status ==
                                                            'paid' &&
                                                            invoice.balance >
                                                                 0) ||
                                                       (invoice.status ==
                                                            'paid' &&
                                                            invoice.credit_balance >
                                                                 0)
                                                  "
                                                  color="red"
                                                  >mdi-alert-circle-outline</v-icon
                                             >
                                             <span
                                                  class="text2_color--text my-auto px-2"
                                                  >▾</span
                                             >
                                        </v-col>

                                        <v-col
                                             cols="3"
                                             class="d-flex flex-column"
                                        >
                                             <div
                                                  class="primary--text"
                                                  @click="
                                                       goToCustomer(
                                                            invoice.billto
                                                                 .customerid
                                                       )
                                                  "
                                             >
                                                  {{ invoice.billto.name }}
                                             </div>
                                             <span
                                                  class="text2_color--text text-caption"
                                                  >{{
                                                       invoice.sales.name
                                                  }}</span
                                             >
                                             <div>
                                                  <span
                                                       v-for="(
                                                            tag, index
                                                       ) in invoice.tag"
                                                       :key="index"
                                                  >
                                                       <v-chip
                                                            class="ma-1"
                                                            text-color="grey darken-1"
                                                            label
                                                            x-small
                                                       >
                                                            {{ tag }}</v-chip
                                                       >
                                                  </span>
                                             </div>
                                        </v-col>

                                        <v-col
                                             cols="2"
                                             class="d-flex flex-column"
                                        >
                                             <span
                                                  class="text1_color--text text-body-2"
                                                  >{{
                                                       formatDate(
                                                            invoice.invoice_date
                                                       )
                                                  }}</span
                                             >
                                             <span
                                                  class="text2_color--text text-caption"
                                                  >{{
                                                       formatDate(
                                                            invoice.due_date
                                                       )
                                                  }}</span
                                             >
                                        </v-col>

                                        <v-col cols="2">
                                             <div
                                                  class="d-flex flex-column my-auto"
                                             >
                                                  <span
                                                       class="text1_color--text text-body-2 font-weight-bold"
                                                       >${{
                                                            formatCurrency(
                                                                 invoice.amount
                                                            )
                                                       }}</span
                                                  >
                                                  <span
                                                       v-if="
                                                            invoice.balance > 0
                                                       "
                                                       class="caption text2_color--text"
                                                  >
                                                       ( ${{formatCurrency(invoice.balance)}}
                                                       )</span
                                                  >
                                             </div>
                                        </v-col>

                                        <v-col cols="2">
                                             <div
                                                  class="d-flex justify-space-between"
                                             >
                                                  <div>
                                                       <v-icon
                                                            small
                                                            v-if="
                                                                 invoice.status ==
                                                                 'void'
                                                            "
                                                            color="#ff5252"
                                                       >
                                                            mdi-cancel
                                                       </v-icon>
                                                       <v-icon
                                                            small
                                                            v-if="
                                                                 invoice.status ==
                                                                 'paid'
                                                            "
                                                            color="#1976d2"
                                                       >
                                                            mdi-checkbox-marked-circle-outline
                                                       </v-icon>
                                                       <v-icon
                                                            small
                                                            v-if="
                                                                 invoice.status ==
                                                                 'unpaid'
                                                            "
                                                            color="orange"
                                                       >
                                                            mdi-progress-clock
                                                       </v-icon>
                                                  </div>
                                                  <v-menu
                                                       offset-y
                                                       :disabled="
                                                            invoice.status ==
                                                            'void'
                                                       "
                                                  >
                                                       <template
                                                            v-slot:activator="{
                                                                 on,
                                                                 attrs,
                                                            }"
                                                       >
                                                            <v-icon
                                                                 v-bind="attrs"
                                                                 v-on="on"
                                                                 :color="
                                                                      invoice.status ==
                                                                      'void'
                                                                           ? 'grey'
                                                                           : 'primary'
                                                                 "
                                                                 >mdi-dots-vertical-circle</v-icon
                                                            >
                                                       </template>
                                                       <v-list dense>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      showPDFRecord(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-icon
                                                                      class="menu-list-btn"
                                                                 >
                                                                      <v-icon
                                                                           >mdi-printer-eye</v-icon
                                                                      >
                                                                 </v-list-item-icon>
                                                                 <v-list-item-title
                                                                      class="ml-1"
                                                                      >{{
                                                                           $t(
                                                                                'invoice.printpreview'
                                                                           )
                                                                      }}</v-list-item-title
                                                                 >
                                                            </v-list-item>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      modifyRecord(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                                 :disabled="
                                                                      invoice.status !=
                                                                      'unpaid'
                                                                 "
                                                            >
                                                                 <v-list-item-icon
                                                                      class="menu-list-btn"
                                                                 >
                                                                      <v-icon
                                                                           >mdi-pencil-circle</v-icon
                                                                      >
                                                                 </v-list-item-icon>
                                                                 <v-list-item-title
                                                                      class="ml-1"
                                                                      >{{
                                                                           $t(
                                                                                'global.modify'
                                                                           )
                                                                      }}</v-list-item-title
                                                                 >
                                                            </v-list-item>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      viewRecord(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-icon
                                                                      class="menu-list-btn"
                                                                 >
                                                                      <v-icon
                                                                           >mdi-eye</v-icon
                                                                      >
                                                                 </v-list-item-icon>
                                                                 <v-list-item-title
                                                                      class="ml-1"
                                                                      >{{
                                                                           $t(
                                                                                'invoice.read'
                                                                           )
                                                                      }}</v-list-item-title
                                                                 >
                                                            </v-list-item>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      dupRecord(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-icon
                                                                      class="menu-list-btn"
                                                                 >
                                                                      <v-icon
                                                                           >mdi-content-duplicate</v-icon
                                                                      >
                                                                 </v-list-item-icon>
                                                                 <v-list-item-title
                                                                      class="ml-1"
                                                                      >{{
                                                                           $t(
                                                                                'global.duplicate'
                                                                           )
                                                                      }}</v-list-item-title
                                                                 >
                                                            </v-list-item>
                                                       </v-list>

                                                       <v-divider></v-divider>
                                                       <v-list subheader dense>
                                                            <v-subheader>{{
                                                                 $t(
                                                                      'components.modifystatus'
                                                                 )
                                                            }}</v-subheader>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      changeInvoiceDone(
                                                                           invoice._id,
                                                                           index
                                                                      )
                                                                 "
                                                                 :disabled="
                                                                      invoice.status ==
                                                                      'paid'
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.paymentdone'
                                                                                )
                                                                           }}</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.invoicedonetips'
                                                                                )
                                                                           }}</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      changeInvoiceInit(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                                 :disabled="
                                                                      invoice.status ==
                                                                      'unpaid'
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.paymentinit'
                                                                                )
                                                                           }}</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.invoiceinittips'
                                                                                )
                                                                           }}</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      voidInvoice(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.void'
                                                                                )
                                                                           }}</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.invoicevoidtips'
                                                                                )
                                                                           }}</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                       </v-list>

                                                       <v-list subheader dense>
                                                            <v-subheader>{{
                                                                 $t(
                                                                      'invoice.paymentfunction'
                                                                 )
                                                            }}</v-subheader>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      addPayment(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                                 :disabled="
                                                                      invoice.status ==
                                                                      'paid'
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.addnewpayment'
                                                                                )
                                                                           }}</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.newpaymenttips'
                                                                                )
                                                                           }}</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      showPayment(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >付款列表</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >顯示此單據的所有付款情況</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                            <!--<v-list-item link @click="addExpense(invoice._id)">
                    <v-list-item-content>
                      <v-list-item-title>新增支出</v-list-item-title>
                      <v-list-item-subtitle>為單據新增支出記錄</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>-->
                                                       </v-list>

                                                       <v-list subheader dense>
                                                            <v-subheader>{{
                                                                 $t(
                                                                      'invoice.other'
                                                                 )
                                                            }}</v-subheader>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      saveToTempate(
                                                                           invoice._id
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.savetemplate'
                                                                                )
                                                                           }}</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.savetemplatetips'
                                                                                )
                                                                           }}</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                       </v-list>
                                                  </v-menu>
                                             </div>
                                        </v-col>
                                   </v-expansion-panel-header>
                                   <div
                                        class="text-right caption text2_color--text"
                                        style="margin-right:10px"
                                   >
                                        Created @
                                        {{
                                                  formatDateTime(
                                                       invoice.created_date
                                                  )
                                        }}
                                   </div>

                                   <v-expansion-panel-content
                                        eager
                                        color="heading_bg"
                                        v-if="
                                             !loading &&
                                             invoice.product.length > 0
                                        "
                                   >
                                        <div class="d-flex flex-row">
                                             <div
                                                  id="detail"
                                                  style="width: 50%"
                                                  class="d-flex flex-column"
                                             >
                                                  <div
                                                       class="caption text2_color--text mt-2"
                                                  >
                                                       {{ $t('components.product') }}
                                                  </div>
                                                  <v-chip
                                                       class="mt-2 primary lighten-2"
                                                       v-for="(
                                                            item, index
                                                       ) in invoice.product"
                                                       :key="index"
                                                       outlined
                                                       label
                                                  >
                                                       <span
                                                            class="px-0 caption"
                                                            style="width: 25px"
                                                            >{{ index + 1 }}:
                                                       </span>
                                                       <span
                                                            class="caption"
                                                            style="width: 280px"
                                                            >{{ item.name }} *
                                                            {{ item.qty }}
                                                            <span
                                                                 v-if="item.cpname"
                                                                 class="text2_color--text caption"
                                                            >
                                                                 (
                                                                 {{ item.cpname}}
                                                                 )
                                                            </span>
                                                       </span>

                                                       <span class="caption">
                                                            <span
                                                                 v-if="
                                                                      item.type ==
                                                                           0 ||
                                                                      item.type ==
                                                                           4
                                                                 "
                                                                 >Cr{{
                                                                      item.credit
                                                                 }}</span
                                                            >
                                                            <span v-else
                                                                 >$
                                                                 {{
                                                                      formatCurrency(
                                                                           item.total
                                                                      )
                                                                 }}</span
                                                            >
                                                       </span>
                                                  </v-chip>
                                             </div>
                                             <div
                                                  id="pay_detail"
                                                  style="width: 50%"
                                                  class="ml-7"
                                                  v-if="
                                                       //paymentList[invoice._id]
                                                       invoice.payment_list
                                                  "
                                             >
                                                  <div
                                                       class="caption text2_color--text mt-2"
                                                  >
                                                       {{ $t('invoice.paymentmethod') }}
                                                  </div>
                                                  <v-chip
                                                       v-for="(
                                                            payment, index
                                                       ) in invoice.payment_list"
                                                       :key="index"
                                                       class="mt-2 primary"
                                                       text-color="primary"
                                                       outlined
                                                       @click="
                                                            showPayment(
                                                                 invoice._id
                                                            )
                                                       "
                                                  >
                                                       <span
                                                            class="caption"
                                                            style="width: 120px"
                                                            >{{
                                                                 payment.payment_type
                                                            }}</span
                                                       >
                                                       <span
                                                            class="caption"
                                                            style="width: 100px"
                                                       >
                                                            <span
                                                                 v-if="
                                                                      payment.payment_type ==
                                                                      'stock'
                                                                 "
                                                                 >Cr
                                                                 {{
                                                                      payment.amount
                                                                 }}</span
                                                            >
                                                            <span v-else
                                                                 >$

                                                                 {{
                                                                      formatCurrency(
                                                                           payment.amount
                                                                      )
                                                                 }}</span
                                                            >
                                                       </span>
                                                       <span
                                                            class="caption"
                                                            style="width: 100px"
                                                            >@{{
                                                                 formatDate(
                                                                      payment.pay_date
                                                                 )
                                                            }}</span
                                                       >
                                                       <v-icon
                                                            small
                                                            v-if="
                                                                 payment.status ==
                                                                 'void'
                                                            "
                                                            color="#ff5252"
                                                       >
                                                            mdi-cancel
                                                       </v-icon>
                                                       <v-icon
                                                            small
                                                            v-if="
                                                                 payment.status ==
                                                                 'done'
                                                            "
                                                            color="#1976d2"
                                                       >
                                                            mdi-checkbox-marked-circle-outline
                                                       </v-icon>
                                                       <v-icon
                                                            small
                                                            v-if="
                                                                 payment.status ==
                                                                 'init'
                                                            "
                                                            color="orange"
                                                       >
                                                            mdi-progress-clock
                                                       </v-icon>
                                                  </v-chip>
                                             </div>
                                        </div>

                                        <div v-if="invoice.notes">
                                             <div
                                                  class="caption text2_color--text mt-3"
                                             >
                                                  {{ $t('global.remark')}}
                                             </div>
                                             <pre
                                                  class="caption"
                                                  style="font-family:'Roboto', sans-serif;"
                                                  >{{
                                                  invoice.notes
                                                  }}</pre
                                             >
                                        </div>

                                        <!--<div
                                             class="text-right caption text2_color--text pt-3"
                                        >
                                             Created @
                                             {{
                                                  formatDateTime(
                                                       invoice.created_date
                                                  )
                                             }}
                                        </div>-->
                                   </v-expansion-panel-content>
                              </v-expansion-panel>
                         </v-row>
                    </v-expansion-panels>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <ConfirmDialog ref="confirm" />

          <v-dialog v-model="pdfdialog" persistent max-width="1240px">
               <v-card>
                    <InvoicePDF
                         v-if="pdfdialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                         :_id="selectedid"
                    />
               </v-card>
          </v-dialog>

          <v-dialog v-model="paydialog" persistent max-width="800px">
               <PaymentDialog
                    v-if="paydialog"
                    @onClickCancel="onClickCancel"
                    @onClickInput="onClickInput"
                    @onFailAction="onFail"
                    :_id="selectedid"
                    :cid="cid"
                    :invoiceno="invoiceno"
               />
          </v-dialog>

          <v-dialog
               v-model="editdialog"
               persistent
               max-width="900px"
               class="pa-1 background"
          >
               <InvoiceEditDialog
                    v-if="editdialog"
                    @onClickCancel="onClickCancel"
                    @onClickInput="onClickInput"
                    @onFailAction="onFail"
                    :_id="selectedid"
                    isEdit="true"
               />
          </v-dialog>

          <v-dialog
               v-model="templatedialog"
               persistent
               max-width="500px"
               @keydown.esc="cancel"
          >
               <v-card v-if="templatedialog">
                    <v-text-field
                         v-model="templateName"
                         class="pa-5"
                         dense
                         outlined
                         :label="$t('invoice.nametemplate')"
                         :error-messages="templatehint"
                    ></v-text-field>
                    <v-card-actions class="pt-3">
                         <v-spacer></v-spacer>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="templateconfirm"
                              >{{ $t('global.confirm') }}
                         </v-btn>
                         <v-btn
                              color="grey"
                              text
                              class="body-2 font-weight-bold"
                              @click="templatecancel"
                              >{{ $t('global.cancel') }}
                         </v-btn>
                    </v-card-actions>
               </v-card>
          </v-dialog>

          <v-dialog
               v-if="qrDialog"
               v-model="qrDialog"
               persistent
               max-width="550px"
          >
               <div class="white rounded elevation-3 text-center">
                    <!--<qrcode-stream @decode="onDecodeQR"></qrcode-stream>-->
                    <StreamBarcodeReader
                         @decode="onDecodeQR"
                    ></StreamBarcodeReader>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="qrDialog = false"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </div>
          </v-dialog>

          <v-dialog
               v-model="filterdialog"
               max-width="350px"
               overlay-opacity="0"
               transition="dialog-top-transition"
          >
               <div class="white rounded elevation-3 text-center">
                    <div class="heading_color--text pa-3">
                         {{ $t('invoice.advancesearch') }}
                    </div>

                    <v-divider></v-divider>
                    <div class="text-center pa-3">
                         <v-text-field
                              v-model="filtercustomer"
                              prepend-inner-icon="mdi-account"
                              :label="$t('customer.name')"
                              outlined
                              dense
                              hide-details
                              class="pa-2"
                         ></v-text-field>
                         <v-text-field
                              v-model="filterphone"
                              prepend-inner-icon="mdi-phone"
                              :label="$t('customer.phone')"
                              outlined
                              dense
                              hide-details
                              class="pa-2"
                         ></v-text-field>
                         <v-menu
                              v-model="invoicedatepicker"
                              :close-on-content-click="false"
                              :nudge-right="-90"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                        :label="$t('invoice.invoicedate')"
                                        v-model="filterdate"
                                        hide-details
                                        dense
                                        outlined
                                        class="pa-2"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                   ></v-text-field>
                              </template>
                              <v-date-picker
                                   v-model="filterdate"
                                   @input="invoicedatepicker = false"
                              ></v-date-picker>
                         </v-menu>
                         <v-select
                              :label="$t('components.employee')"
                              v-model="filteruser"
                              :items="apiUserList"
                              item-text="name"
                              item-value="_id"
                              class="pa-2"
                              outlined
                              hide-details
                              dense
                              return-object
                         ></v-select>
                         <v-text-field
                              v-model="filtertag"
                              prepend-inner-icon="mdi-tag"
                              :label="$t('customer.tag')"
                              outlined
                              dense
                              hide-details
                              class="pa-2"
                         ></v-text-field>
                         <v-select
                              :label="$t('invoice.status')"
                              v-model="filterstatus"
                              :items="['paid', 'unpaid', 'void']"
                              class="pa-2"
                              outlined
                              hide-details
                              dense
                         ></v-select>

                         <v-container fluid>
                              <v-row class="pa-5">
                                   <v-spacer></v-spacer>
                                   <v-btn
                                        color="primary"
                                        class="body-2 font-weight-bold mx-2"
                                        @click="setAdvFilter"
                                   >
                                        <span>{{ $t('global.filter') }}</span>
                                   </v-btn>
                                   <v-btn
                                        color="grey"
                                        text
                                        class="text-body-2 font-weight-bold"
                                        @click="closeAdvFilter"
                                   >
                                        {{ $t('global.close') }}
                                   </v-btn>
                              </v-row>
                         </v-container>
                    </div>
               </div>
          </v-dialog>

          <v-dialog
               v-if="invoiceDetaildialog"
               v-model="invoiceDetaildialog"
               persistent
               max-width="900px"
               class="pa-1 background"
          >
               <InvoiceViewDialog
                    @onClickCancel="onClickCancel"
                    :_id="selectedid"
               />
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     //import { GChart } from 'vue-google-charts';
     import BrowserSession from '@/utils/BrowserSession';
     import InvoiceService from '@/utils/api/InvoiceService';
     //import PaymentService from '@/utils/api/PaymentService';
     import TemplateService from '@/utils/api/TemplateService';

     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import PaymentDialog from '../payment/PaymentAddView';
     import InvoiceViewDialog from './InvoiceDetailView';
     import InvoiceEditDialog from './InvoiceAddView';
     import JsonToCSV from '@/utils/JsonToCSV';
     import Permission from '@/utils/Permission';
     //import _ from 'lodash';
     //import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
     import StreamBarcodeReader from 'vue-barcode-reader/src/components/StreamBarcodeReader.vue';

     var isEmpty = require('lodash/isEmpty');
     var filter = require('lodash/filter');
     var find = require('lodash/find');

     const moment = require('moment');
     const success_sound = require('@/assets/beep.mp3');
     const error_sound = require('@/assets/error_beep.mp3');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });

     export default {
          data: () => ({
               pagekey: ['pbKyf'],
               managekey: ['Vbgoh'],
               valueA: [['status', 'total']],
               valueB: [['payment method', 'type']],

               //drawer: null,
               searchcontent: null,
               filterCid: null,

               pdfdialog: false,
               paydialog: false,
               editdialog: false,
               dialog: false,
               templatedialog: false,
               filterdialog: false,
               invoiceDetaildialog: false,

               message: '請輸入Template名稱',
               templateName: null,
               templatehint: null,
               saveTemplateid: null,

               invoiceList: [],
               noOfPage: 0,
               page: 1,
               recordperpage: 5,

               apiUserList: [],
               invoicedatepicker: false,
               filteruser: null,
               filterdate: null,
               filtercustomer: null,
               filterphone: null,
               filtertag: null,
               filterstatus: null,
               asearchnotify: 0,

               selectedid: '',
               cid: '',
               invoiceno: '',

               displaysearchbar: true,
               searchloading: false,
               qrDialog: false,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',

               panel: null,
               panelsearch: null,
               //paymentList: [],
               loading: false,

               ssound: null,
               esound: null,
          }),
          methods: {
               apiGetUserList() {
                    BrowserSession.getSession('user', (data) => {
                         this.apiUserList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               apiGetInvoiceList() {
                    if (this.filterCid) {
                         this.searchbycid();
                         return;
                    } else if (this.searchcontent) {
                         this.searchbycontent();
                         return;
                    } else if (this.asearchnotify) {
                         this.setAdvFilter();
                         return;
                    }
                    InvoiceService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              this.invoiceList = [];

                              if (response.data.status == 'success') {
                                   this.invoiceList = response.data.data;
                                   //set items commission person in invoicelist
                                   //console.log(this.apiUserList);
                                   this.invoiceList.map(inv=>{
                                        inv.product.map(prod=>{
                                             prod.cpname = null;
                                             if(prod.commissionpersonid != inv.salesid){
                                                  //search commission person
                                                  let user = find(this.apiUserList, {_id: prod.commissionpersonid});
                                                  if(user)
                                                       prod.cpname = user.name
                                             }
                                        })
                                   })
                                   let rs = response.data.recordcount;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }
                              //console.log(this.invoiceList);
                              this.calcInvoiceList();
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               calcInvoiceList() {
                    this.invoiceList.map((invoice) => {
                         let pay_amount = 0.0;
                         invoice.payment_list.map((payment) => {
                              if (
                                   payment.status == 'done' &&
                                   payment.payment_type != 'stock'
                              )
                                   pay_amount += payment.amount;
                         });

                         invoice.balance = invoice.amount - pay_amount;

                         invoice.credit_balance = 0;
                         //check any package payment not paid
                         invoice.product.map((p) => {
                              if (p.type == 4 || p.type == 0) {
                                   let payment_list =filter(
                                        invoice.payment_list,
                                        {
                                             payment_type: 'stock',
                                             upid: p.upid,
                                             status: 'done',
                                        }
                                   );
                                   let notpaycredit = p.credit;
                                   for (let i = 0; i < payment_list.length; i++) {
                                        notpaycredit -= payment_list[i].amount;
                                   }
                                   if (notpaycredit > 0) {
                                        invoice.credit_balance += notpaycredit;
                                   }
                              }
                         });
                    });
               },
               showAddInvoice() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    this.$router.push({ name: 'invoiceadd' });
               },
               addPayment(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    this.selectedid = _id;
                    this.paydialog = true;
               },
               showPDFRecord(_id) {
                    this.selectedid = _id;
                    //this.pdfdialog=true;
                    let routeData = this.$router.resolve({
                         name: 'invoicepdf',
                         query: { _id: _id },
                    });
                    window.open(routeData.href, '_blank');
               },
               modifyRecord(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }

                    this.selectedid = _id;
                    this.editdialog = true;
               },
               viewRecord(_id){
                    this.invoiceDetaildialog=true;
                    this.selectedid = _id;
               },
               async voidInvoice(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('invoice.voidmsg')
                         )
                    ) {
                         // eslint-disable-next-line
                         InvoiceService.deleteInvoice(_id)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.is_success = true;
                                        this.success_message =
                                             'success void invoice';
                                        //this.apiGetRecordsCount();
                                        this.apiGetInvoiceList();
                                   } else {
                                        this.error_message = response.data.message;
                                        this.is_fail = true;
                                   }
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                              });
                    }
               },
               onExportCSV() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    JsonToCSV.export('invoicelist.csv', 'invoice');
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               formatDateTime(value) {
                    return moment(value).format('YYYY-MM-DD HH:mm');
               },
               onChangePage() {
                    this.apiGetInvoiceList();
               },
               onClickCancel() {
                    this.pdfdialog = false;
                    this.paydialog = false;
                    this.editdialog = false;
                    this.invoiceDetaildialog = false;

                    this.apiGetInvoiceList();
               },
               onFail(err_msg) {
                    this.error_message = err_msg;
               },
               onClickInput(msg) {
                    this.pdfdialog = false;
                    this.paydialog = false;
                    this.editdialog = false;

                    this.is_success = true;
                    this.success_message = msg;

                    this.apiGetInvoiceList();
               },
               openPanel(_id, index) {
                    //console.log(_id);
                    //this.apiGetInvoicePayment(_id, index);
               },
               dupRecord(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    this.$router.push({ name: 'invoiceadd', params: { _id: _id } });
               },
               // eslint-disable-next-line
               saveToTempate(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }

                    this.templatedialog = true;
                    this.saveTemplateid = _id;
               },
               templateconfirm() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }

                    if (isEmpty(this.templateName)) {
                         this.templatehint = this.$t('invoice.templateerrmsg');
                         return;
                    }

                    //get invoice detail
                    InvoiceService.getInvoice(this.saveTemplateid)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let t_invoice = response.data.data;
                                   //console.log(t_invoice);
                                   //save template
                                   let templateObj = {
                                        name: this.templateName,
                                        userid: Permission.getkey('_id'),
                                        content: {
                                             tas: t_invoice.tas,
                                             notes: t_invoice.notes,
                                             customerid: t_invoice.billto.customerid,
                                             salesid: t_invoice.salesid,
                                             currency: t_invoice.currency,
                                             subtotal: t_invoice.subtotal,
                                             discount: t_invoice.discount,
                                             tax: t_invoice.tax,
                                             amount: t_invoice.amount,
                                             product: t_invoice.product.filter(p=>{delete p.upid; return(p.type!=4 && p.type!=0)}),
                                        },
                                   };
                                   //console.log(templateObj);
                                   //eslint-disable-next-line
                                   TemplateService.addTemplate(templateObj)
                                        .then((response) => {
                                             if (response.data.status == 'success') {
                                                  this.success_message =
                                                       this.$t(
                                                            'global.successPrefix'
                                                       ) + this.templateName;
                                                  this.is_success = true;

                                                  this.templatehint = null;
                                                  this.templateName = null;
                                             } else {
                                                  this.error_message =
                                                       this.$t('global.failPrefix');
                                                  this.is_fail = true;
                                                  return;
                                             }
                                        })
                                        .catch((e) => {
                                             console.log(e);
                                             this.error_message =
                                                  this.$t('global.failPrefix');
                                             this.is_fail = true;
                                             return;
                                        });
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.error_message = this.$t('global.failPrefix');
                              this.is_fail = true;
                              return;
                         });

                    this.templatedialog = false;
               },
               templatecancel() {
                    this.templatedialog = false;
               },
               // eslint-disable-next-line
               async changeInvoiceDone(_id, index) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    //check payment completed?
                    //console.log(this.invoiceList[index]);
                    if (
                         this.invoiceList[index].balance > 0 ||
                         this.invoiceList[index].credit_balance > 0
                    ) {
                         if (
                              await this.$refs.confirm.open(
                                   this.$t('global.confirm'),
                                   this.$t('invoice.paymentincompletemsg')
                              )
                         ) {
                              //eslint-disable-next-line
                              InvoiceService.updateInvoiceStatus({
                                   _id: _id,
                                   newstatus: 'paid',
                              })
                                   .then((response) => {
                                        if (response.data.status == 'success') {
                                             this.success_message = this.$t(
                                                  'global.successPrefix'
                                             );
                                             this.is_success = true;
                                             //renew list
                                             this.apiGetInvoiceList();
                                        } else {
                                             this.is_fail = true;
                                             this.error_message =
                                                  response.data.message;
                                        }
                                   })
                                   .catch((e) => {
                                        console.log(e);
                                        this.is_fail = true;
                                        this.error_message = e.message;
                                   });
                         }
                    } else {
                         //eslint-disable-next-line
                         InvoiceService.updateInvoiceStatus({
                              _id: _id,
                              newstatus: 'paid',
                         })
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.success_message = this.$t(
                                             'global.successPrefix'
                                        );
                                        this.is_success = true;
                                        //renew list
                                        this.apiGetInvoiceList();
                                   } else {
                                        this.is_fail = true;
                                        this.error_message = response.data.message;
                                   }
                              })
                              .catch((e) => {
                                   console.log(e);
                                   this.is_fail = true;
                                   this.error_message = e.message;
                              });
                    }
               },
               // eslint-disable-next-line
               changeInvoiceInit(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    //eslint-disable-next-line
                    InvoiceService.updateInvoiceStatus({
                         _id: _id,
                         newstatus: 'unpaid',
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.success_message = this.$t(
                                        'global.successPrefix'
                                   );
                                   this.is_success = true;
                                   //renew list
                                   this.apiGetInvoiceList();
                              } else {
                                   this.is_fail = true;
                                   this.error_message = response.data.message;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.is_fail = true;
                              this.error_message = e.message;
                         });
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
               setFilter() {
                    this.page = 1;
                    this.asearchnotify = 0;
                    if (isEmpty(this.searchcontent)) {
                         this.is_fail = true;
                         this.error_message = 'Invalid search content!';
                         return;
                    }
                    this.searchbycontent();
                    this.$refs.searchtextfield.blur();
               },
               resetFilter() {
                    this.filterCid = null;
                    this.searchcontent = null;
                    this.asearchnotify = 0;

                    this.filtercustomer = null;
                    this.filteruser = null;
                    this.filterphone = null;
                    this.filtertag = null;
                    this.filterdate = null;
                    this.filterstatus = null;

                    this.apiGetInvoiceList();
               },
               openQr(){
                    this.ssound = new Audio();
                    this.ssound.autoplay = true;
                    this.ssound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
                    this.ssound.load();

                    this.esound = new Audio();
                    this.esound.autoplay = true;
                    this.esound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
                    this.esound.load();

                    this.qrDialog = true;
               },
               onDecodeQR(decodedString) {
                    this.page = 1;
                    this.filterCid = decodedString;
                    //search invoice by customer id
                    this.searchbycid();
               },
               searchbycontent() {
                    InvoiceService.searchInvoice({
                         value: this.searchcontent,
                         page: this.page,
                         limit: this.recordperpage,
                    })
                         .then((response) => {
                              this.invoiceList = [];
                              if (response.data.status == 'success') {
                                   this.invoiceList = response.data.data;
                                   let rs = response.data.recordcount;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }

                              this.calcInvoiceList();
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               searchbycid() {
                    InvoiceService.searchInvoiceByCustomerid(
                         this.filterCid,
                         this.page,
                         this.recordperpage
                    )
                         .then((response) => {
                              /*const sound = new Audio(
                                   require('@/assets/beep.mp3')
                              ).play(); */
                              this.ssound.src = success_sound;

                              this.invoiceList = [];
                              if (response.data.status == 'success') {
                                   this.invoiceList = response.data.data;
                                   let rs = response.data.recordcount;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }

                              this.calcInvoiceList();

                              this.qrDialog = false;
                         })
                         .catch((e) => {
                              console.log(e);
                              /*const sound = new Audio(
                                   require('@/assets/error_beep.mp3')
                              ).play();*/

                              this.esound.src = error_sound;
                              this.qrDialog = false;
                              this.is_fail = true;
                              this.error_message = e.message;
                         });
               },
               showPayment(_id) {
                    let routeData = this.$router.resolve({
                         name: 'payment',
                         query: { invoiceid: _id },
                    });
                    window.open(routeData.href, '_blank');
               },
               goToCustomer(_id) {
                    let routeData = this.$router.resolve({
                         name: 'customerdetail',
                         query: { id: _id },
                    });
                    window.open(routeData.href, '_blank');
               },
               triggerAdvFilter() {
                    //console.log('click append!');
                    this.filterdialog = true;
                    //this.drawer = true;
               },
               setAdvFilter() {
                    this.filterCid = null;
                    this.searchcontent = null;

                    let query = {};
                    if (!isEmpty(this.filtercustomer)) {
                         //console.log(this.filtercustomer);
                         query = {
                              ...query,
                              ...{ customername: this.filtercustomer },
                         };
                         this.asearchnotify = 1;
                    }
                    if (!isEmpty(this.filteruser)) {
                         //console.log(this.filteruser);
                         query = { ...query, ...{ salesid: this.filteruser._id } };
                         this.asearchnotify = 1;
                    }
                    if (!isEmpty(this.filterphone)) {
                         //console.log(this.filterphone);
                         query = {
                              ...query,
                              ...{ customerphone: this.filterphone },
                         };
                         this.asearchnotify = 1;
                    }
                    if (!isEmpty(this.filtertag)) {
                         //console.log(this.filtertag);
                         query = { ...query, ...{ tag: this.tag } };
                         this.asearchnotify = 1;
                    }
                    if (!isEmpty(this.filterdate)) {
                         //console.log(this.filterdate);
                         query = { ...query, ...{ invoice_date: this.filterdate } };
                         this.asearchnotify = 1;
                    }
                    if (!isEmpty(this.filterstatus)) {
                         //console.log(this.filterstatus);
                         query = { ...query, ...{ status: this.filterstatus } };
                         this.asearchnotify = 1;
                    }
                    this.$refs.searchtextfield.blur();
                    this.filterdialog = false;
                    if (this.asearchnotify != 1) return;
                    InvoiceService.advsearchInvoice(
                         this.page,
                         this.recordperpage,
                         query
                    )
                         .then((response) => {
                              //console.log(response);
                              this.invoiceList = [];

                              if (response.data.status == 'success') {
                                   this.invoiceList = response.data.data;
                                   //console.log(data);

                                   let rs = response.data.recordcount;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }

                              this.calcInvoiceList();
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               closeAdvFilter() {
                    this.filterdialog = false;
               },
          },
          created() {
               this.apiGetInvoiceList();
               this.apiGetUserList();
          },
          components: {
               //GChart,
               ConfirmDialog,
               PaymentDialog,
               InvoiceEditDialog,
               InvoiceViewDialog,
               //QrcodeStream,
               //QrcodeDropZone,
               //QrcodeCapture,
               StreamBarcodeReader
          },
     };
</script>

<style>
     #button_grp {
          flex-flow: row wrap;
          max-width: 30%;
     }
     .inv-unpaid {
          background: orange !important;
          border-color: orange !important;
          color: orange !important;
     }
     .inv-paid {
          background: #1976d2 !important;
          border-color: #1976d2 !important;
          color: #1976d2 !important;
     }
     .inv-void {
          background: #ff5252 !important;
          border-color: #ff5252 !important;
          color: #ff5252 !important;
     }
     .inv-text-void {
          text-decoration: line-through;
     }
     .pay-done {
          background: #1976d2 !important;
     }
     .pay-init {
          background: orange !important;
     }
     .menu-list-btn {
          margin-right: 5px !important;
     }
</style>
