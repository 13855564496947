<template>
     <div class="pa-7">
          <div class="white rounded elevation-3" v-if="havePermit()">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">{{
                              $t('customer.customer')
                         }}</span>
                         <v-btn
                              icon
                              color="primary"
                              @click="displaysearchbar = !displaysearchbar"
                         >
                              <v-icon small>mdi-magnify</v-icon>
                         </v-btn>

                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                        >{{ $t('global.morefunction')}}
                                        <v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link @click="onImportCSV()">
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title>
                                                  {{$t('global.import')}}
                                             </v-list-item-title>
                                        </v-list-item-content>
                                   </v-list-item>
                                   <v-list-item link @click="onExportCSV()">
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{$t('global.export')}}
                                        </v-list-item-title>
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="showAddCustomerDialog"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">
                                   {{$t('global.add')}}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>

               <v-divider></v-divider>

               <div class="pa-2">
                    <v-container fluid>
                         <v-row align="center" justify="center">
                              <v-col cols="4">
                                   <div
                                        align="center"
                                        justify="center"
                                        class="text-h4 text1_color--text"
                                   >
                                        ${{ formatcurrency(expiryamount) }}
                                   </div>
                                   <div
                                        align="center"
                                        justify="center"
                                        class="text1_color--text"
                                   >
                                        {{ $t('customer.overdue') }}
                                   </div>
                              </v-col>
                              <v-col cols="4">
                                   <div
                                        align="center"
                                        justify="center"
                                        class="text-h4 text1_color--text"
                                   >
                                        ${{ formatcurrency(currentamount) }}
                                   </div>
                                   <div
                                        align="center"
                                        justify="center"
                                        class="text1_color--text"
                                   >
                                        {{ $t('customer.outstanding') }}
                                   </div>
                              </v-col>
                              <v-col cols="4"> </v-col>
                         </v-row>
                    </v-container>
               </div>

               <div class="heading_bg mx-4 my-2 rounded">
                    <v-container
                         fluid
                         v-if="displaysearchbar"
                         class="d-flex flex-row justify-space-between"
                    >
                         <div id="searchbox" class="d-flex flex-row">
                              <v-text-field
                                   single-line
                                   :label="$t('customer.searchplaceholder')"
                                   v-model="searchcontent"
                                   prepend-inner-icon="mdi-magnify"
                                   outlined
                                   style="width: 400px"
                                   background-color="white"
                                   dense
                                   hide-details
                                   clearable
                                   clear-icon="mdi-close"
                                   @keydown.enter.prevent="setFilter"
                                   ref="searchtextfield"
                              >
                                   <template v-slot:append>
                                        <v-btn icon @click="triggerAdvFilter">
                                             <v-badge
                                                  :content="asearchnotify"
                                                  :value="asearchnotify"
                                                  color="red"
                                                  overlap
                                             >
                                                  <v-icon small color="primary"
                                                       >mdi-filter-plus</v-icon
                                                  >
                                             </v-badge>
                                        </v-btn>
                                   </template></v-text-field
                              >
                              <v-btn icon @click="openQr()" color="primary">
                                   <v-icon small>mdi-qrcode-scan</v-icon>
                              </v-btn>
                         </div>

                         <div>
                              <v-btn
                                   color="primary"
                                   class="body-2 font-weight-bold mx-2"
                                   @click="onClickSetFilter"
                              >
                                   <span>{{ $t('global.filter') }}</span>
                              </v-btn>
                              <v-btn
                                   color="grey"
                                   class="body-2 font-weight-bold"
                                   text
                                   @click="resetFilter"
                              >
                                   <span class="grey--text">{{
                                        $t('global.cancel')
                                   }}</span>
                              </v-btn>
                         </div>
                    </v-container>
                    <v-progress-linear
                         indeterminate
                         v-if="searchloading"
                    ></v-progress-linear>
               </div>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="5">
                                   <span
                                        class="caption heading_color--text ml-7"
                                        >{{ $t('customer.name') }}</span
                                   >
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text">{{
                                        $t('customer.phone')
                                   }}</span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">{{
                                        $t('customer.tag')
                                   }}</span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">{{
                                        $t('customer.status')
                                   }}</span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(customer, index) in customerList"
                         :key="customer._id"
                         class="px-3"
                         align="center"
                    >
                         <v-col cols="5" class="d-flex">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-3 my-auto"
                                   style="overflow: visible;"
                              >
                                   <span
                                        class="heading_color--text"
                                        style="font-size:9px !important;"
                                   >
                                        {{(page - 1) * 5 + index + 1}}
                                   </span>
                              </v-avatar>
                              <div class="d-flex flex-column">
                                   <div
                                        class="primary--text"
                                        @click="goToCustomer(customer)"
                                   >
                                        {{ customer.name }}
                                   </div>
                                   <div
                                        v-if="customer.company"
                                        style="margin-top: -9px"
                                   >
                                        <span
                                             class="text2_color--text text-caption"
                                             >{{ customer.company }}</span
                                        >
                                   </div>
                                   <div
                                        class="text1_color--text text-caption"
                                        v-if="customer.member[0]"
                                   >
                                        {{
                                             customer.member[0]
                                                  .customer_category_name
                                        }}
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="3">
                              <div class="text1_color--text text-body-2">
                                   {{ formatPhone(customer.phone) }}
                              </div>
                              <div class="text2_color--text text-caption">
                                   {{ customer.email }}
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div v-if="customer.tag">
                                   <span
                                        v-for="(tag, index) in customer.tag"
                                        :key="index"
                                   >
                                        <v-chip class="ma-1" label x-small>
                                             {{ tag }}
                                        </v-chip>
                                   </span>
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div class="d-flex justify-space-between">
                                   <div class="center">
                                        <v-icon
                                             small
                                             :color="
                                                  customer.status == 'active'
                                                       ? 'primary'
                                                       : customer.status ==
                                                         'inactive'
                                                       ? 'red'
                                                       : 'orange'
                                             "
                                        >
                                             {{
                                                  customer.status == 'active'
                                                       ? 'mdi-checkbox-marked-circle-outline'
                                                       : customer.status ==
                                                         'inactive'
                                                       ? 'mdi-cancel'
                                                       : 'mdi-progress-check'
                                             }}</v-icon
                                        >
                                   </div>
                                   <v-menu offset-y>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  v-if="
                                                       customer.status !=
                                                       'verify'
                                                  "
                                                  link
                                                  @click="
                                                       modifyRecord(
                                                            customer._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-pencil-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t('global.modify')
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>

                                             <v-list-item
                                                  v-if="
                                                       customer.status ==
                                                       'active'
                                                  "
                                                  link
                                                  @click="
                                                       AddInvoice(customer._id)
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-file-plus</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t(
                                                                 'customer.createinvoice'
                                                            )
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>

                                             <v-list-item
                                                  v-if="
                                                       customer.status ==
                                                       'active'
                                                  "
                                                  link
                                                  @click="
                                                       setmodifycustomer(
                                                            customer
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-account-key</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t(
                                                                 'customer.modifylogin'
                                                            )
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>

                                             <v-list-item
                                                  v-if="
                                                       customer.status ==
                                                       'active'
                                                  "
                                                  link
                                                  @click="
                                                       suspendRecord(
                                                            customer._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-cancel</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t(
                                                                 'customer.suspendaccount'
                                                            )
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>

                                             <v-list-item
                                                  v-else
                                                  link
                                                  @click="
                                                       activeRecord(
                                                            customer._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-check-circle-outline</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t(
                                                                 'customer.activeaccount'
                                                            )
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>

                                             <v-list-item
                                                  link
                                                  @click="
                                                       deleteRecord(
                                                            customer._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-delete-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{
                                                            $t('global.delete')
                                                       }}</v-list-item-title
                                                  >
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <ConfirmDialog ref="confirm" />

          <v-dialog v-model="dialog" persistent max-width="790px">
               <v-card class="pa-0">
                    <CustomerAddComponent
                         v-if="dialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                    />
               </v-card>
          </v-dialog>

          <v-dialog v-model="editdialog" persistent max-width="790px">
               <v-card class="pa-0">
                    <CustomerAddComponent
                         v-if="editdialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                         :_id="selectedid"
                    />
               </v-card>
          </v-dialog>

          <v-dialog v-model="importdialog" persistent max-width="790px">
               <v-card class="pa-0">
                    <CustomerImportDialog
                         v-if="importdialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                    />
               </v-card>
          </v-dialog>

          <v-dialog
               v-if="qrDialog"
               v-model="qrDialog"
               persistent
               max-width="550px"
          >
               <div class="white rounded elevation-3 text-center">
                    <!--<qrcode-stream @decode="onDecodeQR"></qrcode-stream>-->
                    <StreamBarcodeReader
                         @decode="onDecodeQR"
                    ></StreamBarcodeReader>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="qrDialog = false"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </div>
          </v-dialog>

          <v-dialog v-model="logindialog" persistent max-width="500px">
               <div class="white rounded elevation-3 text-center">
                    <div class="pa-5">{{ $t('customer.modifylogin') }}</div>
                    <v-divider></v-divider>
                    <div
                         class="pa-3"
                         style="font-size:15px; color:var(--v-badge_primary_text-base)"
                    >
                         {{ $t('customer.modifyinfomsg1') }}
                    </div>
                    <div class="text1_color--text text-h5 pt-3">
                         {{ modify_customer.name }}
                         <v-icon
                              small
                              :color="
                                   modify_customer.status == 'active'
                                        ? 'primary'
                                        : 'red'
                              "
                              >{{
                                   modify_customer.status == 'active'
                                        ? 'mdi-checkbox-marked-circle-outline'
                                        : 'mdi-cancel'
                              }}</v-icon
                         >
                    </div>
                    <div class="text2_color--text text-body-1">
                         {{ modify_customer.company }}
                    </div>
                    <div class="pa-3">
                         <v-text-field
                              v-model="new_phone"
                              class="pa-2"
                              :label="$t('customer.phone')"
                              type="number"
                              outlined
                              dense
                              hide-details
                         ></v-text-field>
                         <div class="d-flex flex-row">
                              <v-text-field
                                   v-if="!new_password"
                                   class="pa-2"
                                   :label="$t('global.password')"
                                   value="*******"
                                   readonly
                                   type="password"
                                   outlined
                                   dense
                                   hide-details
                              ></v-text-field>
                              <v-text-field
                                   v-else
                                   v-model="new_password"
                                   class="pa-2"
                                   :label="$t('customer.newpassword')"
                                   readonly
                                   outlined
                                   dense
                                   hide-details
                              ></v-text-field>
                              <v-btn
                                   color="warning"
                                   class="ma-2"
                                   @click="generatepassword"
                                   ><v-icon left> mdi-lock-reset </v-icon
                                   >{{ $t('customer.generate') }}
                              </v-btn>
                         </div>
                         <v-switch
                              v-model="new_enable_member_login"
                              class="pl-2 mt-1"
                              inset
                              :label="$t('customer.allowlogin')"
                              dense
                              hide-details
                         ></v-switch>

                         <v-divider class="mt-5"></v-divider>
                         <div
                              class="pt-5"
                              style="font-size:15px; color:var(--v-badge_primary_text-base)"
                         >
                              {{ $t('customer.modifyinfomsg2') }}
                         </div>
                         <v-text-field
                              v-model="auth_password"
                              class="pa-2"
                              :label="$t('customer.superpassword')"
                              type="password"
                              outlined
                              dense
                              :hint="$t('global.required')"
                              persistent-hint
                         ></v-text-field>
                    </div>
                    <v-divider></v-divider>
                    <v-container fluid>
                         <v-row class="pa-5">
                              <v-spacer></v-spacer>
                              <v-btn
                                   color="primary"
                                   class="body-2 font-weight-bold"
                                   @click="updateLogin"
                              >
                                   {{ $t('global.update') }}
                              </v-btn>
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <v-btn
                                   color="grey"
                                   text
                                   class="body-2 font-weight-bold"
                                   @click="logindialog = false"
                              >
                                   {{ $t('global.cancel') }}
                              </v-btn>
                         </v-row>
                    </v-container>
               </div>
          </v-dialog>

          <v-dialog
               v-model="filterdialog"
               max-width="350px"
               overlay-opacity="0"
               transition="dialog-top-transition"
          >
               <div class="white rounded elevation-3 text-center">
                    <div class="heading_color--text pa-3">
                         {{ $t('customer.advancesearch') }}
                    </div>

                    <v-divider></v-divider>
                    <div class="text-center pa-3">
                         <v-text-field
                              v-model="filtercustomer"
                              prepend-inner-icon="mdi-account"
                              :label="$t('customer.name')"
                              outlined
                              dense
                              hide-details
                              class="pa-2"
                         ></v-text-field>
                         <v-text-field
                              v-model="filterphone"
                              prepend-inner-icon="mdi-phone"
                              :label="$t('customer.phone')"
                              outlined
                              dense
                              hide-details
                              class="pa-2"
                         ></v-text-field>
                         <v-text-field
                              v-model="filteremail"
                              prepend-inner-icon="mdi-email"
                              :label="$t('customer.email')"
                              outlined
                              dense
                              hide-details
                              class="pa-2"
                         ></v-text-field>
                         <v-select
                              :label="$t('customer.sales')"
                              v-model="filteruser"
                              :items="apiUserList"
                              item-text="name"
                              item-value="_id"
                              class="pa-2"
                              outlined
                              hide-details
                              dense
                              return-object
                              clearable
                         ></v-select>
                         <v-text-field
                              v-model="filtertag"
                              prepend-inner-icon="mdi-tag"
                              :label="$t('customer.tag')"
                              :placeholder="$t('customer.fulltagplaceholder')"
                              outlined
                              dense
                              hide-details
                              class="pa-2"
                         ></v-text-field>

                         <v-container fluid>
                              <v-row class="pa-5">
                                   <v-spacer></v-spacer>
                                   <v-btn
                                        color="primary"
                                        class="body-2 font-weight-bold mx-2"
                                        @click="setAdvFilter"
                                   >
                                        <span>{{ $t('global.filter') }}</span>
                                   </v-btn>
                                   <v-btn
                                        color="grey"
                                        text
                                        class="text-body-2 font-weight-bold"
                                        @click="closeAdvFilter"
                                   >
                                        {{ $t('global.close') }}
                                   </v-btn>
                              </v-row>
                         </v-container>
                    </div>
               </div>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               {{ $t('global.successPrefix') }}:
               <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               {{ $t('global.failPrefix') }}:
               <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import BrowserSession from '@/utils/BrowserSession';
     import CustomerService from '@/utils/api/CustomerService';
     import InvoiceService from '@/utils/api/InvoiceService';
     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import CustomerAddComponent from './CustomerAddView';
     import CustomerImportDialog from '../components/CustomerImportDialog.vue';

          //import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
     import StreamBarcodeReader from 'vue-barcode-reader/src/components/StreamBarcodeReader.vue';
     import JsonToCSV from '@/utils/JsonToCSV';
     import Permission from '@/utils/Permission';

          //import _ from 'lodash';
          var sampleSize = require('lodash/sampleSize');
          var isEmpty = require('lodash/isEmpty');

          const numformat = new Intl.NumberFormat('en-US', {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });

          const moment = require('moment');
          const success_sound = require('@/assets/beep.mp3');

          export default {
               data() {
                    return {
                         pagekey: ['xDWyh'],
                         managekey: ['HVvyR'],
                         customerList: [],
                         noOfPage: 0,
                         page: 1,
                         recordperpage: 5,

                         expiryamount: 0,
                         currentamount: 0,

                         dialog: false,
                         editdialog: false,
                         importdialog: false,
                         qrDialog: false,
                         logindialog: false,
                         filterdialog: false,

                         is_fail: false,
                         is_success: false,
                         error_message: '',
                         success_message: '',

                         selectedid: '',

                         filtering: false,
                         advfiltering: false,
                         displaysearchbar: true,
                         searchcontent: '',
                         searchloading: false,
                         searchbytype: 'phone',

                         modify_customer: {},
                         new_phone: null,
                         new_enable_member_login: null,
                         new_password: null,
                         auth_password: null,

                         apiUserList: [],
                         filtercustomer: null,
                         filterphone: null,
                         filteremail: null,
                         filteruser: null,
                         filtertag: null,
                         asearchnotify: 0,

                         ssound: null,
                    };
               },
               methods: {
                    apiGetStatistic() {
                         InvoiceService.getExpiryAmount()
                              .then((response) => {
                                   //console.log(response.data);
                                   if (response.data.status == 'success')
                                        this.expiryamount =
                                             response.data.data.unpaidamount || 0;
                              })
                              .catch((error) => {
                                   this.expiryamount = 0;
                              });
                         InvoiceService.getCurrentAmount()
                              .then((response) => {
                                   //console.log(response.data);
                                   if (response.data.status == 'success')
                                        this.currentamount =
                                             response.data.data.unpaidamount || 0;
                              })
                              .catch((e) => {
                                   this.currentamount = 0;
                              });
                    },
                    apiGetCustomerList() {
                         CustomerService.getAll(this.page, this.recordperpage)
                              .then((response) => {
                                   if (response.data.status == 'success')
                                        this.customerList = response.data.data;
                                   let rs = response.data.recordcount;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              })
                              .catch((e) => {
                                   console.log(e);
                              });
                    },
                    apiGetUserList() {
                         BrowserSession.getSession('user', (data) => {
                              this.apiUserList = data.filter((d) => {
                                   return d.status == 'active';
                              });
                         });
                    },
                    formatDate(value) {
                         return moment(value).format('YYYY-MM-DD');
                    },
                    formatPhone(phone) {
                         return phone.slice(0, 4) + ' ' + phone.slice(4);
                    },
                    async deleteRecord(_id) {
                         if (
                              !Permission.isPageHavePermission(
                                   BrowserSession.getSession('config')['disabled_page'],
                                   this.managekey
                              )
                         ) {
                              this.error_message = this.$t('global.permissionerrmsg');
                              this.is_fail = true;
                              return;
                         }

                         if (
                              await this.$refs.confirm.open(
                                   this.$t('global.confirm'),
                                   this.$t('components.confirmdeletemsg')
                              )
                         ) {
                              CustomerService.deleteCustomer(_id)
                                   .then(() => {
                                        this.success_message = this.$t(
                                             'global.successPrefix'
                                        );
                                        this.is_success = true;
                                        //this.apiGetRecordsCount();
                                        this.apiGetCustomerList();
                                   })
                                   .catch((e) => {
                                        if (e.response.status == 400) {
                                             ///console.log(e.response);
                                             this.error_message = e.response.data.message;
                                        } else {
                                             this.error_message = e.message;
                                        }

                                        this.is_fail = true;
                                   });
                         }
                    },
                    showAddCustomerDialog() {
                         if (
                              !Permission.isPageHavePermission(
                                   BrowserSession.getSession('config')['disabled_page'],
                                   this.managekey
                              )
                         ) {
                              this.error_message = this.$t('global.permissionerrmsg');
                              this.is_fail = true;
                              return;
                         }

                         this.dialog = true;
                    },
                    onClickSetFilter(){
                         if(this.advfiltering){
                              this.page = 1;
                              this.setAdvFilter();
                              return;
                         }

                         if(isEmpty(this.searchcontent)){
                              this.is_fail = true;
                              this.error_message = '請輸入搜索內容';
                              return;
                         }
                         this.page = 1;
                         this.setFilter();
                    },
                    setFilter() {
                         this.filtering = true;
                         this.advfiltering = false;
                         this.searchloading = true;
                         this.asearchnotify = 0;

                         CustomerService.searchCustomer(this.page, this.recordperpage,
                                                       { value: this.searchcontent })
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.customerList = response.data.data;

                                        //console.log(this.customerList);

                                        let rs = response.data.recordcount;
                                        let reminder = rs % this.recordperpage;
                                        if (reminder > 0) reminder = 1;
                                        let ps = Math.floor(rs / this.recordperpage);
                                        this.noOfPage = reminder + ps;
                                   }
                              })
                              .catch((e) => {
                                   console.log(e);
                                   this.is_fail = true;
                                   this.error_message = 'Set filter error!';
                              })
                              .finally(() => {
                                   this.searchloading = false;
                              });

                         this.$refs.searchtextfield.blur();
                    },
                    resetFilter() {
                         this.searchcontent = null;
                         this.filtering = false;
                         this.advfiltering = false;

                         this.asearchnotify = 0;
                         this.filtercustomer = null;
                         this.filteruser = null;
                         this.filterphone = null;
                         this.filtertag = null;
                         this.filteremail = null;

                         this.apiGetCustomerList();
                    },
                    onClickCancel() {
                         this.dialog = false;
                         this.editdialog = false;
                         this.importdialog = false;
                    },
                    onClickInput(msg) {
                         this.success_message = msg;
                         this.is_success = true;

                         //this.apiGetRecordsCount();
                         this.apiGetCustomerList();
                         this.dialog = false;
                         this.editdialog = false;
                         this.importdialog = false;
                    },
                    onFail(msg) {
                         this.error_message = msg;
                         this.is_fail = true;

                         this.dialog = false;
                         this.editdialog = false;
                         this.importdialog =false;
                    },
                    onChangePage() {
                         if (this.filtering) this.setFilter();
                         else if(this.advfiltering) this.setAdvFilter();
                         else this.apiGetCustomerList();
                    },
                    modifyRecord(_id) {
                         if (
                              !Permission.isPageHavePermission(
                                   BrowserSession.getSession('config')['disabled_page'],
                                   this.managekey
                              )
                         ) {
                              this.error_message =
                                   'Sorry, you dont have permission to access!';
                              this.is_fail = true;
                              return;
                         }

                         this.selectedid = _id;
                         this.editdialog = true;
                    },
                    AddInvoice(id) {
                         this.$router.push({ name: 'invoiceadd', params: { cid: id } });
                    },
                    updateLogin() {
                         CustomerService.updateLoginDetail({
                              _id: this.modify_customer._id,
                              phone: this.new_phone,
                              password: this.new_password,
                              enable_member_login: this.new_enable_member_login,
                              auth_password: this.auth_password,
                         })
                              .then((response) => {
                                   //console.log(response);
                                   this.logindialog = false;
                                   this.is_success = true;
                                   this.success_message =
                                        'Customer ' +
                                        this.modify_customer.name +
                                        ' login information updated';
                                   this.apiGetCustomerList();
                              })
                              .catch((e) => {
                                   console.log(e);
                                   this.is_fail = true;
                                   this.error_message = 'Update customer fail';
                              });
                    },
                    async suspendRecord(id) {
                         if (
                              await this.$refs.confirm.open(
                                   this.$t('global.confirm'),
                                   '你要停用帳戶嗎？'
                              )
                         ) {
                              CustomerService.updateCustomerStatus({
                                   _id: id,
                                   status: 'inactive',
                              })
                                   .then((response) => {
                                        if (response.data.status == 'success') {
                                             //reload list
                                             this.apiGetCustomerList();
                                        }
                                   })
                                   .catch((e) => {
                                        this.is_fail = true;
                                        this.error_message = 'Update failed';
                                   });
                         }
                    },
                    async activeRecord(id) {
                         if (
                              await this.$refs.confirm.open(
                                   this.$t('global.confirm'),
                                   '你要啟用帳戶嗎？'
                              )
                         ) {
                              CustomerService.updateCustomerStatus({
                                   _id: id,
                                   status: 'active',
                              })
                                   .then((response) => {
                                        if (response.data.status == 'success') {
                                             //reload list
                                             this.apiGetCustomerList();
                                        }
                                   })
                                   .catch((e) => {
                                        this.is_fail = true;
                                        this.error_message = 'Update failed';
                                   });
                         }
                    },
                    goToCustomer(customer) {
                         if(customer.status != 'verify'){
                              let routeData = this.$router.resolve({ name: 'customerdetail',
                                   query: { id: customer._id },
                              });
                              window.open(routeData.href, '_blank');
                         }else{
                              this.is_fail = true;
                              this.error_message = '帳戶仍未啟動'
                         }
                    },
                    generatepassword() {
                         this.new_password = sampleSize( '0123456789', 6).join('');
                    },
                    setmodifycustomer(obj) {
                         this.modify_customer = obj;
                         this.new_phone = obj.phone;
                         this.new_enable_member_login = obj.enable_member_login;
                         this.new_password = null;
                         this.auth_password = null;
                         this.logindialog = true;
                    },
                    onExportCSV() {
                         if (
                              !Permission.isPageHavePermission(
                                   BrowserSession.getSession('config')['disabled_page'],
                                   this.managekey
                              )
                         ) {
                              this.error_message =
                                   'Sorry, you dont have permission to access!';
                              this.is_fail = true;
                              return;
                         }
                         JsonToCSV.export('customerlist.csv', 'customer');
                    },
                    onImportCSV(){
                         this.importdialog = true;
                    },
                    formatcurrency(total) {
                         return numformat.format(total);
                    },
                    searchby(type) {
                         this.searchbytype = type;
                    },
                    openQr(){
                         this.ssound = new Audio();
                         this.ssound.autoplay = true;
                         this.ssound.src = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
                         this.ssound.load();
                         this.qrDialog = true;
                    },
                    onDecodeQR(decodedString) {
                         //console.log(decodedString);

                         this.filtering = true;
                         this.advfiltering = false;
                         this.searchloading = true;
                         CustomerService.getCustomer(decodedString)
                              .then((response) => {
                                   this.ssound.src = success_sound;

                                   //new Audio(success_sound).play();
                                   if (response.data.status == 'success' && !isEmpty(response.data.data)) {
                                        //console.log(this.customerList);

                                        this.customerList = [response.data.data];
                                        //console.log(this.customerList);

                                        let rs = this.customerList.length;
                                        let reminder = rs % this.recordperpage;
                                        if (reminder > 0) reminder = 1;
                                        let ps = Math.floor(rs / this.recordperpage);
                                        this.noOfPage = reminder + ps;

                                        this.is_success = true;
                                        this.success_message = 'Get customer done';
                                   }else{
                                        throw new Error('no data or fail')
                                   }
                              })
                              .catch((e) => {
                                   console.log(e);
                                   this.is_fail = true;
                                   this.error_message = 'Cannot get this customer';
                              })
                              .finally(() => {
                                   this.searchloading = false;
                              });
                         this.qrDialog = false;
                    },
                    havePermit() {
                         return Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.pagekey
                         );
                    },
                    triggerAdvFilter() {
                         //console.log('click append!');
                         this.filterdialog = true;
                    },
                    setAdvFilter() {
                         this.searchcontent = null;
                         this.advfiltering = true;
                         this.filtering = false;

                         let query = {};
                         if (!isEmpty(this.filtercustomer)) {
                              //console.log(this.filtercustomer);
                              query = {
                                   ...query,
                                   ...{ customername: this.filtercustomer },
                              };
                              this.asearchnotify = 1;
                         }
                         if (!isEmpty(this.filteruser)) {
                              //console.log(this.filteruser);
                              query = {
                                   ...query,
                                   ...{ salesid: this.filteruser._id },
                              };
                              this.asearchnotify = 1;
                         }
                         if (!isEmpty(this.filterphone)) {
                              //console.log(this.filterphone);
                              query = {
                                   ...query,
                                   ...{ customerphone: this.filterphone },
                              };
                              this.asearchnotify = 1;
                         }
                         if (!isEmpty(this.filteremail)) {
                              //console.log(this.filterphone);
                              query = {
                                   ...query,
                                   ...{ customeremail: this.filteremail },
                              };
                              this.asearchnotify = 1;
                         }
                         if (!isEmpty(this.filtertag)) {
                              //console.log(this.filtertag);
                              query = { ...query, ...{ tag: this.filtertag } };
                              this.asearchnotify = 1;
                         }

                         this.$refs.searchtextfield.blur();
                         this.filterdialog = false;
                         if (this.asearchnotify != 1) return;

                         CustomerService.advsearchCustomer(
                              this.page,
                              this.recordperpage,
                              query
                         )
                              .then((response) => {
                                   this.customerList = [];
                                   //console.log(response.data);

                                   if (response.data.status == 'success') {
                                        this.customerList = response.data.data;
                                        //console.log(this.customerList);

                                        let rs = response.data.recordcount;
                                        let reminder = rs % this.recordperpage;
                                        if (reminder > 0) reminder = 1;
                                        let ps = Math.floor(rs / this.recordperpage);
                                        this.noOfPage = reminder + ps;
                                   }
                              })
                              .catch((e) => {
                                   console.log(e);
                              });
                    },
                    closeAdvFilter() {
                         this.filterdialog = false;
                    },
               },
               watch: {},
               created() {
                    this.apiGetStatistic();
                    //this.apiGetRecordsCount();
                    this.apiGetCustomerList();
                    this.apiGetUserList();
               },
               components: {
         ConfirmDialog,
         CustomerAddComponent,
         CustomerImportDialog,
         //QrcodeStream,
         //QrcodeDropZone,
         //QrcodeCapture,
         StreamBarcodeReader,
         CustomerImportDialog
     },
          };
</script>

<style>
     .tc0 {
          background-color: white;
     }
     .tc1 {
          background-color: var(--v-post_bg-base) !important;
     }
     .v-chip.theme--light.active {
          background: var(--v-badge_primary_bg-base) !important;
          color: var(--v-badge_primary_text-base);
     }
     .v-chip.theme--light.inactive {
          background: var(--v-badge_secondary_bg-base) !important;
          color: var(--v-badge_secondary_text-base);
     }
     .amList .row {
          border-bottom-color: #f3f3f3;
          border-bottom-width: 1px;
          border-bottom-style: solid;
     }
     #menu-list-btn01 {
          margin-right: 1px !important;
     }
     #menu-list-btn02 {
          margin-right: 1px !important;
     }

     #searchbox .v-input__control .v-input__slot {
          min-height: auto !important;
          display: flex !important;
          align-items: center !important;
     }
     .v-input__prepend-inner {
          margin: auto !important;
     }
     #searchbox label {
          top: 6px !important;
     }
     #searchbox .v-input__append-inner {
          margin: auto !important;
     }
     #searchbox button {
          font-size: 16px;
     }
</style>
