import http from '../HttpConn';
var isObject = require('lodash/isObject');

class CustomerGroupService {
     /* eslint-disable */
     getCount() {
          return http.get('/customergroup/count');
     }
     getAll(page = 1, limit = 99999) {
          return http.get('/customergroup', {
               params: { page: page, limit: limit },
          });
     }
     getAllAvailable(page = 1, limit = 99999) {
          return http.get('/customergroup/allAvailable', {
               params: { page: page, limit: limit },
          });
     }

     getCustomerGroup(id) {
          return http.get('/customergroup/' + id);
     }

     updateCustomerGroup(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/customergroup/update/' + ccobj._id, ccobj);
     }
     addCustomerGroup(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/customergroup/add', ccobj);
     }
     deleteCustomerGroup(id) {
          return http.delete('/customergroup/delete/' + id);
     }
     deleteCustomerGroupSegment(sname) {
          return http.delete('/customergroup/deletesegment/' + sname);
     }

     importCustomerGroupCustomer(gid, ccobj) {
          //ccobj contain list of customers id
          return http.post('/customergroup/importcustomer/' + gid, ccobj);
     }
     segmentation(id) {
          return http.get('/customergroup/segmentation/' + id);
     }
     /* eslint-enable */
}
export default new CustomerGroupService();
