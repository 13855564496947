<template>
     <div class="pa-7">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="heading_color--text">
                              {{ $t('components.commissionsetting') }}
                         </span>
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                   >
                                        {{ $t('global.morefunction') }}
                                        <v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                             >
                                                  {{ $t('global.import') }}
                                             </v-list-item-title>
                                        </v-list-item-content>
                                   </v-list-item>

                                   <v-list-item link @click="onExportCSV()">
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{ $t('global.export') }}
                                        </v-list-item-title>
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addRecord()"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">
                                   {{ $t('global.add') }}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="6 my-auto">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{ $t('setting.commissionname')}}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('setting.salesrange') }}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('setting.getcommission') }}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('setting.status') }}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(commission, index) in commissionList"
                         :key="commission._id"
                         class="px-3 fill-height align-center"
                    >
                         <v-col cols="6" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="my-auto mx-5"
                              >
                                   <span class="caption heading_color--text">{{
                                        (page - 1) * 5 + index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="my-auto mx-3">
                                   <span
                                        class="text-decoration-none text-subtitle-1"
                                        >{{ commission.name }}</span
                                   >
                                   <div class="text2_color--text text-caption">
                                        <pre style="line-height: 0.8rem">{{
                                             commission.type == 's'
                                                  ? 'Simple'
                                                  : 'Progression'
                                        }}</pre>
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="2" class="my-auto">
                              <span class="text1_color--text text-body-2"
                                   >{{ commission.min_range }} -
                                   {{ commission.max_range }}</span
                              >
                         </v-col>

                         <v-col cols="2" class="my-auto">
                              <span class="text1_color--text text-body-2"
                                   >{{
                                        commission.commission_type == 'f'
                                             ? '$'
                                             : ''
                                   }}
                                   {{ commission.commission }}
                                   {{
                                        commission.commission_type ==
                                        'p'
                                             ? '%'
                                             : ''
                                   }}</span
                              >
                         </v-col>

                         <v-col cols="2">
                              <div
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <v-icon
                                        small
                                        :color="
                                             commission.status == 'active'
                                                  ? 'primary'
                                                  : 'red'
                                        "
                                   >
                                        {{
                                             commission.status == 'active'
                                                  ? 'mdi-checkbox-marked-circle-outline'
                                                  : 'mdi-cancel'
                                        }}
                                   </v-icon>

                                   <v-menu offset-y>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       modifyRecord(
                                                            commission._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-pencil-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{$t('global.modify')}}</v-list-item-title
                                                  >
                                             </v-list-item>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       deleteRecord(
                                                            commission._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-delete-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{$t('global.delete')}}</v-list-item-title
                                                  >
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-snackbar
                    v-model="is_success"
                    color="primary accent-2"
                    elevation="24"
               >
                    Success: <strong>{{ this.success_message }}</strong>
               </v-snackbar>
               <v-snackbar
                    v-model="is_fail"
                    color="red accent-2"
                    elevation="24"
               >
                    Failed: <strong>{{ this.error_message }}</strong>
               </v-snackbar>

               <ConfirmDialog ref="confirm" />

               <v-dialog v-model="commissionDialog" max-width="400px">
                    <v-card class="pa-0">
                         <CommissionAddDialog
                              v-if="commissionDialog"
                              :_id="selectedid"
                              @onClickCancel="cancelInput"
                              @onClickInput="successInput"
                              @onFailAction="failInput"
                         />
                    </v-card>
               </v-dialog>
          </div>
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import CommissionService from '@/utils/api/CommissionService';
     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import JsonToCSV from '@/utils/JsonToCSV';
     import Permission from '@/utils/Permission';
     import CommissionAddDialog from './CommissionAddView.vue';

     const moment = require('moment');

     export default {
          data: () => ({
               pagekey: ['ukWQg'],
               managekey: ['ukWQg'],

               commissionList: [],
               noOfPage: 0,
               page: 1,
               recordperpage: 5,

               commissionDialog: false,
               selectedid: null,

               is_success: false,
               is_fail: false,
               success_message: '',
               error_message: '',
          }),
          methods: {
               apiGetRecordsCount() {
                    CommissionService.getCount()
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let rs = response.data.data;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetCommissionList() {
                    CommissionService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              //console.log(response.data);

                              if (response.data.status == 'success'){
                                   this.commissionList = response.data.data;
                                   //console.log(this.commissionList);
                              }
                              let rs = response.data.recordcount;
                              let reminder = rs % this.recordperpage;
                              if (reminder > 0) reminder = 1;
                              let ps = Math.floor(rs / this.recordperpage);
                              this.noOfPage = reminder + ps;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               onExportCSV() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    JsonToCSV.export('commissionlist.csv', 'commission');
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               addRecord(){
                    this.selectedid = null;
                    this.commissionDialog = true;
               },
               // eslint-disable-next-line
               modifyRecord(_id) {
                    this.selectedid = _id;
                    this.commissionDialog = true;
               },
               // eslint-disable-next-line
               async deleteRecord(id) {
                    if (
                         await this.$refs.confirm.open(
                              '確定操作',
                              '你是否要刪除此項？刪除後不能復原'
                         )
                    ) {
                         CommissionService.deleteCommission(id)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.success_message = '成功刪除';
                                        this.is_success = true;
                                        this.apiGetCommissionList();
                                   }
                              })
                              .catch((e) => {
                                   console.log(e);
                                   this.error_message = e.message;
                                   this.is_fail = true;
                              });
                    }
               },
               onChangePage() {
                    this.apiGetCommissionList();
               },
               successInput(){
                    this.apiGetCommissionList();

                    this.commissionDialog =false;
                    this.selectedid = null;
               },
               failInput(){
                    this.commissionDialog =false;
                    this.selectedid = null;
               },
               cancelInput(){
                    this.commissionDialog =false;
                    this.selectedid = null;
               },


          },
          created() {
               this.apiGetCommissionList();
          },
          components: {
               ConfirmDialog,
               CommissionAddDialog,
          },
     };
</script>
