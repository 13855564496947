<template>
     <div class="pa-7">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">
                              {{ $t('components.setting') }}
                         </span>
                         <v-spacer></v-spacer>
                         <v-menu offset-y> </v-menu>
                    </v-row>
               </v-container>

               <v-divider></v-divider>

               <v-tabs vertical style="align-items: start;">
                    <v-tab
                         :disabled="!config.enable_setting_tab_company"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-domain </v-icon>
                         {{$t('components.companysetting')}}
                    </v-tab>
                    <v-tab
                         :disabled="!config.enable_setting_tab_resource"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-cards </v-icon>
                         {{$t('components.resourcesetting')}}
                    </v-tab>
                    <v-tab
                         :disabled="!config.enable_setting_tab_commission"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-currency-usd </v-icon>
                         {{$t('components.commissionsetting')}}
                    </v-tab>
                    <v-tab
                         :disabled="!config.enable_setting_tab_template"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-eye </v-icon>
                         {{$t('components.templatesetting')}}
                    </v-tab>
                    <v-tab
                         :disabled="!config.enable_setting_tab_notification"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-bell </v-icon>
                         {{$t('components.notificationsetting')}}
                    </v-tab>
                    <v-tab
                         :disabled="!config.enable_setting_tab_api"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-api </v-icon>
                         {{$t('components.apisetting')}}
                    </v-tab>
                    <v-tab
                         :disabled="!config.enable_setting_tab_media"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-multimedia</v-icon>
                         {{$t('components.mediasetting')}}
                    </v-tab>
                    <v-tab
                         :disabled="!config.enable_setting_tab_other"
                         style="justify-content: start; width: 100%;"
                    >
                         <v-icon left> mdi-table-cog </v-icon>
                         {{$t('components.othersetting')}}
                    </v-tab>

                    <v-tab-item>
                         <CompanyComponent />
                    </v-tab-item>

                    <v-tab-item>
                         <ResourceComponent />
                    </v-tab-item>

                    <v-tab-item>
                         <CommissionComponent />
                    </v-tab-item>

                    <v-tab-item>
                         <TemplateComponent />
                    </v-tab-item>

                    <v-tab-item>
                         <NotificationComponent />
                    </v-tab-item>

                    <v-tab-item>
                         <APIComponent />
                    </v-tab-item>

                    <v-tab-item>
                         <MediaComponent />
                    </v-tab-item>

                    <v-tab-item>
                         <OtherComponent />
                    </v-tab-item>
               </v-tabs>
          </div>
     </div>
</template>

<script>
     import CompanyComponent from './CompanyView';
     import ResourceComponent from './ResourceView';
     import CommissionComponent from './CommissionView';
     import OtherComponent from './OtherView.vue';
     import TemplateComponent from './TemplateView.vue';
     import APIComponent from './APIView.vue';
     import MediaComponent from './MediaView.vue';
     import NotificationComponent from './NotificationView.vue';

     import config from '@/config/config';

     export default {
          data: () => ({
               config: config,
          }),
          methods: {},
          created() {
          },
          components: {
               CompanyComponent,
               ResourceComponent,
               CommissionComponent,
               OtherComponent,
               TemplateComponent,
               APIComponent,
               MediaComponent,
               NotificationComponent,
          },
     };
</script>
