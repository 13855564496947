<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span v-if="_id" class="heading_color--text">{{
                         $t('global.modify')
                    }}</span>
                    <span v-else class="heading_color--text">{{
                         $t('global.newrecord')
                    }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>

          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-10 py-5 post_bg"
          >
               <div class="pa-2">
                    <v-text-field
                         v-model="name"
                         :label="$t('setting.commissionname')"
                         hide-details
                         outlined
                         dense
                         background-color="#ffffff"
                    ></v-text-field>
               </div>
               <div class="pa-2">
                    <v-select
                         v-model="type"
                         :items="typeList"
                         :label="$t('setting.type')"
                         outlined
                         dense
                         persistent-hint
                         :hint="$t('setting.commissionhint')"
                         background-color="#ffffff"
                    >
                    </v-select>
               </div>
               <div class="pa-2 d-flex flex-row">
                    <v-text-field
                         v-model="min_range"
                         :label="$t('setting.saleamountlow')"
                         hide-details
                         outlined
                         dense
                         class="pr-2"
                         background-color="#ffffff"
                    ></v-text-field>

                    <v-text-field
                         v-model="max_range"
                         :label="$t('setting.saleamounthigh')"
                         hide-details
                         outlined
                         dense
                         class="pl-2"
                         background-color="#ffffff"
                    ></v-text-field>
               </div>
               <div class="pa-2 d-flex flex-row">
                    <v-select
                         v-model="commission_type"
                         :items="commission_type_List"
                         :label="$t('setting.commissioncal')"
                         hide-details
                         outlined
                         dense
                         class="pr-2"
                         background-color="#ffffff"
                    >
                    </v-select>
                    <v-text-field
                         v-model="commission"
                         :label="$t('setting.commissionvalue')"
                         :prefix="commission_type=='f'?'$':''"
                         :suffix="commission_type=='p'?'%':''"
                         hide-details
                         outlined
                         dense
                         class="pl-2"
                         background-color="#ffffff"
                    ></v-text-field>
               </div>
          </v-form>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="!_id"
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="editRecord"
                    >
                         {{ $t('global.modify') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>

<script>
     import CommissionService from '@/utils/api/CommissionService';

     export default {
          props:{
               _id: String
          },
          data: () => ({
               valid: true,
               pagekey: ['ukWQg'],
               managekey: ['ukWQg'],

               name: '',
               type: 's',
               typeList: [{text:'Simple', value:'s'},
                    {text:'Progression', value:'p'}],
               min_range: 0,
               max_range: 0,
               commission_type: 'p',
               commission_type_List: [{text:'Percentage', value:'p'},
                                   {text:'Fixed', value:'f'}],
               commission: 0,

          }),
          methods: {
               apiGetRecord(){
                    CommissionService.getCommission(this._id
                    ).then(response=>{
                         if(response.data.status=='success'){
                              let result =response.data.data;
                              this.name = result.name;
                              this.type = result.type;
                              this.min_range = result.min_range;
                              this.max_range = result.max_range;
                              this.commission_type = result.commission_type;
                              this.commission = result.commission;
                         }
                    })
               },
               addNewRecord(){
                    CommissionService.addCommission({
                              name: this.name,
                              type: this.type,
                              min_range: this.min_range,
                              max_range: this.max_range,
                              commission_type: this.commission_type,
                              commission: this.commission,
                              status:'active'
                         }).then(response=>{
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +':'+ this.resource_name
                                   );
                         }).catch(e=>{
                              this.$emit('onFailAction', e.message);
                         })
               },
               editRecord(){
                    CommissionService.updateCommission({
                              _id: this._id,
                              name: this.name,
                              type: this.type,
                              min_range: this.min_range,
                              max_range: this.max_range,
                              commission_type: this.commission_type,
                              commission: this.commission,
                              status:'active'
                    }).then(response=>{
                         if (response.data.status == 'success')
                              this.$emit(
                                   'onClickInput',
                                   this.$t('global.successPrefix') +':'+ this.resource_name
                              );
                    }).catch(e=>{
                         this.$emit('onFailAction', e.message);
                    })
               },
               cancel(){
                    this.$emit('onClickCancel', null);
               },
          },
          created(){
               if(this._id) this.apiGetRecord();
          }
     }
</script>
