<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">{{
                              $t('components.payment')
                         }}</span>
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                   >
                                        {{ $t('global.morefunction')}}
                                        <v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                                  >{{
                                                       $t('global.import')
                                                  }}</v-list-item-title
                                             >
                                        </v-list-item-content>
                                   </v-list-item>
                                   <v-list-item link @click="onExportCSV()">
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{
                                                  $t('global.export')
                                             }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addPayment"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">{{
                                   $t('global.add')
                              }}</span>
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="3">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.paymentdate')
                                   }}</span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.invoiceno')
                                   }}</span>
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text">{{
                                        $t('components.customer')
                                   }}</span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.amount')
                                   }}</span>
                              </v-col>
                              <v-col>
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.status')
                                   }}</span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3 ">
                    <v-expansion-panels
                         v-model="panel"
                         flat
                         tile
                         style="flex-direction: column; flex-wrap: nowrap;"
                    >
                         <v-row
                              :class="`tc${index % 2}`"
                              v-for="(payment, index) in paymentList"
                              :key="payment._id"
                              class="px-3 fill-height"
                              align="center"
                         >
                              <v-expansion-panel
                                   :class="`tc${index % 2}`"
                                   class="pa-0"
                              >
                                   <v-expansion-panel-header
                                        hide-actions
                                        class="px-0 py-2"
                                   >
                                        <v-col cols="3" class="d-flex flex-row">
                                             <v-avatar
                                                  size="15"
                                                  color="heading_bg"
                                                  class="mx-3 my-auto"
                                                  style="overflow: visible;"
                                             >
                                                  <span
                                                       class="caption heading_color--text"
                                                       style="font-size: 9px !important;"
                                                  >
                                                       {{
                                                            (page - 1) * 5 +
                                                            index +
                                                            1
                                                       }}</span
                                                  >
                                             </v-avatar>
                                             <span
                                                  class="text1_color--text text-body-2"
                                                  >{{
                                                       formatDate(
                                                            payment.pay_date
                                                       )
                                                  }}</span
                                             >
                                             <span
                                                  class="text2_color--text my-auto px-2"
                                                  >▾</span
                                             >
                                        </v-col>

                                        <v-col
                                             cols="2"
                                             class="d-flex flex-column"
                                        >
                                             <div
                                                  style="max-width: 100px"
                                                  class="caption"
                                             >
                                                  {{
                                                       payment.invoice[0]
                                                            ? payment.invoice[0]
                                                                   .invoiceno
                                                            : $t(
                                                                   'global.notfound'
                                                              )
                                                  }}
                                             </div>
                                        </v-col>

                                        <v-col cols="3">
                                             <span
                                                  class="text1_color--text text-body-2"
                                                  >{{
                                                       payment.customer[0]
                                                            ? payment
                                                                   .customer[0]
                                                                   .name
                                                            : ''
                                                  }}</span
                                             >
                                        </v-col>

                                        <v-col cols="2">
                                             <div
                                                  class="text1_color--text text-body-2 font-weight-bold"
                                             >
                                                  <span
                                                       v-if="
                                                            payment.payment_type ==
                                                            'stock'
                                                       "
                                                       >Cr
                                                  </span>
                                                  <span v-else>$</span
                                                  >{{
                                                       formatCurrency(
                                                            payment.amount
                                                       )
                                                  }}
                                             </div>
                                             <div
                                                  class="text2_color--text text-caption"
                                             >
                                                  {{ payment.payment_type }}
                                             </div>
                                        </v-col>
                                        <v-col>
                                             <div
                                                  class="d-flex flex-row justify-space-between"
                                             >
                                                  <v-icon
                                                       small
                                                       v-if="
                                                            payment.status ==
                                                            'void'
                                                       "
                                                       color="#ff5252"
                                                  >
                                                       mdi-cancel
                                                  </v-icon>
                                                  <v-icon
                                                       small
                                                       v-if="
                                                            payment.status ==
                                                            'done'
                                                       "
                                                       color="#1976d2"
                                                  >
                                                       mdi-checkbox-marked-circle-outline
                                                  </v-icon>
                                                  <v-icon
                                                       small
                                                       v-if="
                                                            payment.status ==
                                                            'init'
                                                       "
                                                       color="orange"
                                                  >
                                                       mdi-progress-clock
                                                  </v-icon>
                                                  <v-menu
                                                       offset-y
                                                       v-if="
                                                            payment.status !=
                                                            'void'
                                                       "
                                                  >
                                                       <template
                                                            v-slot:activator="{
                                                                 on,
                                                                 attrs,
                                                            }"
                                                       >
                                                            <v-icon
                                                                 v-bind="attrs"
                                                                 v-on="on"
                                                                 color="primary"
                                                                 >mdi-dots-vertical-circle</v-icon
                                                            >
                                                       </template>
                                                       <v-list subheader dense>
                                                            <v-subheader>{{
                                                                 $t(
                                                                      'components.modifystatus'
                                                                 )
                                                            }}</v-subheader>
                                                            <v-list-item
                                                                 v-if="
                                                                      payment.status ==
                                                                      'init'
                                                                 "
                                                                 link
                                                                 @click="
                                                                      doneRecord(
                                                                           payment._id
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.paymentdone'
                                                                                )
                                                                           }}</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.donetips'
                                                                                )
                                                                           }}</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                                 link
                                                                 @click="
                                                                      deleteRecord(
                                                                           payment
                                                                      )
                                                                 "
                                                            >
                                                                 <v-list-item-content>
                                                                      <v-list-item-title
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.void'
                                                                                )
                                                                           }}</v-list-item-title
                                                                      >
                                                                      <v-list-item-subtitle
                                                                           >{{
                                                                                $t(
                                                                                     'invoice.voidtips'
                                                                                )
                                                                           }}</v-list-item-subtitle
                                                                      >
                                                                 </v-list-item-content>
                                                            </v-list-item>
                                                       </v-list>
                                                  </v-menu>
                                             </div>
                                        </v-col>
                                   </v-expansion-panel-header>
                                   <v-expansion-panel-content
                                        eager
                                        color="heading_bg"
                                   >
                                        <div class="d-flex flex-column">
                                             <div
                                                  style="max-width: 80%"
                                                  class="pl-5 caption text2_color--text pt-3"
                                             >
                                                  {{
                                                       $t(
                                                            'invoice.invoiceamount'
                                                       )
                                                  }}
                                             </div>
                                             <div
                                                  class="pl-5 caption text1_color--text"
                                             >
                                                  ${{
                                                       formatCurrency(
                                                            payment.invoice[0]
                                                                 ? payment
                                                                        .invoice[0]
                                                                        .amount
                                                                 : 0
                                                       )
                                                  }}
                                             </div>
                                             <div
                                                  v-if="payment.remark"
                                                  class="pl-5 caption text2_color--text pt-3"
                                             >
                                                  {{ $t('global.remark') }}
                                             </div>
                                             <div
                                                  class="pl-5 caption text1_color--text"
                                             >
                                                  {{ payment.remark }}
                                             </div>
                                        </div>
                                   </v-expansion-panel-content>
                              </v-expansion-panel>
                         </v-row>
                    </v-expansion-panels>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <ConfirmDialog ref="confirm" />

          <v-dialog v-model="dialog" persistent max-width="750px">
               <v-card>
                    <PaymentDialog
                         v-if="dialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               {{ $t('global.successPrefix') }}:
               <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               {{ $t('global.failPrefix') }}:
               <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import PaymentService from '@/utils/api/PaymentService';
     import PaymentDialog from '@/views/invoice/payment/PaymentAddView';
     import JsonToCSV from '@/utils/JsonToCSV';
     import Permission from '@/utils/Permission';

     const moment = require('moment');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });

     export default {
          data: () => ({
               invoiceid: String,

               searchcontent: null,

               pagekey: ['ONyFq'],
               managekey: ['uamho'],
               paymentList: [],
               noOfPage: 0,
               page: 1,
               recordperpage: 5,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',

               dialog: false,
               panel: null,
          }),
          methods: {
               apiGetPaymentList() {
                    PaymentService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.paymentList = response.data.data;
                              let rs = response.data.total_records;
                              let reminder = rs % this.recordperpage;
                              if (reminder > 0) reminder = 1;
                              let ps = Math.floor(rs / this.recordperpage);
                              this.noOfPage = reminder + ps;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetPaymentbyInvoice() {
                    PaymentService.getPaymentByInvoice(
                         this.page,
                         this.recordperpage,
                         this.invoiceid
                    )
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.paymentList = response.data.data;
                              let rs = response.data.total_records;
                              let reminder = rs % this.recordperpage;
                              if (reminder > 0) reminder = 1;
                              let ps = Math.floor(rs / this.recordperpage);
                              this.noOfPage = reminder + ps;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               doneRecord(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    this.selectedid = _id;

                    // eslint-disable-next-line
                    //PaymentService.deletePayment(_id).then(response=>{
                    // eslint-disable-next-line
                    PaymentService.updatePaymentStatus({
                         _id: _id,
                         newStatus: 'done',
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.is_success = true;
                                   this.success_message = this.$t(
                                        'global.successPrefix'
                                   );
                                   //this.apiGetRecordsCount();
                                   this.apiGetPaymentList();
                              } else {
                                   this.error_message = response.data.message;
                                   this.is_fail = true;
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               async deleteRecord(payment) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('invoice.voidpaymentmsg')
                         )
                    ) {
                         PaymentService.voidPayment({
                              _id: payment._id,
                              amount: payment.amount,
                              staffname: this.$user.username,
                         })
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.is_success = true;
                                        this.success_message = this.$t(
                                             'global.successPrefix'
                                        );
                                        //this.apiGetRecordsCount();
                                        this.apiGetPaymentList();
                                   } else {
                                        this.error_message = response.data.message;
                                        this.is_fail = true;
                                   }
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                              });
                    }
               },
               onExportCSV() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    JsonToCSV.export('paymentlist.csv', 'payment');
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               onChangePage() {
                    this.apiGetPaymentList();
               },
               addPayment() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }
                    this.dialog = true;
               },
               onClickCancel() {
                    this.dialog = false;
               },
               onFail(err_msg) {
                    this.is_fail = true;
                    this.error_message = err_msg;
               },
               onClickInput(msg) {
                    this.dialog = false;

                    this.is_success = true;
                    this.success_message = msg;

                    //this.apiGetRecordsCount();
                    this.apiGetPaymentList();
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
          },
          created() {
               if (this.$route.query.invoiceid) {
                    this.invoiceid = this.$route.query.invoiceid;
                    this.apiGetPaymentbyInvoice();
                    return;
               }

               //this.apiGetRecordsCount();
               this.apiGetPaymentList();
          },
          components: {
               ConfirmDialog,
               PaymentDialog,
          },
     };
</script>
