<template>
     <div class="pa-7">
          <div class="white rounded elevation-3" v-if="havePermit()">
               <div class="text1_color--text pa-5">
                    {{ $t('components.dashboard') }}
               </div>
               <v-divider class="py-5"></v-divider>

               <v-container class="px-7 d-flex flex-row">
                    <v-card width="300" color="blue darken-2" class="mx-2">
                         <v-card-title class="text-h5 white--text">
                              ${{ formatCurrency(sevenDaysAmount) }}
                         </v-card-title>
                         <v-card-subtitle
                              class="text2_color--text text--lighten-3"
                         >
                              {{ $t('dashboard.sevendayssales') }}
                         </v-card-subtitle>
                    </v-card>

                    <v-card width="300" color="blue darken-2" class="mx-2">
                         <v-card-title class="text-h5 white--text">
                              ${{ formatCurrency(overdueAmount) }}
                         </v-card-title>
                         <v-card-subtitle
                              class="text2_color--text text--lighten-3"
                         >
                              {{ overdueCount }} {{$t('dashboard.overdue')}}
                         </v-card-subtitle>
                    </v-card>

                    <v-card width="200" color="blue darken-2" class="mx-2">
                         <v-card-title class="text-h5 white--text">
                              {{ newCustomerCount }}
                         </v-card-title>
                         <v-card-subtitle
                              class="text2_color--text text--lighten-3"
                         >
                              {{ $t('dashboard.newcustomer')}}
                         </v-card-subtitle>
                    </v-card>

                    <v-card width="200" color="blue darken-2" class="mx-2">
                         <v-card-title class="text-h5 white--text">
                              {{ todayInvoiceCount }}
                         </v-card-title>
                         <v-card-subtitle
                              class="text2_color--text text--lighten-3"
                         >
                              {{ $t('dashboard.todayinvoices')}}
                         </v-card-subtitle>
                    </v-card>
               </v-container>

               <div class="mt-20">
                    <GChart
                         type="LineChart"
                         :data="chartvalue"
                         :options="{
                              title: $t('dashboard.sevendayssales'),
                              legend: { position: 'bottom' },
                              width: '99%',
                              height: 400,
                              chartArea: {
                                   left: 80,
                                   top: 80,
                                   right: 10,
                                   width: '100%',
                                   height: '70%',
                              },
                         }"
                    />
               </div>

               <v-container class="px-7 d-flex flex-row"> </v-container>
          </div>
          <div v-else>
               <span>Sorry, you don't have permission to access!</span>
          </div>
     </div>
</template>

<script>
     /* eslint-disable */
     import { GChart } from 'vue-google-charts';
     import Permission from '@/utils/Permission';
     import StatisticService from '@/utils/api/StatisticService';
     import BrowserSession from '@/utils/BrowserSession';

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });

     export default {
          data: () => ({
               date_range: [],
               chartdata: {},
               chartvalue: [
                    ['Day', 'Total'],
                    ['2022-07-01', 0],
                    ['2022-07-02', 0],
                    ['2022-07-03', 0],
                    ['2022-07-04', 0],
                    ['2022-07-05', 0],
                    ['2022-07-06', 0],
                    ['2022-07-07', 0],
               ],

               sevenDaysAmount: 0,
               overdueCount: 0,
               overdueAmount: 0,
               newCustomerCount: 0,
               todayInvoiceCount: 0,
          }),
          methods: {
               apiGetStatistic() {
                    //eslint-disable-next-line
                    StatisticService.getDashboardStatisticA(this.date_range)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;

                                   data.map((replace) => {
                                        this.chartdata[replace._id] =
                                             replace.totalAmount;
                                   });
                                   //console.log(this.chartdata);
                                   //console.log(this.chartdata.keys());

                                   //create chart value
                                   let index = 8;
                                   for (let [key, value] of Object.entries(
                                        this.chartdata
                                   )) {
                                        this.chartvalue[--index] = [key, value];
                                        this.sevenDaysAmount += value;
                                   }
                              }

                              //console.log(this.chartvalue);
                         })
                         .catch((error) => {
                              console.log(error);
                         });

                    StatisticService.getDashboardStatisticB(this.date_range[1])
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   this.overdueCount = data.count;
                                   this.overdueAmount =
                                        data.unpaidamount - data.paidamount;
                              }
                         })
                         .catch((error) => {
                              //console.log(error);
                              this.overdueCount = 0;
                              this.overdueAmount = 0;
                         });

                    StatisticService.getDashboardStatisticC()
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.newCustomerCount = response.data.data.count;
                         })
                         .catch((error) => {
                              //console.log(error);
                              this.newCustomerCount = 0;
                         });

                    StatisticService.getDashboardStatisticD(this.date_range[1])
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.todayInvoiceCount = response.data.data.count;
                         })
                         .catch((error) => {
                              //console.log(error);
                              this.todayInvoiceCount = 0;
                         });
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         ['BIMeL']
                    );
               },
          },
          created() {
               //BrowserSession.cleanAllSession();
               //BrowserSession.getAllSession();
               //let a = BrowserSession.getSession('config');
               //console.log(a);

               let d = new Date();
               //eslint-disable-next-line
               let abc = {};
               abc[d.toISOString().slice(0, 10)] = 0;
               this.chartdata = abc;

               for (let i = 1; i <= 6; i++) {
                    d.setDate(d.getDate() - 1);
                    let abc = {};
                    abc[d.toISOString().slice(0, 10)] = 0;
                    this.chartdata = { ...this.chartdata, ...abc };
               }

               let x = new Date();
               this.date_range[1] = x.toISOString().slice(0, 10);
               x.setDate(x.getDate() - 6);
               this.date_range[0] = x.toISOString().slice(0, 10);

               this.apiGetStatistic();
          },
          components: {
               GChart,
          },
     };
</script>
