<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span v-if="_id == null" class="heading_color--text">{{
                         $t('customer.addcustomer')
                    }}</span>
                    <span v-else class="heading_color--text">{{
                         $t('customer.modifycustomer')
                    }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="px-10 py-5 post_bg"
               >
                    <v-row>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="name"
                                   :counter="50"
                                   :rules="nameRules"
                                   :label="$t('customer.name')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="email"
                                   :label="$t('customer.email')"
                                   prepend-inner-icon="mdi-email"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="phone"
                                   :label="$t('customer.phone')"
                                   prepend-inner-icon="mdi-phone"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   :rules="nonEmptyRules"
                                   outlined
                                   dense
                                   :disabled="disable_phone_update"
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="password"
                                   :label="$t('global.password')"
                                   outlined
                                   dense
                                   type="password"
                                   :disabled="disable_phone_update"
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="py-0 d-flex flex-row">
                              <VueCtkDateTimePicker
                                   v-model="dob"
                                   :label="$t('customer.dob')"
                                   format="YYYY-MM-DD"
                                   formatted="LL"
                                   only-date
                                   no-header
                                   no-button
                                   auto-close
                              >
                              </VueCtkDateTimePicker>
                              <v-select
                                   v-model="selectyear"
                                   :items="syear"
                                   :label="$t('customer.quickselectyear')"
                                   outlined
                                   dense
                                   class="pl-2"
                                   style="max-width: 100px"
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                         <v-col class="py-0 d-flex flex-row">
                              <v-select
                                   v-model="selectgender"
                                   :items="genderList"
                                   :label="$t('customer.gender')"
                                   outlined
                                   dense
                                   class="pr-2"
                                   style="max-width: 150px"
                                   background-color="#ffffff"
                              ></v-select>
                              <v-select
                                   v-model="selectsales"
                                   :items="salesList"
                                   :label="$t('customer.sales')"
                                   outlined
                                   dense
                                   item-text="name"
                                   item-value="_id"
                                   return-object
                                   background-color="#ffffff"
                              >
                              </v-select>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="py-1">
                              <v-text-field
                                   v-model="company"
                                   :label="$t('customer.company')"
                                   outlined
                                   dense
                                   hide-details
                                   background-color="#ffffff"
                              ></v-text-field>
                              <div class="py-2"></div>
                              <v-textarea
                                   v-model="address"
                                   :label="$t('customer.address')"
                                   rows="3"
                                   outlined
                                   dense
                                   hide-details
                                   background-color="#ffffff"
                              >
                              </v-textarea>
                         </v-col>
                         <v-col class="py-1">
                              <v-textarea
                                   v-model="description"
                                   :label="$t('customer.detail')"
                                   rows="5"
                                   outlined
                                   dense
                                   hide-details
                                   background-color="#ffffff"
                              >
                              </v-textarea>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="10">
                              <v-combobox
                                   v-model="tags"
                                   :items="tagsList"
                                   :label="$t('customer.tag')"
                                   multiple
                                   hide-details
                                   small-chips
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              >
                                   <template v-slot:no-data>
                                        <v-list-item>
                                             <v-list-item-content>
                                                  <v-list-item-title>
                                                       <div
                                                            v-html="
                                                                 $t(
                                                                      'customer.addtagmsg'
                                                                 )
                                                            "
                                                       ></div>
                                                  </v-list-item-title>
                                             </v-list-item-content>
                                        </v-list-item>
                                   </template>
                                   <template
                                        v-slot:selection="{
                                             attrs,
                                             item,
                                             parent,
                                             selected,
                                        }"
                                   >
                                        <v-chip
                                             v-bind="attrs"
                                             :input-value="selected"
                                             label
                                             small
                                        >
                                             <span class="pr-2">
                                                  {{ item }}
                                             </span>
                                             <v-icon
                                                  small
                                                  @click="
                                                       parent.selectItem(item)
                                                  "
                                             >
                                                  $delete
                                             </v-icon>
                                        </v-chip>
                                   </template>
                              </v-combobox>
                         </v-col>
                         <v-col class="pa-0">
                              <v-switch
                                   color="primary"
                                   v-model="isActive"
                                   :label="$t('customer.status')"
                                   hide-details
                                   inset
                                   :disabled="this._id == null"
                                   dense
                                   class="pl-2"
                              >
                              </v-switch>
                         </v-col>
                    </v-row>
                    <v-row class="pa-3 d-flex flex-column">
                         <div class="grey--text text--darken-3">
                              {{ $t('customer.randm') }}
                         </div>
                         <v-divider class="pb-5"></v-divider>
                    </v-row>
                    <v-row class="py-0">
                         <v-col class="py-0 d-flex flex-column">
                              <div class="d-flex flex-row">
                                   <v-text-field
                                        v-model="re_point"
                                        class="pr-1"
                                        :label="$t('customer.reward')"
                                        type="number"
                                        outlined
                                        dense
                                        hide-details
                                        background-color="#ffffff"
                                   ></v-text-field>
                                   <v-text-field
                                        v-model="lv_point"
                                        class="pl-1"
                                        :label="$t('customer.membershippt')"
                                        type="number"
                                        outlined
                                        dense
                                        hide-details
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </div>
                              <v-switch
                                   v-model="join_reward"
                                   :label="$t('customer.joinmembership')"
                                   hide-details
                                   inset
                                   dense
                              ></v-switch>
                         </v-col>
                         <v-col class="py-0 d-flex flex-row">
                              <v-select
                                   v-model="membership"
                                   :items="memberlvList"
                                   :rules="memberlvRules"
                                   item-text="customer_category_name"
                                   item-value="_id"
                                   :label="$t('customer.membershiplv')"
                                   outlined
                                   dense
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   return-object
                                   class="pr-1"
                                   background-color="#ffffff"
                              ></v-select>
                              <VueCtkDateTimePicker
                                   class="pl-1"
                                   id="expiry_date_input"
                                   v-model="expiry"
                                   :label="$t('customer.memebshipexpiry')"
                                   format="YYYY-MM-DD"
                                   formatted="ll"
                                   only-date
                                   no-header
                                   no-button
                                   auto-close
                                   right
                              >
                              </VueCtkDateTimePicker>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="py-0"></v-col>
                    </v-row>
               </v-form>
          </div>
          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="_id == null"
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="editRecord"
                    >
                         {{ $t('global.update') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>

          <v-snackbar
               v-model="is_fail"
               color="red accent-2"
               elevation="24"
               @input="actionOnClose"
          >
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /*eslint-disable */
     import BrowserSession from '@/utils/BrowserSession';
     import CustomerService from '@/utils/api/CustomerService';
     import LogService from '@/utils/api/LogService';
     import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
     import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
     //import _ from 'lodash';

     var isEmpty = require('lodash/isEmpty');
     var find = require('lodash/find');

     export default {
          props: {
               _id: String,
          },
          data: () => ({
               valid: true,
               name: '',
               nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) =>
                         (v && v.length <= 50) ||
                         'Name must be less than 50 characters',
               ],
               nonEmptyRules: [(v) => !!v || 'This field is required'],
               password: null,
               company: '',
               email: '',
               selectgender: 'F',
               genderList: [{text:'男', value:'M'}, {text:'女', value:'F'}],
               //genderListName: ['M', 'F'],

               selectsales: null,
               salesList: ['Mr A', 'Mr B'],
               syear: [
                    '1930',
                    '1940',
                    '1950',
                    '1960',
                    '1970',
                    '1980',
                    '1990',
                    '2000',
                    '2010',
                    '2020',
               ],
               selectyear: '2020',
               phone: '',
               disable_phone_update: false,
               dob: '',
               expiry: '',
               membership: null,
               memberlvList: [],
               memberlvRules: [(v) => !!v || 'This field is required'],
               description: '',
               address: '',
               tags: [],
               tagsList: [],

               re_point: 0,
               lv_point: 0,
               join_reward: true,

               enable_member_login: true,

               isActive: true,

               is_fail: false,
               error_message: '',
               auto_close: false, //control any error will not pass

               extend_days: 365,
          }),
          methods: {
               apiGetCustomerCategory() {
                    BrowserSession.getSession('customer_category', (dd) => {
                         this.memberlvList = dd;
                         //console.log(this.memberlvList);
                         if (!isEmpty(this.memberlvList)) {
                              this.membership = this.memberlvList[0];
                         } else {
                              this.auto_close = true;
                              throw new Error(
                                   this.$t('customer.createmembershipfirst')
                              );
                         }
                    });
               },
               apiGetUser() {
                    BrowserSession.getSession('user', (data) => {
                         this.salesList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               apiGetCustomer() {
                    CustomerService.getCustomer(this._id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   this.name = data.name;
                                   this.company = data.company;
                                   //this.selectgender = data.gender;
                                   this.password = '*******';
                                   this.selectgender = data.gender;
                                   this.email = data.email;
                                   this.selectsales = find(this.salesList, {
                                        _id: data.default_sales || null,
                                   });
                                   this.phone = data.phone;
                                   this.address = data.address;
                                   this.dob = data.dob;
                                   this.description = data.description;
                                   this.membership = find(this.memberlvList, {
                                        _id: data.member_lv,
                                   });
                                   this.enable_member_login =
                                        data.enable_member_login || true;
                                   this.join_reward = data.join_reward || true;
                                   this.re_point = data.re_point || 0;
                                   this.lv_point = data.lv_point || 0;
                                   this.expiry = data.member_lv_expiry;
                                   this.tags = data.tag || [];
                                   this.isActive = data.status == 'active';

                                   this.disable_phone_update = true;
                              }
                         })
                         .catch((e) => {
                              /*this.error_message = e.message;
                              this.is_fail = true;*/
                              if (e.response.data.message)
                                   this.error_message = e.response.data.message;
                              else this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               getExpiryDate(){
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    let nextexpirydate = new Date();
                    nextexpirydate.setDate( today.getDate() + this.extend_days );
                    nextexpirydate.setHours(0, 0, 0, 0);

                    return nextexpirydate;
               },
               addNewRecord() {
                    if (this.auto_close) return;

                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    let cobject = {
                         name: this.name,
                         company: this.company,
                         gender: this.selectgender,
                         password: this.password,
                         email: this.email,
                         default_sales: this.selectsales
                              ? this.selectsales._id
                              : null,
                         phone: this.phone,
                         address: this.address,
                         dob: this.dob?this.dob.substr(0, 10):null,
                         description: this.description,
                         member_lv: this.membership ? this.membership._id : null,
                         enable_member_login: this.enable_member_login,
                         join_reward: this.join_reward,
                         re_point: this.re_point||0,
                         lv_point: this.lv_point||0,
                         member_lv_expiry: _.isEmpty(this.expiry)?this.getExpiryDate():this.expiry,
                         reward_expiry: this.getExpiryDate(),
                         tag: this.tags,
                         status: 'active',
                    };
                    console.log(cobject);

                    CustomerService.addCustomer(cobject)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   LogService.CustomerCreateLog(
                                        response.data.data.insertedId,
                                        this.$user.username
                                   );
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +
                                             ': ' +
                                             this.name
                                   );
                              } else {
                                   this.is_fail = true;
                                   this.error_message =
                                        this.$t('global.failPrefix') +
                                        ': ' +
                                        response.data.message;
                              }
                         })
                         .catch((e) => {
                              //console.log('error catch');
                              if (e.response.data.message)
                                   this.error_message = e.response.data.message;
                              else this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               editRecord() {
                    if (this.auto_close) return;

                    if (!this.$refs.form.validate()) {
                         return;
                    }

                    let cobject = {
                         _id: this._id,
                         name: this.name,
                         company: this.company,
                         gender: this.selectgender,
                         email: this.email,
                         default_sales: this.selectsales
                              ? this.selectsales._id
                              : null,
                         phone: this.phone,
                         address: this.address,
                         dob: this.dob?this.dob.substr(0, 10):null,
                         description: this.description,
                         member_lv: this.membership ? this.membership._id : null,
                         enable_member_login: this.enable_member_login,
                         join_reward: this.join_reward,
                         re_point: this.re_point||0,
                         lv_point: this.lv_point||0,
                         member_lv_expiry: _.isEmpty(this.expiry)?this.getExpiryDate():this.expiry,
                         tag: this.tags,
                         status: this.isActive ? 'active' : 'inactive',
                    };
                    //console.log(cobject);

                    CustomerService.updateCustomer(cobject)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   LogService.CustomerModifyLog(
                                        this._id,
                                        this.$user.username
                                   );
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +
                                             ': ' +
                                             this.name
                                   );
                              } else {
                                   this.is_fail = true;
                                   this.error_message =
                                        this.$t('global.failPrefix') +
                                        ': ' +
                                        +response.data.message;
                              }
                         })
                         .catch((e) => {
                              if (e.response.data.message)
                                   this.error_message = e.response.data.message;
                              else this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               cancel() {
                    this.$emit('onClickCancel', null);
               },
               actionOnClose() {
                    if (this.auto_close) {
                         this.$emit('onClickCancel', null);
                    }
               },
          },
          watch: {
               selectyear(newyear, oldyear) {
                    if (oldyear == newyear) return;

                    //console.log('old year : ' + oldyear);
                    //console.log('set new year : ' + newyear);
                    //console.log('dob:' + this.dob);

                    this.dob = newyear + this.dob.substr(4);
                    //this.dob.setFullYear(newyear);
               },
          },
          created() {
               this.apiGetCustomerCategory();
               this.apiGetUser();

               //this.dob = new Date().toISOString();
               let e = new Date();
               e.setFullYear(e.getFullYear() + 1);
               this.expiry = e.toISOString();

               /*this.expiry = new Date();
               this.expiry.setFullYear(this.expiry.getFullYear() + 1);
               this.expiry.toISOString();*/

               if (this._id) this.apiGetCustomer(this._id);
               //else this.selectsales = this.salesList[0];

               BrowserSession.getSession('setting', (data) => {
                         let tmp = data.find((d) => {
                              return d.key == 'config';
                         }).value;
                         this.extend_days = tmp.membership_duration_days;
               });

               this.genderList = [{text:this.$t('global.male'), value:'M'},
                                   {text:this.$t('global.female'), value:'F'}];
          },
          components: {
               VueCtkDateTimePicker,
          },
     };
</script>
