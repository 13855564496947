import http from '../HttpConn';
var isObject = require('lodash/isObject');
var isNumber = require('lodash/isNumber');
var toNumber = require('lodash/toNumber');

class CustomerCategoryService {
     getCount() {
          return http.get('/customercategory/count');
     }
     getAll(page = 1, limit = 9999) {
          return http.get('/customercategory', {
               params: { page: page, limit: limit },
          });
     }
     getAllWithDefault() {
          return http.get('/customercategory/getwithdefault');
     }
     getCustomerCategory(id) {
          return http.get('/customercategory/' + id);
     }
     /* eslint-disable */
     updateCustomerCategory(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          if (!isNumber(toNumber(ccobj.discount_percentage))) {
               return new Promise((resolve, reject) => {
                    throw new Error('Discount must be a number!'); // 用throw語句
               });
          } else {
               ccobj.discount_percentage = toNumber(ccobj.discount_percentage);
          }

          return http.put('/customercategory/update/' + ccobj._id, ccobj);
     }
     addCustomerCategory(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          if (!isNumber(toNumber(ccobj.discount_percentage))) {
               return new Promise((resolve, reject) => {
                    throw new Error('Discount must be a number!'); // 用throw語句
               });
          } else {
               ccobj.discount_percentage = toNumber(ccobj.discount_percentage);
          }
          return http.post('/customercategory/add', ccobj);
     }
     deleteCustomerCategory(id) {
          return http.delete('/customercategory/delete/' + id);
     }
     /* eslint-enable */
}
export default new CustomerCategoryService();
