<template>
     <div class="pa-7">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="heading_color--text">
                              {{ $t('mobile.booking') }}
                         </span>
                         <v-spacer></v-spacer>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addBooking"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">
                                   {{ $t('global.add') }}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="3 my-auto">
                                   <span class="mx-4"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{$t('mobile.bookingdate')}}
                                   </span>
                              </v-col>
                              <v-col cols="3 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('mobile.bookingperson') }}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('mobile.bookedservice') }}
                                   </span>
                              </v-col>
                              <v-col cols="4 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('mobile.remark') }}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(booking, index) in bookingList"
                         :key="booking._id"
                         class="px-3 fill-height"
                         align="center"
                         :style="booking.over==1?('color:darkgrey'):''"
                    >
                         <v-col cols="3" class="d-flex flex-row">
                              <div>
                                   <v-icon
                                        v-if="booking.by=='mobile'"
                                        class="mr-3"
                                        >mdi-cellphone-basic</v-icon
                                   >
                                   <v-icon
                                        v-else-if="booking.by=='backend'"
                                        class="mr-3"
                                        >mdi-laptop</v-icon
                                   >
                                   <div class="text2_color--text text-caption">
                                        {{ new Date(booking.created_date).getDate() + 
                                        "/" +
                                        (new Date(booking.created_date).getMonth()+1) }}
                                   </div>
                              </div>

                              <div
                                   class="my-auto ml-5"
                                   :style="booking.over==2?'color:#1976d2':''"
                              >
                                   <div
                                        class="text-decoration-none"
                                        style="font-size: 20px"
                                   >
                                        {{  formatTime(booking.booking_date)}}
                                   </div>
                                   <div
                                        class="text-decoration-none text-caption"
                                   >
                                        {{ formatDate(booking.booking_date) }}
                                   </div>
                                   <div class="text2_color--text text-caption">
                                        {{ booking.customerid?'Member':'Guest' }}
                                   </div>
                              </div>
                         </v-col>
                         <v-col cols="3" class="my-auto">
                              <div>
                                   <div class="text-subtitle-1">
                                        {{ booking.customer_name }}
                                   </div>
                                   <div class="text2_color--text text-caption">
                                        {{ booking.customer_phone }}
                                   </div>
                                   <v-chip
                                        label
                                        x-small
                                        text-color="white"
                                        :color="booking.status=='pending'?'warning':booking.status=='approved'?'primary':'error'"
                                   >
                                        {{ booking.status }}
                                   </v-chip>
                              </div>
                         </v-col>

                         <v-col cols="2" class="my-auto">
                              <div>
                                   <div class="text-subtitle-1">
                                        {{ booking.service_detail }}
                                   </div>
                                   <div class="text-caption">
                                        ({{ booking.visitor_count }}
                                        {{ $t('mobile.person') }})
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="4" class="d-flex justify-space-between">
                              <div>
                                   <div class="text-caption">
                                        {{ booking.remark }}
                                   </div>
                              </div>
                              <div>
                                   <v-menu offset-y>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  link
                                                  @click="modifyRecord(booking._id)"
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-pencil-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >{{ $t('global.modify') }}</v-list-item-title
                                                  >
                                             </v-list-item>
                                        </v-list>
                                        <v-divider></v-divider>
                                        <v-list subheader dense>
                                             <v-subheader>
                                                  {{ $t('mobile.status') }}
                                             </v-subheader>
                                             <v-list-item
                                                  link
                                                  :disabled="booking.status == 'pending' "
                                                  @click="
                                                  updateStatus(
                                                       booking._id,
                                                       'pending'
                                                  )
                                             "
                                             >
                                                  <v-list-item-content>
                                                       <v-list-item-title>
                                                            {{ $t('mobile.pending') }}
                                                       </v-list-item-title>
                                                       <v-list-item-subtitle>
                                                            {{ $t('mobile.pendingdesc') }}
                                                       </v-list-item-subtitle>
                                                  </v-list-item-content>
                                             </v-list-item>

                                             <v-list-item
                                                  link
                                                  :disabled="booking.status == 'approved'"
                                                  @click="
                                                  updateStatus(
                                                       booking._id,
                                                       'approved'
                                                  )
                                             "
                                             >
                                                  <v-list-item-content>
                                                       <v-list-item-title>
                                                            {{ $t('mobile.approved') }}
                                                       </v-list-item-title>
                                                       <v-list-item-subtitle>
                                                            {{ $t('mobile.approveddesc') }}
                                                       </v-list-item-subtitle>
                                                  </v-list-item-content>
                                             </v-list-item>

                                             <v-list-item
                                                  link
                                                  :disabled="booking.status == 'rejected'"
                                                  @click="
                                                  updateStatus(
                                                       booking._id,
                                                       'rejected'
                                                  )
                                             "
                                             >
                                                  <v-list-item-content>
                                                       <v-list-item-title>
                                                            {{ $t('mobile.rejected') }}
                                                       </v-list-item-title>
                                                       <v-list-item-subtitle>
                                                            {{ $t('mobile.rejecteddesc') }}
                                                       </v-list-item-subtitle>
                                                  </v-list-item-content>
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <v-dialog v-model="dialog" persistent max-width="500px">
               <v-card class="pa-0">
                    <BookingAddComponent
                         v-if="dialog"
                         @onClickCancel="cancelInput"
                         @onClickInput="successInput"
                         @onFailAction="failInput"
                         :_id="selectedid"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import BookingService from '@/utils/api/BookingService';
     import BookingAddComponent from '@/views/mobile/booking/BookingAddView';
     const moment = require('moment');

     export default {

          data() {
               return {
                    pagekey: ['KvVMJ'],
                    managekey: ['VmDPa'],

                    selectedid: null,

                    bookingList: [],
                    page: 1,
                    noOfPage: 0,
                    recordperpage: 5,

                    dialog: false,

                    is_success: false,
                    is_fail: false,
                    success_message: '',
                    error_message: '',
               }
          },
          methods:{
               apiGetBookingList() {
                    BookingService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              if (response.data.status == 'success'){
                                   this.bookingList = response.data.data;
                                   this.formatStyle();
                                   let rs = response.data.recordcount;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               formatStyle(){
                    this.bookingList.map(booking=>{
                         if(new Date(booking.booking_date) < new Date()){
                              booking.over = 1;   //passed booking
                         }else if(moment(new Date(booking.booking_date)).isSame(new Date(), 'day')){
                              booking.over = 2;   //today booking
                         }else{
                              booking.over = 0;   //future booking
                         }
                         //console.log(booking);
                    })
               },
               onChangePage() {
                    this.apiGetBookingList();
               },
               addBooking(){
                    this.selectedid = null;
                    this.dialog = true;
               },
               updateStatus(id, status){
                    BookingService.updateBooking({ _id: id, status: status })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.is_success = true;
                                   this.success_message = this.$t('global.successPrefix');
                                   this.apiGetBookingList();
                              }
                         })
                         .catch((error) => {
                              //console.log(error);
                              this.is_fail = true;
                              this.error_message = error.message;
                         });
               },
               cancelInput() {
                    this.dialog = false;

                    this.is_success = false;
                    this.is_fail = false;
                    this.success_message = '';
                    this.error_messag = '';
               },
               successInput: function (msg) {
                    this.apiGetBookingList();
                    this.dialog = false;

                    this.success_message = msg;
                    this.is_success = true;
                    this.is_fail = false;
               },
               failInput: function (err_msg) {
                    this.dialog = false;

                    this.is_success = false;
                    this.error_message = err_msg;
                    this.is_fail = true;
               },

               modifyRecord(id) {
                    this.selectedid = id;
                    this.dialog = true;
               },

               formatTime(date){
                    return moment(new Date(date)).format('LT');
               },
               formatDate(date){
                    return moment(new Date(date)).format('ll');
               }

          },
          created() {
               this.apiGetBookingList();
          },
          components: {
               BookingAddComponent
          },
     }
</script>
