<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span class="heading_color--text">{{
                         $t('global.modify')
                    }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="pa-10 post_bg"
               >
                    <v-row>
                         <v-col cols="8" class="py-0">
                              <v-text-field
                                   :value="customerprofileobj.product_name"
                                   :label="$t('product.name')"
                                   readonly
                                   outlined
                                   dense
                                   color="grey"
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>

                         <v-col class="py-0">
                              <v-text-field
                                   v-model="remain_credit"
                                   :label="$t('product.remaincredit')"
                                   type="number"
                                   persistent-hint
                                   :rules="[numberRules]"
                                   :hint="$t('global.required')"
                                   outlined
                                   dense
                                   @keydown="cancelerrmsg"
                                   :error-messages="em"
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="8" class="py-0">
                              <VueCtkDateTimePicker
                                   id="expiry_on"
                                   v-model="expiry_on"
                                   :label="$t('global.expiry')"
                                   format="YYYY-MM-DD"
                                   formatted="LL"
                                   only-date
                                   no-header
                                   no-button
                                   auto-close
                                   right
                              >
                              </VueCtkDateTimePicker>
                              <span
                                   class="caption pl-3"
                                   style="color: rgba(0, 0, 0, 0.6)"
                                   >{{ $t('global.required') }}</span
                              >
                         </v-col>
                         <v-col class="py-0">
                              <v-switch
                                   v-model="status"
                                   :label="$t('customer.status')"
                                   outlined
                                   dense
                                   hide-details
                                   inset
                              ></v-switch>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col>
                              <v-textarea
                                   v-model="refA"
                                   outlined
                                   rows="3"
                                   :label="$t('global.remark')"
                                   hide-details
                                   dense
                                   background-color="#ffffff"
                              ></v-textarea>
                         </v-col>
                    </v-row>
                    <div class="text-right pt-3">
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold right"
                              @click="editRecord"
                         >
                              {{ $t('global.update') }}
                         </v-btn>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="grey"
                              text
                              class="body-2 font-weight-bold"
                              @click="cancel"
                         >
                              {{ $t('global.cancel') }}
                         </v-btn>
                    </div>
               </v-form>
               <v-form
                    ref="formA"
                    v-model="validA"
                    lazy-validation
                    class="px-10 pb-5 post_bg"
               >
                    <div>{{ $t('customer.newconsumerecord') }}</div>
                    <v-divider class="pb-10"></v-divider>
                    <v-row>
                         <v-col class="py-0">
                              <v-select
                                   v-model="selectService"
                                   :items="customerprofileobj.link_services"
                                   :item-text="
                                        (item) =>
                                             item.name +
                                             ' (' +
                                             item.credit +
                                             ' credit)'
                                   "
                                   item-value="id"
                                   return-object
                                   :label="$t('components.service')"
                                   outlined
                                   dense
                                   class="pr-2"
                                   persistent-hint
                                   :hint="$t('global.required')"
                                   @change="onServiceChange"
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                         <v-col cols="3" class="py-0">
                              <v-text-field
                                   v-model="consume_credit"
                                   :label="$t('customer.consumecredit')"
                                   :rules="[numberRules]"
                                   type="number"
                                   outlined
                                   dense
                                   persistent-hint
                                   :hint="$t('global.required')"
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="consume_reason"
                                   outlined
                                   :label="$t('global.remark')"
                                   dense
                                   hide-details
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <div class="text-right pt-7">
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold right"
                              @click="addLog"
                         >
                              {{ $t('global.add') }}
                         </v-btn>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="grey"
                              text
                              class="body-2 font-weight-bold"
                              @click="cancel"
                         >
                              {{ $t('global.cancel') }}
                         </v-btn>
                    </div>
               </v-form>
          </div>
     </div>
</template>

<script>
     /* eslint-disable */
     import CustomerProfileService from '@/utils/api/CustomerProfileService';
     import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
     import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

     const moment = require('moment');
     var isEmpty = require('lodash/isEmpty');

     export default {
          props: { customerobj: Object, _id: String, customerprofileobj: Object },
          data: () => ({
               valid: true,
               validA: true,
               em: null,

               numberRules: (v) => {
                    if (!v && v != 0) return 'This field is required';
                    if (v.length == 0) return 'This field is required';
                    if (!isNaN(v)) return true;
                    return 'Please input a number';
               },
               remain_credit: 0,
               expiry_on: null,
               status: false,
               refA: '',

               selectService: null,
               consume_credit: 0,
               consume_reason: null,
          }),
          methods: {
               async editRecord() {
                    if (this.$refs.form.validate() && this.remain_credit > 0) {
                         //console.log('form pass');
                         //update
                         try {
                              let response =
                                   await CustomerProfileService.updateCustomerProfile(
                                        {
                                             ...this.customerprofileobj,
                                             ...{
                                                  remain_credit: this.remain_credit,
                                                  expiry_on: this.expiry_on,
                                                  status: this.status
                                                       ? 'active'
                                                       : 'inactive',
                                             },
                                        }
                                   );

                              //console.log(response);

                              //let tmplog = this.customerprofileobj.credit_log;
                              let v = '';

                              if (
                                   this.customerprofileobj.remain_credit !=
                                   this.remain_credit
                              ) {
                                   v +=
                                        ' [' +
                                        this.customerprofileobj.remain_credit +
                                        '->' +
                                        this.remain_credit +
                                        ']';
                              }
                              if (
                                   this.formatDate(
                                        this.customerprofileobj.expiry_on
                                   ) != this.expiry_on
                              ) {
                                   v +=
                                        ' [' +
                                        this.formatDate(
                                             this.customerprofileobj.expiry_on
                                        ) +
                                        '->' +
                                        this.expiry_on +
                                        ']';
                              }
                              let status1 = this.status ? 'active' : 'inactive';

                              if (this.customerprofileobj.status != status1) {
                                   v +=
                                        ' [' +
                                        this.customerprofileobj.status +
                                        '->' +
                                        status1 +
                                        ']';
                              }

                              //console.log(this.refA);
                              let tmplog = {
                                   key: 'modify',
                                   value: this.customerprofileobj.product_name,
                                   service: null,
                                   ref:
                                        (this.refA == undefined ? '' : this.refA) +
                                        (isEmpty(v) ? '' : '(' + v + ')'),
                              };

                              //console.log(tmplog);

                              let re =
                                   await CustomerProfileService.addCustomerProfileCreditLog(
                                        {
                                             _id: this.customerprofileobj._id,
                                             credit_log: tmplog,
                                        }
                                   );
                              //console.log(re);

                              this.$emit(
                                   'onClickInput',
                                   this.$t('global.successPrefix')
                              );
                         } catch {
                              (e) => {
                                   console.log(e);
                                   this.$emit(
                                        'onFailAction',
                                        this.$t('global.failPrefix') +
                                             ': ' +
                                             e.message
                                   );
                              };
                         }
                    } else {
                         this.em = this.$t('global.plsentervalid');
                    }
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               async addLog() {
                    if (this.$refs.formA.validate()) {
                         //console.log('formA pass');
                         try {
                              await CustomerProfileService.updateCustomerProfile({
                                   ...this.customerprofileobj,
                                   ...{
                                        remain_credit:
                                             this.remain_credit -
                                             this.consume_credit,
                                   },
                              });

                              //let tmplog = this.customerprofileobj.credit_log;
                              let tmplog = {
                                   key: 'consume',
                                   value: this.consume_credit,
                                   service: this.selectService.name,
                                   ref:
                                        '* ' +
                                        (this.consume_reason == undefined
                                             ? ''
                                             : this.consume_reason),
                              };
                              await CustomerProfileService.addCustomerProfileCreditLog(
                                   {
                                        _id: this.customerprofileobj._id,
                                        credit_log: tmplog,
                                   }
                              );

                              //console.log(response);
                              this.$emit(
                                   'onClickInput',
                                   this.$t('global.successPrefix')
                              );
                         } catch {
                              (e) => {
                                   console.log(e);
                                   this.$emit(
                                        'onFailAction',
                                        this.$t('global.failPrefix') +
                                             ': ' +
                                             e.message
                                   );
                              };
                         }
                    }
               },
               onServiceChange(obj) {
                    //console.log(obj);
                    this.consume_credit = obj.credit;
               },
               cancel() {
                    this.$emit('onClickCancel');
               },
               cancelerrmsg() {
                    this.em = null;
               },
          },
          created() {
               if (this.customerprofileobj != null) {
                    //console.log(this.customerprofileobj);
                    this.remain_credit = this.customerprofileobj.remain_credit;
                    this.status = this.customerprofileobj.status == 'active';
                    this.expiry_on = this.formatDate(
                         this.customerprofileobj.expiry_on
                    );
                    this.refA = this.customerprofileobj.ref;
               }
          },
          components: {
               VueCtkDateTimePicker,
          },
     };
</script>
