<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span v-if="_id" class="heading_color--text">{{
                         $t('global.modify')
                    }}</span>
                    <span v-else class="heading_color--text">{{
                         $t('global.newrecord')
                    }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>

          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-10 py-5 post_bg"
          >
               <div class="pa-2">
                    <v-text-field
                         v-model="resource_name"
                         :label="$t('setting.resourcename')"
                         hide-details
                         outlined
                         dense
                         background-color="#ffffff"
                    ></v-text-field>
               </div>
               <div class="pa-2">
                    <v-text-field
                         v-model="resource_description"
                         :label="$t('setting.description')"
                         hide-details
                         outlined
                         dense
                         background-color="#ffffff"
                    ></v-text-field>
               </div>
               <div class="pa-2">
                    <v-combobox
                         v-model="resource_type"
                         :items="tagsList"
                         :label="$t('setting.type')"
                         multiple
                         hide-details
                         small-chips
                         outlined
                         dense
                         background-color="#ffffff"
                    >
                         <template v-slot:no-data>
                              <v-list-item>
                                   <v-list-item-content>
                                        <v-list-item-title>
                                             <div>
                                                  {{ $t('setting.addnewtypedesc') }}
                                             </div>
                                        </v-list-item-title>
                                   </v-list-item-content>
                              </v-list-item>
                         </template>
                         <template
                              v-slot:selection="{
                                             attrs,
                                             item,
                                             parent,
                                             selected,
                                        }"
                         >
                              <v-chip
                                   v-bind="attrs"
                                   :input-value="selected"
                                   label
                                   small
                              >
                                   <span class="pr-2">
                                        {{ item }}
                                   </span>
                                   <v-icon
                                        small
                                        @click="parent.selectItem(item)"
                                   >
                                        $delete
                                   </v-icon>
                              </v-chip>
                         </template>
                    </v-combobox>
               </div>
          </v-form>
          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="!_id"
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="editRecord"
                    >
                         {{ $t('global.modify') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>
<script>
     import ResourceService from '@/utils/api/ResourceService';
     import BrowserSession from '@/utils/BrowserSession';

     export default {
               props:{
                    _id: String
               },
               data: () => ({
                    valid: true,
                    pagekey: ['ukWQg'],
                    managekey: ['ukWQg'],

                    resource_name: '',
                    resource_description: '',
                    resource_type: '',

                    tagsList:[],
               }),
               methods: {
                    apiGetResource(){
                         ResourceService.getResource(this._id).then(response=>{
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   //console.log(data);
                                   this.resource_name = data.name;
                                   this.resource_description = data.description;
                                   this.resource_type = data.type;
                              }
                         }).catch(e=>{
                              console.log(e);
                         })
                    },
                    addNewRecord(){
                         ResourceService.addResource({name: this.resource_name,
                              description: this.resource_description,
                              type: this.resource_type, status:'active'}).then(response=>{
                              console.log(response.data);
                         }).then(response=>{
                              if (response.data.status == 'success')
                                        this.$emit(
                                             'onClickInput',
                                             this.$t('global.successPrefix') +':'+ this.resource_name
                                        );
                         }).catch(e=>{
                              this.$emit('onFailAction', e.message);
                         })
                    },
                    editRecord(){
                         if (!this.$refs.form.validate()) {
                              //console.log('form invalid!');
                              return;
                         }
                         ResourceService.updateResource({_id: this._id,
                              name: this.resource_name,
                              description: this.resource_description,
                              type: this.resource_type
                              }).then(response=>{
                                   if (response.data.status == 'success')
                                        this.$emit(
                                             'onClickInput',
                                             this.$t('global.successPrefix') +':'+ this.resource_name
                                        );
                              }).catch(e=>{
                                   this.$emit('onFailAction', e.message);
                              })
                    },
                    cancel(){
                         this.$emit('onClickCancel', null);
                    },
                    reloadResourceCache(){
                         BrowserSession.renewSession('resource', null);
                    }
               },
               created() {
                    if (this._id) this.apiGetResource();
               },
          }
</script>
