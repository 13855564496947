<template>
     <div class="pa-7">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">
                              {{ $t('components.employee') }}
                         </span>
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                   >
                                        {{ $t('global.morefunction')}}
                                        <v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                             >
                                                  {{$t('global.import')}}
                                             </v-list-item-title>
                                        </v-list-item-content>
                                   </v-list-item>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                             >
                                                  {{$t('global.export')}}
                                             </v-list-item-title>
                                        </v-list-item-content>
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addDialog = true"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">
                                   {{$t('global.add')}}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>

               <v-divider></v-divider>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="4">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{$t('user.name')}}
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">
                                        {{$t('user.group')}}
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">
                                        {{$t('user.phone')}}
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">
                                        {{$t('user.employdate')}}
                                   </span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">
                                        {{$t('user.status')}}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(user, index) in userList"
                         :key="user._id"
                         class="px-3"
                         align="center"
                    >
                         <v-col cols="4" class="d-flex">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        (page - 1) * 5 + index + 1
                                   }}</span>
                              </v-avatar>
                              <v-avatar size="36" class="mx-3 my-auto">
                                   <v-img :src="publicPath + user.avatar" />
                              </v-avatar>
                              <div class="d-flex flex-column">
                                   <!--<a :href="`/user/${user._id}`" 
                class="text-decoration-none text-subtitle-1">
              {{ user.name }}</a>-->
                                   <span
                                        class="text-decoration-none text-subtitle-1"
                                        >{{ user.name }}</span
                                   >
                                   <div style="margin-top: -9px">
                                        <span
                                             class="text2_color--text text-caption"
                                             >{{ user.title }}</span
                                        >
                                   </div>
                              </div>
                         </v-col>
                         <v-col cols="2">
                              <div class="text1_color--text text-body-2">
                                   {{ user.role[0].role }}
                              </div>
                         </v-col>
                         <v-col cols="2">
                              <div class="text1_color--text text-body-2">
                                   {{ user.phone }}
                              </div>
                              <div class="text2_color--text caption">
                                   {{ user.email }}
                              </div>
                         </v-col>
                         <v-col cols="2">
                              <div class="text1_color--text text-body-2">
                                   {{ user.employ_date }}
                              </div>
                         </v-col>
                         <v-col cols="2">
                              <div
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <v-icon
                                        small
                                        :color="
                                             user.status == 'active'
                                                  ? 'primary'
                                                  : 'red'
                                        "
                                   >
                                        {{
                                             user.status == 'active'
                                                  ? 'mdi-checkbox-marked-circle-outline'
                                                  : 'mdi-cancel'
                                        }}
                                   </v-icon>
                                   <v-menu offset-y>
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       modifyRecord(user._id)
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-pencil-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                  >
                                                       {{ $t('global.modify') }}
                                                  </v-list-item-title>
                                             </v-list-item>
                                             <v-list-item
                                                  v-if="
                                                       user.role[0].role !=
                                                       'administrator'
                                                  "
                                                  link
                                                  @click="stopRecord(user._id)"
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-cancel</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                  >
                                                       {{ $t('user.suspendaccount') }}
                                                  </v-list-item-title>
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-snackbar
                    v-model="is_success"
                    color="primary accent-2"
                    elevation="24"
               >
                    Success: <strong>{{ this.success_message }}</strong>
               </v-snackbar>
               <v-snackbar
                    v-model="is_fail"
                    color="red accent-2"
                    elevation="24"
               >
                    Failed: <strong>{{ this.error_message }}</strong>
               </v-snackbar>
          </div>

          <v-dialog v-model="addDialog" persistent max-width="590px">
               <v-card class="pa-0">
                    <UserAddComponent
                         v-if="addDialog"
                         :_id="modifyUserid"
                         @onClickCancel="cancelInput"
                         @onClickInput="successInput"
                         @onFailAction="failInput"
                    />
               </v-card>
          </v-dialog>
          <ConfirmDialog ref="confirm" />
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import UserService from '@/utils/api/UserService';
     import UserAddComponent from './UserAddView';
     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import Permission from '@/utils/Permission';

     //const config = require('@/config/config.js');
     const moment = require('moment');

     export default {
          data: () => ({
               pagekey: ['lwaRc'],

               publicPath: window.location.origin+'/avatar/',

               userList: [],
               noOfPage: 0,
               page: 1,
               recordperpage: 5,

               modifyUserid: null,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',

               addDialog: false,

               selectedid: '',
          }),
          methods: {
               apiGetUserList() {
                    UserService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.userList = response.data.data;
                              let rs = response.data.recordcount;
                              let reminder = rs % this.recordperpage;
                              if (reminder > 0) reminder = 1;
                              let ps = Math.floor(rs / this.recordperpage);
                              this.noOfPage = reminder + ps;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               stopRecord(id) {
                    // eslint-disable-next-line
                    UserService.disableUser(id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.success_message = this.$t('global.successPrefix');
                                   this.is_success = true;
                                   //this.apiGetRecordsCount();
                                   this.apiGetUserList();
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               onChangePage() {
                    this.apiGetUserList();
               },
               modifyRecord(id) {
                    this.modifyUserid = id;
                    this.addDialog = true;
               },
               cancelInput() {
                    this.addDialog = false;
                    this.is_success = false;
                    this.is_fail = false;
                    this.success_message = '';
                    this.error_message = '';
                    this.modifyUserid = null;
               },
               successInput(msg) {
                    //this.apiGetRecordsCount();
                    this.apiGetUserList();
                    this.addDialog = false;
                    this.success_message = msg;
                    this.is_success = true;
                    this.is_fail = false;
                    this.modifyUserid = null;
               },
               failInput(err_msg) {
                    this.addDialog = false;
                    this.is_success = false;
                    this.error_message = err_msg;
                    this.is_fail = true;
                    this.modifyUserid = null;
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
               reloadUserCache() {
                    BrowserSession.renewSession('user', null);
               },
          },
          created() {
               this.apiGetUserList();
               //this.apiGetRecordsCount();
          },
          components: {
               UserAddComponent,
               ConfirmDialog,
          },
     };
</script>
