import http from '../HttpConn';
var isObject = require('lodash/isObject');

class BookingService {
     getAll(page = 1, limit = 9999) {
          return http.get('/mobilebooking', {
               params: { page: page, limit: limit },
          });
     }
     getBooking(id) {
          return http.get('/mobilebooking/' + id);
     }
     /* eslint-disable */
     addBooking(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/mobilebooking/add', ccobj);
     }
     updateBooking(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/mobilebooking/update/' + ccobj._id, ccobj);
     }
}

export default new BookingService();
