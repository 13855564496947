<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span class="heading_color--text">{{
                         $t('global.newrecord')
                    }}</span>
               </v-row>
          </v-container>
          <v-divider></v-divider>

          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-10 py-5 post_bg"
          >
               <div class="d-flex flex-column">
                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-select
                                   v-model="expenseObj.type"
                                   :items="typeListName"
                                   :label="$t('global.type')"
                                   hide-details
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="expenseObj.name"
                                   :label="$t('invoice.expensename')"
                                   hide-details
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row class="mt-8">
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="expenseObj.payee"
                                   :label="$t('invoice.receiver')"
                                   hide-details
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-menu
                                   v-model="showddate"
                                   :close-on-content-click="false"
                                   :nudge-right="40"
                                   transition="scale-transition"
                                   offset-y
                                   min-width="auto"
                              >
                                   <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                             v-model="expenseObj.due_date"
                                             :label="$t('invoice.duedate')"
                                             hint="YYYY-MM-DD"
                                             persistent-hint
                                             readonly
                                             outlined
                                             dense
                                             v-bind="attrs"
                                             v-on="on"
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </template>
                                   <v-date-picker
                                        v-model="expenseObj.due_date"
                                        @input="showddate = false"
                                   ></v-date-picker>
                              </v-menu>
                         </v-col>
                    </v-row>

                    <v-row class="mt-3">
                         <v-col class="py-0">
                              <v-select
                                   v-model="payment_type"
                                   :items="ApiPaymentListName"
                                   item-text="displayname"
                                   :label="$t('invoice.paymentmethod')"
                                   hide-details
                                   outlined
                                   dense
                                   return-object
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="expenseObj.amount"
                                   prefix="$"
                                   :label="$t('invoice.amount')"
                                   :hint="$t('global.required')"
                                   :rules="amountRules"
                                   type="number"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row class="mt-5">
                         <v-col class="py-0" cols="8">
                              <v-textarea
                                   v-model="expenseObj.description"
                                   :label="$t('invoice.detail')"
                                   rows="4"
                                   dense
                                   outlined
                                   background-color="#ffffff"
                              ></v-textarea>
                         </v-col>
                         <v-col class="py-0 d-flex flex-column" cols="4">
                              <v-menu
                                   v-model="showedate"
                                   :close-on-content-click="false"
                                   :nudge-right="40"
                                   transition="scale-transition"
                                   offset-y
                                   min-width="auto"
                              >
                                   <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                             v-model="expenseObj.pay_date"
                                             :label="$t('invoice.paymentdate')"
                                             hint="YYYY-MM-DD"
                                             persistent-hint
                                             readonly
                                             outlined
                                             dense
                                             v-bind="attrs"
                                             v-on="on"
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </template>
                                   <v-date-picker
                                        v-model="expenseObj.pay_date"
                                        @input="showedate = false"
                                   ></v-date-picker>
                              </v-menu>
                              <v-select
                                   v-model="expenseObj.status"
                                   :items="statusList"
                                   :label="$t('invoice.status')"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                    </v-row>
               </div>
          </v-form>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>

<script>
     /* eslint-disable */
     import ExpenseService from '@/utils/api/ExpenseService';
     import BrowserSession from '@/utils/BrowserSession';

     export default {
          props: {
               _id: String,
          },
          data: function () {
               return {
                    valid: true,
                    selectedid: null,
                    expenseObj: {},

                    showedate: false,
                    showddate: false,

                    amountRules: [
                         (v) =>
                              !Number.isNaN(Number(v)) ||
                              'Amount should be a number',
                    ],

                    typeListName: ['expense', 'bill'],

                    ApiPaymentListName: [],
                    payment_type: '',

                    statusList: [
                         this.$t('invoice.paymentinit'),
                         this.$t('invoice.paymentdone'),
                    ],
                    statusListName: ['init', 'done'],
               };
          },
          methods: {
               apiGetSettingPrefix() {
                         BrowserSession.getSession('setting', (data) => {
                              let ptype = data.find((d) => {
                                   return d.key == 'invoice';
                              }).value['payment_type'];
                              this.ApiPaymentListName = ptype.filter(e=>e.enable);

                              this.ApiPaymentListName = this.ApiPaymentListName.map(e=>{
                                   return {...e, ...{displayname: this.$t('invoice.' + e.name)}}
                              });

                              if(this.ApiPaymentListName.length > 0){
                                   this.payment_type = this.ApiPaymentListName[0];
                              }
                         });
                    },
               addNewRecord() {
                    ExpenseService.addExpense({
                         ...this.expenseObj,
                         ...{
                              payment_type: this.payment_type.name,
                              status: this.statusListName[
                                   this.statusList.indexOf(this.expenseObj.status)
                              ],
                         },
                    })
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix')
                                   );
                              else this.$emit('onFail', response.data.message);
                         })
                         .catch((e) => {
                              this.$emit('onFail', e.message);
                         });
               },
               cancel() {
                    this.$emit('onClickCancel', null);
               },
          },
          created() {
               this.expenseObj = {
                    type: 'expense',
                    name: '',
                    pay_date: new Date().toISOString().substr(0, 10),
                    due_date: null,
                    payee: null,
                    invoiceno: null,
                    invoiceid: null,
                    description: '',
                    amount: 0.0,
                    payment_type: this.$t('invoice.cash'),
                    status: this.$t('invoice.paymentdone'),
               };

               this.apiGetSettingPrefix();
          },
     };
</script>
