<template>
     <div class="pa-7">
          <div class="white rounded elevation-3" v-if="havePermit()">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span
                              class="text1_color--text"
                              >{{$t('components.appointment')}}</span
                         >
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                        >{{$t('global.morefunction')



                                        }}<v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  >{{$t('global.import')}}</v-list-item-title
                                             >
                                        </v-list-item-content>
                                   </v-list-item>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{$t('global.export')}}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="showAddEventDialog"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span
                                   class="white--text"
                                   >{{ $t('global.add') }}</span
                              >
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg mx-4 my-2 rounded">
                    <v-container fluid class="d-flex flex-column">
                         <div class="d-flex flex-row justify-space-between">
                              <div id="searchboxa" class="d-flex flex-row">
                                   <!--<v-text-field
                                        single-line
                                        :label="$t('invoice.searchplaceholder')"
                                        v-model="searchcontent"
                                        prepend-inner-icon="mdi-magnify"
                                        outlined
                                        style="width: 400px"
                                        background-color="white"
                                        dense
                                        hide-details
                                        clearable
                                        clear-icon="mdi-close"
                                        @keydown.enter.prevent="setFilter"
                                        ref="searchtextfield"
                                   >
                                        <template v-slot:append>
                                             <v-btn
                                                  icon
                                                  @click="triggerAdvFilter"
                                             >
                                                  <v-badge
                                                       :content="asearchnotify"
                                                       :value="asearchnotify"
                                                       color="red"
                                                       overlap
                                                  >
                                                       <v-icon
                                                            small
                                                            color="primary"
                                                            >mdi-book-open</v-icon
                                                       >
                                                  </v-badge>
                                             </v-btn>
                                        </template>
                                   </v-text-field>-->

                                   <v-select
                                        v-model="userfilter"
                                        :items="userList"
                                        item-value="_id"
                                        item-text="name"
                                        :label="$t('appointment.salesfilter')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        style="width: 200px"
                                        multiple
                                        small-chips
                                        return-object
                                        hide-details
                                        background-color="white"
                                   >
                                        <template
                                             v-slot:selection="{ item, index }"
                                        >
                                             <v-chip
                                                  :color="colors[0]"
                                                  v-if="index === 0"
                                                  small
                                             >
                                                  <span
                                                       style="color:white"
                                                       >{{ item.name }}</span
                                                  >
                                             </v-chip>
                                             <span
                                                  v-if="index === 1"
                                                  class="grey--text text-caption"
                                             >
                                                  (+{{ userfilter.length - 1}}
                                                  ...)
                                             </span>
                                        </template>
                                   </v-select>
                                   <v-select
                                        id="resource_combo"
                                        v-model="resourcefilter"
                                        :items="resourceList"
                                        item-value="_id"
                                        item-text="name"
                                        :label="$t('appointment.resourcefilter')"
                                        outlined
                                        dense
                                        class="mx-2"
                                        style="width: 200px"
                                        multiple
                                        small-chips
                                        hide-details
                                        return-object
                                        background-color="white"
                                   >
                                        <template
                                             v-slot:selection="{ item, index }"
                                        >
                                             <v-chip
                                                  :color="colors[1]"
                                                  v-if="index === 0"
                                                  small
                                             >
                                                  <span
                                                       style="color:white"
                                                       >{{ item.name }}</span
                                                  >
                                             </v-chip>
                                             <span
                                                  v-if="index === 1"
                                                  class="grey--text text-caption"
                                             >
                                                  (+{{resourcefilter.length - 1}}
                                                  ...)
                                             </span>
                                        </template>
                                   </v-select>
                              </div>

                              <div>
                                   <v-btn
                                        color="primary"
                                        class="body-2 font-weight-bold mx-2"
                                        @click="setFilter"
                                   >
                                        <span>{{ $t('global.filter') }}</span>
                                   </v-btn>
                                   <v-btn
                                        color="grey"
                                        class="body-2 font-weight-bold"
                                        text
                                        @click="resetFilter"
                                   >
                                        <span class="grey--text">{{
                                             $t('global.cancel')
                                        }}</span>
                                   </v-btn>
                              </div>
                         </div>
                    </v-container>
                    <v-progress-linear
                         indeterminate
                         v-if="searchloading"
                    ></v-progress-linear>
               </div>

               <v-divider></v-divider>

               <div>
                    <v-row class="px-10 pt-4" align="center">
                         <v-col cols="1" class="pa-0">
                              <div
                                   class="d-flex flex-column"
                                   style="align-items: center;"
                              >
                                   <v-btn
                                        icon
                                        small
                                        color="grey darken-2"
                                        @click="prev_Year"
                                   >
                                        <v-icon small> mdi-chevron-up </v-icon>
                                   </v-btn>
                                   <div
                                        class="my-1 pa-2"
                                        style="border-radius: 5px; background: #edf2f9; 
                                             width:55px; text-align: center;
                                             box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);"
                                   >
                                        {{ syear }}
                                   </div>
                                   <v-btn
                                        icon
                                        small
                                        color="grey darken-2"
                                        @click="next_Year"
                                   >
                                        <v-icon small>
                                             mdi-chevron-down
                                        </v-icon>
                                   </v-btn>
                              </div>
                         </v-col>
                         <v-col cols="1" class="pa-0">
                              <div
                                   class="d-flex flex-column"
                                   style="align-items: center;"
                              >
                                   <v-btn
                                        icon
                                        small
                                        color="grey darken-2"
                                        @click="prev_Month"
                                   >
                                        <v-icon small> mdi-chevron-up </v-icon>
                                   </v-btn>
                                   <div
                                        class="my-1 pa-2"
                                        style="border-radius: 5px; background: #edf2f9; 
                                             width:55px; text-align: center;
                                             box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);"
                                   >
                                        {{ formatMonth() }}
                                   </div>
                                   <v-btn
                                        icon
                                        small
                                        color="grey darken-2"
                                        @click="next_Month"
                                   >
                                        <v-icon small>
                                             mdi-chevron-down
                                        </v-icon>
                                   </v-btn>
                              </div>
                         </v-col>

                         <!--<v-toolbar-title v-if="$refs.calendar">
                              {{ $refs.calendar.title }}
                         </v-toolbar-title>
                         <span>&nbsp;&nbsp;</span>
                         <v-btn
                              fab
                              text
                              small
                              color="grey darken-2"
                              @click="prev"
                         >
                              <v-icon small> mdi-chevron-left </v-icon>
                         </v-btn>

                         <v-btn
                              fab
                              text
                              small
                              color="grey darken-2"
                              @click="next"
                         >
                              <v-icon small> mdi-chevron-right </v-icon>
                         </v-btn>-->
                         <v-col cols="10" class="pa-0">
                              <v-slide-group
                                   v-model="slidemodel"
                                   class="pa-2"
                                   active-class="success"
                                   show-arrows
                                   mandatory
                                   center-active
                              >
                                   <v-slide-item
                                        v-for="n in maxday"
                                        :key="n"
                                        v-slot="{ active, toggle }"
                                   >
                                        <v-card
                                             style="width:40px; height:55px; margin:5px; border-radius: 7px;  padding:5px;"
                                             :class="active?'selectedbg':'normalbg'"
                                             @click="selectdate(toggle, n)"
                                        >
                                             <div
                                                  style="text-align:center;"
                                                  :class="checkIsToday(n)?'text-decoration-overline font-weight-bold':''"
                                             >
                                                  {{ n }}
                                             </div>
                                             <div
                                                  style="text-align: center; font-size:12px;"
                                             >
                                                  {{ formatMonth() }}
                                             </div>
                                        </v-card>
                                   </v-slide-item>
                              </v-slide-group>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="px-10 py-5">
                              <v-btn
                                   fab
                                   dark
                                   large
                                   color="primary"
                                   fixed
                                   right
                                   bottom
                                   style="bottom: 5%; right: 2%"
                                   @click="showAddEventDialogWithDate"
                              >
                                   <v-icon dark>mdi-plus</v-icon>
                              </v-btn>
                              <v-calendar
                                   ref="calendar"
                                   v-model="focus"
                                   color="primary"
                                   type="category"
                                   category-show-all
                                   category-days="1"
                                   first-interval="18"
                                   interval-minutes="30"
                                   interval-count="24"
                                   interval-height="32"
                                   locale="zh-hk"
                                   :events="events"
                                   :categories="categories"
                                   :event-color="getEventColor"
                                   @change="fetchEvents"
                                   @click:event="showEvent"
                                   @click:time-category="showAddEventDialogWithDetail"
                              ></v-calendar>
                         </v-col>
                    </v-row>
               </div>
               <v-dialog v-model="eventDialog" max-width="375px">
                    <v-card class="pa-0">
                         <EventComponent
                              v-if="eventDialog"
                              @onClickCancel="cancelInput"
                              @onClickInput="successInput"
                              @onFailAction="failInput"
                              @onClickCompleted="completedInput"
                              @onClickModify="modifyEventInput"
                              :_id="selectedid"
                         />
                    </v-card>
               </v-dialog>
               <v-dialog
                    v-model="eventAddDialog"
                    persistent
                    :max-width="defaultwidth"
               >
                    <v-card class="pa-0">
                         <EventAddComponent
                              v-if="eventAddDialog"
                              @onClickCancel="cancelInput"
                              @onClickInput="successInput"
                              @onFailAction="failInput"
                              @onExpand="modifyDialogSize"
                              :_date="selecteddate"
                              :_time="selectedtime"
                              :_staff="selectedstaff"
                              :_appointmentid="selectedid"
                              :_resource="selectedresource"
                         />
                    </v-card>
               </v-dialog>

               <v-snackbar
                    v-model="is_success"
                    color="primary accent-2"
                    elevation="24"
               >
                    Success: <strong>{{ this.success_message }}</strong>
               </v-snackbar>
               <v-snackbar
                    v-model="is_fail"
                    color="red accent-2"
                    elevation="24"
               >
                    Failed: <strong>{{ this.error_message }}</strong>
               </v-snackbar>
          </div>
     </div>
</template>

<script>
     /* eslint-disable */
     import _ from 'lodash';
     import AppointmentService from '@/utils/api/AppointmentService';
     import EventComponent from './EventView';
     import EventAddComponent from './EventAddView';
     import BrowserSession from '@/utils/BrowserSession';
     import Permission from '@/utils/Permission';

     const moment = require('moment');
     var isEmpty = require('lodash/isEmpty');
     const monthstr = [ "January", "February", "March", "April", "May", "June",
           "July", "August", "September", "October", "November", "December" ];
     const zeroPad = (num, places) => String(num).padStart(places, '0');

     export default {
          data: () => ({
               pagekey: ['TiAFN'],
               managekey: ['xtDEL'],

               //selected month 0-11
               smonth: 0,
               //max days on this selected month
               maxday: 0,
               //selected year
               syear: 0,

               //selected day 0-30
               slidemodel: 0,
               //previous selected day
               prev_slidemodel: 0,

               //today
               today_day: 0,
               today_year: 0,
               today_month: 0,

               focus: '',
               events: [],

               names: [
                    'Meeting',
                    'Holiday',
                    'PTO',
                    'Travel',
                    'Event',
                    'Birthday',
                    'Conference',
                    'Party',
               ],
               categories: [],

               addDialog: false,
               userfilter: null,
               userList: [],
               resourcefilter: null,
               resourceList: [],
               filterdate: {
                    startdate: new Date(),
                    enddate: new Date(Date.now() + 24 * 60 * 60 * 1000),
               },
               filterquery: {},

               appointmentList: [],

               showBy: 'd', //d:day, w:week
               typeToLabel: {
                    w: 'Week',
                    d: 'Day',
               },

               eventDialog: false,
               eventAddDialog: false,

               is_fail: false,
               is_success: false,
               success_message: '',
               error_message: '',

               selectedid: '',
               selecteddate: '',
               selectedtime: {},
               selectedstaff: {},
               selectedresource: {},

               searchloading:false,

               defaultwidth: '450px',
               colors: ['#6aadd7', '#d5b077', '#737373'],
          }),
          methods: {
               getEventColor(event) {
                    return event.color;
               },
               fetchEvents(obj) {
                    console.log('Running fetch Events');
                    //console.log(obj);
                    if(!_.isEmpty(this.categories))
                         this.apiGetAppointmentByDate(this.syear+'-'+zeroPad(this.smonth+1, 2)+'-'+zeroPad(this.slidemodel+1, 2));
               },
               buildCategories() {
                    this.categories = [];

                    if (this.userfilter)
                         this.userfilter.map((item) => {
                              this.categories = this.categories.concat(item.name);
                         });
                    else
                         this.userList.map((item) => {
                              this.categories = this.categories.concat(item.name);
                         });

                    if (this.resourcefilter)
                         this.resourcefilter.map((item) => {
                              this.categories = this.categories.concat(item.name);
                         });
                    /*else
                         this.resourceList.map((item) => {
                              this.categories = this.categories.concat(item.name);
                         });*/
               },
               apiGetStuff() {
                    this.categories = [];
                    BrowserSession.getSession('user', (data) => {
                         this.userList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
                    BrowserSession.getSession('resource', (data) => {
                         this.resourceList = data.filter((d) => {
                              return d.status == 'active';
                         });
                    });
               },
               apiGetAppointmentByDate(sdate) {
                    //build filter first
                    let filterquery = [];
                    if (this.userfilter) {
                         this.userfilter.map((item) => {
                              filterquery.push({ staffid: item._id });
                         });
                    }
                    if (this.resourcefilter) {
                         this.resourcefilter.map((item) => {
                              filterquery.push({ resourceid: item._id });
                         });
                    }

                    let query = { startdate: sdate+'T00:00:00.000+08:00', enddate: sdate+'T23:59:59.000+08:00' };

                    if (!isEmpty(filterquery)) {
                         query = {
                              ...query,
                              ...{ filter: filterquery, filterlogic: 'or' },
                         };
                    }

                    //console.log(query);
                    AppointmentService.getAppointmentByFilter(query)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.appointmentList = response.data.data;
                                   this.formatEvent();
                              }
                         })
                         .catch((e) => {
                              console.log(e.response);
                         });
               },
               formatEvent() {
                    this.events = [];
                    //by staff
                    this.appointmentList.map((appointment) => {
                         this.events.push({
                              _id: appointment._id,
                              /*name: appointment.service_name
                                   ? appointment.service_name
                                   : '一般預約' +
                                     '(' +
                                     appointment.customer_name +
                                     ')',*/
                              name: appointment.customer_name,
                              start: new Date(appointment.start_at),
                              end: new Date(appointment.end_at),
                              color: appointment.completed?this.colors[2]:this.colors[0],
                              timed: true,
                              category: appointment.staff_name,
                              singleline: false,
                              eventSummary: appointment.remark,
                         });
                    });
                    //by resource
                    this.appointmentList.map((appointment) => {
                         if (appointment.resourceid) {
                              this.events.push({
                                   _id: appointment._id,
                                   /*name: appointment.service_name
                                        ? appointment.service_name
                                        : '一般預約' +
                                          '(' +
                                          appointment.staff_name +
                                          ')',*/
                                   name: appointment.staff_name,
                                   start: new Date(appointment.start_at),
                                   end: new Date(appointment.end_at),
                                   color: this.colors[1],
                                   timed: true,
                                   category: appointment.resource_name,
                                   singleline: false,
                                   eventSummary: appointment.remark,
                              });
                         }
                    });
                    //console.log(this.events);
               },
               setFilter() {
                    //select all staff
                    if(!this.userfilter)
                         this.userfilter = this.userList;
                    //build categories that will display on calendar
                    this.buildCategories();

                    /*let sdate = this.$refs.calendar.parsedValue.date;
                    let edate = new Date(sdate);
                    edate.setDate(edate.getDate() + 1);*/

                    this.apiGetAppointmentByDate(this.syear+'-'+zeroPad(this.smonth+1, 2)+'-'+zeroPad(this.slidemodel+1, 2));
               },
               resetFilter() {
                    this.categories = [];
                    this.filterquery = {};
                    this.userfilter = null;
                    this.resourcefilter = null;
               },
               showAddEventDialog() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = 'Sorry, you dont have  to access!';
                         this.is_fail = true;
                         return;
                    }

                    this.defaultwidth = '450px';
                    this.eventAddDialog = true;

                    this.selectedid = null;
                    this.selecteddate = '';
                    this.selectedtime = {};
                    this.selectedstaff = {};
                    this.selectedresource = {};
               },
               showAddEventDialogWithDate(){
                    this.defaultwidth = '450px';
                    this.eventAddDialog = true;

                    this.selectedid = null;
                    this.selecteddate = this.focus;
                    this.selectedtime = {};
                    this.selectedstaff = {};
                    this.selectedresource = {};
               },
               showAddEventDialogWithDetail(timeObj){
                    if(this.eventDialog) return;
                    //console.log('showAddEventDialogWithDetail');
                    if(!timeObj.category){
                         return;
                    }

                    this.defaultwidth = '740px';
                    this.eventAddDialog = true;

                    this.selectedid = null;
                    this.selecteddate = this.focus;
                    this.selectedtime = timeObj;
                    this.selectedstaff = _.find(this.userList, {name: timeObj.category});
                    this.selectedresource = _.find(this.resourceList, {name: timeObj.category});
               },
               // eslint-disable-next-line
               showEvent({ nativeEvent, event }) {
                    this.selectedid = event._id;
                    this.eventDialog = true;
               },
               modifyEventInput(){
                    this.eventDialog = false;

                    this.defaultwidth = '740px';
                    this.eventAddDialog = true;
               },
               cancelInput() {
                    this.eventDialog = false;
                    this.eventAddDialog = false;
               },
               successInput(msg) {
                    this.setFilter();

                    this.eventDialog = false;
                    this.eventAddDialog = false;

                    this.success_message = msg;
                    this.is_success = true;
               },
               failInput(err_msg) {
                    this.eventDialog = false;
                    this.eventAddDialog = false;

                    this.error_message = err_msg;
                    this.is_fail = true;
               },
               completedInput(isCompleted){
                    let event = _.find(this.events, {_id: this.selectedid});
                    event.color = isCompleted?this.colors[2]:this.colors[0]
               },
               modifyDialogSize(){
                    this.defaultwidth = '740px';
               },
               handleError(msg) {
                    this.error_message = msg;
                    this.is_fail = true;
               },
               prev() {
                    //console.log(this.focus);
                    this.$refs.calendar.prev();
               },
               next() {
                    //console.log(this.focus);
                    this.$refs.calendar.next();
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
               selectdate(toggle, day){
                    toggle();
                    this.$refs.calendar.move(day - (this.prev_slidemodel+1));
                    this.prev_slidemodel = this.slidemodel;
               },
               prev_Year(){
                    this.syear--;

                    this.setCalendarDateAndGetAppointments();
               },
               next_Year(){
                    this.syear++;

                    this.setCalendarDateAndGetAppointments();
               },
               prev_Month(){
                    //console.log(this.focus);
                    this.smonth--;
                    if(this.smonth<0)
                         this.smonth = 11;

                    this.setCalendarDateAndGetAppointments();
               },
               next_Month(){
                    //console.log(this.focus);
                    this.smonth++;
                    if(this.smonth>11)
                         this.smonth=0;

                    this.setCalendarDateAndGetAppointments();
               },
               setCalendarDateAndGetAppointments(){
                    this.focus = this.syear+'-'+zeroPad(this.smonth+1, 2)+'-'+zeroPad(this.slidemodel+1, 2);
                    //api call, only when filter was set
                    if(!_.isEmpty(this.categories))
                         this.apiGetAppointmentByDate(this.focus);
               },
               formatMonth(){
                    return monthstr[this.smonth].substring(0,3);
               },
               getMaxDayOfMonth(){
                    this.maxday = moment('01-'+this.smonth+'-'+this.syear, 'DD-MM-YYYY').endOf('month').date();
               },
               checkIsToday(day){
                    return (day - 1 == this.today_day) && (this.syear == this.today_year) && (this.smonth == this.today_month);
               }
          },
          watch:{
               smonth: function(newval){
                    this.apiGetAppointmentByDate(this.syear+'-'+zeroPad(newval+1, 2)+'-'+zeroPad(this.slidemodel+1,2))
               }
          },
          created() {
               this.apiGetStuff();
          },
          components: {
               EventComponent,
               EventAddComponent,
          },
          mounted () {
               this.$refs.calendar.checkChange();

               //get current month
               this.smonth = moment().month();
               //get current number of day
               this.maxday = moment().endOf('month').date();

               //preset today
               this.slidemodel = moment().date() - 1;
               this.prev_slidemodel = moment().date() - 1;
               this.today_day = this.slidemodel;

               this.today_year = moment().year();
               this.syear = this.today_year;

               this.today_month = moment().month();

               //console.log(this.smonth);
               //console.log(this.maxday);
          },
     };
</script>

<style scoped>
     #searchboxa label{
          top: 10px;
     }
     .selectedbg{
          background-color:  #1976d2;
          color: white;
     }
     .normalbg{
          background-color: #edf2f9;
     }
</style>
