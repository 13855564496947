<template>
     <div class="pa-7">
          <div class="white rounded elevation-3" v-if="havePermit()">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">
                              {{ $t('components.permissiongroup') }}
                         </span>
                         <v-spacer></v-spacer>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="showAddRoleDialog()"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">
                                   {{ $t('global.add') }}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>

               <v-divider></v-divider>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="8">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{ $t('user.groupname') }}
                                   </span>
                              </v-col>
                              <v-col cols="4">
                                   <span class="caption heading_color--text">
                                        {{ $t('global.createdat') }}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(role, index) in roleList"
                         :key="role._id"
                         class="px-3"
                         align="center"
                    >
                         <v-col cols="8" class="d-flex">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        (page - 1) * 5 + index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="d-flex flex-column">
                                   <!--<a :href="`/role/${role._id}`" 
                class="text-decoration-none text-subtitle-1">
              {{ role.role }}</a>-->
                                   <span
                                        class="text-decoration-none text-subtitle-1"
                                        >{{ role.role }}</span
                                   >
                              </div>
                         </v-col>

                         <v-col cols="4">
                              <div
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <div class="text1_color--text text-body-2">
                                        {{ formatDate(role.created_date) }}
                                   </div>
                                   <v-menu
                                        offset-y
                                        v-if="role.role != 'administrator'"
                                   >
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       modifyRecord(role._id)
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-pencil-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                  >
                                                       {{ $t('global.modify') }}
                                                  </v-list-item-title>
                                             </v-list-item>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       deleteRecord(role._id)
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-delete-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                  >
                                                       {{ $t('global.delete') }}
                                                  </v-list-item-title>
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>
          </div>

          <v-dialog v-model="addDialog" persistent max-width="590px">
               <v-card class="pa-0">
                    <UserRoleAddComponent
                         v-if="addDialog"
                         :_id="selectedid"
                         @onClickCancel="cancelInput"
                         @onClickInput="successInput"
                         @onFailAction="failInput"
                    />
               </v-card>
          </v-dialog>

          <ConfirmDialog ref="confirm" />

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import Permission from '@/utils/Permission';
     import RoleService from '@/utils/api/RoleService';
     import UserRoleAddComponent from './UserRoleAddView';
     import ConfirmDialog from '@/views/components/ConfirmDialog';

     const moment = require('moment');

     export default {
          data: () => ({
               selectedid: null,

               pagekey: ['lwaRc'],

               addDialog: false,

               roleList: [],
               noOfPage: 0,
               page: 1,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',
          }),
          methods: {
               apiGetRoleList() {
                    RoleService.getAll(this.page, 5)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.roleList = response.data.data;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetRecordsCount() {
                    RoleService.getCount()
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let rs = response.data.data;
                                   let reminder = rs % 5;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / 5);
                                   this.noOfPage = reminder + ps;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               onChangePage() {
                    this.apiGetRoleList();
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               showAddRoleDialog() {
                    this.selectedid = null;
                    this.addDialog = true;
               },
               modifyRecord(id) {
                    this.selectedid = id;
                    this.addDialog = true;
                    //console.log(id);
               },
               async deleteRecord(id) {
                    //console.log(id);
                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('components.confirmdeletemsg')
                         )
                    ) {
                         //going to remove this role!
                         RoleService.deleteRole(id)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.success_message = this.$t('components.successdeletedmsg');
                                        this.is_success = true;
                                        this.apiGetRecordsCount();
                                        this.apiGetRoleList();
                                   } else {
                                        this.error_message = response.data.message;
                                        this.is_fail = true;
                                   }
                              })
                              .catch((e) => {
                                   if (e.response.status == 400) {
                                        ///console.log(e.response);
                                        this.error_message = e.response.data.message;
                                   } else {
                                        this.error_message = e.message;
                                   }

                                   this.is_fail = true;
                              });
                    }
               },
               cancelInput() {
                    this.addDialog = false;
               },
               successInput(msg) {
                    this.apiGetRecordsCount();
                    this.apiGetRoleList();

                    this.addDialog = false;
                    this.success_message = msg;
                    this.is_success = true;
               },
               failInput(msg) {
                    this.addDialog = false;
                    this.error_message = msg;
                    this.is_fail = true;
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
          },
          created() {
               this.apiGetRecordsCount();
               this.apiGetRoleList();
          },
          components: {
               UserRoleAddComponent,
               ConfirmDialog,
          },
     };
</script>
