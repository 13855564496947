import http from '../HttpConn';
var isObject = require('lodash/isObject');

class BlogService {
     getAll(page = 1, limit = 9999) {
          return http.get('/blog', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page = 1, limit = 9999) {
          return http.get('/blog/allActive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/blog/count');
     }
     getBlog(id) {
          return http.get('/blog/' + id);
     }
     /* eslint-disable */
     updateBlog(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/blog/update/' + ccobj._id, ccobj);
     }
     addBlog(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/blog/add', ccobj);
     }
     deleteBlog(id) {
          return http.delete('/blog/delete/' + id);
     }
     /* eslint-enable */
}
export default new BlogService();
