import http from '../HttpConn';
var isObject = require('lodash/isObject');
class CustomerService {
     getAll(page = 1, limit = 9999) {
          return http.get('/customer', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page = 1, limit = 9999) {
          return http.get('/customer/allActive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/customer/count');
     }
     getCustomer(id) {
          return http.get('/customer/' + id);
     }
     /* eslint-disable */
     updateCustomer(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/customer/update/' + ccobj._id, ccobj);
     }
     addCustomer(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/customer/add', ccobj);
     }
     deleteCustomer(id) {
          return http.delete('/customer/delete/' + id);
     }

     searchCustomer(page, limit, valueobj) {
          if (!valueobj || valueobj.value == null)
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          return http.post('/customer/search/', {
               ...valueobj,
               ...{ params: { page: page, limit: limit } },
          });
     }

     updateCustomerReward(ccobj) {
          //check ccobj
          //{_id, inc_re_point, inc_lv_point, new_re_point, new_lv_point,
          // new_reward_expiry, new_member_lv_expiry, new_member_lv}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.put('/customer/reward/update/' + ccobj._id, ccobj);
     }

     updateCustomerStatus(ccobj) {
          //{_id, status}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/customer/updatestatus/' + ccobj._id, ccobj);
     }
     updateLoginDetail(ccobj) {
          //{_id, phone, password, enable_member_login, auth_password}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/customer/updateLogin/' + ccobj._id, ccobj);
     }
     advsearchCustomer(page, limit, ccobj) {
          //{customername, customerphone, customeremail, salesid, tag}
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/customer/asearch/', {
               ...ccobj,
               ...{ params: { page: page, limit: limit } },
          });
          /*return http.post('/customer/searchcustomer/', {
               ...ccobj,
               ...{ params: { page: page, limit: limit } },
          });*/
     }
     comboSearch(page, limit, ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/customer/searchcustomer/', {
               ...ccobj,
               ...{ params: { page: page, limit: limit } },
          });
     }
     importCustomer(obj) {
          if (!isObject(obj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/customer/importData/', obj);
     }
     /* eslint-enable */
}
export default new CustomerService();
