<template>
     <div class="pa-7" v-if="Object.keys(customer).length > 0">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="heading_color--text">
                              {{ customer.name }}
                         </span>
                         <span>&nbsp;&nbsp;&nbsp;</span>
                         <!-- <v-icon size="15">mdi-phone</v-icon> -->
                         <span
                              v-if="customer.member.length > 0"
                              class="heading_color--text"
                         >
                              (
                              {{ customer.member[0].customer_category_name }} )
                         </span>
                         <v-spacer></v-spacer>
                         <!--<v-btn
                              color="primary"
                              class="body-2 font-weight-bold mr-2"
                         >
                              <v-icon size="18">mdi-email-edit</v-icon>
                              <span class="white--text">{{
                                   $t('customer.email')
                              }}</span>
                         </v-btn>
                         <v-btn color="primary" class="body-2 font-weight-bold">
                              <v-icon size="18">mdi-calendar-plus</v-icon>
                              <span class="white--text">{{
                                   $t('components.appointment')
                              }}</span>
                         </v-btn>-->
                    </v-row>
               </v-container>
          </div>
          <div class="d-flex flex-row">
               <div
                    class="white rounded elevation-3 pa-5 mt-10 mr-2"
                    style="width: 33%"
               >
                    <div style="text-align: center">
                         <v-avatar size="100">
                              <img
                                   :src="
                                        customer.gender == 'M' ||
                                        customer.gender == '男'
                                             ? '/avatar/male.jpg'
                                             : '/avatar/female.jpg'
                                   "
                              />
                         </v-avatar>
                         <div class="text1_color--text text-h5">
                              {{ customer.name }}
                              <v-icon
                                   small
                                   :color="
                                        customer.status == 'active'
                                             ? 'primary'
                                             : 'red'
                                   "
                                   >{{
                                        customer.status == 'active'
                                             ? 'mdi-checkbox-marked-circle-outline'
                                             : 'mdi-cancel'
                                   }}</v-icon
                              >
                         </div>
                         <div class="text2_color--text text-body-1">
                              {{ customer.company }}
                         </div>
                    </div>
                    <div class="mt-5 d-flex flex-row justify-space-between">
                         <div class="font-weight-medium">
                              {{ $t('customer.contactdetail') }}
                         </div>
                         <v-btn icon small @click="editRecord"
                              ><v-icon small color="primary"
                                   >mdi-pencil-circle</v-icon
                              ></v-btn
                         >
                    </div>

                    <v-divider class="pb-3"></v-divider>

                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.phone') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ customer.phone }}
                         </span>
                    </div>

                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.email') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ customer.email }}
                         </span>
                    </div>

                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.address') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ customer.address }}
                         </span>
                    </div>

                    <div class="mt-5 font-weight-medium">
                         {{ $t('customer.otherdetail') }}
                    </div>
                    <v-divider class="pb-3"></v-divider>
                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.dob') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ formatDate(customer.dob) }}
                         </span>
                    </div>
                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.gender') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ customer.gender }}
                         </span>
                    </div>
                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.detail') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ customer.description }}
                         </span>
                    </div>

                    <div class="mt-5 font-weight-medium">
                         {{ $t('customer.randm') }}
                    </div>
                    <v-divider class="pb-3"></v-divider>
                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.reward') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ customer.re_point }}
                         </span>
                    </div>
                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('global.expiry') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ formatDate(customer.reward_expiry) }}
                         </span>
                    </div>
                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('customer.membershiplv') }}
                         </div>
                         <span
                              v-if="customer.member.length >0"
                              class="text1_color--text font-weight-light"
                         >
                              {{ customer.member[0].customer_category_name }}
                              ({{ customer.lv_point }} pt)
                         </span>
                    </div>
                    <div class="pb-3">
                         <div class="text2_color--text text-body-2">
                              {{ $t('global.expiry') }}
                         </div>
                         <span class="text1_color--text font-weight-light">
                              {{ formatDate(customer.member_lv_expiry) }}
                         </span>
                    </div>

                    <div class="mt-5">{{ $t('customer.tag') }}</div>
                    <v-divider class="pb-3"></v-divider>

                    <div class="pb-3">
                         <span
                              v-for="(tag, index) in customer.tag"
                              :key="index"
                         >
                              <v-chip class="ma-1" label small>
                                   {{ tag }}
                              </v-chip>
                         </span>
                    </div>
               </div>

               <div
                    class="white rounded elevation-3 mt-10 pb-1"
                    style="width: 67%"
               >
                    <v-tabs show-arrows background-color="heading_bg">
                         <v-tab>{{ $t('global.remark') }}</v-tab>
                         <v-tab>{{ $t('components.invoice') }}</v-tab>
                         <v-tab>{{ $t('components.payment') }}</v-tab>
                         <v-tab>{{ $t('product.stock') }}</v-tab>
                         <v-tab>{{ $t('global.log') }}</v-tab>

                         <v-tab-item>
                              <v-divider></v-divider>
                              <TabCustomerNoteComponent
                                   :customerobj="customer"
                              />
                         </v-tab-item>

                         <v-tab-item>
                              <v-divider></v-divider>
                              <TabCustomerInvoiceComponent
                                   :customerobj="customer"
                              />
                         </v-tab-item>

                         <v-tab-item>
                              <v-divider></v-divider>
                              <TabCustomerPaymentComponent
                                   :customerobj="customer"
                              />
                         </v-tab-item>

                         <v-tab-item>
                              <v-divider></v-divider>
                              <TabCustomerInventoryComponent
                                   :customerobj="customer"
                              />
                         </v-tab-item>

                         <v-tab-item>
                              <v-divider></v-divider>
                              <TabCustomerLogComponent
                                   :customerobj="customer"
                              />
                         </v-tab-item>
                    </v-tabs>
               </div>
          </div>

          <v-dialog v-model="editdialog" persistent max-width="790px">
               <v-card class="pa-0">
                    <CustomerAddComponent
                         v-if="editdialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                         :_id="selectedid"
                    />
               </v-card>
          </v-dialog>
          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import CustomerService from '@/utils/api/CustomerService';
     //import CustomerCategoryService from '@/utils/api/CustomerCategoryService';
     //import InvoiceService from '@/utils/api/InvoiceService';
     import CustomerAddComponent from '@/views/customer/CustomerAddView';
     import TabCustomerLogComponent from '@/views/customer/TabCustomerLogView';
     import TabCustomerInvoiceComponent from '@/views/customer/TabCustomerInvoiceView';
     import TabCustomerNoteComponent from '@/views/customer/TabCustomerNoteView';
     import TabCustomerPaymentComponent from '@/views/customer/TabCustomerPaymentView';
     import TabCustomerInventoryComponent from '@/views/customer/TabCustomerInventoryView';

     const moment = require('moment');
     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });

     export default {
          data() {
               return {
                    selectedid: null,

                    valid: true,
                    customer: {},
                    objdata: {},

                    nameRules: [
                         (v) => !!v || 'Name is required',
                         (v) =>
                              (v && v.length <= 50) ||
                              'Name must be less than 50 characters',
                    ],
                    gender: ['男', '女'],
                    numberRules: [
                         (v) => !!v || 'Discount is required',
                         (v) =>
                              (!isNaN(parseInt(v)) && v >= 0 && v <= 100) ||
                              'Discount must between 0 and 100',
                    ],
                    membership: '',
                    memberlv: [],
                    isActive: true,

                    invoiceList: [],
                    inv_page: 1,
                    inv_noOfPage: 1,

                    editdialog: false,

                    is_fail: false,
                    is_success: false,
                    error_message: '',
                    success_message: '',
               };
          },
          methods: {
               apiGetCustomer() {
                    //console.log(this.$route.params.id);
                    CustomerService.getCustomer(this.selectedid)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   //console.log(response.data)
                                   if(data.status == 'verify'){
                                        throw new Error('account not active!');
                                   }

                                   this.customer = data;
                                   this.isActive = this.customer.status == 'active';
                                   this.objdata = {
                                        ...this.objdata,
                                        ...this.customer,
                                   };
                                   this.membership = data.member[0]._id;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.error_message = e.message;
                              this.msg_fail = true;
                         });
               },
               apiGetCustomerCategory() {
                    BrowserSession.getSession('customer_category', (dd) => {
                         this.memberlv = dd;
                    });
               },
               formatDate(value) {
                    if (!value) return 'NONE';
                    return moment(value).format('YYYY-MM-DD');
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               editRecord() {
                    this.editdialog = true;
               },
               onClickCancel() {
                    this.editdialog = false;
               },
               onClickInput(msg) {
                    this.success_message = msg;
                    this.is_success = true;

                    this.apiGetCustomer();
                    this.editdialog = false;
               },
               onFail(msg) {
                    this.error_message = msg;
                    this.is_fail = true;
                    this.editdialog = false;
               },
          },
          created() {
               this.selectedid = this.$route.params.id || this.$route.query.id;
               //console.log(this.selectedid);

               this.apiGetCustomerCategory();
               this.apiGetCustomer();

               //this.apiGetCustomerInvoiceCount();
               //this.apiGetCustomerInvoice();
          },
          components: {
               CustomerAddComponent,
               TabCustomerLogComponent,
               TabCustomerInvoiceComponent,
               TabCustomerNoteComponent,
               TabCustomerPaymentComponent,
               TabCustomerInventoryComponent,
          },
     };
</script>

<style>
     .v-chip.theme--light.active {
          background: var(--v-badge_primary_bg-base) !important;
          color: var(--v-badge_primary_text-base);
     }
     .v-chip.theme--light.inactive {
          background: var(--v-badge_secondary_bg-base) !important;
          color: var(--v-badge_secondary_text-base);
     }
     .mwidth-50 {
          max-width: 50%;
     }
     .mwidth-75 {
          max-width: 75%;
     }
</style>
