<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span class="heading_color--text my-auto">{{
                         $t('global.newrecord')
                    }}</span>
                    <v-tooltip right>
                         <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                   class="mx-1"
                                   small
                                   v-bind="attrs"
                                   v-on="on"
                                   >mdi-help-circle</v-icon
                              >
                         </template>
                         <div v-html="$t('invoice.paymenttips')"></div>
                    </v-tooltip>
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div
               v-if="!_id && !cid"
               class="px-10 py-5 d-flex flex-row my-auto post_bg"
          >
               <v-text-field
                    v-model="selectedid"
                    class="mx-2"
                    :label="$t('invoice.invoiceno')"
                    outlined
                    dense
                    hide-details
                    style="width: 30%"
                    background-color="#ffffff"
               ></v-text-field>
               <span class="my-auto">{{ $t('global.or') }}</span>
               <v-select
                    v-model="selectInvoiceStatus"
                    :items="invoiceStatusList"
                    attach
                    chips
                    :label="$t('invoice.status')"
                    multiple
                    outlined
                    dense
                    style="width: 30%"
                    class="mx-2"
                    hide-details
                    background-color="#ffffff"
               >
                    <template v-slot:selection="{ item, index }">
                         <v-chip v-if="index === 0" small>
                              <span>{{ item }}</span>
                         </v-chip>
                         <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                         >
                              (+{{ selectInvoiceStatus.length - 1 }}...)
                         </span>
                    </template>
               </v-select>
               <v-btn color="primary" @click="searchInvoice">{{
                    $t('global.search')
               }}</v-btn>
          </div>
          <v-divider></v-divider>
          <v-container
               v-if="invoiceObjList && invoiceObjList.length > 0"
               class="d-flex flex-column"
          >
               <div class="caption text2_color--text">
                    {{ $t('invoice.selectinvoice') }}
               </div>
               <v-slide-group show-arrows>
                    <v-slide-item
                         v-for="(invoiceObj, index) in invoiceObjList"
                         :key="index"
                    >
                         <v-card
                              :color="
                                   selectedinvoiceidx == index
                                        ? 'post_bg'
                                        : 'null'
                              "
                              width="250"
                              :outlined="selectedinvoiceidx != index"
                              class="ma-2"
                              @click="selectInv(index)"
                         >
                              <v-card-text>
                                   <div>
                                        {{ $t('invoice.invoiceno') }}:
                                        {{ invoiceObj.invoiceno }}
                                        <v-icon
                                             v-if="selectedinvoiceidx == index"
                                             small
                                             color="primary"
                                             >mdi-check</v-icon
                                        >
                                   </div>
                                   <div>
                                        {{ $t('invoice.amount') }} : $
                                        {{ invoiceObj.amount }}
                                   </div>
                                   <div>
                                        {{ $t('invoice.salesrepresentative') }}:
                                        {{ invoiceObj.sales.name }}
                                   </div>
                                   <div>
                                        {{ $t('components.customer') }}:
                                        {{ invoiceObj.billto.name }}
                                   </div>
                              </v-card-text>
                         </v-card>
                    </v-slide-item>
               </v-slide-group>
          </v-container>
          <v-container v-else-if="_id == null">
               <div class="my-5">
                    <span>{{ $t('global.notfound') }}</span>
               </div>
          </v-container>
          <v-divider></v-divider>

          <v-form
               v-if="invoiceObj"
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-5 pb-5 post_bg d-flex flex-column"
          >
               <div class="mt-5">{{ $t('invoice.normalpayment') }}</div>
               <v-container class="rounded grey lighten-4 my-2 elevation-5">
                    <div class="pb-2">
                         <div class="d-flex flex-row">
                              <v-chip
                                   color="primary"
                                   outlined
                                   label
                                   class="mr-2"
                              >
                                   {{ invoiceObj.invoiceno }}
                              </v-chip>
                              <v-chip
                                   color="primary"
                                   outlined
                                   label
                                   class="mr-2"
                              >
                                   {{ $t('invoice.amount') }} : $
                                   {{ invoiceObj.amount }}
                              </v-chip>
                              <div class="px-2"></div>
                              <v-chip
                                   color="primary lighten-3"
                                   label
                                   class="mr-2"
                              >
                                   {{ $t('invoice.paid') }}: $
                                   {{ payment_total }}
                              </v-chip>
                              <v-chip
                                   v-if="invoiceObj.amount - payment_total > 0"
                                   color="red lighten-3"
                                   label
                                   class="mr-2"
                              >
                                   {{$t('invoice.unpaid')}}: $
                                   {{ invoiceObj.amount - payment_total }}
                              </v-chip>
                         </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex flex-column">
                         <v-row class="mt-5">
                              <v-col class="py-0">
                                   <v-select
                                        v-model="payment_type"
                                        :items="ApiPaymentListName"
                                        item-text="displayname"
                                        hide-selected
                                        :label="$t('invoice.paymentmethod')"
                                        persistent-hint
                                        outlined
                                        dense
                                        return-object
                                        background-color="#ffffff"
                                   ></v-select>
                              </v-col>
                              <v-col class="py-0">
                                   <v-text-field
                                        v-model="amount"
                                        prefix="$"
                                        :label="$t('invoice.paymentamount')"
                                        :hint="$t('global.required')"
                                        :rules="[numberRules]"
                                        type="number"
                                        persistent-hint
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                              </v-col>
                              <!--<v-col class="py-0">
                                   <v-select
                                        v-model="status"
                                        :items="statusList"
                                        hide-selected
                                        :label="$t('invoice.status')"
                                        outlined
                                        dense
                                   ></v-select>
                              </v-col>-->
                              <v-col class="py-0 d-flex flex-column" cols="4">
                                   <VueCtkDateTimePicker
                                        class="pl-1"
                                        id="pay_date"
                                        v-model="pay_date"
                                        :label="$t('invoice.paymentdate')"
                                        format="YYYY-MM-DD"
                                        formatted="LL"
                                        only-date
                                        no-header
                                        no-button
                                        auto-close
                                        right
                                   >
                                   </VueCtkDateTimePicker>

                                   <!--<v-checkbox
                                        v-model="enableAuto"
                                        :label="
                                             $t(
                                                  'invoice.autoupdateinvoicestatus'
                                             )
                                        "
                                        dense
                                        hide-details
                                   ></v-checkbox>-->
                              </v-col>
                         </v-row>

                         <!--<div class="d-flex flex-column">
                              <span
                                   class="caption text2_color--text"
                                   >{{$t('invoice.detail')}}</span
                              >
                              <div
                                   v-for="(item, index) in product_list"
                                   :key="index"
                              >
                                   <div
                                        v-if="item.type != 4 && item.type != 0"
                                        class="d-flex flex-row mx-auto"
                                   >
                                        <v-chip
                                             class="mt-1 primary lighten-2 ml-8"
                                             outlined
                                             label
                                        >
                                             <span
                                                  class="px-0 caption"
                                                  style="width: 10px"
                                             >
                                             </span>
                                             <span
                                                  class="caption"
                                                  style="width: 325px"
                                                  >{{ item.name }} *
                                                  {{ item.qty }}</span
                                             >

                                             <span
                                                  class="caption"
                                                  style="width: 50px"
                                             >
                                                  $ {{ item.total }}</span
                                             >
                                        </v-chip>
                                   </div>
                              </div>
                         </div>-->

                         <!--<v-row class="mt-5">
                              <v-col class="py-0" cols="8">
                                   <v-textarea
                                        v-model="remark"
                                        :label="$t('global.remark')"
                                        rows="3"
                                        outlined
                                   >
                                   </v-textarea>
                              </v-col>
                              
                         </v-row>-->
                    </div>
                    <div class="pa-2 d-flex justify-end">
                         <v-btn
                              color="primary"
                              class="text-body-2 font-weight-bold"
                              @click="newPaymentRecord"
                         >
                              {{ $t('global.add') }}
                         </v-btn>
                    </div>
               </v-container>

               <div class="mt-5" v-if="showPackagePaymentSection">
                    {{ $t('invoice.packagepayment') }}
               </div>
               <v-container
                    class="rounded grey lighten-4 my-2 elevation-5"
                    v-if="showPackagePaymentSection"
               >
                    <div class="pb-2">
                         <div class="d-flex flex-row">
                              <v-chip
                                   color="primary"
                                   outlined
                                   label
                                   class="mr-2"
                              >
                                   {{ invoiceObj.invoiceno }}
                              </v-chip>
                              <v-chip
                                   color="primary"
                                   outlined
                                   label
                                   class="mr-2"
                              >
                                   {{ $t('invoice.amount') }} : Cr
                                   {{ credit_total }}
                              </v-chip>
                              <div class="px-2"></div>
                              <v-chip
                                   color="primary lighten-3"
                                   label
                                   class="mr-2"
                              >
                                   {{ $t('invoice.paid') }}: Cr
                                   {{ credit_payment_total }}
                              </v-chip>
                              <v-chip
                                   v-if="
                                        credit_total - credit_payment_total > 0
                                   "
                                   color="red lighten-3"
                                   label
                                   class="mr-2"
                              >
                                   {{ $t('invoice.unpaid') }}: Cr
                                   {{ credit_total - credit_payment_total }}
                              </v-chip>
                         </div>
                    </div>
                    <v-divider class="pb-3"></v-divider>
                    <div class="d-flex flex-column">
                         <div>
                              <v-row class="mt-5">
                                   <v-col class="py-0">
                                        <v-text-field
                                             :label="
                                                  $t('invoice.paymentmethod')
                                             "
                                             persistent-hint
                                             outlined
                                             dense
                                             readonly
                                             disabled
                                             value="套票支付"
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                                   <v-col class="py-0">
                                        <v-text-field
                                             v-model="credit_balance"
                                             prefix="Cr"
                                             :label="
                                                  $t('invoice.paymentamount')
                                             "
                                             :hint="$t('global.required')"
                                             :rules="[numberRules]"
                                             type="number"
                                             persistent-hint
                                             readonly
                                             disabled
                                             outlined
                                             dense
                                             background-color="#ffffff"
                                        ></v-text-field>
                                   </v-col>
                                   <v-col
                                        class="py-0 d-flex flex-column"
                                        cols="4"
                                   >
                                        <VueCtkDateTimePicker
                                             class="pl-1"
                                             id="package_pay_date"
                                             v-model="ppay_date"
                                             :label="$t('invoice.paymentdate')"
                                             format="YYYY-MM-DD"
                                             formatted="LL"
                                             only-date
                                             no-header
                                             no-button
                                             auto-close
                                             right
                                        >
                                        </VueCtkDateTimePicker>
                                   </v-col>
                              </v-row>

                              <div class="d-flex flex-column">
                                   <span class="caption text2_color--text"
                                        >Products</span
                                   >
                                   <div
                                        v-for="(item, index) in product_list"
                                        :key="index"
                                   >
                                        <div
                                             v-if="
                                                  item.type == 4 ||
                                                  item.type == 0
                                             "
                                             class="d-flex flex-row my-auto"
                                        >
                                             <v-checkbox
                                                  dense
                                                  hide-details
                                                  v-if="item.paid == null"
                                                  v-model="item.isGoingToPaid"
                                             ></v-checkbox>
                                             <span v-else class="ml-8"></span>
                                             <v-chip
                                                  class="primary lighten-2 mt-1"
                                                  outlined
                                                  label
                                             >
                                                  <span
                                                       class="px-0 caption"
                                                       style="width: 10px"
                                                  >
                                                  </span>
                                                  <span
                                                       class="caption"
                                                       style="width: 325px"
                                                       >{{ item.name }} *
                                                       {{ item.qty }}</span
                                                  >

                                                  <span
                                                       class="caption"
                                                       style="width: 50px"
                                                  >
                                                       Cr
                                                       {{ item.credit }}</span
                                                  >
                                             </v-chip>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <!--<v-col class="mt-5 py-0" cols="5">
                              <v-select
                                   v-model="status"
                                   :items="statusList"
                                   hide-selected
                                   :label="$t('invoice.status')"
                                   outlined
                                   dense
                                   disabled
                              ></v-select>
                              <VueCtkDateTimePicker
                                   class="pl-1"
                                   id="pay_date"
                                   v-model="pay_date"
                                   :label="$t('invoice.paymentdate')"
                                   format="YYYY-MM-DD"
                                   formatted="LL"
                                   only-date
                                   no-header
                                   no-button
                                   auto-close
                                   right
                              >
                              </VueCtkDateTimePicker>

                              <v-checkbox
                                   v-model="enableAuto"
                                   :label="
                                        $t('invoice.autoupdateinvoicestatus')
                                   "
                                   dense
                                   hide-details
                              ></v-checkbox>
                         </v-col>-->
                    </div>
                    <div class="pa-2 d-flex justify-end">
                         <v-btn
                              color="primary"
                              class="text-body-2 font-weight-bold"
                              @click="newPackagePaymentRecord"
                         >
                              {{ $t('global.add') }}
                         </v-btn>
                    </div>
               </v-container>
          </v-form>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <!--<v-btn
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add') }}
                    </v-btn>-->
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.close') }}
                    </v-btn>
               </v-row>
          </v-container>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               {{ $t('global.failPrefix') }}:
               <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import BrowserSession from '@/utils/BrowserSession';
     import InvoiceService from '@/utils/api/InvoiceService';
     import PaymentService from '@/utils/api/PaymentService';
     import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
     import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

     //import _ from 'lodash';
     var find = require('lodash/find');
     var isEmpty = require('lodash/isEmpty');

     export default {
          props: {
               _id: String,
               cid: String,
          },
          data: function () {
               return {
                    model: [],

                    selectedid: null,
                    invoiceObj: null,
                    invoiceObjList: null,
                    selectedinvoiceidx: null,
                    valid: true,
                    enableAuto: true,
                    product_list: [],

                    showedate: false,
                    pay_date: new Date(
                         Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                         .toISOString()
                         .substr(0, 10),
                    ppay_date: new Date(
                         Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                         .toISOString()
                         .substr(0, 10),

                    ApiPaymentListName: [],
                    payment_type: '',

                    amount: 0.0,
                    credit_balance: 0.0,

                    credit_total: 0,
                    credit_payment_total: 0,

                    payment_total: 0.0,
                    numberRules: (v) => {
                         if (!v && v != 0) return 'This field is required!';
                         if (v.length == 0) return 'This field is required!';
                         if (v < 0) return 'Must be larger than zero!';
                         if (!isNaN(v)) return true;
                         return 'Please input a number';
                    },

                    remark: '',
                    status: this.$t('invoice.paymentdone'),
                    statusList: [
                         this.$t('invoice.paymentinit'),
                         this.$t('invoice.paymentdone'),
                    ],
                    statusListName: ['init', 'done'],

                    apiCustomerCategoryList: {},
                    apiRewardSetting: {},

                    selectInvoiceStatus: ['unpaid'],
                    invoiceStatusList: ['unpaid', 'paid', 'void'],

                    is_success: false,
                    success_message: null,
                    is_fail: false,
                    error_message: null,

                    showPackagePaymentSection: false,
               };
          },
          methods: {
               apiGetInvoice(_id) {
                    InvoiceService.getInvoice(_id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.invoiceObj = response.data.data;
                                   this.selectedinvoiceidx = 0;
                                   this.invoiceObjList = [];
                                   this.invoiceObjList.push(this.invoiceObj);
                                   this.selectInv(this.selectedinvoiceidx);
                              }
                         })
                         .catch((err) => {
                              console.log(err);
                              this.error_message =
                                   'Fail: ' + err.response.data.error;
                         });
               },
               apiGetInvoiceByid(iid) {
                    InvoiceService.getInvoiceByid(iid)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let Obj = response.data.data;
                                   this.selectedinvoiceidx = 0;
                                   this.invoiceObj = Obj;

                                   this.invoiceObjList = [];
                                   this.invoiceObjList.push(Obj);
                                   this.selectInv(this.selectedinvoiceidx);
                              }
                         })
                         .catch((err) => {
                              console.log(err);
                              this.error_message =
                                   'Fail: ' + err.response.data.error;
                         });
               },
               apiGetInvoiceList() {
                    InvoiceService.getInvoicesByStatus(this.selectInvoiceStatus)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let ObjList = response.data.data;

                                   this.invoiceObjList = ObjList;
                                   this.selectedinvoiceidx = 0;
                                   this.invoiceObj =
                                        ObjList[this.selectedinvoiceidx];

                                   this.selectInv(this.selectedinvoiceidx);
                              }
                         })
                         .catch((err) => {
                              console.log(err);
                         });
               },
               apiGetInvoiceListByCustomer(cid) {
                    InvoiceService.getInvoiceByUserid(cid, null, null, 'unpaid')
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let ObjList = response.data.data;
                                   this.invoiceObjList = ObjList;
                                   this.selectedinvoiceidx = 0;
                                   this.invoiceObj = ObjList[selectedinvoiceidx];
                                   this.selectInv(this.selectedinvoiceidx);
                              }
                         })
                         .catch((err) => {
                              console.log(err);
                         });
               },
               apiGetCustomerCategoryList() {
                    BrowserSession.getSession('customer_category', (dd) => {
                         this.apiCustomerCategoryList = dd;
                         //console.log(this.apiCustomerCategoryList);
                    });
               },
               apiGetSettingPrefix() {
                    BrowserSession.getSession('setting', (data) => {
                         this.apiRewardSetting = data.find((d) => {
                              return d.key == 'reward';
                         }).value;
                         let ptype = data.find((d) => {
                              return d.key == 'invoice';
                         }).value['payment_type'];
                         this.ApiPaymentListName = ptype.filter(e=>e.enable);

                         this.ApiPaymentListName = this.ApiPaymentListName.map(e=>{
                              return {...e, ...{displayname: this.$t('invoice.' + e.name)}}
                         })
                         //console.log(this.ApiPaymentListName);

                         if(this.ApiPaymentListName.length > 0)
                              this.payment_type = this.ApiPaymentListName[0];

                         /*this.paymentList = this.ApiPaymentListName.map(e=>{
                              return this.$t('invoice.' + e.name);

                         })*/

                         //console.log(this.paymentList);
                    });
               },
               selectInv(idx) {
                    this.invoiceObj = this.invoiceObjList[idx];
                    this.selectedinvoiceidx = idx;
                    //console.log(this.invoiceObj);

                    this.showPackagePaymentSection = false;
                    this.invoiceObj.product.map(p=>{
                         if(p.type == 0 || p.type == 4)
                              this.showPackagePaymentSection = true;
                    })

                    //api get invoice detail
                    InvoiceService.getInvoiceWithPayment(this.invoiceObj._id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.payment_total = 0;
                                   this.credit_payment_total = 0;
                                   this.product_list = response.data.data.product;

                                   //console.log(response);

                                   //calculate total credit
                                   this.credit_total = 0;
                                   this.product_list.map((prod) => {
                                        if (prod.type == 0 || prod.type == 4) {
                                             this.credit_total += prod.credit;
                                             prod.isGoingToPaid = true;
                                        }
                                   });

                                   //link payment to corresponding item
                                   response.data.data.payment_list.map((paid) => {
                                        if (paid.status == 'done')
                                             if (paid.payment_type != 'stock') {
                                                  this.payment_total += paid.amount;
                                             } else {
                                                  //search product corresponding to this payment
                                                  let p = find(this.product_list, {
                                                       upid: paid.upid,
                                                  });
                                                  if (p) {
                                                       p.paid == undefined ||
                                                       isEmpty(p.paid)
                                                            ? (p.paid = [paid])
                                                            : p.paid.push(paid);
                                                       p.isGoingToPaid = false;
                                                  }
                                                  this.credit_payment_total +=
                                                       paid.amount;
                                             }
                                   });
                                   //console.log(this.product_list);

                                   this.amount =
                                        response.data.data.amount -
                                        this.payment_total;
                                   this.credit_balance =
                                        this.credit_total -
                                        this.credit_payment_total;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               searchInvoice() {
                    if (isEmpty(this.selectedid)) {
                         this.apiGetInvoiceList();
                    } else {
                         this.apiGetInvoiceByid(this.selectedid);
                    }
               },
               cancel() {
                    this.$emit('onClickCancel', null);
                    //this.$emit('onClickInput', '');
               },
               newPaymentRecord() {
                    if (!this.invoiceObj) return;

                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    if (this.amount <= 0) {
                         this.is_fail = true;
                         this.error_message = 'Amount cannot zero!';
                         return;
                    }
                    let payment = {
                         invoiceid: this.invoiceObj._id,
                         customerid: this.invoiceObj.billto.customerid,
                         amount: this.amount,
                         pay_date: this.pay_date,
                         payment_type: this.payment_type.name,
                         remark: this.remark,
                         status: 'done',
                         staffname: this.$user.username,
                         autoupdateInvoice: this.enableAuto,
                    };

                    PaymentService.addPayment(payment)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   //check if all item paid?
                                   InvoiceService.CanInvoiceComplete(
                                        this.invoiceObj._id
                                   )
                                        .then(async (response) => {
                                             if (
                                                  response.data.status ==
                                                       'success' &&
                                                  response.data.data.isPaymentDone
                                             ) {
                                                  //complete invoice?
                                                  await InvoiceService.completeInvoice(
                                                       this.invoiceObj._id
                                                  );
                                                  this.$emit(
                                                       'onClickInput',
                                                       this.$t(
                                                            'global.successPrefix'
                                                       )
                                                  );
                                                  return;
                                             }
                                        })
                                        .catch((err) => {});
                                   this.is_success = true;
                                   this.success_message = 'Payment added';
                                   this.selectInv(this.selectedinvoiceidx);
                              } else {
                                   this.$emit('onFailAction', response.data.message);
                              }
                         })
                         .catch((error) => {
                              this.$emit('onFailAction', error.response.data.error);
                         });
               },
               async newPackagePaymentRecord() {
                    if (!this.invoiceObj) return;

                    //get any type 0 and type 4 item need to pay
                    let pack_prod = this.product_list.filter((p) => {
                         if (p.type == 0 || p.type == 4) {
                              return p.isGoingToPaid;
                         }

                         return false;
                    });

                    //console.log(pack_prod);

                    try {
                         //loop the pack_prod, create payment one by one
                         for (let i = 0; i < pack_prod.length; i++) {
                              let p = pack_prod[i];
                              let payment = {
                                   invoiceid: this.invoiceObj._id,
                                   customerid: this.invoiceObj.billto.customerid,
                                   amount: p.credit,
                                   pay_date: this.ppay_date,
                                   payment_type: 'stock',
                                   upid: p.upid,
                                   //remark: this.remark,
                                   status: 'done',
                                   staffname: this.$user.username,
                                   autoupdateInvoice: this.enableAuto,
                              };

                              let res = await PaymentService.addPPayment(payment);

                              //console.log(res.data);

                              if (res.data.status != 'success') {
                                   this.is_fail = true;
                                   this.error_message = res.data.message;
                                   return;
                              }
                         }
                         InvoiceService.CanInvoiceComplete(this.invoiceObj._id)
                              .then(async (response) => {
                                   if (
                                        response.data.status == 'success' &&
                                        response.data.data.isPaymentDone
                                   ) {
                                        //complete invoice?
                                        await InvoiceService.completeInvoice(
                                             this.invoiceObj._id
                                        );
                                        this.$emit(
                                             'onClickInput',
                                             this.$t('global.successPrefix')
                                        );
                                        return;
                                   }

                                   this.is_success = true;
                                   this.success_message = 'Payment added';
                                   this.selectInv(this.selectedinvoiceidx);
                              })
                              .catch((err) => {
                                   console.log(err);
                              });
                    } catch (e) {
                         //console.log(e.response);
                         //this.$emit('onFailAction', e.response.data.error);
                         this.is_fail = true;
                         this.error_message = e.response.data.message;
                    }
               },
               refreshInv() {},
          },
          created() {
               this.apiGetCustomerCategoryList();
               this.apiGetSettingPrefix();

               if (this._id) this.apiGetInvoice(this._id);
               else if (this.cid) this.apiGetInvoiceListByCustomer(this.cid);
               else this.apiGetInvoiceList();
          },
          components: {
               VueCtkDateTimePicker,
          },
     };
</script>
