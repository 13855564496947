import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '@/views/LoginView';
import HomeView from '@/views/HomeView';
import AppointmentView from '@/views/appointment/AppointmentView';
import CustomerView from '@/views/customer/CustomerView';
import CustomerDetailView from '@/views/customer/CustomerDetailView';
import CustomerAddView from '@/views/customer/CustomerAddView';
import CustomerGroupView from '@/views/customer/CustomerGroupView';
import CustomerCategoryView from '@/views/customer/CustomerCategoryView';
import ExpenseView from '@/views/invoice/expense/ExpenseView';
import InvoiceView from '@/views/invoice/invoice/InvoiceView';
import InvoiceAddView from '@/views/invoice/invoice/InvoiceAddView';
import InvoiceDetailView from '@/views/invoice/invoice/InvoiceDetailView';
import InvoicePdfView from '@/views/invoice/invoice/InvoicePDF';
import PackageView from '@/views/product/package/PackageView';
import PaymentView from '@/views/invoice/payment/PaymentView';
import ProductView from '@/views/product/product/ProductView';
import QuotationView from '@/views/invoice/quotation/QuotationView';
import UserView from '@/views/user/UserView';
import UserRoleView from '@/views/user/UserRoleView';
import ServiceView from '@/views/product/service/ServiceView';
import SettingView from '@/views/setting/SettingView';

import SalesReportView from '@/views/report/SalesReportView';
import StatementReportView from '@/views/report/StatementReportView';
import CustomerSalesReportView from '@/views/report/CustomerSalesReportView';
import ProductReportView from '@/views/report/ProductReportView';
import SalesProductReportView from '@/views/report/SalesProductReportView';
import PaymentReportView from '@/views/report/PaymentReportView';
import SalesSummaryReportView from '@/views/report/SalesSummaryReportView';

import BlogView from '@/views/mobile/blog/BlogView';
import PushMessageView from '@/views/mobile/pm/PushMessageView';
import BookingView from '@/views/mobile/booking/BookingView';

import FlowView from '@/views/automation/FlowView';

Vue.use(VueRouter);

const routes = [
     {
          path: '/',
          name: 'login',
          component: LoginView,
     },
     {
          path: '/home',
          name: 'home',
          component: HomeView,
     },
     {
          path: '/appointment',
          name: 'appointment',
          component: AppointmentView,
     },
     {
          path: '/customer',
          name: 'customer',
          component: CustomerView,
     },
     {
          path: '/customerdetail',
          name: 'customerdetail',
          component: CustomerDetailView,
          props: true,
     },
     {
          path: '/addcustomer',
          name: 'addcustomer',
          component: CustomerAddView,
     },
     {
          path: '/customercategory',
          name: 'customercategory',
          component: CustomerCategoryView,
     },
     /*{
          path: '/customercategorydetail/:id',
          name: 'customercategorydetail',
          component: CustomerCategoryDetailView,
     },*/
     {
          path: '/customergroup',
          name: 'customergroup',
          component: CustomerGroupView,
     },
     {
          path: '/product',
          name: 'product',
          component: ProductView,
     },
     {
          path: '/package',
          name: 'package',
          component: PackageView,
     },
     {
          path: '/quotation',
          name: 'quotation',
          component: QuotationView,
     },
     {
          path: '/service',
          name: 'service',
          component: ServiceView,
     },
     {
          path: '/expense',
          name: 'expense',
          component: ExpenseView,
     },
     {
          path: '/invoice',
          name: 'invoice',
          component: InvoiceView,
     },
     {
          path: '/invoicedetail',
          name: 'invoicedetail',
          component: InvoiceDetailView,
     },
     {
          path: '/invoiceadd',
          name: 'invoiceadd',
          component: InvoiceAddView,
          props: true,
     },
     {
          path: '/invoicepdf',
          name: 'invoicepdf',
          component: InvoicePdfView,
          props: true,
          meta: {
               hideNavbar: true,
               hideSidebar: true,
          },
     },
     {
          path: '/payment',
          name: 'payment',
          component: PaymentView,
     },
     {
          path: '/user',
          name: 'staff',
          component: UserView,
     },
     {
          path: '/userrole',
          name: 'staffrole',
          component: UserRoleView,
     },
     {
          path: '/setting',
          name: 'setting',
          component: SettingView,
     },
     {
          path: '/about',
          name: 'about',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
               import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
     },
     {
          path: '/salesReport',
          name: 'salesReport',
          component: SalesReportView,
     },
     {
          path: '/statementReport',
          name: 'statementReport',
          component: StatementReportView,
     },
     {
          path: '/customerSalesReport',
          name: 'customerSalesReport',
          component: CustomerSalesReportView,
     },
     {
          path: '/productReport',
          name: 'productReport',
          component: ProductReportView,
     },
     {
          path: '/salesProductReport',
          name: 'salesProductReport',
          component: SalesProductReportView,
     },
     {
          path: '/paymentReport',
          name: 'paymentReport',
          component: PaymentReportView,
     },
     {
          path: '/salesSummaryReport',
          name: 'salesSummaryReport',
          component: SalesSummaryReportView,
     },
     {
          path: '/blog',
          name: 'blog',
          component: BlogView,
     },
     {
          path: '/pm',
          name: 'pm',
          component: PushMessageView,
     },
     {
          path: '/booking',
          name: 'booking',
          component: BookingView,
     },

     {
          path: '/flow',
          name: 'flow',
          component: FlowView,
     },
];

const router = new VueRouter({
     mode: 'history',
     base: process.env.SERVER,
     routes,
});

export default router;
