<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">報價單</span>
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                        >更多功能<v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  >匯入資料</v-list-item-title
                                             >
                                        </v-list-item-content>
                                   </v-list-item>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             匯出資料</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="showAddQuotationDialog()"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">新增</span>
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="3">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text"
                                        >單號</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text"
                                        >建立日期</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text"
                                        >有效期限</span
                                   >
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text"
                                        >客戶</span
                                   >
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text"
                                        >金額</span
                                   >
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(quotation, index) in quotationList"
                         :key="quotation._id"
                         class="px-3 fill-height"
                         align="center"
                    >
                         <v-col cols="3" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        (page - 1) * 5 + index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="d-flex flex-column">
                                   <v-chip
                                        :class="`q-${quotation.status}`"
                                        outlined
                                        :text-color="`q-${quotation.status}`"
                                   >
                                        {{ quotation.quotationno }}
                                   </v-chip>
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <span class="text2_color--text text-body-2">{{
                                   formatDate(quotation.quotation_date)
                              }}</span>
                         </v-col>

                         <v-col cols="2">
                              <span class="text2_color--text text-body-2">{{
                                   formatDate(quotation.due_date)
                              }}</span>
                         </v-col>

                         <v-col cols="3">
                              <span class="text1_color--text text-body-2">{{
                                   quotation.billto.name
                              }}</span>
                         </v-col>

                         <v-col cols="2">
                              <div class="d-flex justify-space-between">
                                   <div class="d-flex flex-column my-auto">
                                        <span
                                             class="text1_color--text text-body-2"
                                             >${{ quotation.amount }}</span
                                        >
                                   </div>
                                   <v-menu
                                        offset-y
                                        v-if="quotation.status != 'deleted'"
                                   >
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  color="primary"
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>
                                        <v-list dense>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       showPDFRecord(
                                                            quotation._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-file-pdf-box</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >顯示PDF</v-list-item-title
                                                  >
                                             </v-list-item>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       modifyRecord(
                                                            quotation._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-pencil-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >修改</v-list-item-title
                                                  >
                                             </v-list-item>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       dupRecord(quotation._id)
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-plus-box-multiple</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >複製</v-list-item-title
                                                  >
                                             </v-list-item>
                                             <v-list-item
                                                  link
                                                  @click="
                                                       deleteRecord(
                                                            quotation._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-icon
                                                       class="menu-list-btn"
                                                  >
                                                       <v-icon
                                                            >mdi-delete-circle</v-icon
                                                       >
                                                  </v-list-item-icon>
                                                  <v-list-item-title
                                                       class="ml-1"
                                                       >刪除</v-list-item-title
                                                  >
                                             </v-list-item>
                                        </v-list>
                                        <v-divider></v-divider>
                                        <v-list subheader dense>
                                             <v-subheader
                                                  >報價單動作</v-subheader
                                             >
                                             <v-list-item
                                                  link
                                                  @click="
                                                       convertRecord(
                                                            quotation._id
                                                       )
                                                  "
                                             >
                                                  <v-list-item-content>
                                                       <v-list-item-title
                                                            >轉為銷售單</v-list-item-title
                                                       >
                                                       <v-list-item-subtitle
                                                            >將報價單轉為銷售單</v-list-item-subtitle
                                                       >
                                                  </v-list-item-content>
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <ConfirmDialog ref="confirm" />
          <v-dialog v-model="pdfdialog" persistent max-width="1240px">
               <v-card>
                    <InvoicePDF
                         v-if="pdfdialog"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                         :_id="selectedid"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
/* eslint-disable */
import QuotationService from '@/utils/api/QuotationService';
import ConfirmDialog from '@/views/components/ConfirmDialog';
import InvoicePDF from '../invoice/InvoicePDF';

import Permission from '@/utils/Permission';
const moment = require('moment');

export default {
     data: () => ({
          pagekey: ['TWwvR'],
          managekey: ['ZCIMR'],
          valueA: [['status', 'total']],
          valueB: [['payment method', 'type']],

          pdfdialog: false,
          dialog: false,

          quotationList: [],
          noOfPage: 0,
          page: 1,

          selectedid: '',
          cid: '',
          invoiceno: '',

          is_fail: false,
          error_message: '',
          is_success: false,
          success_message: '',
     }),
     methods: {
          apiGetQuotationList() {
               QuotationService.getAll(this.page, 5)
                    .then((response) => {
                         if (response.data.status == 'success')
                              this.quotationList = response.data.data;
                    })
                    .catch((e) => {
                         console.log(e);
                    });
          },
          apiGetRecordsCount() {
               QuotationService.getCount()
                    .then((response) => {
                         if (response.data.status == 'success') {
                              let rs = response.data.data;
                              let reminder = rs % 5;
                              if (reminder > 0) reminder = 1;
                              let ps = Math.floor(rs / 5);
                              this.noOfPage = reminder + ps;
                         }
                    })
                    .catch((e) => {
                         console.log(e);
                    });
          },
          showAddQuotationDialog() {
               if (
                    !Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.managekey
                    )
               ) {
                    this.error_message =
                         'Sorry, you dont have permission to access!';
                    this.is_fail = true;
                    return;
               }
          },
          showPDFRecord(_id) {
               this.selectedid = _id;
               this.pdfdialog = true;
          },
          modifyRecord(_id) {
               if (
                    !Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.managekey
                    )
               ) {
                    this.error_message =
                         'Sorry, you dont have permission to access!';
                    this.is_fail = true;
                    return;
               }
               this.selectedid = _id;
          },
          async deleteRecord(_id) {
               if (
                    !Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.managekey
                    )
               ) {
                    this.error_message =
                         'Sorry, you dont have permission to access!';
                    this.is_fail = true;
                    return;
               }
               if (
                    await this.$refs.confirm.open(
                         '確定操作',
                         '你是否要刪除此項？刪除後不能復原'
                    )
               ) {
                    // eslint-disable-next-line
                    QuotationService.deleteInvoice(_id)
                         .then((response) => {
                              this.is_success = true;
                              this.apiGetRecordsCount();
                              this.apiGetQuotationList();
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               }
          },
          formatDate(value) {
               return moment(value).format('YYYY-MM-DD');
          },
          onChangePage() {
               this.apiGetQuotationList();
          },
          onClickCancel() {
               this.pdfdialog = false;
          },
          onFail(err_msg) {
               this.error_message = err_msg;
          },
          onClickInput(msg) {
               this.pdfdialog = false;
               this.success_message = msg;
          },
          havePermit() {
               return Permission.isPageHavePermission(
                    BrowserSession.getSession('config')['disabled_page'],
                    this.pagekey
               );
          },
     },
     created() {
          this.apiGetRecordsCount();
          this.apiGetQuotationList();
     },
     components: {
          ConfirmDialog,
          InvoicePDF,
     },
};
</script>

<style>
#button_grp {
     flex-flow: row wrap;
     max-width: 30%;
}
.q-new {
     background: orange !important;
     border-color: orange !important;
     color: orange !important;
}
.q-converted {
     background: #1976d2 !important;
     border-color: #1976d2 !important;
     color: #1976d2 !important;
}
.q-deleted {
     background: #ff5252 !important;
     border-color: #ff5252 !important;
     color: #ff5252 !important;
}
.q-text-deleted {
     text-decoration: line-through;
}
.menu-list-btn {
     margin-right: 5px !important;
}
</style>
