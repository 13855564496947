<template>
     <div>
          <div class="wrapper">
               <div class="colR">
                    <div
                         style="font-size:13px; font-weight: 600; text-align: center;"
                    >
                         Event
                    </div>
                    <v-divider style="margin:5px;"></v-divider>
                    <div
                         v-for="e in event_list"
                         :key="e"
                         class="drag-drawflow"
                         draggable="true"
                         ondragstart="drag(event)"
                         :data-node="e"
                         style="background-color: #4ea9ff;"
                    >
                         <span>{{ e }}</span>
                    </div>

                    <div
                         style="font-size:13px; font-weight: 600; text-align: center; margin-top:20px"
                    >
                         Target
                    </div>
                    <v-divider style="margin:5px;"></v-divider>
                    <div
                         v-for="t in target_list"
                         :key="t"
                         class="drag-drawflow"
                         draggable="true"
                         ondragstart="drag(event)"
                         :data-node="t"
                         style="background-color: #53a336;"
                    >
                         <span>{{ t }}</span>
                    </div>

                    <div
                         style="font-size:13px; font-weight: 600; text-align: center; margin-top:20px"
                    >
                         Filter
                    </div>
                    <v-divider style="margin:5px;"></v-divider>
                    <div
                         v-for="f in filter_list"
                         :key="f"
                         class="drag-drawflow"
                         draggable="true"
                         ondragstart="drag(event)"
                         :data-node="f"
                         style="background-color: #916FD8;"
                    >
                         <span>{{ f }}</span>
                    </div>

                    <div
                         style="font-size:13px; font-weight: 600; text-align: center; margin-top:20px"
                    >
                         Action
                    </div>
                    <v-divider style="margin:5px;"></v-divider>
                    <div
                         v-for="a in action_list"
                         :key="a"
                         class="drag-drawflow"
                         draggable="true"
                         ondragstart="drag(event)"
                         :data-node="a"
                         style="background-color: #D8D56F;"
                    >
                         <span>{{ a }}</span>
                    </div>
               </div>
               <div>
                    <div
                         id="drawflow"
                         ondrop="drop(event)"
                         ondragover="allowDrop(event)"
                    ></div>
               </div>
          </div>
     </div>
</template>

<script>
     /* eslint-disable */
          import Vue from 'vue';
          import Drawflow from 'drawflow';
          import 'drawflow/dist/drawflow.min.css';

          export default {
               data() {
                    return {
                         event_list: ['Register', 'Purchase', 'Membership'],
                         target_list: ['Member', 'Staff'],
                         filter_list: ['Amount', 'Status'],
                         action_list: ['SMS', 'Email'],
                         mobile_item_selec: '',
                         mobile_last_move: null
                    }
               },
               methods:{
                    allowDrop(ev) {
                         ev.preventDefault();
                    },
                    drag(ev) {
                         if (ev.type === "touchstart") {
                              this.mobile_item_selec = ev.target.closest(".drag-drawflow").getAttribute('data-node');
                         } else {
                              ev.dataTransfer.setData("node", ev.target.getAttribute('data-node'));
                         }
                    },
                    positionMobile(ev) {
                         this.mobile_last_move = ev;
                    },
                    drop(ev) {
                         if (ev.type === "touchend") {
                              var parentdrawflow = document.elementFromPoint( this.mobile_last_move.touches[0].clientX, this.mobile_last_move.touches[0].clientY).closest("#drawflow");
                              if(parentdrawflow != null) {
                                   this.addNodeToDrawFlow(this.mobile_item_selec, this.mobile_last_move.touches[0].clientX, this.mobile_last_move.touches[0].clientY);
                              }
                              this.mobile_item_selec = '';
                         } else {
                              ev.preventDefault();
                              var data = ev.dataTransfer.getData("node");
                              this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
                         }
                    },
                    addNodeToDrawFlow(name, pos_x, pos_y) {
                         if(this.editor.editor_mode === 'fixed') {
                              return false;
                         }
                         pos_x = pos_x * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().x * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)));
                         pos_y = pos_y * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().y * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)));
                         console.log(name);

                         switch (name) {
                              case 'Register':
                                   var element = `
                                   <div class="registerbox">
                                        Register
                                   </div>
                                   `;
                                   this.editor.addNode('register', 0,  1, pos_x, pos_y, 'register', {}, element );
                                   break;
                              default:
                         }
                    },

               },
               mounted(){
                    var id = document.getElementById("drawflow");
                    this.editor = new Drawflow(id, Vue, this);
                    this.editor.start();

                    var html = document.createElement("div");
                    html.innerHTML =  "Hello Drawflow!!";
                    this.editor.registerNode('test', html);

                    var html = `
                         <div><input type="text" df-name></div>
                         `;
                    var data = { "name": 'Trigger Node' };

                    this.editor.addNode('github', 0, 1, 150, 300, 'github', data, html);




                    var elements = document.getElementsByClassName('drag-drawflow');
                    for (var i = 0; i < elements.length; i++) {
                         elements[i].addEventListener('touchend', this.drop, false);
                         elements[i].addEventListener('touchmove', this.positionMobile, false);
                         elements[i].addEventListener('touchstart', this.drag, false );
                    }

               }
          }
</script>
<style>
     .wrapper {
          width: 100%;
          height: calc(100vh - 67px);
          display: flex;
     }
     .colR {
          overflow: auto;
          width: 120px;
          height: 100%;
     }
     .drag-drawflow {
          padding:7px;
          border-radius: 7px;
          width: 95%;
          cursor: move;
          user-select: none;
          text-align:center;
          margin-bottom: 3px;
     }
     #drawflow {
          position: relative;
          width: 760px;
          height: 100%;
          background: white;
          background-size: 25px 25px;
          background-image:
          linear-gradient(to right, #f1f1f1 1px, transparent 1px),
          linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
     }

     .drawflow .drawflow-node {
          background: transparent;
          border: 0px solid grey;
          -webkit-box-shadow: 0 2px 15px 2px grey;
          box-shadow: 0 2px 15px 2px grey;
          padding: 0px;
          width: 120px;
          text-align: center;
     }
     .drawflow .drawflow-node.selected  {
          background: white;
          /*border: 1px solid #4ea9ff;*/
          -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
          box-shadow: 0 2px 20px 2px #4ea9ff;
     }
     .drawflow-node .title-box {
          height: 50px;
          /*line-height: 50px;
          background: #e5e5e5;
          border-bottom: 1px solid #e9e9e9;
          border-radius: 4px 4px 0px 0px;*/
          text-align: center;
     }
     .drawflow .title-box svg {
          position: initial;
     }
     .drawflow-node .box {
          padding: 10px 20px 20px 20px;
          font-size: 14px;
          color: #555555;
     }
     .drawflow-node .box p {
          margin-top: 5px;
          margin-bottom: 5px;
     }
     .registerbox{
          background-color:#4ea9ff;
          min-height: 40px;
          border-radius: 5px;
          padding: 10px;
     }
</style>
