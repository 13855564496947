<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3" v-if="havePermit()">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="text1_color--text">{{
                              $t('invoice.expenserecord')
                         }}</span>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div v-html="$t('invoice.expensetips')"></div>
                         </v-tooltip>
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                        >{{ $t('global.morefunction')

                                        }}<v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                                  >{{
                                                       $t('global.import')
                                                  }}</v-list-item-title
                                             >
                                        </v-list-item-content>
                                   </v-list-item>
                                   <v-list-item link @click="onExportCSV()">
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{
                                                  $t('global.export')
                                             }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="showAddExpenseDialog()"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">{{
                                   $t('global.add')
                              }}</span>
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="3">
                                   <span class="mx-6">&nbsp;</span>
                                   <span class="caption heading_color--text">{{
                                        $t('global.type')
                                   }}</span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">{{
                                        $t('global.date')
                                   }}</span>
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.receiver')
                                   }}</span>
                              </v-col>
                              <v-col cols="2">
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.amount')
                                   }}</span>
                              </v-col>
                              <v-col>
                                   <span class="caption heading_color--text">{{
                                        $t('invoice.status')
                                   }}</span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(expense, index) in expenseList"
                         :key="expense._id"
                         class="px-3 fill-height"
                         align="center"
                    >
                         <v-col cols="3" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        (page - 1) * 5 + index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="d-flex flex-column">
                                   <div class="text1_color--text text-body-2">
                                        {{ expense.name }}
                                   </div>
                                   <div class="text2_color--text caption">
                                        {{ expense.type }}
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <div
                                   v-if="expense.pay_date"
                                   class="text1_color--text text-body-2"
                              >
                                   {{ formatDate(expense.pay_date) }}
                              </div>
                              <div v-else class="text1_color--text text-body-2">
                                   -
                              </div>
                              <div
                                   v-if="expense.invoice.length > 0"
                                   class="text2_color--text caption"
                              >
                                   {{ expense.invoice[0].invoiceno }}
                              </div>
                         </v-col>

                         <v-col cols="3" class="d-flex flex-column">
                              <div class="text1_color--text text-body-2">
                                   {{ expense.payee }}
                              </div>
                              <pre class="text2_color--text caption">{{
                                   expense.description
                              }}</pre>
                         </v-col>

                         <v-col cols="2">
                              <div
                                   class="text1_color--text text-body-2 font-weight-bold"
                              >
                                   ${{ formatCurrency(expense.amount) }}
                              </div>
                              <div class="text2_color--text text-caption">
                                   {{ expense.payment_type }}
                              </div>
                         </v-col>
                         <v-col>
                              <div
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <v-icon
                                        small
                                        v-if="expense.status == 'void'"
                                        color="#ff5252"
                                   >
                                        mdi-cancel
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="expense.status == 'done'"
                                        color="#1976d2"
                                   >
                                        mdi-checkbox-marked-circle-outline
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="expense.status == 'init'"
                                        color="orange"
                                   >
                                        mdi-progress-clock
                                   </v-icon>
                                   <v-menu
                                        offset-y
                                        :disabled="expense.status == 'void'"
                                   >
                                        <template
                                             v-slot:activator="{ on, attrs }"
                                        >
                                             <v-icon
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  :color="
                                                       expense.status == 'void'
                                                            ? 'grey'
                                                            : 'primary'
                                                  "
                                                  >mdi-dots-vertical-circle</v-icon
                                             >
                                        </template>

                                        <v-list subheader dense>
                                             <v-subheader>{{
                                                  $t('components.modifystatus')
                                             }}</v-subheader>
                                             <v-list-item
                                                  v-if="
                                                       expense.status == 'init'
                                                  "
                                                  link
                                                  @click="
                                                       changeExpenseDone(
                                                            expense._id,
                                                            index
                                                       )
                                                  "
                                             >
                                                  <v-list-item-content>
                                                       <v-list-item-title>{{
                                                            $t(
                                                                 'invoice.paymentdone'
                                                            )
                                                       }}</v-list-item-title>
                                                       <v-list-item-subtitle>{{
                                                            $t(
                                                                 'invoice.donetips'
                                                            )
                                                       }}</v-list-item-subtitle>
                                                  </v-list-item-content>
                                             </v-list-item>
                                             <v-list-item
                                                  v-if="
                                                       expense.status != 'init'
                                                  "
                                                  link
                                                  @click="
                                                       changeExpenseInit(
                                                            expense._id,
                                                            index
                                                       )
                                                  "
                                             >
                                                  <v-list-item-content>
                                                       <v-list-item-title>{{
                                                            $t(
                                                                 'invoice.paymentinit'
                                                            )
                                                       }}</v-list-item-title>
                                                       <v-list-item-subtitle>{{
                                                            $t(
                                                                 'invoice.inittips'
                                                            )
                                                       }}</v-list-item-subtitle>
                                                  </v-list-item-content>
                                             </v-list-item>
                                             <v-list-item
                                                  v-if="
                                                       expense.status != 'void'
                                                  "
                                                  link
                                                  @click="
                                                       cancelRecord(
                                                            expense._id,
                                                            index
                                                       )
                                                  "
                                             >
                                                  <v-list-item-content>
                                                       <v-list-item-title>{{
                                                            $t('invoice.void')
                                                       }}</v-list-item-title>
                                                       <v-list-item-subtitle>{{
                                                            $t(
                                                                 'invoice.voidtips'
                                                            )
                                                       }}</v-list-item-subtitle>
                                                  </v-list-item-content>
                                             </v-list-item>
                                        </v-list>
                                   </v-menu>
                              </div>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <ConfirmDialog ref="confirm" />

          <v-dialog v-model="addDialog" persistent max-width="600px">
               <ExpenseDialog
                    v-if="addDialog"
                    @onClickCancel="onClickCancel"
                    @onClickInput="onClickInput"
                    @onFailAction="onFail"
                    :_id="selectedid"
               />
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import BrowserSession from '@/utils/BrowserSession';
     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import ExpenseService from '@/utils/api/ExpenseService';
     import ExpenseDialog from './ExpenseAddView';
     import JsonToCSV from '@/utils/JsonToCSV';
     import Permission from '@/utils/Permission';

     const moment = require('moment');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });

     export default {
          data: () => ({
               pagekey: ['GTgMN'],
               managekey: ['ovyeA'],
               selectedid: null,

               expenseList: [],
               noOfPage: 0,
               page: 1,
               pageRecord: 5,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',

               addDialog: false,
          }),
          methods: {
               apiGetExpenseList() {
                    ExpenseService.getAll(this.page, this.pageRecord)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.expenseList = response.data.data;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetRecordsCount() {
                    ExpenseService.getCount()
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let rs = response.data.data;
                                   let reminder = rs % this.pageRecord;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.pageRecord);
                                   this.noOfPage = reminder + ps;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               showAddExpenseDialog() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    this.addDialog = true;
               },
               // eslint-disable-next-line
               async cancelRecord(_id, idx) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('global.confirmdeletemsg')
                         )
                    ) {
                         ExpenseService.updateExpenseStatus({
                              _id: _id,
                              newStatus: 'void',
                              pay_date: this.expenseList[idx].pay_date,
                              // eslint-disable-next-line
                         })
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.success_message = this.$t(
                                             'global.successPrefix'
                                        );
                                        this.is_success = true;
                                        this.apiGetRecordsCount();
                                        this.apiGetExpenseList();
                                   }
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                              });
                    }
               },
               // eslint-disable-next-line
               changeExpenseInit(_id, idx) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    ExpenseService.updateExpenseStatus({
                         _id: _id,
                         newStatus: 'init',
                         pay_date: null,
                         // eslint-disable-next-line
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.is_success = true;
                                   this.success_message = this.$t(
                                        'global.successPrefix'
                                   );
                                   this.apiGetRecordsCount();
                                   this.apiGetExpenseList();
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               // eslint-disable-next-line
               changeExpenseDone(_id, idx) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    //console.log(_id);
                    let set_date = this.expenseList[idx].pay_date;
                    if (!set_date) {
                         set_date = new Date().toISOString().substr(0, 10);
                    }
                    ExpenseService.updateExpenseStatus({
                         _id: _id,
                         newStatus: 'done',
                         pay_date: set_date,
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.is_success = true;
                                   this.success_message = this.$t(
                                        'global.successPrefix'
                                   );
                                   this.apiGetRecordsCount();
                                   this.apiGetExpenseList();
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               onExportCSV() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }
                    JsonToCSV.export('paymentlist.csv', 'payment');
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               onChangePage() {
                    this.apiGetExpenseList();
               },
               onClickCancel() {
                    this.addDialog = false;
               },
               onFail(err_msg) {
                    this.is_fail = true;
                    this.error_message = err_msg;
               },
               onClickInput(msg) {
                    this.addDialog = false;

                    this.is_success = true;
                    this.success_message = msg;

                    this.apiGetRecordsCount();
                    this.apiGetExpenseList();
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
          },
          created() {
               this.apiGetRecordsCount();
               this.apiGetExpenseList();
          },
          components: {
               ConfirmDialog,
               ExpenseDialog,
          },
     };
</script>
