<template>
     <div class="pa-7">
          <div class="white rounded elevation-3 mx-3">
               <v-container fluid>
                    <v-row class="pa-5 my-auto" align="center">
                         <span class="text1_color--text">
                              {{ $t('report.salesreport') }}
                         </span>
                         <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-icon
                                        class="mx-1"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-help-circle</v-icon
                                   >
                              </template>
                              <div v-html="$t('report.salesreporttips')"></div>
                         </v-tooltip>
                         <v-spacer></v-spacer>
                         <v-menu
                              v-model="showsdate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                        :label="$t('report.daterange')"
                                        :value="dateRangeText"
                                        style="max-width: 220px"
                                        hide-details
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                   ></v-text-field>
                              </template>
                              <v-date-picker
                                   v-model="date_range"
                                   range
                              ></v-date-picker>
                         </v-menu>
                         <v-menu
                              bottom
                              origin="center center"
                              transition="scale-transition"
                         >
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                             >mdi-arrow-down-drop-circle</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list>
                                   <v-list-item
                                        :input-value="datef == 'today'"
                                        @click="setFilterDate('today')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.today') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last7days'"
                                        @click="setFilterDate('last7days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past7day') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'last30days'"
                                        @click="setFilterDate('last30days')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.past1month') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'thismonth'"
                                        @click="setFilterDate('thismonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.thismonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                                   <v-list-item
                                        :input-value="datef == 'lastmonth'"
                                        @click="setFilterDate('lastmonth')"
                                   >
                                        <v-list-item-title
                                             >{{ $t('report.pastmonth') }}</v-list-item-title
                                        >
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-select
                              v-model="selectStatus"
                              :items="invoiceStatus"
                              attach
                              chips
                              :label="$t('report.invoicestatus')"
                              multiple
                              outlined
                              dense
                              style="max-width: 180px"
                              class="my-auto"
                              hide-details
                         >
                              <template v-slot:selection="{ item, index }">
                                   <v-chip v-if="index === 0" small>
                                        <span>{{ item }}</span>
                                   </v-chip>
                                   <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                   >
                                        (+{{ selectStatus.length - 1 }}...)
                                   </span>
                              </template>
                         </v-select>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold my-auto"
                              @click="getRecords"
                              :loading="isLoading"
                         >
                              <span class="white--text">
                                   {{ $t('report.executereport') }}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <v-row class="mx-5">
                    <v-col>
                         <v-card elevation="2" color="#385F73" dark>
                              <v-card-title>
                                   {{ $t('components.sales') }}
                              </v-card-title>
                              <v-card-text
                                   ><span class="text-h5">
                                        $
                                        {{ formatCurrency(invoiceTotal) }}</span
                                   ></v-card-text
                              >
                         </v-card>
                    </v-col>
                    <v-col>
                         <v-card elevation="2" color="#3949AB" dark>
                              <v-card-title>
                                   {{ $t('invoice.paid') }}
                              </v-card-title>
                              <v-card-text
                                   ><span class="text-h5">
                                        $ {{ formatCurrency(payTotal) }}</span
                                   ></v-card-text
                              >
                         </v-card>
                    </v-col>
                    <v-col>
                         <v-card elevation="2" color="#AD1457" dark>
                              <v-card-title>
                                   {{ $t('invoice.unpaid') }}
                              </v-card-title>
                              <v-card-text
                                   ><span class="text-h5">
                                        $
                                        {{ formatCurrency(nonPayTotal) }}</span
                                   ></v-card-text
                              >
                         </v-card>
                    </v-col>
               </v-row>
               <div>
                    <GChart
                         type="ColumnChart"
                         :data="chartvalue"
                         :options="{
                              title: 'Sales Chart',
                              legend: { position: 'bottom' },
                              width: '99%',
                              height: 400,
                              chartArea: {
                                   left: 80,
                                   top: 80,
                                   right: 10,
                                   width: '100%',
                                   height: '60%',
                              },
                              colors: ['#385F73', '#3949AB', '#AD1457'],
                         }"
                    />
               </div>
          </div>
          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="3" class="my-auto">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.invoicedate') }}
                                   </span>
                              </v-col>
                              <v-col cols="3">
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.invoiceno') }}
                                   </span>
                                   <v-btn
                                        color="info"
                                        icon
                                        @click="
                                             selectShowDetail =
                                                  !selectShowDetail
                                        "
                                   >
                                        <v-icon v-if="!selectShowDetail" small
                                             >mdi-chevron-down</v-icon
                                        >
                                        <v-icon v-else small
                                             >mdi-chevron-up</v-icon
                                        >
                                   </v-btn>
                              </v-col>

                              <v-col cols="2" class="my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.discount') }}</span
                                   >
                              </v-col>

                              <v-col cols="2" class="my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('invoice.amount') }}
                                   </span>
                              </v-col>

                              <v-col cols="2" class="my-auto">
                                   <!--<span class="caption heading_color--text"
                                        >總金額</span
                                   >-->
                                   <span class="caption text2_color--text">
                                        {{ $t('invoice.paid') }}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height px-3">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(invoice, index) in invoiceList"
                         :key="invoice._id"
                         class="px-3 fill-height"
                         align="center"
                    >
                         <v-col cols="3" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        index + 1
                                   }}</span>
                              </v-avatar>
                              <div class="text1_color--text text-body-2">
                                   {{ invoice.invoice_date }}
                              </div>
                         </v-col>

                         <v-col cols="3">
                              <div class="text1_color--text text-body-2">
                                   {{ invoice.invoiceno }}
                              </div>
                              <div class="text2_color--text text-caption">
                                   {{ invoice.billto.name }}
                              </div>
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <span
                                   v-if="invoice.discount>0"
                                   class="text1_color--text text-body-2 my-auto"
                              >
                                   ($
                                   {{ formatCurrency(invoice.discount) }})</span
                              >
                         </v-col>

                         <v-col cols="2" class="d-flex flex-column">
                              <div
                                   class="text1_color--text text-body-2 my-auto"
                              >
                                   $ {{ formatCurrency(invoice.amount) }}
                              </div>
                              <!--<div
                                   class="text1_color--text text-body-2 my-auto"
                              >
                                   $ {{ formatCurrency(invoice.subtotal) }}
                              </div>
                              <div
                                   v-if="invoice.tax > 0"
                                   class="text2_color--text text-caption my-auto"
                              >
                                   $ {{ formatCurrency(invoice.tax) }}
                              </div>-->
                         </v-col>

                         <v-col
                              cols="2"
                              class="d-flex flex-row justify-space-between"
                         >
                              <div class="d-flex flex-column">
                                   <!--<span
                                        class="text1_color--text text-body-2 my-auto"
                                   >
                                        $
                                        {{
                                             formatCurrency(invoice.amount)
                                        }}</span
                                   >-->
                                   <span
                                        class="text2_color--text caption my-auto"
                                   >
                                        $
                                        {{ formatCurrency(invoice.paid) }}</span
                                   >
                              </div>
                              <div>
                                   <v-icon
                                        small
                                        v-if="invoice.status == 'void'"
                                        color="#ff5252"
                                   >
                                        mdi-cancel
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="invoice.status == 'paid'"
                                        color="#1976d2"
                                   >
                                        mdi-checkbox-marked-circle-outline
                                   </v-icon>
                                   <v-icon
                                        small
                                        v-if="invoice.status == 'unpaid'"
                                        color="orange"
                                   >
                                        mdi-progress-clock
                                   </v-icon>
                              </div>
                         </v-col>

                         <v-card
                              v-if="selectShowDetail"
                              class="ml-5 mb-5"
                              width="85%"
                              outlined
                         >
                              <v-list-item>
                                   <v-list-item-content>
                                        <div
                                             class="text-caption mb-5 grey--text font-weight-bold"
                                        >
                                             <v-row>
                                                  <v-col cols="4">
                                                       {{ $t('product.name') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('product.price') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('invoice.qty') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('product.cost') }}
                                                  </v-col>
                                                  <v-col cols="2">
                                                       {{ $t('invoice.amount') }}
                                                  </v-col>
                                             </v-row>
                                        </div>
                                        <div>
                                             <v-row
                                                  v-for="product in invoice.product"
                                                  :key="product._id"
                                                  class="fill-height"
                                                  align="center"
                                             >
                                                  <v-col
                                                       cols="4"
                                                       class="text-caption text2_color--text"
                                                       >{{
                                                            product.name
                                                       }}</v-col
                                                  >
                                                  <v-col
                                                       v-if="
                                                            product.type != 4 &&
                                                            product.type != 0
                                                       "
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >$
                                                       {{
                                                            formatCurrency(
                                                                 product.price
                                                            )
                                                       }}</v-col
                                                  >
                                                  <v-col
                                                       v-else
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >Cr
                                                       {{
                                                            formatCurrency(
                                                                 product.credit
                                                            )
                                                       }}</v-col
                                                  >
                                                  <v-col
                                                       v-if="
                                                            product.type != 4 &&
                                                            product.type != 0
                                                       "
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >{{ product.qty }}</v-col
                                                  >
                                                  <v-col
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                  >
                                                       ($
                                                       {{ formatCurrency(product.cost)}}
                                                       )
                                                  </v-col>
                                                  <v-col
                                                       v-if="
                                                            product.type != 4 &&
                                                            product.type != 0
                                                       "
                                                       cols="2"
                                                       class="text-caption text2_color--text"
                                                       >$
                                                       {{
                                                            formatCurrency(
                                                                 product.total
                                                            )
                                                       }}</v-col
                                                  >
                                             </v-row>
                                        </div>
                                   </v-list-item-content>
                              </v-list-item>
                         </v-card>
                    </v-row>
               </v-container>
          </div>

          <div v-if="this.clicked" class="white rounded elevation-3 mx-3 mt-7">
               <div class="d-flex flex-column align-end pa-5">
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('invoice.amount') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(invoiceTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2">
                              {{ $t('invoice.paid') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold primary--text"
                         >
                              $ {{ formatCurrency(payTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="pl-2 text2_color--text text-body-2">
                              → {{ $t('invoice.redeemamount')}} :
                         </span>
                         <span
                              class="text-decoration-underline text2_color--text text-body-2"
                         >
                              $ {{ formatCurrency(redeemTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2">
                              {{$t('invoice.unpaid')}} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold red--text"
                         >
                              $ {{ formatCurrency(nonPayTotal) }}</span
                         >
                    </div>
               </div>
               <v-divider></v-divider>
               <div class="d-flex flex-column align-end pa-5">
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalsales') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(invoiceTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalcost') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $ {{ formatCurrency(costTotal) }}</span
                         >
                    </div>
                    <div
                         style="width: 220px"
                         class="d-flex flex-row justify-space-between"
                    >
                         <span class="text1_color--text text-body-2"
                              >{{ $t('report.totalnet') }} :
                         </span>
                         <span
                              class="text-decoration-underline font-weight-bold"
                         >
                              $
                              {{ formatCurrency(invoiceTotal-costTotal) }}</span
                         >
                    </div>
               </div>
          </div>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import InvoiceService from '@/utils/api/InvoiceService';
     import { GChart } from 'vue-google-charts';

     //import _ from 'lodash';
     var isEmpty = require('lodash/isEmpty');
     var find = require('lodash/find');
     var sortBy = require('lodash/sortBy');

     const numformat = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     const moment = require('moment');

     export default {
          props: {},
          data: () => ({
               clicked: false,
               date_range: ['', ''],
               datef: 'lastmonth',
               showsdate: false,

               invoiceList: [],

               selectStatus: ['paid', 'unpaid'],
               invoiceStatus: ['paid', 'unpaid', 'void'],

               invoiceTotal: 0.0,
               payTotal: 0.0,
               //creditpayTotal: 0.0,
               nonPayTotal: 0.0,
               redeemTotal: 0.0,
               costTotal: 0.0,

               selectShowDetail: false,

               chartvalue: [['Day', 'Sales', 'Paid', 'unPaid']],

               isLoading: false,

               is_fail: false,
               error_message: '',
               is_success: false,
               success_message: '',
          }),
          methods: {
               getRecords() {
                    if (isEmpty(this.date_range) || this.date_range.length != 2) {
                         this.error_message = 'Please select date range';
                         this.is_fail = true;
                         return;
                    }

                    this.isLoading = true;
                    this.clicked = true;

                    //console.log(this.date_range);
                    if (this.date_range[0] > this.date_range[1]) {
                         //exchange the date
                         let date_less = this.date_range[0];
                         this.date_range[0] = this.date_range[1];
                         this.date_range[1] = date_less;
                         //console.log('exchanged!');
                         //console.log(this.date_range);
                    }
                    InvoiceService.getInvoiceByDateRange(this.date_range)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.invoiceTotal = 0.0;
                                   this.payTotal = 0.0;

                                   this.nonPayTotal = 0.0;
                                   this.redeemTotal = 0.0;

                                   this.costTotal = 0.0;

                                   //console.log(response);
                                   this.invoiceList = response.data.data.filter(
                                        (item) => {
                                             //check if this is the status include?
                                             if (
                                                  !isEmpty(this.selectStatus) &&
                                                  !this.selectStatus.includes(
                                                       item.status
                                                  )
                                             ) {
                                                  return false;
                                             }
                                             return true;
                                        }
                                   );

                                   let data_group = [];
                                   this.chartvalue = [
                                        ['Day', 'Sales', 'Paid', 'unPaid'],
                                   ];

                                   this.invoiceList.map((invoice) => {
                                        //console.log(invoice);
                                        invoice.paid = 0.0;
                                        invoice.payment_list.map((payment) => {
                                             if (payment.status == 'done') {
                                                  if (
                                                       payment.payment_type ==
                                                       'stock'
                                                  ) {
                                                       invoice.creditpaid +=
                                                            payment.amount;
                                                       return payment;
                                                  }

                                                  invoice.paid += payment.amount;
                                                  if (
                                                       payment.payment_type ==
                                                       'reward point'
                                                  ) {
                                                       this.redeemTotal +=
                                                            payment.amount;
                                                  }
                                             }
                                        });
                                        //sum cost total
                                        invoice.product.map((p)=>{
                                             this.costTotal += p.cost * p.qty;
                                        });
                                        this.payTotal += invoice.paid;
                                        this.invoiceTotal += invoice.amount;
                                        if (invoice.paid < invoice.amount)
                                             this.nonPayTotal +=
                                                  invoice.amount - invoice.paid;

                                        let t = find(data_group, {
                                             day: invoice.invoice_date,
                                        });
                                        if (t) {
                                             t.total += invoice.amount;
                                             t.paid += invoice.paid;
                                             t.unpaid +=
                                                  invoice.amount - invoice.paid;
                                        } else {
                                             t = {
                                                  day: invoice.invoice_date,
                                                  total: invoice.amount,
                                                  paid: invoice.paid,
                                                  unpaid:
                                                       invoice.amount - invoice.paid,
                                             };
                                             data_group.push(t);
                                        }
                                   });

                                   let sg = sortBy(data_group, ['day']);
                                   //set chart data
                                   sg.map((d) => {
                                        this.chartvalue.push([
                                             d.day,
                                             d.total,
                                             d.paid,
                                             d.unpaid > 0 ? d.unpaid : 0,
                                        ]);
                                   });
                                   if (this.chartvalue.length == 1) {
                                        this.chartvalue.push(['today', 0, 0, 0]);
                                   }

                                   //console.log(sg);
                              }

                              //this.nonPayTotal = this.invoiceTotal - this.payTotal;
                              this.isLoading = false;
                         })
                         .catch((e) => {
                              this.isLoading = false;
                              console.log(e);
                         });
               },
               formatCurrency(total) {
                    return numformat.format(total);
               },
               setFilterDate(datef) {
                    this.datef = datef;
                    if (datef == 'today') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last7days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(6, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'last30days') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().subtract(29, 'days').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'thismonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment().startOf('month').format('YYYY-MM-DD'),
                              moment().format('YYYY-MM-DD'),
                         ];
                         return;
                    }

                    if (datef == 'lastmonth') {
                         //this.date_range = ['',''];
                         this.date_range = [
                              moment()
                                   .subtract(1, 'month')
                                   .startOf('month')
                                   .format('YYYY-MM-DD'),
                              moment()
                                   .subtract(1, 'month')
                                   .endOf('month')
                                   .format('YYYY-MM-DD'),
                         ];
                         return;
                    }
               },
          },
          computed: {
               dateRangeText: function () {
                    //console.log('exe computed');
                    //this.date_range;
                    return this.date_range.join(' 至 ');
               },
          },
          components: {
               GChart,
          },
          created() {
               let x = new Date();
               x.setDate(0);
               this.date_range[1] = x.toISOString().slice(0, 10);
               x.setDate(1);
               this.date_range[0] = x.toISOString().slice(0, 10);

               this.datef = 'lastmonth';
          },
     };
</script>
