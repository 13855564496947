import http from '../HttpConn';
var isObject = require('lodash/isObject');
class ServiceService {
     getAll(page, limit) {
          return http.get('/service', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page = 1, limit = 9999) {
          return http.get('/service/allactive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/service/count');
     }
     getService(id) {
          return http.get('/service/' + id);
     }
     /* eslint-disable */
     updateService(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/service/update/' + ccobj._id, ccobj);
     }
     addService(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/service/add', ccobj);
     }
     deleteService(id) {
          return http.delete('/service/delete/' + id);
     }
     /* eslint-enable */
}
export default new ServiceService();
