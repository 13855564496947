<template>
     <div class="pa-7">
          <div class="white rounded elevation-3" v-if="havePermit()">
               <v-container>
                    <v-row class="pa-5">
                         <span class="heading_color--text">{{
                              $t('customer.membershiplv')
                         }}</span>
                         <v-spacer></v-spacer>
                         <!-- <v-btn color="white" small to="/customercategoryadd"> -->
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="showAddCustomerCategoryDialog()"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">{{
                                   $t('global.add')
                              }}</span>
                         </v-btn>
                    </v-row>
               </v-container>

               <v-divider></v-divider>

               <div class="heading_bg px-3">
                    <v-container>
                         <v-row>
                              <v-col cols="3">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span class="caption heading_color--text">{{
                                        $t('customer.membershipname')
                                   }}</span>
                              </v-col>
                              <v-col cols="4">
                                   <div class="caption heading_color--text">
                                        {{ $t('customer.detail') }}
                                   </div>
                              </v-col>
                              <v-col cols="2">
                                   <div class="caption heading_color--text">
                                        {{ $t('customer.setdiscount') }}
                                   </div>
                              </v-col>
                              <v-col cols="3">
                                   <div class="caption heading_color--text">
                                        {{ $t('global.createdat') }}
                                   </div>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container class="amList">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(
                              customercategory, index
                         ) in customerCategoryList"
                         :key="customercategory._id"
                         class="px-3"
                         align="center"
                    >
                         <v-col cols="3">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="mx-5 my-auto"
                              >
                                   <span class="caption heading_color--text">{{
                                        index + 1
                                   }}</span>
                              </v-avatar>
                              <!--<a :href="`/customercategorydetail/${customercategory._id}`" 
              class="text-decoration-none text-subtitle-2">
            {{ customercategory.customer_category_name }}</a>-->
                              <span
                                   class="text-decoration-none text-subtitle-2"
                                   >{{
                                        customercategory.customer_category_name
                                   }}</span
                              >
                              <v-icon
                                   v-if="customercategory.is_default"
                                   x-small
                                   class="mx-2"
                                   >mdi-account-cog</v-icon
                              >
                         </v-col>
                         <v-col cols="4">
                              <div class="text1_color--text text-body-2">
                                   {{ customercategory.customer_category_desc }}
                              </div>
                         </v-col>
                         <v-col cols="2">
                              <div class="text1_color--text text-body-2">
                                   {{ customercategory.discount_percentage }}%
                              </div>
                         </v-col>

                         <v-col
                              cols="3"
                              class="d-flex flex-row justify-space-between"
                         >
                              <div class="text1_color--text text-body-2">
                                   {{
                                        formatDate(
                                             customercategory.created_date
                                        )
                                   }}
                              </div>
                              <v-menu offset-y>
                                   <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                             v-bind="attrs"
                                             v-on="on"
                                             color="primary"
                                             >mdi-dots-vertical-circle</v-icon
                                        >
                                   </template>
                                   <v-list dense>
                                        <v-list-item
                                             link
                                             @click="
                                                  modifyRecord(
                                                       customercategory._id
                                                  )
                                             "
                                        >
                                             <v-list-item-icon
                                                  class="menu-list-btn"
                                             >
                                                  <v-icon
                                                       >mdi-pencil-circle</v-icon
                                                  >
                                             </v-list-item-icon>
                                             <v-list-item-title class="ml-1">{{
                                                  $t('global.modify')
                                             }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                             link
                                             @click="
                                                  deleteRecord(
                                                       customercategory._id
                                                  )
                                             "
                                        >
                                             <v-list-item-icon
                                                  class="menu-list-btn"
                                             >
                                                  <v-icon
                                                       >mdi-delete-circle</v-icon
                                                  >
                                             </v-list-item-icon>
                                             <v-list-item-title class="ml-1">{{
                                                  $t('global.delete')
                                             }}</v-list-item-title>
                                        </v-list-item>
                                   </v-list>
                              </v-menu>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <v-dialog v-model="dialog" persistent max-width="600px"
               ><v-card class="pa-0">
                    <CustomerCategoryAddComponent
                         v-if="dialog"
                         @onClickCancel="cancelInput"
                         @onClickInput="successInput"
                    />
               </v-card>
          </v-dialog>

          <v-dialog v-model="editdialog" persistent max-width="790px">
               <v-card class="pa-0">
                    <CustomerCategoryAddComponent
                         v-if="editdialog"
                         @onClickCancel="onEditClickCancel"
                         @onClickInput="onEditClickInput"
                         @onFailAction="onEditFail"
                         :_id="selectedid"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_success" color="primary" elevation="24">
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <ConfirmDialog ref="confirm" />
     </div>
</template>
<script>
     import CustomerCategoryService from '@/utils/api/CustomerCategoryService';
     import BrowserSession from '@/utils/BrowserSession';

     import CustomerCategoryAddComponent from './CustomerCategoryAddView';
     import ConfirmDialog from '@/views/components/ConfirmDialog';

     import Permission from '@/utils/Permission';

     const moment = require('moment');
     export default {
          data() {
               return {
                    pagekey: ['LLVWE'],
                    managekey: ['Mpxzf'],

                    customerCategoryList: [],
                    dialog: false,
                    editdialog: false,

                    noOfPage: 0,
                    page: 1,
                    recordperpage: 5,

                    selectedid: null,

                    is_fail: false,
                    is_success: false,
                    error_message: '',
                    success_message: '',
               };
          },
          methods: {
               apiGetCustomerCategoryList() {
                    CustomerCategoryService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.customerCategoryList = response.data.data;
                              let rs = response.data.recordcount;
                              let reminder = rs % this.recordperpage;
                              if (reminder > 0) reminder = 1;
                              let ps = Math.floor(rs / this.recordperpage);
                              this.noOfPage = reminder + ps;
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
               /*apiGetRecordsCount() {
                    CustomerCategoryService.getCount()
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let rs = response.data.data;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },*/
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               showAddCustomerCategoryDialog() {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }

                    this.dialog = true;
               },
               cancelInput() {
                    this.dialog = false;
               },
               successInput() {
                    //this.apiGetRecordsCount();
                    this.apiGetCustomerCategoryList();
                    this.dialog = false;
               },
               async deleteRecord(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message =
                              'Sorry, you dont have permission to access!';
                         this.is_fail = true;
                         return;
                    }

                    if (
                         await this.$refs.confirm.open(
                              this.$t('global.confirm'),
                              this.$t('components.confirmdeletemsg')
                         )
                    ) {
                         CustomerCategoryService.deleteCustomerCategory(_id)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.success_message = this.$t('global.successPrefix');
                                        this.is_success = true;
                                        this.apiGetCustomerCategoryList();
                                        this.reloadCCCache();
                                   }
                              })
                              .catch((e) => {
                                   if (e.response.status == 400) {
                                        ///console.log(e.response);
                                        this.error_message = e.response.data.message;
                                   } else {
                                        this.error_message = e.message;
                                   }

                                   this.is_fail = true;
                              });
                    }
               },
               modifyRecord(_id) {
                    if (
                         !Permission.isPageHavePermission(
                              BrowserSession.getSession('config')['disabled_page'],
                              this.managekey
                         )
                    ) {
                         this.error_message = this.$t('global.permissionerrmsg');
                         this.is_fail = true;
                         return;
                    }

                    this.selectedid = _id;
                    this.editdialog = true;
               },
               onEditClickCancel() {
                    this.editdialog = false;
               },
               onEditClickInput(msg) {
                    //this.apiGetRecordsCount();
                    this.apiGetCustomerCategoryList();
                    this.editdialog = false;
                    this.success_message = msg;
                    this.is_success = true;
               },
               onEditFail(msg) {
                    this.error_message = msg;
                    this.is_fail = true;
               },
               onChangePage() {
                    this.apiGetCustomerCategoryList();
               },
               havePermit() {
                    return Permission.isPageHavePermission(
                         BrowserSession.getSession('config')['disabled_page'],
                         this.pagekey
                    );
               },
               reloadCCCache() {
                    BrowserSession.renewSession('customer_category', null);
               },
          },
          created() {
               //this.apiGetRecordsCount();
               this.apiGetCustomerCategoryList();
          },
          components: {
               CustomerCategoryAddComponent,
               ConfirmDialog,
          },
     };
</script>
