<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span v-if="_id" class="heading_color--text">
                         {{ $t('global.modify') }}
                    </span>
                    <span v-else class="heading_color--text">
                         {{ $t('global.newrecord') }}
                    </span>
               </v-row>
          </v-container>
          <v-divider></v-divider>

          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="px-5 py-5 post_bg"
               >
                    <div>◼ {{$t('mobile.messagesetting')}}</div>
                    <v-divider class="mb-5"></v-divider>
                    <v-row class="px-5">
                         <v-col class="pb-0">
                              <v-text-field
                                   v-model="title"
                                   :counter="30"
                                   :rules="nameRules"
                                   :label="$t('mobile.title')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row class="px-5">
                         <v-col class="py-0">
                              <v-textarea
                                   v-model="content"
                                   :counter="100"
                                   :rules="contentRules"
                                   :label="$t('mobile.content')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   rows="3"
                                   background-color="#ffffff"
                              ></v-textarea>
                         </v-col>
                    </v-row>

                    <div class="mt-5">◼ {{ $t('mobile.targetsetting') }}</div>
                    <v-divider class="mb-5"></v-divider>
                    <v-row class="px-5">
                         <v-col cols="4">
                              <v-select
                                   v-model="stype"
                                   :items="[{text:$t('components.customerlv'), value:'membership'}, 
                                        {text:$t('components.segment'), value:'segment'}]"
                                   attach
                                   :label="$t('mobile.target')"
                                   dense
                                   outlined
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                         <v-col v-if="stype == 'membership'" cols="8">
                              <v-select
                                   v-model="target"
                                   :items="targetList"
                                   item-text="customer_category_name"
                                   item-value="_id"
                                   attach
                                   small-chips
                                   :label="$t('components.customerlv')"
                                   :rules="selectRules"
                                   multiple
                                   return-object
                                   dense
                                   outlined
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                         <v-col v-else cols="8">
                              <v-select
                                   v-model="segment"
                                   :items="segmentList"
                                   item-text="name"
                                   item-value="segment_name"
                                   attach
                                   small-chips
                                   :label="$t('components.segment')"
                                   :rules="selectRules"
                                   multiple
                                   return-object
                                   dense
                                   outlined
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                    </v-row>

                    <div class="mt-5">◼ {{ $t('mobile.deliverysetting') }}</div>
                    <v-divider class="mb-2"></v-divider>
                    <v-row class="px-5 align-baseline">
                         <v-col>
                              <VueCtkDateTimePicker
                                   v-model="schedule_date"
                                   :label="$t('mobile.deliverydatetime')"
                                   format="YYYY-MM-DD HH:mm"
                                   minute-interval="30"
                                   no-header
                                   :error="isDateError"
                              >
                              </VueCtkDateTimePicker>
                         </v-col>
                         <v-col cols="4" class="py-auto pl-5">
                              <v-switch
                                   color="primary"
                                   v-model="sound"
                                   :label="$t('mobile.sound')"
                                   inset
                                   dense
                                   hide-details
                              >
                              </v-switch>
                         </v-col>
                    </v-row>

                    <v-row class="pt-3">
                         <v-col class="py-auto">
                              <div>
                                   ◼ {{$t('mobile.advancesetting')}}
                                   <v-btn
                                        class="ml-2"
                                        icon
                                        @click="add_new_keypair()"
                                   >
                                        <v-icon color="primary">
                                             mdi-text-box-plus
                                        </v-icon>
                                   </v-btn>
                              </div>
                         </v-col>
                    </v-row>
                    <v-divider class="mb-5"></v-divider>

                    <v-row
                         class="pt-1"
                         v-for="(item, index) in key"
                         :key="index"
                    >
                         <v-col class="py-0 d-flex flex-row" cols="4">
                              <v-btn
                                   class="ml-2"
                                   icon
                                   @click="deleteparameter(index)"
                              >
                                   <v-icon color="primary">
                                        mdi-delete-circle
                                   </v-icon>
                              </v-btn>
                              <v-text-field
                                   v-model="key[index]"
                                   :counter="20"
                                   label="key"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0" cols="8">
                              <div class="d-flex flex-row">
                                   <v-text-field
                                        v-model="datav[index]"
                                        :counter="30"
                                        label="value"
                                        outlined
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                                   <v-btn
                                        class="ml-2"
                                        icon
                                        @click="openblogselectiondialog(index)"
                                   >
                                        <v-icon color="primary">
                                             mdi-file-document
                                        </v-icon>
                                   </v-btn>
                              </div>
                         </v-col>
                    </v-row>
               </v-form>

               <v-divider></v-divider>

               <div class="pa-5 d-flex justify-space-between" id="event">
                    <span></span>
                    <div>
                         <v-btn
                              v-if="_id == null"
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addPushMessage"
                         >
                              <span>
                                   {{ $t('global.add')}}
                              </span>
                         </v-btn>
                         <v-btn
                              v-else
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="editPushMessage"
                         >
                              <span>
                                   {{ $t('global.modify')}}
                              </span>
                         </v-btn>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="grey"
                              class="body-2 font-weight-bold"
                              text
                              @click="$emit('onClickCancel', null)"
                         >
                              <span>
                                   {{ $t('global.cancel')}}
                              </span>
                         </v-btn>
                    </div>
               </div>
          </div>

          <v-dialog v-model="selectblog_dialog" width="280">
               <v-container class="white">
                    <span
                         class="heading_color--text"
                         >{{$t('mobile.redirectpost')}}</span
                    >
                    <v-divider></v-divider>
               </v-container>
               <v-container class="white">
                    <v-row
                         v-for="(blog, index) in blogList"
                         :key="index"
                         class="pa-2"
                    >
                         <v-btn
                              rounded
                              color="primary"
                              @click="selectBlogid(blog._id)"
                              >{{ blog.title }}</v-btn
                         >
                    </v-row>
               </v-container>
          </v-dialog>
     </div>
</template>

<script>
     /*eslint-disable */
     import CustomerCategoryService from '@/utils/api/CustomerCategoryService';
     import CustomerGroupService from '@/utils/api/CustomerGroupService';
     import PushMessageService from '@/utils/api/PushMessageService';
     import BlogService from '@/utils/api/BlogService';
     import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
     import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
     //import _ from 'lodash';
     var isEmpty = require('lodash/isEmpty');
     const moment = require('moment');
     //const config = require('@/config/config.js');

     export default {
          props: {
               _id: String,
          },
          data: () => ({
               valid: true,
               url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,
               title: '',
               nameRules: [
                    (v) => !!v || 'Title is required',
                    (v) =>
                         (v && v.length <= 30) ||
                         'Title must be less than 30 characters',
               ],
               content: '',
               contentRules: [
                    (v) => !!v || 'Content is required',
                    (v) =>
                         (v && v.length <= 100) ||
                         'Content must be less than 100 characters',
               ],
               isDateError: false,
               schedule_date: '',

               stype: 'membership',
               target: [],
               targetList: [],
               segment: [],
               segmentList: [],
               selectRules: [(v) => v.length > 0 || 'Item is required'],
               sound: true,

               selectblog_dialog: false,
               selectkp_idx: -1,
               blogList: [],

               open_advance: true,
               key: [],
               datav: [],
               /*key1: null,
               data1: null,
               key2: null,
               data2: null,*/
          }),
          methods: {
               apiGetTargetList() {
                    CustomerCategoryService.getAllWithDefault()
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.targetList = response.data.data;
                         })
                         .catch((e) => {
                              //console.log(e);
                         });
               },
               apiGetGroupList() {
                    CustomerGroupService.getAllAvailable()
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.segmentList = response.data.data;
                         })
                         .catch((e) => {});
               },
               apiGetPushMessage(id) {
                    PushMessageService.getPushMessage(id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;

                                   this.title = data.title;
                                   this.content = data.content;
                                   this.schedule_date = moment(
                                        new Date(data.schedule_date)
                                   ).format('YYYY-MM-DD HH:mm');
                                   this.status = data.status;
                                   this.sound = data.sound;
                                   this.target = data.target.group.map((item) => {
                                        this.stype = 'membership';
                                        return { _id: item };
                                   });
                                   this.segment = data.target.segment.map((item) => {
                                        this.stype = 'segment';
                                        return { segment_name: item };
                                   });
                                   //console.log(this.target);
                                   let index = 0;
                                   Object.entries(data.data_variable).forEach(
                                        ([key, value]) => {
                                             this.key[index] = key;
                                             this.datav[index] = value;
                                             index++;
                                        }
                                   );
                              }
                         })
                         .catch((e) => {
                              //console.log(e);
                         });
               },
               apiGetBlogList() {
                    BlogService.getAllActive()
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.blogList = response.data.data;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               addPushMessage() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    if (isEmpty(this.schedule_date)) {
                         this.isDateError = true;
                         return;
                    }
                    let dv = {};
                    let index = 0;

                    this.key.map((k) => {
                         dv[k] = this.datav[index];
                         index++;
                    });
                    PushMessageService.addPushMessage({
                         title: this.title,
                         content: this.content,
                         schedule_date: this.schedule_date,
                         status: 'scheduled',
                         target: {
                              group:
                                   this.stype == 'membership'
                                        ? this.target.map((t) => {
                                               return t._id;
                                          })
                                        : [],
                              segment:
                                   this.stype == 'segment'
                                        ? this.segment.map((t) => {
                                               return t.segment_name;
                                          })
                                        : [],
                         },
                         sound: this.sound,
                         data_variable: dv,
                    })
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit('onClickInput', '成功新增推送訊息');
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.$emit('onFailAction', e.message);
                         });
               },
               editPushMessage() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    if (isEmpty(this.schedule_date)) {
                         this.isDateError = true;
                         return;
                    }
                    let dv = {};
                    let index = 0;

                    this.key.map((k) => {
                         dv[k] = this.datav[index];
                         index++;
                    });
                    PushMessageService.updatePushMessage({
                         _id: this._id,
                         title: this.title,
                         content: this.content,
                         schedule_date: this.schedule_date,
                         //status: 'scheduled',
                         target: {
                              group:
                                   this.stype == 'membership'
                                        ? this.target.map((t) => {
                                               return t._id;
                                          })
                                        : [],
                              segment:
                                   this.stype == 'segment'
                                        ? this.segment.map((t) => {
                                               return t.segment_name;
                                          })
                                        : [],
                         },
                         sound: this.sound,
                         data_variable: dv,
                    })
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit('onClickInput', '成功更新推送訊息');
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.$emit('onFailAction', e.message);
                         });
               },
               add_new_keypair() {
                    this.key.push('crmid');
                    this.datav.push('');
               },
               openblogselectiondialog(idx) {
                    this.selectblog_dialog = true;
                    this.selectkp_idx = idx;
               },
               selectBlogid(bid) {
                    this.datav[this.selectkp_idx] = bid;

                    this.selectblog_dialog = false;
                    this.selectkp_idx = -1;
               },
               deleteparameter(idx){
                    this.key.splice(idx, 1);
                    this.datav.splice(idx, 1);
               }
          },
          created() {
               this.apiGetTargetList();
               this.apiGetGroupList();
               this.apiGetBlogList();

               if (this._id) this.apiGetPushMessage(this._id);
          },
          components: {
               VueCtkDateTimePicker,
          },
     };
</script>
