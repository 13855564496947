import http from '../HttpConn';
var isObject = require('lodash/isObject');

class CustomerProfileService {
     /*getAll() {
          return http.get("/customerprofile");
     }*/
     getCustomerProfile(cid, page = 1, limit = 9999) {
          return http.get('/customerprofile/group/' + cid, {
               params: { page: page, limit: limit },
          });
     }
     getAvailiableCustomerProfile(cid) {
          return http.get('/customerprofile/availiable/' + cid);
     }
     getExpiryCustomerProfile(cid) {
          return http.get('/customerprofile/expiry/' + cid);
     }
     getInactiveCustomerProfile(cid) {
          return http.get('/customerprofile/noavailiable/' + cid);
     }
     getCustomerProfileById(id) {
          return http.get('/customerprofile/' + id);
     }
     /* eslint-disable */
     updateCustomerProfile(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/customerprofile/update/' + ccobj._id, ccobj);
     }
     consumeCustomerProfile(ccobj) {
          /* ccobj: {_id, consume_credit, serviceid} */
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put(
               '/customerprofile/update/consume/' + ccobj._id,
               ccobj
          );
     }
     addCustomerProfile(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/customerprofile/add', ccobj);
     }
     addCustomerProfileCreditLog(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post(
               '/customerprofile/historylog/add/' + ccobj._id,
               ccobj
          );
     }
     deleteCustomerProfile(id) {
          return http.delete('/customerprofile/delete/' + id);
     }

     /************** This is customer log  ****************/
     getCustomerLog(type, cid) {
          return http.get('/customerlog/' + type + '/' + cid);
     }
     getCustomerSpecifyLog(id) {
          return http.get('/customerlog/' + id);
     }
     addCustomerLog(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/customerlog/add', ccobj);
     }
     updateCustomerLog(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/customerlog/update/' + ccobj._id, ccobj);
     }
     deleteCustomerLog(_id) {
          return http.delete('/customerlog/delete/' + _id);
     }
     /* eslint-enable */
}
export default new CustomerProfileService();
