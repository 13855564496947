<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5" align="center">
                    <span v-if="_id" class="text1_color--text">{{
                         $t('global.modify')
                    }}</span>
                    <span v-else class="text1_color--text">{{
                         $t('global.newrecord')
                    }}</span>
                    <v-spacer></v-spacer>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
               </v-row>
          </v-container>

          <v-divider></v-divider>

          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="px-10 py-5 post_bg"
               >
                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-select
                                   v-model="service"
                                   :items="service_list"
                                   outlined
                                   dense
                                   :label="$t('mobile.bookedservice')"
                                   background-color="#ffffff"
                              ></v-select>
                         </v-col>
                         <v-col class="py-0">
                              <v-slider
                                   v-model="visitor"
                                   color="primary"
                                   track-color="grey"
                                   always-dirty
                                   :thumb-size="20"
                                   :label="$t('mobile.personcount')"
                                   thumb-label="always"
                                   min="1"
                                   max="10"
                              >
                                   <template v-slot:append>
                                        <v-icon
                                             color="primary"
                                             @click="decrement"
                                             class="mr-2"
                                        >
                                             mdi-minus-circle
                                        </v-icon>
                                        <v-icon
                                             color="primary"
                                             @click="increment"
                                        >
                                             mdi-plus-circle
                                        </v-icon>
                                   </template>
                              </v-slider>
                         </v-col>
                    </v-row>

                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <VueCtkDateTimePicker
                                   v-model="booking_date"
                                   :label="$t('mobile.bookingdate')"
                                   format="YYYY-MM-DD HH:mm"
                                   minute-interval="15"
                                   no-header
                                   :error="isDateError"
                              >
                              </VueCtkDateTimePicker>
                              <div
                                   style="font-size: 12px;
                                        margin-left: 12px;
                                        color: rgba(0, 0, 0, 0.6);
                                        margin-bottom: 8px;"
                              >
                                   {{$t('global.required')}}
                              </div>
                         </v-col>
                    </v-row>

                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="cphone"
                                   :label="$t('mobile.phone')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="cname"
                                   :label="$t('mobile.bookingperson')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="remark"
                                   :label="$t('mobile.remark')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <div>{{$t('mobile.status')}}</div>
                              <v-radio-group
                                   v-model="isActive"
                                   row
                                   class="mt-1"
                              >
                                   <v-radio
                                        :label="$t('mobile.pending')"
                                        value="pending"
                                   ></v-radio>
                                   <v-radio
                                        :label="$t('mobile.approved')"
                                        value="approved"
                                   ></v-radio>
                                   <v-radio
                                        :label="$t('mobile.rejected')"
                                        value="rejected"
                                   ></v-radio>
                              </v-radio-group>
                         </v-col>
                    </v-row>
               </v-form>
          </div>

          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="_id == null"
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{$t('global.add')}}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="text-body-2 font-weight-bold"
                         @click="updateRecord"
                    >
                         {{$t('global.modify')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>
     </div>
</template>

<script>
     import BookingService from '@/utils/api/BookingService';
     import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
     import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
     var isEmpty = require('lodash/isEmpty');
     const moment = require('moment');

     export default {
          props: {
               _id: String,
          },
          data: () => ({
               valid: true,

               service_list: ['洗剪吹','晚裝/恤髮','護理','染髮','電髮','其他'],
               cname: '',
               cphone: '',
               service: '洗剪吹',
               booking_date: '',
               visitor: 1,
               remark: '',
               isActive: 'active',

               isDateError: false,
          }),
          methods: {
               apiGetBooking(id){
                         BookingService.getBooking(id)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        let data = response.data.data;
                                        this.cname = data.customer_name;
                                        this.cphone = data.customer_phone;
                                        this.visitor = data.visitor_count;
                                        this.service = data.service_detail;
                                        this.remark = data.remark;
                                        this.booking_date = moment(new Date(data.booking_date)).format('YYYY-MM-DD HH:mm');
                                        //this.isActive = data.status == 'active';
                                        this.isActive = data.status;

                                        //console.log(this.booking_date);
                                   }
                              })
                              .catch((e) => {
                                   console.log(e);
                              });
               },
               decrement(){this.visitor--;},
               increment(){this.visitor++;},
               cancel() {
                    this.$emit('onClickCancel', null);
               },
               validation(){
                    if(isEmpty(this.cname || isEmpty(this.cphone))){
                         return false;
                    }

                    if (isEmpty(this.booking_date)) {
                         this.isDateError = true;
                         return false;
                    }

                    return true;
               },
               addNewRecord(){
                    let obj = {customerid: null,
                         customer_name: this.cname,
                         customer_phone: this.cphone,
                         visitor_count: this.visitor,
                         service_detail: this.service,
                         booking_date: this.booking_date,
                         remark: this.remark,
                         //status: this.isActive?'active':'inactive',
                         status: this.isActive,
                         by: 'backend',
                    };
                    //console.log(obj);

                    if(!this.validation())
                         return;

                    BookingService.addBooking(obj).then((response) =>{
                         //console.log(response.data);
                         if(response.data.status == 'success'){
                              this.$emit('onClickInput', '成功建立預約');
                         }else{
                              this.$emit('onFailAction', '建立預約失敗，請查看輸入資料');
                         }
                    }).catch((e) => {
                         console.log(e);
                         this.$emit('onFailAction', '建立預約發生錯誤，請查看輸入資料');
                    });
               },
               updateRecord(){
                    let obj = {_id: this._id,
                         customer_name: this.cname,
                         customer_phone: this.cphone,
                         visitor_count: this.visitor,
                         service_detail: this.service,
                         booking_date: this.booking_date,
                         remark: this.remark,
                         //status: this.isActive?'active':'inactive',
                         status: this.isActive,
                    };
                    //console.log(obj);

                    if(!this.validation()){
                         //console.log('validation fail');
                         return;
                    }

                    BookingService.updateBooking(obj).then((response) =>{
                         //console.log(response.data);
                         if(response.data.status == 'success'){
                              this.$emit('onClickInput', '成功更新預約');
                         }else{
                              this.$emit('onFailAction', '更新預約失敗，請查看輸入資料');
                         }
                    }).catch((e) => {
                         console.log(e);
                         this.$emit('onFailAction', '更新預約發生錯誤，請查看輸入資料');
                    });

               }
          },
          components: {
               VueCtkDateTimePicker,
          },
          created() {
               if (this._id) this.apiGetBooking(this._id);
          },
     }
</script>
