import http from '../HttpConn';

//const _ = require('lodash');
var isObject = require('lodash/isObject');

class AppointmentService {
     getAll() {
          return http.get('/appointment');
     }
     getAppointment(id) {
          return http.get('/appointment/' + id);
     }

     getTodayAppointment() {
          return http.post('/appointment/getByRange/', {
               startdate: new Date().toISOString().split('T')[0],
               noOfDays: 1,
          });
     }

     getAppointmentByRange(ccobj) {
          //ccobj : {startdate: '2022-01-02', enddate:'2022-01-03' }
          //or {startdate: 'YYYY-MM-DD', noOfDays: 2}
          return http.post('/appointment/getByRange/', ccobj);
     }
     getAppointmentByFilter(ccobj) {
          //ccobj : {startdate: '2022-01-02', enddate:'2022-01-03'
          //         filter:[{staffid:'xxxxxxx'}, {resourceid:'xxxxxxx'}],
          //         filterlogic:'or/and'}
          return http.post('/appointment/getByRangeAndID/', ccobj);
     }
     /* eslint-disable */
     updateAppointment(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.put('/appointment/update/' + ccobj._id, ccobj);
     }
     addAppointment(ccobj) {
          //console.log(ccobj);
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/appointment/add', ccobj);
     }
     deleteAppointment(id) {
          return http.delete('/appointment/delete/' + id);
     }

     getMonthlyStatistic(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/appointment/monthstat/' + ccobj.staffid, ccobj);
     }

     searchCustomer(page, limit, ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/appointment/searchcustomer/', {
               ...ccobj,
               ...{ params: { page: page, limit: limit } },
          });
     }
     /* eslint-enable */
}
export default new AppointmentService();
