import http from '../HttpConn';
var isObject = require('lodash/isObject');
class SettingService {
     getAll(page, limit) {
          return http.get('/setting', {
               params: { page: page, limit: limit },
          });
     }
     getSettingSimilarKey(pattern) {
          return http.get('/setting/keystartwith/' + pattern);
     }
     getSettingByKey(key) {
          return http.get('/setting/' + key);
     }
     /* eslint-disable */
     updateSetting(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/setting/update/' + ccobj._id, ccobj);
     }
     updateSettingByKey(key, ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/setting/updateByKey/' + key, ccobj);
     }
     addSetting(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/setting/add', ccobj);
     }
     deleteSetting(id) {
          return http.delete('/setting/delete/' + id);
     }
     getSettingFile(file) {
          return http.post('/setting/getfile', { file: file });
     }

     sendMail(options) {
          return http.post('/setting/sendmail', options);
     }
     backupnow(type) {
          return http.get('/setting/backup/' + type);
     }
     /* eslint-enable */
}
export default new SettingService();
