<template>
     <div class="pa-7">
          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-10 py-5 post_bg"
          >
               <div class="text-h6 mt-5">
                    {{ $t('setting.notificationsetting') }}
               </div>
               <v-divider class="mb-5"></v-divider>
               <div class="pa-5">
                    <v-row>
                         <v-col class="d-flex flex-row">
                              <span class="my-auto">
                                   {{ $t('setting.enablenotification') }}
                              </span>
                              <v-switch
                                   class="my-auto ml-5"
                                   v-model="push_enable"
                                   dense
                                   inset
                                   hide-details
                              >
                              </v-switch>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col>
                              <span class="text-caption">Current File: </span
                              ><span
                                   v-if="set_file != null && set_file.length > 0"
                                   class="text-caption text-decoration-underline"
                                   @click="openDialog"
                                   >{{ this.set_file }}</span
                              ><span
                                   class="text-caption text-decoration-underline"
                                   v-else
                                   >{{$t('setting.plsuploadconfig')}}</span
                              >
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="pa-1">
                              <v-file-input
                                   v-model="firebase_file"
                                   show-size
                                   counter
                                   outlined
                                   dense
                                   :label="$t('setting.firebasefile')"
                                   accept="application/json"
                                   :rules="fileRules"
                                   :disabled="!push_enable"
                                   :hint="$t('setting.firebasefilehint')"
                                   persistent-hint
                                   background-color="#ffffff"
                              ></v-file-input>
                              <v-progress-linear
                                   v-if="uploading"
                                   class="mx-3"
                                   :value="progress"
                              ></v-progress-linear>
                         </v-col>
                    </v-row>
               </div>
          </v-form>

          <v-form
               ref="form_smtp"
               v-model="validsmtp"
               lazy-validation
               class="px-10 py-5 post_bg"
          >
               <div class="text-h6 mt-5">{{ $t('setting.smtpsetting') }}</div>
               <v-divider class="mb-5"></v-divider>
               <div class="pa-5">
                    <div class="d-flex flex-row">
                         <span
                              class="my-auto"
                              >{{ $t('setting.enablesmtp')}}</span
                         >
                         <v-switch
                              class="my-auto ml-5"
                              v-model="smtp_enable"
                              dense
                              inset
                              hide-details
                         >
                         </v-switch>
                    </div>

                    <v-radio-group
                         v-model="smtp_provider"
                         row
                         hide-details
                         class="pb-5"
                         :disabled="!smtp_enable"
                    >
                         <v-radio
                              label="Gmail"
                              value="gmail"
                              disabled
                         ></v-radio>
                         <v-radio label="Other" value="other"></v-radio>
                    </v-radio-group>
                    <div v-if="smtp_provider == 'gmail'">
                         <v-text-field
                              v-model="email"
                              label="User(Email)"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-text-field
                              v-model="clientid"
                              label="Client ID"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-text-field
                              v-model="clientsecret"
                              label="Client Secret"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-text-field
                              v-model="refreshtoken"
                              label="Refresh Token"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                    </div>
                    <div v-else>
                         <v-text-field
                              v-model="SMTPserver"
                              :label="$t('setting.smtpserver')"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                         <div class="d-flex flex-row pb-3">
                              <v-text-field
                                   v-model="SMTPport"
                                   :label="$t('setting.smtpport')"
                                   outlined
                                   dense
                                   hide-details
                                   :disabled="!smtp_enable"
                                   background-color="#ffffff"
                              ></v-text-field>
                              <v-switch
                                   v-model="secure"
                                   label="TLS(465)"
                                   inset
                                   dense
                                   hide-details
                                   class="my-auto ml-5"
                                   :disabled="!smtp_enable"
                                   background-color="#ffffff"
                              ></v-switch>
                         </div>
                         <v-text-field
                              v-model="user"
                              :label="$t('setting.smtpuser')"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-text-field
                              v-model="password"
                              :label="$t('setting.smtppassword')"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-text-field
                              v-model="from"
                              :label="$t('setting.smtpfrom')"
                              outlined
                              dense
                              hide-details
                              class="pb-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                    </div>
                    <v-divider class="mb-3"></v-divider>
                    <div class="d-flex flex-row">
                         <v-text-field
                              v-model="targetmail"
                              :label="$t('setting.smtptest')"
                              outlined
                              dense
                              hide-details
                              class="pb-3 pr-3"
                              :disabled="!smtp_enable"
                              background-color="#ffffff"
                         ></v-text-field>
                         <v-btn
                              color="primary"
                              :loading="isSending"
                              :disabled="!smtp_enable"
                              @click="sendtestmail"
                              >{{ $t('setting.smtpsendtest') }}</v-btn
                         >
                    </div>
               </div>
          </v-form>

          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="clickUpdate"
                    >
                         {{ $t('global.save') }}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
          </v-container>

          <v-dialog v-model="content_dialog" width="500">
               <v-card class="pa-5">
                    <code class="text-caption">{{
                         this.set_file_content
                    }}</code>
               </v-card>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import SettingService from '@/utils/api/SettingService';
     import uploadService from '@/utils/uploadFileService';
     import BrowserSession from '@/utils/BrowserSession';

     export default {
          data: () => ({
               valid: true,
               validsmtp: true,

               uploading: false,
               push_enable: false,
               set_file: null,
               content_dialog: false,
               set_file_content: null,
               firebase_file: null,
               fileRules: [
                    (value) =>
                         !value ||
                         value.size < 10000 ||
                         'Size should be less than 10 KB!',
               ],

               smtp_enable: false,
               smtp_provider: 'other',
               email: '',
               clientid: '',
               clientsecret: '',
               refreshtoken: '',

               SMTPserver: '',
               SMTPport: '587',
               from: '',
               user: '',
               password: '',
               secure: false,

               targetmail: '',
               isSending: false,

               is_fail: false,
               is_success: false,
               success_message: '',
               error_message: '',

               progress: 0,
          }),
          methods: {
               apiGetNotificationSetting() {
                    SettingService.getSettingByKey('notification')
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;

                                   this.push_enable = data.value.enable_firebase;
                                   this.set_file = data.value.firebase_config_path;

                                   this.smtp_enable = data.value.enable_smtp;
                                   this.smtp_provider = data.value.smtp_provider;
                                   this.email = data.value.email;
                                   this.clientid = data.value.clientid;
                                   this.clientsecret = data.value.clientsecret;
                                   this.refreshtoken = data.value.refreshtoken;

                                   this.SMTPserver = data.value.SMTPserver;
                                   this.SMTPport = data.value.SMTPport;
                                   this.from = data.value.from;
                                   this.user = data.value.user;
                                   this.password = data.value.password;
                                   this.secure = data.value.secure;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },

               clickUpdate() {
                    if (this.firebase_file != null) this.uploadfile();
                    else this.updateRecord();
               },

               updateRecord(path = null) {
                    SettingService.updateSettingByKey('notification', {
                         enable_firebase: this.push_enable,
                         firebase_config_path: path || this.set_file,
                         enable_smtp: this.smtp_enable,
                         smtp_provider: this.smtp_provider,
                         email: this.email,
                         clientid: this.clientid,
                         clientsecret: this.clientsecret,
                         refreshtoken: this.refreshtoken,
                         SMTPserver: this.SMTPserver,
                         SMTPport: this.SMTPport,
                         from: this.from,
                         user: this.user,
                         password: this.password,
                         secure: this.secure,
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.is_success = true;
                                   this.success_message = '成功更新';
                                   this.apiGetNotificationSetting();
                                   this.firebase_file = null;
                                   this.reloadSettingCache();
                              }
                         })
                         .catch((e) => {
                              //console.log(e);
                              this.is_fail = true;
                              this.error_message = e.message;
                         });
               },

               uploadfile() {
                    //console.log(this.firebase_file);
                    this.uploading = true;
                    uploadService
                         .uploadConfig(this.firebase_file, 'firebase', (event) => {
                              this.progress = Math.round(
                                   (100 * event.loaded) / event.total
                              );
                         })
                         .then((response) => {
                              this.uploading = false;
                              this.progress = 0;
                              this.updateRecord(response.data.file.path);
                         })
                         .catch((err) => {
                              this.uploading = false;
                              this.progress = 0;
                              this.is_fail = true;
                              this.error_message = err.message;
                         });
               },

               cancel() {
                    this.apiGetNotificationSetting();
               },

               openDialog() {
                    this.content_dialog = true;
                    SettingService.getSettingFile(this.set_file)
                         .then((response) => {
                              //console.log(response);
                              if (response.status == 200)
                                   this.set_file_content = response.data;
                         })
                         .catch((e) => {
                              this.set_file_content = e;
                         });
               },

               sendtestmail() {
                    this.isSending = true;
                    SettingService.sendMail({
                         smtp_provider: this.smtp_provider,
                         email: this.email,
                         clientid: this.clientid,
                         clientsecret: this.clientsecret,
                         refreshtoken: this.refreshtoken,

                         SMTPserver: this.SMTPserver,
                         SMTPport: this.SMTPport,
                         from: this.from,
                         user: this.user,
                         password: this.password,
                         secure: this.secure,

                         targetmail: this.targetmail,
                    })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.success_message = response.data.data.message;
                                   this.is_success = true;
                              }
                              this.isSending = false;
                         })
                         .catch((e) => {
                              console.log(e);
                              this.isSending = false;

                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },

               reloadSettingCache() {
                    BrowserSession.renewSession('setting', null);
               },
          },
          created() {
               this.apiGetNotificationSetting();
          },
          components: {},
     };
</script>
