<template>
     <v-app-bar
          v-if="this.$route.name !== 'login'"
          color="background"
          app
          elevate-on-scroll
     >
          <!-- <span class="font-weight-light mr-1 blue--text display-1">AM</span>
    <span class="font-weight-bold blue--text text-12"> Booking System</span> -->
          <v-spacer></v-spacer>

          <v-btn fab text small color="grey darken-2" @click="logout">
               <v-icon>mdi-logout</v-icon>
          </v-btn>
     </v-app-bar>
</template>

<script>
export default {
     methods: {
          logout() {
               //clean up local storage & cookies
               localStorage.removeItem('crmlogin');
               sessionStorage.removeItem('crmlogin');
               this.$cookies.remove('token');

               //redirect to dashboard
               //this.$router.push('/');
               document.location.href = '/';
          },
     },
};
</script>
