<template>
     <div class="white rounded elevation-3">
          <v-container fluid>
               <v-row class="pa-5">
                    <span
                         v-if="!_id"
                         class="heading_color--text"
                         >{{ $t('global.newrecord')}}</span
                    >
                    <span
                         v-else
                         class="heading_color--text"
                         >{{ $t('global.modify') }}</span
                    >
               </v-row>
          </v-container>
          <v-divider></v-divider>
          <div>
               <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="px-10 py-5 post_bg"
               >
                    <div class="d-inline-flex flex-row">
                         <v-img
                              :src="url + '/' + previewImage"
                              height="100"
                              width="100"
                              class="mx-2"
                         ></v-img>
                         <!--<v-btn
                              color="primary"
                              @click="$refs.inputUpload.click()"
                              outlined
                              height="100"
                              width="100"
                              >點擊<br />上傳圖片</v-btn
                         >-->
                         <v-btn
                              color="primary"
                              @click="imageDialog = true"
                              outlined
                              height="100px"
                              width="100px"
                              >{{ $t('product.setimage')}}</v-btn
                         >
                         <input
                              v-show="false"
                              ref="inputUpload"
                              type="file"
                              @change="uploadImage"
                         />
                         <div class="my-auto">
                              <span class="text-caption mx-3">
                                   {{ $t('product.imagemsg')}}
                              </span>
                              <v-progress-linear
                                   class="mx-3"
                                   :value="progress"
                              ></v-progress-linear>
                              <span
                                   class="text-body1 error--text mx-3"
                                   v-if="error_message"
                                   >{{ error_message }}</span
                              >
                         </div>
                    </div>
                    <v-row class="mt-5">
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="name"
                                   :counter="50"
                                   :rules="nameRules"
                                   :label="$t('product.name')"
                                   :hint="$t('global.required')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="sku"
                                   :label="$t('product.sku')"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-combobox
                                   v-model="category"
                                   :items="categoryList"
                                   item-text="name"
                                   item-value="_id"
                                   hide-selected
                                   :hint="$t('product.inputcategory')"
                                   :label="$t('product.category')"
                                   persistent-hint
                                   outlined
                                   dense
                                   return-object
                                   background-color="#ffffff"
                              ></v-combobox>
                         </v-col>
                         <v-col class="py-0">
                              <v-combobox
                                   v-model="brand"
                                   :items="brandList"
                                   item-text="name"
                                   item-value="_id"
                                   hide-selected
                                   :hint="$t('product.inputbrand')"
                                   :label="$t('product.brand')"
                                   persistent-hint
                                   outlined
                                   dense
                                   return-object
                                   background-color="#ffffff"
                              ></v-combobox>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="price"
                                   prefix="$"
                                   :label="$t('product.price')"
                                   :hint="$t('global.required')"
                                   :rules="[numberRules]"
                                   type="number"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="cost"
                                   prefix="$"
                                   :label="$t('product.cost')"
                                   :hint="$t('global.required')"
                                   :rules="[numberRules]"
                                   type="number"
                                   persistent-hint
                                   outlined
                                   background-color="#ffffff"
                                   dense
                              ></v-text-field>
                         </v-col>
                    </v-row>

                    <v-row>
                         <v-col class="py-0">
                              <v-combobox
                                   v-model="unit"
                                   :items="unitList"
                                   item-text="name"
                                   item-value="_id"
                                   hide-selected
                                   :hint="$t('product.inputunit')"
                                   :label="$t('product.unit')"
                                   persistent-hint
                                   outlined
                                   dense
                                   background-color="#ffffff"
                                   return-object
                              ></v-combobox>
                         </v-col>
                         <v-col class="py-0">
                              <v-text-field
                                   v-model="barcode"
                                   prepend-inner-icon="mdi-barcode-scan"
                                   :label="$t('product.barcode')"
                                   outlined
                                   dense
                                   background-color="#ffffff"
                              ></v-text-field>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="8" class="py-0">
                              <v-textarea
                                   v-model="description"
                                   :label="$t('product.detail')"
                                   rows="4"
                                   outlined
                                   dense
                                   hide-details
                                   background-color="#ffffff"
                              >
                              </v-textarea>
                         </v-col>
                         <v-col cols="4" class="py-0">
                              <div
                                   class="d-flex flex-row justify-space-between"
                              >
                                   <v-text-field
                                        v-model="commission"
                                        :suffix="ispercentage ? '%' : ''"
                                        :prefix="ispercentage ? '' : '$'"
                                        outlined
                                        :label="$t('product.commission')"
                                        :hint="$t('global.required')"
                                        persistent-hint
                                        type="number"
                                        :rules="[numberRules]"
                                        dense
                                        background-color="#ffffff"
                                   ></v-text-field>
                                   <v-checkbox
                                        v-model="ispercentage"
                                        label="%"
                                        dense
                                        hide-details
                                        background-color="#ffffff"
                                   ></v-checkbox>
                              </div>
                              <v-checkbox
                                   v-model="ispersist"
                                   :label="$t('product.allowpersist')"
                                   dense
                                   hide-details
                              ></v-checkbox>
                              <v-checkbox
                                   v-model="discountable"
                                   :label="$t('product.allowdiscount')"
                                   dense
                                   hide-details
                              ></v-checkbox>
                         </v-col>
                    </v-row>
                    <v-row align="center">
                         <v-col class="py-0">
                              <v-switch
                                   color="primary"
                                   v-model="isActive"
                                   :label="$t('product.status')"
                                   inset
                                   dense
                                   :disabled="_id == null"
                                   hide-details
                              >
                              </v-switch>
                         </v-col>
                         <v-col class="py-0"> </v-col>
                    </v-row>
               </v-form>
          </div>
          <v-divider></v-divider>
          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         v-if="!_id"
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="addNewRecord"
                    >
                         {{ $t('global.add')}}
                    </v-btn>
                    <v-btn
                         v-else
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="editRecord"
                    >
                         {{$t('global.modify')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>

          <v-dialog v-model="imageDialog" persistent max-width="790px">
               <ImageDialog
                    v-if="imageDialog"
                    @onClickCancel="cancelInput"
                    @onClickInput="successInput"
                    @onFailAction="failInput"
               />
          </v-dialog>

          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Update Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /*eslint-disable */
     import TagService from '@/utils/api/TagService';
     import ProductService from '@/utils/api/ProductService';
     import uploadService from '@/utils/uploadFileService';
     import BrowserSession from '@/utils/BrowserSession';
     import ImageDialog from '@/views/components/ImageManagerDialog';

     export default {
          props: {
               _id: String,
          },
          data: () => ({
               valid: true,
               name: '',
               nameRules: [
                    (v) => !!v || 'Name is required',
                    (v) =>
                         (v && v.length <= 50) ||
                         'Name must be less than 50 characters',
               ],
               barcode: null,
               sku: null,
               category: null,
               categoryList: [],
               brand: null,
               brandList: [],
               price: null,
               cost: null,

               numberRules: (v) => {
                    if (!v && v != 0) return 'This field is required!';
                    if (v.length == 0) return 'This field is required!';
                    if (!isNaN(v)) return true;
                    return 'Please input a number';
               },
               unit: null,
               unitList: [],
               description: null,
               ispersist: false,
               ispercentage: false,
               commission: 0.0,
               discountable: true,

               isActive: true,

               is_fail: false,
               error_message: '',

               url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,

               previewImage: 'noimage.jpeg',
               progress: 0,

               imageDialog: false,
          }),
          methods: {
               uploadImage(event) {
                    //console.log(event.target.files[0].name);
                    //this.currentImage = event.target.files[0].name;
                    this.progress = 0;

                    uploadService
                         .upload(event.target.files[0], (event) => {
                              this.progress = Math.round(
                                   (100 * event.loaded) / event.total
                              );
                         })
                         .then((response) => {
                              //this.message = response.data.message;
                              //this.previewImage = './'+response.data.file.path;
                              this.previewImage = '/' + response.data.file.filename;
                              //console.log(response);
                         })
                         .catch((err) => {
                              this.progress = 0;
                              this.error_message =
                                   'Fail: ' + err.response.data.error;
                              //console.log(err.response.data.error);
                              //console.log(this.message);
                         });
               },

               apiGetAllStuff() {
                    TagService.getAll(1, 999)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;

                                   this.categoryList = data.filter((ele) => {
                                        return ele.type == 'category';
                                   });
                                   this.unitList = data.filter((ele) => {
                                        return ele.type == 'unit';
                                   });
                                   this.brandList = data.filter((ele) => {
                                        return ele.type == 'brand';
                                   });
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },

               getRecord() {
                    ProductService.getProduct(this._id)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   //console.log(response);
                                   this.name = data.name;
                                   this.barcode = data.barcode;
                                   this.sku = data.sku;
                                   this.category = data.category
                                        ? { name: data.category }
                                        : data.category;
                                   this.brand = data.brand
                                        ? { name: data.brand }
                                        : data.brand;
                                   this.unit = data.unit
                                        ? { name: data.unit }
                                        : data.unit;
                                   this.description = data.description;
                                   this.price = data.price;
                                   this.cost = data.cost;
                                   this.ispersist = data.persist
                                        ? data.persist
                                        : false;
                                   this.isActive = data.status == 'active';
                                   this.previewImage = data.image;
                                   this.commission = data.commission || 0;
                                   this.discountable =
                                        data.discountable != null
                                             ? data.discountable
                                             : true;
                                   this.ispercentage =
                                        data.commissiontype == 'percentage'
                                             ? true
                                             : false;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },

               async addNewRecord() {
                    if (!this.$refs.form.validate()) {
                         return;
                    }
                    //check need to create new category?
                    //console.log(this.category);
                    if (typeof this.category === 'string') {
                         let cat_result = await TagService.addTag({
                              name: this.category,
                              type: 'category',
                         });
                         //console.log(cat_result);
                         if (cat_result.status != 200) {
                              this.$emit(
                                   'onFailAction',
                                   this.$t('service.categoryerrormsg')
                              );
                              return;
                         }
                    }
                    //check need to create new brand?
                    //console.log(this.brand);
                    if (typeof this.brand === 'string') {
                         let brand_result = await TagService.addTag({
                              name: this.brand,
                              type: 'brand',
                         });
                         //console.log(brand_result);
                         if (brand_result.status != 200) {
                              this.$emit('onFailAction', this.$t('service.branderrormsg'));
                              return;
                         }
                    }
                    //check need to create new unit?
                    //console.log(this.unit);
                    if (typeof this.unit === 'string') {
                         let unit_result = await TagService.addTag({
                              name: this.unit,
                              type: 'unit',
                         });
                         //console.log(unit_result);
                         if (unit_result.status != 200) {
                              this.$emit('onFailAction', this.$t('service.uniterrormsg'));
                              return;
                         }
                    }

                    let new_product = {
                         name: this.name,
                         sku: this.sku,
                         category: this.category
                              ? this.category.name || this.category
                              : null,
                         brand: this.brand ? this.brand.name || this.brand : null,
                         barcode: this.barcode,
                         price: this.price,
                         cost: this.cost,
                         unit: this.unit ? this.unit.name || this.unit : null,
                         description: this.description,
                         image: this.previewImage,
                         persist: this.ispersist,
                         commissiontype: this.ispercentage ? 'percentage' : 'fixed',
                         commission: this.commission,
                         discountable: this.discountable,
                         status: 'active',
                    };
                    //console.log(new_product);

                    ProductService.addProduct(new_product)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +':'+ this.name
                                   );
                              this.reloadProductCache();
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               async editRecord() {
                    if (!this.$refs.form.validate()) {
                         //console.log('form invalid!');
                         return;
                    }
                    //check need to create new category?
                    //console.log(this.category);
                    if (typeof this.category === 'string') {
                         let cat_result = await TagService.addTag({
                              name: this.category,
                              type: 'category',
                         });
                         //console.log(cat_result);
                         if (cat_result.status != 200) {
                              this.$emit(
                                   'onFailAction',
                                   this.$t('service.categoryerrormsg')
                              );
                              return;
                         }
                    }
                    //check need to create new brand?
                    //console.log(this.brand);
                    if (typeof this.brand === 'string') {
                         let brand_result = await TagService.addTag({
                              name: this.brand,
                              type: 'brand',
                         });
                         //console.log(brand_result);
                         if (brand_result.status != 200) {
                              this.$emit('onFailAction', this.$t('service.branderrormsg'));
                              return;
                         }
                    }
                    //check need to create new unit?
                    //console.log(this.unit);
                    if (typeof this.unit === 'string') {
                         let unit_result = await TagService.addTag({
                              name: this.unit,
                              type: 'unit',
                         });
                         //console.log(unit_result);
                         if (unit_result.status != 200) {
                              this.$emit('onFailAction', this.$t('service.uniterrormsg'));
                              return;
                         }
                    }

                    let edit_product = {
                         _id: this._id,
                         name: this.name,
                         sku: this.sku,
                         category: this.category
                              ? this.category.name || this.category
                              : null,
                         brand: this.brand ? this.brand.name || this.brand : null,
                         barcode: this.barcode,
                         price: this.price || 0,
                         cost: this.cost || 0,
                         unit: this.unit ? this.unit.name || this.unit : null,
                         description: this.description,
                         image: this.previewImage,
                         persist: this.ispersist,
                         commission: this.commission,
                         commissiontype: this.ispercentage ? 'percentage' : 'fixed',
                         discountable: this.discountable,
                         status: this.isActive ? 'active' : 'inactive',
                    };
                    //console.log(edit_product);

                    ProductService.updateProduct(edit_product)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.$emit(
                                        'onClickInput',
                                        this.$t('global.successPrefix') +':'+ this.name
                                   );
                              this.reloadProductCache();
                         })
                         .catch((e) => {
                              this.$emit('onFailAction', e.message);
                         });
               },

               cancel() {
                    //this.clearAllField();
                    this.$emit('onClickCancel', null);
               },

               reloadProductCache() {
                    BrowserSession.renewSession('product', null);
               },

               cancelInput() {
                    this.imageDialog = false;
               },
               successInput(file) {
                    this.imageDialog = false;
                    this.previewImage = file;
               },
               failInput() {
                    this.imageDialog = false;
                    this.error_message = 'Cannot select this file';
                    this.is_fail = true;
               },
          },
          created() {
               this.apiGetAllStuff();

               if (this._id) this.getRecord();
          },

          components: {
               ImageDialog,
          },
     };
</script>
