<template>
     <v-stepper alt-labels v-model="e1">
          <v-stepper-header class="py-2">
               <v-stepper-step step="1" :complete="e1 > 1">
                    Start
               </v-stepper-step>
               <v-divider></v-divider>
               <v-stepper-step step="2" :complete="e1 > 2">
                    Upload File
               </v-stepper-step>
               <v-divider></v-divider>
               <v-stepper-step step="3" :complete="e1 > 3">
                    Match Column
               </v-stepper-step>
               <v-divider></v-divider>

               <v-stepper-step step="4" :complete="e1 > 4">
                    Import Data
               </v-stepper-step>
               <v-divider></v-divider>

               <v-stepper-step step="5"> Result </v-stepper-step>

               <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close-circle</v-icon>
               </v-btn>
          </v-stepper-header>

          <v-stepper-items>
               <v-stepper-content step="1">
                    <div class="primary--text" style="font-size:18px">
                         Get ready to import your data
                    </div>
                    <div class="pb-5" style="font-size:12px">
                         The follow column are required/optional.
                    </div>
                    <div style="max-height:250px; overflow-y: scroll;">
                         <v-data-table
                              dense
                              :headers="headers"
                              :items="sampleitems"
                              item-key="name"
                              hide-default-footer
                              :items-per-page="-1"
                         ></v-data-table>
                    </div>
                    <div class="pt-5 d-flex justify-space-between">
                         <v-btn disabled> Previous </v-btn>
                         <v-btn color="primary" @click="goNext(2)">
                              Next
                         </v-btn>
                    </div>
               </v-stepper-content>
               <v-stepper-content step="2">
                    <div class="primary--text" style="font-size:18px">
                         Please select CSV file to import
                    </div>
                    <div class="pb-5" style="font-size:12px">
                         Make sure file size not over 50MB
                    </div>
                    <div class="pa-5">
                         <v-file-input
                              v-model="csvfile"
                              show-size
                              :rules="rules"
                              accept="text/csv"
                              label="CSV file"
                              :error="fileInputError"
                              :error-messages="fileInputErrMsg"
                         ></v-file-input>
                    </div>

                    <div class="pt-5 d-flex justify-space-between">
                         <v-btn color="primary" @click="goNext(1)">
                              Previous
                         </v-btn>
                         <v-btn color="primary" @click="uploadFile()">
                              Next
                         </v-btn>
                    </div>
               </v-stepper-content>

               <v-stepper-content step="3">
                    <div class="primary--text" style="font-size:18px">
                         Match field to CSV
                    </div>
                    <div class="pb-5" style="font-size:12px">
                         Select corresponding fields mapping (* Required)
                    </div>
                    <div class="py-5" style="max-width: 600px; margin:auto">
                         <div class="d-flex justify-space-between pb-2">
                              <span style="font-weight: 700;">Column Name</span>
                              <span style="font-weight: 700;"
                                   >Match CSV Field</span
                              >
                         </div>
                         <v-divider class="pb-3"></v-divider>
                         <div
                              v-for="(header, index) in sampleitems"
                              :key="header.name"
                              class="d-flex justify-space-between pa-2"
                              :style="index%2==0?'background:#f9fafd':''"
                         >
                              <div>
                                   <span style="padding-top:12px"
                                        >{{ header.name }}
                                        {{ header.require=='Yes'?'*':''}}</span
                                   >
                                   <br />
                                   <span style="font-size:12px; color:grey">
                                        {{ header.desc }}
                                   </span>
                              </div>
                              <div>
                                   <v-select
                                        v-model="mapfield[index]"
                                        :items="csvheaders"
                                        label="CSV Field"
                                        style="width:300px"
                                        dense
                                        outlined
                                        hide-details
                                        clearable
                                   ></v-select>
                              </div>
                         </div>
                    </div>
                    <div class="pt-5 d-flex justify-space-between">
                         <v-btn color="primary" @click="goNext(2)">
                              Previous
                         </v-btn>
                         <v-btn color="primary" @click="goNext(4)">
                              Next
                         </v-btn>
                    </div>
               </v-stepper-content>

               <v-stepper-content step="4">
                    <div class="primary--text" style="font-size:18px">
                         Please select import options
                    </div>
                    <div class="pb-5" style="font-size:12px">
                         Use options below to decide how to update or insert
                         records
                    </div>

                    <v-checkbox
                         v-model="updateInsert"
                         :label="updateInsert?'Update and insert':'Update only'"
                         hide-details
                    ></v-checkbox>
                    <div class="caption" style="padding-left:4px; color:grey">
                         {{ updateInsert?updatemessage:noupdatemessage }}
                    </div>
                    <v-checkbox
                         v-model="passwordField"
                         label="Default password"
                         hide-details
                    ></v-checkbox>
                    <div class="caption" style="padding-left:4px; color:grey">
                         {{ passwordField?fieldmessage:nofieldmessage }}
                    </div>
                    <div class="py-5"></div>

                    <div class="pt-5 d-flex justify-space-between">
                         <v-btn color="primary" @click="goNext(3)">
                              Previous
                         </v-btn>
                         <v-btn
                              color="primary"
                              @click="startImport()"
                              :loading="importing"
                         >
                              Import
                         </v-btn>
                    </div>
               </v-stepper-content>

               <v-stepper-content step="5">
                    <div class="primary--text" style="font-size:18px">
                         Data start to import, it may take few minutes or
                         hours...
                    </div>
                    <div class="pt-10"></div>
                    <v-progress-linear
                         :active="importing"
                         indeterminate
                    ></v-progress-linear>
                    <v-progress-linear v-if="!importing" value="100">
                    </v-progress-linear>
                    <div style="text-align: center; font-size:13px">
                         {{action}} Progress: {{ progress }} /
                         {{ total_progress }}
                    </div>
                    <div class="pt-5 pb-10">
                         <div
                              style="font-size:18px; font-weight: 500;"
                              class="pb-5"
                         >
                              Result
                         </div>
                         <div class="d-flex justify-space-between">
                              <div
                                   style="border:solid; border-color:grey; border-width: 1px; border-radius: 5px; padding:15px; width:180px"
                              >
                                   <div style="color:grey; font-size:13px">
                                        Records read
                                   </div>
                                   <div
                                        style="font-size:20px; font-weight: 500;"
                                   >
                                        {{ total_progress }}
                                   </div>
                              </div>
                              <div
                                   style="border:solid; border-color:grey; border-width: 1px; border-radius: 5px; padding:15px; width:180px"
                              >
                                   <div style="color:grey; font-size:13px">
                                        Records add
                                   </div>
                                   <div
                                        style="font-size:20px; font-weight: 500;"
                                   >
                                        {{ total_add}}
                                   </div>
                              </div>
                              <div
                                   style="border:solid; border-color:grey; border-width: 1px; border-radius: 5px; padding:15px; width:180px"
                              >
                                   <div style="color:grey; font-size:13px">
                                        Records update
                                   </div>
                                   <div
                                        style="font-size:20px; font-weight: 500;"
                                   >
                                        {{ total_update}}
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="pt-5 d-flex justify-space-between">
                         <div></div>
                         <v-btn
                              :disabled="importing"
                              color="primary"
                              @click="closeDialog()"
                         >
                              Close
                         </v-btn>
                    </div>
               </v-stepper-content>
          </v-stepper-items>
     </v-stepper>
</template>
<script>
     import UploadService from '@/utils/uploadFileService';
     import CustomerService from '@/utils/api/CustomerService';
     import _ from 'lodash';
     import ws from '@/utils/WsConn';

     export default({
          props: {
               //_filename: String
          },
          data:()=>({
               e1: 1,
               csvfile: null,
               fileInputError: false,
               fileInputErrMsg: '',
               progress: 0,
               total_progress: 0,
               uploading: false,
               rules: [
                         value => !value || value.size < 50000000 || 'File size should be less than 50 MB!',
                    ],

               headers: [
               {
                    text: 'Column',
                    align: 'start',
                    sortable: false,
                    value: 'name',
               },
               {
                    text: 'Format',
                    align: 'start',
                    sortable: false,
                    value: 'format',
               },
               {
                    text: 'Example',
                    align: 'start',
                    sortable: false,
                    value: 'eg',
               },
               {
                    text: 'Required',
                    align: 'start',
                    sortable: false,
                    value: 'require',
               },
               {
                    text: 'Description',
                    align: 'start',
                    sortable: false,
                    value: 'desc',
               },
               ],
               sampleitems: [
                    {
                         name: 'name',
                         fieldname: 'name',
                         format: 'string',
                         eg: 'Chan Tai Man',
                         require: 'Yes',
                         desc: 'customer name'
                    },
                    {
                         name: 'password',
                         fieldname: 'password',
                         format: 'string',
                         eg: 'AsDfAsDf',
                         require: '',
                         desc: 'customer login password'
                    },
                    {
                         name: 'phone',
                         fieldname: 'phone',
                         format: 'string',
                         eg: '22223333',
                         require: 'Yes',
                         desc: 'customer contact phone number'
                    },
                    {
                         name: 'email',
                         fieldname: 'email',
                         format: 'string',
                         eg: 'davidchan@example.com',
                         require: '',
                         desc: 'customer contact email'
                    },
                    {
                         name: 'gender',
                         fieldname: 'gender',
                         format: 'string',
                         eg: 'M',
                         require: '',
                         desc: 'customer gender'
                    },
                    {
                         name: 'dob',
                         fieldname: 'dob',
                         format: 'date',
                         eg: '2001-01-30',
                         require: '',
                         desc: 'customer birthday'
                    },
                    {
                         name: 'address',
                         fieldname: 'address',
                         format: 'string',
                         eg: '1 example building, KLN, HK',
                         require: '',
                         desc: 'customer contact address'
                    },
                    {
                         name: 'staff',
                         fieldname: 'default_sales',
                         format: 'string',
                         eg: 'Oscar Lee/_id',
                         require: '',
                         desc: 'staff/representative follow up this customer'
                    },
                    {
                         name: 'company',
                         fieldname: 'company',
                         format: 'string',
                         eg: 'Example company',
                         require: '',
                         desc: 'customer company'
                    },
                    {
                         name: 'description',
                         fieldname: 'description',
                         format: 'string',
                         eg: 'High value customer',
                         require: '',
                         desc: 'customer description'
                    },
                    {
                         name: 'membership',
                         fieldname: 'member_lv',
                         format: 'string',
                         eg: 'VIP/_id',
                         require: '',
                         desc: 'customer membership level'
                    },
                    {
                         name: 'membership point',
                         fieldname: 'lv_point',
                         format: 'integer',
                         eg: '10',
                         require: '',
                         desc: 'customer membership level point'
                    },
                    {
                         name: 'membership expiry',
                         fieldname: 'member_lv_expiry',
                         format: 'date',
                         eg: '2030-01-30',
                         require: '',
                         desc: 'customer membership expiry date'
                    },
                    {
                         name: 'reward',
                         fieldname: 'join_reward',
                         format: 'string',
                         eg: 'true/false',
                         require: '',
                         desc: 'customer join reward system'
                    },
                    {
                         name: 'reward expiry',
                         fieldname: 'reward_expiry',
                         format: 'date',
                         eg: '2030-01-30',
                         require: '',
                         desc: 'customer reward point expiry date'
                    },
                    {
                         name: 'reward point',
                         fieldname: 're_point',
                         format: 'integer',
                         eg: '10',
                         require: '',
                         desc: 'customer reward point'
                    },
                    {
                         name: 'tag',
                         fieldname: 'tag',
                         format: 'string',
                         eg: 'Monthly,VVIP',
                         require: '',
                         desc: 'customer tag information, comma to seperate'
                    },
                    {
                         name: 'login',
                         fieldname: 'enable_member_login',
                         format: 'boolean',
                         eg: 'true/false',
                         require: '',
                         desc: 'enable customer login'
                    },
                    {
                         name: 'status',
                         fieldname: 'status',
                         format: 'string',
                         eg: 'active/inactive',
                         require: '',
                         desc: 'customer account status'
                    },
               ],
               csvheaders: [],
               uploadfiledetail: [],
               mapfield: [],

               updateInsert: true,
               passwordField: true,

               updatemessage: 'Update new record by _id field, and insert new record if no match (recommend)',
               noupdatemessage: 'Update record only, by _id/phone field, no new record will be create',

               fieldmessage: 'Set default user password by their phone number (recommend)',
               nofieldmessage: 'Set user encrypted password by csv password field',

               importing: false,
               action: 'Reading',
               total_add: '-',
               total_update: '-'
          }),
          methods:{
               goNext(step){
                    this.e1=step;
                    console.log(ws.id);
                    ws.emit(ws.id, this.e1);
                    //ws.emit('import', 'init from client');
               },

               uploadFile(){
                    this.progress = 0;
                    this.uploading = true;

                    //console.log(this.csvfile);
                    if(!this.csvfile){
                         this.fileInputError = true;
                         this.fileInputErrMsg = 'Please select csv file to import!';
                    }
                    UploadService.uploadCSV(this.csvfile, 'customer', (event) => {
                         this.progress = Math.round(
                              (100 * event.loaded) / event.total
                         );
                    }).then((response) => {
                              //this.message = response.data.message;
                              //this.previewImage = './'+response.data.file.path;
                              //this.previewImage = '/' + response.data.file.filename;
                              //console.log(response);
                              if (response.data.status == 'success') {
                                   this.csvheaders = response.data.headers;
                                   this.uploadfiledetail = response.data.file;
                                   //construct mapping fields
                                   this.sampleitems.map((item, idx)=>{
                                        //search any name is equal in csvheaders
                                        let x = _.indexOf(this.csvheaders, item.name);
                                        if(x>-1){
                                             this.mapfield[idx] = item.name;
                                        }else{
                                             this.mapfield[idx] = null;
                                        }
                                   })
                                   this.e1 = 3;
                                   this.uploading = false;
                              }
                         })
                         .catch((err) => {
                              this.progress = 0;
                              //this.err_message = 'Fail: ' + err.response.data.error;
                              console.log(err.response.data.error);
                              //console.log(this.message);
                              this.uploading = false;
                         });

               },

               closeDialog(){
                    this.$emit('onClickCancel');
               },

               startImport(){
                    //console.log(this.mapfield);
                    //construct field mapping
                    let mf = {};
                    this.sampleitems.map((i,idx)=>{
                         //return {field:i.fieldname, csvfield:this.mapfield[idx]};
                         mf[i.fieldname] = this.mapfield[idx];
                    })

                    //send mapping field to backend
                    this.importing = true;
                    this.goNext(5);

                    CustomerService.importCustomer(
                         {filename:this.uploadfiledetail.filename, fieldsmatch: mf,
                              options:{updateInsert: this.updateInsert, passwordField: this.passwordField}}
                    ).then((response) => {
                         if(response.data.status == 'success'){
                              this.progress = 0;
                              /*this.$emit(
                                        'onClickInput',
                                        '匯入完成 - '+
                                        'Total('+response.data.message.total+') '+
                                        'Insert('+response.data.message.insert+') '+
                                        'Update('+response.data.message.update+')'
                                   );
                              this.importing = false;*/
                              this.total_progress = response.data.message.line;
                         }
                    }).catch((err) => {
                         this.importing = false;
                         this.$emit('onFail',
                                   '匯入發生問題 '+err);
                    });
               }
          },
          mounted () {
               //this.readFile();
          },
          created(){
               ws.on('import', (data)=>{
                    console.log(data);
                    if(data.type == 'construct')
                         this.action = 'Reading';
                    if(data.type == 'import')
                         this.action = 'Importing';

                    if(data.type == 'complete'){
                         //popup result message
                         this.total_add = data.result.tadd;
                         this.total_update = data.result.tupdate;

                         /*this.$emit(
                                        'onClickInput',
                                        '匯入完成'
                                        //'Total('+response.data.message.total+') '+
                                        //'Insert('+response.data.message.insert+') '+
                                        //'Update('+response.data.message.update+')'
                                   );*/
                         this.importing = false;
                         return;
                    }
                    this.progress = data.progress;
               })
          }

     })
</script>
