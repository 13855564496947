<template>
     <div>
          <v-container fluid>
               <v-row class="pa-3">
                    <span class="my-auto">{{ $t('components.gallery') }}</span>
                    <v-spacer></v-spacer>
                    <span class="text-caption error--text my-auto px-2">{{
                         err_message
                    }}</span>
                    <v-btn
                         color="primary"
                         @click="$refs.inputUpload.click()"
                         outlined
                         >{{ $t('components.uploadimage') }}
                    </v-btn>
                    <input
                         v-show="false"
                         ref="inputUpload"
                         type="file"
                         @change="uploadImage"
                    />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="text-body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{ $t('global.cancel') }}
                    </v-btn>
               </v-row>
               <v-progress-linear
                    class="px-3"
                    :value="progress"
                    v-if="uploading"
               ></v-progress-linear>
          </v-container>
          <v-divider></v-divider>
          <v-container fluid>
               <v-virtual-scroll
                    bench="1"
                    height="460"
                    item-height="480"
                    :items="scrollImageList"
               >
                    <template v-slot:default="{ item }">
                         <v-row class="pa-0" style="max-width:630px">
                              <v-col
                                   v-for="(image, index) in item"
                                   :key="image + index"
                                   class="d-flex child-flex"
                                   cols="3"
                              >
                                   <div
                                        @click="selectImage(image)"
                                        style="position: relative"
                                   >
                                        <v-img
                                             :src="url + '/' + image"
                                             aspect-ratio="1"
                                             class="grey lighten-2"
                                        >
                                             <template v-slot:placeholder>
                                                  <v-row
                                                       class="fill-height ma-0"
                                                       align="center"
                                                       justify="center"
                                                  >
                                                       <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                       ></v-progress-circular>
                                                  </v-row>
                                             </template>
                                        </v-img>
                                        <v-btn
                                             @click="removeImage(image)"
                                             color="primary"
                                             x-small
                                             dark
                                             fab
                                             style="
                                                  position: absolute;
                                                  top: 4px;
                                                  right: 5px;
                                             "
                                        >
                                             <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                   </div>
                              </v-col>
                         </v-row>
                    </template>
               </v-virtual-scroll>
          </v-container>
          <ConfirmDialog ref="confirm" />
     </div>
</template>

<script>
     //import ImageDialog from '@/views/components/ImageManagerDialog';
     import UploadService from '@/utils/uploadFileService';
     import ConfirmDialog from '@/views/components/ConfirmDialog';

     //const config = require('@/config/config.js');
     export default {
          data: () => ({
               imageList: [],
               scrollImageList: [[]],
               noOfImages: 0,
               url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,
               uploading: false,
               progress: 0,

               err_message: '',
          }),
          methods: {
               apiGetRecordsCountAndList() {
                    UploadService.getImageList().then((response) => {
                         //console.log(response);
                         if (response.data.status == 'success') {
                              let data = response.data.data;
                              this.imageList = data.files;
                              this.noOfImages = data.count;

                              let g = 12;
                              let counter = 0;
                              let index = 0;
                              this.scrollImageList = [[]];

                              data.files.map((image) => {
                                   if (index >= g) {
                                        counter++;
                                        index = 0;
                                        this.scrollImageList[counter] = [];
                                   }
                                   //console.log(this.scrollImageList[counter]);
                                   this.scrollImageList[counter].push(image);
                                   index++;
                              });
                         }

                         //console.log(this.scrollImageList);
                    });
               },
               async removeImage(file) {
                    if (
                         await this.$refs.confirm.open(
                              '確定操作',
                              '你是否要刪除此媒體？'
                         )
                    ) {
                         UploadService.removeImage(file).then((response) => {
                              //console.log(response);
                              if (response.data.status == 'success')
                                   this.apiGetRecordsCountAndList();
                         });
                    }
               },
               selectImage() {
                    //console.log(file);
                    //this.$emit('onClickInput', file);
               },
               cancel() {
                    //this.$emit('onClickCancel', null);
               },
               uploadImage() {
                    //console.log(event.target.files[0].name);
                    //this.currentImage = event.target.files[0].name;
                    this.progress = 0;
                    this.uploading = true;

                    UploadService.upload(event.target.files[0], (event) => {
                         this.progress = Math.round(
                              (100 * event.loaded) / event.total
                         );
                    })
                         .then((response) => {
                              //this.message = response.data.message;
                              //this.previewImage = './'+response.data.file.path;
                              //this.previewImage = '/' + response.data.file.filename;
                              //console.log(response);
                              if (response.data.status == 'success') {
                                   this.apiGetRecordsCountAndList();
                                   this.uploading = false;
                                   this.progress = 0;
                              }
                         })
                         .catch((err) => {
                              this.progress = 0;
                              this.err_message = 'Fail: ' + err.response.data.error;
                              //console.log(err.response.data.error);
                              //console.log(this.message);
                              this.uploading = false;
                         });
               },
          },
          created() {
               this.apiGetRecordsCountAndList();
          },
          components: {
               ConfirmDialog,
          },
     };
</script>
