import UserService from '@/utils/api/UserService';

export default class Authentication {
     //eslint-disable-next-line
     checkExpiry(cb, failcb) {
          UserService.checkToken()
               //eslint-disable-next-line
               .then((response) => {
                    //console.log(response);
                    cb();
               })
               //eslint-disable-next-line
               .catch((e) => {
                    //console.log(e);
                    failcb();
               });
     }

     checkResponse(res) {
          if (res.error) {
               if (res.error == 'auth_expiry') {
                    throw new Error('authentication expiry');
               }
          }
          return true;
     }
}

//const TokenObj = new Authentication();

//export const AuthInstance = new Authentication();
//module.exports = new Authentication();
