<template>
     <div class="pa-7">
          <div class="white rounded elevation-3">
               <v-container fluid>
                    <v-row class="pa-5" align="center">
                         <span class="heading_color--text">
                              {{ $t('components.blog') }}
                         </span>
                         <v-spacer></v-spacer>
                         <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                   <v-btn
                                        depressed
                                        class="heading_color--text"
                                        v-bind="attrs"
                                        v-on="on"
                                        >{{$t('global.morefunction')


















                                        }}<v-icon small
                                             >mdi-triangle-small-down</v-icon
                                        >
                                   </v-btn>
                              </template>
                              <v-list dense>
                                   <v-list-item link>
                                        <v-list-item-icon id="menu-list-btn01">
                                             <v-icon small
                                                  >mdi-database-import</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                             <v-list-item-title
                                                  class="grey--text"
                                             >
                                                  {{$t('global.import')}}
                                             </v-list-item-title>
                                        </v-list-item-content>
                                   </v-list-item>

                                   <v-list-item link @click="onExportCSV()">
                                        <v-list-item-icon id="menu-list-btn02">
                                             <v-icon small
                                                  >mdi-database-export</v-icon
                                             >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                             {{$t('global.export')}}
                                        </v-list-item-title>
                                   </v-list-item>
                              </v-list>
                         </v-menu>
                         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                         <v-btn
                              color="primary"
                              class="body-2 font-weight-bold"
                              @click="addBlog"
                         >
                              <v-icon size="18">mdi-plus</v-icon>
                              <span class="white--text">
                                   {{$t('global.add')}}
                              </span>
                         </v-btn>
                    </v-row>
               </v-container>

               <div class="heading_bg px-3">
                    <v-container fluid>
                         <v-row>
                              <v-col cols="4 my-auto">
                                   <span class="mx-7"> &nbsp; </span>
                                   <span
                                        class="caption heading_color--text"
                                        >{{ $t('components.blog') }}</span
                                   >
                              </v-col>
                              <v-col cols="4 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('mobile.content') }}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('mobile.publishdate') }}
                                   </span>
                              </v-col>
                              <v-col cols="2 my-auto">
                                   <span class="caption heading_color--text">
                                        {{ $t('mobile.status') }}
                                   </span>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>

               <v-container fluid class="amList fill-height">
                    <v-row
                         :class="`tc${index % 2}`"
                         v-for="(blog, index) in blogList"
                         :key="blog._id"
                         class="px-3 fill-height"
                         align="center"
                    >
                         <v-col cols="4" class="d-flex flex-row">
                              <v-avatar
                                   size="15"
                                   color="heading_bg"
                                   class="my-auto mx-5"
                              >
                                   <span class="caption heading_color--text">{{
                                        (page - 1) * 5 + index + 1
                                   }}</span>
                              </v-avatar>

                              <div class="my-auto">
                                   <!--<a :href="`/blogdetail/${blog._id}`" 
              class="text-decoration-none text-subtitle-1">
            {{ blog.title }}</a>-->
                                   <span
                                        class="text-decoration-none text-subtitle-1"
                                        >{{ blog.title }}</span
                                   >
                              </div>
                         </v-col>

                         <v-col cols="4" class="my-auto">
                              <div class="text2_color--text text-caption">
                                   <div style="overflow: hidden">
                                        <pre>{{ blog.short_description }}</pre>
                                   </div>
                              </div>
                         </v-col>

                         <v-col cols="2">
                              <div class="d-flex justify-space-between">
                                   <span>{{
                                        formatDate(blog.publish_date)
                                   }}</span>
                              </div>
                         </v-col>
                         <v-col cols="2" class="d-flex justify-space-between">
                              <v-icon
                                   small
                                   :color="
                                        blog.status == 'active'
                                             ? 'primary'
                                             : 'red'
                                   "
                              >
                                   {{
                                        blog.status == 'active'
                                             ? 'mdi-checkbox-marked-circle-outline'
                                             : 'mdi-cancel'
                                   }}
                              </v-icon>

                              <v-menu offset-y>
                                   <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                             v-bind="attrs"
                                             v-on="on"
                                             color="primary"
                                             >mdi-dots-vertical-circle</v-icon
                                        >
                                   </template>
                                   <v-list dense>
                                        <v-list-item
                                             link
                                             @click="modifyRecord(blog._id)"
                                        >
                                             <v-list-item-icon
                                                  class="menu-list-btn"
                                             >
                                                  <v-icon
                                                       >mdi-pencil-circle</v-icon
                                                  >
                                             </v-list-item-icon>
                                             <v-list-item-title
                                                  class="ml-1"
                                                  >{{ $t('global.modify') }}</v-list-item-title
                                             >
                                        </v-list-item>
                                        <v-list-item
                                             link
                                             @click="deleteRecord(blog._id)"
                                        >
                                             <v-list-item-icon
                                                  class="menu-list-btn"
                                             >
                                                  <v-icon
                                                       >mdi-delete-circle</v-icon
                                                  >
                                             </v-list-item-icon>
                                             <v-list-item-title
                                                  class="ml-1"
                                                  >{{ $t('global.delete') }}</v-list-item-title
                                             >
                                        </v-list-item>
                                   </v-list>
                                   <v-divider></v-divider>
                                   <v-list subheader dense>
                                        <v-subheader>
                                             {{ $t('mobile.blogstatus') }}
                                        </v-subheader>
                                        <v-list-item
                                             link
                                             @click="
                                                  updateBlogStatus(
                                                       blog._id,
                                                       'inactive'
                                                  )
                                             "
                                        >
                                             <v-list-item-content>
                                                  <v-list-item-title>
                                                       {{ $t('mobile.stoppublish') }}
                                                  </v-list-item-title>
                                                  <v-list-item-subtitle>
                                                       {{ $t('mobile.stoppublishdesc')}}
                                                  </v-list-item-subtitle>
                                             </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item
                                             link
                                             @click="
                                                  updateBlogStatus(
                                                       blog._id,
                                                       'active'
                                                  )
                                             "
                                        >
                                             <v-list-item-content>
                                                  <v-list-item-title>
                                                       {{ $t('mobile.stoppublish') }}
                                                  </v-list-item-title>
                                                  <v-list-item-subtitle>
                                                       {{ $t('mobile.publishdesc')}}
                                                  </v-list-item-subtitle>
                                             </v-list-item-content>
                                        </v-list-item>
                                   </v-list>
                              </v-menu>
                         </v-col>
                    </v-row>
               </v-container>

               <div class="text-center">
                    <v-container>
                         <v-row justify="center">
                              <v-col cols="6">
                                   <v-container class="max-width">
                                        <v-pagination
                                             v-model="page"
                                             class="my-0"
                                             :length="noOfPage"
                                             @input="onChangePage"
                                        ></v-pagination>
                                   </v-container>
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </div>

          <ConfirmDialog ref="confirm" />

          <v-dialog v-model="dialog" persistent max-width="790px">
               <v-card class="pa-0">
                    <BlogAddComponent
                         v-if="dialog"
                         @onClickCancel="cancelInput"
                         @onClickInput="successInput"
                         @onFailAction="failInput"
                         :_id="selectedid"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     import ConfirmDialog from '@/views/components/ConfirmDialog';
     import BlogService from '@/utils/api/BlogService';
     import BlogAddComponent from '@/views/mobile/blog/BlogAddView';

     const moment = require('moment');
     export default {
          data() {
               return {
                    pagekey: ['OEVMz'],
                    managekey: ['NogJD'],

                    selectedid: null,

                    blogList: [],
                    page: 1,
                    noOfPage: 0,
                    recordperpage: 5,

                    dialog: false,

                    is_success: false,
                    is_fail: false,
                    success_message: '',
                    error_message: '',
               };
          },
          methods: {
               apiGetRecordsCount() {
                    BlogService.getCount()
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let rs = response.data.data;
                                   let reminder = rs % this.recordperpage;
                                   if (reminder > 0) reminder = 1;
                                   let ps = Math.floor(rs / this.recordperpage);
                                   this.noOfPage = reminder + ps;
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },
               apiGetBlogList() {
                    BlogService.getAll(this.page, this.recordperpage)
                         .then((response) => {
                              if (response.data.status == 'success')
                                   this.blogList = response.data.data;
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },

               onChangePage() {
                    this.apiGetBlogList();
               },
               formatDate(value) {
                    return moment(value).format('YYYY-MM-DD');
               },
               cancelInput() {
                    this.dialog = false;

                    this.is_success = false;
                    this.is_fail = false;
                    this.success_message = '';
                    this.error_messag = '';
               },
               successInput: function (msg) {
                    this.apiGetRecordsCount();
                    this.apiGetBlogList();
                    this.dialog = false;

                    this.success_message = msg;
                    this.is_success = true;
                    this.is_fail = false;
               },
               failInput: function (err_msg) {
                    this.dialog = false;

                    this.is_success = false;
                    this.error_message = err_msg;
                    this.is_fail = true;
               },
               async deleteRecord(id) {
                    if (
                         await this.$refs.confirm.open(
                              '確定操作',
                              '你是否要刪除此項？刪除後不能復原'
                         )
                    ) {
                         // eslint-disable-next-line
                         BlogService.deleteBlog(id)
                              .then((response) => {
                                   if (response.data.status == 'success') {
                                        this.success_message = '成功刪除';
                                        this.is_success = true;
                                        this.apiGetRecordsCount();
                                        this.apiGetBlogList();
                                   }
                              })
                              .catch((e) => {
                                   this.error_message = e.message;
                                   this.is_fail = true;
                              });
                    }
               },
               modifyRecord(id) {
                    this.selectedid = id;
                    this.dialog = true;
               },
               addBlog() {
                    this.selectedid = null;
                    this.dialog = true;
               },
               updateBlogStatus(id, s = 'inactive') {
                    BlogService.updateBlog({ _id: id, status: s })
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   this.success_message = '成功更新';
                                   this.is_success = true;
                                   this.apiGetRecordsCount();
                                   this.apiGetBlogList();
                              }
                         })
                         .catch((e) => {
                              this.error_message = e.message;
                              this.is_fail = true;
                         });
               },
          },
          created() {
               this.apiGetRecordsCount();
               this.apiGetBlogList();
          },
          components: {
               ConfirmDialog,
               BlogAddComponent,
          },
     };
</script>
