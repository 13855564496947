import http from '../HttpConn';
var isObject = require('lodash/isObject');
class TemplateService {
     getAll(page, limit) {
          return http.get('/template', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/template/count');
     }
     getTemplate(id) {
          return http.get('/template/' + id);
     }
     /* eslint-disable */
     addTemplate(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.post('/template/add', ccobj);
     }
     deleteTemplate(id) {
          return http.delete('/template/delete/' + id);
     }
     /* eslint-enable */
}
export default new TemplateService();
