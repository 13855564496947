<template>
     <div class="pa-7">
          <v-form
               ref="form"
               v-model="valid"
               lazy-validation
               class="px-5 py-2 post_bg"
          >
               <div class="text-h6 mt-5">
                    {{ $t('setting.companyinfo') }}
               </div>
               <v-divider class="mb-5"></v-divider>

               <div class="d-inline-flex flex-row mt-3">
                    <v-img
                         :src="url + '/' + companyobj.logo + '?' + randno"
                         :eager="true"
                         v-if="!isImgLoading"
                         height="120"
                         width="120"
                         class="mx-2"
                    ></v-img>
                    <v-btn
                         color="primary"
                         @click="$refs.inputUpload.click()"
                         outlined
                         height="120"
                         width="120"
                    >
                         {{ $t('components.uploadimage') }}
                    </v-btn>
                    <input
                         v-show="false"
                         ref="inputUpload"
                         type="file"
                         @change="uploadImage"
                    />
                    <div class="my-auto">
                         <span class="text-caption mx-3">
                              {{ $t('setting.imageuploaddesc') }}
                         </span>
                         <v-progress-linear
                              class="mx-3"
                              :value="progress"
                         ></v-progress-linear>
                         <span
                              class="text-body1 error--text mx-3"
                              v-if="error_message"
                              >{{ error_message }}</span
                         >
                    </div>
               </div>
               <div class="d-flex flex-row mt-3">
                    <v-text-field
                         v-model="companyobj.name"
                         :rules="nameRules"
                         :label="$t('setting.companyname')"
                         :hint="$t('global.required')"
                         persistent-hint
                         outlined
                         dense
                         class="px-3"
                         background-color="#ffffff"
                    ></v-text-field>
                    <v-text-field
                         v-model="companyobj.phone"
                         :label="$t('setting.companyphone')"
                         outlined
                         dense
                         class="px-3"
                         background-color="#ffffff"
                    ></v-text-field>
               </div>

               <div class="d-flex flex-row mt-3">
                    <v-text-field
                         v-model="companyobj.website"
                         :label="$t('setting.companywebsite')"
                         outlined
                         dense
                         class="px-3"
                         background-color="#ffffff"
                    ></v-text-field>
                    <v-text-field
                         v-model="companyobj.fax"
                         :label="$t('setting.companyfax')"
                         outlined
                         dense
                         class="px-3"
                         background-color="#ffffff"
                    ></v-text-field>
               </div>

               <div class="d-flex flex-row mt-3">
                    <v-textarea
                         v-model="companyobj.address"
                         :label="$t('setting.address')"
                         rows="2"
                         outlined
                         class="px-3"
                         dense
                         background-color="#ffffff"
                    >
                    </v-textarea>
               </div>

               <div class="text-h6 mt-5">
                    {{ $t('setting.businesshour') }}
               </div>
               <v-divider class="mb-5"></v-divider>
               <div
                    class="d-flex flex-row px-1"
                    v-for="day in companyobj.business_hour"
                    :key="day.week"
               >
                    <span class="mr-3 mt-2" :style="{ width: '100px' }">{{
                         dayname[day.weekday - 1]
                    }}</span>
                    <v-select
                         v-model="day.start"
                         :items="clocklist"
                         :label="$t('setting.workstart')"
                         outlined
                         dense
                         :disabled="!day.active"
                         :style="{ 'max-width': '100px' }"
                         class="mx-2"
                         background-color="#ffffff"
                    ></v-select>
                    <span class="mx-3 mt-2"> - </span>
                    <v-select
                         v-model="day.end"
                         :items="clocklist"
                         :label="$t('setting.workend')"
                         outlined
                         dense
                         :disabled="!day.active"
                         :style="{ 'max-width': '100px' }"
                         class="mx-2"
                         background-color="#ffffff"
                    ></v-select>
                    <v-switch
                         color="primary"
                         v-model="day.active"
                         :label="day.active ? $t('setting.open') : $t('setting.close')"
                         hide-details
                         inset
                         class="mt-1 mx-5"
                    ></v-switch>
               </div>

               <div class="text-h6 mt-5">
                    {{ $t('setting.companypage') }}
               </div>
               <v-divider class="mb-5"></v-divider>
               <v-text-field
                    v-model="companyobj.facebook"
                    label="Facebook"
                    prepend-icon="mdi-facebook"
                    outlined
                    dense
                    class="px-3 mt-3"
                    background-color="#ffffff"
               ></v-text-field>
               <v-text-field
                    v-model="companyobj.instagram"
                    label="Instagram"
                    prepend-icon="mdi-instagram"
                    outlined
                    dense
                    class="px-3"
                    background-color="#ffffff"
               ></v-text-field>
               <v-text-field
                    v-model="companyobj.twitter"
                    label="Twitter"
                    prepend-icon="mdi-twitter"
                    outlined
                    dense
                    class="px-3"
                    background-color="#ffffff"
               ></v-text-field>
               <v-text-field
                    v-model="companyobj.linkedin"
                    label="LinkedIn"
                    prepend-icon="mdi-linkedin"
                    outlined
                    dense
                    class="px-3"
                    background-color="#ffffff"
               ></v-text-field>
          </v-form>

          <v-container fluid>
               <v-row class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                         color="primary"
                         class="body-2 font-weight-bold"
                         @click="updateRecord"
                    >
                         {{$t('global.save')}}
                    </v-btn>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <v-btn
                         color="grey"
                         text
                         class="body-2 font-weight-bold"
                         @click="cancel"
                    >
                         {{$t('global.cancel')}}
                    </v-btn>
               </v-row>
          </v-container>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
     /* eslint-disable */
     import BrowserSession from '@/utils/BrowserSession';
     import uploadService from '@/utils/uploadFileService';
     import SettingService from '@/utils/api/SettingService';
     //const config = require('@/config/config.js');

     export default {
          data: () => ({
               valid: true,
               //previewImage:'noimage.jpeg',

               orgi_companyobj: {},
               companyobj: {
                    logo: 'noimage.jpeg',
                    name: '',
                    phone: '',
                    fax: '',
                    website: '',
                    address: '',
                    facebook: '',
                    instagram: '',
                    twitter: '',
                    linkedin: '',
                    business_hour: [
                         { weekday: 1, start: '9:00', end: '18:00', active: true },
                         { weekday: 2, start: '9:00', end: '18:00', active: true },
                         { weekday: 3, start: '9:00', end: '18:00', active: true },
                         { weekday: 4, start: '9:00', end: '18:00', active: true },
                         { weekday: 5, start: '9:00', end: '18:00', active: true },
                         { weekday: 6, start: '9:00', end: '18:00', active: true },
                         { weekday: 7, start: '9:00', end: '18:00', active: true },
                    ],
               },

               nameRules: [(v) => !!v || 'Name is required'],
               addressRules: [(v) => !!v || 'Address is required'],
               progress: 0,

               isImgLoading: false,
               randno: 0,

               is_fail: false,
               is_success: false,
               success_message: '',
               error_message: '',

               working: [
                    { weekday: 1, start: '9:00', end: '18:00', active: true },
                    { weekday: 2, start: '9:00', end: '18:00', active: true },
                    { weekday: 3, start: '9:00', end: '18:00', active: true },
                    { weekday: 4, start: '9:00', end: '18:00', active: true },
                    { weekday: 5, start: '9:00', end: '18:00', active: true },
                    { weekday: 6, start: '9:00', end: '18:00', active: false },
                    { weekday: 7, start: '9:00', end: '18:00', active: false },
               ],
               dayname: [
                    '星期一',
                    '星期二',
                    '星期三',
                    '星期四',
                    '星期五',
                    '星期六',
                    '星期日',
               ],

               clocklist: [
                    '0:00',
                    '0:30',
                    '1:00',
                    '1:30',
                    '2:00',
                    '2:30',
                    '3:00',
                    '3:30',
                    '4:00',
                    '4:30',
                    '5:00',
                    '5:30',
                    '6:00',
                    '6:30',
                    '7:00',
                    '7:30',
                    '8:00',
                    '8:30',
                    '9:00',
                    '9:30',
                    '10:00',
                    '10:30',
                    '11:00',
                    '11:30',
                    '12:00',
                    '12:30',
                    '13:00',
                    '13:30',
                    '14:00',
                    '14:30',
                    '15:00',
                    '15:30',
                    '16:00',
                    '16:30',
                    '17:00',
                    '17:30',
                    '18:00',
                    '18:30',
                    '19:00',
                    '19:30',
                    '20:00',
                    '20:30',
                    '21:00',
                    '21:30',
                    '22:00',
                    '22:30',
                    '23:00',
                    '23:30',
               ],

               url: process.env.VUE_APP_APISERVER || `${location.origin}/api`,
          }),
          methods: {
               uploadImage(event) {
                    this.progress = 0;
                    this.isImgLoading = true;

                    uploadService
                         .uploadLogo(event.target.files[0], (event) => {
                              this.progress = Math.round(
                                   (100 * event.loaded) / event.total
                              );
                         })
                         .then((response) => {
                              this.companyobj.logo = response.data.file.filename;
                              //console.log(response);
                              this.is_success = true;
                              this.success_message = 'Upload completed!';
                              this.isImgLoading = false;
                              this.randno = Math.random();
                         })
                         .catch((err) => {
                              this.progress = 0;
                              this.error_message = 'Fail: ' + err.message;
                              this.isImgLoading = false;
                              //console.log(err.response.data.error);
                              //console.log(this.message);
                         });
               },

               apiGetCompanyRecord() {
                    SettingService.getSettingByKey('company')
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   let data = response.data.data;
                                   //console.log(response);
                                   this.orgi_companyobj = {
                                        ...this.orgi_companyobj,
                                        ...data.value,
                                   };
                                   this.companyobj = {
                                        ...this.companyobj,
                                        ...data.value,
                                   };

                                   this.previewImage = data.value.logo
                                        ? data.value.logo
                                        : '';
                                   this.name = data.value.name
                                        ? data.value.name
                                        : '';
                                   this.phone = data.value.phone
                                        ? data.value.phone
                                        : '';
                                   this.fax = data.value.fax ? data.value.fax : '';
                                   this.website = data.value.website
                                        ? data.value.website
                                        : '';
                                   this.address = data.value.address
                                        ? data.value.address
                                        : '';
                                   this.facebook = data.value.facebook
                                        ? data.value.facebook
                                        : '';
                                   this.ig = data.value.instagram
                                        ? data.value.instagram
                                        : '';
                                   this.twitter = data.value.twitter
                                        ? data.value.twitter
                                        : '';
                                   this.linkedin = data.value.linkedin
                                        ? data.value.linkedin
                                        : '';
                                   this.working = data.value.business_hour
                                        ? data.value.business_hour
                                        : '';
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                         });
               },

               updateRecord() {
                    //1. update logo
                    SettingService.updateSettingByKey('company', this.companyobj)
                         .then((response) => {
                              if (response.data.status == 'success') {
                                   //BrowserSession.cleanAllSession();
                                   //BrowserSession.getAllSession();
                                   this.reloadSettingCache();
                                   this.is_success = true;
                                   this.success_message = '成功更新';
                              }
                         })
                         .catch((e) => {
                              console.log(e);
                              this.is_fail = true;
                              this.error_message = e.message;
                         });
               },

               cancel() {
                    //this.clearAllField();
               },

               reloadSettingCache() {
                    BrowserSession.renewSession('setting', null);
               },
          },
          created() {
               this.apiGetCompanyRecord();

               this.dayname = [this.$t('user.monday'),
                               this.$t('user.tuesday'),
                               this.$t('user.wednesday'),
                               this.$t('user.thursday'),
                               this.$t('user.friday'),
                               this.$t('user.saturday'),
                               this.$t('user.sunday')
                              ]
          },
          components: {},
     };
</script>
