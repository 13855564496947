export default {
     global: {
          confirm: 'Confirm',
          cancel: 'Cancel',
          close: 'Close',
          ok: 'OK',
          update: 'Update',
          add: 'Add',
          modify: 'Modify',
          delete: 'Delete',
          save: 'Save',
          type: 'Type',
          category: 'Category',
          brand: 'Brand',
          unit: 'Unit',
          required: '*Required',
          fieldrequired: 'This field is required',
          successPrefix: 'Success ',
          successPostfix: '',
          failPrefix: 'Fail ',
          failPostfix: '',
          male: 'Male',
          female: 'Female',
          createdat: 'Created At ',
          newrecord: 'New record',
          expiry: 'Expiry',
          remark: 'Remark',
          log: 'Log',
          end: 'End',
          name: 'Name',
          consume: 'Consume',
          plsentervalid: 'Input invalid, please check your input',
          morefunction: 'More',
          import: 'Import',
          export: 'Export',
          search: 'Search',
          filter: 'Filter',
          date: 'Date',
          eq: 'Equal',
          gt: 'Greater than',
          lt: 'Less than',
          active: 'Active',
          inactive: 'Inactive',
          duplicate: 'Duplicate',
          print: 'Print',
          permissionerrmsg: 'Sorry, no permission!',
          notfound: 'Data not found',
          or: 'Or',
          password: 'Password',
          errorgettingdata: 'Get data error!',
     },
     appointment: {
          resource: 'resource',
          salesfilter: 'Filter by staff',
          resourcefilter: 'Filter by resource',
          addevent: 'Add new event',
          modifyevent: 'Modify event',
          searchbynameandphone: 'Enter phone/name to search',
          bookservice: 'service',
          bookresource: 'resource',
          dateandtime: 'Date and time',
          free: 'Free',
          available: 'Available',
          busy: 'Busy',
          starttime: 'Start at',
          endtime: 'End at',
          bookedevent: 'Booked event',
          bookedresource: 'Booked resource',
          thisbooking: 'Booking',
          newbooking: 'New',
          customernotfoundmsg: 'Customer not found',
          missingcustomerinfomsg: 'Please enter customer information',
          missingstaffmsg: 'Please select staff',
          completed: 'Completed',
          detail: 'Detail',
          normalbooking: 'Normal booking',
     },
     components: {
          gallery: 'Gallery',
          uploadimage: 'Upload',
          sessionexpirytitle: ' Login Session Expiry',
          sessionexpirymsg: 'Your session had expiry, please login again.',
          dashboard: 'Dashboard',
          appointment: 'Appointment',
          customer: 'Customer',
          customerlv: 'Membership',
          product: 'Product',
          service: 'Service',
          package: 'Package',
          sales: 'Sales',
          quotation: 'Quotation',
          invoice: 'Invoice',
          transaction: 'Transaction',
          payment: 'Payment',
          expense: 'Expense',
          report: 'Report',
          salereport: 'Sales Report',
          statement: 'Statement',
          customersummary: 'Customer List',
          productreport: 'Product Report',
          paymentreport: 'Payment Report',
          salesreport: 'Employee Report',
          salessummary: 'Employee Summary',
          commissionreport: 'Commission Report',
          employee: 'Employee',
          permissiongroup: 'Permission Group',
          app: 'App',
          blog: 'Blog',
          pushmsg: 'Push Message',
          segment: 'Segment',
          setting: 'Setting',
          companysetting: 'Company',
          resourcesetting: 'Resource',
          commissionsetting: 'Commission',
          templatesetting: 'Template',
          notificationsetting: 'Notification',
          apisetting: 'API',
          mediasetting: 'Media',
          othersetting: 'Other',
          execute: 'Execute',
          confirmdeletemsg: 'Delete process cannot recover, delete anyway?',
          executesegmentmsg:
               'Start customer segmentation? Process need to run several minutes or hours, start now?',
          successdeletedmsg: 'Delete success',
          exesegmentsuccessmsg:
               'Process completed, you could now start to use this customer segment.',
          modifystatus: 'Modify status',
          booking: 'Booking',
     },
     customer: {
          addcustomer: 'New customer',
          modifycustomer: 'Modify Customer',
          customer: 'Customer',
          name: 'Customer name',
          company: 'Company name',
          phone: 'Phone number',
          email: 'Email',
          dob: 'Birthday',
          quickselectyear: 'Quick select',
          gender: 'Gender',
          sales: 'Staff',
          address: 'Address',
          detail: 'Detail',
          tag: 'Tag',
          status: 'Status',
          randm: 'Reward/Point',
          reward: 'Reward',
          membershippt: 'Point',
          joinmembership: 'Join membership system?',
          membershiplv: 'Membership',
          memebshipexpiry: 'Expiry on',
          createmembershipfirst: 'Please create at least 1 membership!',
          modifymembership: 'Modify',
          membershipname: 'Name',
          setdiscount: 'Discount',
          membershipreq: 'Point require',
          setdefaultlv: 'Set as default',
          contactdetail: 'Contact',
          otherdetail: 'Other',
          remain: 'Remain',
          newconsumerecord: 'Add new consume record',
          consumecredit: 'Consume',
          overdue: 'Overdue',
          outstanding: 'Outstanding',
          createinvoice: 'new invoice',
          segmentname: 'Segment name',
          segmentcondition: 'Condition',
          segmentcompare: 'is',
          value: 'value',
          addnewcondition: 'Add condition',
          addtagmsg: 'Press <kbd>enter</kbd> to create a new tag。',
          suspendaccount: 'Account suspended',
          activeaccount: 'Account active',
          modifylogin: 'Update Login',
          searchplaceholder: 'Customer full name, phone, tag',
          newpassword: 'New password',
          generate: 'Genrate',
          allowlogin: 'Allow customer login',
          modifyinfomsg1: '1) You need to click generate to get a new password',
          modifyinfomsg2:
               '2) You must input permission password, and click update to reset new password',
          superpassword: 'Permission password',
          advancesearch: 'Advance search',
          fulltagplaceholder: 'Full tag name',
     },
     dashboard: {
          sevendayssales: '7 days sales',
          overdue: 'overdue',
          newcustomer: 'new customer',
          todayinvoices: 'today invoice',
     },
     invoice: {
          amount: 'Amount',
          invoicedate: 'Invoice Date',
          invoiceamount: 'Invoice Amount',
          paid: 'Paid',
          unpaid: 'Unpaid',
          void: 'Void',
          status: 'Status',
          expensename: 'Expense name',
          receiver: 'To',
          duedate: 'Due date',
          paymentmethod: 'Payment method',
          detail: 'Detail',
          paymentdate: 'Payment date',
          paymentinit: 'Pending',
          paymentdone: 'Done',
          cash: 'Cash',
          cheque: 'Cheque',
          creditcard: 'Credit card',
          visa: 'VISA',
          master: 'MASTER',
          AE: 'AE',
          ATM: 'ATM',
          banktransfer: 'Bank transfer',
          octopus: 'Octopus',
          alipay: 'Alipay',
          wechatpay: 'Wechat pay',
          unionpay: 'Union',
          payme: 'PayMe',
          applepay: 'Apple pay',
          fps: 'FPS',
          paypal: 'Paypal',
          other: 'Other',
          expenserecord: 'Expense',
          expensetips:
               '<span>Your expense record</span><br /><span>you can record all your expense / bill here</span>',
          donetips: 'Change to complete',
          inittips: 'Make pending',
          voidtips: 'Void this expense',
          importtemplate: 'Import template',
          selectcustomer: 'Select customer',
          salesrepresentative: 'Sales',
          invoiceno: 'Invoice',
          pono: 'P.O.',
          paybefore: 'Payment due',
          invoicecurrency: 'Currency',
          unitprice: 'Price',
          qty: 'Quantity',
          subtotal: 'Subtotal',
          tax: 'Tax',
          discount: 'Discount',
          tandc: 'Term and Condition',
          invoicetag: 'Add tag to invoice',
          redeemmark: 'Membership redeem',
          redeemtips:
               '<span>Member could redeem their point</span><br /><span>Redeem amount could use as cash</span>',
          redeem: 'Redeem',
          redeemamount: 'Redeem Total',
          invoicepayment: 'Add payment',
          invoicepaymenttips:
               '<span>Add new payment for invoice</span><br /><span>You could press FULL for full payment</span><br /><span>When you click save, it payment will create at the same time</span>',
          allamount: 'FULL',
          payandsave: 'pay and save',
          commissionto: 'Commission person',
          commissiontotips: 'Default commission person is sale representative',
          commission: 'Commission',
          commissiontips:
               'Commission was calculate base on the product setting',
          stockit: 'Save to member account as stock',
          setserviceconsume: 'Set consume services(max 2)',
          selectconsumeservice: 'Select consumed service',
          modifytandc: 'Modify term and condition',
          remainstockcredit: 'Remain Credit',
          maxusagelimit: 'Usage limit',
          unlimit: 'unlimit',
          consumed: 'Consumed',
          exceedlimit: 'Exceed credit can use',
          include: 'Include',
          invoicetips:
               "<span>Invoice listing, perform action on invoice</span><br /><span>or make payment to invoice.</span><br /><span>Void invoice won't delete invoice.</span>",
          printpreview: 'Preview',
          invoicedonetips: 'Full payment received, completed',
          invoiceinittips: 'Pending, payment not complete',
          invoicevoidtips: 'Void this invoice, but no deleted',
          paymentfunction: 'Payment',
          addnewpayment: 'Add payment',
          newpaymenttips: 'Add new payment on this invoice',
          savetemplate: 'Save as template',
          savetemplatetips: 'Save this invoice as a template',
          overamountmsg: 'Payment over total amount, still continue?',
          billto: 'Bill To',
          nondiscountable: 'Non-discountable',
          nametemplate: 'Enter template name',
          templateerrmsg: 'Please enter valid template name!',
          paymentincompletemsg:
               'This invoice not yet fully paid, continue to completed？',
          voidmsg: 'VOID invoice action is irreversible!<br>This action <b>WILL NOT</b> modify <b>payment </b>detail，<br>you need to VOID payment detail manually to avoid inconsistent！',
          voidpaymentmsg:
               'VOID this payment? This action is irreversible!<br>This action <b>WILL</b><br>1.VOID payment<br>2.Reduce membership point（if enable membership system）<br>3.Refund package credit<br>But <b>WILL NOT</b><br>1.Modify invoice status<br>2.Remove package<br>3.Step down membership level',
          paymenttips:
               '<span>This way to payment</span><br /><span>cannot use point！</span>',
          unpaidamount: 'Unpaid amount',
          autoupdateinvoicestatus: 'Auto update invoice status',
          isDiscountable: 'Discountable',
          paymentamount: 'Payment amount',
          searchplaceholder: 'Customer name, phone',
          read: 'Read',
          searchbynameandphone: 'Name/Phone search',
          advancesearch: 'Advance search',
          selectinvoice: 'Select invoice',
          normalpayment: 'Normal payment',
          packagepayment: 'Package payment',
          receivable: 'Receivable',
     },
     mobile: {
          stoppublish: 'Cancel publish',
          content: 'Content',
          publishdate: 'Publish date',
          status: 'Status',
          blogstatus: 'Blog status',
          stoppublishdesc: 'Stop show this post',
          publish: 'Publish',
          publishdesc: 'Show this post to publish',
          title: 'Title',
          setimage: 'Image',
          shortdesc: 'Short description',
          deliverydate: 'Delivery date',
          stopdeliverydesc: 'Stop schedule this push message',
          deliverydesc: 'Start schedule this push message',
          target: 'Target audience',
          messagesetting: 'Push message setting',
          targetsetting: 'Audience setting',
          deliverysetting: 'Delivery setting',
          advancesetting: 'Advance setting',
          deliverydatetime: 'Delivery time',
          sound: 'PM Sound',
          redirectpost: 'Select post on click',
          booking: 'Booking',
          bookingdate: 'Booking date',
          bookingperson: 'Booker',
          bookedservice: 'Book service',
          remark: 'Remark',
          person: 'Person',
          personcount: 'Visitors',
          phone: 'Phone',
          pending: 'Pending',
          pendingdesc: 'Change booking to pending',
          approved: 'Approved',
          approveddesc: 'Change booking to approved',
          rejected: 'Rejected',
          rejecteddesc: 'Reject this booking',
     },
     product: {
          name: 'Product name',
          stock: 'Stock',
          remaincredit: 'Remain Credit',
          status: 'Status',
          detail: 'Detail',
          cost: 'Cost',
          credit: 'Credit',
          managecategory: 'Manage category',
          managebrand: 'Manage brand',
          manageunit: 'Manage unit',
          sku: 'SKU',
          category: 'Category',
          setimage: 'Image',
          imagemsg:
               'Image format should be jpg/png, and file size below 100KB(400*400)',
          inputcategory: 'You can input new category here',
          brand: 'Brand',
          inputbrand: 'You can input new brand here',
          price: 'Price',
          unit: 'Unit',
          inputunit: 'You can input new unit here',
          barcode: 'Barcode',
          commission: 'Commission',
          allowpersist: 'Allow product store to account',
          allowdiscount: 'Allow product discount',
          categoryerrormsg: 'Cannot create new category',
          branderrormsg: 'Cannot create new brand',
          uniterrormsg: 'Cannot create new unit',
     },
     service: {
          name: 'Service',
          managecategory: 'Manage category',
          manageunit: 'Manage unit',
          category: 'Category',
          serviceno: 'Service no',
          price: 'Price',
          status: 'Status',
          setimage: 'Image',
          imagemsg:
               'Image format should be jpg/png, and file size below 100KB(400*400)',
          inputcategory: 'You can input new category here',
          unit: 'Unit',
          inputunit: 'You can input new unit here',
          serviceduration: 'Service duration',
          minute: 'minute',
          cost: 'Cost',
          detail: 'Detail',
          commission: 'Commission',
          allowpersist: 'Allow product store to account',
          allowdiscount: 'Allow product discount',
          categoryerrormsg: 'Cannot create new category',
          uniterrormsg: 'Cannot create new unit',
     },
     package: {
          name: 'Package',
          manageunit: 'Manage unit',
          id: 'ID',
          duration: 'duration',
          price: 'Price',
          status: 'Status',
          credit: 'Credit',
          allowpersist: 'Allow product store to account',
          allowdiscount: 'Allow product discount',
          convertcredit: 'convert credit',
          unit: 'Unit',
          inputunit: 'You can input new unit here',
          detail: 'Detail',
          month: 'Month',
          commission: 'Commission',
          specifyservice: 'Specify service',
          limit: 'Limit',
          consumecredit: 'Consume credit',
          cost: 'Cost',
          uniterrormsg: 'Cannot create new unit',
     },
     report: {
          salesreport: 'Sales report',
          salesreporttips:
               '<span>Search all invoice by period of time</span><br /><span>No status mean all status</span>',
          daterange: 'Date',
          invoicestatus: 'Invoice status',
          today: 'Today',
          past7day: 'Past 7 days',
          past1month: 'Past 30 days',
          thismonth: 'This month',
          pastmonth: 'Last month',
          executereport: 'Execute',
          statementreport: 'Statement report',
          statementreporttips:
               '<span>Statement for customer</span><br /><span>Default filter is paid & unpaid invoice</span>',
          balance: 'balance',
          customerreport: 'Customer sales report',
          customerreporttips:
               '<span>Customer sales listing report</span><br /><span>（Not include VOID invoice）</span><br /><span>If you need to get VOID invoice, you could access statement report</span>',
          productreport: 'Product report',
          productreporttips:
               '<span>Show product sales report</span><br /><span>VOID invoice will not included</span><br /><span>Statistic only base on product standard price.</span>',
          benefit: 'Benefit',
          paymentreport: 'Payment report',
          paymentreporttips:
               '<span>Display payment detail by period of time</span><br />',
          invoicestatusstat: 'Payment status statistic',
          paymentstat: 'Payment statistic',
          employeereport: 'Employee report',
          employeereporttips:
               '<span>Employee sales report</span><br /><span>VOID invoice will not include</span><br /><span>Invoice discount will not include</span>',
          selectsales: 'Select staff',
          totalsales: 'Total sales',
          totalcost: 'Total cost',
          employeedetailreport: 'Employee detail report',
          employeedetailreporttips:
               '<span>Employee report in detail</span><br /><span>Default show paid invoice only</span><br /><span>Target is filter by order or by product belong to sales</span>',
          target: 'Target',
          targettips:
               '<span>Target filter sales by order or by product</span><br /><span>By order could filter order that belong to sales</span><br /><span>By product could filter invoice product belong to sales</span>',
          invoiceitems: 'Invoice items',
          totalcommission: 'Total commission',
          totalqty: 'Total quantity',
          totalnet: 'Total net profit',
          net: 'Net profit',
          productsummary: 'Product summary',
          servicesummary: 'Service summary',
          packagesummary: 'Package summary',
     },
     setting: {
          companyinfo: 'Company information',
          imageuploaddesc:
               'Image format should be jpg/png, and file size below 100KB(800*800)',
          companyname: 'Company name',
          companyphone: 'Company phone',
          companywebsite: 'Company website',
          companyfax: 'Company fax',
          address: 'Address',
          businesshour: 'Buiness hour',
          workstart: 'Work start',
          workend: 'Work end',
          open: 'Open',
          close: 'Close',
          companypage: 'Company social channel',
          manageunit: 'Manage unit',
          resourcename: 'Resource name',
          type: 'Type',
          status: 'Status',
          description: 'Description',
          addnewtypedesc: 'Input type name，and press enter',
          commissionname: 'Commission name',
          salesrange: 'Sale range',
          getcommission: 'Commission',
          commissionhint:
               'Simple: Simple plan, determine by each sales range. Progression: Calculate commission by each range',
          saleamountlow: 'Sales amount(LOW)',
          saleamounthigh: 'Sales amount(HIGH)',
          commissioncal: 'Commission calculation by',
          commissionvalue: 'Commission',
          templatename: 'Template name',
          belongtostaff: 'Belong Staff',
          createdat: 'Created at',
          notificationsetting: 'Push message setting',
          enablenotification: 'Enable push message',
          plsuploadconfig: 'Please upload config',
          firebasefile: 'Upload firebase service account JSON file',
          firebasefilehint:
               'Before upload JSON file，please backup your old JSON file',
          smtpsetting: 'SMTP Setting',
          enablesmtp: 'Enable SMTP',
          smtpserver: 'SMTP server',
          smtpport: 'SMTP port',
          smtpuser: 'SMTP user',
          smtppassword: 'SMTP password',
          smtpfrom: 'From(Email)',
          smtptest: 'Test Recipient',
          smtpsendtest: 'Send Test',
          enablegooglebu: 'Enable Google API backup',
          googledrivefile: 'Upload google drive service account JSON file',
          googledrivefilehint:
               '*Please create a backup folder on google drive, and share permission to google service account, in order to make backup effective.',
          backupnow: 'Back up now',
          backupmedia: 'Back up media',
          runningbackup: 'Backup process running, please wait...',
          invoicesetting: 'Invoice setting',
          invoiceprefix: 'Invoice prefix',
          decimalplace: 'Decimal place',
          carrysetting: 'Carry setting',
          autoupdatediscount: 'Auto update discount',
          paymentdue: 'Payment due(Day)',
          term: 'Term',
          paymenttype: 'Payment type',
          membersystemsetting: 'Member system setting',
          enablereward: 'Enable reward system',
          earnreward: 'Earn reward point',
          register: 'Register earn',
          point: 'point',
          consumeonedollar: '$1 earn',
          dob: 'Dob earn',
          pointexpirysetting: 'Point expiry setting',
          fixdateexpiry: 'Expiry on date',
          expirydate: 'Expiry date',
          memberdateexpiry: 'Member own expiry date, extend on transaction',
          memberdateexpirynoextend: 'Member own expiry date',
          extend: 'Extend',
          day: 'Day',
          noexpiry: 'Never expiry',
          pointredeemsetting: 'Redeem setting',
          onepointredeemevery: 'X points redeem 1 dollar',
          enablelevel: 'Enable membership system',
          earnlevelpoint: 'Earn membership point',
          eachorder: 'Each order earn',
          membershipexpirysetting: 'Membership expiry setting',
          membershipcal: 'Membership action when expiry',
          lvupsetting: 'Membership upgrade setting',
          auto: 'Automatic',
          manual: 'Manual',
          downtodefaultlv: 'Reset to default membership level on expiry',
          calmembership:
               'Base on current membership point to calculate on expiry',
          calmembershipnoupdownone:
               'Base on current point to calculate(No upgrade), not enough point will downgrade one level',
          calmembershipnoupdowndefault:
               'Base on current point to calculate(No upgrade), not enough point will downgrade to default',
          enable: 'Enable',
          disable: 'Disable',
          roundoff: 'Round off',
          roundup: 'Round up',
          roundown: 'Round down',
     },
     user: {
          name: 'Username',
          group: 'Group',
          phone: 'Phone',
          employdate: 'Employ date',
          status: 'Status',
          suspendaccount: 'Suspend',
          userinfo: 'Information',
          loginsetting: 'Login',
          commissionsetting: 'Commission',
          hoursetting: 'Working',
          avatar: 'Avatar',
          gender: 'Gender',
          occupation: 'Occupation',
          email: 'Email',
          id: 'ID card',
          dob: 'Birthday',
          address: 'Address',
          remark: 'Remark',
          loginname: 'Login name',
          loginpassword: 'Login password',
          allowlogin: 'Allow login',
          commissionplans: 'Commission plan',
          workstart: 'Work start',
          workend: 'Work end',
          open: 'Open',
          close: 'Close',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          groupname: 'Permission group name',
          selectpermission: 'Please select grant permission',
          BIMeL: 'View dashboard',
          BIMeLdesc: 'Allow user to access dashboard page',
          TiAFN: 'View appointment',
          TiAFNdesc: 'Allow user to access appointment page',
          xtDEL: 'Manage appointment',
          xtDELdesc: 'Allow user to operate on appointment',
          xDWyh: 'View customer',
          xDWyhdesc: 'Allow user to access customer page',
          HVvyR: 'Manage customer',
          HVvyRdesc: 'Allow user to operate on customer',
          BWNcA: 'View membership',
          BWNcAdesc: 'Allow user to access membership page',
          oTtHe: 'Manage membership',
          oTtHedesc: 'Allow user to operate on membership',
          fNaDt: 'View product',
          fNaDtdesc: 'Allow user to access product page',
          mLaeD: 'Manage product',
          mLaeDdesc: 'Allow user to operate on product',
          tJnMZ: 'View service',
          tJnMZdesc: 'Allow user to access service page',
          kbwnQ: 'Manage service',
          kbwnQdesc: 'Allow user to operate on service',
          ZvkrC: 'View package',
          ZvkrCdesc: 'Allow user to access package page',
          ChZpz: 'Manage package',
          ChZpzdesc: 'Allow user to operate on package',
          TWwvR: 'View quotation',
          TWwvRdesc: 'Allow user to access quotation page',
          ZCIMR: 'Manage quotation',
          ZCIMRdesc: 'Allow user to operate on quotation',
          pbKyf: 'View invoice',
          pbKyfdesc: 'Allow user to access invoice page',
          Vbgoh: 'Manage invoice',
          Vbgohdesc: 'Allow user to operate on invoice',
          ONyFq: 'View payment',
          ONyFqdesc: 'Allow user to access payment page',
          uamho: 'Manage payment',
          uamhodesc: 'Allow user to operate on payment',
          GTgMN: 'View expense',
          GTgMNdesc: 'Allow user to access expense page',
          ovyeA: 'Manage expense',
          ovyeAdesc: 'Allow user to operate on expense',
          QqseP: 'View sales report',
          QqsePdesc: 'Allow user to access sales report',
          LARTa: 'View statement',
          LARTadesc: 'Allow user to access statement report',
          shsJo: 'View customer list report',
          shsJodesc: 'Allow user to access customer list report',
          UHQDQ: 'View product report',
          UHQDQdesc: 'Allow user to access product report',
          XxvFG: 'View payment report',
          XxvFGdesc: 'Allow user to access payment report',
          sIWzT: 'View employee / summary report',
          sIWzTdesc: 'Allow user to access employee / summary report',
          TJmMw: 'View commission report',
          TJmMwdesc: 'Allow user to access commission report',
          lwaRc: 'Manage employee / permission group',
          lwaRcdesc: 'Allow user to operate on employee / permission group',
          ukWQg: 'Manage setting',
          ukWQgdesc: 'Allow user to operate on setting',
          OEVMz: 'View mobile blog',
          OEVMzdesc: 'Allow user to view mobile blog page',
          NogJD: 'Manage mobile blog',
          NogJDdesc: 'Allow user to operate on mobile blog',
          cRPPN: 'View mobile push',
          cRPPNdesc: 'Allow user to view mobile push page',
          fOESf: 'Manage mobile push',
          fOESfdesc: 'Allow user to operate on mobile push',
          LLVWE: 'View segment',
          LLVWEdesc: 'Allow user to access segment page',
          Mpxzf: 'Manage segment',
          Mpxzfdesc:
               'Allow user to operate on segment, like segmentation by age, invoice etc',
          KvVMJ: 'View mobile booking',
          KvVMJdesc: 'Allow user to access mobile booking page',
          VmDPa: 'Manage mobile booking',
          VmDPadesc: 'Allow user to operate on mobile booking',
          hafeB: 'View commission',
          hafeBdesc: 'Allow user to access commission page',
          AhcXU: 'Manage commission',
          AhcXUdesc: 'Allow user to operate on commission',
     },
};
