import axios from 'axios';

export default axios.create({
     baseURL: process.env.VUE_APP_APISERVER || `${location.origin}/api`,
     timeout: 150000,
     headers: {
          'Content-type': 'application/json',
          'x-access-token': `${localStorage.getItem('accesstoken')}`,
     },
     withCredentials: true,
});

/*import axios from 'axios';

const singleton = Symbol();
const singletonEnforcer = Symbol();

class ApiService {
     constructor(enforcer) {
          if (enforcer !== singletonEnforcer) {
               throw new Error('Cannot construct singleton');
          }

          console.log(`API Service for ${location.origin}`);

          this.session = axios.create({
               baseURL: process.env.VUE_APP_APISERVER || `${location.origin}`,

               timeout: 20000,
               headers: {
                    'Content-type': 'application/json',
                    'x-access-token': `${localStorage.getItem('accesstoken')}`,
               },
               withCredentials: true,
          });
     }

     static get instance() {
          // Try to get an efficient singleton
          if (!this[singleton]) {
               this[singleton] = new ApiService(singletonEnforcer);
          }

          return this[singleton];
     }

     get = (...params) => this.session.get(...params);
     post = (...params) => this.session.post(...params);
     put = (...params) => this.session.put(...params);
     patch = (...params) => this.session.patch(...params);
     remove = (...params) => this.session.delete(...params);
     head = (...params) => this.session.head(...params);
}

export default ApiService.instance;
*/
