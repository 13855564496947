import http from '../HttpConn';
class LogService {
     CustomerCreateLog(_uid, staffname) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'create',
               content: 'User created by ' + staffname,
               tag: [],
          });
     }

     CustomerModifyLog(_uid, staffname) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'modify',
               content: 'User modified by ' + staffname,
               tag: [],
          });
     }

     CustomerInvoiceCreateLog(_uid, staffname, invoiceno) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'create',
               content: invoiceno + ' created by ' + staffname,
               tag: [],
          });
     }

     CustomerInvoiceModifyLog(_uid, staffname, invoiceno) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'modify',
               content: invoiceno + ' modified by ' + staffname,
               tag: [],
          });
     }

     CustomerPaymentCreateLog(
          _uid,
          staffname,
          invoiceno,
          paymentId,
          amount = 0
     ) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'create',
               content:
                    'Payment Amount [' +
                    amount +
                    '] for ' +
                    invoiceno +
                    ' created by ' +
                    staffname,
               tag: [paymentId],
          });
     }

     CustomerPaymentModifyLog(
          _uid,
          staffname,
          invoiceno,
          paymentId,
          amount = 0
     ) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'modify',
               content:
                    'Payment Amount [' +
                    amount +
                    '] for ' +
                    invoiceno +
                    ' modified by ' +
                    staffname,
               tag: [paymentId],
          });
     }

     //depeciate
     CustomerProfileCreateLog(_uid, staffname, profileid, productname, credit) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'create',
               content:
                    productname +
                    ' was added to user storage [credit ' +
                    credit +
                    '] by user ' +
                    staffname,
               tag: [profileid],
          });
     }

     CustomerProfileUpdateLog(_uid, staffname, profileid, productname, credit) {
          return http.post('/customerlog/add', {
               customerid: _uid,
               type: 'log',
               action: 'update',
               content:
                    productname +
                    ' was update [credit ' +
                    credit +
                    '] by user ' +
                    staffname,
               tag: [profileid],
          });
     }

     /*addCustomerNewInvoiceLog(_uid, iobj) {
          return http.post('/customerlog/addinvoicelog/', {
               userid: _uid,
               invoiceobj: iobj,
          });
     }
     addCustomerEditInvoiceLog(_uid, iobj) {
          return http.post('/customerlog/editinvoicelog/', {
               userid: _uid,
               invoiceobj: iobj,
          });
     }
     addCustomerNewPaymentLog(_uid, pobj) {
          return http.post('/customerlog/addpaymentlog/', {
               userid: _uid,
               paymentobj: pobj,
          });
     }
     addCustomerEditPaymentLog(_uid, pobj) {
          return http.post('/customerlog/editpaymentlog/', {
               userid: _uid,
               paymentobj: pobj,
          });
     }
     addCustomerNewRewardLog(_uid, robj) {
          return http.post('/customerlog/addrewardlog/', {
               userid: _uid,
               rewardobj: robj,
          });
     }
     addCustomerEditRewardLog(_uid, robj) {
          return http.post('/customerlog/editrewardlog/', {
               userid: _uid,
               rewardobj: robj,
          });
     }
     addCustomerLoginLog(lobj) {
          return http.post('/customerlog/addloginlog/', lobj);
     }
     addCustomerModifyLog(_uid, _cid) {
          return http.post('/customerlog/addmodifylog/', {
               userid: _uid,
               customerid: _cid,
          });
     }*/
}
export default new LogService();
