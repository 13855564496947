<template>
     <v-dialog
          v-model="authDialog"
          persistent
          max-width="290"
          v-if="authDialog"
     >
          <v-card>
               <v-card-title class="text-h5">
                    {{ $t('components.sessionexpirytitle') }}
               </v-card-title>
               <v-card-text>{{
                    $t('components.sessionexpirymsg')
               }}</v-card-text>
               <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="cancel">
                         {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn color="green darken-1" text @click.native="agree">
                         {{ $t('global.ok') }}
                    </v-btn>
               </v-card-actions>
          </v-card>
     </v-dialog>
</template>

<script>
export default {
     data: function () {
          return {
               authDialog: false,
          };
     },
     methods: {
          open() {
               this.authDialog = true;
          },
          agree() {
               localStorage.removeItem('crmlogin');
               sessionStorage.removeItem('crmlogin');
               this.$cookies.remove('token');
               this.authDialog = false;

               //redirect to dashboard
               document.location.href = '/';
          },
          cancel() {
               this.authDialog = false;
          },
     },
};
</script>
