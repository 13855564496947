import http from '../HttpConn';
var isObject = require('lodash/isObject');
class StatisticService {
     getStatisticSimilarKey(pattern) {
          return http.get('/statistic/keystartwith/' + pattern);
     }
     getStatisticByKey(key) {
          return http.get('/statistic/' + key);
     }
     /* eslint-disable */
     updateStatistic(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/statistic/update/' + ccobj._id, ccobj);
     }
     addStatistic(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/statistic/add', ccobj);
     }
     getDashboardStatisticA(dateArray) {
          return http.get(
               '/statistic/dashboard/' + dateArray[0] + '_' + dateArray[1]
          );
     }
     getDashboardStatisticB(datestr) {
          return http.get('/statistic/dashboard/overduecount/' + datestr);
     }
     getDashboardStatisticC() {
          return http.get('/statistic/dashboard/customercount/');
     }
     getDashboardStatisticD(datestr) {
          return http.get('/statistic/dashboard/invoicecount/' + datestr);
     }
     /* eslint-enable */
}
export default new StatisticService();
