import http from '../HttpConn';
var isObject = require('lodash/isObject');
class RoleService {
     getAll(page, limit) {
          return http.get('/role', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page, limit) {
          return http.get('/role/allactive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/role/count');
     }
     getRole(id) {
          return http.get('/role/' + id);
     }
     getUserCountByRole(id) {
          return http.get('/role/getUserCount/' + id);
     }
     getAllPermissions() {
          return http.get('/role/allpermissions');
     }
     /* eslint-disable */
     updateRole(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/role/update/' + ccobj._id, ccobj);
     }
     addRole(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/role/add', ccobj);
     }
     deleteRole(id) {
          return http.delete('/role/delete/' + id);
     }
     /* eslint-enable */
}
export default new RoleService();
