import http from '../HttpConn';
var isObject = require('lodash/isObject');
class ResourceService {
     getAll(page, limit) {
          return http.get('/resource', {
               params: { page: page, limit: limit },
          });
     }
     getAllActive(page, limit) {
          return http.get('/resource/allactive', {
               params: { page: page, limit: limit },
          });
     }
     getCount() {
          return http.get('/resource/count');
     }
     getResource(id) {
          return http.get('/resource/' + id);
     }
     /* eslint-disable */
     updateResource(ccobj) {
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }
          return http.put('/resource/update/' + ccobj._id, ccobj);
     }
     addResource(ccobj) {
          //check ccobj
          if (!isObject(ccobj)) {
               return new Promise((resolve, reject) => {
                    throw new Error('Invalid object!!'); // 用throw語句
               });
          }

          return http.post('/resource/add', ccobj);
     }
     deleteResource(id) {
          return http.delete('/resource/delete/' + id);
     }
     /* eslint-enable */
}
export default new ResourceService();
