<template>
     <div class="pa-5">
          <div class="pb-10 d-flex flex-row justify-space-between">
               <span class="my-auto">{{ $t('product.stock') }}</span>
               <!--<v-btn color="primary" class="body-2 font-weight-bold">
                    <v-icon size="18">mdi-plus</v-icon>
                    <span class="white--text">新增</span>
               </v-btn>-->
          </div>

          <div
               v-for="(profile, index) in profileList"
               :key="index"
               class="d-flex flex-column"
          >
               <div>
                    <v-row>
                         <v-col cols="2" class="text-center my-auto">
                              <v-avatar
                                   :class="
                                        profile.status == 'active'
                                             ? 'enable_avatar'
                                             : 'disable_avatar'
                                   "
                                   size="40"
                              >
                                   <span
                                        :class="
                                             profile.status == 'active'
                                                  ? 'enable_text'
                                                  : 'disable_text'
                                        "
                                        >Sto</span
                                   >
                              </v-avatar>
                         </v-col>
                         <v-col class="my-auto">
                              <div class="body-2 font-weight-light">
                                   {{ profile.product_name }}
                              </div>
                              <div class="body-2 font-weight-light">
                                   {{ $t('customer.remain') }}:
                                   <strong>{{ profile.remain_credit }}</strong>
                              </div>
                              <div class="text-caption text2_color--text">
                                   {{ $t('global.expiry') }}:
                                   {{ formatDate(profile.expiry_on) }}
                              </div>
                         </v-col>
                         <v-col cols="2" class="text-center my-auto">
                              <v-btn icon @click="editProfile(profile)"
                                   ><v-icon color="primary"
                                        >mdi-pencil-circle</v-icon
                                   >
                              </v-btn>
                         </v-col>
                    </v-row>
               </div>
               <div class="mt-5">
                    <v-row>
                         <v-col cols="2" class="text-center my-auto"> </v-col>
                         <v-col class="my-auto pa-0">
                              <div
                                   class="pl-3 mb-4"
                                   v-for="(log, index) in profile.credit_log"
                                   :key="index"
                                   style="
                                        border-color: rgb(0, 0, 0, 0.12);
                                        border-style: solid;
                                        border-width: 0px 0px 0px 3px;
                                   "
                              >
                                   <span class="body-2"
                                        >{{ log.key }} : {{ log.service }}</span
                                   >
                                   <span class="body-2 font-weight-light">
                                        {{
                                             log.key == 'purchase'
                                                  ? '( +' + log.value + ' Cr )'
                                                  : log.key == 'consume'
                                                  ? '( -' + log.value + ' Cr )'
                                                  : '( ' + log.value + ' )'
                                        }}
                                   </span>

                                   <div class="body-2 font-weight-light">
                                        {{ log.ref }}
                                   </div>
                                   <span
                                        class="text-caption text2_color--text pt-2"
                                   >
                                        @ {{ formatDate(log.created_date) }}
                                   </span>
                              </div>
                         </v-col>
                    </v-row>
               </div>
               <v-divider class="mb-7 mt-2"></v-divider>
          </div>

          <v-dialog v-model="dialog" persistent max-width="550px">
               <v-card class="pa-0">
                    <TabCustomerInventoryAddComponent
                         v-if="dialog"
                         :customerobj="customerobj"
                         :_id="selectedid"
                         :customerprofileobj="selectedcustomerprofileobj"
                         @onClickCancel="onClickCancel"
                         @onClickInput="onClickInput"
                         @onFailAction="onFail"
                    />
               </v-card>
          </v-dialog>

          <v-snackbar
               v-model="is_success"
               color="primary accent-2"
               elevation="24"
          >
               Success: <strong>{{ this.success_message }}</strong>
          </v-snackbar>
          <v-snackbar v-model="is_fail" color="red accent-2" elevation="24">
               Failed: <strong>{{ this.error_message }}</strong>
          </v-snackbar>
     </div>
</template>

<script>
/* eslint-disable */
import CustomerProfileService from '@/utils/api/CustomerProfileService';
import TabCustomerInventoryAddComponent from '@/views/customer/TabCustomerInventoryAddView';

const moment = require('moment');
export default {
     props: {
          customerobj: Object,
     },
     data: () => ({
          profileList: [],

          page: 1,
          recordperpage: 9999,

          dialog: false,
          selectedid: null,
          selectedcustomerprofileobj: null,

          is_fail: false,
          is_success: false,
          error_message: '',
          success_message: '',
     }),
     methods: {
          apiGetRecords() {
               CustomerProfileService.getCustomerProfile(
                    this.customerobj._id,
                    this.page,
                    this.recordperpage
               )
                    .then((response) => {
                         //console.log(response.data);
                         if (response.data.status == 'success')
                              this.profileList = response.data.data;
                    })
                    .catch((e) => {
                         console.log(e);
                    });
          },
          editProfile(profile) {
               this.selectedcustomerprofileobj = profile;
               this._id = profile._id;
               this.dialog = true;
          },
          formatDate(value) {
               return moment(value).format('YYYY-MM-DD');
          },
          onClickCancel() {
               this.selectedcustomerprofileobj = null;
               this._id = null;
               this.dialog = false;
          },
          onClickInput(msg) {
               this.selectedcustomerprofileobj = null;
               this._id = null;
               this.dialog = false;

               this.apiGetRecords();

               this.success_message = msg;
               this.is_success = true;
          },
          onFail(msg) {
               this.selectedcustomerprofileobj = null;
               this._id = null;
               this.dialog = false;

               this.error_message = msg;
               this.is_fail = true;
          },
     },
     created() {
          if (this.customerobj != null) this.apiGetRecords();
          //console.log('inventory tab created!');
     },
     components: {
          TabCustomerInventoryAddComponent,
     },
};
</script>

<style scoped>
.enable_avatar {
     border: solid;
     border-width: 1px;
     border-radius: 50%;
     border-color: #1976d2;
}
.disable_avatar {
     border: solid;
     border-width: 1px;
     border-radius: 50%;
     border-color: #ff5252;
}
.enable_text {
     color: #1976d2;
     font-size: 0.875rem;
     font-weight: 400;
}
.disable_text {
     color: #ff5252;
     font-size: 0.875rem;
     font-weight: 400;
}
</style>
